import React, { MouseEvent, useState } from "react";
import { Button, Col, Row, Table } from "react-bootstrap";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { firestore } from "../../../firebase";
import { useCollectionClassData } from "../../../utils/Hooks";
import LoadingScreen from "../../LoadingScreen";
import { Nudger } from "@swing-therapeutics/swingcore/dist/models/nudge/Nudger";
import moment from "moment";

interface NudgeRowProps {
  nudger: Nudger;
}

enum DeleteStatus {
  DELETE = 2,
  CONFIRM_DELTE = 1,
}

const NudgeRow: React.FC<NudgeRowProps> = ({ nudger }) => {
  const history = useHistory();
  const [deleted, setDeleted] = useState(DeleteStatus.DELETE);

  const handleDeleteClick = (event: MouseEvent) => {
    event.stopPropagation();
    if (deleted === DeleteStatus.DELETE) {
      setDeleted(DeleteStatus.CONFIRM_DELTE);
    }
    else {
      Nudger.deleteNudger(nudger.id)
        .catch((error) => {
          console.error(error);
        });
    }
  }

  return (
    <tr>
      <td>
        {nudger.name}
      </td>
      <td>
        {nudger.description}
      </td>
      <td>
        {moment().set({ hours: nudger.scheduledTime.hours, minutes: nudger.scheduledTime.minutes }).format("h:mm A")}
      </td>
      <td>
        {nudger.active.toString()}
      </td>
      <td>
        <Button className="mr-2" onClick={() => history.push("/nudging/nudges/edit/" + nudger.id)}>
          Edit
        </Button>
        <Button className="mr-2" onClick={() => history.push("/nudging/nudges/logs/" + nudger.id)}>
          Logs
        </Button>
        <Button onClick={handleDeleteClick} className={deleted === DeleteStatus.CONFIRM_DELTE ? 'btn-danger' : ''}>
          {
            deleted === DeleteStatus.DELETE ?
              'Delete'
              :
              deleted === DeleteStatus.CONFIRM_DELTE &&
              'Confirm Delete'
          }
        </Button>
      </td>
    </tr>
  )
}

const NudgeList: React.FC<RouteComponentProps> = () => {
  const [nudgers, loading] = useCollectionClassData(Nudger, firestore.collection("tempest/nudging/nudgers"));

  return (
    <Col>
      <Row>
        <Col>
          <h3>Nudges</h3>
        </Col>
      </Row>
      <Row>
        <Col>
          {
            loading ?
              <LoadingScreen />
              :
              <Table striped responsive className="table-center-rows">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Description</th>
                    <th>Scheduled time</th>
                    <th>Active</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {nudgers?.map((nudger) => {
                    return (
                      <NudgeRow key={nudger.id} nudger={nudger} />
                    )
                  })}
                </tbody>
              </Table>
          }
        </Col>
      </Row>
    </Col>
  )
}

export default NudgeList;
