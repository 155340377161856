import React, { useEffect, useState, useRef, useCallback } from 'react';
import AddSurveyWeekDisplay from '../Surveys/AddSurveyWeekDisplay';
import DisplaySurveyAssignmentDay from '../Surveys/DisplaySurveyAssignmentDay';
import LandingPage, { LandingPageFormValues } from "../../../../Model/LandingPage";
import LandingPageExtension, { LandingPageExtensionFormValues } from '../../../../Model/LandingPageExtension';
import LoadingScreen from '../../../LoadingScreen';
import PaymentNotificationsDisplay from '../PaymentTracker/PaymentNotificationsDisplay';
import { Container, Row, Col } from 'react-bootstrap';
import { firestore } from '../../../../firebase';
import { Form as FormikForm, Formik } from "formik";
import { FormikSelect, FormikSubmitButton, FormikTextField } from "../../../FormikFields";
import { FormSchema } from '../formSchemas/formSchema005Ext';
import { WorkflowType } from '@swing-therapeutics/surveybay/dist/types';
import ScheduleCallOptions from '../ScheduleCallOptions';
import UniqueCallOption from '../UniqueCallOption';
import { Redirect } from "react-router";

interface Props {
  action: "new" | "edit";
  landingPageKey?: string;
}

enum ExtensionStates {
  EXTENSION_NOT_CREATED = "Extension not created",
  EXTENSION_LOADING = "Extension loading",
  EXTENSION_EDIT = "Extension in progress",
}

const LandingPageCreateEditExt: React.FC<Props> = ({ action, landingPageKey }) => {

  const [isNewExt, setIsNewExt] = useState(false);
  const [extensionState, setExtensionState] = useState(ExtensionStates.EXTENSION_LOADING)
  const [initialFormValues, setInitialFormValues] = useState<LandingPageExtensionFormValues>(null);
  const [error, setError] = useState<string>(null);
  const [message, setMessage] = useState("");
  const isMounted = useRef(true);
  const landingPageExtension = useRef<LandingPageExtension>(null);

  const fetchAndSetInitialFormValues = useCallback(async () => {
    if(landingPageKey){
      const lp = await LandingPage.fromLandingPageKey(landingPageKey);
      if (lp.extensionID) {
        const lpDocSnap = await firestore.doc(`${lp.getDocPath()}/extensions/${lp.extensionID}`).get()
        const lpExt = LandingPageExtension.fromFirestore(lpDocSnap);
        const lpData = lpExt.getEditableData();
        setInitialFormValues(lpData);
        setExtensionState(ExtensionStates.EXTENSION_EDIT);
        landingPageExtension.current = lpExt;
        setIsNewExt(false);
      } else {
        setIsNewExt(true);
        const lpData = LandingPageExtension.getEditableData();
        lpData.landingPageKey = landingPageKey;
        setInitialFormValues(lpData);
        setExtensionState(ExtensionStates.EXTENSION_NOT_CREATED);
      }
    }else{
      setIsNewExt(true);
      const lpData = LandingPageExtension.getEditableData();
      lpData.landingPageKey = landingPageKey;
      setInitialFormValues(lpData);
      setExtensionState(ExtensionStates.EXTENSION_NOT_CREATED);
    }
  }, [landingPageKey])

  useEffect(() => {
    isMounted.current = true;
    fetchAndSetInitialFormValues();
    return () => { isMounted.current = false };
  }, [fetchAndSetInitialFormValues])

  const setDisappearingMessage = useCallback((msg) => {
    setMessage(msg);
    setTimeout(() => {
      isMounted.current && setMessage(null);
    }, 5000);
  }, [isMounted])

  if (action !== "new" && !landingPageKey) {
    console.error("Landing page key not defined for edit landing page");
    return <Redirect to="/surveysaurus" />
  }

  return (
    <Container>
      <Row className="mb-3">
        <h3>
          {
            action === "new" ?
              "Styling new landing page"
              :
              "Edit Styling landing page"
          }
        </h3>
      </Row>
      <Row>
        {
          initialFormValues ?
            <Formik
              initialValues={{ ...initialFormValues }}
              enableReinitialize={true}
              onSubmit={async (values: LandingPageExtensionFormValues, actions) => {
                try {
                  if (isNewExt === true) {
                    let landingPageValues: LandingPageFormValues;
                    landingPageValues = {
                      landingPageKey: values.landingPageKey,
                      ...landingPageValues
                    };
                    const landingPage = await LandingPage.persistNewLandingPage(landingPageValues);
                    await LandingPageExtension.persistNewLandingPageExt(values, landingPage.landingPageKey);
                    actions.resetForm();
                    setDisappearingMessage("Extension created");
                    setIsNewExt(false);
                  } else {
                    setIsNewExt(false)
                    Object.assign(landingPageExtension.current, { ...values })
                    await landingPageExtension.current.persist();
                    setInitialFormValues({ ...landingPageExtension.current.getEditableData() })
                    setDisappearingMessage("Extension updated")
                  }
                } catch (error) {
                  setError(`Unable to save form values: ${error}`)
                } finally {
                  actions.setSubmitting(false)
                }
              }}
              validateOnChange={false}
              validationSchema={FormSchema}
            >
              <FormikForm role="form" style={{ width: "100%" }}>
                <Container>
                  <Row>
                    <Col sm={12} md={6}>
                      <FormikTextField
                        name="landingPageKey"
                        label="Landing page key"
                        desc="Key to use to point to landing page on Surveysaurus landing page, same as standard portion of trial"
                      />
                      <FormikSelect
                        name="therapyProgram"
                        label="Therapy Program"
                        desc="Active program for users in therapy of extension program"
                        placeHolder="Select program..."
                        options={[
                          { label: 'tempoACT3 🎉', value: 'tempoACT3' },
                        ]}
                      />
                      <FormikSelect
                        name="consentProgram"
                        label="Consent Program"
                        desc="Active program for users awaiting consent for extension program"
                        placeHolder="Select program..."
                        options={[
                          { label: '003Eligible 💘', value: '003Eligible' },
                          { label: '005EXTEligible 💘', value: '005EXTEligible' },
                        ]}
                      />
                      <FormikSelect
                        name="earlyTerminationProgram"
                        label="Early Termination Program"
                        desc="Active program for the user when they are removed early from the extension study"
                        placeHolder="Select program..."
                        options={[
                          { label: 'earlyTerminationREACTFM 💔', value: 'earlyTerminationREACTFM' },
                          { label: 'earlyTerminationPROSPERFMEXT 💔', value: 'earlyTerminationPROSPERFMEXT' },
                        ]}
                      />
                    </Col>
                    <Col sm={12} md={6}>
                      <FormikTextField
                        name="trialID"
                        label="Trial ID"
                        desc="ID used by Tempo app to handle special cases for the trial"
                      />

                      <FormikSelect
                        name="doneProgram"
                        label="Done Program"
                        desc="Active program for when users have completed all steps of the extension program"
                        placeHolder="Select program..."
                        options={[
                          { label: 'goodbyeREACTFMEXT ✌️', value: 'goodbyeREACTFMEXT' },
                          { label: 'goodbyePROSPERFMEXT ✌️', value: 'goodbyePROSPERFMEXT' },
                        ]}
                      />
                      <FormikSelect
                        name="consentFailProgram"
                        label="Consent Fail Program"
                        desc="Active program for when a user does not make is passed the consent call"
                        placeHolder="Select program..."
                        options={[
                          { label: 'earlyTerminationREACTFM 💔', value: 'earlyTerminationREACTFM' },
                          { label: 'earlyTerminationPROSPERFMEXT 💔', value: 'earlyTerminationPROSPERFMEXT' },
                        ]}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Row>
                        <DisplaySurveyAssignmentDay />
                      </Row>
                      <Row>
                        <AddSurveyWeekDisplay />
                      </Row>
                      <UniqueCallOption
                        title="Consent call"
                        desc="User will be given option to schedule call when they are eligible for extension"
                        basePath="consentCall"
                        allowRequireToggle={false}
                      />
                      <UniqueCallOption
                        title="Termination call"
                        desc="User will be given option to schedule call after being terminated early"
                        basePath="terminationCall"
                        allowRequireToggle={false}
                      />
                      <Row>
                        <ScheduleCallOptions />
                      </Row>
                      <Row className="my-3">
                        <PaymentNotificationsDisplay workflowType={WorkflowType.EXTENSION} />
                      </Row>
                      <Row className="justify-content-center">
                        <FormikSubmitButton label="Submit" />
                      </Row>
                      {
                        message &&
                        <Row className="justify-content-center">
                          <h6 className="mt-2 text-success">{message}</h6>
                        </Row>
                      }
                    </Col>
                  </Row>
                </Container>
              </FormikForm>
            </Formik>
          :
          error ?
          <h3 className="text-warning">{error}</h3>
          :
          <LoadingScreen />
        }
      </Row>
    </Container>
  );
}

export default LandingPageCreateEditExt;
