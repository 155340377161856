export interface JournalModalProps {
  close: (e?: any) => void;
  programKey: string;
  show: boolean;
}

export const knownQuestionTypes: ReadonlySet<string> = new Set<string>([
  "boolean",
  "text",
  "slider",
  "summary",
  "optionalText",
  "instructions",
  "timer",
  "repeat",
  "content",
  "dartboard",
  "dartboard_v2",
  "summary_dartboard_v2",
  "pacing-text",
  "pacing-slider",
  "pacing-summary",
  "pacing-content",
  "valuesEntry",
  "multipleInput",
  "enableReminders",
  "sleepPlan",
  "pacing-multipleInput",
  "pacing-planPicker",
  "pacing-restTimeModal"
]);
