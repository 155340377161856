import React from "react";
import { Container, Row } from "react-bootstrap";
import { RouteComponentProps } from "react-router-dom";
import TempoLogo from "./Images/tempoNew.png";

const DownloadTempo: React.FC<RouteComponentProps> = () => {

  return (
    <Container fluid className="text-center purp">
      <Row className="justify-content-center">
        <h1 style={{ marginTop: "50px", marginBottom: "40px" }}>Tempo</h1>
      </Row>
      <Row className="justify-content-center">
        <img
          style={{ borderRadius: "20px" }}
          height={"350"}
          width={"350"}
          src={TempoLogo}
        />
      </Row>
      <Row style={{ marginTop: "50px" }} className="justify-content-center">
        <p>
          It looks like you opened a link meant to send you to the Tempo Study
          application. Please try again from a device with the application.{" "}
        </p>
      </Row>
    </Container>
  );
};

export default DownloadTempo;
