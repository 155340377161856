import React from "react";
// import GraphTemplate from "../Graphs/GraphTemplate";
import GraphTemplateEPROsA from "../../Graphs/GraphTemplateEPROsA";

import { Col, Container, Row,Jumbotron } from "react-bootstrap";

import "../surveyAdherence.css";
import AdminNavbar from "../../AdminNavbar";

// import GraphTemplateEPROsA from "../../Graphs/GraphTemplateEPROsA";
import AnotherReportsListSidebar from "./AnotherReportsListSidebar";


const Graphs004AST = () => {
  return (
    <>
      <AdminNavbar />
      <Jumbotron>
            <Container fluid={true}>
                <Row>
                    <Col>
                        <h1>004A ST</h1><div className="TitleCb"></div>
                    </Col>
                </Row>
            </Container>
        </Jumbotron>
      <AnotherReportsListSidebar/>
      {/* <Container fluid={true}><AreportsListSidebar002Fright/></Container> */}
      <Container>
        <div className="mt-4">
          {/* <h4>002 ACT</h4> */}
          <Row>
            <Col xs={10}>
              <GraphTemplateEPROsA report={"s10_002"} />
            </Col>
          </Row>
          <Row>
            <Col xs={10}>
              <GraphTemplateEPROsA report={"s10_005"} />
            </Col>
          </Row>
          <Row>
            <Col xs={10}>
              <GraphTemplateEPROsA report={"s10_006"} />
            </Col>
          </Row>
          <Row>
            <Col xs={10}>
              <GraphTemplateEPROsA report={"s10_009"} />
            </Col>
          </Row>
          <Row>
            <Col xs={10}>
              <GraphTemplateEPROsA report={"s11_001"} />
            </Col>
          </Row>
          <Row>
            <Col xs={10}>
              <GraphTemplateEPROsA report={"s12_002"} />
            </Col>
          </Row>
 <Row>
            <Col xs={10}>
              <GraphTemplateEPROsA report={"s12_006"} />
            </Col>
          </Row>
 <Row>
            <Col xs={10}>
              <GraphTemplateEPROsA report={"s12_007"} />
            </Col>
          </Row>
 <Row>
            <Col xs={10}>
              <GraphTemplateEPROsA report={"s12_008"} />
            </Col>
          </Row>
          <Row>
            <Col xs={10}>
              <GraphTemplateEPROsA report={"s12_009"} />
            </Col>
          </Row>
 <Row>
            <Col xs={10}>
              <GraphTemplateEPROsA report={"s13_006"} />
            </Col>
          </Row>
 <Row>
            <Col xs={10}>
              <GraphTemplateEPROsA report={"s13_010"} />
            </Col>
          </Row>
 <Row>
            <Col xs={10}>
              <GraphTemplateEPROsA report={"s14_001"} />
            </Col>
          </Row>
        </div>
      </Container>
      
      
    </>
  );
};

export default Graphs004AST;
