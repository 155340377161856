import React from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import TextStepEditor from "./TextStepEditor";
import AudioStepEditor from "./AudioStepEditor";
import PromptStepEditor from "./PromptStepEditor";
import { Lesson, LessonStep } from "@swing-therapeutics/swingcore/dist/models/content/Lesson";

export interface StepEditorProps {
  lesson: Lesson;
  step: LessonStep;
  goBack: () => void;
}

const StepEditor = ({ lesson }) => {
  const match = useRouteMatch();
  const stepNumber = match.params["stepNumber"];
  const step = lesson?.steps?.[stepNumber - 1];
  let history = useHistory();
  const params = useRouteMatch("/programs/:programID/lessons/:lessonID/:stepID").params as {[key: string]: string};
  const goBack = () => {
    history.push(`/programs/${params.programID}/lessons/${params.lessonID}`);
  }

  return (
    <>
      {step?.type === "Text" && (
        <TextStepEditor lesson={lesson} step={step} goBack={goBack}></TextStepEditor>
      )}
      {step?.type === "Introduction" && (
        <TextStepEditor lesson={lesson} step={step} goBack={goBack}></TextStepEditor>
      )}
      {step?.type === "Audio" && (
        <AudioStepEditor lesson={lesson} step={step} goBack={goBack}></AudioStepEditor>
      )}
      {step?.type === "Prompt" && (
        <PromptStepEditor lesson={lesson} step={step} goBack={goBack}></PromptStepEditor>
      )}
    </>
  );
};

export default StepEditor;
