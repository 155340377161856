import { useFormik } from "formik";
import React from "react";
import { Button, Modal, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { Lesson } from "@swing-therapeutics/swingcore/dist/models/content/Lesson";
import * as uuid from "uuid";
import * as Yup from "yup";
import { BasicNumberField, BasicTextField, FieldHeader } from "../../../../utils/FormikHelpers";

interface AddLessonModalProps {
  programKey: string;
  close: () => void;
  lessons: Lesson[];
}

export const AddLessonModal = ({
  programKey,
  lessons,
  close,
}: AddLessonModalProps) => {
  let history = useHistory();

  const initialValues = {
    id: "",
    title: "",
    chNum: 1,
    programKey: "",
    num: 0,
    steps: [],
  };

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      title: Yup.string().required("Title Required"),
      chNum: Yup.string().required("Chapter number Required"),
      id: Yup.string().required("ID Required").min(1).test(
        'is-unique',
        'ID is not unique',
        id => lessons.findIndex(lesson => lesson.id === id) === -1
      ),
    }),
    onSubmit: async (values) => {
      const filteredLessonsByChapter = lessons.filter(
        (lesson) => lesson.chNum === Number(values.chNum)
      );

      const newLesson = new Lesson({
        id: values.id,
        programKey: programKey,
        title: values.title,
        num: filteredLessonsByChapter.length + 1,
        chNum: Number(values.chNum),
        steps: [],
      });

      await newLesson.persist();

      history.push(`/programs/${programKey}/lessons/${newLesson.id}`);
    },
  });

  const getError = (errors: any) => {
    for (let error in errors) {
      return <div style={{ color: "red" }}>{`${errors[error]}`}</div>;
    }
    return <></>;
  };

  return (
    <>
      <Modal show onHide={close}>
        <Modal.Header closeButton>
          <Modal.Title>Add Lesson</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FieldHeader title="ID" />
          <Row>
            <BasicTextField path="id" lg={9} md={9} formikHelpers={formik} />
            <Button style={{ height: 36 }} onClick={() => formik.setFieldValue('id', uuid.v4())}>
              Generate
            </Button>
          </Row>
          <Row>
            <BasicTextField path="title" title="Title" lg={8} md={8} formikHelpers={formik} />
          </Row>
          <Row>
            <BasicNumberField path="chNum" title="Chapter Number" formikHelpers={formik} />
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => close()} variant="secondary">
            Close
          </Button>
          <Button onClick={() => formik.submitForm()} variant="success">
            Save changes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
