import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { isEmpty, isNil } from "lodash";
import React, { CSSProperties, useState } from "react";
import { Button, Container, Dropdown, DropdownButton, Form, Row, Col } from "react-bootstrap";
import { FirestoreQuery } from "./Users/UserDetail/Sections/UsersEventsTable";

interface SearchBarProps {
  baseQuery: FirestoreQuery;
  newQueryCallback: (query: FirestoreQuery) => void;
  searchProps: string[];
}

export const SearchBar = ({ baseQuery, newQueryCallback: finalizedCallback, searchProps}: SearchBarProps) => {
  const [selectedProp, setSelectedProp] = useState(searchProps[0]);
  const [input, setInput] = useState<string>();

  const search = () => {
    if (isNil(input) || isEmpty(input)) {
      finalizedCallback(baseQuery);
      return;
    }

    finalizedCallback(baseQuery.where(selectedProp, '==', input));
  }

  return (
      <Row className="flex-nowrap" style={{marginBottom: 15}}>
        <Col xs="auto">
          <DropdownButton title={selectedProp}>
            {searchProps.map(prop =>
              <Dropdown.Item key={prop} onClick={() => setSelectedProp(prop)}>
                {prop}
              </Dropdown.Item>
            )}
          </DropdownButton>
        </Col>

        <Col xs={8} lg={6}>
          <Form.Control
            onChange={({ target: { value } }) => setInput(value)}
            placeholder="Enter Search Term..." />
        </Col>
        
        <Col xs="auto">
          <Button onClick={search}>
            <FontAwesomeIcon icon={faSearch} />
          </Button>
        </Col>
      </Row>
  )
}
