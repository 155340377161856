import React, { useCallback, useEffect, useRef, useState } from "react";
import { Button, Card, Col, Container, Fade, Jumbotron, Row } from "react-bootstrap";
import { RouteComponentProps, useHistory } from "react-router";
import AdminNavbar from "../../AdminNavbar";
import { SurveyBayForm } from "@swing-therapeutics/surveybay/dist/models/SurveyBayForm";
import FormInfoEdit from "./FormInfoEdit";
import LoadingScreen from "../../LoadingScreen";
import FormQuestionEdit from "./FormQuestionEdit";
import { getSurveyBayForms } from "@swing-therapeutics/surveybay/dist/utils";
import _ from "lodash";
import AddQuestionButton from "./AddQuestionButton";
import { getUniqueQuestionID } from "./FormQuestionEdit/FormQuestionEdit";
import { Question, QuestionTypes } from "@swing-therapeutics/surveybay/dist/types";
import { useCollectionClassData } from "../../../utils/Hooks";

interface PageParams {
  action: "new" | "edit";
  formKey?: string;
}

enum ErrorTypes {
  QuestionError = 'questionerror',
  InfoError = 'infoerror',
  SaveError = 'saveerror',
  FetchError = 'fetcherror',
}

interface Status {
  status: 'saved' | 'error' | 'adding_questions' | undefined,
  errorType: ErrorTypes | false,
  msg: string;
  questionNumber?: number,
}

const defaultStatus: Status = {
  status: undefined,
  errorType: false,
  msg: '',
}

const FormCreateEdit: React.FC<RouteComponentProps<PageParams>> = ({ match }) => {
  const [form, setForm] = useState<SurveyBayForm>();
  const [questions, setQuestions] = useState<Question[]>([]);
  const questionRefs = useRef([]);
  const [submitting, setSubmitting] = useState(false);
  const formInfoRef = useRef(null);
  const [status, setStatus] = useState<Status>({ ...defaultStatus });
  // Allow the form key and name to be edited, while creating a new form after first question is created this is set to false
  // So questionIDs that are created based on the key are not messed up
  const [allowInfoEdit, setAllowInfoEdit] = useState(match.params.action === 'new');
  const history = useHistory();

  const fetchForm = useCallback(async (surveyBayKey: string): Promise<SurveyBayForm> => {
    const doc = await getSurveyBayForms().doc(surveyBayKey).get();
    if (!doc.exists) {
      console.error(`Could not find survey bay form with key ${surveyBayKey}`);
      return;
    }
    const docData = doc.data();
    // Modify the question's conditional display if exists, add in if not
    for (const question of docData.questions) {
      if (question.conditionalDisplay) {
        question.conditionalDisplay = {
          hideQuestionsIfEquals: Array.isArray(question.conditionalDisplay.hideQuestionsIfEquals) ?
            question.conditionalDisplay.hideQuestionsIfEquals.join(",")
            :
            question.conditionalDisplay.hideQuestionsIfEquals,
          questionNumbersToHide: question.conditionalDisplay.questionNumbersToHide.join(","),
          hide: question.conditionalDisplay.hide ?? true,
        }
      }
      else {
        question.conditionalDisplay = {
          hideQuestionsIfEquals: '',
          questionNumbersToHide: '',
          hide: true,
        }
      }
      if (!question.uniqueID) {
        // If the question doesnt have a unique ID sneek it in, relic of adding a new requirement after questions were already made
        question.uniqueID = await generateFullQuestionID(docData.key);
      }
    }
    return SurveyBayForm.fromParams(docData);
  }, [])

  useEffect(() => {
    if (match.params.action === "new") {
      setForm(new SurveyBayForm());
    }
    else {
      if (match.params.formKey) {
        fetchForm(match.params.formKey)
          .then((form) => {
            setForm(form);
            setQuestions(form.questions);
            setStatus(defaultStatus);
          })
          .catch(error => {
            console.error(error);
            setStatus({
              status: 'error',
              errorType: ErrorTypes.FetchError,
              msg: 'Error getting form',
            })
          });
      }
      else {
        history.push("/003/forms");
      }
    }
  }, [match.params, history])

  const handleSubmitClick = useCallback(async () => {
    if (submitting) return;
    // Pull out the refs
    const validQuestionRefs = questionRefs.current.filter((questionRef) => !!questionRef);
    const validInfoRef = formInfoRef.current;
    setSubmitting(true);
    setStatus({ ...defaultStatus });
    // Set timeout so state updates before crunch the numbers time
    await new Promise((resolve) => setTimeout(resolve, 500));
    let error = false;
    /* First step validate form info */
    const errors = await validInfoRef.validateForm();
    if (Object.keys(errors).length !== 0) {
      error = true;
      validInfoRef.submitForm();
      setStatus({
        status: 'error',
        errorType: ErrorTypes.InfoError,
        msg: "Form info has an error",
      })
      setSubmitting(false);
      // Return early
      return;
    }
    /* End validate form info */

    /* Next step validate questions */
    const cleanQuestions: Question[] = [];
    if (!validQuestionRefs.length) {
      error = true;
      setStatus({
        status: 'error',
        errorType: ErrorTypes.QuestionError,
        msg: 'This form has no questions',
      })
    }
    else {
      for (const questionRef of validQuestionRefs) {
        const errors = await questionRef.validateForm();
        if (Object.keys(errors).length === 0) {
          // Question is valid
          // Clean up the conditionalDisplay property
          // conditionalDisplay property should has two different properties
          // 1. questionNumbersToHide should be an array but is best to edit as a csv list so must convert back into array
          // yup handles validating that the csv is array of numbers
          // 2. hideQuestionsIfEquals should be either an array of strings or a string depending on response type
          if (questionRef.values.conditionalDisplay.questionNumbersToHide?.length) {
            const questionNumbersToHide = questionRef.values.conditionalDisplay.questionNumbersToHide.split(",")
              .filter((value) => !!value)
              .map((value) => parseInt(value));
            let hideQuestionsIfEquals = questionRef.values.conditionalDisplay.hideQuestionsIfEquals;
            if (Array.isArray(questionRef.values.response)) {
              // In csv format, change to array
              hideQuestionsIfEquals = hideQuestionsIfEquals.split(",");
            }
            const cleanQuestion: Question = {
              ...questionRef.values,
              conditionalDisplay: {
                questionNumbersToHide,
                hideQuestionsIfEquals,
                hide: questionRef.values.conditionalDisplay.hide,
              }
            }
            cleanQuestions.push(cleanQuestion);
          }
          else {
            // Property conditionalDisplay doesnt have any data to it, remove it
            const cleanQuestion = { ...questionRef.values } as Question;
            delete cleanQuestion.conditionalDisplay;
            cleanQuestions.push(cleanQuestion);
          }
        }
        else {
          // Question is not valid
          // Submit the form to show the error
          questionRef.submitForm();
          error = true;
          setStatus({
            status: 'error',
            errorType: ErrorTypes.QuestionError,
            msg: `Question ${questionRef.values.order} has an error`,
            questionNumber: questionRef.values.order,
          });
          break;
        }
      }
    }
    /* End validate questions */
    if (!error) {
      try {
        const updatedForm = SurveyBayForm.fromParams({
          // Transfer over created info from form before update
          created: form.created,
          createdBy: form.createdBy,
          // New data
          ...validInfoRef.values,
          questions: cleanQuestions,
        });
        await updatedForm.persist();
        setStatus({
          status: 'saved',
          errorType: false,
          msg: 'Saved form',
        })
        if (match.params.action === "new") {
          formInfoRef.current.resetForm();
          setForm(new SurveyBayForm());
          setQuestions([]);
          setAllowInfoEdit(true);
        }
        else {
          setForm(updatedForm);
        }
      } catch (error) {
        setStatus({
          status: 'error',
          errorType: ErrorTypes.SaveError,
          msg: 'Error saving form, check console',
        })
        console.error(error);
      }
    }
    setSubmitting(false);
  }, [submitting, form])

  const checkFormKeyExists = (): string | false => {
    // Check that required fields are in place before adding unique ID to questions
    // Unique ID requires the form key so if its not set we cannot create the unique ID
    const formInfo = formInfoRef.current;
    if (!formInfo.values.key) {
      setStatus({
        status: 'error',
        errorType: ErrorTypes.InfoError,
        msg: 'Enter form key',
      })
      return false;
    }
    if (!formInfo.values.name) {
      setStatus({
        status: 'error',
        errorType: ErrorTypes.InfoError,
        msg: 'Enter form name',
      })
      return false;
    }
    return formInfo.values.key;
  }

  const addQuestion = (questionType: QuestionTypes, underIndex?: number) => {
    const formKey = checkFormKeyExists();
    if (!formKey) return;
    if (underIndex !== undefined) {
      setQuestions(prevQuestionArray => {
        prevQuestionArray.splice(underIndex + 1, 0, createNewQuestion(questionType, formKey));
        return [...prevQuestionArray];
      });
    }
    else {
      setQuestions((prevQuestions) => {
        return [...prevQuestions, createNewQuestion(questionType, formKey)];
      })
    }
    setStatus((prevStatus) => {
      if (prevStatus.status !== undefined) {
        // If there is a status, set the default
        return { ...defaultStatus };
      }
      return prevStatus;
    });
    setAllowInfoEdit(false);
  }

  const handleCopyQuestions = async (surveyBayKey: string) => {
    const formKey = checkFormKeyExists();
    if (!formKey) return;
    setStatus({
      status: 'adding_questions',
      errorType: false,
      msg: 'Adding questions...',
    })
    setAllowInfoEdit(false);
    const form = await fetchForm(surveyBayKey);
    // Need to update the unique ID of the questions from the form with the current forms key
    for (const question of form.questions) {
      question.uniqueID = await generateFullQuestionID(formKey);
    }
    setQuestions((prevQuestions) => [...prevQuestions, ...form.questions]);
    setStatus(defaultStatus);
  }

  const moveQuestion = (newIndex: number, oldIndex: number) => {
    let cQuestionRefs = questionRefs.current.filter((questionRef) => !!questionRef);
    const movedQuestion = cQuestionRefs.splice(oldIndex, 1)[0];
    cQuestionRefs.splice(newIndex, 0, movedQuestion);
    const newQuestionArray: Question[] = cQuestionRefs
      .map((questionRef) => questionRef.values as Question);
    questionRefs.current = cQuestionRefs;
    setQuestions(newQuestionArray);
  }

  const deleteQuestion = (index: number) => {
    let cQuestionRefs = questionRefs.current.filter((questionRef) => !!questionRef);
    cQuestionRefs.splice(index, 1);
    const newQuestionArray: Question[] = cQuestionRefs
      .map((questionRef) => questionRef.values as Question);
    if (newQuestionArray.length === 0 && match.params.action === 'new') {
      // No questions and this is a new form, allow the info to change
      setAllowInfoEdit(true);
    }
    questionRefs.current = cQuestionRefs;
    setQuestions(newQuestionArray);
  }

  const duplicateQuestion = (index: number) => {
    let cQuestionRefs = questionRefs.current.filter((questionRef) => !!questionRef);
    const copyQuestion = _.cloneDeep(cQuestionRefs[index]);
    // Reset the ID to make a new one
    const lastIndex = copyQuestion.values.uniqueID.lastIndexOf('_');
    copyQuestion.values.uniqueID = copyQuestion.values.uniqueID.substring(0, lastIndex) + "_SETID";
    cQuestionRefs.splice(index + 1, 0, copyQuestion);
    const newQuestionArray: Question[] = cQuestionRefs
      .map((questionRef) => questionRef.values as Question);
    questionRefs.current = cQuestionRefs;
    setQuestions(newQuestionArray);
  }

  const jumpToQuestion = (questionNumber: number) => {
    const element = document.getElementById(`question-${questionNumber}`);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }

  return (
    <>
      <AdminNavbar />
      <Jumbotron>
        <Container fluid style={{ backgroundColor: "white" }}>
          <Row>
            <Col>
              <h3>{match.params.action[0].toUpperCase() + match.params.action.slice(1)} Form</h3>
            </Col>
          </Row>
          {
            form ?
              <>
                <Row className="my-3">
                  <Col xs={12} md={6}>
                    <Card>
                      <Card.Header>
                        <h3>Form Info</h3>
                      </Card.Header>
                      <Card.Body>
                        <FormInfoEdit form={form} ref={formInfoRef} allowInfoEdit={allowInfoEdit} />
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
                <div className="sticky-bottom-right-screen">
                  <div className="py-2 px-4" style={{ backgroundColor: 'rgb(0, 0, 0, 0.2)', maxWidth: 500, borderRadius: 10 }}>
                    <Row>
                      <Col xs="auto">
                        <Button
                          onClick={handleSubmitClick}
                          disabled={submitting}
                        >
                          {
                            submitting ?
                              'Saving...'
                              :
                              'Save'
                          }
                        </Button>
                      </Col>
                      {
                        status.status === 'error' &&
                        <Col xs="auto">
                          <Row className="justify-content-center">
                            <h6 className="text-warning mb-0">{status.msg}</h6>
                          </Row>
                        </Col>
                      }
                      {
                        status.status === 'saved' &&
                        <Col xs="auto">
                          <Row className="justify-content-center">
                            <h6 className="text-success mb-0">{status.msg}</h6>
                          </Row>
                        </Col>
                      }
                      {
                        status.status === 'adding_questions' &&
                        <Col xs="auto">
                          <Row className="justify-content-center">
                            <h6 className="mb-0">{status.msg}</h6>
                          </Row>
                        </Col>
                      }
                      <Col xs="auto">
                        <AddQuestionButton addQuestion={addQuestion} />
                      </Col>
                    </Row>
                    <Row className="mt-2">
                      <QuestionJump questions={questions} jumpToQuestion={jumpToQuestion} />
                    </Row>
                  </div>
                </div>
                <Card>
                  <Card.Header>
                    <Row className="mb-2">
                      <Col>
                        <h3>Form Questions</h3>
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      <Col style={{ maxWidth: 700 }}>
                        <CopyQuestionsFromForm
                          onCopyQuestionsPress={handleCopyQuestions}
                          disabled={status.status === 'adding_questions'}
                        />
                      </Col>
                    </Row>
                  </Card.Header>
                  <Card.Body>
                    <Row>
                      <Col>
                        {questions.map((question, index) => {
                          return (
                            <FormQuestionEdit
                              question={question}
                              index={index}
                              key={question.uniqueID}
                              moveQuestion={moveQuestion}
                              deleteQuestion={deleteQuestion}
                              duplicateQuestion={duplicateQuestion}
                              addQuestion={addQuestion}
                              finalQuestion={index === questions.length - 1}
                              ref={(ref) => { questionRefs.current[index] = ref }}
                              formType={formInfoRef.current.values.formType}
                            />
                          )
                        })}
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </>
              :
              <LoadingScreen />
          }
        </Container>
      </Jumbotron>
    </>
  )
}

export default FormCreateEdit;

interface QuestionJumpProps {
  questions: Question[];
  jumpToQuestion: (questionNumber: number) => void;
}

const QuestionJump: React.FC<QuestionJumpProps> = ({ questions, jumpToQuestion }) => {
  const [show, setShow] = useState(false);

  return (
    <>
      <Row className="px-4">
        <h6 onClick={() => setShow(prev => !prev)} className="hoverable"><u>{show ? 'Hide' : 'Show'} questions</u></h6>
      </Row>
      <Row className="px-3">
        <Fade in={show}>
          <Col style={show ? undefined : { display: 'none' }}>
            <Row>
              {questions.map((_question, index) => {
                return (
                  <h6 onClick={() => jumpToQuestion(index + 1)} className="hoverable px-2" key={index}>
                    <u>{index + 1}</u>
                  </h6>
                )
              })}
            </Row>
          </Col>
        </Fade>
      </Row>
    </>
  )
}

const createNewQuestion = (questionType: QuestionTypes, formKey: string): Question => {
  // Create the new question with required fields
  const newQuestion: Question = {
    // Add a psudo random uniqueID to the end for temp uniqueID, will get set with real uniqueID when question is displayed
    // Need uniqueID for key on .map
    uniqueID: `${formKey.substring(0, 6)}_SETID${Math.random()}`,
    instructions: '',
    type: questionType,
    // Check box has an array response type
    response: QuestionTypes.CheckBox === questionType ? [] : '',
    answered: false,
    order: 0, // Will be set when placed in question array
    allowNoResponse: QuestionTypes.DisplayText === questionType,
    submitBtnText: QuestionTypes.DisplayText === questionType ? 'Continue' : 'Submit',
    updated: new Date(),
    conditionalDisplay: {
      hideQuestionsIfEquals: '',
      questionNumbersToHide: [],
      hide: true,
    },
    skipped: false,
  }

  if ([QuestionTypes.CheckBox, QuestionTypes.Buttons, QuestionTypes.Radio].includes(questionType)) {
    // Include options field
    newQuestion.options = [{
      value: '',
      label: '',
    }]
  }
  else if ([QuestionTypes.Text, QuestionTypes.TextMultiLine].includes(questionType)) {
    // Text fields have validation
    newQuestion.placeHolder = '';
    newQuestion.validation = {
      answerType: 'string',
      min: 0,
      max: 100,
    }
  }
  else if (QuestionTypes.Slider === questionType) {
    // Include slider options
    newQuestion.sliderOptions = {
      min: 0,
      max: 10,
      step: 1,
      minLabel: "",
      maxLabel: "",
    }
  }

  if (QuestionTypes.DisplayText === questionType) {
    newQuestion.text = '';
  }

  return newQuestion;
}

interface CQFromFromProps {
  onCopyQuestionsPress: (surveyBayKey) => void;
  disabled?: boolean;
}

const CopyQuestionsFromForm: React.FC<CQFromFromProps> = ({ onCopyQuestionsPress, disabled }) => {
  const [forms, _loading] = useCollectionClassData(SurveyBayForm, getSurveyBayForms());
  const [selectedSurveyBayKey, setSelectedSurveyBayKey] = useState('');

  const handleCopyQuestionClick = useCallback(() => {
    if (!selectedSurveyBayKey) return;
    onCopyQuestionsPress(selectedSurveyBayKey);
  }, [onCopyQuestionsPress, selectedSurveyBayKey])

  return (
    <>
      <Row>
        <Col>
          <select
            className="form-control"
            value={selectedSurveyBayKey}
            onChange={(event) => setSelectedSurveyBayKey(event.target.value)}
          >
            <option value="">Select...</option>
            {forms.map((form) => {
              return (
                <option key={form.key} value={form.key}>
                  {form.name} ({form.key})
                </option>
              )
            })}
          </select>
        </Col>
        <Col>
          <Button
            onClick={handleCopyQuestionClick}
            disabled={!selectedSurveyBayKey || disabled}
          >
            Copy all questions
          </Button>
        </Col>
      </Row>
      <Row>
        <Col>
          <small>Questions will be appended to end of form. New unique ID will be generated for copied questions.</small>
        </Col>
      </Row>
    </>
  )
}

// This function adds in the formKey to the unique questionID
const generateFullQuestionID = async (formKey: string): Promise<string> => {
  const uniqueID = await getUniqueQuestionID();
  return `${formKey.substring(0, 6)}_${uniqueID}`;
}
