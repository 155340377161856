import { useFormikContext } from 'formik';
import React, { ReactElement } from 'react';

interface Props {
  children(isSubmitting: boolean): ReactElement;
}

// Wrapper component to pass prop isSubmitting to children to limit rerendering entire
// Formik form buy using the props from the <Formik /> component
// Usage:
// <FormikFormSubmitting>
// {isSubmitting => (
//   isSubmitting &&
//   <h6 className="mt-2 text-success">Saving...</h6>
// )}
// </FormikFormSubmitting>
const FormikFormSubmitting: React.FC<Props> = ({ children }) => {
  const { isSubmitting } = useFormikContext();

  return children(isSubmitting);
}

export default FormikFormSubmitting;
