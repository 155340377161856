import moment from "moment";
import React, { MouseEvent, useState } from "react";
import { Button, Col, Row, Table } from "react-bootstrap";
import { RouteComponentProps, useHistory } from "react-router";
import { firestore } from "../../../firebase";
import ReferralGroup from "../../../Model/ReferralGroup";
import { useCollectionClassData } from "../../../utils/Hooks";
import LoadingScreen from "../../LoadingScreen";

interface ReferralRowProps {
  referralGroup: ReferralGroup;
}

const ReferralRow: React.FC<ReferralRowProps> = ({ referralGroup }) => {
  const history = useHistory();
  const [deleted, setDeleted] = useState(2); // Count down to 0 to deleted

  const handleDeleteClick = (event: MouseEvent) => {
    event.stopPropagation();
    if (deleted === 2) {
      setDeleted(1);
    }
    else {
      ReferralGroup.deleteReferralGroup(referralGroup.referralGroupKey);
      setDeleted(0);
    }
  }

  return deleted === 0 ?
    null
    :
    <tr>
      <td>
        {referralGroup.name}
      </td>
      <td>
        {referralGroup.referralGroupKey}
      </td>
      <td>
        {referralGroup.referralID}
      </td>
      <td>
        {referralGroup.referralGroupEmail}
      </td>
      <td>
        {moment(referralGroup.created).format("MM/DD/YY")}
      </td>
      <td>
        <Button className="mr-2" onClick={() => history.push("/surveysaurus/referralgroups/edit/" + referralGroup.referralGroupKey)}>
          Edit
        </Button>
        <Button onClick={handleDeleteClick} className={deleted === 1 ? 'btn-danger' : ''}>
          {
            deleted === 2 ?
              'Delete'
              :
              deleted === 1 &&
                'Confirm delete'
          }
        </Button>
      </td>
    </tr>
}

const ReferralGroupsList: React.FC<RouteComponentProps> = () => {
  const [referralGroups, loading] = useCollectionClassData(ReferralGroup, firestore.collection("web/surveysaurus/referralGroups"));

  return (
    <Col>
      <Row>
        <Col>
          <h2>Referral groups</h2>
        </Col>
      </Row>
      <Row>
        <Col>
          {
            loading ?
              <LoadingScreen />
              :
              <Table striped responsive className="table-center-rows">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Key</th>
                    <th>ID</th>
                    <th>Email</th>
                    <th>Created</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {referralGroups?.map((referralGroup) => {
                    return (
                      <ReferralRow key={referralGroup.referralGroupKey} referralGroup={referralGroup} />
                    )
                  })}
                </tbody>
              </Table>
          }
        </Col>
      </Row>
    </Col>
  )
}

export default ReferralGroupsList;
