import { useFormik } from "formik";
import React, { useEffect } from "react";
import { Button, Col, Form, Modal } from "react-bootstrap";
import { useAwarenessTasks } from "../TaskDataGetter";
import { useCollectionClassData } from "../../../../utils/Hooks";
import { JournalTemplate } from "@swing-therapeutics/swingcore/dist/models/journals/JournalTemplate";
import { getChaptersTemplates, getJournalsTemplates, getLessonsTemplates } from "@swing-therapeutics/swingcore/dist/utils/queries";
import { Chapter } from "@swing-therapeutics/swingcore/dist/models/content/Chapter";
import { SessionTemplate, SessionTaskData } from "@swing-therapeutics/swingcore/dist/models/Session";
import { Lesson } from "@swing-therapeutics/swingcore/dist/models/content/Lesson";

interface AddTaskProps {
  show: boolean;
  close: Function;
  session: SessionTemplate;
  playlistKey: string;
}

enum TaskType {
  awareness = "awareness",
  journaling = "journaling",
  lesson = "lesson",
}

export const AddTaskModal = ({
  show,
  close,
  session,
  playlistKey,
}: AddTaskProps) => {
  const [lessons] = useCollectionClassData(Lesson, getLessonsTemplates(session.programKey))
  const [chapters] = useCollectionClassData(Chapter, getChaptersTemplates(session.programKey))
  const [activities] = useAwarenessTasks(session.programKey);
  const [journals] = useCollectionClassData(JournalTemplate, getJournalsTemplates(session.programKey));

  const formik = useFormik({
    initialValues: {
      type: "",
      id: "",
      uuid: "",
      displayName: "",
      chapter: "",
      lesson: "",
    },

    validate: (values) => {
      const errors: { type?: string } = {};
      if (values.type === "awareness") {
        if (values.id === "") {
          errors.type = "*Pick an Awareness Activity";
        }
      }

      if (values.type === "journaling") {
        if (values.id === "") {
          errors.type = "*Pick a Journal type";
        }
      }

      if (values.type === "lesson") {
        if (values.chapter === "") {
          errors.type = "*Pick a chapter";
        } else if (values.lesson === "") {
          errors.type = "*Pick a lesson";
        }
      }

      return errors;
    },
    onSubmit: async (values) => {
      let task = {} as SessionTaskData;
      const newSession = new SessionTemplate(session);

      if (values.type === "awareness") {
        let awarenessIndex = Number(values.id);
        task = {
          type: values.type,
          displayName: "Awareness",
          id: activities[awarenessIndex].key,
          uuid: activities[awarenessIndex].uuid,
        };
      }

      if (values.type === "journaling") {
        task = {
          type: values.type,
          id: values.id,
          displayName: getJournalFromID(values.id).displayName,
        };
      }

      if (values.type === "lesson") {
        let lessonIndex = Number(values.lesson);
        task = {
          type: values.type,
          chapter: Number(values.chapter),
          lesson: lessons[lessonIndex].num,
          id: lessons[lessonIndex].id,
          displayName: lessons[lessonIndex].title,
        };
      }
      
      newSession.tasks.push(task);
      await newSession.persist(playlistKey);      
      close();
    },
  });

  useEffect(() => {
    formik.resetForm();
  }, [show]);

  const getJournalFromID = (id: string) => {
    return journals.find(journal => journal.id === id)
  }

  const getError = (errors: any) => {
    for (let error in errors) {
      return <div style={{ color: "red" }}>{errors[error]}</div>;
    }
  };

  return (
    <>
      <Modal show={show} onHide={close}>
        <Modal.Header closeButton>
          <Modal.Title>Add Task</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Col>
            <Form.Row>
              <Form.Control
                name="type"
                value={formik.values.type}
                onChange={formik.handleChange}
                as="select"
              >
                <option value={undefined} selected disabled={formik.dirty}>
                  Pick A Type...
                </option>
                <option value={TaskType.awareness}>Awareness</option>
                <option value={TaskType.journaling}>Journaling</option>
                <option value={TaskType.lesson}>Lesson</option>
              </Form.Control>
            </Form.Row>
          </Col>

          {formik.values.type === "awareness" && (
            <Col>
              <Form.Row>
                <Form.Label>Awareness Activity</Form.Label>
                <Form.Control
                  name="id"
                  value={formik.values.id}
                  onChange={formik.handleChange}
                  as="select"
                >
                  <option
                    value={undefined}
                    selected
                    disabled={formik.values.id !== ""}
                  >
                    Pick a Awareness Activity...
                  </option>
                  {activities.map((activity, index) => {
                    return <option value={index}>{activity.key}</option>;
                  })}
                </Form.Control>
              </Form.Row>
            </Col>
          )}

          {formik.values.type === "journaling" && (
            <Col>
              <Form.Row>
                <Form.Label>Journal Display Name</Form.Label>
                <Form.Control
                  name="id"
                  value={formik.values.id}
                  onChange={formik.handleChange}
                  as="select"
                >
                  <option
                    value={undefined}
                    selected
                    disabled={formik.values.displayName !== ""}
                  >
                    Pick a Journal Task...
                  </option>
                  {journals.filter(journal => !journal.notAssignableToTask).map(journal => {
                    return <option value={journal.id}>{journal.displayName}</option>;
                  })}
                </Form.Control>
              </Form.Row>
            </Col>
          )}

          {formik.values.type === "lesson" && (
            <>
              <Col>
                <Form.Row>
                  <Form.Label>Chapter</Form.Label>
                  <Form.Control
                    name="chapter"
                    value={formik.values.chapter}
                    onChange={formik.handleChange}
                    as="select"
                  >
                    <option
                      value={undefined}
                      selected
                      disabled={formik.values.chapter !== ""}
                    >
                      Pick a Chapter...
                    </option>
                    {chapters.map((chapter) => {
                      return (
                        <option value={chapter.num}>
                          Ch {chapter.num}. {chapter.title}
                        </option>
                      );
                    })}
                  </Form.Control>
                </Form.Row>
              </Col>
              {formik.values.chapter && (
                <Col>
                  <Form.Row>
                    <Form.Label>Lesson</Form.Label>
                    <Form.Control
                      name="lesson"
                      value={formik.values.lesson}
                      onChange={formik.handleChange}
                      as="select"
                    >
                      <option
                        value={undefined}
                        selected
                        disabled={formik.values.lesson !== ""}
                      >
                        Pick a Lesson...
                      </option>
                      {lessons.map((lesson, index) => {
                        let chapterId = Number(formik.values.chapter);
                        if (lesson.chNum === chapterId) {
                          return (
                            <option value={index}>
                              {lesson.title}
                            </option>
                          );
                        }
                      })}
                    </Form.Control>
                  </Form.Row>
                </Col>
              )}
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Col>{getError(formik.errors)}</Col>
          <Button onClick={() => close()} variant="secondary">
            Close
          </Button>
          <Button
            onClick={() => formik.submitForm()}
            variant="success"
            disabled={!formik.dirty || !formik.isValid}
          >
            Save changes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
