import { firestore } from "firebase";
import { Formik, Form as FormikForm, useField } from "formik";
import React from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import * as Yup from 'yup';
import { Activity } from '@swing-therapeutics/swingcore/dist/models/content/Activity';
import * as uuid from "uuid";
import { isNil, omitBy } from "lodash";
import { FormikSubmitButton, FormikTextField, FormikToggle } from "../../FormikFields";
import FormikSelectAudio from "../../FormikFields/Constructed/FormikSelectAudio";

interface AwarenessModalProps {
  show: boolean;
  close: Function;
  programKey: string;
  activity: Activity;
}

const AwarenessModal: React.FC<AwarenessModalProps> = ({
  show,
  close,
  programKey,
  activity,
}) => {
  return (
    <Modal show={show} onHide={close}>
      <Formik
        // activity.unlockedByDefault is undefined sometimes which doesnt play well with react input fields
        // Bc setting the prop `value={undefined}` in an input tells react that the component is uncontrolled
        // Changing the value to `true` turns the component into a controlled component
        // Not recommened to switch between controlled and uncontrolled
        initialValues={{ ...activity, unlockedByDefault: activity?.unlockedByDefault ?? false }}
        enableReinitialize={true}
        validationSchema={FormSchema}
        onSubmit={async (values, actions) => {
          values.activity_type = "awareness";
          values.programKey = programKey;
          await firestore()
            .collection("programs")
            .doc(programKey)
            .collection("awarenessActivities")
            .doc(values.key)
            .set(omitBy(values, isNil));
          close();
          actions.setSubmitting(false);
        }}
        validateOnChange={false}
      >
        <FormikForm role="form" style={{ width: '100%' }}>
          <Modal.Header closeButton>
            <Modal.Title>Edit</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col xs={7}>
                <FormikTextField
                  name="title"
                  label="Title"
                  placeHolder="Title"
                />
              </Col>
              <Col xs={5}>
                <FormikTextField
                  name="key"
                  label="Key"
                  placeHolder="Key"
                />
              </Col>
            </Row>
            <FormikTextField
              name="description"
              label="Description"
              placeHolder="Description..."
              multiLine={true}
            />
            <FormikTextField
              name="image_filename"
              label="Image filename"
              placeHolder="Image filename"
            />
            <FormikTextField
              name="audio_filename"
              label="Local Audio filename"
              desc="Old version of audio filename that points to audio in app bundle, old versions of the app use this. If this is changed to new audio and the audio does not exist in the app bundle, the audio will not be played"
            />
            <FormikSelectAudio
              name="remote_audio_filename"
              label="Remote Audio filename"
              desc="New remote audio filename that points to audio stored outside of app, new versions of the app use this"
              program={programKey}
            />
            <UUIDField />
            <FormikToggle
              name="unlockedByDefault"
              label="Unlocked By Default"
            />
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={() => close()} variant="secondary">
              Close
            </Button>
            <FormikSubmitButton label="Save changes" />
          </Modal.Footer>
        </FormikForm>
      </Formik>
    </Modal>
  );
};

export default AwarenessModal;

const UUIDField: React.FC = () => {
  const [_fieldInput, _fieldMeta, fieldHelpers] = useField('uuid');

  return (
    <Row style={{ alignItems: 'flex-end' }}>
      <Col xs={9}>
        <FormikTextField
          name="uuid"
          label="UUID"
          placeHolder="uuid"
          disabled={true}
        />
      </Col>
      <Col xs={3}>
        <Form.Group>
          <Button
            onClick={() => {
              fieldHelpers.setValue(uuid.v4());
            }}
          >
            Generate
          </Button>
        </Form.Group>
      </Col>
    </Row>
  )
}

const FormSchema = Yup.object().shape({
  title: Yup.string().required('Title Required'),
  key: Yup.string().required('Key Required'),
  description: Yup.string().required('Description Required'),
  image_filename: Yup.string().trim('The image filename may not have leading or trailing whitespaces.').strict(true).required('Image filename Required'),
  audio_filename: Yup.string().required('Audio filename Required'),
  remote_audio_filename: Yup.string().required('Remote audio filename Required'),
  uuid: Yup.string().required('uuid Required'),
})
