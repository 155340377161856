import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { Registration } from "../../Model/Registration";
import { Subject } from "../../Model/Subject";
import { User } from "../../Model/User";
import JSONTree from "react-json-tree";
import * as yup from "yup";
import { humanReadableIds } from "human-readable-ids";
import { Button, Card, Col, Spinner, Form, Row } from "react-bootstrap";
import { firestore } from "../../firebase";
import moment from "moment";

interface SelectOption {
  label: string;
  value: string;
}

const RegForm = (props: any) => {
  let { registration, email, presetValues, handleSuccess } = props;

  const [regStatus, setRegStatus] = useState<"" | 'registering' | 'error' | 'complete'>("");
  const [updatedReg, setUpdatedReg] = useState<Registration>();
  const [updatedUser, setUpdatedUser] = useState<User>();
  const [updatedSubject, setUpdatedSubject] = useState<Subject>();
  const [sites, setSites] = useState<SelectOption[]>([]);
  const [programs, setPrograms] = useState<SelectOption[]>([]);
  const [cohorts, setCohorts] = useState<SelectOption[]>([]);
  const [studies, setStudies] = useState<SelectOption[]>([]);
  const jsonTheme = {
    scheme: "chalk",
    author: "chris kempson (http://chriskempson.com)",
    base00: "transparent",
    base01: "#333333",
    base02: "#555555",
    base03: "#777777",
    base04: "#999999",
    base05: "#bbbbbb",
    base06: "#dddddd",
    base07: "#ffffff",
    base08: "#ff4136",
    base09: "#ff851b",
    base0A: "#ffdc00",
    base0B: "#2ecc40",
    base0C: "#7fdbff",
    base0D: "#0074d9",
    base0E: "#b10dc9",
    base0F: "#85144b",
  };

  useEffect(() => {
    (async () => {
      // Fetch medrio sites
      // TODO: Eventually all sites will be in one place
      const medrioSitesSnap = await firestore.collection(`medrio/sites/available`).get();
      const medrioSites: SelectOption[] = [];
      for (const siteSnap of medrioSitesSnap.docs) {
        const siteData = siteSnap.data();
        medrioSites.push({
          label: siteData.siteName ?? siteSnap.id,
          value: siteSnap.id,
        })
      }

      const programsSnap = await firestore.collection(`programs`).get();
      const programSites: SelectOption[] = [];
      for (const progSnap of programsSnap.docs) {
        const progData = progSnap.data();
        programSites.push({
          label: progData.name || progData.id,
          value: progSnap.id,
        })
      }

      const cohortsSnap = await firestore.collection(`cohorts`).get();
      const cohorts: SelectOption[] = [];
      for (const cohortSnap of cohortsSnap.docs) {
        const cohortData = cohortSnap.data();
        cohorts.push({
          label: cohortData.name || cohortData.id,
          value: cohortSnap.id,
        })
      }

      const studiesSnap = await firestore.collection(`studies`).get();
      const studies: SelectOption[] = [];
      for (const studySnap of studiesSnap.docs) {
        const studyData = studySnap.data();
        studies.push({
          label: studyData.name || studyData.id,
          value: studySnap.id,
        })
      }

      setStudies(studies)
      setCohorts(cohorts)
      setSites(medrioSites);
      setPrograms(programSites);
    })()
  }, [])

  let initialValues = {
    firstName: registration?.firstName,
    lastName: registration?.lastName,
    zendeskID: registration?.zendeskID
      ? registration.zendeskID
      : "ZD-" + humanReadableIds.random(),
    email: registration?.email ?? email ?? "",
    subjectID: registration?.subjectID ?? humanReadableIds.random(),
    site: registration?.site ?? "",
    role: registration?.role ?? "patient",
    study: registration?.study ?? "003",
    cohort: registration?.cohort ?? "test",
    activeProgram: registration?.activeProgram ?? "tempoACT",
    arm: registration?.arm ?? "",
  };

  if (presetValues) {
    initialValues = { ...initialValues, ...presetValues };
  }

  const { errors, touched, ...formik } = useFormik({
    initialValues,
    validationSchema: yup.object().shape({
      email: yup.string().required("Email required"),
      firstName: yup.string().required("First name required"),
      lastName: yup.string().required("Last name required"),
      subjectID: yup.string().required("Subject ID required"),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      try {
        setRegStatus("registering");
        let reg = new Registration({
          email: values.email.toLowerCase(),
          subjectID: values.subjectID,
          cohort: values.cohort,
          role: values.role,
          study: values.study,
          zendeskID: values.zendeskID,
          activeProgram: values.activeProgram,
          site: values.site,
          firstName: values.firstName,
          lastName: values.lastName,
          arm: values.arm,
          createdBy: registration?.createdBy,
          created: registration?.created,
        });

        const results = await reg.registerUser();
        reg.persist();
        setUpdatedReg(reg);

        setUpdatedSubject(results.subject);
        if (results.user) {
          // @ts-ignore
          setUpdatedUser(results.user);
        }

        setSubmitting(false);
        setRegStatus("complete");
        if (handleSuccess) {
          handleSuccess({
            registration: reg,
            user: results.user,
            subject: results.subject,
          });
        }
      } catch (error) {
        setRegStatus('error');
        console.error(error);
      }
    },
  });

  return (
    <Row className={"justify-content-center"}>
      <Col xs={11}>
        {["", "error"].includes(regStatus) && (
          <Form onSubmit={formik.handleSubmit}>
            <br></br>

            <Form.Row>
              <Form.Group as={Col} xs={12} controlId="email">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  readOnly={registration?.email || presetValues?.email}
                  type="email"
                  name="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  placeholder="Enter email"
                  isInvalid={!!errors.email && !!touched.email}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.email}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group as={Col} xs={12} md={6} controlId="firstName">
                <Form.Label>First Name</Form.Label>
                <Form.Control
                  readOnly={presetValues?.firstName}
                  type="text"
                  name="firstName"
                  value={formik.values.firstName}
                  onChange={formik.handleChange}
                  placeholder="First Name"
                  isInvalid={!!errors.firstName && !!touched.firstName}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.firstName}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group as={Col} xs={12} md={6} controlId="lastName">
                <Form.Label>Last Name</Form.Label>
                <Form.Control
                  type="text"
                  name="lastName"
                  readOnly={presetValues?.lastName}
                  value={formik.values.lastName}
                  onChange={formik.handleChange}
                  placeholder="Last Name"
                  isInvalid={!!errors.lastName && !!touched.lastName}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.lastName}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group as={Col} xs={12} md={6} controlId="subjectID">
                <Form.Label>Subject ID</Form.Label>
                <Form.Control
                  readOnly={
                    (registration && registration.subjectID) ||
                    presetValues?.subjectID
                  }
                  type="text"
                  name="subjectID"
                  value={formik.values.subjectID}
                  onChange={formik.handleChange}
                  placeholder="Subject ID"
                  isInvalid={!!errors.subjectID && !!touched.subjectID}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.subjectID}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group as={Col} xs={12} md={6} controlId="zendeskID">
                <Form.Label>Zendesk ID</Form.Label>
                <Form.Control
                  type="text"
                  name="zendeskID"
                  value={formik.values.zendeskID}
                  onChange={formik.handleChange}
                  readOnly={presetValues?.zendeskID}
                  isInvalid={!!errors.zendeskID && !!touched.zendeskID}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.zendeskID}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group as={Col} xs={12} controlId="activeProgram">
                <Form.Label>
                  <strong>Active Program</strong>
                </Form.Label>
                <Form.Control
                  name="activeProgram"
                  disabled={presetValues && presetValues.activeProgram}
                  className="custom-select custom-select-large"
                  value={formik.values.activeProgram}
                  onChange={formik.handleChange}
                  as="select"
                >
                  <option></option>
                  {programs.map((program) => (
                    <option value={program.value} key={program.value}>{program.label}</option>
                  ))}
                </Form.Control>
              </Form.Group>

              <Form.Group as={Col} xs={12} md={6} controlId="study">
                <Form.Label>Study</Form.Label>
                <Form.Control
                  name="study"
                  value={formik.values.study}
                  onChange={formik.handleChange}
                  disabled={presetValues?.study}
                  className="custom-select custom-select-large"
                  as="select"
                >
                  <option></option>
                  {studies.map((study) => (
                    <option value={study.value} key={study.value}>{study.label}</option>
                  ))}
                </Form.Control>
              </Form.Group>

              <Form.Group as={Col} xs={12} md={6} controlId="site">
                <Form.Label>Study Site</Form.Label>
                <Form.Control
                  name="site"
                  value={formik.values.site}
                  onChange={formik.handleChange}
                  disabled={presetValues?.site}
                  as="select"
                >
                  <option value={""}></option>
                  <option value={"default"}>default</option>
                  {sites.map((site) => (
                    <option value={site.value} key={site.value}>{site.label}</option>
                  ))}
                </Form.Control>
              </Form.Group>

              <Form.Group as={Col} xs={12} md={6} controlId="role">
                <Form.Label>Role</Form.Label>
                <Form.Control
                  name="role"
                  value={formik.values.role}
                  onChange={formik.handleChange}
                  className="custom-select custom-select-large"
                  disabled={presetValues?.role}
                  as="select"
                >
                  <option>patient</option>
                  <option>admin</option>
                  <option>superuser</option>
                </Form.Control>
              </Form.Group>

              <Form.Group as={Col} xs={12} md={6} controlId="cohort">
                <Form.Label>Cohort</Form.Label>
                <Form.Control
                  name="cohort"
                  value={formik.values.cohort}
                  onChange={formik.handleChange}
                  className="custom-select custom-select-large"
                  disabled={presetValues?.cohort}
                  as="select"
                >
                  <option></option>
                  {cohorts.map((cohort) => (
                    <option value={cohort.value} key={cohort.value}>
                      {cohort.label}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>

              <Form.Group as={Col} xs={12} md={6} controlId="site">
                <Form.Label>Group</Form.Label>
                <Form.Control
                  name="arm"
                  value={formik.values.arm}
                  onChange={formik.handleChange}
                  disabled={presetValues?.arm}
                  as="select"
                >
                  <option value={""}>None</option>
                  <option value={"ACT-weekly-insights"}>
                    A1 ACT Weekly Insights
                  </option>
                  <option value={"ACT-daily-insights"}>
                    A2 ACT Daily Insights
                  </option>
                  {/* <option value={"ST"}>ST</option> */}
                  {/* <option value={"ACT-no-insights"}>ACT No Insights</option> */}
                </Form.Control>
              </Form.Group>
            </Form.Row>

            <Button
              disabled={formik.isSubmitting}
              variant="primary"
              type="submit"
              onClick={() => {
                formik.handleSubmit();
              }}
            >
              {formik.isSubmitting ? (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                <>Submit</>
              )}
            </Button>
            {
              regStatus === 'error' &&
                <h6 className='text-warning mt-3'>Error registering user</h6>
            }
          </Form>
        )}
        {regStatus === "registering" && (
          <Col>
            <h1>Registering</h1>
          </Col>
        )}
        {regStatus === "complete" && (
          <Col>
            <h1>Done</h1>
            <Row>
              <Col xs={4}>
                <Card>
                  <Card.Header className="bg-success">
                    {" "}
                    Updated Registration
                  </Card.Header>
                  <Card.Body>
                    <p>
                      <JSONTree
                        data={updatedReg}
                        theme={jsonTheme}
                        invertTheme={false}
                      />
                    </p>
                  </Card.Body>
                </Card>
              </Col>
              <Col xs={4}>
                <Card>
                  <Card.Header className="bg-success">
                    {" "}
                    Updated Subject
                  </Card.Header>
                  <Card.Body>
                    <p>
                      <JSONTree
                        data={updatedSubject}
                        theme={jsonTheme}
                        invertTheme={false}
                      />
                    </p>
                  </Card.Body>
                </Card>
              </Col>
              <Col xs={4}>
                <Card>
                  <Card.Header className={updatedUser ? "bg-success" : ""}>
                    {" "}
                    Updated User
                  </Card.Header>
                  <Card.Body>
                    <p>
                      {updatedUser ? (
                        <JSONTree
                          data={updatedUser}
                          theme={jsonTheme}
                          invertTheme={false}
                        />
                      ) : (
                        "No user to update"
                      )}
                      ;
                    </p>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
        )}
      </Col>
    </Row>
  );
};

export default RegForm;
