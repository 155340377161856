import React, { useEffect, useMemo, useState } from "react";
import {
  Route,
  RouteComponentProps,
} from "react-router-dom";
import { firestore } from "../../../firebase";
import User from "../../../Model/Usert";
import AdminNavbar from "../../AdminNavbar";
import { Col, Container, Row, Nav } from "react-bootstrap";
import {
  faAlignLeft,
  faAward,
  faBook,
  faBusAlt,
  faCalendarDay,
  faClinicMedical,
  faExclamation,
  faList,
  faNetworkWired,
  faRainbow,
  faRegistered,
  faTable,
  faTheaterMasks,
} from "@fortawesome/free-solid-svg-icons";
import { Program } from "../../Content/Program";
import { useCollectionClassData } from "../../../utils/Hooks";
import { getProgramsCollection } from "@swing-therapeutics/swingcore/dist/utils/queries";
import "../userStyles.css";
import UserUsageTable from "./Sections/UserUsageTable";
import UserUsageByDayTable from "./Sections/UserUsageByDayTable";
import UserSessionsTable from "./Sections/UserSessionsTable";
import UserPromptsTable from "./Sections/UserPromptsTable";
import UserDevices from "./Sections/UserDevices";
import UserTasksTable from "./Sections/UserTasksTable";
import UsersSurveysTable from "./Sections/UsersSurveysTable";
import UserEventsTable from "./Sections/UsersEventsTable";
import UserValueList from "./Sections/UserValueList";
import UserJournalsTable from "./Sections/UserJournalsTable";
import UserReg from "./Sections/UserReg";
import UserOverview from "./Sections/UserOverview";
import UserProgram, { UserProgramList } from "./Sections/UserProgram";
import NavLinker from "../../NavLinker";
import { Workflow } from "../../../Model/Workflow";
import WorkflowView from "./workflows/WorkflowView";
import WorkflowOverview from "./workflows/WorkflowOverview";
import { useCollectionDataOnce } from "react-firebase-hooks/firestore";
import { keyBy } from "lodash";

interface PageParams {
  uid: string;
}

const UserDetail: React.FC<RouteComponentProps<PageParams>> = ({
  match,
}) => {
  const [user, setUser] = useState<User>();
  const [programs, setPrograms] = useCollectionClassData(
    Program,
    getProgramsCollection(match.params.uid)
  );
  const [workflows] = useCollectionClassData(
    Workflow,
    firestore.collection(`users/${match.params.uid}/workflows`)
  );
  const [programTemplates, programTemplatesLoading] = useCollectionDataOnce(firestore.collection('programs'));
  const programTemplatesById = useMemo(() => keyBy(programTemplates || [], 'id'), [programTemplates]);

  useEffect(() => {
    const subscriber = firestore
      .collection("users")
      .doc(match.params.uid)
      .onSnapshot((snapshot) => {
        const u = User.fromFirestore(snapshot);
        setUser(u);
      });

    return subscriber;
  }, [match.params.uid]);

  return (
    <>
      <AdminNavbar />
      <div className={"album bg-light"}>
        <Container fluid={true} style={{ backgroundColor: "white" }}>
          <Row>
            <Col xs={2} className={"sidebar"}>
              <div className={"sidebar-sticky"}>
                <Nav className={"flex-column "}>
                  <NavLinker
                    baseUrl={match.url}
                    route=""
                    label="Overview"
                    icon={faClinicMedical}
                  />
                  <NavLinker
                    baseUrl={match.url}
                    route="/registration"
                    label="Registration"
                    icon={faRegistered}
                  />
                  {
                    workflows.length > 0 &&
                    <NavLinker
                      baseUrl={match.url}
                      route="/workflows"
                      label="Workflows"
                      icon={faNetworkWired}
                    >
                      {workflows.sort((a, b) => {
                        // Sort ext to bottom of list
                        if (a.workflowID.includes('ext') && !b.workflowID.includes('ext')) {
                          return 1;
                        }
                        if (b.workflowID.includes('ext') && !a.workflowID.includes('ext')) {
                          return -1;
                        }
                        // Alphabetize if same workflow type
                        return a.workflowID > b.workflowID ? 1 : -1;
                      }).map((workflow) => {

                        return (
                          <NavLinker
                            subLink={true}
                            key={workflow.docPath}
                            baseUrl={match.url + "/workflows"}
                            route={"/" + workflow.workflowID}
                            label={workflow.workflowName}
                            icon={faAward}
                          />
                        )
                      })}
                    </NavLinker>
                  }
                  {
                    programs.length > 0 &&
                    <NavLinker
                      baseUrl={match.url}
                      route="/programs"
                      label="Programs"
                      icon={faTheaterMasks}
                    >
                      {programs.map((program: Program) => {
                        // Some programs returned have a "null" id?
                        if (!program.id || program.id === "null") return null;
                        return (
                          <NavLinker
                            subLink={true}
                            key={program.key}
                            baseUrl={match.url + "/programs"}
                            route={"/" + program.id}
                            label={(programTemplatesById[program.id] as unknown as any)?.name || program.name}
                            state={{
                              program,
                              programName: (programTemplatesById[program.id] as unknown as any)?.name || program.name
                            }}
                            icon={faBook}
                          />
                        );
                      })}
                    </NavLinker>
                  }
                  {/* Remaining tabs require user to have active program, 
                  which a user completing surveysaurus does not */}
                  {
                    user?.activeProgram &&
                    <>
                      {(user?.activeProgram === "tempo1" ||
                        user?.activeProgram === "tempoACT") && (
                          <NavLinker
                            baseUrl={match.url}
                            route="/sessions"
                            label="Sessions"
                            icon={faList}
                          />
                        )}
                      <NavLinker
                        baseUrl={match.url}
                        route="/tasks"
                        label="Tasks"
                        icon={faTable}
                      />
                      <NavLinker
                        baseUrl={match.url}
                        route="/surveys"
                        label="Surveys"
                        icon={faBusAlt}
                      />
                      <NavLinker
                        baseUrl={match.url}
                        route="/devices"
                        label="Devices"
                        icon={faRainbow}
                      />
                      <NavLinker
                        baseUrl={match.url}
                        route="/usage"
                        label="App Usage"
                        icon={faCalendarDay}
                      />
                      <NavLinker
                        baseUrl={match.url}
                        route="/journals"
                        label="Journals"
                        icon={faBook}
                      />
                      {(user?.activeProgram === "tempo1" ||
                        user?.activeProgram === "tempoACT") && (
                          <NavLinker
                            baseUrl={match.url}
                            route="/prompts"
                            label="Prompts"
                            icon={faTheaterMasks}
                          />
                        )}
                      <NavLinker
                        baseUrl={match.url}
                        route="/events"
                        label="Events"
                        icon={faExclamation}
                      />
                      <NavLinker
                        baseUrl={match.url}
                        route="/valueentries"
                        label="Value Entries"
                        icon={faAlignLeft}
                      />
                      {user?.study === "004" && (
                        <NavLinker
                          baseUrl={match.url}
                          route="/usageByDate"
                          label="Screening Week App Usage"
                          icon={faCalendarDay}
                        />
                      )}
                      {(user?.activeProgram === "tempo1" ||
                        user?.activeProgram === "tempoACT") && (
                          <NavLinker
                            baseUrl={match.url}
                            route="/act"
                            label="Act Program"
                            icon={faTheaterMasks}
                          />
                        )}
                    </>
                  }
                </Nav>
              </div>
            </Col>
            <Col xs={"10"} className={"main-content dashboard-container"}>
              <Row className={"dashboard-content"}>
                <Col>
                  <Row className="dashboard-heading">
                    <Col>
                      <Row>
                        <h1>
                          <small>{user?.firstName} {user?.lastName}</small>
                        </h1>
                      </Row>
                      <Row>
                        <h4>
                          <small>{user?.email}</small>
                        </h4>
                      </Row>
                    </Col>
                  </Row>
                  {user &&
                    <>
                      <Route
                        path={match.url}
                        exact
                        render={() =>
                          <UserOverview user={user} programs={programs} />
                        }
                      />
                      <Route
                        path={match.url + "/usage"}
                        render={() =>
                          <UserUsageTable user={user} programID={null} />
                        }
                      />
                      {/* This route isnt on old UserDetailPage.jsx? */}
                      <Route
                        path={match.url + "/usageByDay"}
                        render={() => (
                          <UserUsageByDayTable user={user} />
                        )}
                      />
                      <Route
                        path={match.url + "/registration"}
                        render={() =>
                          <UserReg user={user} />
                        }
                      />
                      <Route
                        path={match.url + "/programs/:programID"}
                        exact
                        render={(props) =>
                          <UserProgram {...props} user={user} redirect={match.url} />
                        }
                      />
                      <Route
                        path={match.url + "/programs"}
                        exact
                        render={() =>
                          <UserProgramList programs={programs} user={user} linkBaseUrl={match.url + "/programs"} />
                        }
                      />
                      <Route
                        path={match.url + "/sessions"}
                        render={() =>
                          <UserSessionsTable user={user} programID={user.activeProgram} />
                        }
                      />
                      <Route
                        path={match.url + "/prompts"}
                        render={() =>
                          <UserPromptsTable user={user} programID={user.activeProgram} />
                        }
                      />
                      <Route
                        path={match.url + "/journals"}
                        render={() =>
                          <UserJournalsTable user={user} programID={user.activeProgram} />
                        }
                      />
                      <Route
                        path={match.url + "/devices"}
                        render={() =>
                          <UserDevices user={user} />
                        }
                      />
                      <Route
                        path={match.url + "/tasks"}
                        render={() =>
                          <UserTasksTable user={user} programID={user.activeProgram} />
                        }
                      />
                      <Route
                        path={match.url + "/surveys"}
                        render={() =>
                          <UsersSurveysTable subjectID={user.uid} />
                        }
                      />
                      <Route
                        path={match.url + "/events"}
                        render={() =>
                          <UserEventsTable uid={user.uid} />
                        }
                      />
                      <Route
                        path={match.url + "/valueentries"}
                        render={() =>
                          <UserValueList user={user} />
                        }
                      />
                      <Route
                        path={match.url + "/workflows"}
                        exact
                        render={() =>
                          <WorkflowOverview user={user} />
                        }
                      />
                      {
                        workflows.map((workflow) => {
                          return (
                            <Route
                              path={`${match.url}/workflows/${workflow.workflowID}`}
                              key={workflow.workflowID}
                              render={(props) =>
                                <WorkflowView {...props} workflow={workflow} user={user} />
                              }
                            />
                          )
                        })
                      }
                    </>
                  }
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default UserDetail;
