import AdminNavbar from "../AdminNavbar";
import {useDocument} from "react-firebase-hooks/firestore";
import {firestore} from "../../firebase";
import React from "react";
import {Container} from "react-bootstrap";
import UserGraph from "./UserGraph";
import {User} from "../../Model/User";

const UserGraphPage = (props) => {
    let id = "LeHBHirzSRdjm9JIo1iktB1ljFI3";
    const [user, userLoading, userError] = useDocument(firestore.doc("/users/" + id), {
        snapshotListenOptions: {includeMetadataChanges: true},
    });

    return (

        <>{userError && <strong>Error: {JSON.stringify(userError)}</strong>}
            {userLoading && <span>Document: Loading...</span>}
            {user && (<>
                <AdminNavbar/>
                <Container>
                    <UserGraph user={new User(user)} title={"DanceOff"}></UserGraph>
                </Container>
            </>)

            }
        </>);
}


export default UserGraphPage
