import React from 'react';
import { ErrorMessage, useField } from 'formik'
import ErrorMessageRender from './ErrorMessageRender';

interface FormikToggleProps {
  name: string;
  label?: string;
  desc?: string;
  allowLabelClick?: boolean;
  inLine?: boolean;
  disabled?: boolean;
}

// Must be a child of a Formik Form component
const FormikToggle: React.FC<FormikToggleProps> = ({ name, label, desc, allowLabelClick = false, inLine = false, disabled = false }) => {
  const [fieldInfo, _fieldMeta, fieldHelpers] = useField(name);

  const handleToggle = () => {
    fieldHelpers.setValue(!fieldInfo.value);
  }

  return (
    <div className={`form-group ${allowLabelClick && !disabled ? 'hoverable' : ''}`} onClick={() => { allowLabelClick && !disabled && handleToggle() }}>
      {
        label &&
        <label className='mb-0'>{label}</label>
      }
      {
        desc &&
        <small className='form-text text-muted'>{desc}</small>
      }
      {
        (label || desc) && !inLine && // Spacer for the label or desc
        <div className='mb-2' />
      }
      {
        inLine && // Spacer for inline label or desc
        <span className='mx-1'/>
      }
      <input type='checkbox' disabled={disabled} className={disabled ? '' : 'hoberable'} checked={fieldInfo.value} onChange={(event) => fieldHelpers.setValue(event.target.checked)} />
      <ErrorMessage name={name} render={ErrorMessageRender} />
    </div>
  )
}

export default FormikToggle;
