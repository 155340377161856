import React from "react";
import { firestore } from "../../firebase";
import { Table } from "react-bootstrap";
import { useCollection } from "react-firebase-hooks/firestore";
import LoadingScreen from "../LoadingScreen";

const FIQRTable = (props) => {
  let { subjectID } = props;

  const [tasks, tasksLoading, tasksError] = useCollection(
    firestore.collection("subjects").doc(subjectID).collection("FIQR"),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  const getTableRow = (r) => {
    const data = r.data().results[0];

    return (
      <tr key={r.id}>
        <td>{data.score}</td>
        <td>{data.function_score}</td>
        <td>{data.impact_score}</td>
        <td>{data.symptom_score}</td>
      </tr>
    );
  };

  return (
    <>
      {tasksError && <strong>Error: {JSON.stringify(tasksError)}</strong>}
      {tasksLoading && <LoadingScreen></LoadingScreen>}
      {tasks && (
        <Table responsive striped>
          <thead>
            <tr>
              <th>Score</th>
              <th>Function Score</th>
              <th>Impact Score</th>
              <th>Symptom Score</th>
            </tr>
          </thead>
          <tbody>{tasks.docs.map((u) => getTableRow(u))}</tbody>
        </Table>
      )}
    </>
  );
};

export default FIQRTable;
