import { SurveyBayForm } from "@swing-therapeutics/surveybay/dist/models/SurveyBayForm";
import { Formik } from "formik";
import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import * as yup from "yup";
import { FormikSelect, FormikTextField } from "../../../FormikFields";
import { firestore } from "../../../../firebase";

interface FormInfoEditProps {
  form: SurveyBayForm;
  // Flags if user can edit the key
  allowInfoEdit: boolean;
}

interface SelectOption {
  key:string;
  name: string;
  pro:string;
  pro_component: string;
  Type: string;
}

const FormInfoEdit = React.forwardRef<any, FormInfoEditProps>(({ form, allowInfoEdit }, ref) => {

  const [epros, setEpros] = useState<SelectOption[]>([]);

  useEffect(() => {
    (async () => {
      const eproSnap = await firestore.collection("tempest")
      .doc("eproKeys")
      .collection("prePopKeys")
      .get();
      const programSites: SelectOption[] = [];
      console.log('eprodata', eproSnap);
      for(const progSnap of eproSnap.docs){
        const progData = progSnap.data();
        console.log('data', progData);
        programSites.push({
          key: progData.key,
          name: progData.name,
          pro: progData.pro,
          pro_component: progData.pro_component,
          Type: progData.Type,
        })
      }

      setEpros(programSites);
    })()
  }, [])

  return (
    <Formik
      initialValues={{
        key: form.key ?? '',
        name: form.name ?? '',
        formType: form.formType ?? 'standard',
        Type: form.Type ?? '',
        pro_component: form.pro_component ?? '',
        pro: form.pro ?? '',
      }}
      // Dont do anything on submit, values are pulled from ref if they are valid
      onSubmit={() => { }}
      validateOnChange={false}
      validationSchema={FormSchema}
      innerRef={ref}
    >
      {
        props => (
          <Form>
            <FormikSelect
              name="formType"
              label="Form type"
              options={[
                { label: 'Standard', value: 'standard' },
                { label: 'ePRO', value: 'EPRO' },
              ]}
              onChange={() => {
                props.setFieldValue('key', '');
                props.setFieldValue('name', '');
                props.setFieldValue('pro', '');
                props.setFieldValue('pro_component', '');
                props.setFieldValue('Type', '');
              }}
              disabled={!allowInfoEdit}
            />
            {
              props.values.formType === 'EPRO' ?
                <>
                  <FormikTextField
                    name="key"
                    label="Key"
                    placeHolder="Key"
                    disabled={true}
                  />
                  <FormikSelect
                    name="name"
                    label="Name"
                    options={epros.map((eProOption) => {
                      return {
                        label: eProOption.name,
                        value: eProOption.name,
                      }
                    })}
                    onChange={(newValue: string) => {
                      if (newValue === '') {
                        props.setFieldValue('key', '');
                        props.setFieldValue('name', '');
                        props.setFieldValue('pro', '');
                        props.setFieldValue('pro_component', '');
                        props.setFieldValue('Type', '');
                      }
                      else {
                        // Set the corresponding other values
                        const ePro = epros.find((ePro) => ePro.name === newValue);
                        props.setFieldValue('key', ePro.key);
                        props.setFieldValue('pro', ePro.pro);
                        props.setFieldValue('pro_component', ePro.pro_component);
                        props.setFieldValue('Type', ePro.Type);
                      }
                    }}
                    disabled={!allowInfoEdit}
                  />
                  <FormikTextField
                    name="Type"
                    label="Type"
                    placeHolder="Type"
                    disabled={true}
                  />
                  <FormikTextField
                    name="pro"
                    label="Pro"
                    placeHolder="Pro"
                    disabled={true}
                  />
                  <FormikTextField
                    name="pro_component"
                    label="Pro component"
                    placeHolder="Pro component"
                    disabled={true}
                  />
                </>
                :
                <>
                  <FormikTextField
                    name="key"
                    label="Key"
                    placeHolder="Key"
                    disabled={!allowInfoEdit}
                  />
                  <FormikTextField
                    name="name"
                    label="Name"
                    placeHolder="Name"
                    disabled={!allowInfoEdit}
                  />
                  <FormikSelect
                    name="Type"
                    label="Type"
                    options={[
                      { label: 'full_PRO', value: 'full_PRO' },
                      { label: 'subscale', value: 'subscale' },
                      { label: 'subscale_mod', value: 'subscale_mod' },
                    ]}
                    disabled={!allowInfoEdit}
                  />
                  <FormikTextField
                    name="pro"
                    label="Pro"
                    placeHolder="Pro"
                    disabled={!allowInfoEdit}
                  />
                  <FormikTextField
                    name="pro_component"
                    label="Pro component"
                    placeHolder="Pro component"
                    disabled={!allowInfoEdit}
                  />
                </>
            }
          </Form>
        )
      }
    </Formik>
  )
})

export default FormInfoEdit;

const FormSchema = yup.object().shape({
  key: yup.string().matches(/^\S*$/, "No spaces allowed").required("Required"),
  name: yup.string().required("Required"),
  formType: yup.string().required("Required"),
})

