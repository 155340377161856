import { faChartLine, faMobileAlt, faPersonBooth, faTicketAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useMemo, useState } from "react";
import { Card, Col, ListGroup, Row } from "react-bootstrap";
import Medrio from "../../../Model/Medrio";
import LoadingScreen from "../../LoadingScreen";
import moment from 'moment';

interface MedrioOverviewProps {
  medrio: Medrio;
}

const colPaddingBottom = 25;
const cardStyle = {
  height: '100%',
}

const MedrioOverview: React.FC<MedrioOverviewProps> = ({ medrio }) => {
  return (
    <Col xs={12}>
      <Row>
        <Col sm={12} md={6} style={{ paddingBottom: colPaddingBottom }}>
          <Card border="warning" style={cardStyle}>
            <Card.Header className="bg-warning text-white">
              <h3>
                <FontAwesomeIcon
                  icon={faPersonBooth}
                />
                {" "}About
              </h3>
            </Card.Header>
            <ListGroup variant="flush">
              <ListGroup.Item>
                <Row>
                  <Col>
                    <h6>Medrio Subject ID</h6>
                    <span>{medrio?.FormEventData.MedrioSubjectID}</span>
                  </Col>
                  <Col>
                    <h6>Subject Status</h6>
                    <span>{medrio?.FormEventData.SubjectStatus}</span>
                  </Col>
                  <Col>
                    <h6>Visit Name</h6>
                    <span>
                      {medrio?.FormEventData.VisitName}
                    </span>
                  </Col>
                </Row>
              </ListGroup.Item>
              <ListGroup.Item>
                <Row>
                  <Col>
                    <h6>Study Title</h6>
                    <span>{medrio?.FormEventData.StudyTitle}</span>
                  </Col>
                  <Col>
                    <h6>Event Created</h6>
                    <span>
                      {moment(medrio.timestamp.toDate()).format("DD MMM, yyyy hh:mm:ss a")}
                    </span>
                  </Col>
                  <Col>
                    <h6>Event type</h6>
                    <span>{medrio?.FormEventData.EventType}</span>
                  </Col>
                </Row>
              </ListGroup.Item>
            </ListGroup>
          </Card>
        </Col>
        <Col sm={12} md={6} style={{ paddingBottom: colPaddingBottom }}>
          <Card border="primary" style={cardStyle}>
            <Card.Header className="bg-primary text-white">
              <h3>
                <FontAwesomeIcon
                  icon={faTicketAlt}
                />
                {" "}Form Details
              </h3>
            </Card.Header>
            <ListGroup variant="flush">
              <ListGroup.Item>
                <Row>
                  <Col>
                    <h6>Entry Date</h6>
                    <span>{medrio?.FormEventData.DataEntryDate}</span>
                  </Col>
                  <Col>
                    <h6>Entry Username</h6>
                    <span>{medrio?.FormEventData.DataEntryUserName}</span>
                  </Col>
                </Row>
              </ListGroup.Item>
              <ListGroup.Item>
                <Row>
                  <Col>
                    <h6>Form Name</h6>
                    <span>{medrio?.FormEventData.FormName}</span>
                  </Col>
                  <Col>
                    <h6>Form Completion Status</h6>
                    <span>{medrio?.FormEventData.FormCompletionStatus}</span>
                  </Col>
                </Row>
              </ListGroup.Item>
            </ListGroup>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col sm={12} md={6} style={{ paddingBottom: colPaddingBottom }}>
          <Card border="info" style={cardStyle}>
            <Card.Header className="bg-info text-white">
              <h3>
                <FontAwesomeIcon
                  icon={faMobileAlt}
                />
                {" "}Study
              </h3>
            </Card.Header>
            <ListGroup variant="flush">
              <ListGroup.Item>
                <Row>
                  <Col>
                    <h6>Site External ID</h6>
                    <span>
                      {medrio?.FormEventData.SiteExternalID}
                    </span>
                  </Col>
                  <Col>
                    <h6>Site Name</h6>
                    <span>{medrio?.FormEventData.SiteName}</span>
                  </Col>
                </Row>
              </ListGroup.Item>
            </ListGroup>
          </Card>
        </Col>
      </Row>
    </Col>
  );
};

export default MedrioOverview;
