import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { Col, Table } from 'react-bootstrap';
import { auth, firestore, functions } from '../../../../firebase';
import User from '../../../../Model/Usert';
import ConfirmButton from '../../ConfirmButton';

const ScreenWeekAppUsage: React.FC = () => {
  const [users, setUsers] = useState<User[]>([]);

  useEffect(() => {
    let unsub: () => void;
    (async () => {
      unsub = firestore.collection('users')
        .where('activeProgram', '==', 'screenWeek005')
        .onSnapshot((snap) =>
          setUsers(snap.docs.map((userDocRef) => User.fromFirestore(userDocRef)))
        );
    })()
    return unsub;
  }, [])

  return (
    <Col xs={12}>
      <p>
        Users in program 'screenWeek005'
      </p>
      <h6 className='muted'>
        'Passed screen week' indicates that an automated email has been sent to the user's study site notifying the site that the user has passed 005 screening week.
      </h6>
      <Table responsive className="table-sm">
        <thead>
          <tr>
            <th>Subject ID</th>
            <th>Days</th>
            <th>Days app opened</th>
            <th>Passed screen week</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {users.map((user) => (
            <UserRow user={user} key={user.uid} />
          ))}
        </tbody>
      </Table>
    </Col>
  )
}

export default ScreenWeekAppUsage;

interface UserRowProps {
  user: User;
}

const failProgram = 'screenWeekFail005';

const UserRow: React.FC<UserRowProps> = ({ user }) => {
  const [days, setDays] = useState<number | null>(null);
  const [appOpens, setAppOpens] = useState<number | null>(null);
  const [loadingAction, setLoadingAction] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    (async () => {
      setAppOpens(null);
      // Calculation for app opens live in cloud functions but is exposed via callable functions
      const calculate005AppOpens = functions.httpsCallable('calculate005AppOpens');
      const result = await calculate005AppOpens({ userUID: user.uid, timeZone: user.timezone });
      if (result.data.status === 'SUCCESS') {
        setAppOpens(result.data.data.appOpens);
      } else {
        console.error(result.data);
        setError('Error calculating days app opened');
      }
    })()
  }, [user.uid, user.timezone, user.subjectID])

  useEffect(() => {
    setDays(null);
    /*
    * Days will be the number of days since the user was created.
    * First program the user is in is `screenWeek005` so when a user first logs into app
    * they will be in `screenWeek005` program. Therefore the created field indicates how long 
    * the user has been in `screenWeek005` in `screenWeek005`
    */
    const now = moment();
    const created = moment(user.created);
    const days = now.diff(created, 'days');
    setDays(days);
  }, [user.created])

  const handleFailClick = useCallback(async () => {
    setLoadingAction(true);
    setError('');
    const registerUserInProgram = functions.httpsCallable('registerUserInProgram');
    // Register the user into the fail program
    const result = await registerUserInProgram({ userUID: user.uid, programKey: failProgram, requestingIdentifier: auth.currentUser.email });
    if (result.data.status !== 'SUCCESS') {
      console.error(result.data);
      setError(result.data.error?.message ?? `Error registering user in ${failProgram}`);
    }
    setLoadingAction(false)
  }, [user.uid])

  return (
    <tr>
      <td>
        {user.subjectID}
      </td>
      <td>
        {days ?? <LoadingText title='Calculating' />}
      </td>
      <td>
        {appOpens ?? <LoadingText title='Calculating' />}
      </td>
      <td>
        {user.passed005ScreenWeek ? '✅' : ''}
      </td>
      <td>
        <ConfirmButton confirmText="Confirm move" className="btn-danger" onClick={handleFailClick} disabled={loadingAction}>
          {
            loadingAction ? `Moving to ${failProgram}` : `Move to ${failProgram}`
          }
        </ConfirmButton>
        {
          error && <h6 className='text-warning mt-1'>{error}</h6>
        }
      </td>
    </tr >
  )
}

interface LoadingTextProps {
  title?: string;
}

// Active loading text
const LoadingText: React.FC<LoadingTextProps> = ({ title = 'Loading' }) => {
  const [text, setText] = useState(title);

  useEffect(() => {
    const interval = setInterval(() => {
      setText((prevText) => {
        if (prevText.length > title.length + 3) {
          return title;
        }
        return prevText + '.';
      })
    }, 200);

    return () => {
      clearInterval(interval)
    }
  }, [title])

  return (
    <>
      {text}
    </>
  )
}
