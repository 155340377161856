import React, { useState } from "react";
import { Col, Row, Table } from "react-bootstrap";
import { firestore, Timestamp } from "../../../firebase";
import LoadingScreen from "../../LoadingScreen";
import { NudgeActionType } from "@swing-therapeutics/swingcore/dist/models/nudge/Nudger";
import moment from "moment";
import { useMemo } from "react";
import { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleDown, faArrowCircleUp } from "@fortawesome/free-solid-svg-icons";

interface NudgeLogRowProps {
  log: INudgerLog;
}

const NudgeLogRow: React.FC<NudgeLogRowProps> = ({ log }) => {
  const [expanded, setExpanded] = useState(false);
  const timeRan = useMemo(() => {
    return log.timeStamp.toDate();
  }, [log.timeStamp])
  const logHasError = log.results.some((result) => !!result.error);

  return (
    <>
      <tr className="hoverablerow" onClick={() => setExpanded((prevState) => !prevState)}>
        <td>
          {
            expanded &&
            <FontAwesomeIcon icon={faArrowCircleDown} style={{ marginRight: 5 }} />
          }
          {moment(timeRan).format("hh:mm A MM/DD/YY")}
        </td>
        <td>
          {log.results.length}
        </td>
        <td />
        <td>
          {logHasError.toString()}
        </td>
      </tr>
      {
        expanded &&
        <>
          <tr>
            {
              logHasError ?
                <td colSpan={4}>
                  <b>Error</b>
                </td>
                :
                <>
                  <td>
                    <b>Subject ID</b>
                  </td>
                  <td>
                    <b>Local time</b>
                  </td>
                  <td>
                    <b>Message</b>
                  </td>
                  <td>
                    <b># of devices</b>
                  </td>
                </>
            }
          </tr>
          {log.results.map((result, index) => {
            // User can have multiple devices which makes multiple messages
            // Each message will be with different deviceIDs
            const message = result.messages[0];
            return (
              <tr key={index}>
                {
                  result.error ?
                    <td colSpan={4}>
                      {result.error}
                    </td>
                    :
                    <>
                      <td>
                        {message.subjectID}
                      </td>
                      <td>
                        {message.usersLocalTime}
                      </td>
                      <td>
                        {message.body}
                      </td>
                      <td>
                        {result.messages.length}
                      </td>
                    </>
                }
              </tr>
            )
          })}
          <tr>
            <td colSpan={4}>
              <FontAwesomeIcon icon={faArrowCircleUp} style={{ marginRight: 5 }} />
            </td>
          </tr>
        </>
      }
    </>
  )
}

interface NudgeLogsProps {
  nudgeID: string;
}

const NudgeLogs: React.FC<NudgeLogsProps> = ({ nudgeID }) => {
  const [logs, setLogs] = useState<INudgerLog[]>();

  useEffect(() => {
    const unsub = firestore.collection("systemevents")
      .where("nudgerID", "==", nudgeID)
      .onSnapshot((snap) => {
        const logs: INudgerLog[] = []
        for (const logDoc of snap.docs) {
          const logData = logDoc.data() as INudgerLog;
          logs.push(logData);
        }
        logs.sort((a, b) => b.timeStamp.toDate().getTime() - a.timeStamp.toDate().getTime());
        setLogs(logs);
      });

    return unsub;
  }, [nudgeID])

  return (
    <Col>
      <Row>
        {
          logs ?
            <Table striped responsive className="table-center-rows">
              <thead>
                <tr>
                  <th>Time Ran</th>
                  <th>Users Nudged</th>
                  <th />
                  <th>Errors</th>
                </tr>
              </thead>
              <tbody>
                {logs.map((log, index) => {
                  return (
                    <NudgeLogRow key={index} log={log} />
                  )
                })}
              </tbody>
            </Table>
            :
            <LoadingScreen />
        }
      </Row>
    </Col>
  )
}

export default NudgeLogs;

interface NudgerMessages {
  FCMToken: string;
  actionType: NudgeActionType;
  body: string;
  deviceID: string;
  email: string;
  eventGroup: string;
  nudgerID: string;
  scheduledFor: Timestamp;
  status: string;
  subjectID: string;
  timeActionPerformed: string;
  usersLocalTime: string;
  title: string;
  triggerMethod: string;
  uid: string;
  url: string;
}

interface NudgerResults {
  error: null | string;
  eventGroup: string;
  forReals: boolean;
  messages: NudgerMessages[];
}

interface INudgerLog {
  category: string;
  eventGroup: string;
  message: string;
  nudgerID: string;
  results: NudgerResults[];
  timeStamp: Timestamp;
  type: string;
}
