import { storage } from "firebase";
import { useCallback, useEffect, useState } from "react"
import type { Result, StorageError, StorageHook, StorageHookArgs } from "./types";

interface State { result: Result | null, loading: boolean, error: StorageError };
const defaultState: State = {
  result: null,
  loading: true,
  error: false,
}

interface AudioStorageHookArgs extends StorageHookArgs {
  type?: 'generic' | 'all' | 'voice_actor';
}

/*
* Return an array of file references to audio files in a storage bucket
*/
export const useAudioFiles: StorageHook<AudioStorageHookArgs> = ({ program, type = 'all' }) => {
  const [state, setState] = useState<State>({ ...defaultState });

  const getSetFiles = useCallback(async () => {
    try {
      // Following the remote audio file format ${program_key}/assets/audio/{audio_filename}.mp3
      // Or files without voice actors ${program_key}/assets/audio/voice_actor_audio/${audio_name}/${voice_actor}.mp3
      const path = `gs://${process.env.REACT_APP_FIREBASE_REMOTE_ASSET_BUCKET}/${program}/assets/audio`;
      let result: Result = {};
      if (['all', 'voice_actor'].includes(type)) {
        const voiceActorAudio = await storage().refFromURL(path + '/voice_actor_audio').listAll();
        // Need to select prefixes here becuase voice actor audio is a path to a 'folder' in the bucket
        result.voiceActorAudio = voiceActorAudio.prefixes;
      }
      if (['all', 'generic'].includes(type)) {
        const genericAudio = await storage().refFromURL(path).listAll();
        result.genericAudio = genericAudio.items;
      }
      setState({
        loading: false,
        result,
        error: false,
      })
    } catch (error) {
      console.error(error);
      setState({
        result: null,
        loading: false,
        error,
      })
    }
  }, [program, type])

  useEffect(() => {
    getSetFiles();
  }, [getSetFiles])

  return [state.result, state.loading, state.error];
}
