import React, { useState } from "react";
import { Celebration } from "@swing-therapeutics/swingcore/dist/models/content/Celebration";
import { CelebrationEditor } from "./CelebrationEditor";
import { Route, useHistory, useRouteMatch } from "react-router-dom";
import { CelebrationList } from "./CelebrationList";

interface CelebrationViewerProps {
  programKey: string;
}

export const CelebrationViewer = ({ programKey }: CelebrationViewerProps) => {
  const [selectedCelebration, setSelectedCelebration] = useState<Celebration>();

  const match = useRouteMatch("/programs/:programKey/celebrations");
  const history = useHistory();

  const hideEditor = () => {
    setSelectedCelebration(undefined);
    history.goBack();
  };
  
  const showEditor = (celebration?: Celebration) => {
    setSelectedCelebration(celebration);
    if (celebration) {
      history.push(match.url + "/edit/" + celebration.id)
    } else {
      history.push(match.url + "/edit/")
    }
  };

  return (
    <>
      <Route exact path={match.url}>
        <CelebrationList programKey={programKey} showEditor={showEditor} />
      </Route>
      <Route exact path={match.url + "/edit/"}>
        <CelebrationEditor
          hideEditor={hideEditor}
          programKey={programKey}
        />
      </Route>
      <Route exact={false} path={match.url + "/edit/:id"}>
        <CelebrationEditor
          hideEditor={hideEditor}
          programKey={programKey}
          selectedCelebration={selectedCelebration}
        />
      </Route>
    </>
  );
};
