import React from "react";
import { ErrorMessage, Field } from "formik"
import ErrorMessageRender from "./ErrorMessageRender";
import { InputGroup } from "react-bootstrap";

interface FormikTextFieldProps {
  name: string;
  label?: string;
  placeHolder?: string;
  desc?: string;
  autoComplete?: boolean;
  multiLine?: boolean;
  disabled?: boolean;
  type?: "number" | "date" | "time" | "color" | "radio";
  prepend?: string;
  append?: string;
}

// Must be a child of a Formik Form component
const FormikTextField: React.FC<FormikTextFieldProps> = ({ name, label, placeHolder, desc, autoComplete = false, type, multiLine = false, disabled = false, prepend, append }) => {

  return (
    <div className="form-group">
      {
        label &&
        <label className="mb-0">{label}</label>
      }
      {
        desc &&
        <small className="form-text text-muted">{desc}</small>
      }
      {
        (label || desc) && // Spacer for the label or desc
        <div className="mb-2" />
      }
      <InputGroup>
        {
          prepend !== undefined &&
          <InputGroup.Text style={{ borderRadius: '0.25rem 0 0 0.25rem' }}>
            {prepend}
          </InputGroup.Text>
        }
        <Field disabled={disabled} type={type ? type : undefined} className="form-control" name={name} placeholder={placeHolder} autoComplete={autoComplete ? "on" : "off"} as={multiLine ? 'textarea' : undefined} />
        {
          append !== undefined &&
          <InputGroup.Text style={{ borderRadius: '0 0.25rem 0.25rem 0' }}>
            {append}
          </InputGroup.Text>
        }
      </InputGroup>
      <ErrorMessage name={name} render={ErrorMessageRender} />
    </div>
  )
}

export default FormikTextField;
