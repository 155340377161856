import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import AdminNavbar from "../AdminNavbar";
import { firestore } from "../../firebase";
import PrescriptionDetail from "./PrescriptionDetail";

const Prescription = () => {
  const { id } = useParams<{ id: string }>();

  const [prescription, setPrescription] = useState<any>();

  useEffect(() => {
    const unsubscribe = 
    firestore
      .collection("prescriptions")
      .doc(id)
      .onSnapshot((doc) => {
        setPrescription(doc.data());
    });

    return () => unsubscribe();
  }, []);
  
  return (
    <>
      <AdminNavbar/>
      {prescription &&
        <PrescriptionDetail id={id} prescription={prescription}/>
      }
    </>
  )
};

export default Prescription;