import { useFormik } from "formik";
import React, { useState } from "react";
import { Button, Col, Form, Modal } from "react-bootstrap";
import { ContentMode, ContentType, Program } from "../Program";
import LoadingScreen from "../../LoadingScreen";
import { useCollectionDataOnce } from "react-firebase-hooks/firestore";
import { firestore } from "../../../firebase";

interface ProgramEditorProps {
  show: boolean;
  close: () => void;
  programToEdit?: Program;
  programs: Program[];
}

export const ProgramEditor = ({
  show,
  close,
  programToEdit,
  programs,
}: ProgramEditorProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [pages = [], pagesLoading = false] = useCollectionDataOnce(
    programToEdit?.id ? firestore.collection('programs').doc(programToEdit.id).collection('pages') : null
  ) as unknown as [any[], boolean];

  const isNewProgram = !programToEdit;
  const preexistingIDs = programs.map((program) => program.id);

  const modalTitle = isNewProgram ? "Create Program" : "Edit Program";

  const closeModal = () => {
    if (isLoading) {
      return;
    }
    close();
  };

  const {values, touched, errors, handleChange, submitForm} = useFormik({
    initialValues: { ...programToEdit },
    enableReinitialize: true,
    validationSchema: Program.validationSchema(preexistingIDs, programToEdit?.id),
    onSubmit: async (values) => {
      setIsLoading(true);
      if (values.contentTypeKey !== ContentType.ST) {
        delete values.resourceTabInstructions;
      }

      const newProgram = Program.fromParams(values);
      await newProgram.persist();

      setIsLoading(false);
      closeModal();
      window.alert(`${newProgram.name} saved`);
    },
  });

  return (
    <Modal show={show} onHide={closeModal}>
      <Modal.Header closeButton>
        <Modal.Title>{modalTitle}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {isLoading ? (
          <LoadingScreen />
        ) : (
          <Form>
            {isNewProgram && (
              <Form.Group as={Col} xs={7}>
                <Form.Label>ID</Form.Label>
                <Form.Control
                  name="id"
                  value={values.id}
                  onChange={handleChange}
                  placeholder={"Program ID"}
                  isInvalid={!!errors.id && touched.id}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.id}
                </Form.Control.Feedback>
              </Form.Group>
            )}

            <Form.Group as={Col} xs={7}>
              <Form.Label>Name</Form.Label>
              <Form.Control
                name="name"
                value={values.name}
                onChange={handleChange}
                placeholder={"Name"}
                isInvalid={!!errors.name && touched.name}
              />
              <Form.Control.Feedback type="invalid">
                {errors.name}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} xs={12}>
              <Form.Label>Description</Form.Label>
              <Form.Control
                as="textarea"
                name="description"
                rows={4}
                value={values.description}
                onChange={handleChange}
                placeholder={"Description..."}
                isInvalid={!!errors.description && touched.description}
              />
              <Form.Control.Feedback type="invalid">
                {errors.description}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} xs={7}>
              <Form.Label>Reminder Title</Form.Label>
              <Form.Control
                name="reminderMessage.text"
                value={values.reminderMessage?.text}
                onChange={handleChange}
                placeholder={"Name"}
              />
            </Form.Group>

            <Form.Group as={Col} xs={12}>
              <Form.Label>Reminder Message</Form.Label>
              <Form.Control
                as="textarea"
                name="reminderMessage.title"
                rows={2}
                value={values.reminderMessage?.title}
                onChange={handleChange}
                placeholder={"Reminder Message..."}
              />
            </Form.Group>

            <Form.Group as={Col} xs={12}>
              <Form.Label>Symptom Tracker ID</Form.Label>
              <Form.Control
                name="symptomTrackerID"
                value={values.symptomTrackerID}
                onChange={handleChange}
                placeholder={"Name"}
                isInvalid={
                  !!errors.symptomTrackerID && touched.symptomTrackerID
                }
              />
              <Form.Control.Feedback type="invalid">
                {errors.symptomTrackerID}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} xs={12}>
              <Form.Label>Select Program Type</Form.Label>
              <Form.Control
                name="contentTypeKey"
                value={values.contentTypeKey}
                onChange={handleChange}
                disabled={!isNewProgram}
                as="select"
              >
                <option value={ContentType.ACT}>ACT</option>
                <option value={ContentType.ST}>ST</option>
              </Form.Control>
            </Form.Group>
            
            <Form.Group as={Col} xs={12}>
              <Form.Label>Initial Display State</Form.Label>
              <Form.Control
                name="initialDisplayState"
                value={values.initialDisplayState}
                onChange={handleChange}
                as="select"
              >
                <option value={ContentMode.Content}>content (standard app content)</option>
                <option value={ContentMode.Page}>page (html or url from the page key)</option>
              </Form.Control>
            </Form.Group>

            <Form.Group as={Col} xs={12}>
              <Form.Label>Starting Page Key</Form.Label>
              {pagesLoading ? (
                <span>Loading...</span>
              ) : (
                <Form.Control
                  name="startingPageKey"
                  value={values.startingPageKey}
                  onChange={handleChange}
                  as="select"
                >
                  <option key={"empty-page"} value={null} disabled={!pages.length}>
                    {pages.length ? "" : "No pages to select"}
                  </option>
                  {!!pages.length &&
                    pages.map((page, index) => (
                      <option key={index} value={page.key}>
                        {page.key}
                      </option>
                    ))}
                </Form.Control>
              )}
            </Form.Group>

            {values.contentTypeKey === ContentType.ST && (
              <Col className={"border-top"}>
                <Form.Group as={Col} xs={12}>
                  <Form.Label>Resource Tab Instructions</Form.Label>
                  <Form.Control
                    as="textarea"
                    name="resourceTabInstructions"
                    rows={2}
                    value={values.resourceTabInstructions}
                    onChange={handleChange}
                    placeholder={"Instructions..."}
                    isInvalid={
                      !!errors.resourceTabInstructions &&
                      touched.resourceTabInstructions
                    }
                  />
                </Form.Group>
                <Form.Control.Feedback type="invalid">
                  {errors.resourceTabInstructions}
                </Form.Control.Feedback>
              </Col>
            )}
          </Form>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={closeModal} disabled={isLoading} variant="secondary">
          Close
        </Button>
        <Button onClick={submitForm} disabled={isLoading} variant="success">
          Save changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
