import { Field, Form, Formik, ErrorMessage } from "formik";
import "./AccessCodes.css";
import React, { useEffect, useState } from "react";
import AdminNavbar from "../../AdminNavbar";
import * as Yup from "yup";
import Papa from "papaparse";
import { useAccessCodes } from "../../../hooks/useAccessCodes";
import LoadingScreen from "../../LoadingScreen";
import { firestore } from "../../../firebase";

interface SelectOption {
  label: string;
  value: string;
}

export default function AccessCodes() {
  const { submitting, hideButton, codes, setHideButton, onSubmit } =
    useAccessCodes();
  const [sites, setSites] = useState<SelectOption[]>([]);
  useEffect(() => {
    (async () => {
      const medrioSitesSnap = await firestore.collection(`medrio/sites/available`).get();
      const medrioSites: SelectOption[] = [];
      for (const siteSnap of medrioSitesSnap.docs) {
        const siteData = siteSnap.data();
        medrioSites.push({
          label: siteData.siteName ?? siteSnap.id,
          value: siteSnap.id,
        })
      }
      setSites(medrioSites);
    })()
  }, [])

  return (
    <div>
      <AdminNavbar />
      {!codes.length && !submitting && (
        <Formik
          initialValues={{
            expiration: 90,
            count: "",
            program: "",
            active: false,
            site: "swing care",
          }}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(true);
            onSubmit(values);
            console.log(values);
          }}
          validationSchema={Yup.object().shape({
            expiration: Yup.string().required("Expiration is required"),
            count: Yup.number().required("Count is required").max(20),
            program: Yup.string().required("Program is required"),
            active: Yup.string().required("Active is required"),
            site: Yup.string().required("Site is required"),
          })}
        >
          <Form className="form">
            <h1>Access Codes</h1>
            <label>Expiration Days</label>
            <Field
              className="field"
              as="select"
              name="expiration"
              type="number"
            >
              <option value="">Select</option>
              {Array.from(Array(Math.ceil(390 / 30)).keys()).map((i) => {
                const days = i * 30;
                if (i === 0) return null;
                return (
                  <option key={days} value={days}>
                    {days}
                  </option>
                );
              })}
              <option value={365}>365</option>
            </Field>
            <ErrorMessage name="expiration" component="div" />
            <label>Count</label>
            <Field className="field" as="select" name="count" type="date">
              <option value="">Select</option>
              {Array.from(Array(20).keys()).map((i) => {
                return (
                  <option key={i + 1} value={i + 1}>
                    {i + 1}
                  </option>
                );
              })}
            </Field>
            <ErrorMessage name="count" component="div" />
            <label>Program</label>
            <Field className="field" as="select" name="program" type="text">
              <option value="">Select Program</option>
              <option value="stanzaACT1">stanzaACT1</option>
              <option value="tempoACT3">tempoACT3</option>
            </Field>
            <ErrorMessage name="program" component="div" />
            <label>Active</label>
            <Field className="field" as="select" name="active" type="boolean">
              <option disabled value={"true"}>True</option>
              <option value={"false"}>False</option>
            </Field>
            <ErrorMessage name="active" component="div" />
            <label>Site</label>
            <Field className="field" as="select" name="site" type="text">
              {sites.map((site) => (
                <option value={site.value} key={site.value}>{site.label}</option>
              ))}
            </Field>
            <button className="submitButton" type="submit">
              Submit
            </button>
          </Form>
        </Formik>
      )}

      {submitting && <LoadingScreen />}

      {/* once codes are defined show the codes and an option to download as csv file */}
      {codes.length > 0 && (
        <div className="accessCodesContainer">
          <h1 className="accessCodeHeader">Access Codes</h1>
          <table className="table">
            <thead>
              <tr>
                <th>Access Code</th>
                <th>Expires</th>
                <th>Created At</th>
                <th>Created By</th>
                <th>Program</th>
                <th>Active</th>
                <th>Site</th>
                <th>PatientIdNum</th>
              </tr>
            </thead>
            <tbody>
              {codes.map((code) => {
                return (
                  <tr key={code.id}>
                    <td>{code.accessCode}</td>
                    <td>{code.expires}</td>
                    <td>
                      {code.createdAt.toLocaleString("en-GB", {
                        month: "long",
                        day: "numeric",
                        year: "numeric",
                      })}
                    </td>
                    <td>{code.createdBy}</td>
                    <td>{code.program}</td>
                    <td>{code.active === true ? "Yes" : "No"}</td>
                    <td>{code.site}</td>
                    <td>{code.patientIdNum}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          {!hideButton && (
            <button
              className="downloadButton"
              onClick={() => {
                const csv = Papa.unparse(codes);
                const blob = new Blob([csv], { type: "text/csv" });
                const url = URL.createObjectURL(blob);
                const link = document.createElement("a");
                link.href = url;
                link.download = "accessCodes.csv";
                link.click();
                setHideButton(true);
              }}
            >
              Download as CSV
            </button>
          )}
          {hideButton && (
            <p className="thankYou">
              Thank you for downloading please check your downloads folder for
              the file.
            </p>
          )}
        </div>
      )}
    </div>
  );
}
