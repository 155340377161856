import React from "react";
// import GraphTemplate from "../Graphs/GraphTemplate";
// import GraphTemplateEPROsA from "../../Graphs/GraphTemplateEPROsA";
import GraphTemplateIndivs004B from "./GraphTemplateIndivs004B";
import { Col, Container, Row,Jumbotron } from "react-bootstrap";

import "../surveyAdherence.css";
import AdminNavbar from "../../AdminNavbar";

// import GraphTemplateEPROsA from "../../Graphs/GraphTemplateEPROsA";
import AnotherReportsListSidebar004B from "./AnotherReportsListSidebar004B";


const Graphs004B = () => {
  return (
    <>
      <AdminNavbar />
      <Jumbotron>
            <Container fluid={true}>
                <Row>
                    <Col>
                        <h1>004 ACT Cohort 2</h1><div className="TitleCb"></div>
                    </Col>
                </Row>
            </Container>
        </Jumbotron>
      <AnotherReportsListSidebar004B/>
      <Container>
        <div className="mt-4">
          <Row>
            <Col xs={10}>
              <GraphTemplateIndivs004B report={"s13_012"} />
            </Col>
          </Row>
           <Row>
            <Col xs={10}>
              <GraphTemplateIndivs004B report={"s15_002"} />
            </Col>
          </Row>
          <Row>
            <Col xs={10}>
              <GraphTemplateIndivs004B report={"s15_003"} />
            </Col>
          </Row>
          <Row>
            <Col xs={10}>
              <GraphTemplateIndivs004B report={"s15_005"} />
            </Col>
          </Row>
          <Row>
            <Col xs={10}>
              <GraphTemplateIndivs004B report={"s15_006"} />
            </Col>
          </Row>
          <Row>
            <Col xs={10}>
              <GraphTemplateIndivs004B report={"s15_011"} />
            </Col>
          </Row>
          <Row>
            <Col xs={10}>
              <GraphTemplateIndivs004B report={"s15_012"} />
            </Col>
          </Row>
          <Row>
            <Col xs={10}>
              <GraphTemplateIndivs004B report={"s16_001"} />
            </Col>
          </Row>
          <Row>
            <Col xs={10}>
              <GraphTemplateIndivs004B report={"s16_003"} />
            </Col>
          </Row>
          <Row>
            <Col xs={10}>
              <GraphTemplateIndivs004B report={"s16_004"} />
            </Col>
          </Row>
          <Row>
            <Col xs={10}>
              <GraphTemplateIndivs004B report={"s16_005"} />
            </Col>
          </Row>
          {/*
          <Row>
            <Col xs={10}>
              <GraphTemplateEPROsA report={"s10_008"} />
            </Col>
          </Row>
          <Row>
            <Col xs={10}>
              <GraphTemplateEPROsA report={"s11_002"} />
            </Col>
          </Row>
          <Row>
            <Col xs={10}>
              <GraphTemplateEPROsA report={"s12_003"} />
            </Col>
          </Row>
 <Row>
            <Col xs={10}>
              <GraphTemplateEPROsA report={"s12_005"} />
            </Col>
          </Row>
 <Row>
            <Col xs={10}>
              <GraphTemplateEPROsA report={"s12_010"} />
            </Col>
          </Row>
 <Row>
            <Col xs={10}>
              <GraphTemplateEPROsA report={"s12_012"} />
            </Col>
          </Row>
 <Row>
            <Col xs={10}>
              <GraphTemplateEPROsA report={"s13_001"} />
            </Col>
          </Row>
 <Row>
            <Col xs={10}>
              <GraphTemplateEPROsA report={"s13_004"} />
            </Col>
          </Row>
 <Row>
            <Col xs={10}>
              <GraphTemplateEPROsA report={"s14_004"} />
            </Col>
          </Row> */}
        </div>
      </Container>
      
      
    </>
  );
};

export default Graphs004B;
