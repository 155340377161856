import { firestore } from "../firebase";

export interface ReferralGroupFormValues {
  referralGroupKey: string;
  name: string;
  logoURL: string;
  referralGroupEmail: string;
  referralID: string;
}

class ReferralGroup {
  created: Date;
  logoURL: string;
  referralGroupKey: string;
  name: string;
  updated: Date;
  referralGroupEmail: string;
  referralID: string;

  constructor(props: any) {
    this.created = props.created ?? new Date();
    this.logoURL = props.logoURL;
    this.name = props.name;
    this.referralGroupKey = props.referralGroupKey;
    this.updated = props.updated ?? new Date();
    this.referralGroupEmail = props.referralGroupEmail;
    this.referralID = props.referralID;
  }

  static fromFirestore(docRef: any) {
    const data = docRef.data();
    const created = data.created?.toDate();
    const updated = data.updated?.toDate();
    return new ReferralGroup({ ...data, created, updated });
  }

  data() {
    return { ...this };
  }

  getEditableData(): ReferralGroupFormValues {
    return {
      logoURL: this.logoURL,
      referralGroupKey: this.referralGroupKey,
      name: this.name,
      referralGroupEmail: this.referralGroupEmail,
      referralID: this.referralID,
    }
  }

  static getEditableData(): ReferralGroupFormValues {
    return {
      logoURL: "",
      referralGroupKey: "",
      name: "",
      referralGroupEmail: "",
      referralID: "",
    }
  }

  async persist() {
    const data = { ...this };
    // Update the doc, removing created
    data.updated = new Date();
    delete data.created;
    return await firestore.collection("web/surveysaurus/referralGroups")
      .doc(data.referralGroupKey)
      .update(data);
  }

  static async persistNewReferralGroup(values: ReferralGroupFormValues): Promise<ReferralGroup> {
    const referralGroup = new ReferralGroup({ ...values });
    // Make sure created/updated is set
    referralGroup.created = new Date();
    referralGroup.updated = new Date();
    await firestore.collection("web/surveysaurus/referralGroups")
      .doc(referralGroup.referralGroupKey)
      .set(referralGroup.data());
    return referralGroup;
  }

  static async deleteReferralGroup(referralGroupKey: string) {
    await firestore.collection("web/surveysaurus/referralGroups")
      .doc(referralGroupKey)
      .delete();
  }
}

export default ReferralGroup;
