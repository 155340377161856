import { auth, firestore } from "firebase";
import { useFormik } from "formik";
import React, { useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import * as Yup from "yup";
import * as uuid from "uuid";
import { Resource } from "../../Model/Resource";

interface ResourcesFormModalProps {
  show: boolean;
  close: Function;
  programKey: string;
  resourcesLength: number;
  selectedResource?: Resource;
}

export const ResourcesFormModal = ({ show, close, programKey, resourcesLength, selectedResource }: ResourcesFormModalProps) => {
  const formik = useFormik({
    initialValues: {
      order: selectedResource?.order || resourcesLength + 1,
      text: selectedResource?.text || "",
      url: selectedResource?.url || "",
    },
    validationSchema: Yup.object().shape({
      text: Yup.string().trim().required("a text description is required"),
      url: Yup.string()
        .trim()
        .url("invalid url format")
        .required("a url is required"),
    }),
    enableReinitialize: true,
    onSubmit: async (values) => {
      let resource: Resource;
      if (selectedResource) {
        selectedResource.text = values.text;
        selectedResource.url = values.url;
        resource = selectedResource;
        console.log({resource});
      } else {
        resource = new Resource(
          uuid.v4(),
          values.order,
          values.text,
          values.url
        );
      }

      try {
        await resource.persist(programKey);
        window.alert(`resource ${selectedResource ? 'updated' : 'created'} successfully!`);
      } catch (error) {
        console.error(error);
        window.alert(
          "An error occurred when trying to reorder steps. Check the developer console for details."
        );
      } finally {
        close();
      }
    },
  });

  return (
    <Modal show={show} onHide={close}>
      <Modal.Header closeButton>
        <Modal.Title>{`${selectedResource ? 'Edit' : 'Add'} Resource`}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col>
            <Form.Group>
              <Form.Label>Text</Form.Label>
              <Form.Control
                name="text"
                required
                value={formik.values.text}
                onChange={formik.handleChange}
                as="input"
                placeholder="Add text..."
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group>
              <Form.Label>URL</Form.Label>
              <Form.Control
                name="url"
                required
                value={formik.values.url}
                onChange={formik.handleChange}
                as="input"
                placeholder="Add a url..."
              />
            </Form.Group>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        {!formik.isValid && (
          <Col>
            <div style={{ color: "red" }}>
              * {Object.values(formik.errors)[0]}
            </div>
          </Col>
        )}
        <Button onClick={() => close()} variant="outline-secondary">
          Close
        </Button>
        <Button
          disabled={!formik.isValid}
          onClick={() => {
            formik.submitForm();
          }}
          variant="outline-success"
        >
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ResourcesFormModal;