import React, { useState, useEffect } from "react";
import { Form, Col, Row, Container, Button, Modal, Table } from "react-bootstrap";
import { useFormik } from "formik";
import * as yup from "yup";
import { firestore } from "firebase";
import AdminNavbar from "../AdminNavbar";

const Sites = () => {
  let initialValues = {
    siteID: "",
    siteName: "",
    siteEmail: "",
  };

  let validationSchema = yup.object().shape({
    siteID: yup.string().required("ID is required"),
    siteName: yup.string().required("Name is required"),
    siteEmail: yup.string().email("Invalid email format"),
  });

  const {values, errors, touched, handleChange, handleSubmit, resetForm } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      let site = {
        siteName: values.siteName,
        email: values.siteEmail,
      };

      await firestore().collection("medrio/sites/available").doc(values.siteID).set(site);

      window.alert(`${site.siteName} is saved`);
      
      resetForm();
    },
  });

  const deleteSite = async (siteID: string) => {
    await firestore().collection("medrio/sites/available").doc(siteID).delete();
  };

  const [sites, setSites] = useState([]);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const unsubscribe = firestore()
      .collection(`medrio/sites/available`)
      .onSnapshot({ includeMetadataChanges: true }, (collection) => {
        if (collection.docs.length > 0) {
          setSites(collection.docs.map((doc) => {
            const data = doc.data();
            const id = doc.id;
            return { id, ...data };
          }));
        }
      });
    return unsubscribe;
  }, []);

  return (
    <div>
      <AdminNavbar />

      <Modal show={showModal} animation={false}>
        <Modal.Header style={{ display: "flex", justifyContent: "center" }}>
          <Modal.Title>Create Site</Modal.Title>
        </Modal.Header>

        <Modal.Body style={{ display: "flex", justifyContent: "center" }}>
          <Form onSubmit={handleSubmit}>
            <Container>
              <Row>
                <Form.Group as={Col} xs={12} md={6} controlId="siteID">
                  <Form.Label>ID</Form.Label>
                  <Form.Control
                    type="text"
                    name="siteID"
                    placeholder="ID"
                    value={values.siteID}
                    onChange={handleChange}
                    isInvalid={!!errors.siteID && !!touched.siteID}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.siteID}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group as={Col} xs={12} md={6} controlId="siteName">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="siteName"
                    placeholder="Name"
                    value={values.siteName}
                    onChange={handleChange}
                    isInvalid={!!errors.siteName && !!touched.siteName}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.siteName}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>

              <Row>
                <Form.Group as={Col} xs={12} md={6} controlId="siteEmail">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="email"
                    name="siteEmail"
                    placeholder="Email"
                    value={values.siteEmail}
                    onChange={handleChange}
                    isInvalid={!!errors.siteEmail && !!touched.siteEmail}
                  />
                    <Form.Control.Feedback type="invalid">
                    {errors.siteEmail}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
            </Container>
          </Form>
        </Modal.Body>

        <Modal.Footer style={{display: "flex", justifyContent: "center"}}>
          <Button variant="secondary" onClick={() => {setShowModal(false); resetForm();}}>
            Close
          </Button>
          <Button variant="primary" onClick={() => handleSubmit()}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>

      <Container
        fluid={true}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Button
          onClick={() => setShowModal(true)}
          style={{ marginTop: 10, marginBottom: 10 }}
        >
          New Site
        </Button>

        <Col xs={12}>
          <Table>
            <thead key="sites">
              <tr>
                <th>Name</th>
                <th>Email</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {sites.map((site) => (
                <tr key={site.id}>
                  <td>{site.siteName}</td>
                  <td>{site.email}</td>
                  <td>
                      <Button
                        variant="danger"
                        onClick={() => {
                          if (window.confirm(`Are you sure you want to delete ${site.siteName}?`)) {
                            deleteSite(site.id);
                            window.alert(`${site.siteName} deleted`);
                          }
                        }}
                      >
                        Delete
                      </Button>
                    </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Container>
    </div>
  )
}

export default Sites;