import * as Yup from "yup";
import { YupPayCondition } from "./yupPayCondition";

export const FormSchema = Yup.object().shape({
  consentProgram: Yup.string().required("Required"),
  consentFailProgram: Yup.string().required("Required"),
  landingPageKey: Yup.string()
    .min(2, "Too short!")
    .max(15, "Too long!")
    .matches(/^[a-zA-Z0-9_-]*$/, "Only charaters and numbers allowed")
    .required("Required"),
  doneProgram: Yup.string().required("Required"),
  disclaimerText: Yup.string().min(2, "Too Short!"),
  earlyTerminationProgram: Yup.string().required("Required"),
  surveyWeeks: Yup.array().of(
    Yup.object().shape({
      week: Yup.number(),
      surveys: Yup.array().of(Yup.string()),
      daysToCompleteTherapySurvey: Yup.string(),
    })
  ),
  paymentNotifications: Yup.object().shape({
    eligibility: YupPayCondition,
    baseline: YupPayCondition,
    surveyWeeks: Yup.array().of(YupPayCondition),
  }),
  therapyProgram: Yup.string().required("Required"),
  trialID: Yup.string()
    .min(2, "Too Short!")
    .max(30, "Too Long!")
    .required("Required"),
    weekdaySurveyAssignment: Yup.number()
    .min(0, "Must be 0 or greater")
    .max(8, "Must be less than 9"),
})
