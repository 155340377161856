import { ScheduleCallOption } from "@swing-therapeutics/surveybay/dist/types";
import { useField } from "formik";
import React, { useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { FormikSelect, FormikTextField, FormikToggle } from "../../../FormikFields";
import { optionalCalls } from "../../CalendlyCalls";

const ScheduleCallOptions: React.FC = () => {
  const [surveyWeeksInfo] = useField("surveyWeeks");
  const numOfSurveyWeeks = surveyWeeksInfo.value.length;
  const [fieldInfo, _fieldMeta, fieldHelpers] = useField("scheduleCallOptions");
  const [selectedWeek, setSelectedWeek] = useState(1);

  return (
    <Col>
      <h3>Scheduled calls</h3>
      <p>Allow a user to schedule a call after a therapy survey is completed/expired</p>
      <Row>
        <Col xs="auto">
          <Button
            onClick={() => {
              if (fieldInfo[selectedWeek]) return; // Already exists
              const newCallOption: ScheduleCallOption = {
                description: '',
                calendlyCallURL: '',
                eventName: '',
                required: false,
                therapyWeek: selectedWeek,
                title: '',
              }
              const newValue = { ...fieldInfo.value, [selectedWeek]: newCallOption };
              fieldHelpers.setValue(newValue)
            }}
          >
            Add call option
          </Button>
        </Col>
        <Col xs="auto">
          <div className="form-group">
            <select
              className="form-control"
              value={selectedWeek.toString()}
              onChange={(event) => {
                setSelectedWeek(parseInt(event.target.value));
              }}
            >
              {new Array(numOfSurveyWeeks).fill(0).map((_x, index) => {
                return (
                  <option key={index} value={index + 1}>
                    Week {index + 1}
                  </option>
                )
              })}
            </select>
          </div>
        </Col>
      </Row>
      {Object.keys(fieldInfo.value).map((therapyWeek) => {
        const handleDeleteClick = () => {
          const newValue = { ...fieldInfo.value };
          delete newValue[therapyWeek];
          fieldHelpers.setValue(newValue);
        }

        return (
          <CallOption key={therapyWeek} therapyWeek={therapyWeek} onDeleteClick={handleDeleteClick} />
        )
      })}
    </Col>
  )
}

export default ScheduleCallOptions;

interface CallOptionProps {
  therapyWeek: string;
  onDeleteClick: () => void;
}

const CallOption: React.FC<CallOptionProps> = ({ therapyWeek, onDeleteClick }) => {
  const baseField = `scheduleCallOptions[${therapyWeek}]`;
  const [_urlFieldInput, _urlFieldMeta, urlFieldHelpers] = useField(`${baseField}.calendlyCallURL`);

  return (
    <Row className="my-4">
      <Col>
        <Row className="mb-3">
          <Col xs={6}>
            <h4>Therapy week {therapyWeek}</h4>
            <small>
              User will be shown option to schedule call after therapy survey {therapyWeek} is completed or expired.
              If no survey for that week exists then the user will be given the option to schedule a call after a survey would have been assigned for that week.
            </small>
          </Col>
          <Col xs={6}>
            <Row className="justify-content-end">
              <Button variant="danger" onClick={onDeleteClick}>
                X
              </Button>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col xs={4}>
            <FormikTextField
              name={`${baseField}.title`}
              label="Call title"
              desc="Title for the call, displayed to user as 'Schedule call X'"
              placeHolder="Call title"
            />
          </Col>
          <Col xs={4}>
            <FormikSelect
              name={`${baseField}.eventName`}
              label="Calendly event name"
              desc="Exact name of the event from Calendly"
              options={optionalCalls.map((optionalCall) => ({ value: optionalCall.name, label: optionalCall.name }))}
              onChange={(name) => {
                if (name) {
                  // Set the corresponding url for the call
                  const optionalCall = optionalCalls.find((optionalCall) => optionalCall.name === name);
                  urlFieldHelpers.setValue(optionalCall.calendlyURL);
                }
              }}
            />
          </Col>
          <Col xs={4}>
            <FormikToggle
              name={`${baseField}.required`}
              label={`Required call`}
              desc='If selected, user does not have the option to decline call'
              allowLabelClick={true}
            />
          </Col>
          <Col xs={12}>
            <FormikTextField
              name={`${baseField}.description`}
              label="Call description"
              desc="Descriptive text so that purpose of call can be differentiated, displayed to user while scheduling the call"
              placeHolder="Call description"
              multiLine={true}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  )
}
