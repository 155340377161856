import React from "react";
import { firestore } from "../../../../firebase";
import { Badge, Table } from "react-bootstrap";
import { useCollection } from "react-firebase-hooks/firestore";
import LoadingScreen from "../../../LoadingScreen";
import { Task } from "../../../../Model/User";
import moment from "moment";
import { UserSectionProps } from "./UserSectionInterface";

interface TableRowProps {
    task: Task;
}

const TableRow: React.FC<TableRowProps> = ({ task }) => {
    let variant = "primary";
    let durationText = "";
    if (task.status === "completed") {
        variant = "success";
        let started = moment(task.dateStarted);
        let completed = moment(task.dateCompleted);
        let duration = moment.duration(started.diff(completed));
        durationText = duration.humanize();

    } else if (task.status === "not-started") {
        variant = "warning";
    }

    const assignmentID = task.type === "lesson" ? "Lesson " + task.id : task.id;

    return (
        <tr key={task.taskRecordID}>
            <td>{task.taskRecordID}</td>
            <td>{task.type}</td>
            <td>{assignmentID}</td>
            <td>{task.dateStarted && task.dateStarted.toLocaleString()}</td>
            <td> {task.dateCompleted && task.dateCompleted.toLocaleString()}</td>
            <td>{durationText}</td>
            <td>
                <Badge variant={variant}>{task.status}</Badge>{" "}
            </td>
        </tr>
    );
}

const UserTasksTable: React.FC<UserSectionProps> = ({ user, programID }) => {
    const query = programID ? firestore
        .collection("users")
        .doc(user.uid || user.subjectID)  // This component is also used in SubjectsDetail.jsx and id properties are not the same
        .collection("programs")
        .doc(programID)
        .collection("tasks")
        .orderBy("dateStarted", "desc") : firestore.collection('users');
    const [tasks, tasksLoading, tasksError] = useCollection(query, {
        snapshotListenOptions: { includeMetadataChanges: true },
    });

    if (!programID) {
        return <></>;
    }

    return (<>
        {tasksError && <strong>Error: {JSON.stringify(tasksError)}</strong>}
        {tasksLoading && <LoadingScreen />}
        {tasks &&
            <Table responsive striped>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Type</th>
                        <th>Assignment ID</th>
                        <th>Started</th>
                        <th>Completed</th>
                        <th>Duration</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody>
                    {tasks.docs.map((task) =>
                        <TableRow key={task.id} task={new Task(task)} />
                    )}
                </tbody>
            </Table>
        }
    </>);
};

export default UserTasksTable;
