import React, { useState } from "react";
import RichTextEditor from "react-rte";

export type EasyEditMarkupType = "html" | "markdown";

interface EasyEditProps {
  content: string;
  onChange: (value: string) => void,
  markupType: EasyEditMarkupType,
  placeholder?: string,
  disabled?: boolean,
}

const EasyEdit = ({
  content,
  onChange,
  markupType = 'markdown',
  placeholder = "",
  disabled = false,
}: EasyEditProps) => {
  const [markdownContent, setMarkdownContent] = useState(
    content
      ? RichTextEditor.createValueFromString(content, markupType)
      : RichTextEditor.createEmptyValue()
  );

  return (
    <RichTextEditor
      readOnly={disabled}
      as="textarea"
      rows={9}
      style={{ height: "600px" }}
      value={markdownContent}
      onChange={(value) => {
        setMarkdownContent(value);
        if (onChange) {
          onChange(value.toString(markupType));
        }
      }}
      placeholder={placeholder ?? ""}
    />
  );
};

export default EasyEdit;
