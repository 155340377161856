import { firestore } from 'firebase';
import React, { useEffect, useState } from 'react';
import { User } from '../../../../Model/User';
import UserT from '../../../../Model/Usert';
import { useDocumentClassData } from '../../../../utils/Hooks';
import { ValuesCategoryEntry } from '@swing-therapeutics/swingcore/dist/models/values/ValuesCategoryEntry'
import LoadingScreen from '../../../LoadingScreen';
import { Button, Col, Container, Row, Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortAmountDown, faSortAmountUp } from '@fortawesome/free-solid-svg-icons';

interface UserValueListProps {
  user: UserT;
}

interface SingleValue {
  value: string;
  valuesCategoryID: string;
  programKey: string;
  created: any;
}

const UserValueList: React.FC<UserValueListProps> = ({ user }) => {
  const [valuesEntries, setEntries] = useState([] as SingleValue[])
  const [loadingEntries, setLoadingEntries] = useState(false);
  const [, loadingUser] = useDocumentClassData(User, firestore().collection('users').doc(user.uid));
  const [programs] = useState([] as string[]);
  const [filteredPrograms, setFilteredPrograms] = useState([] as string[])
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('desc');

  useEffect(() => {
    if (!loadingEntries) {
      setLoadingEntries(true);
      loadEntries();
    }
  }, [])

  const loadEntries = async () => {
    const entries = [] as SingleValue[];
    const programDocs = await firestore().collection('users').doc(user.uid).collection('programs').get();

    for (const doc of programDocs.docs) {
      const docEntries = await doc.ref.collection('valuesCategoryEntries').get();
      if (docEntries.size > 0) {
        docEntries.docs.forEach(valueDoc => {
          const entry = ValuesCategoryEntry.fromFirestore(valueDoc);

          if (!programs.find(program => program === entry.programKey)) {
            programs.push(entry.programKey);
            filteredPrograms.push(entry.programKey)
          }

          entry.values?.forEach(value => {
            entries.push({
              value,
              valuesCategoryID: entry.valuesCategoryID,
              programKey: entry.programKey,
              created: entry.created,
            })
          })
        })
      }
    }
    setEntries(entries);
    setLoadingEntries(false);
  }

  if (loadingUser || loadingEntries) {
    return <LoadingScreen />
  }

  if (valuesEntries.length === 0) {
    return <div>No values entries for this user</div>
  }

  const finalizedEntries = finalizeEntries(valuesEntries, filteredPrograms, sortDirection)

  const getRowForValue = (singleValue: SingleValue) => (
    <tr>
      <td>{singleValue.value}</td>
      <td>{singleValue.valuesCategoryID}</td>
      <td>{singleValue.programKey}</td>
      <td>{singleValue.created.toLocaleString()}</td>
    </tr>
  )

  const getProgramButton = (key: string) => {
    return (
      <Col xs="auto" className="pb-3">
        <Button
          onClick={() => {
            if (filteredPrograms.includes(key)) {
              setFilteredPrograms(filteredPrograms.filter(programKey => programKey !== key))
            } else {
              setFilteredPrograms([...programs, key])
            }
          }}
          variant={filteredPrograms.includes(key) ? 'primary' : 'secondary'}
        >
          {key}
        </Button>
      </Col>
    )
  }

  return (
    <Container>
      <Row>
        {programs.length > 0 && programs.map(getProgramButton)}
      </Row>
      <Row>
        <Col>
          <Table bordered responsive="xs" className="table-striped">
            <thead>
              <tr>
                <th>Value</th>
                <th>Values Category ID</th>
                <th>Program Key</th>
                <th>
                  <Row className="d-flex justify-content-between">
                    <Col xs="auto">
                      <div>Created</div>
                    </Col>
                    <Col xs="auto">
                      <Button onClick={() => setSortDirection(sortDirection === 'desc' ? 'asc' : 'desc')}>
                        <FontAwesomeIcon icon={sortDirection === 'desc' ? faSortAmountDown : faSortAmountUp} />
                      </Button>
                    </Col>
                  </Row>
                </th>
              </tr>
            </thead>
            <tbody>
              {finalizedEntries.length > 0 && finalizedEntries.map(singleValue => getRowForValue(singleValue))}
            </tbody>
          </Table>
          {finalizeEntries.length === 0 && <div>No entries found</div>}
        </Col>
      </Row>
    </Container>
  )
}

const finalizeEntries = (entries: SingleValue[], filteredPrograms: string[], sortDirection: 'asc' | 'desc'): SingleValue[] => {
  return entries
    .sort((a, b) => sortDirection === 'desc' ? b.created - a.created : a.created - b.created)
    .filter(finalEntry => filteredPrograms.includes(finalEntry.programKey));
}

export default UserValueList;