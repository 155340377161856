import React from "react";
import { Container, Row } from "react-bootstrap";

const NudgingOverView: React.FC = () => {
  return (
    <Container>
      <Row className="mb-3">
        <h3>Overview</h3>
      </Row>
      <Row>
        <p>Hi welcome to the nudging overview page. Not sure what to put on this page yet but we have it!</p>
      </Row>
    </Container>
  );
};

export default NudgingOverView;
