import React, { useCallback, useEffect, useState } from "react";
import { firestore } from "../../../../firebase";
import { Badge, Button, Col, Container, Row, Table } from "react-bootstrap";
import LoadingScreen from "../../../LoadingScreen";
import { UserSectionProps } from "./UserSectionInterface";
import { PlaylistProgress } from "../../../../Model/Playlist";
import { useCollectionClassData } from "../../../../utils/Hooks";
import { useHistory } from "react-router";

interface TableRowProps {
  programID: string;
  playlistProgress: PlaylistProgress;
  highlight: boolean; // Flags if the row should be hightlighted
  onPlaylistProgressClick: (playlistProgress: PlaylistProgress) => void;
}

const TableRow: React.FC<TableRowProps> = ({ programID, playlistProgress, highlight, onPlaylistProgressClick }) => {
  const [loading, setLoading] = useState(true);
  const history = useHistory();

  const getPlaylistInfo = useCallback(async () => {
    setLoading(true);
    await playlistProgress.loadPlaylist(programID);
    if (playlistProgress.playlistExists) {
      await playlistProgress.playlist.loadSessions();
    }
    setLoading(false);
  }, [programID, playlistProgress])

  useEffect(() => {
    if (!playlistProgress.playlistExists) {
      getPlaylistInfo()
    }
    else {
      setLoading(false);
    }
  }, [getPlaylistInfo, playlistProgress])

  const handleRowClick = useCallback(() => {
    onPlaylistProgressClick?.(playlistProgress);
  }, [playlistProgress, onPlaylistProgressClick])

  const handleViewPlaylistClick = useCallback((event) => {
    event.stopPropagation();
    // Go to program's playlist page
    history.push(`/programs/${programID}/playlists/${playlistProgress.playlist.id}`);
  }, [programID, playlistProgress])

  return !loading ?
    playlistProgress.playlistExists ?
      <tr onClick={handleRowClick} className="hoverablerow" style={highlight ? { borderLeft: '10px solid #a8d2a8' } : undefined}>
        <td>
          {playlistProgress.playlist?.name}
        </td>
        <td>
          {playlistProgress.currentSession}
        </td>
        <td>
          {playlistProgress.completedSessions} / {playlistProgress.playlist?.sessions?.length}
        </td>
        <td>
          {playlistProgress.playlistStarted.toDateString()}
        </td>
        <td>
          {
            playlistProgress.playlistCompleted ?
              <Badge variant="success">
                completed {playlistProgress.playlistCompleted.toDateString()}
              </Badge>
              :
              <Badge variant="primary">in-progress</Badge>
          }
        </td>
        <td>
          <Button size="sm" onClick={handleViewPlaylistClick}>
            View playlist page
          </Button>
        </td>
      </tr>
      :
      <tr>
        <td>Playlist does not exists</td>
        <td>{playlistProgress.currentSession}</td>
        <td>{playlistProgress.completedSessions} / ?</td>
        <td>
          {playlistProgress.playlistStarted.toDateString()}
        </td>
        <td>
          {
            playlistProgress.playlistCompleted ?
              <Badge variant="success">
                completed {playlistProgress.playlistCompleted.toDateString()}
              </Badge>
              :
              <Badge variant="primary">in-progress</Badge>
          }
        </td>
        <td></td>
      </tr>
    :
    <tr>
      <td>Loading...</td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
    </tr>
}

enum ViewTypes {
  PlaylistProgressList,
  PlaylistView,
}

interface ViewData {
  viewType: ViewTypes;
  selectedIndex: number
  data?: PlaylistProgress;
}

const defaultView: ViewData = {
  viewType: ViewTypes.PlaylistProgressList,
  selectedIndex: -1,
}

const UserPlaylistTable: React.FC<UserSectionProps> = ({ user, programID }) => {
  const playlistProgressQuery = firestore
    .collection("users")
    .doc(user.uid)
    .collection("programs")
    .doc(programID)
    .collection("playlistProgress")
  const [progressCollection, progressCollectionLoading] = useCollectionClassData(PlaylistProgress, playlistProgressQuery);
  const [view, setView] = useState<ViewData>(defaultView);

  const handlePlaylistProgressClick = useCallback((playlistProgress: PlaylistProgress, selectedIndex: number) => {
    setView({ viewType: ViewTypes.PlaylistView, selectedIndex, data: playlistProgress });
  }, [])

  return (
    <Container fluid>
      {progressCollectionLoading ?
        <LoadingScreen />
        :
        <>
          <Row>
            <Table responsive striped className="table-center-rows">
              <thead>
                <tr>
                  <th>Playlist Name</th>
                  <th>Current Session</th>
                  <th>Sessions Completed</th>
                  <th>Started</th>
                  <th>Status</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {progressCollection
                  .slice()
                  .sort((a, b) => {
                    // Place newer playlists started at top of table
                    return b.playlistStarted.getTime() - a.playlistStarted.getTime()
                  })
                  .map((playlistProgress, index) => {
                    const highlight = index === view.selectedIndex;
                    return (
                      <TableRow key={index}
                        programID={programID}
                        highlight={highlight}
                        playlistProgress={playlistProgress}
                        onPlaylistProgressClick={(playlistProgress) => handlePlaylistProgressClick(playlistProgress, index)} />
                    )
                  })}
              </tbody>
            </Table>
          </Row>
          {
            view.viewType === ViewTypes.PlaylistView &&
            <>
              <Row style={{ marginTop: 30 }}>
                <Col xs={6}>
                  <h3>{view.data.playlist.name} sessions</h3>
                </Col>
                <Col xs={6} className="d-flex justify-content-end">
                  <Button style={{ margin: "10px 0px 15px" }} onClick={() => setView(defaultView)}>
                    Close
                                    </Button>
                </Col>
              </Row>
              <Row>
                <Table responsive striped>
                  <thead>
                    <tr>
                      <th>Order</th>
                      <th>ID</th>
                      <th>Previous ID</th>
                      <th>Next ID</th>
                      <th>Tasks</th>
                    </tr>
                  </thead>
                  <tbody>
                    {view.data.playlist.sessions
                      .slice()
                      .sort((a, b) => {
                        return a.num - b.num
                      })
                      .map((session, index) => {
                        const currentSession = session.id === view.data.currentSession;
                        return (
                          <tr key={index} style={currentSession ? { borderLeft: '10px solid #a8d2a8' } : undefined}>
                            <td>
                              {session.num}
                              {currentSession &&
                                <>
                                  <br />
                                  (Current sesssion)
                                </>
                              }
                            </td>
                            <td>{session.id}</td>
                            <td>{session.previousSessionID}</td>
                            <td>{session.nextSessionID}</td>
                            <td>
                              <ul>
                                {session.tasks.map((task: any, index: number) => {
                                  const key = `row-${session.id}-task-${index + 1}`;
                                  if (task.type === "lesson") {
                                    return (
                                      <li
                                        key={key}
                                      >{`lesson (Ch${task.chapter},L${task.lesson})`}</li>
                                    );
                                  } else {
                                    return <li key={key}>{task.displayName || task.type}</li>;
                                  }
                                })}
                              </ul>
                            </td>
                          </tr>
                        )
                      })}
                  </tbody>
                </Table>
              </Row>
            </>
          }
        </>
      }
    </Container>
  );
};

export default UserPlaylistTable;