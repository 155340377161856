import React from 'react';
import { Col, Row } from 'react-bootstrap';
import User from '../../../../../../Model/Usert';
import EarlyTerminationFollowup from './EarlyTermination';

interface Props {
  user: User;
}

const EarlyTerminationView: React.FC<Props> = ({ user }) => {
  return (
    <Row>
      <Col xs={12} lg={7}>
        <Row>
          <h3>Early termination</h3>
        </Row>
        <Row>
          <p>Move user to early termination or early termination followup.</p>
        </Row>
        <Row>
          <p>Careful this cannot be undone!</p>
        </Row>
        <EarlyTerminationFollowup user={user} />
      </Col>
    </Row>
  )
}

export default EarlyTerminationView;
