import React, {useEffect, useState} from "react";
import {firestore} from "../../firebase";

import Plot from "react-plotly.js";
import GraphLines from "./GraphLines";


const UserGroupGraph = (props) => {
    const [lines, setLines] = useState([]);
    const [loading, setLoading] = useState(false);

    let {users, title, program} = props;
    title = title ? title : " ";


    /*
    Sets the program to default to the users active program if nothing is passed in.
     */


    //Data Lines for fun


    const setup = async () => {
        if (loading) {
            return;
        }
        setLoading(true);
        let oneADayLine = GraphLines.onePerDayLine(84, 41);
        setLines([oneADayLine]);


        //add new lines here
        let taskLine = users.map(async (user) => {
            let l = GraphLines.getUsersTaskLine(user, program);
            return l;
        })
        let sessionLine = users.map(async (user) => {
            let l = GraphLines.getUsersSessionLine(user, program);
            return l;
        })
        // let newLines = taskLine.concat(sessionLine);
        let newLines = sessionLine;

        Promise.all(newLines).then((t) => {

            t.push(oneADayLine);
            setLines(t);
        })


        //dont forget new lines go in the chart too


    }

    useEffect(() => {

        setup();


    }, [setup]);


    let graph = (<Plot
        scrollZoom={true}
        data={lines}
        className="col"
        useResizeHandler={true}
        layout={{
            title: title, margin: {
                l: 100, r: 100, t: 100,
            },
        }}
    />);


    return graph;


};

export default UserGroupGraph;
