import React from "react";
import { faBook, faClinicMedical, faMoneyBill, faPhone, faPlus, faRainbow, faRegistered } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Container, Nav, Row } from "react-bootstrap";
import { Route, RouteComponentProps } from "react-router";
import AdminNavbar from "../AdminNavbar";
import NavLinker from "../NavLinker";
import LandingPageList from "./LandingPages";
import ReferralGroupsList from "./ReferralGroups";
import ReferralGroupCreateEdit from "./ReferralGroups/ReferralGroupCreateEdit";
import SurveysaurusOverview from "./SurveysaurusOverview";
import SurveysaurusStats from "./SurveysaurusStats";
import Payments from "./Payments";
import ManageLandingPage from "./LandingPages/ManageLandingPage";
import ScheduledCalls from "./ScheduledCalls";

const Managesurveysaurus: React.FC<RouteComponentProps> = ({ match }) => {

  return (
    <>
      <AdminNavbar />
      <div className={"album bg-light"}>
        <Container fluid={true} style={{ backgroundColor: "white" }}>
          <Row>
            <Col xs={2} className={"sidebar"}>
              <div className={"sidebar-sticky"}>
                <Nav className={"flex-column "}>
                  <NavLinker
                    baseUrl={match.url}
                    route=""
                    label="Overview"
                    icon={faClinicMedical}
                  />
                  <NavLinker
                    baseUrl={match.url}
                    route="/stats"
                    label="Stats"
                    icon={faBook}
                  />
                  <NavLinker
                    baseUrl={match.url}
                    route="/landingpages"
                    label="Landing pages"
                    icon={faRainbow}
                  >
                    <NavLinker
                      subLink={true}
                      baseUrl={match.url + "/landingpages"}
                      route="/new"
                      label="Create"
                      icon={faPlus}
                    />
                  </NavLinker>
                  <NavLinker
                    baseUrl={match.url}
                    route="/referralgroups"
                    label="Referral groups"
                    icon={faRegistered}
                  >
                    <NavLinker
                      subLink={true}
                      baseUrl={match.url + "/referralgroups"}
                      route="/new"
                      label="Create"
                      icon={faPlus}
                    />
                  </NavLinker>
                  <NavLinker
                    baseUrl={match.url}
                    route="/payments"
                    label="Payments"
                    icon={faMoneyBill}
                  />
                  <NavLinker
                    baseUrl={match.url}
                    route="/scheduledcalls"
                    label="Scheduled calls"
                    icon={faPhone}
                  />
                </Nav>
              </div>
            </Col>
            <Col xs={10} className={"main-content dashboard-container"}>
              <Row className={"dashboard-content"}>
                <Col>
                  <Row className="dashboard-heading align-items-center">
                    <h2>
                      Surveysaurus
                    </h2>
                    <FontAwesomeIcon icon={faBook} style={{ fontSize: 30, marginLeft: 20 }} />
                  </Row>
                  <Route
                    path={match.url}
                    exact
                    component={SurveysaurusOverview}
                  />
                  <Route
                    path={match.url + "/landingpages"}
                    exact
                    component={LandingPageList}
                  />
                  <Route
                    path={match.url + "/landingpages/:action/:landingPageKey?"}
                    component={ManageLandingPage}
                  />
                  <Route
                    path={match.url + "/referralgroups"}
                    exact
                    component={ReferralGroupsList}
                  />
                  <Route
                    path={match.url + "/referralgroups/:action/:referralGroupKey?"}
                    component={ReferralGroupCreateEdit}
                  />
                  <Route
                    path={match.url + "/stats"}
                    exact
                    component={SurveysaurusStats}
                  />
                  <Route
                    path={match.url + "/payments"}
                    exact
                    component={Payments}
                  />
                  <Route
                    path={match.url + "/scheduledcalls"}
                    exact
                    component={ScheduledCalls}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

export default Managesurveysaurus;
