import React from "react";
// import GraphTemplate from "../Graphs/GraphTemplate";
import GraphTemplateEPROsA from "../../Graphs/GraphTemplateEPROsA";
import { Col, Container, Row,Jumbotron } from "react-bootstrap";

// import "../surveyAdherence.css";
import AdminNavbar from "../../AdminNavbar";
import AreportsListSidebar002F from "./AreportsListSidebar002F";
import AreportsListSidebar002Fright from "./AreportsListSidebar002Fright";



const Graphs002 = () => {
  return (
    <>
      <AdminNavbar />
      <Jumbotron>
            <Container fluid={true}>
                <Row>
                    <Col>
                        <h1>002-Fukui ACT</h1><div className="TitleCb"></div>
                    </Col>
                </Row>
            </Container>
        </Jumbotron>
      <AreportsListSidebar002F/>
      {/* <Container fluid={true}><AreportsListSidebar002Fright/></Container> */}
      <Container>
        <div className="mt-4">
          {/* <h4>002 ACT</h4> */}
          <Row>
            <Col xs={10}>
              <GraphTemplateEPROsA report={"s0047"} />
            </Col>
          </Row>
          <Row>
            <Col xs={10}>
              <GraphTemplateEPROsA report={"s0134"} />
            </Col>
          </Row>
          <Row>
            <Col xs={10}>
              <GraphTemplateEPROsA report={"s0135"} />
            </Col>
          </Row>
          <Row>
            <Col xs={10}>
              <GraphTemplateEPROsA report={"s0136"} />
            </Col>
          </Row>
          <Row>
            <Col xs={10}>
              <GraphTemplateEPROsA report={"s0137"} />
            </Col>
          </Row>


          <Row>
            <Col xs={10}>
              <GraphTemplateEPROsA report={"s0139"} />
            </Col>
          </Row>
<Row>
            <Col xs={10}>
              <GraphTemplateEPROsA report={"s0141"} />
            </Col>
          </Row>
<Row>
            <Col xs={10}>
              <GraphTemplateEPROsA report={"s0142"} />
            </Col>
          </Row>
<Row>
            <Col xs={10}>
              <GraphTemplateEPROsA report={"s0144"} />
            </Col>
          </Row>
        </div>
      </Container>
      
      
    </>
  );
};

export default Graphs002;
