import React, { useEffect, useState } from "react";
import { firestore, functions } from "../../firebase";
import {
  Button,
  Col,
  Container,
  Jumbotron,
  Row,
  Spinner,
} from "react-bootstrap";
import AdminNavbar from "../AdminNavbar";
import { SystemEvent } from "../../Model/SystemEvent";

import Select from "react-select";
import User from "../../Model/Usert";
import useRole from "../../UseRole";
import firebase from "firebase";

const UserDataReset = (props: any) => {
  const [message, setMessage] = useState<string>("");
  const [deletionMessage, setDeletionMessage] = useState<string>("");
  const [confirmed, setConfirmed] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState<User>();

  useEffect(() => {
    return firestore
      .collection("users")
      .orderBy("email", "asc")
      .onSnapshot((snapshot: any) => {
        const userOptions = snapshot.docs?.map((doc: firebase.firestore.QueryDocumentSnapshot) => {
          const data = doc.data();
          const u = User.fromFirestore(doc);
          return {
            value: doc.id,
            label: `${data.subjectID ? data.subjectID : "No Subject ID"}: ${
              data.email
            }`,
            user: u,
          };
        });
        // @ts-ignore
        setUsers(userOptions);
      });
  }, []);

  const [results, setResults] = useState<any>();
  const isAdmin = useRole("admin");

  const getDescriptionRow = (name: string, value: string) => {
    // if (!value) {
    //     return <></>
    // }
    return (
      <>
        <dt className="col-sm-3 text-right">{name}</dt>
        <dd className="col-sm-9">{value}</dd>
      </>
    );
  };
  const deleteAtPath = (path: string) => {
    if (loading) return;
    setLoading(true);
    const group = new Date().toISOString();
    SystemEvent.fireEvent(
      "Document Deletion Request",
      "Data Management",
      "Deleting  document  at " + path,
      group,
      { path }
    );
    const deleteFn = functions.httpsCallable("FS_recursiveDelete");
    deleteFn({ path: path })
      .then(function (result: any) {
        console.log("Delete success: " + JSON.stringify(result));
        setLoading(false);
        setDeletionMessage("Successfully deleted " + result?.data?.path);
      })
      .catch(function (err: any) {
        console.log("Delete failed, see console,");
        console.warn(err);
        setDeletionMessage("Failed deletion " + err);
        setLoading(false);
      });
  };

  const backupDocument = (path: string) => {
    console.log("backing up " + path);
    if (loading) {
      return;
    }

    setLoading(true);
    const backupFunc = functions.httpsCallable("FS_documentBackup");
    const group = new Date().toISOString();
    SystemEvent.fireEvent(
      "Document Backup Request",
      "Data Management",
      "Backing up document  at " + path,
      group,
      { path }
    );
    backupFunc({ sourceDoc: path, recursive: true })
      .then(function (result: any) {
        setMessage("Success! " + JSON.stringify(result));
        setResults(result);
        SystemEvent.fireEvent(
          "Document Backup Success",
          "Data Management",
          "Document at " + path + "backed up to " + result?.destinationPath,
          group,
          result
        );

        console.log("Backup success: ", result);
        setLoading(false);
      })
      .catch(function (err: any) {
        setMessage("Failed " + JSON.stringify(err));

        console.warn(err);
        setLoading(false);
      });
  };

  const handleInputChange = (newValue: {
    value: string;
    label: string;
    user: User;
  }) => {
    console.log("new Value selected", newValue);
    setSelectedUser(newValue.user);
    setConfirmed(false);
    setResults(null);
  };
  return (
    <>
      <AdminNavbar></AdminNavbar>
      <Container fluid={true}>
        <Jumbotron className={"text-center"}>
          <h1 className={"jumbotron-heading text-danger"}>Data Reset</h1>
          <p className="lead text-muted">
            This is an admin tool meant only for resetting test data.
          </p>
        </Jumbotron>

        {isAdmin ? (
          <>
            <div className={"album py-5 bg-light"}>
              <Container fluid={true} style={{ backgroundColor: "white" }}>
                <Row>
                  <Col>
                    <h2>Backup a user</h2>
                  </Col>
                </Row>
                <Row>
                  {users && (
                    <Col>
                      <Select
                        name="colors"
                        options={users}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onSelectResetsInput={false}
                        onChange={(val) => {
                          // @ts-ignore
                          handleInputChange(val);
                        }}
                      />
                    </Col>
                  )}
                  <Col>
                    {selectedUser && (
                      <Button
                        onClick={() => {
                          backupDocument("users/" + selectedUser?.uid);
                        }}
                      >
                        {loading ? (
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                        ) : (
                          <>Backup</>
                        )}
                      </Button>
                    )}
                  </Col>
                </Row>

                {results && (
                  <>
                    <h3 className={"text-success"}> Successful Backup</h3>
                    <dl className={"row"}>
                      {getDescriptionRow(
                        "Original Path",
                        results.data?.originalPath
                      )}
                      {getDescriptionRow(
                        "Destination Path",
                        results.data?.destinationPath
                      )}
                      {getDescriptionRow(
                        "Recursive",
                        results.data?.recursive ? "True" : "False"
                      )}
                    </dl>
                  </>
                )}
              </Container>
            </div>
            {selectedUser && (
              <Container fluid={true} className={"bg-light"}>
                <Row>
                  {selectedUser?.study === "002" ||
                  selectedUser?.study === "004" ? (
                    <>
                      {" "}
                      <Col xs={12}>
                        <h4 className={"text-warning"}>
                          Unable to Reset This User
                        </h4>
                      </Col>
                      <Col>
                        <p className="lead text-muted">
                          {selectedUser.subjectID} is a member of the{" "}
                          {selectedUser.study} study, as such their data cannot
                          be reset with this tool.
                        </p>
                      </Col>
                    </>
                  ) : (
                    <>
                      {" "}
                      <Col xs={12}>
                        <h4 className={"text-warning"}>
                          Able to Reset This User
                        </h4>
                      </Col>
                      <Col xs={12}>
                        <p className="lead text-muted">
                          Please confirm that you would like to delete this
                          users data. Note - backups can take a long time, and
                          so are not automatically integrated into the deletion
                          just yet. Do it manually above, and then wait a few
                          minutes, and then do the deletion.
                        </p>
                      </Col>
                      <Col xs={12}>
                        <p className="lead text-muted">
                          Active Program: {selectedUser?.activeProgram}
                        </p>
                      </Col>
                      <Col xs={12}>
                        <p className="lead text-muted">
                          Cohort: {selectedUser?.cohort}
                        </p>
                      </Col>
                      <Col xs={12}>
                        <p className="lead text-muted">
                          Arm: {selectedUser?.arm}
                        </p>
                      </Col>
                      <Col xs={12}>
                        <p className="lead text-muted">
                          Email: {selectedUser?.email}
                        </p>
                      </Col>
                      <Col xs={12}>
                        <p className="lead text-muted">
                          Name: {selectedUser?.firstName}{" "}
                          {selectedUser?.lastName}
                        </p>
                      </Col>
                      {!confirmed && (
                        <Col>
                          <Button
                            variant={"warning"}
                            onClick={() => {
                              setConfirmed(true);
                            }}
                          >
                            {" "}
                            I confirm that I want to do this{" "}
                          </Button>
                        </Col>
                      )}
                      {confirmed && (
                        <Row>
                          {deletionMessage && (
                            <Col xs={12}>
                              <h4>{deletionMessage}</h4>
                            </Col>
                          )}
                          <Col xs={"auto"}>
                            <Button
                              variant={"danger"}
                              onClick={() => {
                                const path = "users/" + selectedUser?.uid;
                                SystemEvent.fireEvent(
                                  "Deleting User Document",
                                  "Data Management",
                                  "Deleting " +
                                    selectedUser.email +
                                    "  at " +
                                    path,
                                  new Date().toISOString(),
                                  { path }
                                );
                                deleteAtPath(path);
                              }}
                            >
                              {" "}
                              {loading && (
                                <Spinner
                                  as="span"
                                  animation="border"
                                  size="sm"
                                  role="status"
                                  aria-hidden="true"
                                />
                              )}
                              Erase User Document
                            </Button>
                          </Col>
                          <Col></Col>
                          <Col xs={"auto"} className={"align-right"}>
                            <Button
                              variant={"danger"}
                              onClick={() => {
                                const path =
                                  "registrations/" + selectedUser?.email;
                                SystemEvent.fireEvent(
                                  "Deleting Registration Document",
                                  "Data Management",
                                  "Deleting registration for" +
                                    selectedUser.email +
                                    "  at " +
                                    path,
                                  new Date().toISOString(),
                                  { path }
                                );
                                deleteAtPath(path);
                              }}
                            >
                              {loading && (
                                <Spinner
                                  as="span"
                                  animation="border"
                                  size="sm"
                                  role="status"
                                  aria-hidden="true"
                                />
                              )}
                              Erase Reg Document
                            </Button>
                          </Col>
                        </Row>
                      )}
                    </>
                  )}
                </Row>
              </Container>
            )}
          </>
        ) : (
          <h1>Admin Only</h1>
        )}
      </Container>
    </>
  );
};

export { UserDataReset };
