import { SessionTemplate } from "@swing-therapeutics/swingcore/dist/models/Session";
import { firestore } from "firebase";
import { isNil } from "lodash";
import React, { useState } from "react";
import { Button, Col, Row, Table } from "react-bootstrap";
import { AddUnlockedActivityModal } from "./AddUnlockedActivityModal";

interface UnlockActivitiesProps {
  session: SessionTemplate;
  sessionDoc: firestore.DocumentReference<firestore.DocumentData>;
  programID: string;
}

export const UnlockActivities: React.FC<UnlockActivitiesProps> = ({ session, sessionDoc, programID }) => {
  const [showAddUnlock, setShowUnlock] = useState(false);

  const unlocksIsEmpty = isNil(session.unlockedActivities) || session.unlockedActivities.length === 0;
  const { unlockedActivities } = session;


  const deleteUnlock = (index: number) => {
    const updatedActivities = [...unlockedActivities];
    updatedActivities.splice(index, 1);

    sessionDoc.update({
      unlockedActivities: updatedActivities
    })
  }

  return (
    <Row>
      <Col xl={8}>
        {unlocksIsEmpty ?
          <div style={{ marginBottom: 15 }}>No Unlocked Activities</div>
          :
          <Table bordered hover>
            <tr>
              <th>type</th>
              <th>ID</th>
              <th style={{ width: 50 }}>Delete</th>
            </tr>
            {unlockedActivities.map((activity, index) => {
              return (
                <tr>
                  <td>{activity.type}</td>
                  <td>{activity.id}</td>
                  <td style={{ width: 50, textAlign: 'center' }}>
                    <Button
                      style={{ height: 30 }}
                      variant="outline-danger"
                      size="sm"
                      onClick={() => deleteUnlock(index)}
                    >
                      X
                    </Button>
                  </td>
                </tr>
              )
            })}
          </Table>
        }
        <Row>
          <Col>
            <Button
              variant="outline-primary"
              onClick={() => setShowUnlock(true)}
              style={{ marginTop: 5 }}
            >
              Add Unlocked Activity
            </Button>
          </Col>
        </Row>
        <AddUnlockedActivityModal
          programID={programID}
          session={session}
          sessionDoc={sessionDoc}
          show={showAddUnlock}
          close={() => setShowUnlock(false)}
        />
      </Col>
    </Row>
  )

}
