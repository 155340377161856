import React, { useEffect, useState } from "react";
import { FormikErrors, useFormik } from "formik";
import { Modal, Button, Col, Form, Row, Table } from "react-bootstrap";
import { cloneDeep } from "lodash";
import {
  ConfirmationActivity,
  deletePlaylist,
  Playlist,
  PlaylistDocument,
  playlistSchema,
} from "../../../Model/Playlist";
import ConfirmationEditor from "./ConfirmationActivityEditor";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import { getActivityRows } from "./PlaylistFieldActivityRow";

interface modalProps {
  close: Function;
  programKey: string;
  doc?: PlaylistDocument;
}

const getErrorSummary = (errors: FormikErrors<any>) => {
  let error;
  let color;
  if (Object.values(errors).length > 0) {
    error = "Required: " + Object.values(errors).join(", ") + "*";
    color = "red";
  } else {
    error = "Fields marked with * are required";
    color = "black";
  }
  return <div style={{ color, margin: 10 }}>{error}</div>;
};

const getErrorText = (name: string, value: any) => (
  <>
    <div style={{ display: "inline" }}>{name}</div>
    <div style={{ display: "inline", color: value ? "red" : "black" }}>*</div>
  </>
);

export const PlaylistFieldModal = ({
  close,
  programKey,
  doc = undefined,
}: modalProps) => {
  const [playlist] = useState(doc ? new Playlist(programKey, doc) : undefined);
  const [activitiesJSON, setActivitiesJSON] = useState(
    JSON.stringify(playlist?.confirmationActivities ?? [])
  );
  const [edit, setEdit] = useState(false);

  const confirmationActivities = JSON.parse(activitiesJSON);
  const setActivities = (value: ConfirmationActivity[]) =>
    setActivitiesJSON(JSON.stringify(value));

  const deleteAndClose = async () => {
    if (
      doc?.id &&
      window.confirm("Are you sure you want to delete this playlist?")
    ) {
      deletePlaylist(programKey, doc?.id);
      close();
    }
  };

  const addConfirmationActivity = (activity: ConfirmationActivity) => {
    const activities = confirmationActivities;
    activities.push(activity);
    setActivities(activities);
  };

  const deleteConfirmationActivity = (index: number) => {
    confirmationActivities.splice(index, 1);
    setActivities(confirmationActivities);
  };

  const reorder = (startIndex: number, endIndex: number) => {
    const [removed] = confirmationActivities.splice(startIndex, 1);
    confirmationActivities.splice(endIndex, 0, removed);
    setActivities(confirmationActivities);
  };

  const finalizeFormData = (values) => {
    const finalData = cloneDeep(values);
    finalData.confirmationLessons = values?.confirmationLessons?.split("\n");
    finalData.confirmationActivities = confirmationActivities;
    return finalData;
  };

  const formik = useFormik({
    initialValues: {
      id: doc?.id,
      name: playlist?.name,
      rank: playlist?.rank,
      description: playlist?.description,
      userViewable: playlist?.userViewable ?? false,
      created: playlist?.created,
      updated: playlist?.updated,
      confirmationLessons: playlist?.confirmationLessons?.join("\n"),
      firstSessionID: playlist?.firstSessionID,
      imageURL: playlist?.imageURL,
    },
    validationSchema: playlistSchema,
    onSubmit: async (values) => {
      const newPlaylist = new Playlist(programKey, finalizeFormData(values));

      //If this screen was created from an existing doc and the id changed
      //delete the old doc to avoid duplication
      if (doc?.id && doc.id !== values.id) {
        await deletePlaylist(programKey, doc.id);
      }

      await newPlaylist.persist();
      close();
    },
    enableReinitialize: true,
  });

  return (
    <Modal show={true} onHide={close} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Edit Playlist</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col sm={12} lg={6} className="border-right border-dark">
            <h3>Info</h3>
            <Form.Row>
              <Form.Group as={Col} xs={doc?.id ? 12 : 8} controlId="name">
                <Form.Label>
                  {getErrorText("Name", formik.errors.name)}
                </Form.Label>
                <Form.Control
                  name="name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  placeholder="Enter the playlists name"
                />
              </Form.Group>

              <Form.Group as={Col} xs={4} controlId="id">
                <Form.Label>{getErrorText("ID", formik.errors.id)}</Form.Label>
                <Form.Control
                  name="id"
                  value={formik.values.id}
                  onChange={formik.handleChange}
                  placeholder="Playlists ID"
                />
              </Form.Group>

              <Form.Group as={Col} xs={12} controlId="description">
                <Form.Label>
                  {getErrorText("Description", formik.errors.description)}
                </Form.Label>
                <Form.Control
                  as="textarea"
                  rows={4}
                  name="description"
                  value={formik.values.description}
                  onChange={formik.handleChange}
                  placeholder="Description"
                />
              </Form.Group>

              <Form.Group as={Col} xs={12} controlId="userViewable">
                <Form.Label>User Viewable</Form.Label>
                <Col>
                  <Row>
                    <Form.Check
                      name="userViewable"
                      checked={formik.values.userViewable}
                      onChange={formik.handleChange}
                    />
                  </Row>
                </Col>
              </Form.Group>

              <Form.Group as={Col} xs={12} controlId="imageURL">
                <Form.Label>
                  Image Name
                </Form.Label>
                <Form.Control
                  name="imageURL"
                  value={formik.values.imageURL}
                  onChange={formik.handleChange}
                  placeholder="The image shown on the playlist picker"
                />
              </Form.Group>
            </Form.Row>
          </Col>
          <Col sm={12} lg={6}>
            <h3>Confirmation</h3>
            <Form.Row>
              <Form.Group as={Col} xs={12} controlId="description">
                <Form.Label>Lesson Confirmation Text</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={5}
                  name="confirmationLessons"
                  value={formik.values.confirmationLessons}
                  onChange={formik.handleChange}
                  placeholder="Lesson confirmation text"
                />
              </Form.Group>
              <Form.Group as={Col} xs={12} controlId="description">
                <Form.Label>Activity Confirmation</Form.Label>
                {ConfirmationEditor((activity) =>
                  addConfirmationActivity(activity)
                )}
                <Col className="border-top border-light">
                  <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button
                      variant="activity-add"
                      onClick={() => setEdit(!edit)}
                    >
                      <FontAwesomeIcon
                        icon={faPencilAlt}
                        color="lightBlue"
                        style={{
                          height: 20,
                          width: 20,
                          paddingTop: 2,
                          marginRight: 10,
                        }}
                      />
                    </Button>
                  </div>
                  <Table striped>
                    {getActivityRows(
                      edit,
                      reorder,
                      deleteConfirmationActivity,
                      confirmationActivities
                    )}
                  </Table>
                </Col>
              </Form.Group>
            </Form.Row>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div>
            {doc?.id && (
              <Button
                variant="danger"
                style={{ margin: 10 }}
                onClick={() => deleteAndClose()}
                disabled={formik.isSubmitting}
              >
                Delete
              </Button>
            )}
          </div>

          <Row>
            {getErrorSummary(formik.errors)}
            <Button
              style={{ margin: 10 }}
              onClick={() => formik.handleSubmit()}
              disabled={formik.isSubmitting}
            >
              Save
            </Button>

            <Button style={{ margin: 10 }} 
            onClick={() => close()}
            variant="secondary"
            >
              Cancel
            </Button>
          </Row>
        </div>
        <Row></Row>
      </Modal.Footer>
    </Modal>
  );
};
