import { firestore } from "firebase";
import { useCollectionData } from "react-firebase-hooks/firestore";

export interface LessonType {
  title: string;
  num: number;
  chNum: number;
  id: string;
}

export interface ChapterType {
  title: string;
  num: number;
}

export interface AwarenessType {
  id: string;
  uuid: string;
  key: string;
}

export const useLessonTasks = (
  programKey: string
): [Array<ChapterType>, Array<LessonType>] => {
  const chaptersRef = firestore()
    .collection("programs")
    .doc(programKey)
    .collection("chapters");
  const lessonsRef = firestore()
    .collection("programs")
    .doc(programKey)
    .collection("lessons");
  const [chapters] = useCollectionData<ChapterType>(chaptersRef);
  const [lessons] = useCollectionData<LessonType>(lessonsRef);
  return [chapters ?? [], lessons ?? []];
};

export const useAwarenessTasks = (
  programKey: string
): [Array<AwarenessType>] => {
  const activitiesRef = firestore()
    .collection("programs")
    .doc(programKey)
    .collection("awarenessActivities");
  const [activities] = useCollectionData<AwarenessType>(activitiesRef);
  return [activities ?? []];
};
