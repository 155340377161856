import React, {useEffect, useState} from "react";
import {auth, firestore} from "../../firebase";
import User from "../../Model/Usert";
import {
  Button, Col, Form, FormControl, InputGroup, Row,
} from "react-bootstrap";
import RegForm from "../Registration/RegForm";
import {sendToUsersDevices} from "../../Model/Notifications";
import {SystemEvent} from "../../Model/SystemEvent";
import {ScreeningCard} from "./ScreeningCard";

const uuid = require("uuid");

const ScreeningVerification = (props: any) =>
{
  const [viewStep, setViewStep] = useState<String>("overview"); //overview -> confirmation -> registration
  const [screeningUsers, setScreeningUsers] = useState<User[]>();
  const [confirmingUser, setConfirmingUser] = useState("");
  const [regMessage, setRegMessage] = useState(false);

  const [regTime, setRegTime] = useState<any>();

  useEffect(() =>
  {
    const subscriber = firestore
    .collection("users")
    .where("activeProgram", "==", "screeningWeek")
    .onSnapshot((snap) =>
    {
      let screeners = snap.docs.map((u) =>
      {
        return User.fromFirestore(u);
      });
      setScreeningUsers(screeners);
    });
    return () => subscriber();
  }, []);

  const handleSuccessfulRegistration = async (results: any) =>
  {

    const {registration, user} = results;
    const messages = registration.activeProgram === "tempoACT" ? [
      {
        title: "Welcome to Tempo ACT", body: " Tempo is now ready and here for you. Thank you for your participation in the SMART-FM study..",
      },
    ] : [
      {
        title: "Welcome to the Tempo Symptom Tracker", body: "The Tempo Symptom Tracker is now ready for you. Thank you for your participation in the SMART-FM study.",
      },
    ];
    const enrollmentUser = auth.currentUser?.email;

    const props = {
      registration: {...registration}, user: {...user}, subjectID: registration.subjectID, confirmingUser: confirmingUser,
    };
    const group = new Date().toISOString();
    SystemEvent.fireEvent(" Randomization Enrollment", "004-reg", enrollmentUser + " working with " + confirmingUser + " Enrolled subject " + registration.subjectID + " in  " + registration.activeProgram, group, props);
    await sendToUsersDevices(results.user, new Date().toUTCString(), messages);
    SystemEvent.fireEvent(" Randomization Notification Sent", "004-reg", " Notification sent to  " + registration.subjectID + " to verify they have completed screening and are enrolled in a program", group, props);
  };

  const handleEnrollButton = (program: string, user: User) =>
  {
    // @ts-ignore
    setRegTime({
      email: user.email, activeProgram: program, study: "004", role: "patient", cohort: user.cohort, site: user.site, zendeskID: user.zendeskID, subjectID: user.subjectID, firstName: user.firstName, lastName: user.lastName,
    });

    setViewStep("confirmation");
  };

  return (<>
    <h1>Screening Week</h1>
    {viewStep === "overview" && (<Row>
      {screeningUsers && React.Children.toArray(screeningUsers?.map((u) => (<ScreeningCard
      handleEnroll={handleEnrollButton}
      user={u}
      ></ScreeningCard>)))}
    </Row>)}

    {viewStep === "confirmation" && (<>
      <h3>Confirmation</h3>
      <p>
        Please confirm that you do in fact want to enroll{" "}
        {regTime.subjectID} in{" "}
        {regTime.activeProgram === "tempoACT" ? "ACT" : "ST"} by entering the
        name of the person you are paired with below
      </p>

      <Form inline>
        <Col xs={9}>
          <InputGroup className="">
            <FormControl
            id=" search"
            placeholder=" Search"
            onChange={(e) =>
            {
              setConfirmingUser(e.target.value.trim());
            }}
            />
          </InputGroup>
        </Col>
        <Col xs={2}>
          <Button
          onClick={() =>
          {
            setViewStep("registration");
          }}
          >
            Confirm
          </Button>
        </Col>
      </Form>
    </>)}
    {viewStep === "registration" && (<>
      <RegForm
      handleSuccess={handleSuccessfulRegistration}
      presetValues={regTime}
      ></RegForm>
    </>)}
  </>);
};

export {ScreeningVerification};
