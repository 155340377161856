import { Col, Row } from "react-bootstrap";
import React from "react";
import ReportTable from "../Reports/ReportTable";

const ScreeningOverview = (props: any) => {
  return (
    <>
   
      <Row>
      <Col xs={12} md={12}>
          <h4>C2 Qualification</h4>

          <ReportTable tableKey={"screening004B_C2qual"}></ReportTable>
        </Col>
        <Col xs={12} md={12}>
          {/* <h4>Visits by Site</h4> */}
          {/* <ReportTable tableKey={"visits_by_site"}></ReportTable> */}
        </Col>
        <Col xs={12} md={12}>
          {/* <h4>C2 Visits</h4> */}

          {/* <ReportTable tableKey={"screening004B_C2qualPROs"}></ReportTable> */}
        </Col>
      </Row>
    </>
  );
};

export { ScreeningOverview };
