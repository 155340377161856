import Journal from "@swing-therapeutics/swingcore/dist/models/journals/Journal";
import { DartboardV2JournalQuestion, ValuesEntryQuestion } from "@swing-therapeutics/swingcore/dist/models/journals/JournalInterfaces";
import { PacingJournal } from "@swing-therapeutics/swingcore/dist/models/journals/PacingJournal";
import { isNil } from "lodash";
import React, { useState } from "react";
import { Badge } from "react-bootstrap";
import "./userTable.css";

interface TableRowProps {
  journal: Journal | PacingJournal;
  isAdmin: boolean;
}

export const UserJournalRow = ({ journal, isAdmin}: TableRowProps) => {
  return (
    <tr key={journal.id}>
      <td>{journal.journalTypeID}</td>
      <td>{journal.phase}</td>
      <td>{journal?.dateCreated?.toLocaleString()}</td>
      <td>{journal?.dateCompleted?.toLocaleString()}</td>
      {isAdmin && 
        <td style={{ width: "50%" }}>{GetJournalResponses(journal)}</td>
      }
    </tr>
  );
};

const GetJournalResponses = (journal: Journal | PacingJournal) => {
  return (
    <>
      {GetPhaseResponse(journal, "creation")}
      {GetPhaseResponse(journal, "conversion")}
    </>
  );
};

export const GetPhaseResponse = (
  journal: PacingJournal | Journal,
  phase: string
): JSX.Element => {
  const [viewMore, setViewMore] = useState(false);

  try {
    const questions = (journal?.phases?.[phase]?.questions as any[])?.filter(
      (question) =>
        question.userResponseText ||
        question.userResponse ||
        question.userResponses
    );

    if (isNil(questions) || questions.length === 0) {
      return <></>;
    }

    const userResponses = questions.map((question, index) => {
      if (question?.type === "dartboard_v2" || question?.type === "dartboard") {
        return formatDartboardResponse(question);
      }

      if (question?.type === 'valuesEntry') {
        return formatValuesJournalReponse(question);
      }

      let response =
        question.userResponseText ??
        question.userResponse ??
        question.userResponses ??
        "";

      return <p className="response">{`${response}`}</p>;
    });

    const prompts = questions.map((question, index) => {
      if (question.type === "dartboard_v2") {
        return formatDartboardPrompt(question);
      }

      const prompt =
        question?.screenText?.promptText ??
        question?.screenText?.promptText2 ??
        "";
      return <p className="prompt">{`${index}. ${prompt}`}</p>;
    });

    return (
      <div>
        <Badge
          variant={phase === "creation" ? "success" : "danger"}
          className="viewMore"
          onClick={() => setViewMore(!viewMore)}
        >
          {phase} (click to {viewMore ? "hide" : "show"})
        </Badge>
        {viewMore &&
          userResponses.map((response, index) => (
            <React.Fragment key={index}>
              {prompts[index]}
              {response}
            </React.Fragment>
          ))}
      </div>
    );
  } catch (error) {
    console.error(error);
  }

  return <div>Error retrieving responses...</div>;
};

const formatValuesJournalReponse = (question: ValuesEntryQuestion) => {
  const responses = question?.userResponses?.map((response, index) => `${index + 1}) ${response}`)
  return (
    <>
      {responses.map(response => <p className="response">{response}</p>)}
    </>
  );
} 

const formatDartboardPrompt = (question: DartboardV2JournalQuestion) => {
  return (
    <p className="prompt">
      {question?.valuesCategoryID ?? question?.screenText?.promptText}
    </p>
  );
};

const formatDartboardResponse = (question: DartboardV2JournalQuestion) => {
  if (!question?.userResponse?.xPercent || !question?.userResponse?.yPercent) {
    return <p className="response">Incomplete Journal</p>;
  }

  const xPercent = `${question?.userResponse?.xPercent * 100}%`;
  const yPercent = `${question?.userResponse?.yPercent * 100}%`;

  return (
    <p>
      <svg
        width="70"
        height="70"
        viewBox="0 0 100 100"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          cx="50%"
          cy="50%"
          r="48%"
          fill="none"
          stroke="gray"
          strokeWidth="2"
        />
        <circle
          cx="50%"
          cy="50%"
          r="5%"
          fill="none"
          stroke="gray"
          strokeWidth="2"
        />
        <line
          x1="50%"
          y1="0"
          x2="50%"
          y2="100%"
          height="100%"
          stroke="gray"
          strokeWidth="2"
        />
        <line
          y1="50%"
          x1="0"
          y2="50%"
          x2="100%"
          height="100%"
          stroke="gray"
          strokeWidth="2"
        />
        {question?.userResponse && (
          <circle
            cx={xPercent}
            cy={yPercent}
            r="5%"
            fill="pink"
            stroke="gray"
            strokeWidth="2"
          />
        )}
      </svg>
    </p>
  );
};
