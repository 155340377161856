import React, { useEffect, useState } from "react";
import "./surveyAdherence.css";
import { firestore } from "../../firebase";
import { v4 as idMaker } from "uuid";

import { Col, Row, Table } from "react-bootstrap";

const ReportTable = (props) => {
  const { tableKey } = props;
  const [tableDoc, setTableDoc] = useState();
  const [name, setName] = useState("");
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);

  useEffect(() => {
    const subscriber = firestore
      .doc("analytics/reports/reportTables/" + tableKey)
      .get()
      .then((doc) => {
        const data = doc.data();
        setColumns(data.columns);
        setName(data.name);
        setTableDoc(doc);
        const path = data.collectionPath
          ? data.collectionPath
          : "analytics/reports/reportTables/" + tableKey + "/rows";
        let query = data.orderBy
          ? firestore
              .collection(path)
              .orderBy(data.orderBy.field, data.orderBy.direction)
          : firestore.collection(path);
        query.onSnapshot((snap) => {
          setRows(snap.docs);
        });
      });
  }, [tableKey]);

  const getTableRow = (progRef) => {
    let data = progRef.data();

    let cols = columns.map((c) => {
      let val = data[c];
      if (typeof data[c] === "object") {
        try {
          val = data[c].toDate().toLocaleDateString();
        } catch (error) {
          console.log("couldnt format something", { data, error });
        }
      }
      return <td>{val}</td>;
    });

    return (
      <tr key={idMaker()} onClick={() => {}}>
        {cols}
      </tr>
    );
  };
  if (!(columns && rows)) {
    return <> </>;
  }
  return (
    <>
      <Col className={""} xs={12}>
        <Table responsive className={"table-sm"}>
          <thead key={"tableHead" + (tableDoc ? tableDoc.id : "")}>
            <tr key={idMaker()}>
              {columns.map((c) => {
                return <th key={c.id}>{c}</th>;
              })}
            </tr>
          </thead>
          <tbody>{rows.map((u) => getTableRow(u))}</tbody>
        </Table>
      </Col>
    </>
  );
};

export default ReportTable;
