import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { Col, Container, FormControl, Row, Table } from "react-bootstrap";
import { RouteComponentProps } from "react-router";
import { useCollectionClassData } from "../../../utils/Hooks";
import { firestore } from "../../../firebase";
import CompleteRequestedPayment from "./CompleteRequestedPayment";
import moment from "moment";
import {
  CompletedPayment,
  RequestedPayment,
} from "@swing-therapeutics/swingcore/dist/models/payments/Payment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp } from "@fortawesome/free-solid-svg-icons";
import "./Payments.css";
import { useHistory, useLocation } from "react-router-dom";
import usePayments from "./usePayments";

const Payments: React.FC<RouteComponentProps> = () => {
  const requestedPaymentsRef = useRef(null);
  const completedPaymentsRef = useRef(null);
  const [requestedPaymentsActive, setRequestedPaymentsActive] = useState(true);
  let history = useHistory();
  let { search } = useLocation();
  console.log(search, "search");
  const pageNumber = new URLSearchParams(search).get("completedPayments");
  const onTabClick = useCallback(
    (tab: "requested" | "completed", replaceHistory?: boolean) => {
      if (tab === "requested") {
        setRequestedPaymentsActive(true);
        requestedPaymentsRef.current.classList.add("activeTab");
        completedPaymentsRef.current.classList.remove("activeTab");
        if(replaceHistory){
          history.replace({
            pathname: "payments",
            search: "",
          });
        }
      } else {
        setRequestedPaymentsActive(false);
        completedPaymentsRef.current.classList.add("activeTab");
        requestedPaymentsRef.current.classList.remove("activeTab");
        if(replaceHistory){
          history.replace({
            pathname: "payments",
            search: "",
          });
        }
      }
    },
    [history]
  );

  useLayoutEffect(() => {
    if (pageNumber) {
      onTabClick("completed", false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container>
      <h3>Payments</h3>
      <Row className="mb-3">
        <div className="tabSection">
          <button
            onClick={() => onTabClick("requested", true)}
            className="tabButton activeTab"
            ref={requestedPaymentsRef}
          >
            Requested Payments
          </button>
          <button
            onClick={() => onTabClick("completed", true)}
            className="tabButton"
            ref={completedPaymentsRef}
          >
            Completed Payments
          </button>
        </div>
      </Row>
      {requestedPaymentsActive ? (
        <Row className="mb-4">
          <RequestedPayments />
        </Row>
      ) : (
        <Row>
          <CompletedPayments />
        </Row>
      )}
    </Container>
  );
};

export default Payments;

interface Filters {
  // field (key) is the key of the field
  // Value of key is what the field must include
  [field: string]: string;
  datePayed: "asc" | "desc";
}

const defaultFilters: Filters = {
  paymentGroup: "",
  subjectID: "",
  paymentFor: "",
  paidBy: "",
  notes: "",
  datePayed: "asc",
};

/*
 * Perform a filter on string values, also sort by date field
 */
const filterSortPayments = (
  payments: CompletedPayment[],
  filters: Filters
): CompletedPayment[] => {
  const filterKeys = Object.keys(filters).filter((key) => key !== "datePayed"); // Save date payed for sorting
  let filteredPayments = [...payments];
  for (const filterKey of filterKeys) {
    if (filters[filterKey]) {
      // Filter is defined, perform it
      filteredPayments = filteredPayments.filter((payment) =>
        payment[filterKey]
          .toLowerCase()
          .includes(filters[filterKey].toLowerCase())
      );
    }
  }
  // Sort by date
  filteredPayments.sort(
    (b, a) =>
      (a.payedTimestamp.getTime() - b.payedTimestamp.getTime()) *
      (filters.datePayed === "asc" ? 1 : -1)
  );
  return filteredPayments;
};

const CompletedPayments: React.FC = () => {
  const [completedPayments] = useCollectionClassData(
    CompletedPayment,
    firestore.collection("/tempest/payments/completed")
  );
  const [filters, setFilters] = useState<Filters>({ ...defaultFilters });
  const [isFirstLoad, setIsFirstLoad] = useState(true);

  let history = useHistory();
  let { search } = useLocation();
  const pageNumber = new URLSearchParams(search).get("completedPayments");
  console.log(pageNumber, "in payments");
  const { visibleDocs, setDocs, page, PaginationButtons } = usePayments(
    20,
    Number(pageNumber)
  );

  useEffect(() => {
    if (completedPayments.length) {
      setDocs(filterSortPayments(completedPayments, filters));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, completedPayments]);

  const setFilterValue = useCallback((newValue: any, field: string) => {
    setFilters((prevFilters) => ({ ...prevFilters, [field]: newValue }));
  }, []);

  useEffect(() => {
    //@ts-ignore
    if (page.current !== pageNumber && (!isFirstLoad || !pageNumber)) {
      history.replace({
        pathname: "payments",
        search: "?completedPayments=" + page.current,
      });
    } else {
      setIsFirstLoad(false);
      if (pageNumber && isFirstLoad) {
        console.log('184')
        page.current = Number(pageNumber);
      }
    }
    setIsFirstLoad(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, page.current, pageNumber]);

  return (
    <Col>
      <Row>
        <h4>Completed payments</h4>
      </Row>
      <Row className="mb-3">
        <Col xs={4}>
          <h6>Payment group</h6>
          <FormControl
            type="text"
            value={filters.paymentGroup}
            onChange={(e) => setFilterValue(e.target.value, "paymentGroup")}
            placeholder="Payment group"
          />
        </Col>
        <Col xs={4}>
          <h6>Subject ID</h6>
          <FormControl
            type="text"
            value={filters.subjectID}
            onChange={(e) => setFilterValue(e.target.value, "subjectID")}
            placeholder="Subject ID"
          />
        </Col>
        <Col xs={4}>
          <h6>Payment for</h6>
          <FormControl
            type="text"
            value={filters.paymentFor}
            onChange={(e) => setFilterValue(e.target.value, "paymentFor")}
            placeholder="Payment for"
          />
        </Col>
      </Row>
      <Row className="mb-3">
        <Col xs={4}>
          <h6>Paid by</h6>
          <FormControl
            type="text"
            value={filters.paidBy}
            onChange={(e) => setFilterValue(e.target.value, "paidBy")}
            placeholder="Paid by"
          />
        </Col>
        <Col xs={4}>
          <h6>Notes</h6>
          <FormControl
            type="text"
            value={filters.notes}
            onChange={(e) => setFilterValue(e.target.value, "notes")}
            placeholder="Notes"
          />
        </Col>
      </Row>
      <Row>
        <Table striped responsive>
          <thead>
            <tr>
              <th>Payment group</th>
              <th>Subject ID</th>
              <th>Payment for</th>
              <th>Amount</th>
              <th>Paid by</th>
              <th
                className="hoverable"
                onClick={() =>
                  setFilters((prevFilter) => ({
                    ...prevFilter,
                    datePayed: prevFilter.datePayed === "asc" ? "desc" : "asc",
                  }))
                }
              >
                Date paid{" "}
                <FontAwesomeIcon
                  icon={faArrowUp}
                  style={{
                    transform: `rotate(${
                      filters.datePayed === "asc" ? "0deg" : "180deg"
                    })`,
                  }}
                />
              </th>
              <th>Notes</th>
            </tr>
          </thead>
          <tbody>
            {visibleDocs.map((payment) => (
              <tr key={payment.getDocPath()}>
                <td>{payment.paymentGroup}</td>
                <td>{payment.subjectID}</td>
                <td>{payment.paymentFor}</td>
                <td>
                  {"$"}
                  {payment.amount}
                </td>
                <td>{payment.paidBy}</td>
                <td>
                  {moment.utc(payment.payedTimestamp).format("MM/DD/yyyy")}
                </td>
                <td>{payment.notes}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Row>
      <PaginationButtons />
    </Col>
  );
};

const RequestedPayments: React.FC = () => {
  const [requestedPayments] = useCollectionClassData(
    RequestedPayment,
    firestore.collection("/tempest/payments/requested")
  );
  let history = useHistory();

  let { search } = useLocation();
  const pageNumber = new URLSearchParams(search).get("requestedPayments");
  const pageNumberCompleted = new URLSearchParams(search).get("completedPayments");
  const { visibleDocs, setDocs, page, PaginationButtons } = usePayments(
    20,
    Number(pageNumber)
  );

  useEffect(() => {
    if (requestedPayments.length) {
      setDocs(
        requestedPayments.sort((a, b) => {
          return (
            b.requestedTimestamp?.getTime() - a.requestedTimestamp?.getTime()
          );
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestedPayments]);

  useEffect(() => {
    //@ts-ignore
    if (page.current !== pageNumber && !pageNumberCompleted) {
      history.replace({
        pathname: "payments",
        search: "?requestedPayments=" + page.current,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, page.current, pageNumber]);

  return (
    <Col>
      <Row>
        <h4>Payments to complete</h4>
      </Row>
      <Row>
        <Col>
          {!!visibleDocs.length ? (
            visibleDocs.map((payment) => {
              return (
                <Row key={payment.getDocPath()}>
                  <CompleteRequestedPayment requestedPayment={payment} />
                </Row>
              );
            })
          ) : (
            <Row>
              <p>None!</p>
            </Row>
          )}
          <PaginationButtons />
        </Col>
      </Row>
    </Col>
  );
};
