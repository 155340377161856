import { faPaperPlane } from "@fortawesome/free-regular-svg-icons";
import {
  faAnchor,
  faBook,
  faBookmark,
  faBookOpen,
  faClinicMedical,
  faGift,
  faGlasses,
  faHeart,
  faListAlt,
  faListOl,
  faMask,
  faGasPump,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Col, Container, NavLink, Row } from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
import NavItem from "react-bootstrap/NavItem";
import {
  Route,
  Switch,
  useHistory,
  useParams,
  useRouteMatch,
} from "react-router-dom";
import { firestore } from "../../firebase";
import AdminNavbar from "../AdminNavbar";
import ResourcesList from "../Resources/ResourcesList";
import AboutPage from "./About/AboutPage";
import AwarenessList from "./Awareness/AwarenessList";
import { CelebrationViewer } from "./Celebrations/CelebrationViewer";
import { ChapterViewer } from "./Chapters/ChapterViewer";
import { FavoritesListScreen } from "./Favorites/FavoritesListScreen";
import { JournalList } from "./Journals/JournalList";
import { LessonViewer } from "./Lessons/LessonViewer";
import { PagesList } from "./Pages/PagesList";
import { PlaylistList } from "./Playlist/PlaylistList";
import { ContentType, Program } from "./Program";
import { ProgramOverview } from "./ProgramOverview";
import GatingList from "./SessionGating/GatingList";
import { ValuesCategoryList } from "./Values/ValuesCategoryList";
import VoiceActorList from "./VoiceActors/VoiceActorList";

const ProgramDetail = (props: any) => {
  const { match } = props;
  let history = useHistory();

  const { key } = useParams<{ key: string }>();
  const [program, setActiveProgram] = useState<Program>();
  const [active, setActive] = useState<string>("");
  const fullMatch = useRouteMatch("/programs/:programID/:section?");

  useEffect(() => {
    if (!active) {
      const newActive = fullMatch.params["section"]
        ? fullMatch.params["section"]
        : "overview";
      setActive(newActive);
    }
    return firestore
      .collection("programs")
      .doc(key)
      .onSnapshot((snapshot: any) => {
        const u = Program.fromFirestore(snapshot);
        setActiveProgram(u);
      });
  }, []);

  if (!program) {
    return <></>;
  }

  const navLinker = (newActive: string, text: string, icon: any) => {
    return (
      <NavItem>
        <NavLink
          onClick={() => {
            setActive(newActive);
            history.push(match.url + "/" + newActive);
          }}
        >
          <Row>
            <Col>
              <strong
                className={
                  active === newActive ? "text-primary" : "text-secondary"
                }
              >
                {text}
              </strong>
            </Col>
            <Col xs={"auto"}>
              {" "}
              <FontAwesomeIcon icon={icon}></FontAwesomeIcon>
            </Col>
          </Row>
        </NavLink>
      </NavItem>
    );
  };

  return (
    <>
      <AdminNavbar></AdminNavbar>
      <Container fluid={true} style={{ backgroundColor: "white" }}>
        <Row>
          <Col xs={2} className={"sidebar "}>
            {program && (
              <div className={"sidebar-sticky"}>
                <Nav className={"flex-column "}>
                  {navLinker("overview", "Overview", faClinicMedical)}
                  {navLinker("pages", "Pages", faPaperPlane)}
                  {program.contentTypeKey === ContentType.ACT &&
                    navLinker("chapters", "Chapters", faBookmark)}
                  {program.contentTypeKey === ContentType.ACT &&
                    navLinker("lessons", "Lessons", faBookOpen)}
                  {program.contentTypeKey === ContentType.ACT &&
                    navLinker(
                      "awareness-activities",
                      "Awareness Activities",
                      faGlasses
                    )}
                  {navLinker("journals", "Journals", faBook)}
                  {program.contentTypeKey === ContentType.ACT &&
                    navLinker("playlists", "Playlists", faListOl)}
                  {program.contentTypeKey === ContentType.ACT &&
                    navLinker("celebrations", "Celebrations", faGift)}
                  {program.contentTypeKey === ContentType.ACT &&
                    navLinker("favorites", "Favorites", faHeart)}
                  {program.contentTypeKey === ContentType.ACT &&
                    navLinker("resources", "Resources", faListAlt)}
                  {program.contentTypeKey === ContentType.ACT &&
                    navLinker("values", "Values", faAnchor)}
                  {program.contentTypeKey === ContentType.ACT &&
                    navLinker("voice-actors", "Voice actors", faMask)}
                  {program.contentTypeKey === ContentType.ACT &&
                    navLinker("gating", "Gating", faGasPump)}
                  {program.initialDisplayState === "content" &&
                    navLinker("about-page", "About Page", faListAlt)}
                </Nav>
              </div>
            )}
          </Col>
          <Col
            xs={"10"}
            className={"main-content dashboard-container"}
            style={{ padding: 10 }}
          >
            <Row className={"dashboard-content"}>
              <Col>
                <Row>
                  <h1 className={"jumbotron-heading dashboard-heading"}>
                    {program.name} <small> {program.key}</small>
                  </h1>
                </Row>
                {program && (
                  <>
                    <Switch>
                      <Route exact={true} path={match.url}>
                        <ProgramOverview program={program}></ProgramOverview>
                      </Route>
                      <Route path={match.url + "/overview"}>
                        <ProgramOverview program={program}></ProgramOverview>
                      </Route>
                      <Route path={match.url + "/chapters"}>
                        <ChapterViewer programKey={key}></ChapterViewer>
                      </Route>
                      <Route path={match.url + "/lessons"}>
                        <LessonViewer programKey={key}></LessonViewer>
                      </Route>
                      <Route path={match.url + "/awareness-activities"}>
                        {" "}
                        <AwarenessList programKey={key}></AwarenessList>
                      </Route>
                      <Route path={match.url + "/journals"}>
                        {" "}
                        <JournalList programKey={key}></JournalList>
                      </Route>
                      <Route path={match.url + "/pages"}>
                        <PagesList program={program}></PagesList>
                      </Route>
                      <Route exact={false} path={match.url + "/playlists"}>
                        {" "}
                        <PlaylistList programKey={program.key}></PlaylistList>
                      </Route>
                      <Route path={match.url + "/celebrations"}>
                        <CelebrationViewer programKey={program.key} />
                      </Route>
                      <Route path={match.url + "/favorites"}>
                        <FavoritesListScreen programKey={program.key} />
                      </Route>
                      <Route path={match.url + "/resources"}>
                        <ResourcesList programKey={program.key} />
                      </Route>
                      <Route path={match.url + "/values"}>
                        <ValuesCategoryList programKey={program.key} />
                      </Route>
                      <Route path={match.url + "/voice-actors"}>
                        <VoiceActorList programKey={program.key} />
                      </Route>
                      <Route path={match.url + "/gating"}>
                        <GatingList programKey={program.key} />
                      </Route>
                      <Route path={match.url + "/about-page"}>
                        <AboutPage programKey={program.key} />
                      </Route>
                    </Switch>
                  </>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export { ProgramDetail };
