import { ValuesCategory } from "@swing-therapeutics/swingcore/dist/models/values/ValuesCategory";
import { getValuesCategoryCollection } from "@swing-therapeutics/swingcore/dist/utils/queries";
import { useFormik } from "formik";
import React, { useState } from "react";
import { Button, Col, Form, Modal } from "react-bootstrap";
import { useCollectionOnce } from "react-firebase-hooks/firestore";
import * as Yup from "yup";
import { firestore, functions } from "firebase";
import { BasicTextField } from "../../../utils/FormikHelpers";

interface ProgramCloneModalProps {
  show: boolean;
  close: Function;
  programKey: string;
}

export const ProgramCloneModal = ({
  show,
  close,
  programKey,
}: ProgramCloneModalProps) => {
  const [programs] = useCollectionOnce(firestore().collection("programs"));

  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: { sourceProgramID: programKey, destinationProgramID: "", destinationProgramName: ""},
    onSubmit: async (values) => {
      try {
        setLoading(true);
        await functions().httpsCallable("FS_copyProgram")(values);
        window.alert("Program cloned successfully! Please wait for 15 minutes before using the program for subcollections to be fully cloned.");
        close();
      } catch (error) {
        window.alert(
          "An error occurred while trying to clone this program. Check the developer console for details."
        );
        console.error(error);
      } finally {
        setLoading(false);
      }
    },
    validationSchema: Yup.object().shape({
      sourceProgramID: Yup.string().required('Source Program ID is required'),
      destinationProgramID: Yup.string().required('Destination Program ID is required'),
      destinationProgramName: Yup.string().required('Destination Program Name is required'),
    }),
  });

  const handleClose = () => {
    formik.resetForm();
    close();
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Clone Program</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Col>
          <p>Cloning program "{programKey}" to this program:</p>
        </Col>
        <BasicTextField
          formikHelpers={formik}
          path="destinationProgramID"
          title="New Program ID"
        />
        <BasicTextField
          formikHelpers={formik}
          path="destinationProgramName"
          title="New Program Name"
        />
      </Modal.Body>
      <Modal.Footer>
        {loading && (
          <p style={{ marginRight: "auto" }} data-testid="loading">
            Loading...
          </p>
        )}
        <Button onClick={handleClose} variant="outline-secondary">
          Close
        </Button>
        <Button
          data-testid="programCloneSubmit"
          disabled={
            loading || 
            formik.isSubmitting ||
            !formik.values.sourceProgramID ||
            !formik.values.destinationProgramID ||
            !formik.values.destinationProgramName
          }
          onClick={formik.submitForm}
          variant="outline-primary"
        >
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
