import React, { useEffect, useState } from "react";
import { Page } from "../Program";
import { Row, Button, Col, ListGroup, ListGroupItem } from "react-bootstrap";
import { firestore } from "../../../firebase";
import { Route, useHistory, useRouteMatch } from "react-router-dom";

import { PagesEdit } from "./PagesEdit";
import useRole from "../../../UseRole";

const PagesList = (props: any) => {
  const isAdmin = useRole("admin");
  const { program } = props;
  let history = useHistory();

  let match = useRouteMatch();
  const [pages, setPages] = useState<Page[]>([]);
  const [selectedPage, setSelectedPage] = useState<Page>();

  useEffect(() => {
    const unsubscribe = firestore
      .collection("programs")
      .doc(program.key)
      .collection("pages")
      .onSnapshot((snap) => {
        let m = snap.docs.map((m) => Page.fromFirestore(m));
        setPages(m);
      });
    return () => unsubscribe();
  }, [program.key]);

  const getPageRow = (page: Page) => {
    return (
      <ListGroupItem
        key={page.id}
        style={{ cursor: "pointer" }}
        active={page === selectedPage}
        onClick={() => {
          setSelectedPage(page);
        }}
      >
        <Row>
          <Col>{page.key}</Col>
          {isAdmin && (
            <Col xs={"auto"}>
              <Button
                variant={
                  page === selectedPage ? "outline-light" : "outline-primary"
                }
                onClick={() => {
                  setSelectedPage(page);
                  history.push(match.url + "/edit/" + page.key);
                }}
              >
                Edit
              </Button>
            </Col>
          )}
        </Row>
      </ListGroupItem>
    );
  };

  if (!pages) {
    return <p>Loading</p>;
  }

  return (
    <>
      <Row>
        <Col xs={12}>
          <h4> HTML Pages</h4>
        </Col>
      </Row>
      <Row>
        {match.isExact && (
          <>
            {isAdmin && (
              <Col xs={12}>
                <Button
                  variant={"outline-primary"}
                  onClick={() => {
                    history.push(match.url + "/edit");
                  }}
                >
                  New Page
                </Button>
              </Col>
            )}
            <Col xs={selectedPage ? 4 : 12}>
              <ListGroup variant={"flush"}>
                {pages.map((p) => getPageRow(p))}
              </ListGroup>
            </Col>
            {selectedPage && (
              <Col xs={8} className={"tempo_css"}>
                <div dangerouslySetInnerHTML={{ __html: selectedPage.HTML }} />
              </Col>
            )}
          </>
        )}
        <Route
          exact
          path={match.url + "/edit/"}
          render={() => <PagesEdit program={program}></PagesEdit>}
        ></Route>
        <Route
          exact
          path={match.url + "/edit/:pageKey"}
          render={() => (
            <PagesEdit page={selectedPage} program={program}></PagesEdit>
          )}
        ></Route>
      </Row>
    </>
  );
};

export { PagesList };
