import { 
    ILandingPage, 
    ILandingPageStyling,
    ILandingPageMetadata,
    ILandingPageHeader,
    ILandingPageSectionOne,
    ILandingPageSectionTwo,
    ILandingPageFooter,
    Faq,
} from "@swing-therapeutics/surveybay/dist/types";
import { firebaseTimestampToDate } from "@swing-therapeutics/swingcore/dist/utils/firebase";
import { firestore } from "../firebase";
import LandingPage from "./LandingPage";

const defaultLPFields = { text: "", color: "" };
const numberOfFaqs = 1;

const defaultMetaData: ILandingPageMetadata = {
  title: "",
  description: "",
  favicon: "",
  textColor: "",
  buttonColor: ""
}

const defaultHeaderData: ILandingPageHeader = {
    title: { ...defaultLPFields },
    subtitle: { ...defaultLPFields },
    banner: "",
    logo: "",
    hideFAQ: false,
    hideProfile: false
}

const defaultSectionOneData: ILandingPageSectionOne = {
    title: { ...defaultLPFields },
    subtitle: { ...defaultLPFields },
    banner: "",
    button: {
        ...defaultLPFields,
        link: ""
    }
}

const defaultSectionTwoData: ILandingPageSectionTwo = {
    title: { ...defaultLPFields },
    subtitle: { ...defaultLPFields },
    subsectionOne: {
        title: { ...defaultLPFields },
        subtitle: { ...defaultLPFields },
        svg: ""
    },
    subsectionTwo: {
        title: { ...defaultLPFields },
        subtitle: { ...defaultLPFields },
        svg: ""
    },
    subsectionThree: {
        title: { ...defaultLPFields },
        subtitle: { ...defaultLPFields },
        svg: ""
    },
    banner: "",
    button: {
        ...defaultLPFields,
        link: ""
    }
}

const defaultFooterData: ILandingPageFooter = {
    textColor: "",
    bgColor: "",
    logo: ""
}

const getEmptyFaqs = (): Faq[] => {
  return new Array(numberOfFaqs).fill(0).map((_value, index) => ({
    question: ``,
    answer: ``,
  }));
}

export type LandingPageStylingFormValues = Omit<ILandingPageStyling, 'created' | 'updated' | 'stylingID'>;

class LandingPageStyling {
    private _docPath: string;
    created: Date | undefined;
    updated: Date;
    metadata: ILandingPageMetadata;
    header: ILandingPageHeader;
    sectionOne: ILandingPageSectionOne;
    sectionTwo: ILandingPageSectionTwo;
    footer: ILandingPageFooter;
    stylingID: string;
    landingPageKey: string;
    faqs: Faq[];

    constructor(props) {
        Object.assign(this, {
        ...props,
        created: firebaseTimestampToDate(props.created),
        updated: firebaseTimestampToDate(props.updated),
        })
    }

  static fromFirestore(docSnap: firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>) {
    const data = docSnap.data() as ILandingPageStyling;
    const lpStyling = new LandingPageStyling(data)
    lpStyling._docPath = docSnap.ref.path;
    return lpStyling;
  }

  data(): ILandingPageStyling {
    const data = { ...this }
    delete data._docPath;
    return data;
  }

  getDocPath() {
    // Userful for unique identifier when mapping
    return this._docPath;
  }

  getEditableData(): LandingPageStylingFormValues {
    return {
        metadata: this.metadata,
        header: this.header,
        sectionOne: this.sectionOne,
        sectionTwo: this.sectionTwo,
        footer: this.footer,
        landingPageKey: this.landingPageKey,
        faqs: this.faqs || getEmptyFaqs(),
    }
  }

  static getEditableData(): LandingPageStylingFormValues {
    return {
        metadata: { ...defaultMetaData },
        header: { ...defaultHeaderData },
        sectionOne: { ...defaultSectionOneData },
        sectionTwo: { ...defaultSectionTwoData },
        footer: { ...defaultFooterData },
        landingPageKey: "",
        faqs: getEmptyFaqs(),
    }
  }

  async persist() {
    const data = this.data();
    data.updated = new Date();
    return await firestore.doc(this._docPath).update(data);
  }

  static async persistNewLandingPage(values: LandingPageStylingFormValues, landingPageKey: string): Promise<LandingPageStyling> {
    // Find the landing page
    const landingPage = await LandingPage.fromLandingPageKey(landingPageKey);

    const newLPStylingID: Omit<ILandingPageStyling, 'stylingID'> = {
      ...values,
      created: new Date(),
      updated: new Date(),
    }

    // Add in the newly created styling
    const newLPStylingRef = await firestore.collection(`web/surveysaurus/landingPages/${landingPageKey}/styling`).add(newLPStylingID);
    const newLPStyling: ILandingPageStyling = {
      ...values,
      created: new Date(),
      updated: new Date(),
      stylingID: newLPStylingRef.id
    }

    // Tie the new styling to the landing page
    landingPage.stylingID = newLPStylingRef.id;
    await landingPage.persist();
    
    newLPStylingRef.update({
      stylingID: newLPStylingRef.id
    })

    return new LandingPageStyling(newLPStyling);
  }

  static async deleteLandingPageExt(landingPageKey: string, landingPageExt: LandingPageStyling) {
    // Delete the styling doc from stylings collection inside of current landing page
    await firestore.collection(`web/surveysaurus/landingPages/${landingPageKey}/styling`).doc(landingPageExt.stylingID).delete();
    // Need to update base Landing Page stylingID to false
    await firestore.collection("web/surveysaurus/landingPages").doc(landingPageKey).update({
      stylingID: false
    });
  }

  static async fromLandingPageKey(landingPageKey: string): Promise<LandingPageStyling> {
    const landingPageRef = await firestore.doc(`web/surveysaurus/landingPages/${landingPageKey}`).get();
    if (!landingPageRef.exists) {
      throw new Error(`Landing page with key ${landingPageKey} does not exist`);
    }
    const landingPageData = landingPageRef.data() as ILandingPage;
    if (!landingPageData.stylingID) {
        throw new Error(`Landing page does not have any styling`);
      }
    const landingPageStylingRef = await firestore.doc(`web/surveysaurus/landingPages/${landingPageKey}/styling/${landingPageData.stylingID}`).get();
    if (!landingPageStylingRef.exists) {
      throw new Error(`Landing page with key ${landingPageKey} and styling ID $${landingPageData.stylingID} does not exist`);
    }
    return LandingPageStyling.fromFirestore(landingPageStylingRef);
  }
}

export default LandingPageStyling;
