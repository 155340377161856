import React, { useEffect, useState } from "react";
import LoadingScreen from "../LoadingScreen";
import { DocumentData, firebase, firestore } from "../../firebase";
import AdminNavbar from "../AdminNavbar";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { useCollectionClassData } from "../../utils/Hooks";
import Medrio from "../../Model/Medrio";
import {
  Col, Container, Form, FormControl, Jumbotron, Row, Table,
} from "react-bootstrap";

interface MedrioRowProps {
  medrioRef: DocumentData;
  filter: string;
}

const MedrioRow: React.FC<MedrioRowProps> = ({ medrioRef, filter }) => {
  const history = useHistory();
  const medrio = medrioRef.data();

  const formUpdateQuery = firestore
    .collection("medrio")
    .doc("events")
    .collection("triggers")
    .where("FormEventData.SubjectIdentifier", "==", medrio.FormEventData.SubjectIdentifier);

  const [formUpdate] = useCollectionClassData(Medrio, formUpdateQuery);
  const [check, setCheck] = useState(false);

  useEffect(() => {
  formUpdate.map((medrioData) => {
    if (medrioData.FormEventData.EventType === 'FormDataUpdated') {
      setCheck(true);
    }
  })
}, [check, formUpdate])

  if (filter && !medrio?.FormEventData.SubjectIdentifier?.toLowerCase().includes(filter)) {
    return <></>;
  }

  const handleRowClick = () => {
    history.push("/medrio/" + medrio.FormEventData.SubjectIdentifier);
  }


  return (
    <tr className={"hoverablerow "} style={{color: medrio.FormEventData.EventType === 'FormDataUpdated' || medrio.FormEventData.FormCompletionStatus === 'NotComplete' || check === true ? 'red' : ''}} onClick={handleRowClick}>
      <td>
        {medrio.FormEventData.SubjectIdentifier}
      </td>
      <td>
        {medrio.FormEventData.DataEntryUserName}
      </td>
      <td>
        {medrio.FormEventData.StudyTitle}
      </td>
      <td>
        {medrio.FormEventData.EventType}
      </td>
      <td>
        {medrio.FormEventData.SubjectStatus}
      </td>
      <td>
        {medrio.FormEventData.FormCompletionStatus}
      </td>
      <td>
        {medrio.FormEventData.FormName}
      </td>
      <td>
        {medrio.FormEventData.DataEntryDate}
      </td>
      <td>
        {medrio.retries}
      </td>
    </tr>
  );
};

const MedrioList: React.FC<RouteComponentProps> = () => {
  const [filter, setFilter] = useState("");
  const [users, setUsers] = useState<firebase.firestore.DocumentData>(null);

  useEffect(() => {
    let query = firestore.collection("medrio").doc('events').collection('playback').orderBy("timestamp", "desc");
    const subscriber = query.onSnapshot((snap) => {
        console.log(snap);
      setUsers(snap);
    }, (error) => {
      console.log("error occured loading users", error);
    });
    return subscriber;
  }, []);

  return (
    <>
      <AdminNavbar />
      <Jumbotron>
        <Container fluid={true}>
          <Row>
            <Col>
              <h1>Medrio Events</h1>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form>
                <Form.Row>
                  <Col xs={5} md={4} lg={3}>
                    <Form.Label> Search </Form.Label>
                    <Form.Group className="">
                      <FormControl
                        id="search"
                        placeholder="Search"
                        onChange={(e) => {
                          setFilter(e.target.value.trim());
                        }}
                      />
                    </Form.Group>
                  </Col>
                </Form.Row>
              </Form>
            </Col>
          </Row>
        </Container>
      </Jumbotron>
      <Container fluid={true} style={{ backgroundColor: "white" }}>
        <Row>
          {!users && (
            <>
              <strong>Subjects Loading</strong>
              <br></br>
              <LoadingScreen></LoadingScreen>
            </>
          )}
          {!!users && (
            <Col xs={12}>
              <Table striped responsive>
                <thead key="systemEventsTable">
                  <tr>
                    <th>Subject ID</th>
                    <th>Username</th>
                    <th>Study Title</th>
                    <th>Event Type</th>
                    <th>Subject Status</th>
                    <th>Form Completion Status</th>
                    <th>Form Name</th>
                    <th>Date</th>
                    <th>Retries</th>
                  </tr>
                </thead>
                <tbody>
                  {users.docs.map((u: DocumentData) => {
                    return (
                      <MedrioRow
                        medrioRef={u}
                        filter={filter}
                        key={u.id}
                      />
                    )
                  })}
                </tbody>
              </Table>
            </Col>
          )}
        </Row>
      </Container>
    </>
  );
};

export default MedrioList;
