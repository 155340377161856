import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormikErrors } from "formik";
import { get } from "lodash";
import React from "react";
import {
  Col,
  Form,
  OverlayTrigger,
  Popover,
  Row,
  Image,
} from "react-bootstrap";
import { ValidationError } from "yup";
import EasyEdit, { EasyEditMarkupType } from "../Components/EasyEdit";

export const getFirstFormikError = (errors: any) => {
  for (let error in errors) {
    return <div style={{ color: "red" }}>{`${errors[error]}`}</div>;
  }
  return <></>;
};

export const formikHasError = (errors: any) => Object.values(errors).length > 0;

export const isValidationError = (error: any): error is ValidationError => {
  return error && error.name === 'ValidationError'
}

export interface FormikHelpers {
  values: Record<string, any>;
  errors: Record<string, any>;
  touched: Record<string, any>;
  setFieldValue?: (field: string, value: any, shouldValidate?: boolean) => void;
  handleChange: (event: React.ChangeEvent) => void;
  setFieldTouched?: (
    field: string,
    touched?: boolean,
    shouldValidate?: boolean
  ) => Promise<void> | Promise<FormikErrors<any>>;
  formSubmitPressed?: boolean;
}

interface FieldHeaderProps {
  title?: string;
  tooltip?: string;
  imageURL?: string;
}

export const FieldHeader = ({ title, tooltip, imageURL }: FieldHeaderProps) => (
  <Row>
    <Col xs="auto">
      <Form.Label>{title}</Form.Label>
    </Col>
    {(tooltip || imageURL) && (
      <Col xs="auto" className="px-0">
        <OverlayTrigger
          trigger="click"
          placement="top"
          rootClose
          overlay={
            <Popover id={`popover-${tooltip}`}>
              <Popover.Content>
                <Row>
                  <Col>
                    {imageURL && (
                      <Image style={{ maxWidth: "100%" }} src={imageURL} />
                    )}
                    {tooltip && tooltip}
                  </Col>
                </Row>
              </Popover.Content>
            </Popover>
          }
        >
          <FontAwesomeIcon icon={faQuestionCircle} />
        </OverlayTrigger>
      </Col>
    )}
  </Row>
);

interface BasicFieldProps {
  path: string;
  title?: string;
  md?: number | string;
  lg?: number | string;
  tooltip?: string;
  imageURL?: string;
  formikHelpers: FormikHelpers;
  disabled?: boolean;
  placeholder?: string;
}

interface BasicEasyEditProps extends BasicFieldProps {
  markupType: EasyEditMarkupType;
}

interface BasicNumberProps extends BasicFieldProps {
  defaultValue?: number;
}

interface BasicTextAreaProps extends BasicFieldProps {
  rows?: number;
}

interface BasicOptionProps extends BasicFieldProps {
  options: { name: string; value: string | number }[];
  undefinedOption?: string;
}

export const BasicTextField = ({
  path,
  title,
  md = 12,
  lg = 6,
  formikHelpers,
  tooltip,
  imageURL,
  disabled = false,
  placeholder,
}: BasicFieldProps) => {
  const {
    values,
    errors,
    touched,
    handleChange,
    formSubmitPressed,
  } = formikHelpers;
  return (
    <Form.Group as={Col} md={md} lg={lg}>
      {!!title && (
        <FieldHeader title={title} tooltip={tooltip} imageURL={imageURL} />
      )}
      <Form.Control
        type="text"
        name={path}
        placeholder={placeholder ?? ''}
        data-testid={path}
        disabled={disabled}
        value={get(values, path) || ""}
        onChange={handleChange}
        isInvalid={
          get(errors, path) && (formSubmitPressed || get(touched, path))
        }
      />
      <Form.Control.Feedback data-testid={`error-${path}`} type="invalid">
        {get(errors, path)}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

export const BasicNumberField = ({
  path,
  title,
  md = 12,
  lg = 6,
  formikHelpers,
  defaultValue,
  tooltip,
  imageURL,
  disabled = false,
}: BasicNumberProps) => {
  const {
    errors,
    touched,
    handleChange,
    formSubmitPressed,
    values,
  } = formikHelpers;
  return (
    <Form.Group as={Col} md={md} lg={lg}>
      <FieldHeader title={title} tooltip={tooltip} imageURL={imageURL} />
      <Form.Control
        type="number"
        name={path}
        value={get(values, path) ?? defaultValue}
        data-testid={path}
        disabled={disabled}
        onChange={handleChange}
        isInvalid={
          get(errors, path) && (formSubmitPressed || get(touched, path))
        }
      />
      <Form.Control.Feedback data-testid={`error-${path}`} type="invalid">
        {get(errors, path)}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

export const BasicTextAreaField = ({
  path,
  title,
  md = 12,
  lg = 6,
  rows = 1,
  formikHelpers,
  tooltip,
  imageURL,
  disabled = false,
  placeholder,
}: BasicTextAreaProps) => {
  const {
    values,
    errors,
    touched,
    handleChange,
    formSubmitPressed,
  } = formikHelpers;
  return (
    <Form.Group as={Col} md={md} lg={lg}>
      <FieldHeader title={title} tooltip={tooltip} imageURL={imageURL} />
      <Form.Control
        as="textarea"
        rows={rows}
        name={path}
        placeholder={placeholder ?? ''}
        data-testid={path}
        disabled={disabled}
        value={get(values, path)}
        onChange={handleChange}
        isInvalid={
          get(errors, path) && (formSubmitPressed || get(touched, path))
        }
      />
      <Form.Control.Feedback data-testid={`error-${path}`} type="invalid">
        {get(errors, path)}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

export const BasicBooleanField = ({
  path,
  title = "",
  md = 12,
  lg = 6,
  formikHelpers,
  tooltip,
  imageURL,
  disabled = false,
}: BasicFieldProps) => {
  const {
    values,
    errors,
    touched,
    setFieldValue,
    formSubmitPressed,
    setFieldTouched,
  } = formikHelpers;
  return (
    <Form.Group as={Col} md={md} lg={lg}>
      <FieldHeader title={title} tooltip={tooltip} imageURL={imageURL} />
      <Form.Check
        type="checkbox"
        name={path}
        data-testid={path}
        disabled={disabled}
        checked={get(values, path)}
        onChange={() => {
          setFieldValue(path, !get(values, path));
          setFieldTouched(path, true, true);
        }}
        isInvalid={
          get(errors, path) && (formSubmitPressed || get(touched, path))
        }
      />
      <Form.Control.Feedback data-testid={`error-${path}`} type="invalid">
        {get(errors, path)}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

export const BasicEasyEditField = ({
  path,
  title = "",
  md = 12,
  lg = 6,
  formikHelpers,
  tooltip,
  imageURL,
  disabled = false,
  markupType,
}: BasicEasyEditProps) => {
  const {
    values,
    errors,
    touched,
    setFieldValue,
    formSubmitPressed,
  } = formikHelpers;
  return (
    <Form.Group as={Col} md={md} lg={lg}>
      <FieldHeader title={title} tooltip={tooltip} imageURL={imageURL} />
      <EasyEdit
        markupType={markupType}
        content={get(values, path)}
        disabled={disabled}
        onChange={(value) => setFieldValue(path, value)}
      />
      {get(errors, path) && (formSubmitPressed || get(touched, path)) && (
        <div style={{ fontSize: 12, color: '#e74c3c'}}>{get(errors, path)}</div>
      )}
    </Form.Group>
  );
};

export const BasicOptionField = ({
  path,
  title,
  md = 12,
  lg = 6,
  formikHelpers,
  tooltip,
  imageURL,
  options,
  undefinedOption = undefined,
  disabled = false,
}: BasicOptionProps) => {
  const {
    values,
    errors,
    touched,
    formSubmitPressed,
    setFieldValue,
  } = formikHelpers;
  return (
    <Form.Group as={Col} md={md} lg={lg}>
      <FieldHeader title={title} tooltip={tooltip} imageURL={imageURL} />
      <Form.Control
        as="select"
        disabled={disabled}
        name={path}
        data-testid={path}
        value={get(values, path) || ""}
        onChange={(selected) => setFieldValue(path, selected.target.value)}
        isInvalid={
          get(errors, path) && (formSubmitPressed || get(touched, path))
        }
      >
        {undefinedOption && (
          <option
            key={undefinedOption}
            value={""}
            disabled={get(values, path) !== ""}
          >
            {undefinedOption}
          </option>
        )}
        {options.map(({ name, value }) => (
          <option key={`${value}.${name}`} value={value}>
            {name}
          </option>
        ))}
      </Form.Control>
      <Form.Control.Feedback data-testid={`error-${path}`} type="invalid">
        {get(errors, path)}
      </Form.Control.Feedback>
    </Form.Group>
  );
};
