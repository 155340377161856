import React from "react";
import { Col, Row } from "react-bootstrap";
import { DisclaimerWorkflowData, Workflow } from "../../../../Model/Workflow";

interface DisclaimerViewProps {
  workflow: Workflow;
}

const DisclaimerView: React.FC<DisclaimerViewProps> = ({ workflow }) => {
  const workflowData = workflow.workflowData as DisclaimerWorkflowData;

  return (
    <Col>
      <Row>
        <h5>Disclaimer accepted:{' '}
          <span className={workflowData.disclaimerAccepted ? 'text-success' : 'text-danger'}>
            {workflowData.disclaimerAccepted.toString()}
          </span>
        </h5>
      </Row>
      <Row>
        <h5>Disclaimer text:</h5>
      </Row>
      <Row>
        <p>{workflowData.disclaimerText}</p>
      </Row>
    </Col>
  )
}

export default DisclaimerView;
