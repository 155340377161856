import React from "react";
import { Button, Col, Form, Modal } from "react-bootstrap";
import { useFormik } from "formik";
import { firestore } from "firebase";

interface ChapterEditorProps {
  programKey: string;
  activeChapter: any;
  show: boolean;
  close: Function;
}

const ChapterEditor = ({
  programKey,
  activeChapter,
  show,
  close,
}: ChapterEditorProps) => {
  const formik = useFormik({
    initialValues: { ...activeChapter },

    validate: (values) => {
      const errors: { type?: string } = {};
      const trimmedDescription = values.description.trim();
      if (values.title.length === 0) {
        errors.type = "*Chapter title needed";
      }
      if (trimmedDescription.length === 0) {
        errors.type = "*Chapter description needed";
      }
      return errors;
    },

    onSubmit: async (values) => {
      firestore()
        .collection("programs")
        .doc(programKey)
        .collection("chapters")
        .doc(values.id)
        .set(values);
      close();
    },
  });

  const getError = (errors: any) => {
    for (let error in errors) {
      return <div style={{ color: "red" }}>{errors[error]}</div>;
    }
  };

  return (
    <Modal show={show} onHide={close}>
      <Modal.Header closeButton>
        <Modal.Title>Edit Chapter {activeChapter.num}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Row>
          <Form.Group as={Col}>
            <Form.Label>Title</Form.Label>
            <Form.Control
              name="title"
              value={formik.values.title}
              onChange={formik.handleChange}
            />
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col}>
            <Form.Label>Description</Form.Label>
            <Form.Control
              as="textarea"
              rows={5}
              name="description"
              value={formik.values.description}
              onChange={formik.handleChange}
            />
          </Form.Group>
        </Form.Row>
      </Modal.Body>
      <Modal.Footer>
        <Col>{getError(formik.errors)}</Col>
        <Button onClick={() => close()} variant="secondary">
          Close
        </Button>
        <Button
          onClick={() => formik.submitForm()}
          variant="success"
          disabled={!formik.dirty || !formik.isValid}
        >
          Save changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export { ChapterEditor };
