import type { TextJournalQuestion } from "@swing-therapeutics/swingcore/dist/models/journals/JournalInterfaces";
import { JournalTemplate } from "@swing-therapeutics/swingcore/dist/models/journals/JournalTemplate";
import { TextPacingQuestion } from "@swing-therapeutics/swingcore/dist/models/journals/PacingJournalInterfaces";
import { cloneDeep, isNil } from "lodash";
import React, { useRef, useState } from "react";
import { Button, Card, Col, Pagination, Row } from "react-bootstrap";
import JournalQuestionForm, { JournalQuestionFormRef } from "./JournalQuestionForm";
import { knownQuestionTypes } from "./types";
import { UnknownQuestion } from "./UnknownQuestion";
import { PacingJournal } from "@swing-therapeutics/swingcore/dist/models/journals/PacingJournal";

interface JournalQuestionURL {
  programID?: string;
  journalID?: string;
}

interface QuestionCardProps {
  phase: string,
  journal: JournalTemplate,
}

export const AccordionQuestionCard = ({
  phase,
  journal,
}: QuestionCardProps) => {
  const questionFormRef = useRef<JournalQuestionFormRef>();
  const [selectedQuestion, setSelectedQuestion] = useState(0);

  const questionExists = !isNil(journal.phases?.[phase]?.questions?.[selectedQuestion]);
  const questionType = journal.phases.creation.questions?.[selectedQuestion]?.questionType;
  const isUnknownQuestionType = !knownQuestionTypes.has(questionType);
  const isPacingJournal = PacingJournal.isPacingJournalID(journal.id);

  const onQuestionChange = (questionIndex: number) => {
    const canChangeQuestions = isUnknownQuestionType || questionFormRef.current?.resetForm('Are you sure you want to change questions? Your unsaved changes to the current question will be lost.');

    if (canChangeQuestions) {
      setSelectedQuestion(questionIndex);
    }
  };

  const addNewQuestion = (phase: string) => {
    if (questionExists && !questionFormRef.current?.resetForm('Are you sure you want to add a new question? Your unsaved changes to the current question will be lost.')) {
      return;
    }

    const newKeyName = window.prompt('Enter a key name for the new question:');
    if (newKeyName === null) {
      return;
    }

    if (questionExists && journal.phases[phase].questions.some(question => question.key === newKeyName)) {
      return window.alert('A question already exists with that key. Please use a unique key name.');
    }

    const basicFields = {
      type: "text",
      key: newKeyName,
      order: questionExists ? journal.phases[phase].questions.length + 1 : 1,
      required: true,
      summaryText: "",
      screenText: {
        navigationTitle: "",
        promptText: "",
        placeholderText: "Record your thoughts here...",
        PrevButtonText: "Back",
        NextButtonText: "Next",
      },
      validation: {
        minLength: 0,
        maxLength: 512,
      },
    }

    const newQuestion = isPacingJournal ?
      { questionType: "pacing-text", ...basicFields } as TextPacingQuestion
      :
      { questionType: "text", ...basicFields } as TextJournalQuestion;

    const newQuestions = cloneDeep(journal.phases[phase].questions) ?? [];
    newQuestions.push(newQuestion);

    journal.query()
      .update({
        [`phases.${phase}.questions`]: newQuestions
      })
      .then(() => {
        window.alert('New question added successfully!');
      })
      .catch(error => {
        window.alert('An error occurred when saving this question. Check the developer console for details.');
        console.error(error);
      });
  };

  const deleteCurrentQuestion = () => {
    if (
      window.confirm('Are you sure you want to delete this question? This cannot be undone.')
    ) {
      journal.removeQuestionAtIndex(phase, selectedQuestion)
      .then(() => {
        window.alert('Question deleted successfully!')
        setSelectedQuestion(0);
      })
      .catch(error => {
        window.alert('An error occurred when deleting this question. Check the developer console for details.');
        console.error(error);
      });
    }
    
  };

  const moveQuestion = () => {
    const questions = journal.phases[phase]?.questions || [];

    const targetQuestionNumberPrompt = window.prompt(`Enter the new question number for this question (currently question ${selectedQuestion + 1})`);
    if (targetQuestionNumberPrompt === null) {
      return;
    }

    const targetQuestionNumber = Number(targetQuestionNumberPrompt);
    if (!Number.isInteger(targetQuestionNumber)) {
      return window.alert('Input must be an integer');
    } else if (Number.isInteger(targetQuestionNumber) && Number(targetQuestionNumber) < 1) {
      return window.alert('Input must be > 0');
    } else if (questions.length <= 1) {
      return window.alert('There must be at least 2 questions available to change a question number');
    } else if (!questions[targetQuestionNumber - 1]) {
      return window.alert(`Invalid question number (range: 1-${questions.length})`);
    } else if (selectedQuestion === targetQuestionNumber - 1) {
      return window.alert('The question already has that question number!');
    }

    journal.moveQuestionToIndex(phase, selectedQuestion, targetQuestionNumber - 1)
      .then(() => {
        window.alert('Question moved successfully!');
        setSelectedQuestion(targetQuestionNumber - 1);
      })
      .catch(error => {
        window.alert('An error occurred when moving this question. Check the developer console for details.');
        console.error(error);
      });
  };

  return (
    <Card className="m-3">
      <Card.Title className="ml-3 mt-3 mb-0">Questions</Card.Title>
      <Card.Body>
        <Row>
          <Col xs="auto" className="pr-0">
            <Pagination>
              {journal.phases[phase]?.questions?.map(
                (question, questionIndex) => (
                  <Pagination.Item
                    onClick={() => onQuestionChange(questionIndex)}
                    key={questionIndex}
                    active={questionIndex === selectedQuestion}
                  >
                    {questionIndex + 1}
                  </Pagination.Item>
                )
              )}
            </Pagination>
          </Col>
          <Col xs="auto">
            <Button onClick={() => addNewQuestion(phase)} variant="info">+</Button>
          </Col>
          <Col>
          <Row className="d-flex justify-content-end">
            {questionExists && 
              <Col xs="auto">
                <Button onClick={moveQuestion} variant="outline-info">Change Question Number</Button>
              </Col>
            }
            {questionExists && 
              <Col xs="auto">
                <Button onClick={deleteCurrentQuestion} variant="outline-danger">Delete Question</Button>
              </Col>
            }
          </Row>
          </Col>
        </Row>
        {questionExists && isUnknownQuestionType && <UnknownQuestion question={journal.phases.creation.questions?.[selectedQuestion]} />}

        {questionExists && !isUnknownQuestionType &&
          <JournalQuestionForm
            ref={questionFormRef}
            journal={journal}
            key={`${journal.id}-${phase}-${selectedQuestion}`}
            question={
              journal.phases[phase].questions[selectedQuestion]
            }
            journalType={journal.id}
            phase={phase}
            questionType={
              journal.phases[phase].questions[selectedQuestion]
                .questionType
            }
          />
        }
      </Card.Body>
    </Card>
  )
}
