import type { firestore as firestoreType } from "firebase";
import type { LogData } from "./types"
import { firestore } from "../../../firebase";

// This stuff should eventually be moved to tacosaurus
// For access across repos

const logLocation = `tempest/logs/surveysaurus`;

/*
* Pass in an optional batch parameter if writing to database in a batch
* Should couple actions permformed on database with the log writing 
* so if one fails the log doesn't get written
*/
export const surveysaurusLog = async (logData: LogData, batch?: firestoreType.WriteBatch) => {
  const logRef = firestore.collection(logLocation);
  if (batch) {
    // Doing doc() does not write to database but makes
    // A new reference to the next doc that will be added
    // Batch API does not provide a .add
    batch.set(logRef.doc(), logData);
  } else {
    await logRef.add(logData);
  }
}
