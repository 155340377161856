import React, { useCallback, useEffect, useRef, useState } from "react";
import LandingPageStyling, { LandingPageStylingFormValues } from '../../../Model/LandingPageStyling';
import LandingPage, { LandingPageFormValues } from "../../../Model/LandingPage";
import LoadingScreen from "../../LoadingScreen";
import AddFaqDisplay from "./AddFaqDisplay";
import { Col, Container, Row, Form } from "react-bootstrap";
import { firestore } from "../../../firebase";
import { Field, Form as FormikForm, Formik } from "formik";
import { FormikSubmitButton, FormikTextField } from "../../FormikFields";
import { FormSchema } from "./formSchemas/formSchemaStyling";
import { Redirect } from "react-router";
import { storage } from "firebase";
import { set as setObj } from "lodash";
interface Props {
  action: "new" | "edit";
  landingPageKey?: string;
}

const LandingPageStylingEdit: React.FC<Props> = ({ action, landingPageKey }) => {
  const [error, setError] = useState<string>(null);
  const [initialFormValues, setInitialFormValues] = useState<LandingPageStylingFormValues>(null);
  const [message, setMessage] = useState("");
  const isMounted = useRef(true);
  const landingPage = useRef<LandingPageStyling>(null);

  let ImageProp  = { url: "", obj: File };

  const imageInputs = {
    'metadata.favicon': ImageProp,
    'header.banner': ImageProp,
    'header.logo': ImageProp,
    'sectionOne.banner': ImageProp,
    'sectionTwo.subsectionOne.svg': ImageProp,
    'sectionTwo.subsectionTwo.svg': ImageProp,
    'sectionTwo.subsectionThree.svg': ImageProp,
    'footer.logo': ImageProp
  };

  const [imageNames, setImageNames] = useState(imageInputs);

  const fetchAndSetInitialFormValues = useCallback(async () => {
    if(landingPageKey){
      let lp;
      try {
        lp = await LandingPageStyling.fromLandingPageKey(landingPageKey);
      }catch(e) {
        console.error(e);
        const lpData = LandingPageStyling.getEditableData();
        await LandingPageStyling.persistNewLandingPage(lpData, landingPageKey);
        lp = await LandingPageStyling.fromLandingPageKey(landingPageKey);
      }
      
      const lpDocSnap = await firestore.doc(`${lp.getDocPath()}`).get();
      const lpExt = LandingPageStyling.fromFirestore(lpDocSnap);
      const lpData = lpExt.getEditableData();
      landingPage.current = lpExt;
      setInitialFormValues(lpData);
    }else{
      const lpData = LandingPageStyling.getEditableData();
      setInitialFormValues(lpData);
    }
  }, [landingPageKey])

  useEffect(() => {
    isMounted.current = true;
    fetchAndSetInitialFormValues();
    return () => { isMounted.current = false };
  }, [fetchAndSetInitialFormValues])

  const setDisappearingMessage = useCallback((msg) => {
    setMessage(msg);
    setTimeout(() => {
      isMounted.current && setMessage(null);
    }, 5000);
  }, [isMounted])

  if (action !== "new" && !landingPageKey) {
    console.error("Landing page key not defined for edit landing page");
    return <Redirect to="/surveysaurus" />
  }

  const uploadImages = async (values) => {
    
    await Promise.all(
      Object.keys(imageNames).map(async (image: any) => {
        if(!image && !imageNames[image].obj.hasOwnProperty('type'))
          return Promise.resolve();

        if(!imageNames[image].obj.type)
          return Promise.resolve();

        const file: File = imageNames[image].obj;

        const storageRef = storage().ref().child('imgs/landing_pages/' + file.name);
        
        const snapshot = await storageRef.put(file);

        const downloadURL = await snapshot.ref.getDownloadURL();

        setObj(values, image, downloadURL);
        
        return Promise.resolve(image);
      })
    );
    
  }

  const handleSubmit = async (values: LandingPageStylingFormValues, actions) => {
    try {
      if (action === "new") {
        // New landing page created
        let landingPageValues: LandingPageFormValues;
        landingPageValues = {
          landingPageKey: values.landingPageKey,
          ...landingPageValues
        };

        await uploadImages(values);
        
        const landingPage = await LandingPage.persistNewLandingPage(landingPageValues);
        await LandingPageStyling.persistNewLandingPage(values, landingPage.landingPageKey);
        actions.resetForm();
        
        setDisappearingMessage("New landing page created");
      }
      else {
        // Landing page edited
        await uploadImages(values);
        Object.assign(landingPage.current, { ...values });
        await landingPage.current.persist();
        setInitialFormValues({ ...landingPage.current.getEditableData() });
        setDisappearingMessage("Landing page updated");
      }
    } catch (error) {
      console.error(error);
      setError(`Unable to save form values: ${error}`)
    } finally {
      actions.setSubmitting(false);
    }
  }

  const handleFileChange = (e: any) => {
    let updateValues = { ...imageNames };
    updateValues[e.target.name] = {
      url: URL.createObjectURL(e.target.files[0]),
      obj: e.target.files[0]
    };
    setImageNames(prev => ({ ...prev, ...updateValues }));
  };

  const imageStyle = { maxWidth: '200px', maxHeight: '200px' };

  return (
    <Container>
      <Row className="mb-3">
        <h3>
          {action === "new"
            ? "Styling new landing page"
            : "Edit Styling landing page"}
        </h3>
      </Row>
      <Row>
        {initialFormValues ? (
          <Formik
            initialValues={{ ...initialFormValues }}
            enableReinitialize={false}
            onSubmit={handleSubmit}
            validateOnChange={false}
            validationSchema={FormSchema}
          >
            <FormikForm role="form" style={{ width: "100%" }}>
              <Container>
                <Row>
                  <Container>
                    <hr />
                    <h5>Metadata:</h5>
                    <hr />
                    <Row>
                      <Col>
                        <FormikTextField
                          name="landingPageKey"
                          label="Landing page key"
                          desc="Key to use to point to landing page on Surveysaurus landing page"
                        />
                      </Col>
                      <Col sm={12} md={6}>
                        <FormikTextField
                          name="metadata.title"
                          label="Landing Page Title"
                          desc="Metadata Title to be used for Surveysaurus landing page"
                        />
                      </Col>
                      <Col sm={12} md={6}>
                        <FormikTextField
                          name="metadata.description"
                          label="Landing Page Description"
                          desc="Metadata Description to be used for Surveysaurus landing page"
                        />
                      </Col>
                      <Col sm={12} md={6}>
                        <h6>Favicon:</h6>
                        {
                          <img
                            src={
                              imageNames["metadata.favicon"].url ||
                              initialFormValues.metadata.favicon
                            }
                            alt=""
                            style={imageStyle}
                          />
                        }
                        {
                          <Form.Control
                            as="input"
                            type="file"
                            name="metadata.favicon"
                            onChange={handleFileChange}
                          />
                        }
                      </Col>
                      <Col sm={12} md={6}>
                        <FormikTextField
                          type="color"
                          name="metadata.textColor"
                          label="Title Color"
                          desc="Default Text Color"
                        />
                      </Col>
                      <Col sm={12} md={6}>
                        <FormikTextField
                          type="color"
                          name="metadata.buttonColor"
                          label="Button Color"
                          desc="Default Button Color"
                        />
                      </Col>
                    </Row>
                  </Container>
                  <Container>
                    <hr />
                    <h5>Header:</h5>
                    <hr />
                    <Row>
                      <Col sm={12} md={6}>
                        <FormikTextField
                          name="header.title.text"
                          label="Title Text"
                          desc=""
                        />
                      </Col>
                      <Col sm={12} md={6}>
                        <FormikTextField
                          type="color"
                          name="header.title.color"
                          label="Title Color"
                          desc=""
                        />
                      </Col>
                      <Col sm={12} md={6}>
                        <FormikTextField
                          name="header.subtitle.text"
                          label="Subtitle Text"
                          desc=""
                        />
                      </Col>
                      <Col sm={12} md={6}>
                        <FormikTextField
                          type="color"
                          name="header.subtitle.color"
                          label="Subtitle Color"
                          desc=""
                        />
                      </Col>
                      <Col sm={12} md={6}>
                        <h6>Banner:</h6>
                        {
                          <img
                            src={
                              imageNames["header.banner"].url ||
                              initialFormValues.header.banner
                            }
                            alt=""
                            style={imageStyle}
                          />
                        }
                        {
                          <Form.Control
                            as="input"
                            type="file"
                            name="header.banner"
                            onChange={handleFileChange}
                          />
                        }
                      </Col>
                      <Col sm={12} md={6}>
                        <h6>Logo:</h6>
                        {
                          <img
                            src={
                              imageNames["header.logo"].url ||
                              initialFormValues.header.logo
                            }
                            alt=""
                            style={imageStyle}
                          />
                        }
                        {
                          <Form.Control
                            as="input"
                            type="file"
                            name="header.logo"
                            onChange={handleFileChange}
                          />
                        }
                      </Col>
                    </Row>
                  </Container>
                  <Container>
                    <hr />
                    <h5>Section #1:</h5>
                    <hr />
                    <Row>
                      <Col sm={12} md={6}>
                        <FormikTextField
                          name="sectionOne.title.text"
                          label="Title Text"
                          desc=""
                        />
                      </Col>
                      <Col sm={12} md={6}>
                        <FormikTextField
                          type="color"
                          name="sectionOne.title.color"
                          label="Title Color"
                          desc=""
                        />
                      </Col>
                      <Col sm={12} md={6}>
                        <FormikTextField
                          name="sectionOne.subtitle.text"
                          label="Subtitle Text"
                          desc=""
                        />
                      </Col>
                      <Col sm={12} md={6}>
                        <FormikTextField
                          type="color"
                          name="sectionOne.subtitle.color"
                          label="Subtitle Color"
                          desc=""
                        />
                      </Col>
                      <Col sm={12} md={6}>
                        <h6>Banner:</h6>
                        {
                          <img
                            src={
                              imageNames["sectionOne.banner"].url ||
                              initialFormValues.sectionOne.banner
                            }
                            alt=""
                            style={imageStyle}
                          />
                        }
                        {
                          <Form.Control
                            as="input"
                            type="file"
                            name="sectionOne.banner"
                            onChange={handleFileChange}
                          />
                        }
                      </Col>
                      <Col sm={12} md={6}>
                        <FormikTextField
                          name="sectionOne.button.text"
                          label="Button Text"
                          desc=""
                        />
                      </Col>
                      <Col sm={12} md={6}>
                        <FormikTextField
                          name="sectionOne.button.link"
                          label="Button Link"
                          desc=""
                        />
                      </Col>
                      <Col sm={12} md={6}>
                        <FormikTextField
                          type="color"
                          name="sectionOne.button.color"
                          label="Button Color"
                          desc=""
                        />
                      </Col>
                    </Row>
                  </Container>
                  <Container>
                    <hr />
                    <h5>Section #2:</h5>
                    <hr />
                    <Row>
                      <Col sm={12} md={6}>
                        <FormikTextField
                          name="sectionTwo.title.text"
                          label="Title Text"
                          desc=""
                        />
                      </Col>
                      <Col sm={12} md={6}>
                        <FormikTextField
                          type="color"
                          name="sectionTwo.title.color"
                          label="Title Color"
                          desc=""
                        />
                      </Col>
                      <Col sm={12} md={6}>
                        <FormikTextField
                          name="sectionTwo.subtitle.text"
                          label="Subtitle Text"
                          desc=""
                        />
                      </Col>
                      <Col sm={12} md={6}>
                        <FormikTextField
                          type="color"
                          name="sectionTwo.subtitle.color"
                          label="Subtitle Color"
                          desc=""
                        />
                      </Col>
                      <Container>
                        <br />
                        <h6>
                          <b>Subsection #1:</b>
                        </h6>
                        <hr />
                        <Row>
                          <Col sm={12} md={6}>
                            <FormikTextField
                              name="sectionTwo.subsectionOne.title.text"
                              label="Subsection #1 Title Text"
                              desc=""
                            />
                          </Col>
                          <Col sm={12} md={6}>
                            <FormikTextField
                              type="color"
                              name="sectionTwo.subsectionOne.title.color"
                              label="Subsection #1 Title Color"
                              desc=""
                            />
                          </Col>
                          <Col sm={12} md={6}>
                            <FormikTextField
                              name="sectionTwo.subsectionOne.subtitle.text"
                              label="Subsection #1 Subtitle Text"
                              desc=""
                            />
                          </Col>
                          <Col sm={12} md={6}>
                            <FormikTextField
                              type="color"
                              name="sectionTwo.subsectionOne.subtitle.color"
                              label="Subsection #1 Subtitle Color"
                              desc=""
                            />
                          </Col>
                          <Col sm={12} md={6}>
                            <h6>SVG:</h6>
                            {
                              <img
                                src={
                                  imageNames["sectionTwo.subsectionOne.svg"]
                                    .url ||
                                  initialFormValues.sectionTwo.subsectionOne.svg
                                }
                                alt=""
                                style={imageStyle}
                              />
                            }
                            {
                              <Form.Control
                                as="input"
                                type="file"
                                name="sectionTwo.subsectionOne.svg"
                                onChange={handleFileChange}
                              />
                            }
                          </Col>
                        </Row>
                      </Container>
                      <Container>
                        <br />
                        <h6>
                          <b>Subsection #2:</b>
                        </h6>
                        <hr />
                        <Row>
                          <Col sm={12} md={6}>
                            <FormikTextField
                              name="sectionTwo.subsectionTwo.title.text"
                              label="Subsection #2 Title Text"
                              desc=""
                            />
                          </Col>
                          <Col sm={12} md={6}>
                            <FormikTextField
                              type="color"
                              name="sectionTwo.subsectionTwo.title.color"
                              label="Subsection #2 Title Color"
                              desc=""
                            />
                          </Col>
                          <Col sm={12} md={6}>
                            <FormikTextField
                              name="sectionTwo.subsectionTwo.subtitle.text"
                              label="Subsection #2 Subtitle Text"
                              desc=""
                            />
                          </Col>
                          <Col sm={12} md={6}>
                            <FormikTextField
                              type="color"
                              name="sectionTwo.subsectionTwo.subtitle.color"
                              label="Subsection #2 Subtitle Color"
                              desc=""
                            />
                          </Col>
                          <Col sm={12} md={6}>
                            <h6>SVG:</h6>
                            {
                              <img
                                src={
                                  imageNames["sectionTwo.subsectionTwo.svg"]
                                    .url ||
                                  initialFormValues.sectionTwo.subsectionTwo.svg
                                }
                                alt=""
                                style={imageStyle}
                              />
                            }
                            {
                              <Form.Control
                                as="input"
                                type="file"
                                name="sectionTwo.subsectionTwo.svg"
                                onChange={handleFileChange}
                              />
                            }
                          </Col>
                        </Row>
                      </Container>
                      <Container>
                        <br />
                        <h6>
                          <b>Subsection #3:</b>
                        </h6>
                        <hr />
                        <Row>
                          <Col sm={12} md={6}>
                            <FormikTextField
                              name="sectionTwo.subsectionThree.title.text"
                              label="Subsection #3 Title Text"
                              desc=""
                            />
                          </Col>
                          <Col sm={12} md={6}>
                            <FormikTextField
                              type="color"
                              name="sectionTwo.subsectionThree.title.color"
                              label="Subsection #3 Title Color"
                              desc=""
                            />
                          </Col>
                          <Col sm={12} md={6}>
                            <FormikTextField
                              name="sectionTwo.subsectionThree.subtitle.text"
                              label="Subsection #3 Subtitle Text"
                              desc=""
                            />
                          </Col>
                          <Col sm={12} md={6}>
                            <FormikTextField
                              type="color"
                              name="sectionTwo.subsectionThree.subtitle.color"
                              label="Subsection #3 Subtitle Color"
                              desc=""
                            />
                          </Col>
                          <Col sm={12} md={6}>
                            <h6>SVG:</h6>
                            {
                              <img
                                src={
                                  imageNames["sectionTwo.subsectionThree.svg"]
                                    .url ||
                                  initialFormValues.sectionTwo.subsectionThree
                                    .svg
                                }
                                alt=""
                                style={imageStyle}
                              />
                            }
                            {
                              <Form.Control
                                as="input"
                                type="file"
                                name="sectionTwo.subsectionThree.svg"
                                onChange={handleFileChange}
                              />
                            }
                          </Col>
                        </Row>
                      </Container>
                    </Row>
                  </Container>
                  <Container>
                    <hr />
                    <h5>Footer:</h5>
                    <hr />
                    <Row>
                      <Col sm={12} md={6}>
                        <h6>Logo:</h6>
                        {
                          <img
                            src={
                              imageNames["footer.logo"].url ||
                              initialFormValues.footer.logo
                            }
                            alt=""
                            style={imageStyle}
                          />
                        }
                        {
                          <Form.Control
                            as="input"
                            type="file"
                            name="footer.logo"
                            onChange={handleFileChange}
                          />
                        }
                      </Col>
                      <Col sm={12} md={6}>
                        <FormikTextField
                          type="color"
                          name="footer.textColor"
                          label="Footer Section Text Color"
                          desc=""
                        />
                      </Col>
                      <Col sm={12} md={6}>
                        <FormikTextField
                          type="color"
                          name="footer.bgColor"
                          label="Footer Section Background Color"
                          desc=""
                        />
                      </Col>
                    </Row>
                  </Container>
                </Row>
                <AddFaqDisplay initialFormValues={initialFormValues} />
                <Col sm={12} md={6}>
                  <div
                    style={{
                      width: 200,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <label style={{ marginBottom: 0, width: 150 }}>
                      Hide FAQ:
                    </label>
                    <Field
                      type="checkbox"
                      name="header.hideFAQ"
                      style={{ height: "1.5rem", width: "1.5rem" }}
                    />
                  </div>
                </Col>
                <Row className="justify-content-center">
                  <FormikSubmitButton label="Submit" />
                </Row>
                {message && (
                  <Row className="justify-content-center">
                    <h6 className="mt-2 text-success">{message}</h6>
                  </Row>
                )}
              </Container>
            </FormikForm>
          </Formik>
        ) : error ? (
          <h3 className="text-warning">{error}</h3>
        ) : (
          <LoadingScreen />
        )}
      </Row>
    </Container>
  );
}

export default LandingPageStylingEdit;
