import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { CSSProperties } from 'react';
import { Button, Col, Row } from 'react-bootstrap';

interface PaginationBarProps {
  page: number;
  limit: number;
  nextCallback: () => void;
  prevCallback: () => void;
  selectCallback: (page: number) => void;
  maxPreviousButtons?: number;
}

const PageColumn = ({ children }: { children: any }) => (
  <Col xs="auto" className="px-0" style={{marginRight: 10}}>
    {children}
  </Col>
)

const getPreviousPages = (page: number, limit: number, selectCallback: (page: number) => void, maxPreviousButtons: number) => {
  let previousPages = [] as JSX.Element[];

  const overMax = page > maxPreviousButtons;

  let i = overMax ? page - maxPreviousButtons : 1

  if (overMax) {
    previousPages.push(
      <PageColumn>
        <Button variant='light' disabled>...</Button>
      </PageColumn>
    )
  }

  for (i; i < page; i++) {
    let j = i;
    previousPages.push(
      <PageColumn>
        <Button variant='light' onClick={() => selectCallback(j)} key={j}>
          {i * limit}
        </Button>
      </PageColumn>
    )
  }

  return previousPages;
}

export const PaginationBar = ({ page, limit, nextCallback, prevCallback, selectCallback, maxPreviousButtons = 4 }: PaginationBarProps) => {
  return (
    <Row className="flex-nowrap justify-content-center auto" style={{marginBottom: 15}}>
      <PageColumn>
        <Button variant="light" disabled={page === 1} onClick={prevCallback}>
          <FontAwesomeIcon icon={faChevronLeft} />
        </Button>
      </PageColumn>
      {getPreviousPages(page, limit, selectCallback, maxPreviousButtons)}
      <PageColumn>
        <Button variant='primary' disabled>{page * limit}</Button>
      </PageColumn>
      <PageColumn>
        <Button variant='light' disabled>...</Button>
      </PageColumn>
      <PageColumn>
        <Button variant="light" onClick={nextCallback}>
          <FontAwesomeIcon icon={faChevronRight} />
        </Button>
      </PageColumn>
    </Row>
  )
}
