import AdminNavbar from "../AdminNavbar";
import {useCollection} from "react-firebase-hooks/firestore";
import {firestore} from "../../firebase";
import React from "react";
import {Container} from "react-bootstrap";
import UserGraph from "./UserGraph";
import {User} from "../../Model/User";
import UserGroupGraph from "./UserGroupGraph";

const UserGroupGraphPage = (props) => {
    let id = "C5jdpPXcESar6aNi2OFcvj4yxvj2";
    const [users, userLoading, userError] = useCollection(firestore.collection('users').where('cohort', '==', '002-brachio'), {
        snapshotListenOptions: {includeMetadataChanges: true},
    });

    return (

        <>{userError && <strong>Error: {JSON.stringify(userError)}</strong>}
            {userLoading && <span>Document: Loading...</span>}
            {users && (<>
                <AdminNavbar/>
                <Container>
                    <UserGroupGraph users={users.docs.map((u) => {

                        return new User(u)
                    })} title={"DanceOff"} program={'tempo1'}/>
                </Container>
            </>)

            }
        </>);
}


export default UserGroupGraphPage