import React, { forwardRef, Ref, useImperativeHandle, useMemo, useState } from "react";
import type {
  JournalQuestion,
} from "@swing-therapeutics/swingcore/dist/models/journals/JournalInterfaces";
import type { PacingQuestion } from "@swing-therapeutics/swingcore/dist/models/journals/PacingJournalInterfaces";
import { Button, Col, Row } from "react-bootstrap";
import { useFormik } from "formik";
import { cloneDeep, pick } from "lodash";
import {
  CombinedQuestionType,
  ScreenTextFields,
  ValidationFields,
  PinkEyeFields,
} from "./JournalQuestionFormWrappers";
import { getJournalQuestionValidator } from "./journalQuestionValidators";
import { JournalTemplate } from "@swing-therapeutics/swingcore/dist/models/journals/JournalTemplate";
import { FormikHelpers } from "../../../utils/FormikHelpers";
import { PacingJournal } from "@swing-therapeutics/swingcore/dist/models/journals/PacingJournal";


interface JournalQuestionFormProps {
  question?: JournalQuestion | PacingQuestion;
  questionType?: CombinedQuestionType;
  journalType: string;
  phase: string;
  journal: JournalTemplate;
}

export interface JournalQuestionFormRef {
  resetForm: Function
}

const JournalQuestionForm = forwardRef(({
  question,
  questionType,
  journalType,
  phase,
  journal,
}: JournalQuestionFormProps, ref: Ref<JournalQuestionFormRef>) => {
  const isPacingJournal = PacingJournal.isPacingJournalID(journalType);
  const isInsightsJournal = ["dailyInsights", "weeklyInsights"].includes(journalType);
  
  const [submitPressed, setSubmitPressed] = useState(false);
  const [initialValues, setInitialValues] = useState<
    Partial<JournalQuestion | PacingQuestion>
  >(cloneDeep(question));

  const [validator, setValidator] = useState(
    getJournalQuestionValidator(questionType)
  );

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit: async (_results) => {
      const results = cloneDeep(_results);

      // When switching question types, we need to make sure that fields
      // from the old question type don't persist to the new question type
      (results.screenText as any) = pick(results.screenText, validator.fields['screenText']['_nodes']);

      if (!results.validation) {
        results.validation = {};
      }

      if (validator.fields['validation']) {
        (results.validation as any) = pick(results.validation, validator.fields['validation']['_nodes']);
      }

      if (checkForExistingKey(results.key, results.order, phase)) {
        return;
      }

      //If valuesEntry question filter out empty indexes from array
      if (results.questionType === 'valuesEntry' && results.screenText.placeholderTexts) {
        results.screenText.placeholderTexts = results.screenText.placeholderTexts.filter(Boolean);
      }

      if (results.questionType === 'pacing-planPicker' && !results.plans) {
        results.plans = {
          taskBasedPacing: {
            journalTypeID: "pacing_task_based",
          },
          timeBasedPacing: {
            journalTypeID: "pacing_v2"
          },
        };
      }

      //If hasPinkEye is turned off delete pinkEye
      if (!results.hasPinkEye) {
        delete results.pinkEye
      }

      const newQuestions = cloneDeep(journal.phases[phase].questions);
      const newQuestionIndex = newQuestions.findIndex(question => question.key === results.key);
      newQuestions[newQuestionIndex] = results;

      journal.query()
        .update({
          [`phases.${phase}.questions`]: newQuestions
        })
        .then(() => {
          setInitialValues(results);
          resetForm();
          window.alert('Question saved successfully!');
        })
        .catch(error => {
          window.alert('An error occurred when saving this question. Check the developer console for details.');
          console.error(error);
        });
    },
    validationSchema: validator,
  });

  const checkForExistingKey = (key: string, order: number, phase: string) => {
    if (!journal.phases?.[phase]?.questions) {
      return;
    }

    const keyAlreadyExists = journal.phases[phase].questions.some(question => question.key === key && question.order !== order);

    if (keyAlreadyExists) {
      formik.setFieldError("key", "another question exists with that key");
    }

    return keyAlreadyExists;
  }

  const {
    values,
    handleChange,
    resetForm,
    submitForm,
    dirty,
    setFieldValue,
    setFieldTouched,
    isSubmitting,
  } = formik;

  const formikHelpers = {...formik, formSubmitPressed: submitPressed} as FormikHelpers;

  useImperativeHandle(ref, () => ({
    resetForm: (confirmMessage: string) => {
      if (!dirty) {
        return true;
      } else if (dirty && window.confirm(confirmMessage)) {
        resetForm();
        return true;
      }

      return false;
    }
  }), [formik]);

  const switchToQuestionType = (type: CombinedQuestionType) => {
    if (
      window.confirm(
        "Are you sure you want to change the question type? This will reset the form."
      )
    ) {
      resetForm();
      setValidator(getJournalQuestionValidator(type));

      const oldType = getTypeFromQuestionType(type);

      setTimeout(() => {
        setFieldValue("questionType", type);
        setFieldValue("type", oldType);
        setFieldTouched("questionType", true);
      }, 0);
    }
  };

  // There might be an error in the Yup schema, but if there isn't a form field for it the error will be slent
  // This will make it a lot easier to identify missing form fields
  const checkForInvisibleErrors = () => {
    validator.validate(values).catch(error => {
      const errorLabels = Array.from(document.getElementsByClassName('invalid-feedback'));
      const hasInvisibleErrors = errorLabels.every(label => !label.textContent);
      if (hasInvisibleErrors) {
        throw error;
      }
    });
  };

  return (
    <>
      <Row>
        <ScreenTextFields
          phase={phase}
          formikHelpers={formikHelpers}
          handleTypeChange={(event) => {            
            if (event.target.getAttribute("name") === "type") {
              const newType = (event.target as HTMLSelectElement).value as CombinedQuestionType;
              switchToQuestionType(newType);
              return;
            }

            handleChange(event);
          }}
          isPacingJournal={isPacingJournal}
          isInsights={isInsightsJournal}
        />
        <ValidationFields
          formikHelpers={formikHelpers}
        />
        <PinkEyeFields
          formikHelpers={formikHelpers}
        />
      </Row>
      <Row>
        <Col>
          <Button
            data-testid="questionFormSubmit"
            variant={dirty ? 'primary' : 'secondary'}
            disabled={isSubmitting || !dirty}
            onClick={() => {
              setSubmitPressed(true);
              submitForm();
              setTimeout(() => {
                checkForInvisibleErrors();
              }, 0);
            }}
          >
            Save
          </Button>
        </Col>
      </Row>
    </>
  );
});

export default JournalQuestionForm;

export const getTypeFromQuestionType = (questionType: string): string => {
  if (questionType.includes("pacing-")) {
    return questionType.split("-")[1];
  }
  return questionType;
}
