import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { firestore } from "../../../firebase";
import User from "../../../Model/Usert";
import { Registration } from "../../../Model/Registration";

interface MedrioRegistrationProps {
  subjectID: string;
}

const MedrioRegistration: React.FC<MedrioRegistrationProps> = ({
  subjectID,
}) => {
  const [userReg, setUserReg] = useState<Registration>();
  const [user, setUser] = useState<User>();

  subjectID = subjectID.replace("-", "_");

  useEffect(() => {
    const subscriber = firestore
      .collection("registrations")
      .where("subjectID", "==", subjectID)
      .onSnapshot((snapshot) => {
        if (snapshot.empty) return;
        const u = Registration.fromFirestore(snapshot.docs[0]);
        setUserReg(u);
      });

    return subscriber;
  }, [subjectID]);

  useEffect(() => {
    const subscriber = firestore
      .collection("users")
      .where("subjectID", "==", subjectID)
      .onSnapshot((snapshot) => {
        if (snapshot.empty) return;
        const u = User.fromFirestore(snapshot.docs[0]);
        setUser(u);
      });

    return subscriber;
  }, [subjectID]);

  /**
   * If regQuery doesn't return anything, registration record is missing - User is not registered yet
   *
   * If exists, get uid and email
   * - If uid is empty, then user isn't logged into the app/surveysaurus
   * - If email is empty, medrio integration didn't go well
   * - If uid exists, check users document with the uid.
   *   - If record doesn't exist, user isn't logged into the app yet
   *   - If record exists, continue to the next step
   * - If email exists, check users document with users email
   *   - If uid doesn't match, it is a bug and needs to be fixed
   *   - If uid match, then continue to the next step
   */

  return (
    <Container fluid>
      {user && userReg && (
        <>
          <table style={{ border: "1px solid black", width: "1100px", height: "110px" }}>
            <tr>
              <th>User email</th>
              <th>Reg email</th>
              <th>User email mismatch/empty?</th>
            </tr>
            <tr style={{ borderBottom: "1px solid grey" }}>
              <td>{user.email}</td>
              <td>{userReg.email}</td>
              <td style={{ color: user.email !== userReg.email ? 'red' : 'green', fontWeight: 'bold' }}>{user.email !== userReg.email ? "Yes" : "No"}</td>
            </tr>
            <tr>
              <th>User uid</th>
              <th>Reg uid</th>
              <th>User ID mismatch/empty?</th>
            </tr>
            <tr style={{ borderBottom: "1px solid grey" }}>
              <td>{user.uid}</td>
              <td>{userReg.uid}</td>
              <td style={{ color: user.uid !== userReg.uid ? 'red' : 'green', fontWeight: 'bold' }}>{user.uid !== userReg.uid ? "Yes" : "No"}</td>
            </tr>
            <tr>
              <th>User Active Program</th>
              <th>Reg Active Program</th>
              <th>User active program mismatch/empty?</th>
            </tr>
            <tr style={{ borderBottom: "1px solid grey" }}>
              <td>{user.activeProgram}</td>
              <td>{userReg.activeProgram}</td>
              <td style={{ color: user.activeProgram !== userReg.activeProgram ? 'red' : 'green', fontWeight: 'bold' }}>{user.activeProgram !== userReg.activeProgram ? "Yes" : "No"}</td>
            </tr>
            <tr>
              <th>User ZendeskId</th>
              <th>Reg ZendeskId</th>
              <th>User ZendeskId mismatch/empty?</th>
            </tr>
            <tr>
              <td>{user.activeProgram}</td>
              <td>{userReg.activeProgram}</td>
              <td style={{ color: user.zendeskID !== userReg.zendeskID ? 'red' : 'green', fontWeight: 'bold' }}>{user.zendeskID !== userReg.zendeskID ? "Yes" : "No"}</td>
            </tr>
          </table>
          <br></br>
          <Row>
            <Col>
              <h5>Passed 005 ScreenWeek</h5>
              <span>{user.passed005ScreenWeek}</span>
            </Col>
            <Col>
              <h5>Reg updatedBy</h5>
              <span>{userReg.updatedBy}</span>
            </Col>
            <Col>
              <h5>Last App Usage</h5>
              <span>{user.lastLogon.toLocaleString('en-GB', { timeZone: 'PST' })}</span>
            </Col>
            <Col>
              <h5>Site</h5>
              <span>{user.site}</span>
            </Col>
          </Row>
        </>
      )}
    </Container>
  );
};

export default MedrioRegistration;
