import React from "react";
import { Container, Row } from "react-bootstrap";
import { RouteComponentProps } from "react-router";
import User from "../../../../Model/Usert";
import { Workflow } from "../../../../Model/Workflow";
import BaselineSurveysView from "./BaselineSurveysView";
import DisclaimerView from "./DisclaimerView";
import EligibiityWorkflowView from "./EligibilityScreeningView";
import ConsentView from "./ConsentView";
import TherapySurveysView from "./TherapySurveysView";
import { WorkflowID, WorkflowType } from "@swing-therapeutics/surveybay/dist/types";
import ScheduledCallsView from "./ScheduledCallsView";
import TerminationSurveysView from "./TerminationSurveysView";
import TerminationCallView from "./TerminationCallView";

interface WorkflowViewPageParams {
  workflowID?: string;
}

interface WorkflowViewProps extends RouteComponentProps<WorkflowViewPageParams, any, any> {
  user: User;
  workflow: Workflow;
}

const WorkflowView: React.FC<WorkflowViewProps> = ({ user, workflow }) => {

  return (
    <Container>
      <Row className="mb-4">
        <h3>{workflow.workflowName}</h3>
      </Row>
      <Row>
        {
          { // Inline switch statement
            [WorkflowID.ELIGIBILITY]: <EligibiityWorkflowView workflow={workflow} user={user} />,
            [WorkflowID.BASELINE]: <BaselineSurveysView workflow={workflow} user={user} />,
            [WorkflowID.DISCLAIMER]: <DisclaimerView workflow={workflow} />,
            [WorkflowID.CONSENT]: <ConsentView workflow={workflow} />,
            [WorkflowID.EXT_CONSENT]: <ConsentView workflow={workflow} />,
            [WorkflowID.THERAPY]: <TherapySurveysView workflow={workflow} user={user} workflowType={WorkflowType.STANDARD} />,
            [WorkflowID.EXT_THERAPY]: <TherapySurveysView workflow={workflow} user={user} workflowType={WorkflowType.EXTENSION} />,
            [WorkflowID.EXT_SCHEDULED_CALLS]: <ScheduledCallsView workflow={workflow} user={user} />,
            [WorkflowID.EARLY_TERM_SURVEYS]: <TerminationSurveysView workflow={workflow} user={user} />,
            [WorkflowID.EXT_EARLY_TERM_SURVEYS]: <TerminationSurveysView workflow={workflow} user={user} />,
            [WorkflowID.EARLY_TERM_CALL]: <TerminationCallView workflow={workflow}/>,
            [WorkflowID.EXT_EARLY_TERM_CALL]: <TerminationCallView workflow={workflow}/>,
          }[workflow.workflowID] || <h1>{workflow.workflowID} Not found in workflow view switcher</h1>
        }
      </Row>
    </Container>
  )
}

export default WorkflowView;
