import {auth, firestore} from "../firebase";
import {Registration} from "./Registration";


class Subject {
    email: string;
    subjectID: string;
    cohort: string;
    role: string;
    activeProgram: string;
    zendeskID: string;
    study: string;
    firstName: string;
    lastName: string;
    site: string;
    created: Date;
    updated: Date;
    createdBy: string;
    updatedBy: string;


    constructor(email: string = "", subjectID: string = "default", cohort: string = "default", role: string = "patient",
                study: string = "003", zendeskID: string = "",
                activeProgram: string = "tempo1", site: string = "",
                firstName: string = "", lastName: string = "") {
        this.email = email;
        this.subjectID = subjectID;
        this.cohort = cohort;
        this.role = role;
        this.study = study;
        this.zendeskID = zendeskID;
        this.site = site;
        this.firstName = firstName;
        this.lastName = lastName;
        this.created = new Date();
        this.updated = new Date();
        this.activeProgram = activeProgram;
        this.updatedBy = auth?.currentUser?.email ? auth.currentUser.email : "";
        this.createdBy = this.updatedBy;

    }

    static fromFirestore(docRef: any) {
        let data = docRef.data();
        let reg = new Subject(data.email, data.subjectID, data.cohort, data.role, data.study, data.zendeskID, data.activeProgram,
            data.site, data.firstName, data.lastName);
        reg.created = data.created ? data.created.toDate() : new Date();
        reg.updated = data.updated ? data.updated.toDate() : new Date();

        reg.updatedBy = data.updatedBy;
        reg.createdBy = data.updatedBy;
        return reg;
    }

    static fromRegistration(registration: Registration) {
        let data = registration;
        let reg = new Subject(data.email, data.subjectID, data.cohort, data.role, data.study, data.zendeskID, data.activeProgram,
            data.site, data.firstName, data.lastName);
        reg.created = data.created ? data.created : new Date();
        reg.updated = data.updated ? data.updated : new Date();
        reg.updatedBy = data.updatedBy;
        reg.createdBy = data.updatedBy;
        return reg;
    }

    static async subjectWithID(subjectID: string) {
        let reg = await firestore.collection("subjects").doc(subjectID).get();
        if (reg.exists) {
            return Subject.fromFirestore(reg);
        }
        return null;

    }

    matchesFilter(filter: string) {
        if (!filter || filter.length === 0) return true;
        return this.email.toLowerCase().includes(filter.toLowerCase());
    }


    data() {
        let data = JSON.parse(JSON.stringify(this));
        data.created = this.created;
        data.updated = this.updated;
        return data;
    }

    async persist() {
        let data = this.data();
        data.updatedBy = auth?.currentUser?.email ? auth.currentUser.email : "";
        data.updated = new Date();
        let res = await firestore.collection('subjects').doc(this.subjectID)
            .set(data);
        return res;
    }


}


export {Subject};