import { TherapySurvey } from '@swing-therapeutics/surveybay/dist/types';
import moment from 'moment';

export const getDailySurveyInfo = (therapyStartDay: Date, surveyConfig: any) => {
  const {weekLength} = surveyConfig;

  const today = moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
  therapyStartDay.setHours(0, 0, 0, 0);

  const therapyDay = today.diff(moment(therapyStartDay), 'days');
  const therapyWeek = Math.floor(therapyDay / weekLength) + 1;
  const therapyWeekDay = (therapyDay % weekLength) + 1;

  return {
    therapyWeek,
    therapyWeekDay,
  };
};

export const calculateSurveySchedule = (surveyConfig: any, therapyStartDay: Date, therapySurveys: TherapySurvey[]) => {
  const {weekLength, totalWeeks} = surveyConfig;

  let currentDay = moment(therapyStartDay);
  currentDay = currentDay.clone().add(5, 'days');

  let surveySchedule: {surveyWeek: number, surveyDay: string, surveyStatus: string}[] = [];

  for (let i = 0; i < totalWeeks; i++) {
    if ((i + 1) % 4 === 0) {
      surveySchedule.push({
        surveyWeek: i + 1,
        surveyDay: currentDay.format('ddd MMM DD YYYY'),
        surveyStatus: therapySurveys[i] && therapySurveys[i].surveys.length !== 0 ? 'sent': 'not sent',
      });
    }
    currentDay = currentDay.clone().add(weekLength, 'days');
  }

  return surveySchedule;
};