import React, { useCallback, useEffect, useMemo, useState } from "react";
import ErrorMessageRender from "./ErrorMessageRender";
import { ErrorMessage, useField } from "formik";

export interface FormikSelectProps {
  name: string;
  options?: Array<SelectOption>; // Array of options for the select
  fetchOptions?: () => Promise<Array<SelectOption>>; // Function to call to return options for the select
  defaultOption?: string | number; // Set a default option once options are retrieved, must be a value in options array
  label?: string;
  desc?: string;
  placeHolder?: string;
  // HTML <select/> always return values as string
  // Flag if an extra parseInt is necessary to convert back to number
  type?: 'string' | 'int' | 'boolean';
  showSelectOption?: boolean;
  onChange?: (newValue: any) => void; // Optional onChange listener
  disabled?: boolean;
}

interface SelectOption {
  value: string | number;
  label: string;
}

// Must be a child of a Formik Form component
const FormikSelect: React.FC<FormikSelectProps> = ({ name, options, fetchOptions, defaultOption, label, desc, placeHolder, type = 'string', showSelectOption = true, onChange, disabled = false }) => {
  const [field, meta, helpers] = useField(name);
  const [selectOptions, setSelectOptions] = useState<Array<SelectOption>>(options ?? []);

  const fetchAndSetSelectOptions = useCallback(async () => {
    const options = await fetchOptions();
    setSelectOptions(options);
  }, [fetchOptions])

  const setDefaultOption = useCallback(() => {
    if (defaultOption) {
      const optionExists = !!selectOptions.find((option) => option.value === defaultOption);
      if (!optionExists) {
        console.warn("Trying to set a default option that does not exist in field select \"" + name + "\"");
        return;
      }
      if (type === 'boolean') {
        helpers.setValue(defaultOption == 'true');
      }
      else {
        helpers.setValue(defaultOption);
      }
    }
  }, [defaultOption, selectOptions, name, type])

  useEffect(() => {
    if (!options && fetchOptions) {
      fetchAndSetSelectOptions();
    }
    else if (!options && !fetchOptions) {
      console.warn("No options array and no fetchOptions function passed to field select \"" + name + "\"");
    }
    else {
      setSelectOptions(options ?? []);
    }
  }, [options, fetchOptions])

  useEffect(() => {
    if (!meta.touched && field.value === "" && selectOptions.length > 0) {
      setDefaultOption();
    }
  }, [meta.touched, field.value, selectOptions])

  return useMemo(() =>
    <div className="form-group">
      {
        label &&
        <label className="mb-0">{label}</label>
      }
      {
        desc &&
        <small className="form-text text-muted">{desc}</small>
      }
      {
        (label || desc) && // Spacer for the label or desc
        <div className="mb-2" />
      }
      <select disabled={disabled} id={name} className="form-control"
        // If the value is undefined or null then set the selected value to empty string
        value={field.value == null ? "" : field.value.toString()}
        onFocus={() => helpers.setTouched(true)}
        onChange={(event) => {
          let newValue: string | number | boolean = event.target.value;
          if (type === 'int') {
            // Convert new value to number, <select/> always returns strings
            newValue = parseInt(newValue);
            if (isNaN(newValue)) {
              newValue = '';
            }
          }
          else if (type === 'boolean') {
            newValue = newValue === 'true';
          }
          helpers.setValue(newValue);
          onChange?.(newValue)
        }}
        onBlur={field.onBlur}
      >
        {
          showSelectOption &&
          <option value="">{placeHolder ?? "Select..."}</option>
        }
        {selectOptions.map((option) => (
          <option key={option.value} value={option.value}>{option.label}</option>
        ))}
      </select>
      <ErrorMessage name={name} render={ErrorMessageRender} />
    </div>
    , [field.value, selectOptions, name, label, desc, placeHolder, onChange, disabled])
}

export default FormikSelect;
