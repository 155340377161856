export const REACT_APP_SWING_API_URL = process.env.REACT_APP_SWING_API_URL;
export const StanzaAndroidLink = "https://bit.ly/3yT5SlF";
export const StanzaIOSLink = "https://apple.co/3JUtvk3";

export const createPrescription = (data: any) => {
  let myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  let raw = JSON.stringify(data);

  let requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
  };

  fetch(`${REACT_APP_SWING_API_URL}/user/createPrescription`, requestOptions)
    .then((response) => response.text())
    .then((data) => console.log(data))
    .catch((error) => console.log("error", error));
};

export const deactivatePrescription = (data: any) => {
  let myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  let raw = JSON.stringify(data);

  let requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
  };

  fetch(
    `${REACT_APP_SWING_API_URL}/user/deactivatePrescription`,
    requestOptions
  )
    .then((response) => response.text())
    .then((data) => console.log(data))
    .catch((error) => console.log("error", error));
};

export const reactivatePrescription = (data: any) => {
  let myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  let raw = JSON.stringify(data);

  let requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
  };

  fetch(
    `${REACT_APP_SWING_API_URL}/user/reactivatePrescription`,
    requestOptions
  )
    .then((response) => response.text())
    .then((data) => console.log(data))
    .catch((error) => console.log("error", error));
};

export const refillPrescription = (data: any) => {
  let myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  let raw = JSON.stringify(data);

  let requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
  };

  fetch(
    `${REACT_APP_SWING_API_URL}/user/schedulePrescriptionRefill`,
    requestOptions
  )
    .then((response) => response.text())
    .then((data) => console.log(data))
    .catch((error) => console.log("error", error));
};

export const sendText = (body: string, to: string) => {
  let myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  let raw = JSON.stringify({
    message: body,
    targetNumber: to,
  });

  let requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
  };

  fetch(`${REACT_APP_SWING_API_URL}/twilio/sendSMS`, requestOptions)
    .then((response) => response.text())
    .then((data) => console.log(data))
    .catch((error) => console.log(error));
};
