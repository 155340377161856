import React, { useState } from "react";
import { useCollection, useDocument } from "react-firebase-hooks/firestore";
import LoadingScreen from "../LoadingScreen";
import "./surveyAdherence.css";
import { firestore } from "../../firebase";
import AdminNavbar from "../AdminNavbar";
import { useHistory } from "react-router-dom";
import AreportsListSidebar from "./AreportsListSidebar";
import {Button, Col, Container, Form, FormControl, InputGroup, Jumbotron, Modal, Row, Table,} from "react-bootstrap";
// import { Col, Container, Jumbotron, Row, Table } from "react-bootstrap";
import AReportTable from "./AReportTable";
import AlargerReportTable from "./AlargerReportTable";
import BReportTable from "./BReportTable";

const FiqrFablesOfTables = (props) => {
  return (<>
         <AdminNavbar/>
         <Jumbotron>
             <Container fluid={true}>
                 <Row>
                     <Col>
                         <h1>FIQR Scores 002</h1>
                     </Col>
                 </Row>
             </Container>
         </Jumbotron>
         <AreportsListSidebar/>


         <Container fluid={true} style={{backgroundColor: "white"}}>
            <div className="article">
            <p className="TitleC">Score Changes</p>
            <BReportTable tableKey={"fiqr_changes002"}></BReportTable>
                </div>
        </Container>
      
</>);

};

export default FiqrFablesOfTables;

