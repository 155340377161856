import React from 'react';
import {
  SessionTaskData,
  SessionTemplate,
} from "@swing-therapeutics/swingcore/dist/models/Session";
import { useFormik } from "formik";
import { useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import * as yup from "yup";
import { BasicNumberField, BasicTextField } from '../../../../utils/FormikHelpers';

interface EditTaskProps {
  show: boolean;
  close: Function;
  session: SessionTemplate;
  task: SessionTaskData;
  playlistID: string;
  taskIndex: number;
}

export const EditTaskModal = ({
  show,
  close,
  session,
  playlistID,
  task,
  taskIndex,
}: EditTaskProps) => {

  const formik = useFormik({
    initialValues: task,
    validationSchema: yup.object().shape({
      displayName: yup.string().required("Display name is required"),
      id: yup.string().required("ID is required"),
      type: yup.string().required("Type is required"),
      lesson: yup.string().test('lesson-is-required', 'Lesson number is required for lessons', (value) => {
        if (formik.values.type !== 'lesson') return true;
        return !!value
      }),
      chapter: yup.string().test('chapter-is-required', 'Chapter number is required for lessons', (value) => {
        if (formik.values.type !== 'lesson') return true;
        return !!value
      }),
      uuid: yup.string().when("type", {
        is: "awareness",
        then: yup.string().required("UUID is required for activities"),
        otherwise: yup.string().nullable(),
      }),
    }),
    onSubmit: async (values) => {
      const newSession = new SessionTemplate(session);

      try {
        newSession.tasks[taskIndex] = values;
        await newSession.persist(playlistID);
        window.alert("Task saved successfuly");
        close();
      } catch (error) {
        console.error(error);
        window.alert("Error saving task. Check console for details");
        close();
      }
    },
  });

  useEffect(() => {
    formik.resetForm({values: task});
  }, [show]);

  return (
    <Modal show={show} onHide={close}>
      <Modal.Header closeButton>
        <Modal.Title>Edit Task</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <BasicTextField title="ID" path="id" disabled  formikHelpers={formik} lg={12}/>
        <BasicTextField title="Type" path="type" disabled formikHelpers={formik} lg={12}/>
        <BasicTextField title="Display Name" path="displayName" formikHelpers={formik} lg={12}/>
        { task?.type === 'awareness' &&
          <BasicTextField title="UUID" path="uuid" formikHelpers={formik} lg={12}/>
        }
        { task?.type === 'lesson' &&
          <>
            <BasicNumberField title="Chapter Number" path="chapter" formikHelpers={formik} lg={12}/>
            <BasicNumberField title="Lesson Number" path="lesson" formikHelpers={formik} lg={12}/>
          </>
        }
        <BasicTextField title="Image filename" path="imageFilename" formikHelpers={formik} lg={12}/>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={() => close()}
        >
          Cancel
        </Button>
        <Button
          disabled={!formik.dirty}
          onClick={() => formik.submitForm()}
        >
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  )
};
