import React, { useEffect, useState } from "react";
import { Formik, Form, Field } from "formik";
import { firestore } from "../../../firebase";
import AdminNavbar from "../../AdminNavbar";

const AccessKeyPageLinks = () => {
  const [inputs, setInputs] = useState({
    eula: "",
    tc: "",
  });

  const fetchData = () => {
    firestore
      .collection("tempest")
      .doc("accessKeyScreen")
      .get()
      .then(doc => setInputs(doc.data()))
  };

  const container = {
    borderStyle: "ridge",
    padding: "20px",
    marginTop: "40px",
  };

  const handleSubmit = () => {
    firestore
      .collection("tempest")
      .doc("accessKeyScreen")
      .update({
        eula: inputs.eula,
        tc: inputs.tc,
      });
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setInputs((vals) => {
      return { ...vals, [name]: value };
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <><AdminNavbar></AdminNavbar><Formik
      initialValues={{
        eula: "",
        tc: "",
      }}
      onSubmit={handleSubmit}
    >
      {({ }) => (
        <div className="container" style={container}>
          <div className="col-sm-12">
            <Form>
              <label>EULA</label>
              <div className="form-group">
                <Field
                  className="form-control"
                  type="url"
                  placeholder="EULA"
                  name="eula"
                  value={inputs.eula}
                  onChange={handleChange} />
              </div>
              <label>TC</label>
              <div className="form-group">
                <Field
                  className="form-control"
                  type="url"
                  placeholder="Terms and conditions"
                  name="tc"
                  value={inputs.tc || ""}
                  onChange={handleChange} />
              </div>

              <div className="form-group">
                <button
                  className="btn btn-primary"
                  type="submit"
                  style={{ fontSize: "16px" }}
                >
                  Save
                </button>
              </div>
            </Form>
          </div>
        </div>
      )}
    </Formik></>
  );
};

export default AccessKeyPageLinks;
