import React, {useState} from "react";
import {Link} from "react-router-dom";
import {useCollection, useDocument} from "react-firebase-hooks/firestore";
import LoadingScreen from "../LoadingScreen";
import TabContainer from "react-bootstrap/TabContainer";
import "./surveyAdherence.css";
import {firestore} from "../../firebase";
import AdminNavbar from "../AdminNavbar";
import {useHistory} from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import NavItem from "react-bootstrap/NavItem";
import AreportsListSidebar from "./AreportsListSidebar";
       
import {Button, Col, Container, Form, FormControl, InputGroup, Jumbotron, Modal, Row, Table,} from "react-bootstrap";
import AReportTable from "./AReportTable";
import AsmallerReportTable from "./AsmallerReportTable";
    // const query = firestore.collection("analytics/reports/reportPages");
    // const [reportPages, reportPagesLoading, reportPagesError] = useCollection(query, {
        // snapshotListenOptions: {includeMetadataChanges: true},
    // });
// const query = firestore.collection("analytics").doc("kpi_summary")
const PandExFablesOfTables = (props) => {
 return (<>
        <AdminNavbar/>
        <Jumbotron>
            <Container fluid={true}>
                <Row>
                    <Col>
                        <h1>Pacing & Exercise</h1>
                    </Col>
                </Row>
            </Container>
        </Jumbotron>
        <AreportsListSidebar/>
        {/* <Container fluid={true} style={{backgroundColor: "white"}}> */}
            {/* <TabContainer defaultActiveKey={"overview"}> */}
{/* <Container fluid={true}> */}
            {/* <div className={"sidebar-sticky"}> */}
            {/* <Nav className={"flex-column "}> */}
            {/* wtf */}
            {/* <NavItem> */}
          
            {/* </Nav> */}
                {/* </TabContainer> */}
             {/* </Container> */}
        {/* </Container> */}
       
        {/* <div className="article.TitleC">
        KPIs
        </div> */}
        {/* <Container fluid={true} style={{backgroundColor: "white"}}>
        </Container>  */}
        <Container fluid={true} style={{backgroundColor: "white"}}>
            <div className="article">
            <p className="TitleC">Pacing</p>
            <AReportTable tableKey={"pacing"}></AReportTable>
                </div>
        </Container>
        <Container fluid={true} style={{backgroundColor: "white"}}>
            <div className="article">
            <p className="TitleC">Exercise</p>
            <AReportTable tableKey={"exercise"}></AReportTable>
                </div>
        </Container>
      
</>);

};

export default PandExFablesOfTables;

