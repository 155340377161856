import { QuestionTypes } from "@swing-therapeutics/surveybay/dist/types";
import React from "react";
import { Dropdown, DropdownButton } from "react-bootstrap";

interface AddQuestionProps {
  addQuestion: (questionType: QuestionTypes, underIndex?: number) => void;
  underIndex?: number;
}

const AddQuestionButton: React.FC<AddQuestionProps> = ({ addQuestion, underIndex }) => {
  return (
    <DropdownButton title="Add Question" className="mx-2">
      <Dropdown.Item onClick={() => addQuestion(QuestionTypes.Text, underIndex)}>Text Field</Dropdown.Item>
      <Dropdown.Item onClick={() => addQuestion(QuestionTypes.TextMultiLine, underIndex)}>Text Box</Dropdown.Item>
      <Dropdown.Item onClick={() => addQuestion(QuestionTypes.Slider, underIndex)}>Slider</Dropdown.Item>
      <Dropdown.Item onClick={() => addQuestion(QuestionTypes.Buttons, underIndex)}>Buttons</Dropdown.Item>
      <Dropdown.Item onClick={() => addQuestion(QuestionTypes.CheckBox, underIndex)}>Checkboxes</Dropdown.Item>
      <Dropdown.Item onClick={() => addQuestion(QuestionTypes.Radio, underIndex)}>Multiple choice</Dropdown.Item>
      <Dropdown.Item onClick={() => addQuestion(QuestionTypes.DisplayText, underIndex)}>Display Text</Dropdown.Item>
    </DropdownButton>
  )
}

export default AddQuestionButton;
