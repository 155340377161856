import React from "react";
// import GraphTemplate from "../Graphs/GraphTemplate";
import GraphTemplateEPROsA from "../../Graphs/GraphTemplateEPROsA";

import { Col, Container, Row,Jumbotron } from "react-bootstrap";

import "../surveyAdherence.css";
import AdminNavbar from "../../AdminNavbar";

// import GraphTemplateEPROsA from "../../Graphs/GraphTemplateEPROsA";
import AnotherReportsListSidebar from "./AnotherReportsListSidebar";


const Graphs004A = () => {
  return (
    <>
      <AdminNavbar />
      <Jumbotron>
            <Container fluid={true}>
                <Row>
                    <Col>
                        <h1>004A ACT</h1><div className="TitleCb"></div>
                    </Col>
                </Row>
            </Container>
        </Jumbotron>
      <AnotherReportsListSidebar/>
      <Container>
        <div className="mt-4">
          <Row>
            <Col xs={10}>
              <GraphTemplateEPROsA report={"s10_001"} />
            </Col>
          </Row>
          <Row>
            <Col xs={10}>
              <GraphTemplateEPROsA report={"s10_003"} />
            </Col>
          </Row>
          <Row>
            <Col xs={10}>
              <GraphTemplateEPROsA report={"s10_007"} />
            </Col>
          </Row>
          <Row>
            <Col xs={10}>
              <GraphTemplateEPROsA report={"s10_008"} />
            </Col>
          </Row>
          <Row>
            <Col xs={10}>
              <GraphTemplateEPROsA report={"s11_002"} />
            </Col>
          </Row>
          <Row>
            <Col xs={10}>
              <GraphTemplateEPROsA report={"s12_003"} />
            </Col>
          </Row>
 <Row>
            <Col xs={10}>
              <GraphTemplateEPROsA report={"s12_005"} />
            </Col>
          </Row>
 <Row>
            <Col xs={10}>
              <GraphTemplateEPROsA report={"s12_010"} />
            </Col>
          </Row>
 <Row>
            <Col xs={10}>
              <GraphTemplateEPROsA report={"s12_012"} />
            </Col>
          </Row>
 <Row>
            <Col xs={10}>
              <GraphTemplateEPROsA report={"s13_001"} />
            </Col>
          </Row>
 <Row>
            <Col xs={10}>
              <GraphTemplateEPROsA report={"s13_004"} />
            </Col>
          </Row>
 <Row>
            <Col xs={10}>
              <GraphTemplateEPROsA report={"s14_004"} />
            </Col>
          </Row>
        </div>
      </Container>
      
      
    </>
  );
};

export default Graphs004A;
