import { Form as FormikForm, Formik } from "formik";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { Workflow } from "../../../../Model/Workflow";
import * as Yup from "yup";
import moment from "moment";
import { FormikSelect, FormikSubmitButton, FormikTextField } from "../../../FormikFields";
import { IScheduleCall, ScheduledCallStatus, WorkflowIDMap } from "@swing-therapeutics/surveybay/dist/types";
import User from "../../../../Model/Usert";
import { DocumentData, DocumentSnapshot, firestore } from "../../../../firebase";
import { ScheduleCall } from "../../../../Model/ScheduleCall";

interface ScheduledCallsProps {
  user: User;
  workflow: Workflow;
}

interface State {
  calls: DocumentSnapshot<DocumentData>[] | null;
  error: string;
}

const defaultState: State = {
  calls: null,
  error: '',
}

const ScheduledCallsView: React.FC<ScheduledCallsProps> = ({ user, workflow }) => {
  const [state, setState] = useState({ ...defaultState });
  const unsub = useRef<() => void>();

  const getCallsForUser = useCallback(async () => {
    try {
      const query = firestore.collection(`users/${user.uid}/calls`).where('workflowType', '==', WorkflowIDMap[workflow.workflowID]).orderBy('created');
      // Listen for changes to added docs and add to list of docs
      unsub.current = query.onSnapshot((snap) => {
        const newDocs = [];
        for (const docChange of snap.docChanges()) {
          if (docChange.type === 'added') {
            newDocs.push(docChange.doc);
          }
        }
        setState((prevState) => {
          const updatedCallDocs = prevState.calls ? [...prevState.calls, ...newDocs] : [...newDocs];
          return {
            error: prevState.error,
            calls: updatedCallDocs,
          }
        })
      });
    } catch (error) {
      setState({
        calls: null,
        error: 'Error getting calls',
      })
    }
  }, [user.uid, workflow.workflowID])

  useEffect(() => {
    getCallsForUser();
    return () => {
      unsub.current?.();
    }
  }, [getCallsForUser])

  return (
    <Col>
      {state.calls ?
        state.calls.length ?
          state.calls.map((callSnap) => {
            return (
              <ScheduleCallView key={callSnap.id} callSnap={callSnap} />
            )
          })
          :
          <Row>
            <h4>User does not have any scheduled calls</h4>
          </Row>
        :
        <Row>
          {
            state.error ?
              <h4 className="mt-2 text-warning">{state.error}</h4>
              :
              <h4>Loading...</h4>
          }
        </Row>
      }
    </Col>
  )
}

export default ScheduledCallsView;

const FormSchema = Yup.object().shape({
  status: Yup.string()
    .required('Required'),
  dateOfCall: Yup.string().when('status', {
    is: ScheduledCallStatus.COMPLETED,
    then: Yup.string().required('Required when setting status to completed'),
    otherwise: Yup.string(),
  })
})

interface ScheduleCallViewProps {
  callSnap: DocumentSnapshot<DocumentData>;
}

// Override the dateOfCall value so we can input a string date and later change
// back into date
interface ScheduleCallFormValues {
  dateOfCall: string;
  status: ScheduledCallStatus,
}

const ScheduleCallView: React.FC<ScheduleCallViewProps> = ({ callSnap }) => {
  const [callData, setCallData] = useState<IScheduleCall | null>(null);
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const scheduleCall = useRef<ScheduleCall>();
  const unSub = useRef<() => void>();
  const isMounted = useRef(true);

  const parseCallSnap = useCallback(async () => {
    unSub.current = callSnap.ref.onSnapshot((snap) => {
      const _scheduleCall = ScheduleCall.fromFirestore(snap);
      setCallData({ ..._scheduleCall });
      scheduleCall.current = _scheduleCall;
    })
  }, [callSnap])

  useEffect(() => {
    isMounted.current = true;
    setCallData(null);
    parseCallSnap();
    return () => {
      unSub.current?.();
      isMounted.current = false;
    }
  }, [callSnap])

  const setDisappearingMessage = useCallback((msg, error = false) => {
    const setFunc = error ? setError : setMessage;
    setFunc(msg);
    setTimeout(() => {
      isMounted.current && setFunc("");
    }, 5000);
  }, [isMounted])

  const disabled = callData && [ScheduledCallStatus.COMPLETED, ScheduledCallStatus.ABANDONED].includes(callData.status);

  return (
    <Row className="my-3">
      <Col xs={12} md={7}>
        {
          callData ?
            <Card>
              <Card.Header>
                <Row>
                  <Col xs={6}>
                    <h6>{callData.eventName}{callData.required && ' (required)'}</h6>
                  </Col>
                  <Col xs={6}>
                    <Row className="justify-content-end px-2">
                      <h6>Created: {moment(callData.created).format("MM/DD/YY")}</h6>
                    </Row>
                  </Col>
                </Row>
              </Card.Header>
              <Card.Body>
                <Formik
                  initialValues={{
                    status: callData.status,
                    dateOfCall: callData.dateOfCall ? moment.utc(callData.dateOfCall).format("yyyy-MM-DD") : "",
                  }}
                  onSubmit={async (values: ScheduleCallFormValues, actions) => {
                    try {
                      scheduleCall.current.status = values.status;
                      if (values.status === ScheduledCallStatus.COMPLETED) {
                        scheduleCall.current.dateOfCall = new Date(values.dateOfCall);
                      }
                      else {
                        scheduleCall.current.dateOfCall = null;
                      }
                      await scheduleCall.current.persist();
                      setDisappearingMessage('Saved');
                    } catch (error) {
                      console.error(error);
                      setDisappearingMessage('Error saving', true);
                    } finally {
                      actions.setSubmitting(false);
                    }
                  }}
                  validateOnChange={false}
                  validationSchema={FormSchema}
                  enableReinitialize={true}
                >
                  <FormikForm role="form" style={{ width: '100%' }}>
                    <Container>
                      <Row>
                        <Col xs="auto">
                          <FormikSelect
                            name="status"
                            label="Call status"
                            desc="Status of the call, this will automatically update when a user schedules a call. Set to 'Completed' once the call is over."
                            options={[
                              { value: ScheduledCallStatus.NOT_SCHEDULED, label: 'Not scheduled' },
                              { value: ScheduledCallStatus.CALL_SCHEDULED, label: 'Call scheduled' },
                              { value: ScheduledCallStatus.ABANDONED, label: 'Abandoned call' },
                              { value: ScheduledCallStatus.DECLINED, label: 'Declined' },
                              { value: ScheduledCallStatus.COMPLETED, label: 'Completed' },
                            ]}
                            disabled={disabled}
                          />
                        </Col>
                        <Col xs="auto">
                          <FormikTextField
                            name="dateOfCall"
                            label="Date of call"
                            desc="Date the call took place"
                            type="date"
                            disabled={disabled}
                          />
                        </Col>
                      </Row>
                      {
                        callData.status === ScheduledCallStatus.CALL_SCHEDULED
                        && callData.callStartTime
                        && callData.callEndTime
                        &&
                        <p>
                          <small>
                            Scheduled for {moment(callData.callStartTime).format('h:mm')} -{' '}
                            {moment(callData.callEndTime).format('h:mm a, dddd, MMMM DD, YYYY')}
                          </small>
                        </p>
                      }
                      <FormikSubmitButton label="Save" disabled={disabled} />
                      {
                        message &&
                        <h6 className="mt-2 text-success">{message}</h6>
                      }
                      {
                        error &&
                        <h6 className="mt-2 text-warning">{error}</h6>
                      }
                    </Container>
                  </FormikForm>
                </Formik>
              </Card.Body>
            </Card>
            :
            <h6>Loading call info...</h6>
        }
      </Col>
    </Row>
  )
}
