import React, { useEffect, useState } from 'react';
import User from "../../../../Model/Usert";
import { Registration } from "../../../../Model/Registration";
import RegForm from "../../../Registration/RegForm";
import LoadingScreen from "../../../LoadingScreen";

interface UserRegProps {
    user: User;
}

const UserReg: React.FC<UserRegProps> = ({ user }) => {
    const [registration, setRegistration] = useState<Registration>();
    const [unregistered, setUnregistered] = useState<boolean>(false);

    useEffect(() => {
        if (!user?.email) return;
        Registration.registrationForEmail(user.email).then((reg) => {
            if (!reg) {
                setUnregistered(true);
            } else {
                setRegistration(reg);
            }
        })
    }, [user])

    if (registration)
        return <RegForm registration={registration}></RegForm>
    if (unregistered)
        return <RegForm presetValues={user}></RegForm>
    return <LoadingScreen></LoadingScreen>

};

export default UserReg;