import {
  ILandingPage,
  PayCondition,
  PaymentNotifications,
  ScheduleCallOption,
  ScheduleCallOptions,
  SurveyWeek,
} from "@swing-therapeutics/surveybay/dist/types";
import {
  consentCall,
  terminationCall,
} from "../Components/Surveysaurus/CalendlyCalls";
import { firestore } from "../firebase";
import * as uuid from "uuid";

const numberOfTherapyWeeks = 12;

export const defaultPayCondition = (paymentFor = ''): PayCondition => {
  return {
    eligibilityCompleted: false,
    baselineCompleted: false,
    therapyWeeksCompleted: new Array(numberOfTherapyWeeks).fill(false),
    paymentFor,
    amount: 0,
  }
}

const emptyPaymentNotification: PaymentNotifications = {
  eligibility: null,
  baseline: null,
  surveyWeeks: new Array(numberOfTherapyWeeks).fill(null),
}

// Schedule call options field not used for standard portion of trial
export type LandingPageFormValues = Omit<ILandingPage, 'created' | 'updated' | 'scheduleCallOptions'>;

const getEmptySurveyWeeks = (): SurveyWeek[] => {
  return new Array(numberOfTherapyWeeks).fill(0).map((_value, index) => ({
    week: index + 1,
    surveys: [],
    daysToCompleteTherapySurvey: 10,
  }));
};

const defaultTermCall: ScheduleCallOption = {
  eventName: terminationCall.name,
  calendlyCallURL: terminationCall.calendlyURL,
  description: '',
  title: '',
  required: true,
}

const defaultConsentCall: ScheduleCallOption = {
  eventName: consentCall.name,
  calendlyCallURL: consentCall.calendlyURL,
  description: '',
  title: '',
  required: true,
}

class LandingPage implements ILandingPage {
  private _docPath: string;
  created: Date | undefined;
  updated: Date;
  landingPageKey: string;
  cohort: string;
  arm: string;
  site: string;
  study: string;
  role: string;
  trialID: string;
  eligibilityScreening: string[];
  terminationSurveys: string[];
  terminationCall: ScheduleCallOption;
  consentCall: ScheduleCallOption;
  baselineSurveys: string[];
  disclaimerText: string;
  weekdaySurveyAssignment: number; // (0-6)
  surveyWeeks: SurveyWeek[];
  tempoDownloadLink: string;
  daysToCompleteTherapySurvey: number;
  postEligibilityProgram: string;
  postBaselineProgram: string;
  postTherapyProgram: string;
  earlyTerminationProgram: string;
  maxUsersInConsent: number;
  paymentNotifications: PaymentNotifications;
  extensionID: false | string;
  stylingID: false | string;
  scheduleCallOptions: ScheduleCallOptions = {};

  constructor(props: any) {
    this.created = props.created ?? new Date();
    this.updated = props.updated ?? new Date();
    this.landingPageKey = props.landingPageKey ?? uuid.v4();
    this.cohort = props.cohort ?? "";
    this.arm = props.arm ?? "";
    this.site = props.site ?? "";
    this.study = props.study ?? "";
    this.role = props.role ?? "";
    this.trialID = props.trialID ?? "";
    this.eligibilityScreening = props.eligibilityScreening ?? [];
    this.baselineSurveys = props.baselineSurveys ?? [];
    this.disclaimerText = props.disclaimerText ?? "";
    this.weekdaySurveyAssignment = props.weekdaySurveyAssignment ?? 6;
    this.daysToCompleteTherapySurvey = props.daysToCompleteTherapySurvey ?? 4;
    this.surveyWeeks = props.surveyWeeks ?? getEmptySurveyWeeks();
    this.tempoDownloadLink = props.tempoDownloadLink ?? '';
    this.postEligibilityProgram = props.postEligibilityProgram ?? "";
    this.postBaselineProgram = props.postBaselineProgram ?? "";
    this.postTherapyProgram = props.postTherapyProgram ?? "";
    this.earlyTerminationProgram = props.earlyTerminationProgram ?? "";
    this.maxUsersInConsent = props.maxUsersInConsent ?? 20;
    this.paymentNotifications = props.paymentNotifications ?? emptyPaymentNotification;
    this.extensionID = props.extensionID ?? false;
    this.terminationCall = props.terminationCall ?? { ...defaultTermCall };
    this.terminationSurveys = props.terminationSurveys ?? [];
    this.consentCall = props.consentCall ?? { ...defaultConsentCall };
    this.stylingID = props.stylingID ?? false;
  }

  static fromFirestore(docSnap: firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>) {
    const data = docSnap.data();
    const created = data.created?.toDate();
    const updated = data.updated?.toDate();
    const landingPage = new LandingPage({ ...data, created, updated });
    landingPage._docPath = docSnap.ref.path
    return landingPage;
  }

  data(): ILandingPage {
    const data = { ...this }
    delete data._docPath;
    return data;
  }

  getDocPath() {
    return this._docPath;
  }

  getDaysToCompleteTherapySurveyIfExists() {
    this.surveyWeeks.forEach(
      (survey) =>
        (survey.daysToCompleteTherapySurvey =
          survey.daysToCompleteTherapySurvey === undefined
            ? this.daysToCompleteTherapySurvey
            : survey.daysToCompleteTherapySurvey)
    );
  }

  getEditableData(): LandingPageFormValues {
    this.getDaysToCompleteTherapySurveyIfExists()
    return {
      landingPageKey: this.landingPageKey,
      cohort: this.cohort,
      arm: this.arm,
      site: this.site,
      study: this.study,
      role: this.role,
      trialID: this.trialID,
      eligibilityScreening: this.eligibilityScreening,
      terminationSurveys: this.terminationSurveys,
      terminationCall: this.terminationCall,
      consentCall: this.consentCall,
      baselineSurveys: this.baselineSurveys,
      disclaimerText: this.disclaimerText,
      weekdaySurveyAssignment: this.weekdaySurveyAssignment,
      daysToCompleteTherapySurvey: this.daysToCompleteTherapySurvey,
      surveyWeeks: this.surveyWeeks,
      tempoDownloadLink: this.tempoDownloadLink,
      postEligibilityProgram: this.postEligibilityProgram,
      postBaselineProgram: this.postBaselineProgram,
      postTherapyProgram: this.postTherapyProgram,
      earlyTerminationProgram: this.earlyTerminationProgram,
      maxUsersInConsent: this.maxUsersInConsent,
      paymentNotifications: this.paymentNotifications,
      extensionID: this.extensionID,
      stylingID: this.stylingID,
    }
  }

  static getEditableData(): LandingPageFormValues {
    return {
      landingPageKey: "",
      cohort: "",
      arm: "",
      site: "",
      study: "",
      role: "",
      trialID: "",
      eligibilityScreening: [],
      terminationSurveys: [],
      terminationCall: { ...defaultTermCall },
      consentCall: { ...defaultConsentCall },
      baselineSurveys: [],
      disclaimerText: "",
      daysToCompleteTherapySurvey: 4, // Default is 4
      weekdaySurveyAssignment: 6, // Default is 6
      surveyWeeks: getEmptySurveyWeeks(),
      tempoDownloadLink: "",
      postEligibilityProgram: "",
      postBaselineProgram: "",
      postTherapyProgram: "",
      earlyTerminationProgram: "",
      maxUsersInConsent: 20, // Default is 20
      paymentNotifications: {
        eligibility: null,
        baseline: null,
        surveyWeeks: new Array(numberOfTherapyWeeks).fill(null),
      },
      extensionID: false,
      stylingID: false
    }
  }

  async persist() {
    const data = this.data();
    data.updated = new Date();
    return await firestore.doc(this._docPath)
      .update(data);
  }

  static async persistNewLandingPage(values: LandingPageFormValues): Promise<LandingPage> {
    const landingPage = new LandingPage({ ...values });
    // Make sure created/updated is set
    landingPage.created = new Date();
    landingPage.updated = new Date();
    await firestore.collection("web/surveysaurus/landingPages")
      .doc(landingPage.landingPageKey)
      .set(landingPage.data());
    return landingPage;
  }

  static async deleteLandingPage(landingPageKey: string) {
    await firestore.collection("web/surveysaurus/landingPages").doc(landingPageKey).delete();
  }

  static async fromLandingPageKey(landingPageKey: string): Promise<LandingPage> {
    const landingPageRef = await firestore.doc(`web/surveysaurus/landingPages/${landingPageKey}`).get();
    if (!landingPageRef.exists) {
      throw new Error(`Landing page with key ${landingPageKey} does not exist`);
    }
    return LandingPage.fromFirestore(landingPageRef);
  }
}

export default LandingPage;
