import { IScheduleCall, ScheduledCallStatus, WorkflowType } from "@swing-therapeutics/surveybay/dist/types";
import { firebaseTimestampToDate } from "@swing-therapeutics/swingcore/dist/utils/firebase";
import { DocumentData, DocumentSnapshot, firestore } from "../firebase";
export class ScheduleCall implements IScheduleCall {
  private _docPath: string;
  description: string;
  title: string;
  eventName: string;
  required: boolean;
  calendlyCallURL: string;
  therapyWeek: number;
  // If this is undefined then there is no call to schedule right now
  status: ScheduledCallStatus;
  created: Date;
  workflowType: WorkflowType;
  dateOfCall?: Date | null;
  eventURI?: string | null;
  inviteeURI?: string | null;
  callStartTime?: Date | null;
  callEndTime?: Date | null;
  locationInfo?: any | null;
  cancelURL?: string | null;
  rescheduleURL?: string | null;

  constructor(props: any) {
    Object.assign(this, props);
    this.callStartTime = props.callStartTime ? firebaseTimestampToDate(props.callStartTime) : props.callStartTime;
    this.callEndTime = props.callEndTime ? firebaseTimestampToDate(props.callEndTime) : props.callEndTime;
    this.created = props.created ? firebaseTimestampToDate(props.created) : props.created;
    this.dateOfCall = props.dateOfCall ? firebaseTimestampToDate(props.dateOfCall) : props.dateOfCall;
  }

  static fromFirestore(docSnap: DocumentSnapshot<DocumentData | undefined>): ScheduleCall {
    const data = docSnap.data();
    const scheduledCallMeta = new ScheduleCall(data);
    scheduledCallMeta._docPath = docSnap.ref.path;
    return scheduledCallMeta;
  }

  firestoreData() {
    // Only return fields that should be modified be modified in this repo
    const data: Partial<IScheduleCall> = {
      status: this.status,
    };
    if (this.dateOfCall) {
      data.dateOfCall = this.dateOfCall;
    }
    return data;
  }

  async persist() {
    await firestore.doc(this._docPath).update(this.firestoreData());
  }
}
