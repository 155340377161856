import { auth, firestore } from "../firebase";
import { SystemEvent } from './SystemEvent';

class Role {
    constructor() {
    }

    static fromFirestore(docRef) {
        let r = new Role();
        if (!docRef.exists) return;
        r.docRef = docRef;
        const docData = docRef.data();
        r.id = docRef.id;
        r.name = docData.name;
        r.membersCount = docData.membersCount;

        r.dateCreated = docData.dateCreated ? docData.dateCreated.toDate() : null;
        r.lastUpdated = docData.lastUpdated ? docData.lastUpdated.toDate() : null;
        r.updatedBy = docData.updatedBy ? docData.updatedBy : "";
        return r;
    }

    //firestore helpers
    data() {
        return {
            name: this.name, dateCreated: this.dateCreated, lastUpdated: this.lastUpdated, membersCount: this.membersCount, updatedBy: this.updatedBy,
        };
    }

    persist() {
        firestore.collection("roles").doc(this.name).set(this.data());
    }

    //data loading

    async getMembers(force) {
        if (!force && this.loadedMembers) {
            return this.loadedMembers;
        }
        var snapshot = await firestore
            .collection("roles")
            .doc(this.id)
            .collection("members")
            .get();

        if (snapshot.empty) return null;

        let members = [];
        snapshot.docs.forEach((doc) => {
            let t = new RoleMember(doc);
            members.push(t);
        });
        this.loadedMembers = members;
        return members;
    }

    //internal helper for removing a users email from a role

    static async hasRole(roleName, email) {
        if (!email || email.length === 0) return;

        let member = await firestore
            .collection("roles")
            .doc(roleName)
            .collection("members")
            .doc(email)
            .get();

        return member.exists;


    }

    static async removeEmail(roleName, email, eventGroup) {
        if (!email || email.length === 0) return;
        if (!eventGroup) eventGroup = new Date().toDateString();
        await firestore
            .collection("roles")
            .doc(roleName)
            .collection("members")
            .doc(email)
            .delete();
        console.log('deleted! with roleName ' + roleName + ' email ' + email);

        let props = {
            role: roleName, email: email,

        }
        //(type, category, message, eventGroup, props)
        SystemEvent.fireEvent("User Removed From Role", "Role Management", email + " was removed from the role " + roleName, eventGroup, props);
    }

    _removeEmail(email) {
        Role.removeEmail(this.name, email);
    }

    //just adds an email to the role, no side effects
    static async addEmail(roleName, email, uid, subjectID, eventGroup) {
        const name = auth.currentUser.email;

        let member = {
            email: email, uid: uid, subjectID: subjectID, dateAdded: new Date(), addedBy: name,
        };
        firestore
            .collection("roles")
            .doc(roleName)
            .collection("members")
            .doc(email)
            .set(member);

        let ref = await firestore.collection("roles").doc(roleName).get();
        if (!ref.exists) return;
        let dat = ref.data();
        dat.membersCount += 1;
        dat.lastUpdated = new Date();
        dat.updatedBy = auth.currentUser.email;
        ref.ref.update(dat);
        let props = {
            role: roleName, email: email, uid: uid, subjectID: subjectID,
        }
        //(type, category, message, eventGroup, props)
        SystemEvent.fireEvent("User Added To Role", "Role Management", email + " was added to the role " + roleName, eventGroup, props);


    }

    static async updateUserdocRole(email, newRoleName) {
        const existingUser = await firestore
            .collection("users")
            .where("email", "==", email)
            .get();
        let uid = null;
        let subjectID = null;
        let oldRoleName = null;

        if (!existingUser.empty) {
            var user = existingUser.docs[0];
            uid = user.id;
            const userData = user.data();
            subjectID = userData.subjectID ?? null;
            oldRoleName = userData.role ?? null;
            // Update the user's document with the new role
            user.ref.update({
                role: newRoleName
            })
        }
        // if (oldRoleName === newRoleName) oldRoleName = null;
        // analytics.logEvent("Userdoc changed for  " + email + " uid " + uid + "new role " + newRoleName + " old role" + oldRoleName);
        return {
            email: email, uid: uid, subjectID: subjectID, oldRoleName: oldRoleName,
        };
    }

    static async isEmailInRole(email, roleName) {
        if (!email || email.length === 0 || !roleName || roleName.length === 0) return false;
        const existingRef = await firestore
            .collection("roles")
            .doc(roleName)
            .collection("members")
            .doc(email)
            .get();

        return existingRef.exists;
    }

    static async addMemberByEmail(email, roleName) {
        const alreadyAMember = await Role.isEmailInRole(email, roleName);

        if (alreadyAMember) {
            console.log("user was already a member of this role", email);
            return;
        }

        let userProps = await Role.updateUserdocRole(email, roleName);
        await Role.addEmail(roleName, email, userProps.uid, userProps.subjectID, new Date().getTime());


        // if (userProps.oldRoleName) {
        //   Role.removeEmail(userProps.oldRoleName, email);
        // }
    }

    async addMemberByEmail(email) {
        await Role.addMemberByEmail(email, this.name);
    }

    matchesFilter(filter) {
        if (!filter || filter.length === 0) return true;
        return this.name.toLowerCase().includes(filter.toLowerCase());
    }

    //static helpers
    static async newRole(roleName) {
        if (!roleName || roleName.length === 0) return;
        const oldRole = await firestore.collection("roles").doc(roleName).get();
        if (oldRole.exists) {
            console.log("attempted to overwrite an existing role. Dont do that.", roleName);
            return;
        }
        const name = auth.currentUser.email;

        const roleData = {
            name: roleName, dateCreated: new Date(), lastUpdated: new Date(), membersCount: 0, updatedBy: name,
        };
        firestore.collection("roles").doc(roleName).set(roleData);
    }
}

class RoleMember {
    constructor() {
    }

    static fromFirestore(docRef) {
        if (!docRef.exists) return;

        let r = new RoleMember();

        r.docRef = docRef;
        const docData = docRef.data();
        r.id = docRef.id;
        r.email = docData.email;
        r.uid = docData.uid;

        r.dateAdded = docData.dateAdded ? docData.dateAdded.toDate() : null;

        r.addedBy = docData.addedBy;
        r.sujectID = docData.subjectID;
        return r;
    }

    //firestore helpers
    data() {
        return {
            email: this.email, uid: this.uid, dateAdded: this.dateAdded, addedBy: this.addedBy, subjectID: this.subjectID,
        };
    }

    persist() {
        this.docRef.set(this.data());
    }

    matchesFilter(filter) {
        if (!filter || filter.length === 0) return true;
        return (this.email.toLowerCase().includes(filter.toLowerCase()) || this.addedBy.toLowerCase().includes(filter.toLowerCase()));
    }
}

export { Role, RoleMember };
