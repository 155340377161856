import { firestore } from "firebase";
import { useFormik } from "formik";
import React, { Fragment, useReducer, useState } from "react";
import { Button, Col, Table } from "react-bootstrap";
import { Chapter } from "@swing-therapeutics/swingcore/dist/models/content/Chapter";
import { usePrograms } from "../ContentGetters";
import { LessonAndChapterCopyModal } from "../Lessons/LessonAndChapterCopyModal";
import { Program } from "../Program";

interface ChapterListProps {
  setShow: Function;
  setActiveChapter: Function;
  chapters: Chapter[];
  programKey: string;
}

const initialValues: {
  checks: Array<Chapter | boolean>;
  selectedProgramID: string | undefined;
} = {
  checks: [],
  selectedProgramID: undefined,
};

const ChapterList = ({
  setShow,
  setActiveChapter,
  chapters,
  programKey,
}: ChapterListProps) => {
  const [selectMode, setSelectMode] = useReducer((current) => !current, false);
  const [showCopy, setShowCopy] = useState(false);
  const programs = usePrograms();

  const { values, setFieldValue, resetForm, handleSubmit }= useFormik({
    initialValues,
    onSubmit: ({checks, selectedProgramID}) => {
      const batch = firestore().batch();
      for (let chapter of checks as Chapter[]) {
        if (!chapter?.id) continue;
        try {
          chapter.programKey = selectedProgramID;
          chapter.addToBatch(batch);
        } catch (error) {
          console.log('unable to persist chapter', chapter, error)
        }
      }
      batch.commit();
      setFieldValue('checks', []);
      setSelectMode();
    },
  });

  const checkBox = (index: number, value: any) => {
    if (values.checks[index]) {
      setFieldValue(`checks.${index}`, false);
    } else {
      setFieldValue(`checks.${index}`, value);
    }
  };

  const selectAll = () => {
    //Selects or deselects all check boxes
    if (values.checks.length === chapters.length) {
      setFieldValue("checks", []);
    } else {
      setFieldValue("checks", chapters);
    }
  };

  const hasSelectedLessons = () => {
    for (let chapter of values.checks) {
      if (chapter !== false) return true;
    }
    return false;
  };

  const onRowSelect = (index: number, chapter: Chapter) => {
    if (selectMode) {
      checkBox(index, chapter)
    } else {
      setActiveChapter(chapter);
      setShow(true)
    }
  }

  return (
    <Col className={"chapter-content"}>
      {showCopy && (
        <LessonAndChapterCopyModal
          contents={values.checks}
          programs={programs}
          selectProgram={(selectedProgram: Program) => {
            setFieldValue("selectedProgramID", selectedProgram.id);
            handleSubmit();
          }}
          close={() => setShowCopy(false)}
        />
      )}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <h4 className={"table-title"}>Chapters</h4>
        <div>
          {selectMode && (
            <Button
              variant={hasSelectedLessons() ? "success" : "outline-dark"}
              style={{ marginRight: 10 }}
              disabled={!hasSelectedLessons()}
              onClick={() => setShowCopy(true)}
            >
              Copy
            </Button>
          )}
          <Button
            variant={selectMode ? "primary" : "outline-primary"}
            style={{ margin: 5 }}
            onClick={() => {
              if (selectMode) {
                resetForm();
              }
              setSelectMode();
            }}
          >
            {selectMode ? "Cancel" : "Select for Copy"}
          </Button>
        </div>
      </div>
      <Table bordered hover responsive="xl">
        <thead>
          <tr>
            {selectMode && (
              <th>
                <Button onClick={() => selectAll()} variant="success">
                  Select All
                </Button>
              </th>
            )}
            <th>Chapter</th>
            <th>Title</th>
            <th>Description</th>
            <th>Last Edited</th>
          </tr>
        </thead>
        <tbody>
          {chapters.map((chapter, index) => {
            return (
              <Fragment key={index}>
                <tr
                  className="chapter-row"
                  onClick={() => onRowSelect(index, chapter)}
                >
                  {selectMode && (
                    <td>
                      <input
                        type="checkbox"
                        name="checks"
                        onChange={() => {
                          checkBox(index, chapter);
                        }}
                        checked={!!values.checks[index] ?? false}
                      />
                    </td>
                  )}
                  <td>{chapter.num}</td>
                  <td>{chapter.title}</td>
                  <td>{chapter.description}</td>
                  <td>{chapter.updated?.toLocaleDateString()}</td>
                </tr>
              </Fragment>
            );
          })}
        </tbody>
      </Table>
    </Col>
  );
};

export { ChapterList };
