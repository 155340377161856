import { UserStatus } from "@swing-therapeutics/surveybay/dist/types";
import React, { useEffect, useState } from "react";
import Plot from 'react-plotly.js';
import { firestore } from "../../../../firebase";
import User from "../../../../Model/Usert";
import LoadingScreen from "../../../LoadingScreen";
import { isExcludedEmail } from "../ExcludeFromStats";

const workflowStatusRank: { [key in Exclude<UserStatus, UserStatus.CREATING>]: number } = {
  [UserStatus.EXT_EARLY_TERMINATION_FOLLOWUP]: -2,
  [UserStatus.EXT_EARLY_TERMINATION]: -2,
  [UserStatus.EARLY_TERMINATION_FOLLOWUP]: -1,
  [UserStatus.EARLY_TERMINATION]: -1,
  // These are in the order that a user would go through
  [UserStatus.CREATED]: 0,
  [UserStatus.HOLDING_PRE_SCREEN]: 1,
  [UserStatus.SCREENING]: 2,
  [UserStatus.SCREENING_ABANDONED]: 3,
  [UserStatus.SCREENING_FAIL]: 4,
  [UserStatus.CONSENT]: 5,
  [UserStatus.CONSENT_ABANDONED]: 6,
  [UserStatus.CONSENT_FAIL]: 7,
  [UserStatus.BASELINE]: 8,
  [UserStatus.BASELINE_ABANDONED]: 9,
  [UserStatus.PRE_THERAPY]: 10,
  [UserStatus.NEVER_STARTED]: 11,
  [UserStatus.THERAPY]: 12,
  [UserStatus.DONE]: 13,
  [UserStatus.EXT_ELIGIBLE]: 14,
  [UserStatus.EXT_DECLINED]: 15,
  [UserStatus.EXT_CONSENT]: 16,
  [UserStatus.EXT_CONSENT_ABANDONED]: 17,
  [UserStatus.EXT_CONSENT_FAIL]: 18,
  [UserStatus.EXT_PRE_THERAPY]: 19,
  [UserStatus.EXT_NEVER_STARTED]: 20,
  [UserStatus.EXT_THERAPY]: 21,
  [UserStatus.EXT_DONE]: 22,
}

interface Data {
  funnelData: [number, number, number, number, number, number];
  barData: [number, number, number, number, number, number, number, number, number];
}

const WorkflowCharts: React.FC = () => {
  const [data, setDate] = useState<Data>();

  useEffect(() => {
    const unsub = firestore.collection("users")
      .where("workflowStatus", "!=", "")
      .onSnapshot((snap) => {
        let accountsCreated = 0;
        let eligibilityPassed = 0;
        let consentPassed = 0;
        let consentAbandoned = 0;
        let baselineCompleted = 0;
        let therapyStarted = 0;
        let therapyCompleted = 0;

        let screeningFail = 0;
        let screeningAbandoned = 0;
        let baselineAbandoned = 0;
        let earlyTermination = 0;
        let extEarlyTermination = 0;
        let neverStarted = 0;
        let extNeverStarted = 0;
        let consentFail = 0;
        for (const userRef of snap.docs) {
          const user = User.fromFirestore(userRef);
          if (isExcludedEmail(user.email)) {
            continue;
          }
          accountsCreated++;
          // Rank of the workflow is in order a user would go through
          // Early termination is special case, cant determine where in the workflow they were set to early termination
          const workflowRank = workflowStatusRank[user.workflowStatus];
          // Ex. if the rank is above 4, that means the user passed eligibility
          if (workflowRank > 4) {
            eligibilityPassed++;
          }
          else {
            if (workflowRank === 3) {
              screeningAbandoned++;
            }
            else if (workflowRank === 4) {
              screeningFail++;
            }
            else if (workflowRank === -1) {
              earlyTermination++;
            } else if (workflowRank === -2) {
              extEarlyTermination++;
            }
            // Exit early from conditional checks and continue,
            // All future conditionals check for a larger numebr
            continue;
          }
          if (workflowRank > 7) {
            consentPassed++;
          }
          else {
            if (workflowRank === 6) {
              consentAbandoned++;
            }
            else if (workflowRank === 7) {
              consentFail++;
            }
            continue;
          }
          if (workflowRank > 9) {
            baselineCompleted++;
          }
          else {
            if (workflowRank === 9) {
              baselineAbandoned++;
            }
            continue;
          }
          if (workflowRank > 10) {
            therapyStarted++;
          }
          else {
            continue;
          }
          if (workflowRank === 11) {
            neverStarted++;
            // Exit early, if equal to 11 later checks won't matter
            continue;
          }
          if (workflowRank > 12) {
            therapyCompleted++;
          }
          if (workflowRank === 20) {
            extNeverStarted++;
          }
        }
        setDate({
          funnelData: [accountsCreated, eligibilityPassed, consentPassed, baselineCompleted, therapyStarted, therapyCompleted],
          barData: [screeningFail, screeningAbandoned, baselineAbandoned, earlyTermination, extEarlyTermination, consentFail, consentAbandoned, neverStarted, extNeverStarted],
        });
      })

    return unsub;
  }, [])

  return (
    <>
      {
        data ?
          <>
            <Plot
              data={[{
                type: 'funnel',
                y: ["Accounts created", "Eligibility passed<br>(Made it to Consent)", "Consent passed<br>(Made it to Baseline)", "Baseline completed<br>(Made it to Pre-therapy)", "Therapy started<br>(Made it to Therapy)", "Therapy completed<br>(Made it to Done)"],
                x: data.funnelData,
                hoverinfo: 'x+percent previous+percent initial',
                marker: { color: ["383cfb", "0084ff", "00afff", "00d0f9", "00ebac", "3eff61"] },
              }]}
              layout={{
                title: '<b>User Workflow Funnel</b><br>Represents different stages of the workflow,<br>each box is a percentage of \'Accounts Created\'',
                margin: { l: 150, t: 130 },
                width: '100%',
                height: 500,
              }}
              config={{ displayModeBar: false }}
            />
            <Plot
              data={[{
                type: 'bar',
                y: data.barData,
                x: ["Screening fail", "Screening abandoned", "Baseline abandoned", "Early termination", "EXT Early termination", "Consent fail", "Consent abandoned", "Never started", "EXT never started"],
                hoverinfo: 'percent previous+percent initial',
                marker: { color: ["#f2ff42", "#ffe217", "#ffc115", "#ff9b37", "#ff6e5a", "#ff3980", "#ff00aa", "#ff00d6", "#e530ff"] },
              }]}
              layout={{
                title: '<b>Fail Info</b><br>Number of users per each fail type',
                yaxis: {
                  title: '# of users',
                },
                width: '100%',
                height: 500,
              }}
              config={{ displayModeBar: false }}
            />
          </>
          :
          <LoadingScreen />
      }
    </>
  )
}

export default WorkflowCharts;
