import React, { useCallback, useEffect, useState } from "react";
import User from "../../../../Model/Usert";
import { Program } from "../../../Content/Program";
import { firestore } from "firebase";
import { Container, Row, Tab, Tabs } from "react-bootstrap";
import { Link, Redirect, RouteComponentProps } from "react-router-dom";
import LoadingScreen from "../../../LoadingScreen";
import UserSessionsTable from "./UserSessionsTable";
import UserJournalsTable from "./UserJournalsTable";
import UserTasksTable from "./UserTasksTable";
import { UserSectionProps } from "./UserSectionInterface";
import UserUsageTable from "./UserUsageTable";
import UserPromptsTable from "./UserPromptsTable";
import UserPlaylistTable from "./UserPlaylistTable";
import { useCollectionDataOnce } from "react-firebase-hooks/firestore";
import { useMemo } from "react";
import { keyBy } from "lodash";

interface TabCollectionProps {
  program: Program;
  user: User;
}

interface TabInfo {
  label: string;
  id: string;
  component: React.FC<UserSectionProps>;
}

// Tabs specific to control programs
const controlPotentialTabs: TabInfo[] = [
  {
    label: 'Journal Entries',
    id: 'journalEntries',
    component: UserJournalsTable,
  },
  {
    label: 'Usage',
    id: 'usage',
    component: UserUsageTable,
  },
]

// Tabs specific to programs other than control (includes control programs tabs)
const otherPotentialTabs: TabInfo[] = [
  {
    label: 'Playlist Progress',
    id: 'playlistProgress',
    component: UserPlaylistTable,
  },
  {
    label: 'Sessions',
    id: 'sessions',
    component: UserSessionsTable,
  },
  {
    label: 'Tasks',
    id: 'tasks',
    component: UserTasksTable,
  },
]

const controlPrograms = ["control", "control_v2", "control_v3"];

const TabCollection: React.FC<TabCollectionProps> = ({ program, user }) => {
  const [availableTabs, setAvailableTabs] = useState<TabInfo[]>([]);

  const getAvailableTabs = useCallback(async () => {
    const newAvailableTabs = [];
    const baseCollection = firestore()
      .collection("users")
      .doc(user.uid)
      .collection("programs")
      .doc(program.id)
    const potentialTabs = controlPrograms.includes(program.id) ?
      controlPotentialTabs
      :
      [...otherPotentialTabs, ...controlPotentialTabs];
    // Wait for all the queries to finish
    await Promise.all(potentialTabs.map((potentialTab) => {
      return baseCollection
        .collection(potentialTab.id)
        .get()
        .then((collection) => {
          // If the collection contains docs then it exists in the program
          if (collection?.docs.length) {
            newAvailableTabs.push(potentialTab);
          }
        })
    }))
    setAvailableTabs(newAvailableTabs);
  }, [user, program])

  useEffect(() => {
    setAvailableTabs([]);
    getAvailableTabs();
  }, [user, program]);

  return (
    <>
      {
        availableTabs.length ?
          // Tabs transition component throws warning in console but has to be true for lazy loading mountOnEnter = true
          <Tabs 
            id="program-tabs"
            // defaultActiveKey={availableTabs[0].id}
            defaultActiveKey="journalEntries"
            mountOnEnter={true}>  
            {availableTabs.map((tab) => {
              const TabComponent = tab.component;
              return (
                <Tab eventKey={tab.id} key={tab.id} title={tab.label}>
                  <TabComponent user={user} programID={program.id} />
                </Tab>
              )
            })}
            {program.promptAnswers &&
              <Tab eventKey="promptAnswers" title="Prompt Answers">
                <UserPromptsTable user={user} programID={program.id} />
              </Tab>
            }
          </Tabs>
          :
          <LoadingScreen />
      }
    </>
  )
}

interface UserProgramPageParams {
  programID?: string;
}

interface LocationState {
  program?: Program;
  programName?: string;
}

interface UserProgramProps extends RouteComponentProps<UserProgramPageParams, any, LocationState> {
  user: User;
  redirect: string;  //Url to redirect to if component does not receive program in state
}

const programsWithoutTabs = ["goodbyeST", "goodbyeACT", "screeningWeek", "dropout"];

const UserProgram: React.FC<UserProgramProps> = ({ location, user, redirect }) => {
  if (!location.state?.program) {
    console.error("No program passed to <UserProgram/> in React Router state");
    return (
      <Redirect to={redirect} />
    )
  }
  const renderTabs = !programsWithoutTabs.includes(location.state.program.id);

  return (
    <>
      {
        location.state.program ?
          <>
            <Row>
              <h2>
                {location.state.programName}
                {user.activeProgram === location.state.program.id && ' (Active program)'}
              </h2>
            </Row>
            <Row>
              {
                location.state.program.therapyStartDate &&
                <h4>Program started: {location.state.program.therapyStartDate.toTimeString()}</h4>
              }
            </Row>
            <br />
            {renderTabs &&
              <TabCollection user={user} program={location.state.program} />
            }
          </>
          :
          <strong>Error: No program supplied to program page</strong>
      }
    </>
  );
};

export default UserProgram;

interface UserProgramListProps {
  programs: Program[];
  user: User;
  linkBaseUrl: string;
}

export const UserProgramList: React.FC<UserProgramListProps> = ({ programs, user, linkBaseUrl }) => {
  // Use the template programs to display the names of the programs
  // User's copy of the program could have an out of date name
  const [programTemplates, programTemplatesLoading] = useCollectionDataOnce(firestore().collection('programs'));
  const programTemplatesById = useMemo(() => keyBy(programTemplates || [], 'id'), [programTemplates]);

  if (programTemplatesLoading) {
    return (<LoadingScreen />);
  }

  return (
    <Container>
      <Row>
        <h3>User's programs:</h3>
      </Row>
      <Row>
        <ul>
          {programs.map((program, index) => {
             if (!program.id || program.id === "null") return null;
            const activeProgram = program.id === user.activeProgram;
            return (
              <li key={index}>
                <Link
                  to={{
                    pathname: linkBaseUrl + "/" + program.id,
                    state: {
                      program,
                      programName:
                        (programTemplatesById[program.id] as unknown as any)
                          ?.name || program.name,
                    },
                  }}
                >
                  <h4>
                    {(programTemplatesById[program.id] as unknown as any)
                      ?.name || program.name}
                    <span> (Program Key: </span>
                    <span style={{ color: "orange" }}>{program.key}</span>
                    <span> )</span>
                    {!!activeProgram && (
                      <span style={{ color: "blue" }}>
                        {" (Active program)"}
                      </span>
                    )}
                  </h4>
                </Link>
              </li>
            );
          })}
        </ul>
      </Row>
    </Container>
  )
}
