import { auth, firestore } from "../../firebase";
import { v4 as idMaker } from "uuid";
import { isNil, omitBy } from "lodash";
import * as yup from "yup";
import { Timestamp } from "../../firebase";

enum ContentType {
  ACT = "ACT",
  ST = "ST",
}

export enum ContentMode {
  Page = "page", //shows a page
  Content = "content", //shows the tabs
}

interface Message {
  title: string;
  text: string;
}

const emptyMessage: Message = {
  title: "",
  text: "",
}

interface PromptAnswers {
  [key: string]: string
}

class Program {
  created: Date = new Date();
  id: string = "testKey";
  key: string = "testKey";
  contentTypeKey: ContentType = ContentType.ACT;
  initialDisplayState: ContentMode = ContentMode.Content;
  startingPageKey?: string = "testKey";
  version: number = 0.1;
  updated: Date = new Date();
  updatedBy: string = "";
  description: string = "";
  name: string = "";
  reminderMessage?: Message = emptyMessage;
  promptAnswers: PromptAnswers = {};
  therapyStartDate: Date = null;
  enrollmentState: string = "unknown"
  isNotHoldingProgram?: boolean

  //ST specific props
  symptomTrackerID?: string;
  resourceTabInstructions?: string;

  //makes a minimal program with defaults set
  static byKey(key: string) {
    let prog = new Program();
    prog.id = key;
    prog.key = key;
    return prog;
  }

  static validationSchema(preexistingIDs: string[], currentID: string) {
    const otherIDs = preexistingIDs.filter(id => id !== currentID);
    return yup.object().shape({
      id: yup.string()
        .required("ID is required.")
        .notOneOf(otherIDs, 'Cannot use preexisting ID'),
      name: yup.string().required("Name is required."),
      description: yup.string().required("Description is required."),
      symptomTrackerID: yup.string().when(
        'contentTypeKey', {
          is: ContentType.ST,
          then: yup.string().required('Must select a symptom tracker.'),
        }
      ),
      resourceTabInstructions: yup.string().when(
        'contentTypeKey', {
          is: ContentType.ST,
          then: yup.string().required('Must input resource tab instructions.'),
        }
      )
    })
  }

  static fromParams(props: any) {
    let program = new Program();
    program.id = props.id;
    program.created = parseDate(props.created);
    program.updated = parseDate(props.updated);
    program.key = program.id;
    program.enrollmentState = props.enrollmentState ? props.enrollmentState : "unknown";
    program.contentTypeKey = props.contentTypeKey
      ? props.contentTypeKey
      : ContentType.ACT;
    program.initialDisplayState = props.initialDisplayState
      ? props.initialDisplayState
      : ContentMode.Content;
    program.startingPageKey = props.startingPageKey
      ? props.startingPageKey
      : null;
    program.version = props.version ? props.version : 0.1;
    program.updatedBy = props.updatedBy ? props.updatedBy : "Flapjack";
    program.description = props.description ? props.description : "a program";
    // program name on firebase is sometime "name" and sometimes "programName" and sometimes "programName" is empty
    program.name = props.programName || props.name ? props.programName || props.name : props.id;
    program.reminderMessage = props.reminderMessage
      ? props.reminderMessage
      : null;
    program.symptomTrackerID = props.symptomTrackerID;
    program.resourceTabInstructions = props?.resourceTabInstructions;
    program.promptAnswers = props.promptAnswers;
    program.therapyStartDate = props.therapyStartDate?.toDate();
    program.isNotHoldingProgram = props.isNotHoldingProgram;
    return program;
  }

  static fromFirestore(docRef: any) {
    return Program.fromParams({...docRef.data(), id: docRef.id});
  }

  data() {
    this.updated = new Date();
    this.updatedBy = auth.currentUser?.email;
    const data = { ...this };
    return omitBy(data, isNil)
  }

  async persist() {
    let res = await firestore
      .collection("programs")
      .doc(this.key)
      .set(this.data());
    return res;
  }
}

const parseDate = (date: undefined | Timestamp | Date): Date => {
  if (isNil(date)) {
    return new Date();
  }
  
  if ('toDate' in date) {
    return date.toDate();
  } 

  return date;
}

class Page {
  id: string = idMaker();
  key: string = "uniqueKey";
  name: string = "visibleName";
  HTML: string = "<HMTL><Body><h1>Hello!</h1></Body></HMTL>";
  URL: string = "";
  programKey: string = "";
  created: Date = new Date();
  updated: Date = new Date();

  static fromFirestore(docRef: any) {
    let obj = new Page();
    let content = docRef.data();

    obj.id = docRef.id;
    obj.created = content.created ? content.created.toDate() : new Date();
    obj.updated = content.updated ? content.updated.toDate() : new Date();
    obj.URL = content.url ? content.url : "";

    obj.key = content.key;
    obj.name = content.name ? content.name : null;
    obj.HTML = content.HTML ? content.HTML : null;
    obj.programKey = content.programKey;

    return obj;
  }

  data() {
    this.updated = new Date();
    const data = { ...this };
    data["updatedBy"] = auth.currentUser?.email;
    return omitBy(data, isNil);
  }

  async persist() {
    let res = await firestore
      .collection("programs")
      .doc(this.programKey)
      .collection("pages")
      .doc(this.id)
      .set(this.data(), { merge: true })
      .catch((e) => {
        console.log("unable to persist page ", e);
      });
    return res;
  }
}

export { Page, Program, ContentType };
