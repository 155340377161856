import React, { useCallback, useEffect, useState } from "react";
import {Col, Container, Row } from "react-bootstrap";
import _ from "lodash";
import QuestionDisplay from "@swing-therapeutics/surveybay/dist/FormComponents/QuestionDisplay";
import { FailReason, Question } from "@swing-therapeutics/surveybay/dist/types";
import { firestore } from "../../../../../firebase";
import User from "../../../../../Model/Usert";
import EarlyTermination from "./EarlyTermination";

interface WorkflowOverviewProps {
  user: User;
}

const WorkflowOverview: React.FC<WorkflowOverviewProps> = ({ user }) => {

  return (
    <Container>
      <Row className="mb-4">
        <h3>Workflow overview</h3>
      </Row>
      <Row className="mb-4">
        <h4>Workflow status: {user.workflowStatus}</h4>
      </Row>
      {
        user.failReason &&
        <>
          <Row>
            <h4>Fail info:</h4>
          </Row>
          <Row>
            <h5>Reason for failing: {user.failReason.reason}</h5>
          </Row>
          {
            user.failReason.failSurveyBayResponseID &&
            <DisplayFailQuestion userUID={user.uid} failReason={user.failReason} />
          }
        </>
      }
      <EarlyTermination user={user} />
    </Container>
  )
}

export default WorkflowOverview;

interface DisplayFailProps {
  failReason: FailReason;
  userUID: string;
}

const DisplayFailQuestion: React.FC<DisplayFailProps> = ({ failReason, userUID }) => {
  const [failQuestions, setFailQuestions] = useState<Question[]>();

  const getSetFailQuestions = useCallback(async (surveyBayResponseID: string, failQuestionIndexes: number[], userUID: string) => {
    const form = await firestore.doc(`/users/${userUID}/surveyBayResponses/${surveyBayResponseID}`).get();
    const formData = form.data();
    if (!form.data()) {
      console.error(`Cannot find survey bay response form at location /users/${userUID}/surveyBayResponses/${surveyBayResponseID}`);
      return;
    }

    const failQuestions = failQuestionIndexes.map((questionIndex) => formData.questions[questionIndex] as Question);

    setFailQuestions(failQuestions);
  }, [])

  useEffect(() => {
    if (failReason.failSurveyBayResponseID) {
      getSetFailQuestions(failReason.failSurveyBayResponseID, failReason.failQuestionIndexes, userUID);
    }
  }, [])

  return (
    <Row className="my-3">
      <Col>
        <Row>
          <h5>
            Question(s) failed on:
          </h5>
        </Row>
        {
          failQuestions?.map((question) => {
            return (
              <Row className="my-3" key={question.uniqueID}>
                <Col md={4} xs={12}>
                  <Row className="mb-2">
                    <QuestionDisplay mode="display" question={question} />
                  </Row>
                  <Row>
                    <small>
                      Survey name: {failReason.failSurveyName}
                    </small>
                  </Row>
                  <Row>
                    <small>
                      Question ID: {question.uniqueID}
                    </small>
                  </Row>
                </Col>
              </Row>
            )
          })
        }
      </Col>
    </Row>
  )
}
