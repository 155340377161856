import { FormikErrors, useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Modal, } from "react-bootstrap";
import { firestore } from "firebase";
import { useCollectionOnce } from "react-firebase-hooks/firestore";
import {
  formikHasError,
  getFirstFormikError,
} from "../../../utils/FormikHelpers";

type Document = firebase.firestore.QueryDocumentSnapshot<
  firebase.firestore.DocumentData
>;

interface PlaylistCloneProps {
  show: boolean;
  close: Function;
  programKey: string;
  playlistDoc: Document;
}

enum CloneOption {
  clone = "clone",
  copy = "copy",
  none = "none",
}

interface InitialValuesProps {
  selectedOption: CloneOption;
  copyId?: string;
  cloneId?: string;
  selectedProgram?: string;
}

interface FinalCloneProps {
  oldProgramKey: string;
  oldPlaylistKey: string;
  newProgramKey: string;
  newPlaylistKey: string;
}

export const PlaylistCloneModal = ({
  show,
  close,
  programKey,
  playlistDoc,
}: PlaylistCloneProps) => {
  const initialValues: InitialValuesProps = {
    selectedOption: CloneOption.none,
    copyId: playlistDoc.id,
    cloneId: undefined,
    selectedProgram: undefined,
  };

  //Keep track of submit being pressed to conditionally show errors
  const [submitPressed, setSubmitPressed] = useState(false);
  const [programs] = useCollectionOnce(firestore().collection("programs"));

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validate: values => {
      const errors: FormikErrors<InitialValuesProps> = {};
      const {selectedOption, copyId, cloneId, selectedProgram} = values;

      switch (selectedOption) {
        case CloneOption.none:
          errors.selectedOption = 'Must select Clone or Copy';
          break;
        case CloneOption.clone:
          if (isInvalidString(cloneId)) {
            errors.copyId = "Must input id";
          }
          break;
        case CloneOption.copy:
          if (isInvalidString(copyId)) {
            errors.cloneId = "Must input id";
          }
          if (isInvalidString(selectedProgram)) {
            errors.cloneId = "Must select a program";
          }
          break;
        default:
          break;
      }

      return errors;
    },
    onSubmit: async (values) => {
      let finalProps: FinalCloneProps;
      //Ignoring ts error, values are guaranteed from validation
      if (values.selectedOption === CloneOption.clone) {
        finalProps = {
          oldPlaylistKey: playlistDoc.id, oldProgramKey: programKey,
          //@ts-ignore
          newPlaylistKey: values.cloneId, newProgramKey: programKey
        }
      } else {
        finalProps = {
          oldPlaylistKey: playlistDoc.id, oldProgramKey: programKey,
          //@ts-ignore 
          newPlaylistKey: values.copyId, newProgramKey: values.selectedProgram
        }
      }
      try {
        await clonePlaylist(finalProps);
        window.alert(`Playlist ${finalProps.newPlaylistKey} created in program ${finalProps.newProgramKey}`)
      } catch (error) {
        console.log(error);
        window.alert(`Error creating new playlist`);
      }
      close();
    },
  });

  useEffect(() => {
    formik.resetForm();
  }, [show]);

  const getProgramOptions = () => {
    if (programs) {
      return programs.docs
        .filter((program) => program.id !== programKey)
        .map((program) => (
          <option key={program.id} value={program.id}>
            {program.id}
          </option>
        ));
    }
  };

  return (
    <>
      <Modal show={show} onHide={close}>
        <Modal.Header closeButton>
          <Modal.Title>Edit</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Col>
            <Form.Group>
              <Form.Label>
                <strong>Clone or Copy</strong>
              </Form.Label>
              <Form.Control
                name="selectedOption"
                value={formik.values.selectedOption}
                onChange={formik.handleChange}
                as="select"
              >
                <option value={CloneOption.none} selected disabled>
                  Choose one option...
                </option>
                <option value={CloneOption.clone}>
                  Clone playlist within this program
                </option>
                <option value={CloneOption.copy}>
                  Copy to another program
                </option>
              </Form.Control>
            </Form.Group>
            {formik.values.selectedOption === CloneOption.clone && (
              <Form.Group>
                <Form.Label>New ID</Form.Label>
                <Form.Control
                  name="cloneId"
                  value={formik.values.cloneId}
                  onChange={formik.handleChange}
                  placeholder="Input new ID"
                />
              </Form.Group>
            )}
            {formik.values.selectedOption === CloneOption.copy && (
              <Form.Group>
                <Form.Label>ID</Form.Label>
                <Form.Control
                  name="copyId"
                  value={formik.values.copyId}
                  onChange={formik.handleChange}
                  placeholder="Input new ID"
                />
              </Form.Group>
            )}
            {formik.values.selectedOption === CloneOption.copy && (
              <Form.Group>
                <Form.Label>Select Program</Form.Label>
                <Form.Control
                  name="selectedProgram"
                  value={formik.values.selectedProgram}
                  onChange={formik.handleChange}
                  as="select"
                >
                  <option value={CloneOption.none} selected disabled>
                    Choose one playlist...
                  </option>
                    {getProgramOptions()}
                </Form.Control>
              </Form.Group>
            )}
          </Col>
        </Modal.Body>
        <Modal.Footer>
          <Col>{submitPressed && getFirstFormikError(formik.errors)}</Col>
          <Button onClick={() => close()} variant="outline-secondary">
            Close
          </Button>
          <Button
            onClick={() =>{
              setSubmitPressed(true);
              formik.submitForm()
            }}
            variant="outline-success"
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const isInvalidString = (value?: string): boolean => (
  value === undefined || value.length === 0
)

const clonePlaylist = async ({oldPlaylistKey, oldProgramKey, newPlaylistKey, newProgramKey}: FinalCloneProps) => {
  const playlistDoc = await firestore()
    .collection("programs")
    .doc(oldProgramKey)
    .collection("playlist")
    .doc(oldPlaylistKey)
    .get();
  const sessionDocs = await firestore()
    .collection("programs")
    .doc(oldProgramKey)
    .collection("playlist")
    .doc(oldPlaylistKey)
    .collection('sessions')
    .orderBy('num', 'asc')
    .get();
  
  const rank = (await firestore()
    .collection("programs")
    .doc(newProgramKey)
    .collection("playlist")
    .get()).docs.length + 1

  const playlistToCopy = playlistDoc.data();

  if (playlistToCopy) {
    playlistToCopy.id = newPlaylistKey
    playlistToCopy.updated = new Date();
    playlistToCopy.rank = rank;
    playlistToCopy.firstSessionID = `${newPlaylistKey}_1`;
    playlistToCopy.sessionCount = sessionDocs.docs.length;
  }
  
  const batch = firestore().batch();

  batch.set(
    firestore()
    .collection("programs")
    .doc(newProgramKey)
    .collection("playlist")
    .doc(newPlaylistKey), 
    playlistToCopy);

  sessionDocs.docs.forEach((sessionDoc, index) => {
    const num = index + 1;
    const newId = `${newPlaylistKey}_${num}`;
    const newPreviousSessionID = sessionDocs.docs[index - 1] ? `${newPlaylistKey}_${num - 1}` : null;
    const newNextSessionID = sessionDocs.docs[index + 1] ? `${newPlaylistKey}_${num + 1}` : null;

    const session = sessionDoc.data();
    session.programKey = newProgramKey;
    batch.set(
      firestore()
      .collection("programs")
      .doc(newProgramKey)
      .collection("playlist")
      .doc(newPlaylistKey)
      .collection("sessions")
      .doc(newId),
      {...session, id: newId, previousSessionID: newPreviousSessionID, nextSessionID: newNextSessionID}
    );
  });



  await batch.commit();
}
