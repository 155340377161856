import React, { useState } from "react";
import "./playlists.css";
import { firestore } from "../../../firebase";
import { useCollection } from "react-firebase-hooks/firestore";
import { Button, Col, Row, Table } from "react-bootstrap";
import PlaylistDetail from "./PlaylistDetail";
import { PlaylistFieldModal } from "./PlaylistFieldModal";
import {
  Route,
  useHistory,
  useRouteMatch,
  useLocation,
} from "react-router-dom";
import useRole from "../../../UseRole";
import { PlaylistCloneModal } from "./PlaylistCloneModal";
import { PlaylistDocument } from "../../../Model/Playlist";

interface PlaylistProps {
  programKey: string;
}

type Document = firebase.firestore.QueryDocumentSnapshot<
  firebase.firestore.DocumentData
>;

enum PlaylistEditors {
  field,
  clone,
}

const PlaylistList = ({ programKey }: PlaylistProps) => {
  const isAdmin = useRole("admin");

  const query = firestore
    .collection("programs")
    .doc(programKey)
    .collection("playlist")
    .orderBy("rank", "asc");

  let history = useHistory();
  let match = useRouteMatch();

  let location = useLocation();

  const [playlists, playlistsLoading, playlistsError] = useCollection(query);
  const [selectedPlaylist, setSelectedPlaylist] = useState<string>(null);

  const [editorToShow, setEditorToShow] = useState<PlaylistEditors>();
  const [docToEdit, setDocToEdit] = useState<Document>();

  const getPlaylistDoc = (editingId: string) =>
    playlists?.docs.find((doc) => doc.id === editingId);

  const editPlaylist = (editorType: PlaylistEditors, editingId?: string) => {
    if (editingId) setDocToEdit(getPlaylistDoc(editingId));
    setEditorToShow(editorType);
  };

  const closeEditor = () => {
    setDocToEdit(undefined);
    setEditorToShow(undefined);
  };

  const getTableRow = (playlist: any) => {
    const data = playlist.data();
    return (
      <tr>
        <td>{data.name}</td>
        <td>{playlist.id}</td>
        <td>
          {isAdmin && (
            <Button
              variant="outline-primary"
              style={{ margin: 5 }}
              onClick={() => editPlaylist(PlaylistEditors.field, playlist.id)}
            >
              Edit Playlist
            </Button>
          )}

          <Button
            style={{ margin: 5 }}
            variant="outline-primary"
            onClick={() => {
              setSelectedPlaylist(data);
              history.push(match.url + "/" + data.id);
            }}
          >
            View Details
          </Button>

          {isAdmin && (
            <Button
              variant="outline-primary"
              style={{ margin: 5 }}
              onClick={() => editPlaylist(PlaylistEditors.clone, playlist.id)}
            >
              Clone
            </Button>
          )}
        </td>
      </tr>
    );
  };

  return (
    <>
      <Route
        path={match.url + "/:playlistKey"}
        render={() => (
          <PlaylistDetail
            playlist={selectedPlaylist}
            programKey={programKey}
          ></PlaylistDetail>
        )}
      ></Route>

      {match.isExact && (
        <>
          {editorToShow === PlaylistEditors.field && (
            <PlaylistFieldModal
              close={closeEditor}
              programKey={programKey}
              doc={docToEdit as PlaylistDocument}
            />
          )}
          {docToEdit && (
            <PlaylistCloneModal
              show={editorToShow === PlaylistEditors.clone}
              close={closeEditor}
              programKey={programKey}
              playlistDoc={docToEdit}
            />
          )}
          {!!playlists?.docs.length && (
            <>
              <Row>
                <Col>
                  <h4 className="table-title">Playlists</h4>
                </Col>
              </Row>
              {/* <Row className={"playlist-list"}> */}
                <Col xs={12}>
                  <Table
                    bordered
                    hover
                    responsive="xl"
                    className={"table-striped"}
                  >
                    <thead key="playlistsIndex">
                      <tr>
                        <th>Name</th>
                        <th>ID</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>{playlists.docs.map((u) => getTableRow(u))}</tbody>
                  </Table>
                </Col>
              {/* </Row> */}
            </>
          )}
          {isAdmin && (
            <Col>
              <Button
                variant="outline-primary"
                style={{ marginBottom: 10 }}
                onClick={() => editPlaylist(PlaylistEditors.field)}
              >
                Add New Playlist
              </Button>
            </Col>
          )}
        </>
      )}
    </>
  );
};

export { PlaylistList };
