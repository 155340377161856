import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Form, Col, Row, Container, Button, Modal, Table } from "react-bootstrap";
import PhoneInput, { isPossiblePhoneNumber, formatPhoneNumberIntl } from "react-phone-number-input/input";
import { useFormik } from "formik";
import * as yup from "yup";
import moment from "moment";
import { auth, firestore } from "../../firebase";
import { deactivatePrescription, reactivatePrescription, refillPrescription } from "../../utils/services";

const PrescriptionDetail = (props) => {
  const {id, prescription} = props;

  let history = useHistory();

  const [sites, setSites] = useState([]);

  useEffect(() => {
    (async () => {
      const sitesSnap = await firestore.collection(`medrio/sites/available`).get();
      const sites = [];
      for (const siteSnap of sitesSnap.docs) {
        const siteData = siteSnap.data();
        sites.push({
          label: siteData.siteName ?? siteSnap.id,
          value: siteSnap.id,
        })
      }

      setSites(sites);
    })()
  }, [])

  const states = ['AK', 'AL', 'AR', 'AS', 'AZ', 'CA', 'CO', 'CT', 'DC', 'DE', 'FL', 'GA', 'GU', 'HI', 'IA', 'ID', 'IL', 'IN', 'KS', 'KY', 'LA', 'MA', 'MD', 'ME', 'MI', 'MN', 'MO', 'MS', 'MT', 'NC', 'ND', 'NE', 'NH', 'NJ', 'NM', 'NV', 'NY', 'OH', 'OK', 'OR', 'PA', 'PR', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VA', 'VI', 'VT', 'WA', 'WI', 'WV', 'WY'];

  const today = new Date();
  today.setHours(0, 0, 0, 0);

  let initialValues = {
    prescriberName: prescription.prescriber.name,
    NPI: prescription.prescriber.NPI,
    prescriberEmail: prescription.prescriber.email,
    contactPreference: prescription.prescriber.contactPreference,
    physicianState: prescription.prescriber.state,
    patientFirstName: prescription.patient.firstName,
    patientLastName: prescription.patient.lastName,
    patientEmail: prescription.patient.email,
    patientDOB: prescription.patient.DOB,
    patientUserId: prescription?.patient?.userId || "",
    requestDate: moment(prescription.prescription.requestDate.toDate()).format("MM/DD/YYYY"),
    filedDate: moment(prescription.prescription.filedDate.toDate()).format("MM/DD/YYYY"),
    activationDate: prescription?.prescription?.activationDate ? moment(prescription?.prescription?.activationDate.toDate()).format("MM/DD/YYYY") : "",
    length: prescription.prescription.length,
    indication: prescription.prescription.indication,
    discountCode: prescription.prescription.discountCode,
    notes: prescription.prescription.notes,
    isTest: prescription.isTest,
    site: prescription.site,
    healthieId: prescription?.healthieId || ""
  };

  let validationSchema = yup.object().shape({
    prescriberName: yup.string().required("Name is required"),
    NPI: yup.string().required("NPI is required").matches(/^\d{10}$/, "NPI must have exactly 10 digits"),
    prescriberEmail: yup.string().required("Email is required").email("Invalid email format").test(
      "equal",
      "Prescriber and patient email should not match",
      function(value) {
        const ref = yup.ref("patientEmail");
        return value !== this.resolve(ref);
      }
    ),
    patientFirstName: yup.string().required("First name is required"),
    patientLastName: yup.string().required("Last name is required"),
    patientEmail: yup.string().required("Email is required").email("Invalid email format").test(
      "equal",
      "Prescriber and patient email should not match",
      function(value) {
        const ref = yup.ref("prescriberEmail");
        return value !== this.resolve(ref);
      }
    ),
    patientDOB: yup.date().required("Date of birth is required").transform((value) => new Date(value)).max(today, "Please input a valid date"),
    requestDate: yup.date().required("Request date is required").transform((value) => new Date(value)).max(today, "Please input a valid date"),
    filedDate: yup.date().required("Filed date is required").transform((value) => new Date(value)).max(today, "Please input a valid date"),
    activationDate: yup.date().transform((value) => new Date(value)).max(today, "Please input a valid date"),
    discountCode: yup.string().uppercase().matches(/^SWM(\w{3})$/, "Code must have exactly 6 alphanumeric characters"),
    notes: yup.string().max(512, "Notes must have a maximum limit of 512 characters"),
    isTest: yup.boolean(),
    healthieId: yup.string().when("site", {
      is: "swing care",
      then: yup.string().required("Healthie Id is required for Swing Care").matches(/^\d{7}$/, "Healthie Id must have exactly 7 digits")
    })
  });

  const [prescriberPhoneNumber, setPrescriberPhoneNumber] = useState(prescription.prescriber.phone);
  const [patientPhoneNumber, setPatientPhoneNumber] = useState(prescription.patient.phone);
  const [prescriberPhoneNumberTouched, setPrescriberPhoneNumberTouched] = useState(false);
  const [patientPhoneNumberTouched, setPatientPhoneNumberTouched] = useState(false);

  const phoneInputError = (value) => {
    return value ? (isPossiblePhoneNumber(value) ? undefined : "Invalid phone number format") : "Phone number is required"
  }

  const phoneSameError = (prescriberPhoneNumber && patientPhoneNumber && prescriberPhoneNumber === patientPhoneNumber) ? "Prescriber and patient phone number should not match": undefined;

  const [showModal, setShowModal] = useState(false);

  const {values, errors, touched, handleChange, handleSubmit } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      if (phoneInputError(prescriberPhoneNumber) || phoneInputError(patientPhoneNumber)) {
        if (phoneInputError(prescriberPhoneNumber)) {
          setPrescriberPhoneNumberTouched(true);
        }

        if (phoneInputError(patientPhoneNumber)) {
          setPatientPhoneNumberTouched(true);
        }
        return;
      }

      if (phoneSameError) {
        return;
      }

      let prescriber = {
        name: values.prescriberName.trim(),
        NPI: values.NPI,
        phone: formatPhoneNumberIntl(prescriberPhoneNumber),
        email: values.prescriberEmail.trim(),
        contactPreference: values.contactPreference,
        state: values.physicianState,
      };

      let patient = {
        firstName: values.patientFirstName.trim().toLowerCase(),
        lastName: values.patientLastName.trim().toLowerCase(),
        name: values.patientFirstName.trim() + " " + values.patientLastName.trim(),
        phone: formatPhoneNumberIntl(patientPhoneNumber),
        email: values.patientEmail.trim(),
        DOB: moment(values.patientDOB).format("MM/DD/YYYY"),
        userId: values.patientUserId,
      };

      let _prescription = {
        requestDate: new Date(moment(values.requestDate).format("MM/DD/YYYY")),
        filedDate: new Date(moment(values.filedDate).format("MM/DD/YYYY")),
        activationDate: values.activationDate ? new Date(moment(values.activationDate).format("MM/DD/YYYY")) : null,
        length: +values.length,
        indication: values.indication,
        discountCode: values.discountCode.substring(0,3).toUpperCase() + values.discountCode.substring(3),
        notes: values.notes.trim(),
      };

      let prescriptionForm: any = {
        prescriber: prescriber,
        patient: patient,
        prescription: _prescription,
        isTest: values.isTest,
        site: values.site,
        prescriptionId: id
      };

      let healthieIdError = false;

      if (values.healthieId) {
        const existingPrescriptions = await firestore.collection("prescriptions").where("healthieId", "==", values.healthieId).get();

        if (!existingPrescriptions.empty) {
          existingPrescriptions.docs.map((doc) => {
            const prescriptionData = doc.data();
            if (prescriptionData.prescriptionId !== id && !(prescriptionData?.status === 'inactive' || prescriptionData?.status === 'expired')) {
              setShowhealthieErrorModal(true);
              healthieIdError = true;
            }
          })
        }

        prescriptionForm.healthieId = values.healthieId;
      }

      if (healthieIdError) {
        return;
      }

      await firestore.collection("prescriptions").doc(id).update(prescriptionForm);
      
      setShowModal(true);
      setPrescriberPhoneNumberTouched(false); 
      setPatientPhoneNumberTouched(false);
      
    },
  });

  const [showDeactivatingModal, setShowDeactivatingModal] = useState(false);
  const [showReactivatingModal, setShowReactivatingModal] = useState(false);

  const [showRefillModal, setshowRefillModal] = useState(false);

  const [refillLength, setRefillLength] = useState(90);

  const [showhealthieErrorModal, setShowhealthieErrorModal] = useState(false);

  return (
    <>
      <Modal centered show={showModal} onHide={() => setShowModal(false)} animation={false}>
        <Modal.Header style={{ display: "flex", justifyContent: "center" }}>
          <Modal.Title>Form is saved successfully</Modal.Title>
        </Modal.Header>
        <Modal.Footer style={{ display: "flex", justifyContent: "center" }}>
          <Button variant="primary" onClick={() => setShowModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal centered show={showDeactivatingModal} onHide={() => setShowDeactivatingModal(false)} animation={false}>
        <Modal.Header style={{ display: "flex", justifyContent: "center" }}>
          <Modal.Title>Do you want to deactivate this prescription?</Modal.Title>
        </Modal.Header>
        <Modal.Footer style={{ display: "flex", justifyContent: "center" }}>
          <Button variant="primary" onClick={async () => {
            await deactivatePrescription({userId: prescription?.patient?.userId || "", updatedBy: auth.currentUser?.email || ""});
            setShowDeactivatingModal(false);
          }}>
            Confirm
          </Button>
          <Button variant="secondary" onClick={() => setShowDeactivatingModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal centered show={showReactivatingModal} onHide={() => setShowReactivatingModal(false)} animation={false}>
        <Modal.Header style={{ display: "flex", justifyContent: "center" }}>
          <Modal.Title>Do you want to reactivate this prescription?</Modal.Title>
        </Modal.Header>
        <Modal.Footer style={{ display: "flex", justifyContent: "center" }}>
          <Button variant="primary" onClick={async () => {
            await reactivatePrescription({userId: prescription?.patient?.userId || "", updatedBy: auth.currentUser?.email || ""});
            setShowReactivatingModal(false);
          }}>
            Confirm
          </Button>
          <Button variant="secondary" onClick={() => setShowReactivatingModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal centered show={showRefillModal} animation={false}>
        <Modal.Header style={{ display: "flex", justifyContent: "center" }}>
          <Modal.Title>Add Refill</Modal.Title>
        </Modal.Header>

        <Modal.Body style={{ display: "flex", justifyContent: "center" }}>
          <Form.Group as={Col} xs={12} md={12} controlId="length">
            <Form.Label>Prescription Refill Length</Form.Label>
            <Form.Control 
              as="select"
              name="length"
              value={refillLength}
              onChange={event => setRefillLength(+event.target.value)}
            >
              <option value={90}>90 days</option>
              <option value={180}>180 days</option>
              <option value={365}>365 days</option>
            </Form.Control>
          </Form.Group>
        </Modal.Body>

        <Modal.Footer style={{display: "flex", justifyContent: "center"}}>
          <Button variant="primary" onClick={() => {
            refillPrescription({userId: prescription?.patient?.userId || "", updatedBy: auth.currentUser?.email || "", refillLength: refillLength});
            setshowRefillModal(false);
          }}>
            Confirm
          </Button>
          <Button variant="secondary" onClick={() => {setshowRefillModal(false);}}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal centered show={showhealthieErrorModal} onHide={() => setShowhealthieErrorModal(false)} animation={false}>
        <Modal.Header style={{ display: "flex", justifyContent: "center" }}>
          <Modal.Title>Sorry, the healthie ID you entered is already associated with a prescription, please use a different one.</Modal.Title>
        </Modal.Header>
        <Modal.Footer style={{ display: "flex", justifyContent: "center" }}>
          <Button variant="primary" onClick={() => setShowhealthieErrorModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <h1 style={{textAlign: "center"}}>Edit Prescription</h1>

      <Form onSubmit={handleSubmit}>
        <Container>
          {prescription && (prescription?.prescription?.activationDate && (prescription?.status === 'active' || prescription?.status === 'refill')) &&
            <Row>
              <Col style={{textAlign: "center"}}>
                <Button variant="primary" className="m-2" onClick={() => setShowDeactivatingModal(true)}>
                  Deactivate
                </Button>
              </Col>
            </Row>
          }

          {prescription && prescription?.prescription?.activationDate && (prescription?.status === 'inactive' || prescription?.status === 'expired') &&
            <Row>
              <Col style={{textAlign: "center"}}>
                <Button variant="primary" className="m-2" onClick={() => setShowReactivatingModal(true)}>
                  Reactivate
                </Button>
              </Col>
            </Row>
          }

          {prescription && prescription?.prescription?.activationDate &&
            <Row>
              <Col style={{textAlign: "center"}}>
                <Button variant="primary" className="m-2" onClick={() => setshowRefillModal(true)}>
                  Refill
                </Button>
              </Col>
            </Row>
          }

          <Row>
            <Form.Group as={Col} xs={12} md={6} controlId="isTest">
              <Form.Check
                label={"Test Prescription"}
                name={"isTest"}
                checked={values.isTest}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group as={Col} xs={12} md={6} controlId="site">
              <Form.Label>Site: {prescription.site}</Form.Label>
              <Form.Control 
                as="select"
                name="site" 
                value={values.site}
                onChange={handleChange}
              >
                {sites.map((site) => (
                  <option value={site.value} key={site.value}>{site.label}</option>
                ))}
              </Form.Control>
            </Form.Group>
          </Row>

          <Row>
            <Form.Group as={Col} xs={12} md={6} controlId="prescriberName">
              <Form.Label>Prescriber Name: {prescription.prescriber.name}</Form.Label>
              <Form.Control
                type="text"
                name="prescriberName"
                placeholder="Name"
                value={values.prescriberName}
                onChange={handleChange}
                isInvalid={!!errors.prescriberName && !!touched.prescriberName}
              />
              <Form.Control.Feedback type="invalid">
                {errors.prescriberName}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} xs={12} md={6} controlId="NPI">
              <Form.Label>NPI: {prescription.prescriber.NPI}</Form.Label>
              <Form.Control
                type="text"
                name="NPI"
                placeholder="NPI"
                value={values.NPI}
                onChange={handleChange}
                isInvalid={!!errors.NPI && !!touched.NPI}
              />
              <Form.Control.Feedback type="invalid">
                {errors.NPI}
              </Form.Control.Feedback>
            </Form.Group>
          </Row>

          <Row>
            <Form.Group as={Col} xs={12} md={6} controlId="prescriberPhoneNumber">
              <Form.Label>Prescriber Phone Number: {prescription.prescriber.phone}</Form.Label>
              <br></br>
              <PhoneInput
                country="US"
                name="prescriberPhoneNumber"
                placeholder="(XXX) XXX-XXXX"
                value={prescriberPhoneNumber}
                onChange={setPrescriberPhoneNumber}
                onBlur={() => setPrescriberPhoneNumberTouched(true)}
              />
              <br></br>
              <div className='invalid-feedback d-block'>{prescriberPhoneNumberTouched && phoneInputError(prescriberPhoneNumber) || phoneSameError}</div>
            </Form.Group>

            <Form.Group as={Col} xs={12} md={6} controlId="prescriberEmail">
              <Form.Label>Prescriber Email: {prescription.prescriber.email}</Form.Label>
              <Form.Control
                type="email"
                name="prescriberEmail"
                placeholder="example@domain.com"
                value={values.prescriberEmail}
                onChange={handleChange}
                isInvalid={!!errors.prescriberEmail && !!touched.prescriberEmail}
              />
              <Form.Control.Feedback type="invalid">
                {errors.prescriberEmail}
              </Form.Control.Feedback>
            </Form.Group>
          </Row>

          <Row>
            <Form.Group as={Col} xs={12} md={6} controlId="contactPreference">
              <Form.Label>Contact Preference: {prescription.prescriber.contactPreference}</Form.Label>
              <Form.Control 
                as="select"
                name="contactPreference"
                value={values.contactPreference}
                onChange={handleChange}
              >
                <option value={"phone"}>Phone</option>
                <option value={"email"}>Email</option>
              </Form.Control>
            </Form.Group>

            <Form.Group as={Col} xs={12} md={6} controlId="physicianState">
              <Form.Label>Physician's State of Practice: {prescription.prescriber.state}</Form.Label>
              <Form.Control 
                as="select"
                name="physicianState" 
                value={values.physicianState}
                onChange={handleChange}
              >
                {states.map(state => <option value={state} key={state}>{state}</option> )}
              </Form.Control>
            </Form.Group>
          </Row>

          <Row>
            <Form.Group as={Col} xs={12} md={6} controlId="patientFirstName">
              <Form.Label>Patient First Name: {prescription.patient.firstName}</Form.Label>
              <Form.Control
                type="text"
                name="patientFirstName"
                placeholder="First Name"
                value={values.patientFirstName}
                onChange={handleChange}
                isInvalid={!!errors.patientFirstName && !!touched.patientFirstName}
              />
              <Form.Control.Feedback type="invalid">
                {errors.patientFirstName}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} xs={12} md={6} controlId="patientLastName">
              <Form.Label>Patient Last Name: {prescription.patient.lastName}</Form.Label>
              <Form.Control
                type="text"
                name="patientLastName"
                placeholder="Last Name"
                value={values.patientLastName}
                onChange={handleChange}
                isInvalid={!!errors.patientLastName && !!touched.patientLastName}
              />
              <Form.Control.Feedback type="invalid">
                {errors.patientLastName}
              </Form.Control.Feedback>
            </Form.Group>
          </Row>

          <Row>
            <Form.Group as={Col} xs={12} md={6} controlId="patientPhoneNumber">
              <Form.Label>Patient Phone Number: {prescription.patient.phone}</Form.Label>
              <br></br>
              <PhoneInput
                country="US"
                name="patientPhoneNumber"
                placeholder="(XXX) XXX-XXXX"
                value={patientPhoneNumber}
                onChange={setPatientPhoneNumber}
                onBlur={() => setPatientPhoneNumberTouched(true)}
              />
              <br></br>
              <div className='invalid-feedback d-block'>{patientPhoneNumberTouched && phoneInputError(patientPhoneNumber) || phoneSameError}</div>
            </Form.Group>

            <Form.Group as={Col} xs={12} md={6} controlId="patientEmail">
              <Form.Label>Patient Email: {prescription.patient.email}</Form.Label>
              <Form.Control
                type="email"
                name="patientEmail"
                placeholder="example@domain.com"
                value={values.patientEmail}
                onChange={handleChange}
                isInvalid={!!errors.patientEmail && !!touched.patientEmail}
              />
              <Form.Control.Feedback type="invalid">
                {errors.patientEmail}
              </Form.Control.Feedback>
            </Form.Group>
          </Row>

          <Row>
            <Form.Group as={Col} xs={12} md={6} controlId="patientDOB">
              <Form.Label>Patient Date of Birth: {prescription.patient.DOB}</Form.Label>
              <Form.Control
                type="date"
                name="patientDOB"
                placeholder="MM/DD/YYYY"
                value={values.patientDOB}
                onChange={handleChange}
                isInvalid={!!errors.patientDOB && !!touched.patientDOB}
              />
              <Form.Control.Feedback type="invalid">
                {errors.patientDOB}
              </Form.Control.Feedback>
            </Form.Group>

            {values.site === "swing care" &&
              <Form.Group as={Col} xs={12} md={6} controlId="NPI">
                <Form.Label>Healthie Id</Form.Label>
                <Form.Control
                  type="text"
                  name="healthieId"
                  value={values.healthieId}
                  onChange={handleChange}
                  isInvalid={!!errors.healthieId && !!touched.healthieId}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.healthieId}
                </Form.Control.Feedback>
              </Form.Group>
            }
          </Row>

          <Row>
            <Form.Group as={Col} xs={12} md={6} controlId="requestDate">
              <Form.Label>Prescription Request Date: {moment(prescription.prescription.requestDate.toDate()).format("MM/DD/YYYY")}</Form.Label>
              <Form.Control
                type="date"
                name="requestDate"
                placeholder="MM/DD/YYYY"
                value={values.requestDate}
                onChange={handleChange}
                isInvalid={!!errors.requestDate && !!touched.requestDate}
              />
              <Form.Control.Feedback type="invalid">
                {errors.requestDate}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} xs={12} md={6} controlId="filedDate">
              <Form.Label>Prescription Filed Date: {moment(prescription.prescription.filedDate.toDate()).format("MM/DD/YYYY")}</Form.Label>
              <Form.Control
                type="date"
                name="filedDate"
                placeholder="MM/DD/YYYY"
                value={values.filedDate}
                onChange={handleChange}
                isInvalid={!!errors.filedDate && !!touched.filedDate}
              />
              <Form.Control.Feedback type="invalid">
                {errors.filedDate}
              </Form.Control.Feedback>
            </Form.Group>
          </Row>

          <Row>
            <Form.Group as={Col} xs={12} md={6} controlId="activationDate">
              <Form.Label>Prescription Activation Date: {prescription?.prescription?.activationDate && moment(prescription?.prescription?.activationDate.toDate()).format("MM/DD/YYYY")}</Form.Label>
              <Form.Control
                type="date"
                name="activationDate"
                placeholder="MM/DD/YYYY"
                value={values.activationDate}
                onChange={handleChange}
                isInvalid={!!errors.activationDate && !!touched.activationDate}
              />
              <Form.Control.Feedback type="invalid">
                {errors.activationDate}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} xs={12} md={6} controlId="length">
              <Form.Label>Prescription Length: {prescription.prescription.length} days</Form.Label>
              <Form.Control 
                as="select"
                name="length"
                value={values.length}
                onChange={handleChange}
              >
                <option value={90}>90 days</option>
                <option value={180}>180 days</option>
                <option value={365}>365 days</option>
              </Form.Control>
            </Form.Group>
          </Row>

          <Row>
            <Form.Group as={Col} xs={12} md={6} controlId="indication">
              <Form.Label>Indication: {prescription.prescription.indication}</Form.Label>
              <Form.Control 
                as="select"
                name="indication" 
                value={values.indication}
                onChange={handleChange}
              >
                <option value={"Fibromyalgia"}>Fibromyalgia</option>
              </Form.Control>
            </Form.Group>

            <Form.Group as={Col} xs={12} md={6} controlId="discountCode">
              <Form.Label>Discount Code: {prescription.prescription.discountCode}</Form.Label>
              <Form.Control
                type="text"
                name="discountCode"
                placeholder="SWMXXX"
                value={values.discountCode}
                onChange={handleChange}
                isInvalid={!!errors.discountCode && !!touched.discountCode}
              />
              <Form.Control.Feedback type="invalid">
                {errors.discountCode}
              </Form.Control.Feedback>
            </Form.Group>
          </Row>

          <Row>
            <Form.Group as={Col} xs={12} controlId="notes">
              <Form.Label>Notes: {prescription.prescription.notes}</Form.Label>
              <Form.Control
                as="textarea"
                name="notes"
                placeholder="Notes or special instructions"
                value={values.notes}
                onChange={handleChange}
                isInvalid={!!errors.notes && !!touched.notes}
              />
              <Form.Control.Feedback type="invalid">
                {errors.notes}
              </Form.Control.Feedback>
            </Form.Group>
          </Row>

          {prescription?.refills &&
            <Row>
              <Col xs={12}>
                <Table>
                  <thead key="refills">
                    <tr>
                      <th>Refill Start Date</th>
                      <th>Refill Length</th>
                      <th>Refill Expire Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {[...prescription?.refills].reverse().map((refill, i) => 
                      <tr key={i} className={i === 0 ? "table-active": ""}>
                        <td>{refill.startDate && moment(refill.startDate.toDate().toISOString().slice(0,10)).format("MM/DD/YYYY")}</td>
                        <td>{refill.length} days</td>
                        <td>{refill.expireDate && moment(refill.expireDate.toDate().toISOString().slice(0,10)).format("MM/DD/YYYY")}</td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </Col>
            </Row>
          }
          
          <Row>
            <Col style={{display: "flex", justifyContent: "center"}}>
              <Button
                variant="primary"
                type="submit"
                style={{ margin: 5 }}
                onClick={() => {setPrescriberPhoneNumberTouched(true); setPatientPhoneNumberTouched(true);}}
              >
                Submit
              </Button>
              <Button
                variant="secondary"
                type="submit"
                style={{ margin: 5 }}
                onClick={() => {history.goBack()}}
              >
                Cancel
              </Button>
            </Col>
          </Row>
        </Container>
      </Form>
    </>
  )
}

export default PrescriptionDetail;