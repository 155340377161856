import React from "react";
import { ValuesCategory, ValuesCategoryJson } from "@swing-therapeutics/swingcore/dist/models/values/ValuesCategory";
import { Button, Col, Row } from "react-bootstrap";
import { BasicNumberField, BasicTextAreaField, BasicTextField } from "../../../utils/FormikHelpers";
import { useFormik } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { cloneDeep } from "lodash";
import { valuesCategoryValidator } from "./valuesCategoryValidators";

interface ValuesCategoryFormProps {
  programKey: string;
  order?: number;
  valuesCategory?: ValuesCategory;
  clearSelectedValuesCategory?: Function;
}
export const ValuesCategoryForm = ({
  programKey,
  order,
  valuesCategory,
  clearSelectedValuesCategory,
}: ValuesCategoryFormProps) => {
  const history = useHistory();
  const isNew = !valuesCategory;

  const formik = useFormik({
    initialValues: (valuesCategory?.data() || {
      id: undefined,
      name: undefined,
      description: undefined,
      entryValidation: {
        minValues: 1,
        maxValues: 5,
        minChars: 1,
        maxChars: 150,
      }
    }) as ValuesCategoryJson,
    enableReinitialize: true,
    onSubmit: async (_values) => {
      const values = cloneDeep(_values);
      let _valuesCategory: ValuesCategory;

      if (isNew) {
        if (!order) {
          throw new Error('order not provided for ValuesCategory');
        }
        _valuesCategory = new ValuesCategory(values.id, values.name, programKey, values.description, order, values.entryValidation, new Date());
      } else {
        _valuesCategory = valuesCategory;
        Object.assign(_valuesCategory, values);
      }

      try {
        await _valuesCategory.persist();
        window.alert('ValuesCategory saved successfully!');

        if (isNew) {
          history.goBack();
        }
      } catch (error) {
        console.error(error);
        window.alert('An error occurred when trying to save the ValuesCategory. Check the developer console for details.');
      }
    },
    validationSchema: valuesCategoryValidator,
  });

  return (
    <>
      <Row>
        <Col xs="12">
          <Link to={`/programs/${programKey}/values`} onClick={() => clearSelectedValuesCategory?.()}>
            <FontAwesomeIcon icon={faArrowLeft} /> Go Back
          </Link>
        </Col>
      </Row>
      <Row>
        <BasicTextField
          formikHelpers={formik}
          title="ID"
          path="id"
          tooltip="Unique and human readable ID for this values category"
          disabled={!isNew}
        />
        <BasicTextField formikHelpers={formik} title="Name" path="name" />
        <BasicTextAreaField
          rows={4}
          formikHelpers={formik}
          title="Description"
          path="description"
        />
        <BasicNumberField
          formikHelpers={formik}
          title="Minimum Values"
          path="entryValidation.minValues"
          tooltip="How many values a user can add"
        />
        <BasicNumberField
          formikHelpers={formik}
          title="Maximum Values"
          path="entryValidation.maxValues"
          tooltip="How many values a user can add"
        />
        <BasicNumberField
          formikHelpers={formik}
          title="Minimum Characters"
          path="entryValidation.minChars"
          tooltip="How long a values entry can be"
        />
        <BasicNumberField
          formikHelpers={formik}
          title="Maximum Characters"
          path="entryValidation.maxChars"
          tooltip="How long a values entry can be"
        />
      </Row>
      <Row>
        <Col xs="12">
          <Button
            data-testid="valuesCategoryFormSubmit"
            variant={formik.dirty ? "primary" : "secondary"}
            disabled={formik.isSubmitting || !formik.dirty}
            onClick={() => {
              formik.submitForm();
            }}
          >
            Save
          </Button>
        </Col>
      </Row>
    </>
  );
};
