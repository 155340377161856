import React from "react";
// import GraphTemplate from "../Graphs/GraphTemplate";
import GraphTemplateEPROsA from "../../Graphs/GraphTemplateEPROsA";
import AnotherReportsListSidebar from "./AnotherReportsListSidebar";

import AdminNavbar from "../../AdminNavbar";
import { Col, Container, Row,Jumbotron } from "react-bootstrap";

const A004FFNRgraphs = () => {
  return (
    <>
      <AdminNavbar />
      <Jumbotron>
      <Container fluid={true} style={{paddingBottom: "30px", paddingRight: "40px"}}>
                <Row>
                    <Col>
                    <h1>Five Facets of Mindfulness, Non-Reactive Section</h1>
          <div className="TitleCb">7 questions, 1-5 pts each; max = 35;  goal: increase</div>
                    </Col>
                </Row>
            </Container>
        </Jumbotron>


      <AnotherReportsListSidebar/>
      <Container fluid={true} style={{paddingLeft: "40px"}}>
        <div className="mt-4">
        <div className="article" style={{paddingLeft: "40px"}}>
          {/* <h4>Five Facets of Mindfulness, Non-Reactive Section</h4>
          <div className="TitleCb">7 questions; 1-5 pts each; max = 35;  goal increase</div> */}
          <Row>
            <Col xs={10}>
              <GraphTemplateEPROsA report={"ACT_fivefacetsNR004A"} />
            </Col>
          </Row>
          <Row>
            <Col xs={10}>
              <GraphTemplateEPROsA report={"ST_fivefacetsNR004A"} />
            </Col>
          </Row>
          </div>
        </div>
      </Container>
    </>
  );
};

export default A004FFNRgraphs;
{/* <AdminNavbar/>
<Jumbotron>
    <Container fluid={true}>
        <Row>
            <Col>
                <h1>004 Participants</h1>
            </Col>
        </Row>
    </Container>
</Jumbotron>
<AnotherReportsListSidebar/> */}