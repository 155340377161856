import * as Yup from "yup";

const requiredMessage = (name: string) => `${name} is required`;
const rangeMessage = (name: string, min: number, max: number) =>
  `${name} must be between ${min}-${max}`;

export const valuesCategoryValidator = Yup.object().shape({
  id: Yup.string().required(requiredMessage("id")),
  name: Yup.string().required(requiredMessage("name")),
  description: Yup.string().optional(),
  entryValidation: Yup.object().shape({
    minValues: Yup.number()
      .required(requiredMessage("minimum values"))
      .min(1, rangeMessage("min", 1, 5))
      .max(5, rangeMessage("min", 1, 5)),
    maxValues: Yup.number()
      .required(requiredMessage("maximum values"))
      .min(1, rangeMessage("max", 1, 5))
      .max(5, rangeMessage("max", 1, 5)),
    minChars: Yup.number()
      .required(requiredMessage("minimum characters"))
      .min(0, rangeMessage("minChars", 1, 150))
      .max(150, rangeMessage("minChars", 1, 150)),
    maxChars: Yup.number()
      .required(requiredMessage("maximum characters"))
      .min(0, rangeMessage("maxChars", 1, 150))
      .max(150, rangeMessage("maxChars", 1, 150)),
  }),
});
