import React, { useEffect, useState } from "react";
import {
  Route,
  RouteComponentProps,
} from "react-router-dom";
import { firestore } from "../../firebase";
import Medrio from "../../Model/Medrio";
import AdminNavbar from "../AdminNavbar";
import { Col, Container, Row, Nav } from "react-bootstrap";
import {
  faClinicMedical,
  faRegistered,
  faTable,
} from "@fortawesome/free-solid-svg-icons";
import NavLinker from "../NavLinker";
import MedrioOverview from "./Sections/MedrioOverview";
import MedrioTriggers from "./Sections/MedrioTriggers";
import MedrioRegistration from "./Sections/MedrioRegistration";

interface PageParams {
  uid: string;
}

const MedrioDetail: React.FC<RouteComponentProps<PageParams>> = ({
  match,
}) => {
  const [medrio, setMedrio] = useState<Medrio>();

  useEffect(() => {
    const subscriber = firestore
      .collection("medrio")
      .doc('events')
      .collection('playback')
      .where("FormEventData.SubjectIdentifier", "==", match.params.uid)
      .limit(1)
      .onSnapshot((snapshot) => {
        const u = Medrio.fromFirestore(snapshot.docs[0]);
        setMedrio(u);
      });

    return subscriber;
  }, [match.params.uid]);

  return (
    <>
      <AdminNavbar />
      <div className={"album bg-light"}>
        <Container fluid={true} style={{ backgroundColor: "white" }}>
          <Row>
            <Col xs={2} className={"sidebar"}>
              <div className={"sidebar-sticky"}>
                <Nav className={"flex-column "}>
                  <NavLinker
                    baseUrl={match.url}
                    route=""
                    label="Overview"
                    icon={faClinicMedical}
                  />
                  <NavLinker
                    baseUrl={match.url}
                    route="/registrationDetail"
                    label="Registration Detail"
                    icon={faRegistered}
                  />
                  <NavLinker
                    baseUrl={match.url}
                    route="/triggers"
                    label="Triggers"
                    icon={faTable}
                  />
                </Nav>
              </div>
            </Col>
            <Col xs={"10"} className={"main-content dashboard-container"}>
              <Row className={"dashboard-content"}>
                <Col>
                  <Row className="dashboard-heading">
                    <Col>
                      <Row>
                        <h1>
                          <small>{medrio?.FormEventData.SubjectIdentifier} (Retries: {medrio?.retries})</small>
                        </h1>
                      </Row>
                      <Row>
                        <h4>
                          <small>{medrio?.FormEventData.SubjectStatus}</small>
                        </h4>
                      </Row>
                    </Col>
                  </Row>
                  {medrio &&
                    <>
                      <Route
                        path={match.url}
                        exact
                        render={() =>
                          <MedrioOverview medrio={medrio} />
                        }
                      />
                      <Route
                        path={match.url + "/triggers"}
                        render={() =>
                          <MedrioTriggers subjectID={medrio?.FormEventData.SubjectIdentifier} />
                        }
                      />
                      <Route
                        path={match.url + "/registrationDetail"}
                        render={() =>
                          <MedrioRegistration subjectID={medrio?.FormEventData.SubjectIdentifier} />
                        }
                      />
                    </>
                  }
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default MedrioDetail;
