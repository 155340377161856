import { useFormik } from "formik";
import { Button, Col, Form, Spinner } from "react-bootstrap";
import React from "react";
import { SystemEvent } from "../../../../Model/SystemEvent";
import { BasicEasyEditField, BasicTextField } from "@swing-therapeutics/surveybay/dist/Forms/FormikHelpers";
import { StepEditorProps } from "./StepEditor";
import * as yup from 'yup';


const PromptStepEditor = ({step, lesson, goBack}: StepEditorProps) => {
  const formik = useFormik({
    initialValues: step,
    validationSchema: yup.object().shape({
      title: yup.string().nullable(),
      placeholder: yup.string().nullable(),
      prompt: yup.string()
        .required('Prompt is required')
        .test('empty markdown test', 'Prompt is required', value => value !== "​\n"),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      step.title = values.title ?? "";
      step.placeholder = values.placeholder ?? "";
      step.prompt = values.prompt ?? "";

      try {
        await lesson.persist();
        window.alert('Step saved successfully!');
        SystemEvent.fireEvent(
          "Content Update",
          "Prompt step update",
          "Lesson " + lesson.id + " prompt step edited",
          new Date().toISOString(),
          { lesson: JSON.stringify(lesson), step }
        );
        setSubmitting(false);
        goBack();
      } catch (error) {
        window.alert('Error during saving. Refer to console for details')
      }
    },
  });

  return (
    <Col>
      <Form onSubmit={formik.handleSubmit}>
        <br></br>

        <Form.Row>
          <BasicTextField title="Title" path="title" formikHelpers={formik} />
          <BasicTextField title="UUID" path="uuid" formikHelpers={formik} disabled/>
          <BasicEasyEditField title="Prompt" path="prompt" markupType="markdown" formikHelpers={formik} lg={12}/>
          <BasicTextField title="Placeholder" path="placeholder" placeholder="Enter a placeholder" formikHelpers={formik}/>
        </Form.Row>

        <Button
          disabled={formik.isSubmitting}
          variant="primary"
          type="submit"
          onClick={() => {
            formik.handleSubmit();
          }}
        >
          {formik.isSubmitting ? (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          ) : (
            <>Submit</>
          )}
        </Button>
      </Form>
    </Col>
  );
};

export default PromptStepEditor;
