import React, { useState } from "react";
import {
  Button,
  Dropdown,
  DropdownButton,
  Modal,
} from "react-bootstrap";
import { Lesson } from "@swing-therapeutics/swingcore/dist/models/content/Lesson";
import { Chapter } from "@swing-therapeutics/swingcore/dist/models/content/Chapter";
import { Program } from "../Program";

interface LessonAndChapterCopyModalProps {
  contents: Lesson[] | Chapter[];
  programs: Program[];
  selectProgram: (choice: Program) => void;
}

export const LessonAndChapterCopyModal = ({ contents, programs, selectProgram, close }) => {
  const [selectedProgram, setSelectedProgram] = useState<Program>(
    undefined
  );

  const contentList = (contents
    .filter((content: Lesson | Chapter) => content?.title)
    .map((content: Lesson | Chapter) => {
      if ((content as Lesson)?.chNum) {
        return `${(content as Lesson).chNum}.${content.num}`;
      }
      return `${content.num}`
    })
  ).join(', ');

  const dropdownTitle =
    selectedProgram === undefined
      ? "Select a program..."
      : selectedProgram.name;

  const title = (contents[0] as Lesson)?.chNum ?
    'Select Program to Copy Lessons to' :
    'Select Program to Copy Chapters to';

  return (
    <Modal show onHide={close}>
      <Modal.Header>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4>Copying lessons</h4>
        <div>
          {contentList}
        </div>
        <h4 style={{marginBottom: 10, marginTop: 30}}>To Program</h4>
        <DropdownButton title={dropdownTitle}>
          {programs && programs.map((program: Program) => (
            <Dropdown.Item as="button" onClick={() => setSelectedProgram(program)}>
              {program.name}
            </Dropdown.Item>
          ))}
        </DropdownButton>
      </Modal.Body>
      <Modal.Footer>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <Button style={{ marginRight: 30 }} onClick={() => close()}>
            cancel
          </Button>
          <Button onClick={() => {
              selectProgram(selectedProgram);
              close();
            }}>
            Copy
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};
