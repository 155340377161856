import Journal from "@swing-therapeutics/swingcore/dist/models/journals/Journal";
import { PacingJournal } from "@swing-therapeutics/swingcore/dist/models/journals/PacingJournal";
import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { firestore } from "../../../../firebase";
import useRole from "../../../../UseRole";
import LoadingScreen from "../../../LoadingScreen";
import { UserJournalRow } from "./UserJournalRow";
import { UserSectionProps } from "./UserSectionInterface";
import "./userTable.css";

const UserJournalsTable: React.FC<UserSectionProps> = ({ user, programID }) => {
  const isAdmin = useRole("admin");
  const [journals, setJournals] = useState<(Journal | PacingJournal)[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();

  const query = firestore
    .collection("users")
    .doc(user.uid)
    .collection("programs")
    .doc(programID)
    .collection("journalEntries")
    .orderBy("dateCreated", "desc");

  useEffect(() => {
    let unsubscribe = () => {};
    try {
      unsubscribe = query.onSnapshot((result) => {
        setLoading(true);
        if (result?.docs?.length > 0) {
          setJournals(
            result.docs.map((doc) => {
              if (PacingJournal.isPacingJournalID(doc.data().journalTypeID)) {
                return PacingJournal.fromFirestore(
                  doc,
                  user.activeProgram,
                  {} as any
                );
              } else {
                return Journal.fromFirestore(
                  doc,
                  user.activeProgram,
                  {} as any
                );
              }
            })
          );
          setLoading(false);
        }
      });
    } catch (error) {
      setError(error);
    }

    return unsubscribe;
  }, []);

  return (
    <>
      {error && <strong>Error: {JSON.stringify(error)}</strong>}
      {loading && <LoadingScreen></LoadingScreen>}
      {journals && (
        <>
          <Table responsive striped>
            <thead>
              <tr>
                <th>Type</th>
                <th>Phase</th>
                <th>Created</th>
                <th>Completed</th>
                {isAdmin && <th>Response</th>}
                
              </tr>
            </thead>
            <tbody>
              {journals.map((journal) => {
                return <UserJournalRow journal={journal} key={journal.id} isAdmin={isAdmin}/>;
              })}
            </tbody>
          </Table>
        </>
      )}
    </>
  );
};

export default UserJournalsTable;
