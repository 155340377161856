import { firestore } from "firebase";
import { useEffect, useState } from "react";
import { Program } from "./Program";

export const usePrograms = (): Program[] => {
  const [programs, setPrograms] = useState<Program[]>([]);
  useEffect(() => {
    const unsubscribe = firestore()
      .collection("programs")
      .onSnapshot((querySnapshot) => {
        if (!querySnapshot.docs) return;
        setPrograms(
          querySnapshot.docs
            .map((doc) => Program.fromFirestore(doc))
            .filter((program) => program.name)
        );
      });
    return () => unsubscribe();
  }, []);

  return programs;
};
