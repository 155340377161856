import React, {useState} from "react";
import {Link} from "react-router-dom";
import {useCollection, useDocument} from "react-firebase-hooks/firestore";
import LoadingScreen from "../LoadingScreen";
import TabContainer from "react-bootstrap/TabContainer";
import "./surveyAdherence.css";
import {firestore} from "../../firebase";
import AdminNavbar from "../AdminNavbar";
import {useHistory} from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import NavItem from "react-bootstrap/NavItem";
import AreportsListSidebar from "./AreportsListSidebar";
       
import {Button, Col, Container, Form, FormControl, InputGroup, Jumbotron, Modal, Row, Table,} from "react-bootstrap";
import AReportTable from "./AReportTable";
import BReportTable from "./BReportTable";
import AlargerReportTable from "./AlargerReportTable";
import AsmallerReportTable from "./AsmallerReportTable";

    // const query = firestore.collection("analytics/reports/reportPages");
    // const [reportPages, reportPagesLoading, reportPagesError] = useCollection(query, {
        // snapshotListenOptions: {includeMetadataChanges: true},
    // });
// const query = firestore.collection("analytics").doc("kpi_summary")
const FiqrVfablesOfTable = (props) => {
 return (<>
        <AdminNavbar/>
        <Jumbotron>
            <Container fluid={true}>
                <Row>
                    <Col>
                        <h1>FIQR Scores</h1>
                    </Col>
                </Row>
            </Container>
        </Jumbotron>
        <AreportsListSidebar/>
        <Container fluid={true} style={{backgroundColor: "white"}}>
            <div className="article">
            <p className="TitleC">FIQR: most recent vs baseline</p>
            <BReportTable tableKey={"fiqrVbaseline002"}></BReportTable>
                </div>
        </Container>
        <Container fluid={true} style={{backgroundColor: "white"}}>
            <div className="article">
            <p className="TitleC">FIQR: weekly changes</p>
            <BReportTable tableKey={"fiqr_changes002"}></BReportTable>
                </div>
        </Container>
        <Container fluid={true} style={{backgroundColor: "white"}}>
            <div className="article">
            <p className="TitleC">FIQR: weekly scores</p>
            <AlargerReportTable tableKey={"fiqr_scores"}></AlargerReportTable>
                </div>
        </Container>
</>);

};

export default FiqrVfablesOfTable;

