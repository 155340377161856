import React from "react";
import {
  Route,
  RouteComponentProps,
  Switch,
} from "react-router-dom";
import AdminNavbar from "../../AdminNavbar";
import { Col, Container, Jumbotron, Row } from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
import {
  faClinicMedical,
} from "@fortawesome/free-solid-svg-icons";
import NavLinker from "../../NavLinker";
import ScreenWeekAppUsage from "./ScreenWeekAppUsage";

const Dashboard005: React.FC<RouteComponentProps> = ({ match }) => {

  return (
    <>
      <AdminNavbar />
      <Container fluid={true}>
        <Jumbotron className={"text-center"}>
          <h1 className={"jumbotron-heading"}>005 Screening Week</h1>
          <p className="lead text-muted">
            That special time in a patients life where we check if they can open
            an app
          </p>
        </Jumbotron>
        <Container fluid={true} style={{ backgroundColor: "white" }}>
          <Row>
            <Col xs={2} className={"sidebar"}>
              <div className={"sidebar-sticky"}>
                <Nav className={"flex-column"}>
                  <NavLinker
                    baseUrl={match.url}
                    route=""
                    label="Overview"
                    icon={faClinicMedical}
                  />
                </Nav>
              </div>
            </Col>
            <Col xs={10} className="main-content" style={{ padding: 10 }}>
              <Switch>
                <Route
                  path={match.url}
                  exact
                  component={ScreenWeekAppUsage}
                />
              </Switch>
            </Col>
          </Row>
        </Container>
      </Container>
    </>
  );
};

export default Dashboard005;
