import React from "react";
import { DraggableProvided, DraggableStateSnapshot } from "react-beautiful-dnd";
import { Badge, Button, OverlayTrigger, Popover } from "react-bootstrap";
import { useHistory, useRouteMatch } from "react-router-dom";
import { formatAudioFile } from "../../../utils/Format";

interface LessonStepRowProps {
  index: number;
  step: any;
  activeLesson: any;
  isDragDisabled: boolean;
  draggableProvided: DraggableProvided;
  draggableSnapshot: DraggableStateSnapshot;
}

export const LessonStepRow = ({
  index,
  step,
  activeLesson,
  isDragDisabled,
  draggableProvided,
  draggableSnapshot,
}: LessonStepRowProps) => {
  const history = useHistory();
  const match = useRouteMatch();

  const needsImageType = step.image_filename && !step.imageType;
  const cellStyle = needsImageType ? {backgroundColor: "#CCCC00"} : undefined;

  return (
    <tr
      ref={draggableProvided.innerRef}
      {...draggableProvided.draggableProps}
      {...draggableProvided.dragHandleProps}
      onClick={() => {
        if (isDragDisabled) {
          history.push(match.url + "/" + (index + 1));
        }
      }}
    >
      <td style={cellStyle}>{index + 1}</td>
      <td style={cellStyle}>
        <Badge pill style={{ backgroundColor: getPillColor(step.type) }}>
          <span className="lesson-type-text">{step.type} </span>
        </Badge>
      </td>
      <td
        style={cellStyle}
        onClick={() => {
          history.push(match.url + "/" + (index + 1));
        }}
      >
        {step.title}
      </td>
      <td
        style={cellStyle}
        onClick={() => {
          history.push(match.url + "/" + (index + 1));
        }}
      >
        {(step.prompt && step.prompt) ||
          (step.content && step.content) ||
          (step.description && step.description)}{" "}
      </td>
      <td style={cellStyle}>
        {!!step.image_filename && (
          <>
          <img
            style={{ maxHeight: "150px" }}
            alt={step.image_filename}
            src={
              "https://storage.googleapis.com/tempo-pub/assets/v1/images/" +
              step.image_filename
            }
          ></img>
          <br />
          <span>{step.image_filename}</span>
          <br />
          </>
        )}{" "}
        {!!step.remote_audio_filename && formatAudioFile(step.remote_audio_filename)}
        {!!step.videoId && <a href={`https://vimeo.com/${step.videoId}`} target="_blank">{`https://vimeo.com/${step.videoId}`}</a>}
        {!step.image_filename && !step.remote_audio_filename && !step.videoId && "None"}
      </td>
      <td>
        <OverlayTrigger
          trigger="click"
          rootClose
          placement={"left"}
          overlay={
            // @ts-ignore
            <Popover onClick={(event) => event.stopPropagation()}>
              <Popover.Title as="h3">
                <div style={{ color: "black" }}>
                  Do you really want to delete this?
                </div>
              </Popover.Title>
              <Popover.Content>
                <Button
                  variant="danger"
                  onClick={(event) => {
                    event.stopPropagation();
                    activeLesson.steps.splice(index, 1);
                    activeLesson
                      .persist()
                      .then(() => window.alert("step deleted successfully!"))
                      .catch((error) => {
                        window.alert(
                          "An error occurred when trying to delete the step. Check the developer console for details."
                        );
                        console.error(error);
                      });
                  }}
                >
                  Yes Really
                </Button>
              </Popover.Content>
            </Popover>
          }
        >
          <Button variant="danger" onClick={(event) => event.stopPropagation()}>
            Delete
          </Button>
        </OverlayTrigger>
      </td>
    </tr>
  );
};

const getPillColor = (type) => {
  switch (type) {
    case "Introduction":
      return "MediumPurple";
    case "Text":
      return "darksalmon";
    case "Audio":
      return "Goldenrod";
    case "Prompt":
      return "DarkTurquoise";
    default:
      return "gray";
  }
};
