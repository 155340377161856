import { PayCondition, WorkflowType } from '@swing-therapeutics/surveybay/dist/types';
import { useField } from 'formik';
import { capitalize } from 'lodash';
import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { FormikTextField, FormikToggle } from '../../../FormikFields';

interface PNProps {
  forWeek: 'eligibility' | 'baseline' | number;
  workflowType?: WorkflowType;
}

const PaymentNotification: React.FC<PNProps> = ({ forWeek, workflowType = WorkflowType.EXTENSION }) => {
  const isTherapyWeek = typeof forWeek === 'number';
  const label = isTherapyWeek ? `therapy week ${forWeek as number}` : forWeek.toString();
  const baseField = isTherapyWeek ?
    `paymentNotifications.surveyWeeks[${(forWeek as number) - 1}]`
    :
    `paymentNotifications.${forWeek}`;
  const [fieldInfo, _fieldMeta, fieldHelpers] = useField(baseField);

  return (
    <Row className="my-3">
      <Col>
        <Row>
          <Col xs={6}>
            <h4>{capitalize(label)}</h4>
            <small>
              This condition is checked after {label} is over for a user.
              If the condition is met then the user will appear in the 'Payments' tab requesting the payment amount.
              Leaving a condition unchecked means the user can have any status to meet the conditon.
              {
                isTherapyWeek && ' If no survey exists for a week, it is considered completed.'
              }
            </small>
          </Col>
          <Col xs={6}>
            <Row className="justify-content-end">
              <Button variant="danger" onClick={() => fieldHelpers.setValue(null)}>
                X
              </Button>
            </Row>
          </Col>
        </Row>
        <Row className="my-3">
          <Col>
            <Button
              onClick={() => {
                const updatedValue = { ...fieldInfo.value } as PayCondition;
                updatedValue.eligibilityCompleted = true;
                if (forWeek === 'eligibility') {
                  fieldHelpers.setValue(updatedValue);
                  return;
                }
                updatedValue.baselineCompleted = true;
                if (forWeek === 'baseline') {
                  fieldHelpers.setValue(updatedValue);
                  return;
                }
                for (let index = 0; index < (forWeek as number); index++) {
                  updatedValue.therapyWeeksCompleted[index] = true;
                }
                fieldHelpers.setValue(updatedValue);
              }}
            >
              Select all
            </Button>
          </Col>
        </Row>
        <Row>
          {/* Eligibility will always be available to toggle completed condition */}
          {
            workflowType === WorkflowType.STANDARD &&
            <Col xs={4}>
              <FormikToggle
                name={`${baseField}.eligibilityCompleted`}
                label={`Eligibility completed`}
                inLine={true}
                allowLabelClick={true}
              />
            </Col>
          }
          {/* Baseline comes after eligibility, if not an eligibility condition then make it available to toggle */}
          {
            workflowType === WorkflowType.STANDARD &&
            forWeek !== 'eligibility' &&
            <Col xs={4}>
              <FormikToggle
                name={`${baseField}.baselineCompleted`}
                label={`Baseline completed`}
                inLine={true}
                allowLabelClick={true}
              />
            </Col>
          }
          {/* Map over therapy weeks to toggle completed condition */}
          {
            new Array((forWeek as number)).fill(0).map((_x, index) => {
              return (
                <Col xs={4} key={index}>
                  <FormikToggle
                    name={`${baseField}.therapyWeeksCompleted[${index}]`}
                    label={`Week ${index + 1} surveys completed`}
                    inLine={true}
                    allowLabelClick={true}
                  />
                </Col>
              )
            })
          }
        </Row>
        <Row>
          <Col xs={3}>
            <FormikTextField
              name={`${baseField}.amount`}
              type="number"
              label="Payment amount"
              placeHolder="Amount"
              prepend="$"
            />
          </Col>
          <Col xs={3}>
            <FormikTextField
              name={`${baseField}.paymentFor`}
              label="Payment for"
              placeHolder="Payment for"
            />
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default PaymentNotification;