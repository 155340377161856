import React, { useContext, useState, useEffect } from "react";
import { firestore } from "../../firebase";
import AdminNavbar from "../AdminNavbar";
import { useParams } from "react-router-dom";
import LoadingScreen from "../LoadingScreen";

import {
  Container,
  Row,
  Col,
  Table,
  Form,
  InputGroup,
  FormControl,
  Jumbotron,
  Button,
} from "react-bootstrap";
import { Role, RoleMember } from "../../Model/Role";

import { ReactComponent as Loading } from "../Images/loading.svg";

const RolesDetailPage = (props) => {
  const [activeRole, setActiveRole] = useState();
  const [members, setMembers] = useState([]);
  const [newMembersEmail, setNewMembersEmail] = useState("");
  const [filter, setFilter] = useState("");
  const [loading, setLoading] = useState(false);
  let { roleName } = useParams();

  useEffect(() => {
    setLoading(true);

    const subscriber = firestore
      .collection("roles")
      .doc(roleName)
      .collection("members")
      .onSnapshot((snapshot) => {
        if (!snapshot.empty) {
          let rolesLists = [];
          snapshot.docs.forEach((doc) => {
            let r = RoleMember.fromFirestore(doc);
            rolesLists.push(r);
          });

          setMembers(rolesLists);
        } else {
          console.log("no results", snapshot);
        }
      });
    setLoading(false);

    return () => subscriber();
  }, [roleName, setMembers]);

  useEffect(() => {
    const subscriber = firestore
      .collection("roles")
      .doc(roleName)
      .onSnapshot((snapshot) => {
        if (snapshot.exists) {
          const r = Role.fromFirestore(snapshot);
          setActiveRole(r);
          setLoading(false);
        } else {
          console.log("no results", snapshot);
        }
      });
    return () => subscriber();
  }, [setActiveRole, roleName]);

  const getTableRow = (member) => {
    if (!member || !member.matchesFilter(filter)) {
      return <></>;
    }
    return (
      <tr key={member.email}>
        <td>{member.email}</td>
        {member.dateAdded && <td>{member.dateAdded.toLocaleDateString()}</td>}
        <td>{member.addedBy}</td>
        <td>{member.uid}</td>
      </tr>
    );
  };

  if (loading || !activeRole) {
    return <LoadingScreen></LoadingScreen>;
  }

  return (
    <>
      <AdminNavbar></AdminNavbar>
      <Jumbotron>
        <Container>
          <Row>
            <h1>
              Role {roleName} - Members
            </h1>
          </Row>
          <Row>
            <Col>
              <Form inline>
                <InputGroup className="">
                  <FormControl
                    id="search"
                    placeholder="Search"
                    onChange={(e) => {
                      setFilter(e.target.value.trim());
                    }}
                  />
                </InputGroup>
              </Form>
            </Col>
            <Col xs="auto">
              <Form inline>
                <InputGroup className="">
                  <FormControl
                    id="new"
                    placeholder="New Member Email"
                    onChange={(e) => {
                      setNewMembersEmail(e.target.value.trim());
                    }}
                  />
                  <InputGroup.Append>
                    <Button
                      onClick={() => {
                        if (!newMembersEmail || newMembersEmail.length === 0)
                          return;
                        activeRole.addMemberByEmail(newMembersEmail);
                      }}
                    >
                      Create
                    </Button>
                  </InputGroup.Append>
                </InputGroup>
              </Form>
            </Col>
          </Row>
        </Container>
      </Jumbotron>

      <Container>
        <Row>
          <Table>
            <thead>
              <tr>
                <th>Email</th>
                <th>Date Added</th>
                <th>Added By</th>
                <th>Firestore UID</th>
              </tr>
            </thead>
            <tbody>{members.map((u) => getTableRow(u))}</tbody>
          </Table>
        </Row>
      </Container>
    </>
  );
};

export default RolesDetailPage;
