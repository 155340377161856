import React, {useState} from "react";
import {useCollection} from "react-firebase-hooks/firestore";
import LoadingScreen from "../LoadingScreen";

import {firestore} from "../../firebase";
import AdminNavbar from "../AdminNavbar";
import {useHistory} from "react-router-dom";

import {Button, Col, Container, Form, FormControl, InputGroup, Jumbotron, Row, Table,} from "react-bootstrap";

const JournalTypesList = (props) => {
    const [filter, setFilter] = useState("");

    let history = useHistory();
    const [journalTypes, journalTypesLoading, journalTypesError] = useCollection(firestore.collection("journalTypes"), {
        snapshotListenOptions: {includeMetadataChanges: true},
    });

    const getTableRow = (journalTypeRef) => {
        // if (!journalType.matchesFilter(filter)) {
        //     return <></>;
        // }
        let journalType = journalTypeRef.data();

        return (<tr
            key={journalType.id}

        >

            <td>{journalType.id}</td>


        </tr>);
    };

    return (<>
        <AdminNavbar></AdminNavbar>
        <Jumbotron>
            <Container>
                <Row>
                    <Col>
                        <h1>Journal Types</h1>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form inline>
                            <InputGroup className="">
                                <FormControl
                                    id="search"
                                    placeholder="Search"
                                    onChange={(e) => {
                                        setFilter(e.target.value.trim());
                                    }}
                                />
                            </InputGroup>
                        </Form>
                    </Col>
                    <Col xs="auto">
                        <Form inline>
                            <InputGroup className="">
                                <Button
                                    onClick={() => {
                                        history.push("/subjects/new");
                                    }}
                                >
                                    Create
                                </Button>
                            </InputGroup>
                        </Form>
                    </Col>
                </Row>
            </Container>
        </Jumbotron>
        <Container style={{backgroundColor: "white"}}>
            <Row>
                {journalTypesError && (<>
                    <strong>Error: {JSON.stringify(journalTypesError)}</strong>
                    <br></br>
                </>)}
                {journalTypesLoading && (<>
                    <strong>Subjects Loading: {JSON.stringify(journalTypesError)}</strong>
                    <br></br>
                    <LoadingScreen></LoadingScreen>
                </>)}
                {journalTypes && (<Col xs={12}>
                    <Table>
                        <thead key="systemEventsTable">
                        <tr>
                            <th>ID</th>


                        </tr>
                        </thead>
                        <tbody>
                        {journalTypes.docs.map((u) => getTableRow(u))}
                        </tbody>
                    </Table>
                </Col>)}
            </Row>
        </Container>
    </>);

}

export default JournalTypesList;
