import { firestore } from "firebase";
import { isNil } from "lodash";
import React, { useEffect } from "react";
import { useFormik } from "formik";
import {
  Button,
  Container,
  Modal,
  FormControl,
  Col,
  Table,
} from "react-bootstrap";
import useRole from "../../UseRole";
import AdminNavbar from "../AdminNavbar";
import * as yup from "yup";

type Cohort = {
  id: string;
  name: string;
};

export default function Cohorts() {
  const [cohorts, setCohorts] = React.useState([] as any);
  const [modalOpen, setModalOpen] = React.useState(false);
  const isAdmin = useRole("admin");
  const preExistingIds = cohorts.map((cohort) => cohort.id);

  const validationSchemaFunction = (preExistingIds: []) => {
    return yup.object().shape({
      name: yup
        .string()
        .required("Name is required")
        .notOneOf(preExistingIds, "Cohort already exists"),
    });
  };

  const { values, errors, handleChange, submitForm } = useFormik({
    initialValues: { name: "" },
    enableReinitialize: true,
    validationSchema: validationSchemaFunction(preExistingIds),
    onSubmit: async (values) => {
      const newCohort: Cohort = { name: values.name, id: values.name };
      setModalOpen(false);
      await firestore().collection("cohorts").doc(newCohort.id).set(newCohort);
      window.alert(`${newCohort.name} saved`);
    },
  });

  useEffect(() => {
    const unsubscribe = firestore()
      .collection("cohorts")
      .onSnapshot({ includeMetadataChanges: true }, (collection) => {
        if (collection.docs.length > 0) {
          setCohorts(collection.docs.map((doc) => doc.data()));
        }
      });
    return unsubscribe;
  }, []);

  const deleteCohort = async (cohortId: string) => {
    if (isNil(cohortId)) {
      window.alert("Error, invalid ID");
      return;
    }

    await firestore().collection("cohorts").doc(cohortId).delete();
    window.alert(`${cohortId} deleted`);
  };

  return (
    <div>
      <AdminNavbar />
      <Modal show={modalOpen}>
        <Modal.Header style={{ display: "flex", justifyContent: "center" }}>
          <Modal.Title>Create Cohort</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ display: "flex", justifyContent: "center" }}>
          <FormControl
            id="name"
            name="name"
            value={values.name}
            type="text"
            onChange={handleChange}
            placeholder="Cohort Name"
          />
        </Modal.Body>
        <Modal.Footer
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {errors && <p>{errors.name}</p>}
          <div
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              width: "50%",
            }}
          >
            <Button variant="secondary" onClick={() => setModalOpen(false)}>
              Close
            </Button>
            <Button
              variant="primary"
              onClick={() => {
                submitForm();
              }}
            >
              Save Changes
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
      <Container
        fluid={true}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {isAdmin && (
          <Button
            onClick={() => setModalOpen(true)}
            style={{ marginTop: 10, marginBottom: 10 }}
          >
            New Cohort
          </Button>
        )}
        <Col xs={12}>
          <Table>
            <thead key="cohorts">
              <tr>
                <th>ID</th>
                <th>Name</th>
                {isAdmin && <th>Actions</th>}
              </tr>
            </thead>
            <tbody>
              {cohorts.map((cohort) => (
                <tr key={cohort.id}>
                  <td>{cohort.id}</td>
                  <td>{cohort.name}</td>
                  {isAdmin && (
                    <td>
                      <Button
                        variant="danger"
                        onClick={() => {
                          if (
                            window.confirm(
                              `Are you sure you want to delete ${cohort.id}?`
                            )
                          ) {
                            deleteCohort(cohort.id);
                          }
                        }}
                      >
                        Delete
                      </Button>
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Container>
    </div>
  );
}
