import React from "react";
import AdminNavbar from "../AdminNavbar";
import {useHistory} from "react-router-dom";

import {Button, Col, Container, Form, Jumbotron, Row,} from "react-bootstrap";
import {useFormik} from "formik";
import {Subject} from "../../Model/Subjects";

const SubjectsCreate = () => {
    const formik = useFormik({
        initialValues: {
            email: "", fullName: "", role: "patient", study: "003", cohort: "default", smsOptOut: false,
        },

        onSubmit: (values, {setSubmitting}) => {
            const val = {...values};

            Subject.create(val.email, val.subjectID, val.fullName, val.study, val.cohort, val.role, val.smsNumber, val.smsOptOut, val.surveyMonkeyID, val.zendeskID, "tempo1", null);
            setSubmitting(false);
        },
    });
    let history = useHistory();

    return (<>
        <AdminNavbar></AdminNavbar>
        <Jumbotron>
            <Container>
                <Row>
                    <h1>Create a Subject </h1>
                </Row>
            </Container>
        </Jumbotron>
        <Container style={{backgroundColor: "white"}}>
            <Row>
                <Col xs={12}>
                    <Form onSubmit={formik.handleSubmit}>
                        <br></br>

                        <h4>Subject Info</h4>
                        <Form.Row>
                            <Form.Group as={Col} xs={12} sm={6} controlId="fullName">
                                <Form.Label>Full Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="fullName"
                                    value={formik.values.fullName}
                                    onChange={formik.handleChange}
                                    placeholder="Paula Patient"
                                />
                            </Form.Group>

                            <Form.Group as={Col} xs={12} sm={6} controlId="email">
                                <Form.Label>Email</Form.Label>
                                <Form.Control
                                    type="email"
                                    name="email"
                                    value={formik.values.email}
                                    onChange={formik.handleChange}
                                    placeholder="Enter email"
                                />
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} xs={6} sm={9} controlId="smsNumber">
                                <Form.Label>SMS Phone Number</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="smsNumber"
                                    value={formik.values.smsNumber}
                                    onChange={formik.handleChange}
                                />
                            </Form.Group>

                            <Form.Group as={Col} xs={6} sm={3} id="smsOptOut">
                                <Form.Label>SMS Opt Out</Form.Label>
                                <Form.Check
                                    type="checkbox"
                                    name="smsOptOut"
                                    value={formik.values.smsOptOut}
                                    onChange={formik.handleChange}
                                    label="Opted out"
                                />
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} xs={12} sm={9} controlId="subjectID">
                                <Form.Label>Subject ID</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="subjectID"
                                    value={formik.values.subjectID}
                                    onChange={formik.handleChange}
                                    placeholder="Subject ID"
                                />
                            </Form.Group>


                        </Form.Row>
                        <br></br>

                        <h4>Enrollment</h4>
                        <Form.Row>
                            <Form.Group as={Col} controlId="study">
                                <Form.Label>Study Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="study"
                                    value={formik.values.study}
                                    onChange={formik.handleChange}
                                />
                            </Form.Group>

                            <Form.Group as={Col} controlId="role">
                                <Form.Label>Role</Form.Label>
                                <Form.Control
                                    name="role"
                                    value={formik.values.role}
                                    onChange={formik.handleChange}
                                    as="select"
                                >
                                    <option>patient</option>
                                    <option>admin</option>
                                </Form.Control>
                            </Form.Group>

                            <Form.Group as={Col} controlId="cohort">
                                <Form.Label>Cohort</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="cohort"
                                    value={formik.values.cohort}
                                    onChange={formik.handleChange}
                                />
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} controlId="zendeskID">
                                <Form.Label>Zendesk ID</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="zendeskID"
                                    value={formik.values.zendeskID}
                                    onChange={formik.handleChange}
                                />
                            </Form.Group>

                            <Form.Group as={Col} controlId="surveyMonkeyID">
                                <Form.Label>Survey Monkey ID</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="surveyMonkeyID"
                                    value={formik.values.surveyMonkeyID}
                                    onChange={formik.handleChange}
                                />
                            </Form.Group>
                        </Form.Row>

                        <Button
                            disabled={formik.isSubmitting}
                            variant="primary"
                            type="submit"
                            onClick={() => {
                                formik.handleSubmit();
                            }}
                        >
                            Submit
                        </Button>
                    </Form>
                </Col>
            </Row>
        </Container>
    </>);
};

export default SubjectsCreate;
