import React from "react";
import "../surveyAdherence003.css";

import { Col, Container, Jumbotron, Row, } from "react-bootstrap";



import { HashLink } from "react-router-hash-link";
import GraphTemplate003Aref from "./GraphTemplate003Aref";
import CmReportTable003Axref from "./CmReportTable003Axref";
import Graphs003ASwingInternal_indiv from "./Graphs003ASwingInternal_indiv";
import { Helmet } from "react-helmet";




const ReportTest = (props) => {


    return (<>

        <Helmet>
            <title>referrer_report_test 003A</title>
            <meta name="Referrer 003A" content="Referrer 003A" />
        </Helmet>


        <Jumbotron>
            <Container fluid={true} style={{ paddingBottom: "20px", paddingLeft: "0px" }}>
                <Row>
                    <Col>
                        <h1>Referrer Report: Swing Internal</h1>
                    </Col>
                </Row>
            </Container>
        </Jumbotron>

        <Container fluid={true} style={{ paddingBottom: "0px", paddingLeft: "10px", paddingTop: "0px" }}>
            <Row>
                <Col>
                    <div className="TitleC" style={{ paddingBottom: "0px", paddingLeft: "0px" }}>Contents</div>
                </Col>
            </Row>

        </Container>
        <Container fluid={true} style={{ paddingBottom: "15px", paddingLeft: "10px", paddingTop: "0px" }}>
            <Row>
                <Col>



                    <HashLink id="top" className="TitleDB" style={{ paddingBottom: "30px", fontSize: "18px", paddingLeft: "15px" }} to="#appUse">app use</HashLink>
                    <br />
                    <HashLink id="top" className="TitleDB" style={{ paddingBottom: "30px", fontSize: "18px", paddingLeft: "15px" }} to="#pgic">pgic</HashLink>
                    <br />
                    <HashLink id="top" className="TitleDB" style={{ paddingBottom: "30px", fontSize: "18px", paddingLeft: "15px" }} to="#indivs">individual participant charts</HashLink>

                </Col>
            </Row>

        </Container>
        <Container fluid={true} style={{ backgroundColor: "white", paddingLeft: "10px", paddingRight: "0px", maxHeight: "10in" }}>
            <Row>
                <Col xs={4}>

                    <div className="TitleC" style={{ paddingBottom: "0px", paddingRight: "30px", paddingLeft: "0px" }}>Screening Funnel</div>
                    <GraphTemplate003Aref style={{ paddingBottom: "0px", paddingTop: "0px", paddingRight: "0px", paddingLeft: "0px" }} report={"funnelA_swing_internal"} />
                </Col>
                <Col xs={4} style={{ paddingLeft: "40px" }}>

                    <div className="TitleC" style={{ paddingBottom: "0px", paddingRight: "0px" }}>Participant Statuses</div>
                    <GraphTemplate003Aref style={{ paddingBottom: "0px", paddingTop: "0px", paddingRight: "0px", paddingLeft: "0px" }} report={"funnelB_swing_internal"} />
                </Col>
            </Row>
        </Container>
        <Container fluid={true} style={{ backgroundColor: "white", paddingLeft: "5px", paddingRight: "10px", paddingTop: "30px", paddingBottom: "20px" }}>
            <Row>
                <Col xs={7}>
                    <div className="TitleC" style={{ paddingBottom: "0px", paddingRight: "0px", paddingLeft: "0px" }}>FIQR Scores</div>
                    <GraphTemplate003Aref style={{ paddingBottom: "0px", paddingTop: "0px", paddingRight: "0px", paddingLeft: "0px" }} report={"fiqr_plotA_swing_internal"} />
                </Col>
            </Row>
        </Container>
        <Container fluid={true} style={{ backgroundColor: "white", paddingLeft: "10px", paddingRight: "0px" }}>
            <Container fluid={true} style={{ paddingBottom: "1px", paddingTop: "10px" }}>
                <Row>
                    <Col>

                        <HashLink id="appUse" className="TitleDB" style={{ paddingBottom: "30px", fontSize: "14px", paddingLeft: "0px" }} to="#top">jump to top</HashLink>
                    </Col>
                </Row>
            </Container>
            <Row>
                <Col xs={7}>
                    <div className="TitleC" style={{ paddingBottom: "0px", paddingRight: "0px", paddingLeft: "0px" }}>App Use</div>
                    <GraphTemplate003Aref style={{ paddingBottom: "10px", paddingTop: "0px", paddingRight: "0px", paddingLeft: "0px" }} report={"appUseA_swing_internal"} />
                </Col>

            </Row>
        </Container>

        <Container className="page_" fluid={true} style={{ backgroundColor: "white", paddingLeft: "5px", paddingRight: "10px", paddingTop: "10px", paddingBottom: "10px" }}>
            <Container fluid={true} style={{ paddingBottom: "1px", paddingTop: "10px" }}>
                <Row>
                    <Col>

                        <HashLink id="pgic" className="TitleDB" style={{ paddingBottom: "30px", fontSize: "14px", paddingLeft: "0px" }} to="#top">jump to top</HashLink>
                    </Col>
                </Row>
            </Container>
            <Row>

                <Col xs={7}>
                    <div className="TitleC" style={{ paddingBottom: "0px", paddingRight: "0px", paddingLeft: "0px" }}>PGIC Scores</div>
                    <GraphTemplate003Aref class="page_" style={{ paddingBottom: "0px", paddingTop: "0px", paddingRight: "0px", paddingLeft: "0px" }} report={"pgicA_swing_internal"} />
                </Col>
            </Row>
        </Container>
        <Container fluid={true} style={{ backgroundColor: "white", paddingLeft: "5px", paddingRight: "10px", paddingTop: "10px", paddingBottom: "20px" }}>

            <Row>
                <Col xs={11} style={{ paddingTop: "30px" }}>
                    <div className="TitleC" style={{ paddingBottom: "0px", paddingRight: "0px", paddingLeft: "0px" }}>PGIC Scores, by Participant</div>
                    <CmReportTable003Axref bgColor="#6694BA" style={{ paddingTop: "30px" }} tableKey={"pgicTableA_swing_internal"} />
                </Col>
            </Row>
        </Container>
<br/>
<br/>
<br/>
<br/>
<br/>

        <div className="page_" style={{ paddingBottom: "180px" }}></div>




        <Container fluid={true} style={{ backgroundColor: "white", paddingLeft: "5px", paddingRight: "10px" }}>
            <Container fluid={true} style={{ paddingBottom: "1px", paddingTop: "5px" }}>
                <Row>
                    <Col>

                        <HashLink id="indivs" className="TitleDB" style={{ paddingBottom: "3px", fontSize: "14px", paddingLeft: "0px" }} to="#top">jump to top</HashLink>
                    </Col>
                </Row>
            </Container>
          
            <Row>
                <Col xs={7}>
                    <div className="TitleC" style={{ paddingBottom: "0px", paddingRight: "0px", paddingLeft: "0px" }}>Individual App Progress</div>
                    <Graphs003ASwingInternal_indiv style={{ paddingBottom: "0px", paddingTop: "0px", paddingRight: "0px", paddingLeft: "0px" }} report={"r00_00227"} />
                </Col>
            </Row>
        </Container>
        <Container fluid={true} style={{ backgroundColor: "white", paddingLeft: "5px", paddingRight: "10px" }}>
            <Row>
                <Col xs={7}>
                    <Graphs003ASwingInternal_indiv style={{ paddingBottom: "0px", paddingTop: "0px", paddingRight: "0px", paddingLeft: "0px" }} report={"r00_00301"} />
                </Col>
            </Row>
        </Container>

        <Container fluid={true} style={{ backgroundColor: "white", paddingLeft: "5px", paddingRight: "10px" }}>
            <Row>
                <Col xs={7}>
                    <Graphs003ASwingInternal_indiv style={{ paddingBottom: "0px", paddingTop: "0px", paddingRight: "0px", paddingLeft: "0px" }} report={"r00_00299"} />
                </Col>
            </Row>
        </Container>

        <Container fluid={true} style={{ backgroundColor: "white", paddingLeft: "5px", paddingRight: "10px" }}>
            <Row>
                <Col xs={7}>
                    <Graphs003ASwingInternal_indiv style={{ paddingBottom: "0px", paddingTop: "0px", paddingRight: "0px", paddingLeft: "0px" }} report={"r00_00357"} />
                </Col>
            </Row>
        </Container>



        <Container fluid={true} style={{ backgroundColor: "white", paddingLeft: "5px", paddingRight: "10px" }}>
            <Row>
                <Col xs={7}>
                    <Graphs003ASwingInternal_indiv style={{ paddingBottom: "0px", paddingTop: "0px", paddingRight: "0px", paddingLeft: "0px" }} report={"r00_00264"} />
                </Col>
            </Row>
        </Container>



        <Container fluid={true} style={{ backgroundColor: "white", paddingLeft: "5px", paddingRight: "10px" }}>
            <Row>
                <Col xs={7}>
                    <Graphs003ASwingInternal_indiv style={{ paddingBottom: "0px", paddingTop: "0px", paddingRight: "0px", paddingLeft: "0px" }} report={"r00_00327"} />
                </Col>
            </Row>
        </Container>

        <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "5px", paddingRight: "10px"}}>
 <Row>
 <Col xs={7}>
        <Graphs003ASwingInternal_indiv style={{paddingBottom:"0px",paddingTop: "0px", paddingRight: "0px", paddingLeft: "0px"}} report={"r00_00372"} />
        </Col>
        </Row>   
        </Container>
    <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "5px", paddingRight: "10px"}}>
 <Row>
 <Col xs={7}>
        <Graphs003ASwingInternal_indiv style={{paddingBottom:"0px",paddingTop: "0px", paddingRight: "0px", paddingLeft: "0px"}} report={"r00_00388"} />
        </Col>
        </Row>   
        </Container>
        <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "5px", paddingRight: "10px"}}>
 <Row>
 <Col xs={7}>
        <Graphs003ASwingInternal_indiv style={{paddingBottom:"0px",paddingTop: "0px", paddingRight: "0px", paddingLeft: "0px"}} report={"r00_00408"} />
        </Col>
        </Row>   
        </Container>
    <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "5px", paddingRight: "10px"}}>
 <Row>
 <Col xs={7}>
        <Graphs003ASwingInternal_indiv style={{paddingBottom:"0px",paddingTop: "0px", paddingRight: "0px", paddingLeft: "0px"}} report={"r00_00409"} />
        </Col>
        </Row>   
        </Container>

    </>);

};


export default ReportTest;







