import React from "react";
import { useCollection } from "react-firebase-hooks/firestore";
import LoadingScreen from "../../../LoadingScreen";
import { DocumentData, firestore } from "../../../../firebase";
import { Table } from "react-bootstrap";
import User from "../../../../Model/Usert";

interface TableRowProps {
  usageDoc: DocumentData;
  user: User;
}

const TableRow: React.FC<TableRowProps> = ({ usageDoc, user }) => {
  const usageRow = usageDoc.data();
  let subjectID = user.uid;
  let pdate = usageRow.pdate?.toDate()?.toLocaleDateString();
  let day = usageRow.day;
  let opens = usageRow.opens;

  return (
    <tr>
      <td>{subjectID}</td>
      <td>{pdate}</td>
      <td>{day}</td>
      <td>{opens}</td>
    </tr>
  );
}

interface UserUsageByDayProps {
  user: User;
}

const UserUsageByDayTable: React.FC<UserUsageByDayProps> = ({ user }) => {
  const [usage, usageLoading, usageError] = useCollection(
    firestore
      .collection("analytics")
      .doc("screening004")
      .collection("screening004BappUse")
      .doc(user.uid)
      .collection("screening004BappUse")
      .orderBy("day"),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  return (
    <>
      {usageError && <strong>Error getting Usage: {JSON.stringify(usageError)}</strong>}
      {usageLoading && <LoadingScreen />}
      <h4>App Usage By Day, Screening Week</h4>
      {usage && (
        <Table responsive>
          <thead>
            <tr>
              <th>SubjectID</th>
              <th>Date</th>
              <th>Day</th>
              <th>Opens</th>
            </tr>
          </thead>
          <tbody>
            {usage.docs.map((u) =>
              <TableRow key={u.id} user={user} usageDoc={u} />
            )}
          </tbody>
        </Table>
      )}
    </>
  );
};

export default UserUsageByDayTable;
