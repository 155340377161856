import React from 'react';
import { useField } from "formik";

const DisplaySurveyAssignmentDay: React.FC = () => {
  const [fieldInfo] = useField("weekdaySurveyAssignment");
  // +1 because it is 0 index
  return (
    <h6>Therapy weekday that a therapy survey will be assigned: Day {fieldInfo.value + 1}</h6>
  )
}

export default DisplaySurveyAssignmentDay;