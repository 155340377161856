import { Form as FormikForm, Formik } from "formik";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Col, Container, Row, Button } from "react-bootstrap";
import { TerminationCallWorkflowData, Workflow } from "../../../../Model/Workflow";
import * as Yup from "yup";
import moment from "moment";
import { FormikSelect, FormikSubmitButton, FormikTextField } from "../../../FormikFields";
import { ScheduledCallStatus } from "@swing-therapeutics/surveybay/dist/types";

interface TerminationCallViewProps {
  workflow: Workflow;
}

// Override the dateOfCall value so we can input a string date and later change
// back into date
interface TerminationCallForm extends Omit<TerminationCallWorkflowData, 'dateOfCall'> {
  dateOfCall: string;
}

const TerminationCallView: React.FC<TerminationCallViewProps> = ({ workflow }) => {
  const workflowData = workflow.workflowData as TerminationCallWorkflowData;
  const isMounted = useRef(true);
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");

  useEffect(() => {
    isMounted.current = true;
    return () => { isMounted.current = false };
  }, [])

  const setDisappearingMessage = useCallback((msg, error = false) => {
    const setFunc = error ? setError : setMessage;
    setFunc(msg);
    setTimeout(() => {
      isMounted.current && setFunc("");
    }, 5000);
  }, [isMounted])

  return (
    <Col>
      <Row>
        <Formik
          initialValues={{
            ...workflowData,
            dateOfCall: moment.utc(workflowData.dateOfCall ?? new Date()).format("yyyy-MM-DD"),
          }}
          enableReinitialize={true}
          onSubmit={async (values: TerminationCallForm, actions) => {
            const updatedWorkflowData: any = { ...values };
            updatedWorkflowData.dateOfCall = new Date(updatedWorkflowData.dateOfCall);
            workflow.workflowData = { ...updatedWorkflowData } as TerminationCallWorkflowData;
            const status = await workflow.persist();
            if (status instanceof Error) {
              setDisappearingMessage("Error saving date, check console", true);
            }
            else {
              setDisappearingMessage("Saved");
            }
            actions.setSubmitting(false);
          }}
          validateOnChange={false}
          validationSchema={FormSchema}
        >
          <FormikForm role="form" style={{ width: '100%' }}>
            <Container>
              <Row xs={12} sm={4}>
                <FormikSelect
                  name="status"
                  label="Call status"
                  desc="Status of the call, this will automatically update when a user schedules a call. Set to 'Completed' once the call is over."
                  options={[
                    { value: ScheduledCallStatus.NOT_SCHEDULED, label: 'Not scheduled' },
                    { value: ScheduledCallStatus.CALL_SCHEDULED, label: 'Call scheduled' },
                    { value: ScheduledCallStatus.ABANDONED, label: 'Abandoned call' },
                    { value: ScheduledCallStatus.COMPLETED, label: 'Completed' },
                  ]}
                />
              </Row>
              {
                workflowData.status === ScheduledCallStatus.CALL_SCHEDULED && workflowData.callEndTime && workflowData.callStartTime &&
                <>
                  <Row>
                    <p>
                      <small>
                        Scheduled for {moment(workflowData.callStartTime).format('h:mm')} -{' '}
                        {moment(workflowData.callEndTime).format('h:mm a, dddd, MMMM DD, YYYY')}
                      </small>
                    </p>
                  </Row>
                  <Row className="mb-3">
                    <a href={workflowData.cancelURL} target="_blank"><Button>Cancel call</Button></a>
                  </Row>
                </>
              }
              <Row>
                <FormikTextField
                  name="dateOfCall"
                  label="Followup call date"
                  desc="Date the followup call took place"
                  type="date"
                />
              </Row>
              <Row>
                <FormikTextField
                  name="employeeName"
                  label="Employee name"
                  desc="Name of the employee that completed the followup call"
                  placeHolder="Employee name"
                />
              </Row>
              <Row>
                <FormikTextField
                  name="notes"
                  label="Notes"
                  desc="Notes on the followup call or other information to be saved"
                  placeHolder="Notes..."
                  multiLine={true}
                />
              </Row>
              <Row>
                <FormikSubmitButton label="Submit" />
              </Row>
              <Row>
                {
                  message &&
                  <h6 className="mt-2 text-success">{message}</h6>
                }
                {
                  error &&
                  <h6 className="mt-2 text-warning">{error}</h6>
                }
              </Row>
            </Container>
          </FormikForm >
        </Formik>
      </Row>
    </Col>
  )
}

export default TerminationCallView;

const FormSchema = Yup.object().shape({
  status: Yup.string().required('Required'),
  employeeName: Yup.string()
    .required('Required'),
  dateOfCall: Yup.date()
    .transform((_value, originalValue) => {
      return new Date(originalValue);
    })
    .typeError('Enter date in format mm/dd/yyyy')
    .required('Required')
})
