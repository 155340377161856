import {useHistory} from "react-router-dom";
import User from "../../Model/Usert";
import React, {useEffect, useState} from "react";
import {firestore} from "../../firebase";
import {Button, Col, Container, Row} from "react-bootstrap";
import {PropertyDisplay} from "./PropertyDisplay";

const ScreeningCard = (props: any) =>
{
  let history = useHistory();
  const user: User = props.user;
  const handleEnroll = props.handleEnroll;
  const [progDoc, setProgDoc] = useState<any>();
  const [enrollmentDate, setEnrollmentDate] = useState<Date>();
  const [daysOpened, setDaysOpened] = useState(0);
  useEffect(() =>
  {
    const subscriber = firestore
    .collection("users/" + user.uid + "/appUsage")
    .orderBy("timeOpened", "desc")
    .onSnapshot((snap) =>
    {
      let usageMap: number[] = [];
      let numDays = 0;
      snap.docs.forEach((u) =>
      {
        let data = u.data();
        let startDate = data.timeOpened?.toDate().toLocaleDateString();
        if (!usageMap[startDate]) {
          usageMap[startDate] = 1;
          numDays++;
        }
        else {
          usageMap[startDate] = usageMap[startDate] + 1;
        }
      });

      setDaysOpened(numDays);
    });
    const secondarySubscriber = firestore
    .doc("users/" + user.uid + "/programs/screeningWeek")
    .onSnapshot((snapshot) =>
    {
      let date = user.created;
      setEnrollmentDate(date);
      setProgDoc(snapshot.data());
    });

    return () =>
    {
      subscriber();
      secondarySubscriber();
    };
  }, [user.created, user.uid]);

  return (<>
    <Container fluid>
      <hr/>
      <Row>
        <Col>
          <h4
          onClick={() =>
          {
            history.push("/u/" + user.uid);
          }}
          className={daysOpened >= 4 ? "text-success" : "text-primary"}
          >
            {user.subjectID} <small>{user.email}</small>
          </h4>
        </Col>
      </Row>
      <Row>
        <PropertyDisplay
        name={"Days Engaged"}
        value={daysOpened}
        ></PropertyDisplay>
        <PropertyDisplay
        name={"Cohort"}
        value={user.cohort}
        ></PropertyDisplay>
        <PropertyDisplay name={"Site"} value={user.site}></PropertyDisplay>
        <PropertyDisplay
        name={"C1 Date"}
        value={enrollmentDate?.toLocaleDateString()}
        ></PropertyDisplay>
      </Row>
      <Row>
        <Col>
          {" "}
          <Button
          variant={"success"}
          onClick={() =>
          {
            handleEnroll("tempoACT", user, "ACT-daily-insights");
          }}
          >
            {" "}
            A2 Enroll ACT Daily
          </Button>
        </Col>
        <Col>
          {" "}
          <Button
          variant={"success"}
          onClick={() =>
          {
            handleEnroll("tempoACT", user, "ACT-weekly-insights");
          }}
          >
            {" "}
            A1 Enroll ACT Weekly
          </Button>
        </Col>
        <Col xs={"auto"}>
          {" "}
          <Button
          variant={"info"}
          onClick={() =>
          {
            handleEnroll("control_v2", user);
          }}
          >
            {" "}
            Enroll ST
          </Button>
        </Col>
      </Row>
    </Container>
  </>);
};

export {ScreeningCard};
