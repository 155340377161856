import { ValuesCategory } from "@swing-therapeutics/swingcore/dist/models/values/ValuesCategory";
import { getValuesCategoryCollection } from "@swing-therapeutics/swingcore/dist/utils/queries";
import { useFormik } from "formik";
import React, { useState } from "react";
import { Button, Col, Form, Modal } from "react-bootstrap";
import { useCollectionOnce } from "react-firebase-hooks/firestore";
import * as Yup from "yup";
import { firestore } from "firebase";
import { BasicTextField } from "../../../utils/FormikHelpers";

interface ValuesCategoryCloneModalProps {
  show: boolean;
  close: Function;
  valuesCategory: ValuesCategory;
}

export const ValuesCategoryCloneModal = ({
  show,
  close,
  valuesCategory,
}: ValuesCategoryCloneModalProps) => {
  const [programs] = useCollectionOnce(firestore().collection("programs"));

  const [
    targetProgramValuesCategories,
    setTargetProgramValuesCategories,
  ] = useState<firestore.QueryDocumentSnapshot<firestore.DocumentData>[]>([]);

  const [loading, setLoading] = useState(false);

  const getTargetProgramValuesCategories = async (targetProgram: string) => {
    setLoading(true);
    const targetProgramValuesCategories = await getValuesCategoryCollection(
      targetProgram
    ).get();
    setTargetProgramValuesCategories(targetProgramValuesCategories.docs || []);
    setLoading(false);
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: { selectedProgram: "", newId: valuesCategory.id },
    onSubmit: async (values) => {
      try {
        const newOrder = targetProgramValuesCategories.length + 1 || 1;
        const newValuesCategory = new ValuesCategory(
          values.newId,
          valuesCategory.name,
          values.selectedProgram,
          valuesCategory.description,
          newOrder,
          { ...valuesCategory.entryValidation },
          new Date()
        );
        await newValuesCategory.persist();
        window.alert("Values Category cloned successfully!");
        close();
      } catch (error) {
        window.alert(
          "An error occurred while trying to clone this values category. Check the developer console for details."
        );
        console.error(error);
      }
    },
    validationSchema: Yup.object().shape({
      newId: Yup.string()
        .required("New ID is required")
        .test(
          "unique-id",
          "A Values Category in the selected program already has this id",
          (value) => {
            if (!targetProgramValuesCategories.length) {
              return true;
            }

            return !targetProgramValuesCategories.some(
              (_valuesCategory) => _valuesCategory.id === value
            );
          }
        ),
      selectedProgram: Yup.string().required("Selected Program is required"),
    }),
  });

  const handleClose = () => {
    formik.resetForm();
    close();
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Edit</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Col>
          <p>Cloning Values Category to this program:</p>
          <Form.Group controlId="selectedProgram">
            <Form.Label>Target Program</Form.Label>
            <Form.Control
              as="select"
              data-testid="selectedProgram"
              value={formik.values.selectedProgram}
              onChange={(event) => {
                getTargetProgramValuesCategories(event.target.value);
                formik.handleChange(event);
              }}
            >
              <option value={""} disabled>
                Select a program...
              </option>
              {programs?.docs
                .filter((program) => program.id !== valuesCategory.programKey)
                .map((program) => (
                  <option
                    data-testid={`program-${program.id}`}
                    key={program.id}
                    value={program.id}
                  >
                    {program.id}
                  </option>
                ))}
            </Form.Control>
          </Form.Group>
        </Col>
        <BasicTextField
          formikHelpers={formik}
          path="newId"
          title="New ID"
          tooltip="New ID for the cloned Values Category. IDs are unique per program."
        />
      </Modal.Body>
      <Modal.Footer>
        {loading && (
          <p style={{ marginRight: "auto" }} data-testid="loading">
            Loading...
          </p>
        )}
        <Button onClick={handleClose} variant="outline-secondary">
          Close
        </Button>
        <Button
          data-testid="valuesCategoryCloneSubmit"
          disabled={
            formik.isSubmitting ||
            !formik.values.selectedProgram ||
            !formik.values.newId
          }
          onClick={formik.submitForm}
          variant="outline-primary"
        >
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
