import React, { useCallback, useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom";
import { firestore } from "firebase";
import AdminNavbar from "../../AdminNavbar";
import { debounce } from "lodash";

const EditBlacklistHTML = () => {
  let history = useHistory();
  const [blacklistScreen, setScreen] = useState("");
  const [header, setHeader] = useState("");
  const [body, setBody] = useState("");

  // debounced to prevent screen flickering
  const setScreenDebounced = useCallback(
    debounce((html) => {
      setScreen(html);
    }, 500),
    []
  );

  const getBlacklistHTML = () => {
    const htmlSubscriber = firestore()
      .collection("tempest")
      .doc("versions")
      .onSnapshot((snapshot) => {
        const html = snapshot.data()?.blacklistHTML;
        if (html) {
          const parsedHtmlDoc = new DOMParser().parseFromString(
            html,
            "text/html"
          );
          const _header = parsedHtmlDoc.getElementsByClassName("header")[0]
            .innerHTML;

          const _body = parsedHtmlDoc
            .getElementsByClassName("body")[0].innerHTML
            .trim()
            .split("\n")
            .map((str) => str.trim())
            .join("\n");

          setHeader(_header);
          setBody(_body);
          setScreen(buildHTML(_header, _body));
        }
      });
    return htmlSubscriber;
  };

  useEffect(() => {
    const unsubscribe = getBlacklistHTML();
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    setScreenDebounced(buildHTML(header, body));
  }, [header, body]);

  const onFormSubmit = async (event) => {
    event.preventDefault();

    try {
      await firestore()
        .collection("tempest")
        .doc("versions")
        .set({ blacklistHTML: buildHTML(header, body) });
      setScreen(buildHTML(header, body));
      window.alert("HTML saved successfully!");
    } catch (error) {
      console.error(error);
      window.alert(
        "There was a problem with saving the HTML. Check the developer console for details."
      );
    }
  };

  const backBtn = (
    <>
      <div
        className="back-button"
        onClick={() => {
          history.goBack();
        }}
      >
        <FontAwesomeIcon icon={faArrowLeft}></FontAwesomeIcon> Go Back
      </div>
    </>
  );

  if (!blacklistScreen) {
    return (
      <>
        <Row className={"mt-4 ml-4"}>
          <h4>No blacklist screen found.</h4>;
        </Row>
      </>
    );
  }

  return (
    <>
      <AdminNavbar />
      <Row className={"mt-4 ml-4"}>{backBtn}</Row>
      <Container style={{ paddingLeft: 30, paddingTop: 40 }}>
        <Row className={"mt-4"}>
          <div>
            <h4>Blacklist Screen (try refreshing if it does not appear)</h4>
          </div>
        </Row>
        <Row>
          <Col>
            <iframe
              style={{ height: "896px", width: "414px", border: 0 }}
              srcDoc={blacklistScreen}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Form onSubmit={onFormSubmit}>
              <Form.Group>
                <Form.Label>Header</Form.Label>
                <Form.Control
                  required
                  as="textarea"
                  type="text"
                  name="header"
                  value={header}
                  onChange={(e) => setHeader(e.target.value)}
                />
              </Form.Group>

              <Form.Group>
                <Form.Label>Body</Form.Label>
                <Form.Control
                  required
                  rows={10}
                  as="textarea"
                  type="text"
                  name="body"
                  value={body}
                  onChange={(e) => {
                    setBody(
                      e.target.value
                        .trim()
                        .split("\n")
                        .map((str) => str.trim())
                        .join("\n")
                    );
                  }}
                />
              </Form.Group>

              <Button variant="primary" type="submit">
                Submit
              </Button>
            </Form>
          </Col>
        </Row>
      </Container>
    </>
  );
};

const buildHTML = (
  header = `Please update tempo`,
  body = `
    This version of the app is no longer supported. 

    To get back to your Tempo journey, visit the Android Play Store or TestFlight to install the newest version.

    If you run into any issues, please <a style="color: rgb(190,162,200)" href="mailto:support@swingtx.com">contact us.</a>
  `
) => `
  <html>
    <head>
    <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Noto+Sans&display=swap" />
    <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1.0">
    <style>
      .container {
        height: 100vh;
        width: 100vw; 
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .content {
        display: flex; 
        align-items: center; 
        justify-content: center; 
        flex-direction: column; 
        color: white;
        text-align: center;
        width: 70vw;
        white-space: pre-line;
      }
      .header {
        font-family: -apple-system, "Noto Sans";
        font-size: 1.5rem; 
        font-weight: 600; 
        margin-bottom: -0.25rem;
      }
      .body {
        font-family: "Noto Sans";
        font-size: 1.25rem;
      }
    </style>
  </head>
  <body style="background: linear-gradient(322.16deg, #0b0438 45.55%, #3e3575 72.86%, #776db9 93.99%); -webkit-font-smoothing: subpixel-antialiased; overflow: hidden; height: 100vh; width: 100vw">
    <div class="container">
      <div class="content">
        <svg xmlns="http://www.w3.org/2000/svg" width="80" height="67" fill="none" viewBox="0 0 80 67">
          <path fill="#fff" d="M55.238 31.852c2.02.002 4.034.22 6.002.648L42.18 1.192C41.96.83 41.642.53 41.26.32 40.876.11 40.442 0 40 0c-.441 0-.875.11-1.258.32-.383.209-.7.51-.921.872L11.58 44.291c.765.022 1.543.033 2.335.034 14.463 0 22.21-12.473 41.322-12.473zM2.516 66.336h74.968c.442 0 .876-.11 1.258-.32.382-.208.7-.509.92-.87.221-.363.338-.773.338-1.192 0-.418-.115-.828-.336-1.19L68.53 44.476c-2.426-.42-4.886-.634-7.352-.641-14.463 0-22.986 10.517-38.482 10.517-5.06 0-10.338-1.46-15.155-3.427L.336 62.763c-.22.363-.336.773-.336 1.191 0 .419.117.83.338 1.191.22.362.538.663.92.872.382.209.816.32 1.258.32z"/>
        </svg>
        <p class="header">${header}</p>
        <p class="body">${body}</p>
      </div>
    </div>
    </body>
  </html>`;

export default EditBlacklistHTML;
