import { auth, firestore } from "../firebase";
import User from "./Usert";
import { SystemEvent } from "./SystemEvent";
import { Subject } from "./Subject";

interface RegistrationType {
  email?: string;
  subjectID?: string;
  cohort?: string;
  role?: string;
  arm?: string;
  activeProgram?: string;
  zendeskID?: string;
  study?: string;
  firstName?: string;
  lastName?: string;
  site?: string;
  created?: Date;
  updated?: Date;
  uid?: string;
  createdBy?: string;
  updatedBy?: string;
}

export class Registration {
  email: string;
  subjectID: string;
  cohort: string;
  role: string;
  arm: string;
  activeProgram: string;
  zendeskID: string;
  study: string;
  firstName: string;
  lastName: string;
  site: string;
  created: Date;
  updated: Date;
  uid: string;
  createdBy: string;
  updatedBy: string;

  constructor(props: RegistrationType = {}) {
    this.email = props.email ?? "";
    this.subjectID = props.subjectID ?? "default";
    this.cohort = props.cohort ?? "default";
    this.role = props.role ?? "patient";
    this.arm = props.arm ?? "";
    this.study = props.study ?? "003";
    this.zendeskID = props.zendeskID ?? "";
    this.site = props.site ?? "";
    this.firstName = props.firstName ?? "";
    this.lastName = props.lastName ?? "";
    this.created = props.created ?? new Date();
    this.updated = new Date();
    this.activeProgram = props.activeProgram ?? 'tempo1';
    this.updatedBy = props.updatedBy ?  props.updatedBy : auth?.currentUser?.email;
    this.createdBy = props.createdBy ?? this.updatedBy;
    this.uid = props.uid ?? "";
  }

  static fromFirestore(docRef: any) {
    let data = docRef.data();
    if (!data) {
      return new Registration();
    }

    return new Registration({
      email: data.email,
      subjectID: data.subjectID,
      cohort: data.cohort,
      role: data.role,
      arm: data.arm,
      study: data.study,
      zendeskID: data.zendeskID,
      activeProgram: data.activeProgram,
      site: data.site,
      firstName: data.firstName,
      lastName: data.lastName,
      created: data.created ? data.created.toDate() : new Date(),
      updated: data.updated ? data.updated.toDate() : new Date(),
      createdBy: data.updatedBy,
      updatedBy: data.updatedBy,
      uid: data.uid
    });
  }

  static async registrationForEmail(email: string) {
    let reg = await firestore.collection("registrations").doc(email).get();
    if (reg.exists) {
      return Registration.fromFirestore(reg);
    }
    return null;
  }

  matchesFilter(filter: string) {
    if (!filter || filter.length === 0) return true;
    return this.email.toLowerCase().includes(filter.toLowerCase());
  }


  data() {
    let data = JSON.parse(JSON.stringify(this));
    data.created = this.created;
    data.updated = this.updated;
    return data;
  }

  async persist() {
    let data = this.data();
    data.updatedBy = auth?.currentUser?.email ? auth.currentUser.email : "";
    data.updated = new Date();
    let res = await firestore.collection('registrations').doc(this.email)
      .set(data);
    return res;
  }

  async registerUser() {
    const user = await User.userWithEmail(this.email);
    if (!user) {
      SystemEvent.fireEvent("No Matching User", "registration",
        "No user with the following email could be found " + this.email, null, { reg: { ...this } });
    } else {
      await user.updateFromRegistration(this);
      this.uid = user.uid;
      await this.persist();
    }
    let subject = Subject.fromRegistration(this);
    await subject.persist();

    return { subject, user };
  }
}
