import React, {useState} from "react";
import {Link} from "react-router-dom";
import {useCollection, useDocument} from "react-firebase-hooks/firestore";
import LoadingScreen from "../../LoadingScreen";
import TabContainer from "react-bootstrap/TabContainer";
import "../surveyAdherence.css";
import {firestore} from "../../../firebase";
import AdminNavbar from "../../AdminNavbar";
import {useHistory} from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import NavItem from "react-bootstrap/NavItem";

import {Button, Col, Container, Form, FormControl, InputGroup, Jumbotron, Modal, Row, Table,} from "react-bootstrap";
// import AReportTable from "./AReportTable";
import AlargerReportTable004A from "./AlargerReportTable004A";
import BReportTable004A from "./BReportTable004A";
import BReportTable004ACT from "./BReportTable004ACT";
import AReportTable004A from "./AReportTable004A";
import AsmallerReportTable004A from "./AsmallerReportTable004A";
import AnotherReportsListSidebar from "./AnotherReportsListSidebar";

// import AsmallerReportTable from "./AsmallerReportTable";
    // const query = firestore.collection("analytics/reports/reportPages");
    // const [reportPages, reportPagesLoading, reportPagesError] = useCollection(query, {
        // snapshotListenOptions: {includeMetadataChanges: true},
    // });
// const query = firestore.collection("analytics").doc("kpi_summary")
const A004FIQR = (props) => {
 return (<>
        <AdminNavbar/>
        <Jumbotron>
            <Container fluid={true} style={{paddingBottom: "30px"}}>
                <Row>
                    <Col>
                        <h1>FIQR Scores, by Visit</h1>
                    </Col>
                </Row>
            </Container>
        </Jumbotron>
        <AnotherReportsListSidebar/>
       
        <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "40px"}}>
            <div className="article" style={{paddingBottom: "30px", paddingLeft: "40px"}}>
            <div className="TitleC">Mean FIQR Scores
            <span className="TitleDB" style={{fontSize: "13px", paddingLeft: "10px"}} > *excludes s10_008</span>
            </div>
            <AsmallerReportTable004A tableKey={"fiqrBV_004A_means"}></AsmallerReportTable004A>
                </div>
        </Container>
       
        <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "40px"}}>
            <div className="article" style={{paddingBottom: "30px", paddingLeft: "40px"}}>
            <div className="TitleC">ACT FIQR Scores</div>
            <BReportTable004ACT tableKey={"fiqrBV_004A_ACT"}></BReportTable004ACT>
                </div>
        </Container>
  
        <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "40px"}}>
            <div className="article" style={{paddingBottom: "30px", paddingLeft: "40px"}}>
            <div className="TitleC">ST FIQR Scores</div>
            <BReportTable004A tableKey={"fiqrBV_004A_ST"}></BReportTable004A>
                </div>
        </Container>
        {/* <Container fluid={true} style={{backgroundColor: "white"}}>
            <div className="article">
            <p className="TitleC">FIQR Scores</p>
            <BReportTable004A tableKey={"fiqr_scores004"}></BReportTable004A>
                </div>
        </Container> */}
       
      
</>);

};

export default A004FIQR;

