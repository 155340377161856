import { PaymentNotifications, WorkflowType } from '@swing-therapeutics/surveybay/dist/types';
import { useField } from 'formik';
import React, { useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { defaultPayCondition as defaultPayConditionStandard } from '../../../../Model/LandingPage';
import { defaultPayCondition as defaultPayConditionExt } from '../../../../Model/LandingPageExtension';
import PaymentNotification from './PaymentNotification';

interface Props {
  workflowType?: WorkflowType;
}

// Add a props to flag the eligibility and baseline
const PaymentNotificationsDisplay: React.FC<Props> = ({ workflowType = WorkflowType.STANDARD }) => {
  type SelectedWeek = number | 'eligibility' | 'baseline';
  const [fieldInfo, _fieldMeta, fieldHelpers] = useField("paymentNotifications");
  // Default workflow type to 0 (week 1) if not in standard workflow, baseline and eligibility dont exist in extension
  const [selectedWeek, setSelectedWeek] = useState<SelectedWeek>(workflowType === WorkflowType.STANDARD ? 'eligibility' : 0);

  return (
    <Col>
      <h3>Payment notifications</h3>
      <p>Notify in the 'Payments' tab when a user has reached a payment condition</p>
      <Row>
        <Col xs="auto">
          <Button
            onClick={() => {
              const defaultPayCondition = workflowType === WorkflowType.STANDARD ? defaultPayConditionStandard : defaultPayConditionExt
              let newValue = fieldInfo.value as PaymentNotifications;
              if (typeof selectedWeek === 'string') {
                // This is a payment notification for eligibility or baseline
                if (newValue[selectedWeek] !== null) {
                  // Payment notification already exists
                  return;
                }
                newValue[selectedWeek] = defaultPayCondition(`${selectedWeek.substring(0, 1).toUpperCase()}${selectedWeek.substring(1)}`);
              }
              else {
                // Payment notification for a survey week
                if (newValue.surveyWeeks[selectedWeek] !== null) {
                  // Payment notification already exists
                  return;
                }
                newValue.surveyWeeks[selectedWeek] = defaultPayCondition(`Therapy week ${selectedWeek + 1}`);
              }
              fieldHelpers.setValue(newValue)
            }}
          >
            Add payment notification condition
          </Button>
        </Col>
        <Col xs="auto">
          <div className="form-group">
            <select
              className="form-control"
              value={selectedWeek.toString()}
              onChange={(event) => {
                let newValue = event.target.value as SelectedWeek;
                if (!isNaN(newValue as number)) {
                  newValue = parseInt(newValue as string);
                }
                setSelectedWeek(newValue);
              }}
            >
              {/* Include or do not include these two */}
              {
                workflowType === WorkflowType.STANDARD &&
                <>
                  <option value="eligibility">Eligibility</option>
                  <option value="baseline">Baseline</option>
                </>
              }
              {new Array(fieldInfo.value.surveyWeeks.length).fill(0).map((_x, index) => {
                return (
                  <option key={index} value={index}>
                    Week {index + 1}
                  </option>
                )
              })}
            </select>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          {
            fieldInfo.value.eligibility &&
            <PaymentNotification forWeek="eligibility" />
          }
          {
            fieldInfo.value.baseline &&
            <PaymentNotification forWeek="baseline" />
          }
          {(fieldInfo.value as PaymentNotifications)?.surveyWeeks.map((surveyWeek, index) => {
            if (surveyWeek === null) {
              return null;
            }
            return (
              <PaymentNotification key={index} forWeek={index + 1} workflowType={workflowType} />
            )
          })}
        </Col>
      </Row>
    </Col>
  )
}

export default PaymentNotificationsDisplay;
