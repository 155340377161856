import React, {useContext, useState, useEffect} from "react";
import {Redirect} from "react-router-dom";
import {auth} from "../firebase";
import {Route} from "react-router-dom";
import SignIn from "../Components/SignIn";
import {ReactComponent as Loading} from "../Components/Images/loading.svg";
import LoadingScreen from "../Components/LoadingScreen";

const ProtectedRoute = ({component: Component, ...rest}) => {
    const [authenticated, setAuthenticated] = useState(false);
    const [loadingAuth, setLoadingAuth] = useState(true);

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((user) => {
            if (user) {
                setAuthenticated(true);
            } else {
                setAuthenticated(false);
            }
            setLoadingAuth(false);
        });
        return () => unsubscribe();
    }, []);
    return loadingAuth ? (<LoadingScreen></LoadingScreen>) : (<Route
            {...rest}
            render={(props) => authenticated ? <Component {...props} /> : <SignIn></SignIn>}
        />);
};
export default ProtectedRoute;
