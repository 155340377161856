import React, { useEffect, useState } from "react";
import "./surveyAdherence.css";
import { firestore } from "../../firebase";

import { Col, Row, Table } from "react-bootstrap";

const AsmallerReportTable = (props) => {
  const { tableKey } = props;
  const [tableDoc, setTableDoc] = useState();
  const [name, setName] = useState("");
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);

  useEffect(() => {
    firestore
      .doc("analytics/reports/reportTables/" + tableKey)
      .get()
      .then((doc) => {
        const data = doc.data();
        setColumns(data.columns);
        setName(data.name);
        setTableDoc(doc);
        const path = data.collectionPath
          ? data.collectionPath
          : "analytics/reports/reportTables/" + tableKey + "/rows";
        let query = data
          ? firestore
              .collection(path)
              // .orderBy(data.orderBy.field, data.orderBy.direction)
          : firestore.collection(path);
        query.onSnapshot((snap) => {
          setRows(snap.docs);
        });
      });
  }, [tableKey]);

  const getTableRow = (progRef) => {
    let data = progRef.data();

    let cols = columns.map((c) => {
      return <td>{data[c]}</td>;
    });

    return (
      <tr key={progRef.id} onClick={() => {}}>
        {cols}
      </tr>
    );
  };
  if (!(columns && rows)) {
    return <> </>;
  }
  return (
    <>
      
      <Col className={"reports_table"} xs={12}>
        <Table style={{backgroundColor: "white", width: "20%", paddingLeft:"20px"}}>
          <thead key={"tableHead" + (tableDoc ? tableDoc.id : "")}>
            <tr key={"headerRow"}>
              {columns.map((c) => {
                return   <th style={{backgroundColor: "#DBF1F8"}} key={c.id}>{c}</th>;
              })}
            </tr>
          </thead>
          <tbody>{rows.map((u) => getTableRow(u))}</tbody>
        </Table>
      </Col>
      
    </>
  );
};

export default AsmallerReportTable;
