import {
  JournalPhase,
  JournalQuestionType, MultipleInputQuestion
} from "@swing-therapeutics/swingcore/dist/models/journals/JournalInterfaces";
import {
  journalQuestionTypes
} from "@swing-therapeutics/swingcore/dist/models/journals/JournalInterfaces";
import type {
  PacingJournalQuestionType,
  SliderPacingQuestion
} from "@swing-therapeutics/swingcore/dist/models/journals/PacingJournalInterfaces";
import {
  pacingQuestionTypes
} from "@swing-therapeutics/swingcore/dist/models/journals/PacingJournalInterfaces";
import { get, isNil } from "lodash";
import React, { useEffect, useMemo } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { BasicBooleanField, BasicEasyEditField, BasicNumberField, BasicOptionField, BasicTextAreaField, BasicTextField, FieldHeader, formikHasError, FormikHelpers } from "../../../utils/FormikHelpers";
import { useCollectionClassDataOnce } from "../../../utils/Hooks";
import { knownQuestionTypes } from "./types";
import { ValuesCategory } from '@swing-therapeutics/swingcore/dist/models/values/ValuesCategory';
import { getValuesCategoryCollection } from "@swing-therapeutics/swingcore/dist/utils/queries";
import { useRouteMatch } from "react-router-dom";

const defaultMin = 0;
const defaultMax = 512;
const defaultStepSize = 1;
const defaultSliderInitialValue = 0;

export type CombinedQuestionType = JournalQuestionType | PacingJournalQuestionType;

export const matchType = (questionType: CombinedQuestionType, types: CombinedQuestionType | CombinedQuestionType[]) => {
  if (Array.isArray(types)) {
    for (const type of types) {
      if (questionType === type || questionType === `pacing-${type}`) {
        return true;
      }
    }
    return false;
  }

  return questionType === types || questionType === `pacing-${types}`;
}

export const getMinField = (questionType: CombinedQuestionType) => {
  if (questionType === "slider") {
    return "minValue";
  } else if (questionType === "pacing-slider" || questionType === "pacing-summary" || questionType === "pacing-restTimeModal") {
    return "min";
  } else if (matchType(questionType, ["text", "optionalText", "multipleInput"])) {
    return "minLength";
  }
  return null;
};

export const getMaxField = (questionType: CombinedQuestionType) => {
  if (questionType === "slider") {
    return "maxValue";
  } else if (questionType === "pacing-slider" || questionType === "pacing-summary" || questionType === "pacing-restTimeModal") {
    return "max"
  } else if (matchType(questionType, ["text", "optionalText", "multipleInput"])) {
    return "maxLength";
  }
  return null;
};

const ValidationFields = ({ formikHelpers }: { formikHelpers: FormikHelpers }) => {
  const { values, setFieldValue } = formikHelpers;

  const { questionType } = values;

  useEffect(() => {
    const minField = getMinField(questionType);
    const maxField = getMaxField(questionType);
    if (minField !== null && maxField !== null) {
      setFieldValue('validation', {
        [minField]: values.validation?.[minField] ?? defaultMin,
        [maxField]: values.validation?.[maxField] ?? defaultMax,
        ...(matchType(questionType, ["slider", "pacing-summary", "pacing-multipleInput", "pacing-restTimeModal"]) && {
          initialValue: (values as SliderPacingQuestion).validation?.initialValue ?? defaultSliderInitialValue,
          discreteStepSize: (values as SliderPacingQuestion).validation?.discreteStepSize ?? defaultStepSize,
        }),
        ...(matchType(questionType, "multipleInput") && {
          numInputs: (values as MultipleInputQuestion).validation?.numInputs ?? 0,
          minResponses: (values as MultipleInputQuestion).validation?.minResponses ?? 0,
        })
      })
    }

  }, [questionType]);

  return (
    <>
      {(matchType(questionType, ["text", "optionalText", "slider", "multipleInput", "pacing-summary", "pacing-restTimeModal"]) || questionType === "pacing-summary") && (
        <>
          <BasicNumberField path={`validation.${getMinField(questionType)}`} title="Minimum Value/Characters" defaultValue={defaultMin} formikHelpers={formikHelpers} tooltip="For sliders, this is the minimum value. For inputs, this is the minimum character amount." />
          <BasicNumberField path={`validation.${getMaxField(questionType)}`} title="Maximum Value/Characters" defaultValue={defaultMax} formikHelpers={formikHelpers} tooltip="For sliders, this is the maximum value. For inputs, this is the maximu, character amount." />
        </>
      )}
      {matchType(questionType, ["slider", "pacing-summary", "pacing-restTimeModal"])&& (
        <>
          <BasicNumberField path="validation.initialValue" title="Initial Value" defaultValue={defaultSliderInitialValue} formikHelpers={formikHelpers} />
          <BasicNumberField path="validation.discreteStepSize" title="Step Size" defaultValue={defaultStepSize} formikHelpers={formikHelpers} />
        </>
      )}

      {matchType(questionType, "multipleInput") &&
            <>
              {new Array(formikHelpers.values?.validation?.numInputs || 0)
                .fill(null)
                .map((_, index) => (
                  <BasicTextField
                    key={`numInputs-${index}`}
                    path={`screenText.placeholderTexts[${index}]`}
                    title={`Placeholder for input #${index + 1}`}
                    formikHelpers={formikHelpers}
                  />
                ))}
              <BasicNumberField path={"validation.numInputs"} title="Number of inputs" formikHelpers={formikHelpers} tooltip="How many text boxes the user will see" />
              <BasicNumberField path={"validation.minResponses"} title="Minimum Responses" formikHelpers={formikHelpers} tooltip="Minimum number of responses the user must input" />
            </>
      }
    </>
  );
};

interface ScreenTextFieldsProps {
  formikHelpers: FormikHelpers;
  phase: string;
  handleTypeChange: (event: any) => void;
  isPacingJournal: boolean;
  isInsights: boolean;
}

const ScreenTextFields = ({
  formikHelpers,
  phase,
  handleTypeChange,
  isPacingJournal,
  isInsights,
}: ScreenTextFieldsProps) => {
  const routeMatch = useRouteMatch("/programs/:programKey/journals/:journalID");
  const [valuesCategoriesData] = useCollectionClassDataOnce(ValuesCategory, getValuesCategoryCollection(routeMatch.params["programKey"]));
  const valuesCategoriesOptions = useMemo(() => valuesCategoriesData.map(valuesCategory => ({ name: valuesCategory.name, value: valuesCategory.id })), [valuesCategoriesData]);

  const isInvalid = (fieldName: string) => {
    return get(formikHelpers.errors, fieldName) && (get(formikHelpers.touched, fieldName) || formikHelpers.touched.type);
  };

  const questionTypes = (() => {
    // Pacing journals are a little weird. The creation phase only uses special pacing question types,
    // while the CheckIn and Conversion flows can use a combination of pacing and normal question types.
    // If we ever make a program after tempoACT3, this would be a good time to clean up the tech debt.
    if (isPacingJournal) {
      if (phase === JournalPhase.Creation) {
        return pacingQuestionTypes;
      } else {
        return ([...pacingQuestionTypes, ...journalQuestionTypes] as ReadonlyArray<string>);
      }
    } else {
      return journalQuestionTypes;
    }
  })();

  const { questionType } = formikHelpers.values;

  return (
    <>
      <Form.Group as={Col} md={12} lg={6}>
        <Form.Label>Type</Form.Label>
        <Form.Control
          as="select"
          name="type"
          data-testid="type"
          value={questionType}
          onChange={handleTypeChange}
          isInvalid={isInvalid("type")}
        >
          {questionTypes.filter(questionType => knownQuestionTypes.has(questionType)).map((questionType) => (
            <option
              key={questionType}
              value={questionType}
            >
              {questionType}
            </option>
          ))}
        </Form.Control>
        <Form.Control.Feedback type="invalid">{formikHelpers.errors.type}</Form.Control.Feedback>
      </Form.Group>

      <BasicTextField path="key" title="Key" formikHelpers={formikHelpers} tooltip="For internal use, must be unique among the other questions in this phase" />
      <BasicTextField path="screenText.heading" title="Heading" formikHelpers={formikHelpers} tooltip="Large text near the top of the screen" />
      {matchType(questionType, ["pacing-planPicker", "pacing-multipleInput"]) && (
        <BasicTextField path="screenText.footer" title="Footer" formikHelpers={formikHelpers} tooltip="Text that shows up above the prev/next buttons" />
      )}
      <BasicTextField path="screenText.navigationTitle" title="Navigation Title" formikHelpers={formikHelpers} tooltip="Text at the top of the screen between home and ··· buttons" />
      <BasicTextField path="screenText.PrevButtonText" title="Previous Button Text" formikHelpers={formikHelpers} tooltip="Button that takes the user back to the previous screen (e.g. 'Back')" />
      <BasicTextField path="screenText.NextButtonText" title="Next Button Text" formikHelpers={formikHelpers} tooltip="Button that takes user to the next screen (e.g. 'Next')" />

      {matchType(questionType, ["optionalText", "boolean"]) && (
        <>
          <BasicTextField path="screenText.positiveText" title="Positive Text" formikHelpers={formikHelpers} tooltip="Response to prompt (e.g. 'Yes')" />
          <BasicTextField path="screenText.negativeText" title="Negative Text" formikHelpers={formikHelpers} tooltip="Responst to prompt (e.g. 'No')" />
        </>
      )}

      {matchType(questionType, ["slider", "text", "optionalText"]) && (
        <BasicTextField path="graphName" title="Graph Title" formikHelpers={formikHelpers} tooltip="Title when viewing response to this question on a graph. Only required if this is going to be graphed" />
      )}

      {matchType(questionType, ["content", "summary"]) && (
        <BasicTextField path="screenText.imageURL" title="Image URL" formikHelpers={formikHelpers} tooltip="Usually the filename of the image" />
      )}

      {matchType(questionType, "dartboard") && (
        <BasicNumberField path="quadrant" title="Quadrant" formikHelpers={formikHelpers} tooltip="Starts in the top right and goes counter clockwise (based on quadrant system in geometry)" />
      )}

      {matchType(questionType, ["summary_dartboard_v2"]) && (
        <BasicTextField path="screenText.dartboardText" title="Dartboard Text" formikHelpers={formikHelpers} tooltip="Purple text under the dartboard" />
      )}

      {matchType(questionType, ["content", "dartboard", "summary_dartboard_v2", "enableReminders"]) && (
        <BasicTextAreaField path="screenText.content" title="Content" rows={10} formikHelpers={formikHelpers} />
      )}

      {questionType === "pacing-content" && (
        <BasicEasyEditField path="screenText.callout" title="Callout" formikHelpers={formikHelpers} markupType="markdown" tooltip="An example Pacing plan. Supports Markdown" />
      )}

      {(matchType(questionType, ["text", "content", "dartboard", "dartboard_v2", "optionalText", "boolean", "summary", "valuesEntry", "multipleInput", "sleepPlan", "pacing-planPicker"]) || questionType === 'slider') && (
        <BasicTextAreaField rows={3} path="screenText.promptText" title="Prompt Text" formikHelpers={formikHelpers} />
      )}

      {matchType(questionType, ["pacing-slider", "pacing-restTimeModal"]) && (
        <BasicTextAreaField path="screenText.promptText1" title="Prompt Text 1" formikHelpers={formikHelpers} rows={4} tooltip="Larger prompt that explains question in detail, at the top below heading" />
      )}

      {matchType(questionType, ["pacing-slider", "pacing-summary", "pacing-restTimeModal"]) &&
        <BasicTextField path="screenText.promptText2" title="Prompt Text 2" formikHelpers={formikHelpers} tooltip="Smaller prompt just above the slider" />
      }

      {matchType(questionType, ["pacing-slider", "pacing-restTimeModal"]) && (
        <>
          <BasicTextField path="screenText.unitSingular" title="Units (Singular)" formikHelpers={formikHelpers} tooltip="e.g. minute" />
          <BasicTextField path="screenText.unitPlural" title="Units (Plural)" formikHelpers={formikHelpers} tooltip="e.g. minutes" />
        </>
      )}

      {matchType(questionType, ["slider", "pacing-summary", "pacing-restTimeModal"]) && (
        <BasicTextField path="screenText.scale" title="Scale Label" formikHelpers={formikHelpers} tooltip="Scale below slider (e.g. '0 = Very Easy; 10 = Extremely Difficult')" />
      )}

      {matchType(questionType, ["text", "slider", "optionalText"]) && (
        <>
          <BasicTextField path={isPacingJournal ? "screenText.summaryText" : "summaryText"} title="Summary Text" formikHelpers={formikHelpers} tooltip="Used before response in summary. such as 'for ' or 'and then rest for'" />

          {questionType === 'pacing-slider' &&
            <BasicTextField path="screenText.summaryEndText" title="Summary End Text" formikHelpers={formikHelpers} tooltip="Used after response in summary, generally a comma, period, or statement such as 'times in a row'" />
          }
        </>
      )}

      {matchType(questionType, ["text", "optionalText"]) &&
        <BasicTextField path="screenText.placeholderText" title="Placeholder Text" formikHelpers={formikHelpers} tooltip="The text shown in the text box when the user hasn't entered a response yet" />
      }

      {isInsights && matchType(questionType, 'summary') &&
        <>
          <BasicTextField path="screenText.preSummaryText" title="Pre-Summary Text" formikHelpers={formikHelpers} tooltip="Text above summary (e.g. 'Here are your insights so far:')" />
          <BasicTextField path="screenText.postSummaryText" title="Post-SummaryText" formikHelpers={formikHelpers} tooltip="Text below summary (e.g. 'Tap 'Finish' to complete your insights')" />
        </>
      }

      {questionType === "text" && (
        <BasicBooleanField path="displayValuesReference" title="Display Values Reference" formikHelpers={formikHelpers} tooltip="Toggles displaying values categories under input box" />
      )}

      {matchType(questionType, ['dartboard_v2', 'valuesEntry'])&& (
        <BasicOptionField path="valuesCategoryID" title="Values Category" formikHelpers={formikHelpers} options={valuesCategoriesOptions} undefinedOption="Choose a values category..." />
      )}

      {matchType(questionType, ["valuesEntry", "multipleInput"]) && (
          <BasicTextField path="screenText.defaultPlaceholder" title="Default input placeholder" formikHelpers={formikHelpers} tooltip="Default placeholder (e.g. 'Optional')" />
      )}

      {matchType(questionType, "valuesEntry") && getValuesCategoryFields(valuesCategoriesData, formikHelpers)}

      {matchType(questionType, "sleepPlan") &&
      <>
      {[['bedTime', 'Bed Time'], ['wakeUpTime', 'Wake Up Time'], ['windDownTime', 'Wind Down Time']].map(([sectionKey, sectionTitle]) => (
         <Form.Group key={`sleep-${sectionKey}`} as={Col} md={12} lg={6}>
           <h4>{sectionTitle}</h4>
           <BasicTextField path={`screenText.${sectionKey}.title`} title="Title" formikHelpers={formikHelpers} />
           <BasicTextField path={`screenText.${sectionKey}.promptText`} title="Prompt Text" formikHelpers={formikHelpers} />
           <BasicTextField path={`screenText.${sectionKey}.orderErrorText`} title="Order error text" tooltip='Text to display when the reminder time is out of order' formikHelpers={formikHelpers} />
           <BasicTextField path={`screenText.${sectionKey}.timeBetweenErrorText`} title="Time between error text" tooltip='Text to display when the reminder does not have enough time between another reminder' formikHelpers={formikHelpers} />
           <BasicTextField path={`screenText.${sectionKey}.reminderBoxTitle`} title="Reminder Box Title" formikHelpers={formikHelpers} />
           <BasicTextField path={`screenText.${sectionKey}.reminderBoxCTA`} title="Reminder Box CTA" tooltip="Tappable text that opens the time picker" formikHelpers={formikHelpers} />
           <BasicTextField path={`screenText.${sectionKey}.reminderBoxCheckbox`} title="Reminder Box Checkbox" tooltip="Text beside of checkbox to turn on reminders" formikHelpers={formikHelpers} />
           <BasicTextField path={`screenText.${sectionKey}.notificationTitle`} title="Notification title" formikHelpers={formikHelpers} />
           <BasicTextField path={`screenText.${sectionKey}.notificationBody`} title="Notification body" formikHelpers={formikHelpers} />
           <BasicTextField path={`screenText.${sectionKey}.notificationUrl`} title="Notification url" tooltip="An in-app url that tells the app where to go to. This is not a web page url." formikHelpers={formikHelpers} />
         </Form.Group>
      ))}
      </>}

      {matchType(questionType, "pacing-planPicker") && 
        <>
          <BasicTextField path="screenText.taskBasedPacingDescription" title="Task-Based Pacing Description" formikHelpers={formikHelpers} />
          <BasicTextField path="screenText.timeBasedPacingDescription" title="Time-Based Pacing Description" formikHelpers={formikHelpers} />
        </>
      }

      {matchType(questionType, "pacing-restTimeModal") && 
        <>
          <BasicTextField path="screenText.modalPromptText" title="Modal Prompt Text" formikHelpers={formikHelpers} />
          <BasicTextField path="screenText.checkboxText" title="Checkbox Text" formikHelpers={formikHelpers} />
        </>
      }

      <BasicBooleanField path="firstTimeViewOnly" title="First Time View Only" formikHelpers={formikHelpers} />
      <BasicBooleanField path="showNotificationPermissionAlert" title="Show Notification Permission Alert?" formikHelpers={formikHelpers} tooltip="Show an in-app alert to enable push notifications (if not already enabled). This is for system level notifications, not reminders" />
    </>
  );
};

const PinkEyeFields = ({formikHelpers}: {formikHelpers: FormikHelpers}) => {
  return (
    <>
      <BasicBooleanField path="hasPinkEye" title="Has pink 'i'?" formikHelpers={formikHelpers}/>
      {get(formikHelpers.values, 'hasPinkEye') && (
        <>
          <BasicTextField path="pinkEye.contentTitle" title="Pink 'i' Title" formikHelpers={formikHelpers}/>
          <BasicEasyEditField path="pinkEye.content" title="Pink 'i' Content" markupType="markdown" formikHelpers={formikHelpers}/>
          <BasicBooleanField path="pinkEye.skipFirstTime" title="Skip pink 'i' first time" formikHelpers={formikHelpers}/>
        </>
      )}
    </>
  )
}

export { ValidationFields, ScreenTextFields, PinkEyeFields };

const getValuesCategoryFields = (valuesCategoriesData: ValuesCategory[], formikHelpers: FormikHelpers) => {
  const currentCategory = valuesCategoriesData.find(category => category.id === formikHelpers?.values?.valuesCategoryID)

  if (isNil(currentCategory)) {
    return (
      <Row className="ml-2" xs={12}>
        <Col className="d-flex align-items-center text-warning">
          *Please Select A Values Category ID*
        </Col>
      </Row>
    )
  }

  //Create array of values, 0...(maxValues - 1)
  const entryNumbers = Array.from(Array(currentCategory.entryValidation.maxValues).keys());
  return entryNumbers.map(entryNumber => (
    <BasicTextField key={`entry-${entryNumber}`} path={`screenText.placeholderTexts[${entryNumber}]`} title={`Placeholder text entry #${entryNumber + 1}`} formikHelpers={formikHelpers} tooltip="Example of a value (e.g. 'Be kind to myself')" />
  )) 
}
