import { useFormik } from "formik";
import React from "react";
import { Button, Col, Form, Modal } from "react-bootstrap";
import { auth } from "firebase";
import {
  BlacklistedVersion,
  Platform,
} from "../../../Model/BlacklistedVersion";
import * as Yup from "yup";

interface AddVersionModalProps {
  close: () => void;
  versions: BlacklistedVersion[];
}

interface InitialValueType {
  fullVersionName: string;
  platform: string;
  reason: string;
}

const AddVersionModal = ({ versions, close }: AddVersionModalProps) => {
  const initialValues: InitialValueType = {
    fullVersionName: "",
    platform: "",
    reason: "",
  };
  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      fullVersionName: Yup.string().required("Version Name Required"),
      platform: Yup.string()
        .required("Platform Required")
        .test(
          "Is ios or android",
          "${path} isn't ios or android",
          (value) => value === "ios" || value === "android"
        ),
      reason: Yup.string().required("Reason Required"),
    }),
    onSubmit: async (values) => {
      const blacklistVersion = BlacklistedVersion.fromParams(
        values.fullVersionName,
        values.reason,
        values.platform as Platform,
        new Date(),
        auth().currentUser.email
      );

      await blacklistVersion.persist();

      close();
    },
  });

  const getError = (errors: any) => {
    for (let error in errors) {
      return <div style={{ color: "red" }}>{`${errors[error]}`}</div>;
    }
    return <></>;
  };

  return (
    <>
      <Modal show onHide={close}>
        <Modal.Header closeButton>
          <Modal.Title>Add Version</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Row>
            <Form.Group as={Col} xs={12}>
              <Form.Label>Full Version Name</Form.Label>
              <Form.Control
                name="fullVersionName"
                value={formik.values.fullVersionName}
                onChange={formik.handleChange}
                placeholder={"Version name"}
              />
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} xs={5}>
              <Form.Label>Platform</Form.Label>
              <Form.Control
                name="platform"
                value={formik.values.platform}
                onChange={formik.handleChange}
                placeholder={"Platform"}
              />
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} xs={5}>
              <Form.Label>Reason</Form.Label>
              <Form.Control
                name="reason"
                value={formik.values.reason}
                onChange={formik.handleChange}
                placeholder={"Reason"}
              />
            </Form.Group>
          </Form.Row>
        </Modal.Body>
        <Modal.Footer>
          <Col>{getError(formik.errors)}</Col>
          <Button onClick={() => close()} variant="secondary">
            Close
          </Button>
          <Button onClick={() => formik.submitForm()} variant="success">
            Save changes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddVersionModal;
