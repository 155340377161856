import React, { useEffect, useState } from "react";
import { firestore } from "../../firebase";
import { Table } from "react-bootstrap";

const ScreeningLogs = (props: any) => {
  const [regLogs, setRegLogs] = useState<any[]>([]);

  useEffect(() => {
    const subscriber = firestore
      .collection("systemevents")
      .where("category", "==", "004-reg")
      .orderBy("occurred", "desc")
      .onSnapshot(
        (snap: any) => {
          let users: any[] = [];

          snap.docs.forEach((u: any) => {
            users.push(u);
          });
          setRegLogs(users);
        },
        (error) => {
          console.log(error);
        }
      );
    return () => subscriber();
  }, []);

  const getTableRow = (log: any) => {
    const data = log.data();
    return (
      <tr>
        <td>
          {data.subjectID
            ? data.subjectID
            : data.registration?.subjectID
            ? data.registration.subjectID
            : ""}
        </td>
        <td>{data.type}</td>
        <td>{data.message}</td>
        <td>{data.performedBy}</td>
        <td>{data.occurred?.toDate().toLocaleString()}</td>
      </tr>
    );
  };

  return (
    <Table responsive striped>
      <thead>
        <tr>
          <th>Subject ID</th>
          <th>Event type</th>
          <th>Message</th>
          <th>Performed By</th>
          <th>Time</th>
        </tr>
      </thead>
      <tbody>{regLogs.map((u) => getTableRow(u))}</tbody>
    </Table>
  );
};

export { ScreeningLogs };
