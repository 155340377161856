import React, { useEffect, useState } from "react";
import LoadingScreen from "../LoadingScreen";
import { DocumentData, firebase, firestore } from "../../firebase";
import AdminNavbar from "../AdminNavbar";
import { RouteComponentProps, useHistory } from "react-router-dom";

import {
  Col, Container, Form, FormControl, Jumbotron, Row, Table,
} from "react-bootstrap";
import moment from "moment";
import { useCollectionClassData } from "../../utils/Hooks";
import { Registration } from "../../Model/Registration";

interface UserRowProps {
  userRef: DocumentData;
  filter: string;
}

const UserRow: React.FC<UserRowProps> = ({ userRef, filter }) => {
  const history = useHistory();
  const user = userRef.data();

  if (filter && !user?.email?.toLowerCase().includes(filter) && !user?.displayName?.toLowerCase().includes(filter) && !user?.subjectID?.toLowerCase().includes(filter)) {
    return <></>;
  }
  let regName = !user.firstName ? user.displayName : user.displayName + " (" + user.firstName + " " + user.lastName + ")";

  const handleRowClick = () => {
    history.push("/users/" + user.uid);
  }
  return (
    <tr className={"hoverablerow "} onClick={handleRowClick}>
      <td>
        {user.email ? user.email : null}
      </td>
      <td>
        {user.displayName ? user.displayName : null}
      </td>
      <td>
        {user.created ? moment(user.created.toDate()).format("DD MMM, yyyy hh:mm:ss a") : null}
      </td>
      <td>
        {user.lastLogon ? moment(user.lastLogon.toDate()).format("DD MMM, yyyy hh:mm:ss a") : null}
      </td>
      <td>
        {user.uid ? user.uid : null}
      </td>
      <td>
        {user.UTCOffset ? user.UTCOffset : null}
      </td>
    </tr>
  );
};

const UnregisteredUsersList: React.FC<RouteComponentProps> = () => {
  const [filter, setFilter] = useState("");
  const [users, setUsers] = useState<firebase.firestore.DocumentData>(null);

  useEffect(() => {
    let query = firestore.collection("users").orderBy("email", "asc");

    const subscriber = query.onSnapshot((snap) => {
      setUsers(snap);
    }, (error) => {
      console.log("error occured loading users", error);
    });
    return subscriber;
  }, []);

  const registrationRecords = firestore.collection("registrations")

  const [registration] =
    useCollectionClassData(Registration, registrationRecords);

  const emails = []
  for (let i = 0; i < registration.length; i++) {
    emails.push(registration[i].email)
  }
  return (
    <>
      <AdminNavbar />
      <Jumbotron>
        <Container fluid={true}>
          <Row>
            <Col>
              <h1>Unregistered Users</h1>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form>
                <Form.Row>
                  <Col xs={5} md={4} lg={3}>
                    <Form.Label> Search </Form.Label>

                    <Form.Group className="">
                      <FormControl
                        id="search"
                        placeholder="Search"
                        onChange={(e) => {
                          setFilter(e.target.value.trim());
                        }}
                      />
                    </Form.Group>
                  </Col>
                </Form.Row>
              </Form>
            </Col>
          </Row>
        </Container>
      </Jumbotron>
      <Container fluid={true} style={{ backgroundColor: "white" }}>
        <Row>
          {!users && (
            <>
              <strong>Subjects Loading</strong>
              <br></br>
              <LoadingScreen></LoadingScreen>
            </>
          )}
          {!!users && (
            <Col xs={12}>
              <Table striped responsive>
                <thead key="systemEventsTable">
                  <tr>
                    <th>Email</th>
                    <th>Display Name</th>
                    <th>Created</th>
                    <th>Last Logon</th>
                    <th>Uid</th>
                    <th>UTCOffset</th>
                  </tr>
                </thead>
                <tbody>
                  {users.docs.map((u: DocumentData) => {
                    const userd = u.data()
                    if (!emails.includes(userd.email))
                      return (
                        <UserRow
                          userRef={u}
                          filter={filter}
                          key={u.uid}
                        />
                      )
                  })}
                </tbody>
              </Table>
            </Col>
          )}
        </Row>
      </Container>
    </>
  );
};

export default UnregisteredUsersList;
