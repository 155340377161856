import React, {useState} from "react";
import {Link} from "react-router-dom";
import {useCollection, useDocument} from "react-firebase-hooks/firestore";
import LoadingScreen from "../../LoadingScreen";
import TabContainer from "react-bootstrap/TabContainer";
import "../surveyAdherence.css";
import {firestore} from "../../../firebase";
import AdminNavbar from "../../AdminNavbar";
import {useHistory} from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import NavItem from "react-bootstrap/NavItem";

import {Button, Col, Container, Form, FormControl, InputGroup, Jumbotron, Modal, Row, Table,} from "react-bootstrap";
// import AReportTable from "./AReportTable";
import AlargerReportTable004A from "./AlargerReportTable004A";
import BReportTable004A from "./BReportTable004A";
import BReportTable004ACT from "./BReportTable004ACT";
import CmReportTable004A from "./CmReportTable004A";
import AReportTable004A from "./AReportTable004A";
import AsmallerReportTable004A from "./AsmallerReportTable004A";
import AnotherReportsListSidebar from "./AnotherReportsListSidebar";


const A004fivefacets_TW = (props) => {
 return (<>
		<AdminNavbar/>
		<Jumbotron>
			<Container fluid={true} style={{paddingBottom: "30px"}}>

				<Row>
					<Col>
						<h1>Five Facets of Mindfulness, by Therapy Week</h1>
					</Col>
				</Row>
			</Container>
		</Jumbotron>
		<AnotherReportsListSidebar/>
	
	   
		{/* <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "30px"}}>
			<div className="article" style={{paddingBottom: "30px", paddingLeft: "30px"}}>
			<div className="TitleC">ACT FFQM Acting with Awareness</div> <div className="norms">8 questions, 1-5pts, max=40; goal: increased scores</div>
			<BReportTable004ACT tableKey={"fivefacetsAwA_004A_ACT"}></BReportTable004ACT>
				</div>
		</Container>
		<Container fluid={true} style={{backgroundColor: "white", paddingLeft: "30px"}}>
			<div className="article" style={{paddingBottom: "30px", paddingLeft: "30px"}}>
			<div className="TitleC">ST FFQM Acting with Awareness</div> <div className="norms">8 questions, 1-5pts, max=40; goal: increased scores</div>
			<BReportTable004A tableKey={"fivefacetsAwA_004A_ST"}></BReportTable004A>
				</div>
		</Container> */}


		{/* <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "30px"}}>
			<div className="article" style={{paddingBottom: "30px", paddingLeft: "30px"}}>
			<div className="TitleC">ACT Nonjudging</div> <div className="norms">8 questions, 1-5pts, max=40; goal: decreased scores</div>
			<BReportTable004ACT tableKey={"fivefacetsNJ_004A_ACT"}></BReportTable004ACT>
				</div>
				</Container>
		<Container fluid={true} style={{backgroundColor: "white", paddingLeft: "30px"}}>
			<div className="article" style={{paddingBottom: "30px", paddingLeft: "30px"}}>
			<div className="TitleC">ST FFQM Nonjudging</div> <div className="norms">8 questions, 1-5pts, max=40; goal: decreased scores</div>
			<BReportTable004A tableKey={"fivefacetsNJ_004A_ST"}></BReportTable004A>
				</div>
		</Container> */}
		
		<Container fluid={true} style={{backgroundColor: "white", paddingLeft: "30px"}}>
			<div className="article" style={{paddingBottom: "30px", paddingLeft: "30px"}}>
			<div className="TitleC">ACT FFQM Observing</div> <div className="norms">8 questions, 1-5pts, max=40; goal: increased scores</div>
			<BReportTable004ACT tableKey={"fivefacetsOBS_004A_ACT"}></BReportTable004ACT>
				</div>
				</Container>
		<Container fluid={true} style={{backgroundColor: "white", paddingLeft: "30px"}}>
			<div className="article" style={{paddingBottom: "30px", paddingLeft: "30px"}}>
			<div className="TitleC">ST FFQM Observing</div> <div className="norms">8 questions, 1-5pts, max=40; goal: increased scores</div>
			<BReportTable004A tableKey={"fivefacetsOBS_004A_ST"}></BReportTable004A>
				</div>
		</Container>

		<Container fluid={true} style={{backgroundColor: "white", paddingLeft: "30px"}}>
			<div className="article" style={{paddingBottom: "30px", paddingLeft: "30px"}}>
			<div className="TitleC">ACT FFQM Nonreactive</div> <div className="norms">7 questions, 1-5pts, max=35; goal: increased scores</div>
			<BReportTable004ACT tableKey={"fivefacetsNR_004A_ACT"}></BReportTable004ACT>
				</div>
				</Container>
		<Container fluid={true} style={{backgroundColor: "white", paddingLeft: "30px"}}>
			<div className="article" style={{paddingBottom: "30px", paddingLeft: "30px"}}>
			<div className="TitleC">ST FFQM Nonreactive</div> <div className="norms">7 questions, 1-5pts, max=35; goal: increased scores</div>
			<BReportTable004A tableKey={"fivefacetsNR_004A_ST"}></BReportTable004A>
				</div>
		</Container>
		{/* <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "30px"}}>
			<div className="article" style={{paddingBottom: "30px", paddingLeft: "30px"}}>
			<div className="TitleC">ACT FFQM Description</div> <div className="norms">8 questions, 1-5pts, max=40; goal: increased scores</div>
			<BReportTable004ACT tableKey={"fivefacetsDSC_004A_ACT"}></BReportTable004ACT>
				</div>
				</Container>
		<Container fluid={true} style={{backgroundColor: "white", paddingLeft: "30px"}}>
			<div className="article" style={{paddingBottom: "30px", paddingLeft: "30px"}}>
			<div className="TitleC">ST FFQM Description</div> <div className="norms">8 questions, 1-5pts, max=40; goal: increased scores</div>
			<BReportTable004A tableKey={"fivefacetsDSC_004A_ST"}></BReportTable004A>
				</div>
		</Container> */}
	

	
</>);

};

export default A004fivefacets_TW;




