import { firestore } from 'firebase'
import React, { useEffect, useState } from 'react'
import { Col, Row, Container, Button, Table } from 'react-bootstrap'
import useRole from '../../../UseRole'
import LoadingScreen from '../../LoadingScreen'
import { FavoritesEditor } from './FavoritesEditor'
import { FavoritesList } from '@swing-therapeutics/swingcore/dist/models/FavoritesList';
import { Link, Route, Switch, useHistory, useRouteMatch } from 'react-router-dom'
import { getFavoritesListsTemplates } from '@swing-therapeutics/swingcore/dist/utils/queries'

interface FavoritesListProps {
  programKey: string;
}

export const FavoritesListScreen = ({ programKey }: FavoritesListProps) => {

  const isAdmin = useRole("admin");
  const history = useHistory();
  const { url } = useRouteMatch("/programs/:programID/favorites")

  const [favorites, setFavorites] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = getFavoritesListsTemplates(programKey)
      .onSnapshot(snapshot => {
        setLoading(false);
        if (!snapshot.empty) {
          setFavorites(snapshot.docs.map(doc => FavoritesList.fromFirestore(doc)))
        };
      });

    return unsubscribe;
  }, [])

  const getTableRow = (favorite: FavoritesList) => (
    <tr key={favorite.id}>
      <td>{favorite.id}</td>
      <td>{favorite.name}</td>
      <td>
        <Link to={url + '/edit/' + favorite.id}>
          <Button>Edit</Button>
        </Link>
        <Button
          className="ml-2"
          onClick={() => {
            window.confirm(`Are you sure you want to delete ${favorite.name}?`) && FavoritesList.deleteFavoritesList(favorite.id, favorite.programKey)
          }} variant="danger">
          Delete
          </Button>
      </td>
    </tr>
  )

  if (loading) {
    return (
      <Container>
        <LoadingScreen />
      </Container>
    )
  }

  return (
    <Switch>
      <Route exact path={url}>
        <Container fluid>
          <Col>
            <Row>
              {isAdmin &&
                <Col>
                  <Link to={url + '/new'}>
                    <Button
                      variant="outline-primary"
                      style={{ marginBottom: 10 }}
                    >
                      Add New Favorite
                    </Button>
                  </Link>
                </Col>
              }
            </Row>
            <Table bordered hover responsive className="table-striped">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Name</th>
                  <th style={{width: 156}}>Actions</th>
                </tr>
              </thead>
              <tbody>
                {favorites.map(favorite => getTableRow(favorite))}
              </tbody>
            </Table>
          </Col>
        </Container>

      </Route>
      <Route exact={false} path={[url + '/edit/(.*)', url + '/new']}>
        <FavoritesEditor
          programKey={programKey}
          close={() => history.goBack()}
        />
      </Route>
    </Switch>
  )
}
