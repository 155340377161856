import React, {useState} from "react";
import {useCollection} from "react-firebase-hooks/firestore";
import {SystemEvent} from "../../Model/SystemEvent";
import LoadingScreen from "../LoadingScreen";

import {firestore} from "../../firebase";
import AdminNavbar from "../AdminNavbar";
import {useHistory} from "react-router-dom";

import {Button, Col, Container, Form, FormControl, InputGroup, Jumbotron, Row, Table,} from "react-bootstrap";

const SurveyList = () => {
    const [filter, setFilter] = useState("");

    let history = useHistory();
    let query = firestore.collection("analytics")
        .doc('SurveyMonkeyData')
        .collection('byEmail')

    const [surveyUsers, surveyUsersLoading, surveyUsersError] = useCollection(query, {
        snapshotListenOptions: {includeMetadataChanges: true},
    });

    const getTableRow = (sysEvent) => {
        let data = sysEvent.data();

        if (!sysEvent.id.toLowerCase().includes(filter.toLowerCase()) || data.userExists || (!data.baseline && !data.screening)) {
            return <></>;
        }

        let baseline = data.baseline ? new Date(data.baseline).toLocaleString() : "";
        baseline = data.baseline_status ? "(" + data.baseline_status + ") " + baseline : baseline;
        let screening = data.screening ? new Date(data.screening).toLocaleString() : "";
        screening = data.screening_status ? "(" + data.screening_status + ") " + screening : screening;


        return (<tr>
            <td>{sysEvent.id}</td>
            <td>{screening}</td>

            <td>{baseline}</td>


        </tr>);
    };

    return (<>
        <AdminNavbar></AdminNavbar>
        <Jumbotron>
            <Container fluid={true}>
                <Row>
                    <Col>
                        <h1>Baseline and Screening Survey Status</h1>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form inline>
                            <InputGroup className="">
                                <FormControl
                                    id="search"
                                    placeholder="Search"
                                    onChange={(e) => {
                                        setFilter(e.target.value.trim());
                                    }}
                                />
                            </InputGroup>
                        </Form>
                    </Col>
                    <Col xs="auto">
                        <Form inline>
                            <InputGroup className="">
                                <Button
                                    onClick={() => {
                                        history.push("/subjects/new");
                                    }}
                                >
                                    Create
                                </Button>
                            </InputGroup>
                        </Form>
                    </Col>
                </Row>
            </Container>
        </Jumbotron>
        <Container style={{backgroundColor: "white"}}>
            <Row>
                {surveyUsersError && (<>
                    <strong>Error: {JSON.stringify(surveyUsersError)}</strong>
                    <br></br>
                </>)}
                {surveyUsersLoading && (<>
                    <strong>Subjects Loading: {JSON.stringify(surveyUsersError)}</strong>
                    <br></br>
                    <LoadingScreen></LoadingScreen>
                </>)}
                {surveyUsers && (<Col xs={12}>
                    <Table striped={true}>
                        <thead key="systemEventsTable">
                        <tr>
                            <th>Email</th>
                            <th>Screening</th>

                            <th>Baseline</th>


                        </tr>
                        </thead>
                        <tbody>
                        {surveyUsers.docs.map((u) => getTableRow(u))}
                        </tbody>
                    </Table>
                </Col>)}
            </Row>
        </Container>
    </>);
};

export default SurveyList;
