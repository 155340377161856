import { useField } from 'formik';
import React, { useEffect, useMemo, useState } from 'react';
import { useAudioFiles } from '../../../hooks/firebase-storage';
import { formatAudioFile } from '../../../utils/Format';
import FormikSelect, { FormikSelectProps } from '../FormikSelect';

// Options will be prefilled here
interface Props extends Omit<FormikSelectProps, 'options'> {
  program: string;
  audioType?: 'all' | 'voice_actor' | 'generic';
}

const FormikSelectAudio: React.FC<Props> = ({ program, audioType = 'all', ...otherProps }) => {
  const [audioFiles, loading, error] = useAudioFiles({ program, type: audioType });
  const audioPathError = useAudioPathError(otherProps.name);
  const options = useMemo(() => {
    if (loading || error || !audioFiles) {
      return [];
    }
    const options = [];
    if (['all', 'voice_actor'].includes(audioType)) {
      options.push(...audioFiles.voiceActorAudio.map((audioRef) => ({ label: formatAudioFile(audioRef.fullPath), value: audioRef.fullPath })));
    }
    if (['all', 'generic'].includes(audioType)) {
      options.push(...audioFiles.genericAudio.map((audioRef) => ({ label: formatAudioFile(audioRef.fullPath), value: audioRef.fullPath })));
    }
    return options;
  }, [audioFiles, loading, error, audioType])

  return (
    <>
      <FormikSelect
        {...otherProps}
        placeHolder={loading ? "Loading audio..." : "Select audio..."}
        options={options}
      />
      <h6 className="text-warning">{audioPathError}</h6>
    </>
  )
}

export default FormikSelectAudio;

// Show error with voice actor audio path to help with new implementation of remote audio + voice actor audio
const useAudioPathError = (name: string): string => {
  const [fieldInput] = useField(name);
  const [error, setError] = useState('');

  useEffect(() => {
    let error = '';
    if (fieldInput.value) {
      // If the path does not contain a /assets/audio/ then it is old version of audio
      if (!fieldInput.value?.includes('/assets/audio/')) {
        error = `Audio path ${fieldInput.value} does not include /assets/audio, it should for remote audio implementation`;
      }
    }
    if (error) {
      console.error(error);
    }
    setError(error)
  }, [fieldInput.value])

  return error;
}
