import { auth, firestore } from "firebase";
import { Formik, Form as FormikForm, useField } from "formik";
import React, { useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import * as Yup from "yup";
import * as uuid from "uuid";
import { stepImageTypes } from "@swing-therapeutics/swingcore/dist/models/content/Lesson";
import { FormikSelect, FormikSubmitButton, FormikTextField } from "../../FormikFields";
import FormikSelectAudio from "../../FormikFields/Constructed/FormikSelectAudio";

interface NewStepModalProps {
  show: boolean;
  close: Function;
  type: "Introduction" | "Text" | "Prompt" | "Audio" | "Video" | "";
  steps: any[];
  programID: string;
  lessonID: string;
}

export const NewStepModal = ({
  show,
  close,
  type,
  steps,
  programID,
  lessonID,
}: NewStepModalProps) => {
  const contentKeyName = type === "Audio" ? "description" : "content";

  return (
    <Modal show={show} onHide={close}>
      <Formik
        initialValues={{
          // Big YIKES with this logic here, leaving for now, can only refractor so much at a time... 😔
          title: "",
          ...(type !== "Video" && {
            [contentKeyName]: "",
            image_filename: null,
            imageType: null,
          }),
          ...(type === "Audio" && { remote_audio_filename: "", audio_filename: "" }),
          ...(type === "Video" && { videoId: "" }),
        }}
        validationSchema={Yup.object().shape({
          title: Yup.string().trim().required("a title is required"),
          ...(type !== 'Video' && {
            image_filename: Yup.string().trim('The image filename may not have leading or trailing whitespaces.').strict(true).nullable(),
            imageType: Yup.string().when('image_filename', {
              is: (fileName) => !!fileName,
              then: Yup.string().required('Image type is required when using an image'),
              otherwise: Yup.string().nullable(),
            }),
            [contentKeyName]: Yup.string()
              .trim()
              .required(`${contentKeyName} is required`),
          }),
          ...(type === 'Video' && {
            videoId: Yup.string().trim().required('a video id is required'),
          }),
          ...(type === "Audio" && {
            audio_filename: Yup.string()
              .trim()
              .required("audio file name is required"),
            remote_audio_filename: Yup.string()
              .trim()
              .required("audio file name is required"),
          }),
        })}
        enableReinitialize={true}
        validateOnChange={false}
        onSubmit={async (values, actions) => {
          try {
            const newStep: Record<string, any> = { ...values, type };
            if (type === "Prompt") {
              newStep.uuid = uuid.v4();
            }
            await firestore()
              .collection("programs")
              .doc(programID)
              .collection("lessons")
              .doc(lessonID)
              .update({
                steps: [...steps, newStep],
                updated: new Date(),
                updatedBy: auth().currentUser.email,
              });
            window.alert("new step added successfully!");
          } catch (error) {
            console.error(error);
            window.alert(
              "An error occurred when trying to reorder steps. Check the developer console for details."
            );
          } finally {
            actions.setSubmitting(false);
            close();
          }
        }}
      >
        <FormikForm>
          <Modal.Header closeButton>
            <Modal.Title>Add {type} Step</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FormikTextField
              name="title"
              label="Title"
            />
            {type !== "Video" && (
              <>
                <FormikTextField
                  name={contentKeyName}
                  label={contentKeyName.charAt(0).toUpperCase() + contentKeyName.slice(1)}
                  multiLine={true}
                />
                <FormikTextField
                  name="image_filename"
                  label="Image file name"
                  desc="Leave blank for no image"
                />
                <ConditionallShownImageType />
              </>
            )}
            {type === "Audio" && (
              <>
                <FormikTextField
                  name="audio_filename"
                  label="Local Audio filename"
                  desc="Old version of audio filename that points to audio in app bundle, old versions of the app use this. If this is changed to new audio and the audio does not exist in the app bundle, the audio will not be played"
                />
                <FormikSelectAudio
                  name="remote_audio_filename"
                  label="Remote Audio filename"
                  desc="New remote audio filename that points to audio stored outside of app, new versions of the app use this"
                  program={programID}
                />
              </>
            )}

            {type === "Video" && (
              <FormikTextField
                name="videoId"
                label="Video ID"
                desc="ID found at the end of the video url (for example, the ID for https://vimeo.com/manage/videos/572227955 would be 572227955)"
              />
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={() => close()} variant="outline-secondary">
              Close
            </Button>
            <FormikSubmitButton label="Save" />
          </Modal.Footer>
        </FormikForm>
      </Formik>
    </Modal>
  );
};

const ConditionallShownImageType: React.FC = () => {
  const [fieldInput, _fieldMeta, fieldHelpers] = useField('image_filename');
  const [_it_fieldInput, _it_fieldMeta, it_fieldHelpers] = useField('imageType');

  useEffect(() => {
    if (fieldInput.value === "") {
      fieldHelpers.setValue(null);
      it_fieldHelpers.setValue(null);
    }
  }, [fieldInput.value])

  return (
    <div style={{ display: fieldInput.value ? 'block' : 'none' }}>
      <FormikSelect
        label="Image Type"
        name="imageType"
        options={stepImageTypes.filter((option) => option.name !== 'None').map((option) => ({ label: option.name, value: option.value }))}
      />
    </div>
  )
}
