import React from "react";
import { useCollection } from "react-firebase-hooks/firestore";
import LoadingScreen from "../../../LoadingScreen";
import { firestore } from "../../../../firebase";

import { Card, Col, ListGroup, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDemocrat } from "@fortawesome/free-solid-svg-icons";
import ManualNotificationForm from "../../../Notifications/ManualNotificationForm";
import NotificationsList from "../../../Notifications/NotificationsList";
import User from "../../../../Model/Usert";

interface UserDevicesProps {
  user: User;
}

const UserDevices: React.FC<UserDevicesProps> = ({ user }) => {
  const [devices, devicesLoading, devicesError] = useCollection(
    firestore
      .collection("users")
      .doc(user.uid)
      .collection("devices")
      .orderBy("lastOpenedTime", "desc"),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  return (
    <>
      {devicesError && <strong>Error getting Usage: {JSON.stringify(devicesError)}</strong>}
      {devicesLoading && <LoadingScreen></LoadingScreen>}
      {devices && devices.docs.map((usageRef) => {
        const dev = usageRef.data();

        return (
          <Col style={{ paddingBottom: "10px" }} key={dev.lastOpenedTime.toDate().toLocaleString()} xs={12} md={12}>
            <Card border={"success"}>
              <Card.Header className="bg-warning text-white">
                <h3>
                  <FontAwesomeIcon icon={faDemocrat}></FontAwesomeIcon>{" "}
                  {dev.deviceOS} {dev.deviceOSVersion} App Version {dev.appVersion}
                </h3>
              </Card.Header>
              <ListGroup variant="flush">
                <ListGroup.Item>
                  <Row>
                    <span>
                      <strong>Last Opened</strong>{" "}
                      {dev.lastOpenedTime &&
                        dev.lastOpenedTime.toDate().toLocaleString()}
                    </span>
                  </Row>
                </ListGroup.Item>
                <ListGroup.Item>
                  <ManualNotificationForm
                    uid={user.uid}
                    FCMToken={dev.FCMToken}
                    deviceID={usageRef.id}
                  ></ManualNotificationForm>
                </ListGroup.Item>
                <ListGroup.Item>
                  <NotificationsList
                    uid={user.uid}
                    deviceID={usageRef.id}
                  ></NotificationsList>
                </ListGroup.Item>
              </ListGroup>
            </Card>
          </Col>
        );
      })}
    </>
  );
};

export default UserDevices;
