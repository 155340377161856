import React, {useState} from "react";
import {Link} from "react-router-dom";
import {useCollection, useDocument} from "react-firebase-hooks/firestore";
import LoadingScreen from "../../LoadingScreen";
import TabContainer from "react-bootstrap/TabContainer";
import "../surveyAdherence.css";
import {firestore} from "../../../firebase";
import AdminNavbar from "../../AdminNavbar";
import {useHistory} from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import NavItem from "react-bootstrap/NavItem";

import {Button, Col, Container, Form, FormControl, InputGroup, Jumbotron, Modal, Row, Table,} from "react-bootstrap";
import AReportTable004A from "./AReportTable004A";
import AlargerReportTable004A from "./AlargerReportTable004A";
import AnotherReportsListSidebar from "./AnotherReportsListSidebar";

import AsmallerReportTable004A from "./AsmallerReportTable004A";
    // const query = firestore.collection("analytics/reports/reportPages");
    // const [reportPages, reportPagesLoading, reportPagesError] = useCollection(query, {
        // snapshotListenOptions: {includeMetadataChanges: true},
    // });
// const query = firestore.collection("analytics").doc("kpi_summary")
const A004Pro_compliance = (props) => {
 return (<>
        <AdminNavbar/>
        <Jumbotron>
            <Container fluid={true} style={{paddingBottom: "30px", paddingRight: "50px"}}>
                <Row>
                    <Col>
                        <h1>004 PRO Compliance</h1>
                    </Col>
                </Row>
            </Container>
        </Jumbotron>
        <AnotherReportsListSidebar/>
        
        <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "40px"}}>
            <div className="article" style={{paddingBottom: "30px", paddingLeft: "40px"}}>
            <div className="TitleC" >KPI Target Met, by week</div>
            < div classname="TitleCc">80% of all participants completing weekly PROs</div>
           
            <AsmallerReportTable004A tableKey={"pro_kpi_compliance_target004"}></AsmallerReportTable004A> 
                </div>
        </Container>       
           <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "40px"}}>
            <div className="article" style={{paddingBottom: "30px", paddingLeft: "40px"}}>
            <div className="TitleC">Compliance by Site</div>
            <AsmallerReportTable004A tableKey={"site_pro_compliance004"}></AsmallerReportTable004A> 
                </div>
        </Container>
        <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "40px"}}>
            <div className="article" style={{paddingBottom: "30px", paddingLeft: "40px"}}>
            <div className="TitleC">Compliance by Participant</div>
            <AsmallerReportTable004A tableKey={"participant_pro_compliance004"}></AsmallerReportTable004A>
                </div>
        </Container>       
        <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "40px"}}>
            <div className="article" style={{paddingBottom: "30px", paddingLeft: "40px"}}>
            <div className="TitleC">Overall PRO Compliance</div>
            <AReportTable004A tableKey={"overall_pro_compliance004"}></AReportTable004A>
                </div>
        </Container>
    
        <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "40px"}}>
            <div className="article" style={{paddingBottom: "30px", paddingLeft: "40px"}}>
            <div className="TitleC">Missing PROs</div>
            <AReportTable004A tableKey={"missing_pros004"}></AReportTable004A>
                </div>
        </Container>
       

    



      
</>);

};

export default A004Pro_compliance;

