import React, { useEffect, useRef, useState } from "react";
import { Row } from "react-bootstrap";
import { firestore } from "../firebase";

export default function usePagination(
  collection: string,
  pageSize: number,
  orderBy: string,
  order: string,
  searchTerms: Array<string>,
  pageNumber?: number,
  limit?: number
) {
  // get the collection from firebase
  const [docs, setDocs] = React.useState<any[]>([]);
  const [visibleDocs, setVisibleDocs] = React.useState<any[]>([]);
  const [documentsLoading, setDocumentsLoading] = React.useState(true);
  const page = useRef(1);
  const [hasNextPage, setHasNextPage] = React.useState(true);
  const [hasPreviousPage, setHasPreviousPage] = React.useState(false);
  const [maxPages, setMaxPages] = React.useState(0);
  const [docsCopy, setDocsCopy] = React.useState([]);
  const [documentsError, setDocumentsError] = React.useState("" as any);
  const [searchDocs, setSearchDocs] = React.useState([]);
  const [searchMaxPages, setSearchMaxPages] = React.useState(null);
  const [filter, setFilter] = useState("");

  useEffect(() => {
    if (pageNumber && pageNumber !== 1) {
      page.current = Number(pageNumber);
    }
    getDocs();
    setDocumentsLoading(true);
  }, []);

  useEffect(() => {
    setMaxPages(Math.ceil(searchMaxPages || docs.length / pageSize));
    setHasNextPage(page.current < maxPages);
    setHasPreviousPage(page.current > 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [docs, maxPages, pageSize, page.current, searchDocs]);

  const getDocs = async () => {
    let orderCondition: any = order === "asc" ? "asc" : "desc";
    try {
      const unsubscribe = firestore
        .collection(collection)
        .orderBy(orderBy, orderCondition)
        .limit(limit || 10000)
        .onSnapshot((snapshot) => {
          const nextDocs = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setDocs(nextDocs);
          setDocsCopy(nextDocs);
          if (pageNumber > Math.ceil(nextDocs.length / pageSize)) {
            page.current = Math.ceil(nextDocs.length / pageSize);
          }
          const start = (page.current - 1) * pageSize;
          const end = page.current * pageSize;
          setVisibleDocs(nextDocs.slice(start, end));
          setDocumentsLoading(false);
        });
      return unsubscribe;
    } catch (error) {
      setDocumentsError(error);
      setDocumentsLoading(false);
    }
  };

  const goToPreviousPage = () => {
    page.current = page.current - 1;
    const sliceEnd = page.current * pageSize;
    const sliceStart = sliceEnd - 20;
    setVisibleDocs(docs.slice(sliceStart, sliceEnd));
  };

  const goToNextPage = () => {
    const sliceStart = page.current * pageSize;
    let sliceEnd = sliceStart + pageSize;
    setVisibleDocs(docs.slice(sliceStart, sliceEnd));
    page.current = page.current + 1;
  };

  const search = async (input: string, searchTerm?: string) => {
    let copiedDocs = docsCopy;
    let results = [];

    searchTerms.forEach((term) => {
      const matchingDocs = copiedDocs?.filter((doc) => {
        if (term.includes('.')) {
          let [outerTerm, innerTerm] = term.split('.');
          return doc[outerTerm][innerTerm]?.toString().toLowerCase().includes(input.toLowerCase());
        }
        return doc[term]?.toString().toLowerCase().includes(input.toLowerCase());
      });

      if (!searchTerm || searchTerm === term) {
        results.push(matchingDocs);
      }
    });
    
    results = results.flat();
    let unique = Array.from(new Set(results));
    if (!unique.length) {
      unique.push({ email: "No results found" });
    }
    setDocs(unique);
    setVisibleDocs(unique.slice(0, pageSize));
    setMaxPages(Math.ceil(unique.length / pageSize));
    page.current = 1;
  };

  const searchBoolean = async (searchTerm: string, searchValue: boolean) => {
    let copiedDocs = docsCopy;
    let results = [];
    const matchingDocs = copiedDocs?.filter((doc) => {
      return doc[searchTerm] === searchValue;
    });
    results.push(matchingDocs);
    results = results.flat();
    let unique = Array.from(new Set(results));
    if (!unique.length) {
      unique.push({ email: "No results found" });
    }
    setDocs(unique);
    setVisibleDocs(unique.slice(0, pageSize));
    setMaxPages(Math.ceil(unique.length / pageSize));
    page.current = 1;
  }

  const resetClick = (e) => {
    e.preventDefault();
    setFilter("");
    reset();
  };

  const reset = () => {
    setDocs(docsCopy);
    setVisibleDocs(docsCopy.slice(0, pageSize));
    setSearchDocs([]);
    setSearchMaxPages(null);
    page.current = 1;
  };

  const onSearchSubmit = (e) => {
    e.preventDefault();
    search(filter);
  };

  const ResetButton = () => {
    return (
      <>
        {!!filter.length && (
          <button
            onClick={(e) => resetClick(e)}
            style={{ height: 35, width: 75, marginLeft: 20 }}
          >
            Reset
          </button>
        )}
      </>
    );
  };

  const SearchButton = () => {
    return (
      <button
        onClick={(e) => onSearchSubmit(e)}
        style={{ height: 35, width: 75, marginLeft: 20 }}
      >
        Search
      </button>
    );
  };

  const PaginationButtons = () => {
    return (
      <Row
        style={{ display: "flex", justifyContent: "center", marginBottom: 10 }}
      >
        <button
          style={{ opacity: !hasPreviousPage ? 0.3 : 1, marginRight: 15 }}
          disabled={!hasPreviousPage}
          onClick={goToPreviousPage}
        >
          Previous Page
        </button>
        <button
          style={{ opacity: !hasNextPage ? 0.3 : 1, marginLeft: 15 }}
          disabled={!hasNextPage}
          onClick={goToNextPage}
        >
          Next Page
        </button>
      </Row>
    );
  };

  return {
    docs,
    documentsLoading,
    page,
    hasNextPage,
    hasPreviousPage,
    visibleDocs,
    goToNextPage,
    goToPreviousPage,
    searchFunction: search,
    reset,
    setDocs,
    docsCopy,
    PaginationButtons,
    documentsError,
    filter,
    setFilter,
    ResetButton,
    SearchButton,
    searchBoolean
  };
}
