import { auth, firestore, functions } from "../firebase";
import { SystemEvent } from "./SystemEvent";
import moment from 'moment';
import { Registration } from "./Registration";
import LandingPage from "./LandingPage";
import { EarlyTermInfo, FailReason, UserStatus, WorkflowStatusMap, WorkflowType } from "@swing-therapeutics/surveybay/dist/types";
import LandingPageExtension from "./LandingPageExtension";
import _ from 'lodash';

class User {
    uid: string = "";
    email: string = "";
    activeProgram: string | null = null;
    displayName: string = "";
    subjectID?: string;
    lastOpenedTime?: Date;
    lastLogon?: Date;
    zendeskID?: string;
    cohort: string = "default";
    role: string = "default";
    site: string = "default";
    study: string = "003";
    arm: string = "";
    firstName?: string;
    lastName?: string;
    timezone: string = "";
    appVersion: string = "";
    created: Date = new Date();
    updated: Date = new Date();
    updatedBy: string = "";
    deviceName: string = "";
    deviceOS: string = "";
    deviceOSVersion: string = "";
    registered: boolean = false;
    FCMToken: string | null = null;
    FCMTokenUpdated: Date | null = null;
    workflowStatus: UserStatus;
    earlyTermInfo: EarlyTermInfo | null;
    failReason: FailReason | null;
    phone: string = "";
    landingPageKey: string;
    passed005ScreenWeek: boolean | null;


    static fromFirestore(docRef: any) {
        let data = docRef.data();
        let u = new User();

        u.cohort = data.cohort ? data.cohort : "default";
        u.role = data.role ? data.role : "patient";

        u.created = data.created ? data.created.toDate() : new Date();
        u.updated = data.updated ? data.updated.toDate() : new Date();
        u.lastOpenedTime = data.lastOpenedTime ? data.lastOpenedTime.toDate() : null;
        u.lastLogon = data.lastLogon ? data.lastLogon.toDate() : null;
        u.uid = docRef.id;
        u.email = data.email;
        u.activeProgram = data.activeProgram ? data.activeProgram : null;
        u.displayName = data.displayName;
        u.subjectID = data.subjectID ? data.subjectID : null;
        u.zendeskID = data.zendeskID ? data.zendeskID : null;
        u.site = data.site ? data.site : "default";
        u.study = data.study ? data.study : null;
        u.arm = data.arm ?? null;
        u.appVersion = data.appVersion ? data.appVersion : "";
        u.firstName = data.firstName ? data.firstName : null;
        u.lastName = data.lastName ? data.lastName : null;
        u.timezone = data.timezone ?? "";
        u.deviceName = data.deviceName ? data.deviceName : null;
        u.deviceOS = data.deviceOS ? data.deviceOS : null;
        u.deviceOSVersion = data.deviceOSVersion ? data.deviceOSVersion : null;
        u.registered = data.registered ? data.registered : false;
        u.FCMToken = data.FCMToken ? data.FCMToken : null;
        u.FCMTokenUpdated = data.FCMTokenUpdated ? data.FCMTokenUpdated.toDate() : null;
        u.updatedBy = data.updatedBy ? data.updatedBy : "";
        u.workflowStatus = data.workflowStatus ?? "";
        u.failReason = data.failReason ?? null;
        u.phone = data.phone ?? "";
        u.landingPageKey = data.landingPageKey ?? "";
        u.earlyTermInfo = data.earlyTermInfo ?? null;
        u.passed005ScreenWeek = data.passed005ScreenWeek ?? null;
        return u;
    }


    async persist() {
        let dat = { ...this };
        dat.updated = new Date();
        dat.updatedBy = auth.currentUser?.email ? auth.currentUser?.email : "";
        const cleanDat = _.omitBy(dat, _.isUndefined);
        await firestore.collection("users").doc(this.uid).set(cleanDat, { merge: true });
    }

    timeSinceLastOpen() {

        return this.lastOpenedTime ? moment(this.lastOpenedTime).fromNow() : "";

    }


    matchesFilter(filter: string) {
        if (!filter || filter.length === 0) return true;
        return (this.displayName?.toLowerCase().includes(filter.toLowerCase()) ||
            this.email?.toLowerCase().includes(filter.toLowerCase()) ||
            this.subjectID?.toLowerCase().includes(filter.toLowerCase()) ||
            this.cohort?.toLowerCase().includes(filter.toLowerCase()));
    }

    //registration stuff
    static async userWithEmail(email: string) {
        const existingUser = await firestore
            .collection("users")
            .where("email", "==", email)
            .limit(1)
            .get();

        if (existingUser.empty) return null;
        let userRef = existingUser.docs[0];
        return User.fromFirestore(userRef);
    }


    async updateFromRegistration(reg: Registration) {
        let oldUserProps = { ...this };

        this.subjectID = reg.subjectID;
        this.cohort = reg.cohort;
        this.role = reg.role;
        this.activeProgram = reg.activeProgram;
        this.site = reg.site;
        this.zendeskID = reg.zendeskID;
        this.study = reg.study;
        this.arm = reg.arm;
        this.firstName = reg.firstName;
        this.lastName = reg.lastName;
        this.registered = true;
        let newUserProps = { ...this };
        let props = {
            userEmail: this.email, uid: this.uid, previousUserProps: oldUserProps, newUserProps: newUserProps, registrationRecord: { ...reg },
        };
        SystemEvent.fireEvent("Registration Updated", "registration",
            "Updated User " + this.email + "'s" + " registration", null, props);
        await this.persist();
    }

    async setTerminationWorkflow(terminationReason: string) {
        let landingPage: LandingPage | LandingPageExtension;
        let newWorkflowStatus: UserStatus.EARLY_TERMINATION | UserStatus.EXT_EARLY_TERMINATION;
        if (WorkflowStatusMap[this.workflowStatus] === WorkflowType.EXTENSION) {
            landingPage = await LandingPageExtension.fromLandingPageKey(this.landingPageKey);
            newWorkflowStatus = UserStatus.EXT_EARLY_TERMINATION;
        }
        else {
            landingPage = await LandingPage.fromLandingPageKey(this.landingPageKey);
            newWorkflowStatus = UserStatus.EARLY_TERMINATION;
        }
        const registerUserInProgram = functions.httpsCallable('registerUserInProgram');
        // Register the user in the early termination program
        const result = await registerUserInProgram({ userUID: this.uid, programKey: landingPage.earlyTerminationProgram, requestingIdentifier: auth.currentUser.email });
        if (result.data.status !== 'SUCCESS') {
            console.error(result.data);
            throw new Error("Error registering user to early termination program, check console");
        }
        else {
            // Update the workflow status
            this.workflowStatus = newWorkflowStatus;
            this.activeProgram = landingPage.earlyTerminationProgram;
            this.failReason = {
                reason: terminationReason,
                failSurveyBayResponseID: "",
                failQuestionIDs: [],
                failSurveyBayKey: "",
                failSurveyName: "",
                failQuestionIndexes: [],
            }
            this.persist();
        }
    }
}

export default User;
