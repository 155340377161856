import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { FormikTextField, FormikToggle, FormikSelect } from '../../../FormikFields';
import { mainCalls } from "../../CalendlyCalls";
import { useField } from "formik";
interface Props {
  title: string;
  desc: string;
  basePath: string; // Should be of type ScheduleCallOption
  allowRequireToggle?: boolean;
}

const UniqueCallOption: React.FC<Props> = ({ title, desc, basePath, allowRequireToggle = true }) => {
  const [_urlFieldInput, _urlFieldMeta, urlFieldHelpers] = useField(`${basePath}.calendlyCallURL`);
  
  return (
    <Row className="my-4">
      <Col>
        <Row>
          <Col>
            <h3>{title}</h3>
            <p>
              {desc}
            </p>
          </Col>
        </Row>
        <Row>
          <Col xs={4}>
            <FormikTextField
              name={`${basePath}.title`}
              label="Call title"
              desc="Title for the call, displayed to user"
              placeHolder="Call title"
            />
          </Col>
          <Col xs={4}>
            <FormikSelect
              name={`${basePath}.eventName`}
              label="Calendly event name"
              desc="Name of the event in Calendly"
              options={mainCalls.map((mainCall) => ({ value: mainCall.name, label: mainCall.name }))}
              onChange={(name) => {
                if (name) {
                  // Set the corresponding url for the call
                  const mainCall = mainCalls.find((mainCall) => mainCall.name === name);
                  urlFieldHelpers.setValue(mainCall.calendlyURL);
                }
              }}
            />
          </Col>
          <Col xs={4}>
            <FormikToggle
              name={`${basePath}.required`}
              label="Required call"
              desc='If selected, user does not have the option to decline call'
              allowLabelClick={true}
              disabled={!allowRequireToggle}
            />
          </Col>
          <Col xs={12}>
            <FormikTextField
              name={`${basePath}.description`}
              label="Call description"
              desc="Descriptive text so that purpose of call can be differentiated, displayed to user while scheduling the call"
              placeHolder="Call description"
              multiLine={true}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default UniqueCallOption;
