import { useFormikContext } from "formik";
import React, { useMemo } from "react";
import { Button } from "react-bootstrap";

interface FormikSubmitButtonProps {
  label: string;
  color?: string;
  disabled?: boolean;
}

const FormikSubmitButton: React.FC<FormikSubmitButtonProps> = ({ label, color, disabled = false }) => {
  const { isSubmitting } = useFormikContext();

  // Wrap return in useMemo as formikContext state updates on every input change
  // But we only need to rerender the button when isSubmitting changes
  return useMemo(() =>
    <Button
      color={color}
      type="submit"
      disabled={isSubmitting || disabled}
    >
      {
        isSubmitting ?
          'Submitting...'
          :
          label
      }
    </Button>
    , [isSubmitting, label, color, disabled])
}

export default FormikSubmitButton;
