import React, {useState} from "react";
import {Link} from "react-router-dom";
import {useCollection, useDocument} from "react-firebase-hooks/firestore";
import LoadingScreen from "../../LoadingScreen";
import TabContainer from "react-bootstrap/TabContainer";
import "../surveyAdherence.css";
import {firestore} from "../../../firebase";
import AdminNavbar from "../../AdminNavbar";
import {useHistory} from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import NavItem from "react-bootstrap/NavItem";

import {Button, Col, Container, Form, FormControl, InputGroup, Jumbotron, Modal, Row, Table,} from "react-bootstrap";
// import AReportTable from "./AReportTable";
import AlargerReportTable004B from "./AlargerReportTable004B";
import BReportTable004B from "./BReportTable004B";
import BReportTable004BCT from "./BReportTable004BCT";
import CmReportTable004B from "./CmReportTable004B";
import AReportTable004B from "./AReportTable004B";
import AsmallerReportTable004B from "./AsmallerReportTable004B";
import AnotherReportsListSidebar004B from "./AnotherReportsListSidebar004B";

// import AsmallerReportTable from "./AsmallerReportTable";
    // const query = firestore.collection("analytics/reports/reportPages");
    // const [reportPages, reportPagesLoading, reportPagesError] = useCollection(query, {
        // snapshotListenOptions: {includeMetadataChanges: true},
    // });
// const query = firestore.collection("analytics").doc("kpi_summary")
const B004byTherapyWeek = (props) => {
 return (<>
        <AdminNavbar/>
        <Jumbotron >
            <Container fluid={true} style={{paddingBottom: "30px"}}>
                <Row>
                    <Col>
                        <h1>Other PRO Scores, by Therapy Week</h1>
                        <div className="TitleCb" style={{ paddingLeft: "30px"}}>weeks since app start, where week 0 averages the two scores before app use.</div>
                    </Col>
                </Row>
            </Container>
        </Jumbotron>
        <AnotherReportsListSidebar004B/>
   
        <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "40px"}}>
            <div className="article" style={{paddingBottom: "30px", paddingLeft: "40px"}}>
            <div className="TitleC">PROs by Therapy Week
            <span className="TitleDB" style={{fontSize: "13px", paddingLeft: "10px"}} ></span>
            </div>
            {/* <p></p> */}
            <CmReportTable004B  tableKey={"otherPros004B"}></CmReportTable004B>
                </div>                
        </Container>

        {/* <Container fluid={true} style={{backgroundColor: "white"}}>
            <div className="article" style={{paddingBottom: "30px"}}>
            <div className="TitleC">PGIC Scores, by Arm</div>
            <BReportTable004B  tableKey={"pgicTW_004B"}></BReportTable004B>
                </div>                
        </Container> */}
{/* 
        <Container fluid={true} style={{backgroundColor: "white" }}>
            <div className="article" style={{paddingBottom: "30px"}}>
            <div className="TitleC">ACT FIQR Scores</div>
            <BReportTable004BCT tableKey={"fiqrTW_004B_ACT"}></BReportTable004BCT>
                </div>
        </Container>
  
        <Container fluid={true} style={{backgroundColor: "white"}}>
            <div className="article" style={{paddingBottom: "30px"}}>
            <div className="TitleC">ST FIQR Scores</div>
            <BReportTable004B tableKey={"fiqrTW_004B_ST"}></BReportTable004B>
                </div>
        </Container> */}
       
      
</>);

};

export default B004byTherapyWeek;

// dsgfdfgdfg
