import moment from 'moment';
import {firestore} from "../../firebase";


class UsersSurveys {
    id: string = "";

    subjectID: string = "";
    percent_progress: string = "";
    function: number = 0;
    impact: number = 0;
    treatment_week: number = 0;
    nps: number = 0;
    avgd_baseline_score: number = 0;
    percent_change_from_baseline: string = "";
    response_id: number = 0;
    score: number = 0;
    symptoms: number = 0;
    week_of_year: number = 0;
    zendeskID?: string = "";
    survey: string = "";
    survey_type: string = "default";
    start_date: Date = new Date();
    enddate: Date = new Date();

    static fromFirestore(docRef: any) {
        let data = docRef.data();
        let u = new UsersSurveys();

        try {
            u.start_date = data.start_date ? data.start_date.toDate() : new Date();
            u.enddate = data.enddate ? data.enddate.toDate() : new Date();
            u.id = docRef.id;
            u.subjectID = data.subjectID;
            u.percent_progress = data.percent_progress;
            u.function = data.function;
            u.impact = data.impact;
            u.nps = data.nps;
            u.avgd_baseline_score = data.avgd_baseline_score;
            u.response_id = data.response_id;
            u.percent_change_from_baseline=data.percent_change_from_baseline;
            u.score = data.score;
            u.treatment_week = data.treatment_week;
            u.symptoms = data.symptoms;
            u.week_of_year = data.week_of_year;
            u.zendeskID = data.zendeskID;
            u.survey = data.survey;
            u.survey_type = data.survey_type;
        } catch (e) {
            console.log('error with data in the user surveys', e);
            console.log('that data was this', data);

        }


        return u;


    }

    persist() {
        firestore.collection("surveySummaries").doc(this.id).set(this);
    }


    matchesFilter(filter: string) {
        if (!filter || filter.length === 0) return true;
        return this.subjectID?.toLowerCase().includes(filter.toLowerCase()) ||
            this.subjectID?.toLowerCase().includes(filter.toLowerCase())
    }
}

export default UsersSurveys;
