import React from "react";
import GraphTemplate from "../Graphs/GraphTemplate";
import GraphTemplateEPROsA from "../Graphs/GraphTemplateEPROsA";

import AdminNavbar from "../AdminNavbar";
import { Col, Container, Row } from "react-bootstrap";

const Graphs = () => {
  return (
    <>
      <AdminNavbar />
      <Container>
        <div className="mt-4">
          <h4>Graphs</h4>
          <Row>
            <Col xs={10}>
              <GraphTemplateEPROsA report={"ST_fiqr004A"} />
            </Col>
          </Row>
          <Row>
            <Col xs={10}>
              <GraphTemplateEPROsA report={"ACT_fiqr004A"} />
            </Col>
          </Row>
        </div>
      </Container>
    </>
  );
};

export default Graphs;
