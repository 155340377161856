import React, { useState, useEffect, useRef, useMemo, useCallback } from "react";
import { INudger, NudgeActionType, NudgeCriteria, Nudger, ScheduledTime } from "@swing-therapeutics/swingcore/dist/models/nudge/Nudger";
import { Redirect, RouteComponentProps } from "react-router";
import { Button, Col, Container, Row, Table } from "react-bootstrap";
import { ErrorMessage, Form as FormikForm, Formik, FormikProps, useField, useFormikContext } from "formik";
import LoadingScreen from "../../LoadingScreen";
import * as yup from "yup";
import { cloneDeep, padStart } from "lodash";
import { firestore, functions } from "../../../firebase";
import { useCollectionClassData } from "../../../utils/Hooks";
import { Program } from "../../Content/Program";
import { ErrorMessageRender, FormikSelect, FormikSubmitButton, FormikTextField, FormikToggle } from "../../FormikFields";
import NudgeLogs from "../NudgeLogs";
import { Link } from "react-router-dom";

interface PageParams {
  action: "new" | "edit" | "logs";
  nudgeID?: string;
}

// A couple of fields need to be changed to be edited
const parseNudgeToFormValues = (nudgeData) => {
  // The scheduledTime goes into a type="time" field so convert it to time string
  const scheduledTime = nudgeData.scheduledTime as ScheduledTime;
  nudgeData.scheduledTime = padStart(String(scheduledTime.hours), 2, "0")
    + ":"
    + padStart(String(scheduledTime.minutes), 2, "0");
  return nudgeData;
}

// Change back the fields that were changed earlier
const parseFormValuesToNudge = (formData) => {
  const [hours, minutes] = formData.scheduledTime.split(":").map((str: string) => parseInt(str));
  formData.scheduledTime = {
    hours,
    minutes
  };
  if (!formData.reactFMEXT) {
    delete formData.reactFMEXT;
  }
  // Change the potential users to remove fields that are not set
  formData.potentialUsers = cleanPotentialUsers(formData.potentialUsers);
  return formData;
}

const cleanPotentialUsers = (potentialUsers: NudgeCriteria) => {
  const keys = Object.keys(potentialUsers);
  // If a nudge criteria is an empty string remove it (formik does empty strings)
  for (const key of keys) {
    if (!potentialUsers[key]) {
      delete potentialUsers[key];
    }
  }
  return potentialUsers;
}

const NudgeCreateEdit: React.FC<RouteComponentProps<PageParams>> = ({ match }) => {
  const {
    params: { action, nudgeID },
  } = match;

  const [initialNudger, setInitialNudger] = useState<Nudger>(null);
  const [message, setMessage] = useState<{ error: boolean, msg: string }>(null);
  const isMounted = useRef(true);
  const fieldsDisabled = action === "logs";

  const fetchAndSetInitialNudger = useCallback(async (nudgeID: string) => {
    const doc = await firestore.doc(`tempest/nudging/nudgers/${nudgeID}`).get();
    if (doc?.exists) {
      const nudger = Nudger.fromFirestore(doc);
      setInitialNudger(nudger);
    }
    else {
      setMessage({
        error: true,
        msg: 'Could not find nudger with ID',
      });
    }
  }, [])

  useEffect(() => {
    isMounted.current = true;
    if (nudgeID && (action === "edit" || action === "logs")) {
      // Get the nudge from firestore
      fetchAndSetInitialNudger(nudgeID);
    }
    else {
      setInitialNudger(new Nudger());
    }
    return () => {
      isMounted.current = false;
    }
  }, [action, nudgeID])

  const setDisappearingMessage = useCallback((msg: string, error = false) => {
    setMessage({
      error,
      msg,
    });
    setTimeout(() => {
      isMounted.current && setMessage(null);
    }, 5000);
  }, [isMounted])

  if (action !== "new" && !nudgeID) {
    console.error("Nudge ID not defined for edit nudge");
    return <Redirect to="/nuding" />;
  }

  return (
    <Container>
      {
        action === 'logs' &&
        <>
          <Row className="mb-3">
            <h3>
              Nudge Logs
            </h3>
          </Row>
          <NudgeLogs nudgeID={nudgeID} />
        </>
      }
      <Row className="mb-3">
        <h3>
          {
            {
              'logs': 'Nudge info',
              'edit': 'Edit nudge',
              'new': 'Create new nudge',
            }[action]
          }
        </h3>
      </Row>
      {
        action !== 'new' &&
        <Row className="mb-3">
          <Button as={Link} to={`/nudging/nudges/${action === 'logs' ? 'edit' : 'logs'}/${nudgeID}`}>
            {action === 'logs' ? 'Edit Nudge' : 'Nudge Logs'}
          </Button>
        </Row>
      }
      <Row>
        {
          initialNudger ?
            <Formik
              initialValues={parseNudgeToFormValues(cloneDeep(initialNudger.data()))}
              enableReinitialize={true}
              onSubmit={async (values, actions) => {
                const cleanValues = parseFormValuesToNudge(cloneDeep(values));
                // Use fromFirestore here instead of new Nudger
                // bc the data is exactly like what would be retrieved from firestore (with private fields)
                const newNudger = Nudger.fromFirestore(cleanValues);
                try {
                  await newNudger.persist();
                } catch (error) {
                  setDisappearingMessage("Error saving nudger", true);
                  console.error(error);
                  return;
                }
                if (action === "new") {
                  // New nudge created
                  actions.resetForm();
                  setDisappearingMessage("New nudger saved");
                }
                else {
                  // Nudge edited/updated
                  setDisappearingMessage("Nudger updated");
                }
                actions.setSubmitting(false)
              }}
              validateOnChange={false}
              validationSchema={formSchema}
            >
              <FormikForm role="form" style={{ width: '100%' }}>
                <Container>
                  <Row>
                    <Col xs={6}>
                      <FormikTextField
                        name="name"
                        label="Name"
                        desc="Name of the nudge"
                        disabled={fieldsDisabled}
                      />
                      <FormikTextField
                        name="description"
                        label="Description"
                        desc="Description of the nudge"
                        disabled={fieldsDisabled}
                      />
                      <FormikToggle
                        name="active"
                        label="Is active"
                        desc="Flags if the nudge is currently active"
                        allowLabelClick={true}
                        disabled={fieldsDisabled}
                      />
                      <FormikTextField
                        name="scheduledTime"
                        label="Scheduled time (Paula's local time)"
                        desc="Please note, Paula should receive notification at the specified time in her local time. Nudger only runs every 5 minutes so the minutes must be a multiple of 5."
                        type="time"
                        disabled={fieldsDisabled}
                      />
                      <FormikSelect
                        name="actionType"
                        label="Action type"
                        options={[
                          { label: 'App opened', value: NudgeActionType.appOpened },
                          { label: 'Session completed', value: NudgeActionType.sessionCompleted },
                          { label: 'Any journal created', value: NudgeActionType.anyJournalCreated },
                          { label: 'Any task completed', value: NudgeActionType.anyTaskCompleted },
                          { label: 'Lesson task completed', value: NudgeActionType.lessonTaskCompleted },
                        ]}
                        disabled={fieldsDisabled}
                      />
                      <h6>
                        The user must have performed the action between min hours ago and max hours ago to get nudged
                      </h6>
                      <FormikTextField
                        name="minHoursAgo"
                        label="Min hours ago"
                        type="number"
                        disabled={fieldsDisabled}
                      />
                      <FormikTextField
                        name="maxHoursAgo"
                        label="Max hours ago"
                        type="number"
                        disabled={fieldsDisabled}
                      />
                    </Col>
                  </Row>
                  <MessagesField disabled={fieldsDisabled} />
                  <PotentialUsersField disabled={fieldsDisabled} />
                  {
                    !fieldsDisabled &&
                    <Row className="justify-content-center">
                      <FormikSubmitButton label="Save" />
                    </Row>
                  }
                  {
                    message &&
                    <Row className="justify-content-center">
                      <h6 className={`mt-2 text-success ${message.error ? 'text-warning' : ''}`}>{message.msg}</h6>
                    </Row>
                  }
                </Container>
              </FormikForm>
            </Formik>
            :
            message ?
              <Row className="justify-content-center">
                <Col>
                  <h6 className={`mt-2 text-success ${message.error ? 'text-warning' : ''}`}>{message.msg}</h6>
                </Col>
              </Row>
              :
              <LoadingScreen />
        }
      </Row>
    </Container>
  )
}

export default NudgeCreateEdit;

interface MessagesFieldProps {
  disabled?: boolean
}

const MessagesField: React.FC<MessagesFieldProps> = ({ disabled = false }) => {
  const [fieldInputProps, fieldMetaProps, fieldHelpers] = useField("messages");

  return (
    <Row className="my-3">
      <Col>
        <Row>
          <Col>
            <h4>Messages</h4>
            <h6>A set of messages that will be selected from randomly</h6>
          </Col>
        </Row>
        <Row>
          <Col>
            {
              fieldInputProps.value?.map((_value, index) => {
                return (
                  <Row key={index}>
                    <Col xs={3}>
                      <FormikTextField
                        name={`messages[${index}].title`}
                        label="Title"
                        disabled={disabled}
                      />
                    </Col>
                    <Col xs={8}>
                      <FormikTextField
                        name={`messages[${index}].text`}
                        label="Message"
                        disabled={disabled}
                      />
                    </Col>
                    {
                      !disabled &&
                      <Col xs={1}>
                        <Button
                          variant="danger"
                          onClick={() => {
                            // Remove option
                            const newOptions = [...fieldInputProps.value];
                            newOptions.splice(index, 1);
                            fieldHelpers.setValue(newOptions);
                          }}>
                          X
                        </Button>
                      </Col>
                    }
                  </Row>
                )
              })
            }
          </Col>
        </Row>
        {
          // The message field itself could have an error
          // In which case the error object is a string
          // If it is an array then it is an error within the array of messages
          typeof fieldMetaProps.error === "string" &&
          <Row className="my-2">
            <Col>
              {ErrorMessageRender(fieldMetaProps.error)}
            </Col>
          </Row>
        }
        {
          !disabled &&
          <Row>
            <Col>
              <Button
                onClick={() => {
                  fieldHelpers.setValue([...fieldInputProps.value, { title: "", message: "" }])
                }}
              >
                Add message
              </Button>
            </Col>
          </Row>
        }
      </Col>
    </Row>
  )
}

interface PotentialUsersProps {
  disabled?: boolean;
}

const PotentialUsersField: React.FC<PotentialUsersProps> = ({ disabled = false }) => {
  const [programs] = useCollectionClassData(Program, firestore.collection("programs"));
  const [cohorts, setCohorts] = useState([]);
  const [studies, setStudies] = useState([]);
  const [sites, setSites] = useState([]);

  const programOptions = useMemo(() => {
    return programs.map((program) => {
      return { label: program.name, value: program.key }
    })
  }, [programs])

  useEffect(() => {
    (async () => {
      const cohortsSnap = await firestore.collection(`cohorts`).get();
      const cohorts = [];
      for (const cohortSnap of cohortsSnap.docs) {
        const cohortData = cohortSnap.data();
        cohorts.push({
          label: cohortData.name || cohortData.id,
          value: cohortSnap.id,
        })
      }

      const studiesSnap = await firestore.collection(`studies`).get();
      const studies = [];
      for (const studySnap of studiesSnap.docs) {
        const studyData = studySnap.data();
        studies.push({
          label: studyData.name || studyData.id,
          value: studySnap.id,
        })
      }

      const sitesSnap = await firestore.collection(`medrio/sites/available`).get();
      const sites = [];
      for (const siteSnap of sitesSnap.docs) {
        const siteData = siteSnap.data();
        sites.push({
          label: siteData.siteName ?? siteSnap.id,
          value: siteSnap.id,
        })
      }

      setStudies(studies);
      setCohorts(cohorts);
      setSites(sites);
    })()
  }, [])

  return (
    <Row className="my-3">
      <Col>
        <Row>
          <Col>
            <h4>Potential users</h4>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <Row>
              <Col xs={4}>
                <FormikSelect
                  name="potentialUsers.cohort"
                  label="Cohort"
                  placeHolder="Not in query"
                  options={cohorts}
                  disabled={disabled}
                />
              </Col>
              <Col xs={4}>
                <FormikSelect
                  name="potentialUsers.site"
                  label="Site"
                  placeHolder="Not in query"
                  options={sites}
                  disabled={disabled}
                />
              </Col>
              <Col xs={4}>
                <FormikSelect
                  name="potentialUsers.study"
                  label="Study"
                  placeHolder="Not in query"
                  options={studies}
                  disabled={disabled}
                />
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col xs={4}>
                <FormikSelect
                  name="potentialUsers.activeProgram"
                  label="Active program"
                  placeHolder="Not in query"
                  options={programOptions}
                  disabled={disabled}
                />
              </Col>
              <Col xs={4}>
                <FormikSelect
                  name="potentialUsers.role"
                  label="Role"
                  placeHolder="Not in query"
                  options={[
                    { value: 'patient', label: 'Patient' },
                  ]}
                  disabled={disabled}
                />
              </Col>
              <Col xs={4}>
                <FormikSelect
                  name="potentialUsers.arm"
                  label="Arm"
                  placeHolder="Not in query"
                  options={[
                    { value: 'ACT-daily-insights', label: 'ACT Daily Insights' },
                    { value: 'ACT-weekly-insights', label: 'ACT Weekly Insights' },
                  ]}
                  disabled={disabled}
                />
              </Col>
              <Col xs={6}>
                <FormikSelect
                  name="reactFMEXT"
                  label="React FM EXT"
                  // React FM EXT users are included by default
                  placeHolder="Include React FM EXT users"
                  options={[
                    { value: 'exclude', label: 'Exclude React FM EXT users' },
                    { value: 'only', label: 'Only React FM EXT users' },
                  ]}
                  disabled={disabled}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="my-2">
          <Col>
            <ErrorMessage name="potentialUsers" render={ErrorMessageRender} />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            <h4>Users captured by above query</h4>
          </Col>
        </Row>
        <NudgeQueryList />
      </Col>
    </Row>
  )
}

interface IUserNugable {
  uid: string;
  email: string;
  subjectID: string;
  nudgeable: boolean;
  usersLocalTime: string;
  actionType: NudgeActionType;
  timeActionPerformed: string;
  error: any;
}

const NudgeQueryList: React.FC = () => {
  // Grab all formik form values and validate form function
  const { values, validateForm }: FormikProps<INudger> = useFormikContext();
  const [users, setUsers] = useState<IUserNugable[]>(null);
  const [error, setError] = useState("");
  const nudger = useMemo(() => {
    // Remove empty strings put in by formik and change the scheduledTime property
    return parseFormValuesToNudge(cloneDeep(values) as INudger);
    // Only need to requery when these values change as they affect the query
  }, [values.potentialUsers, values.actionType, values.minHoursAgo, values.maxHoursAgo, values.reactFMEXT])

  const queryNewNudger = useCallback(async () => {
    setUsers(null);
    setError("");
    const errors = Object.keys(await validateForm());
    // If these fields have errors, we cant query
    const errorKeys = ["potentialUsers", "minHoursAgo", "maxHoursAgo", "actionType"];
    for (const error of errors) {
      if (errorKeys.includes(error)) {
        // Dont query if the form has errors in the query
        let errorMessage = "Form has error";
        switch (error) {
          case 'potentialUsers':
            errorMessage = "Potential users has error";
            break;
          case 'minHoursAgo':
            errorMessage = "Min hours ago has error";
            break;
          case 'maxHoursAgo':
            errorMessage = "Max hours ago has error";
            break;
          case 'actionType':
            errorMessage = "Action type has error";
            break;
        }
        setError(`${errorMessage}, can't query`);
        return;
      }
    }
    try {
      const nudgeUsers = functions.httpsCallable('nudgeUsers');
      const response = await nudgeUsers({ nudgerParams: nudger, forReals: false });
      if (response.data.error) {
        console.log(response.data.error);
        setError(response.data.error);
      }
      // Sort so nugable users are on top of table
      const sortedUsers = (response.data.nudgeableUsers as IUserNugable[]).sort((a, b) => +b.nudgeable - +a.nudgeable);
      setUsers(sortedUsers);
    } catch (error) {
      console.error(error);
    }
  }, [validateForm, nudger])

  useEffect(() => {
    queryNewNudger()
  }, [queryNewNudger])

  return (
    <Row>
      <Col xs={12}>
        {
          users &&
          <h6>Total users captured: {users?.length}</h6>
        }
      </Col>
      <Col xs={12}>
        {
          error &&
          <h6 className="text-warning">{error}</h6>
        }
      </Col>
      <Col>
        <Table striped responsive className="table-center-rows">
          <thead>
            <tr>
              <th>
                Subject ID
              </th>
              <th>
                Action performed
              </th>
              <th>
                Local time
              </th>
            </tr>
          </thead>
          <tbody>
            {
              users ?
                users.length === 0 ?
                  <tr>
                    <td>
                      No users captured
                    </td>
                    <td />
                    <td />
                  </tr>
                  :
                  users.map((user) => {

                    return (
                      <tr key={user.uid}>
                        <td>
                          {user.subjectID ? user.subjectID : 'Not set'}
                        </td>
                        <td style={{ maxWidth: 300 }}>
                          {user.error ? `Error: ${user.error} (${user.uid})` : user.timeActionPerformed}
                        </td>
                        <td>
                          {user.usersLocalTime ? user.usersLocalTime : 'N/A'}
                        </td>
                      </tr>
                    )
                  })
                :
                <tr>
                  <td>
                    {
                      !error &&
                      'Loading...'
                    }
                  </td>
                  <td />
                  <td />
                </tr>
            }
          </tbody>
        </Table>
      </Col>
    </Row>
  )
}

const formSchema = yup.object().shape({
  name: yup.string().required("Required"),
  description: yup.string().required("Required"),
  scheduledTime: yup.string().test(
    'is-multiple-5',
    'Minutes must be multiple of 5',
    (value, _context) => {
      const [_hours, minutes] = value.split(":");
      return parseInt(minutes) % 5 === 0;
    }),
  minHoursAgo: yup.number()
    .lessThan(yup.ref("maxHoursAgo"), 'Must be less than max hours ago')
    .min(0, "Must be positive")
    .required("Required"),
  maxHoursAgo: yup.number()
    .moreThan(yup.ref("minHoursAgo"), 'Must be more than min hours ago')
    .min(0, "Must be positive")
    .required("Required"),
  messages: yup.array().of(
    yup.object().shape({
      title: yup.string().required("Required"),
      text: yup.string().required("Required"),
    })
  ).min(1, "Must have at least one message"),
  actionType: yup.string().required("Required"),
  potentialUsers: yup.object().test(
    'has-at-least-one-query',
    'Add at least one query filter, this current query will select all users',
    (value, _context) => {
      // Ensure that at least 1 filter is set
      // Dont want to create queries that dont contain any filters
      // List of filter keys for the object
      const keys = ["cohort", "site", "study", "activeProgram", "role", "arm"];
      for (const key of keys) {
        if (value[key]) {
          // At least one key is set, valid query
          return true;
        }
      }
      // Made it through all the keys without any being set
      // Not valid query
      return false;
    }
  )
})
