import React, { useEffect } from "react";
import LoadingScreen from "../LoadingScreen";
import AdminNavbar from "../AdminNavbar";
import { useHistory, useLocation } from "react-router-dom";
import JSONTree from "react-json-tree";
import usePagination from "../../hooks/usePagination";
import moment from "moment";

import {
  Button,
  Col,
  Container,
  Form,
  FormControl,
  InputGroup,
  Jumbotron,
  Row,
  Table,
} from "react-bootstrap";

const SystemEventsList = () => {
  const jsonTheme = {
    scheme: "chalk",
    author: "chris kempson (http://chriskempson.com)",
    base00: "transparent",
    base01: "#333333",
    base02: "#555555",
    base03: "#777777",
    base04: "#999999",
    base05: "#bbbbbb",
    base06: "#dddddd",
    base07: "#ffffff",
    base08: "#ff4136",
    base09: "#ff851b",
    base0A: "#ffdc00",
    base0B: "#2ecc40",
    base0C: "#7fdbff",
    base0D: "#0074d9",
    base0E: "#b10dc9",
    base0F: "#85144b",
  };
  let { search } = useLocation();
  const pageNumber = new URLSearchParams(search).get("page");
  const searchTerms = [
    "type",
    "performedBy",
    "occured",
    "category",
    "path",
    "message",
  ];
  let history = useHistory();
  const {
    visibleDocs,
    page,
    PaginationButtons,
    documentsError,
    documentsLoading,
    ResetButton,
    SearchButton,
    filter,
    setFilter,
  } = usePagination(
    "systemevents",
    20,
    "occurred",
    "desc",
    searchTerms,
    Number(pageNumber),
    500
  );

  useEffect(() => {
    if (page.current !== pageNumber) {
      history.replace({
        pathname: "/events",
        search: "?page=" + page.current,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, page.current, pageNumber]);

  const getTableRow = (sysEvent) => {
    return (
      <tr>
        <td>
          {moment(sysEvent.occurred.toDate()).format("MM/DD/yyyy, hh:mm:ss")}
        </td>

        <td>{sysEvent.type}</td>
        <td>{sysEvent.category}</td>
        <td>{sysEvent.message}</td>
        <td>
          <JSONTree
            data={sysEvent}
            theme={jsonTheme}
            invertTheme={false}
            shouldExpandNode={() => false}
          />
        </td>
      </tr>
    );
  };

  return (
    <>
      <AdminNavbar></AdminNavbar>
      <Jumbotron>
        <Container fluid={true}>
          <Row>
            <Col>
              <h1>System Events</h1>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form inline>
                <InputGroup className="">
                  <FormControl
                    id="search"
                    placeholder="Search"
                    onChange={(e) => {
                      setFilter(e.target.value.trim());
                    }}
                    value={filter}
                  />
                  <SearchButton />
                  <ResetButton />
                </InputGroup>
              </Form>
            </Col>
            <Col xs="auto">
              <Form inline>
                <InputGroup className="">
                  <Button
                    onClick={() => {
                      history.push("/subjects/new");
                    }}
                  >
                    Create
                  </Button>
                </InputGroup>
              </Form>
            </Col>
          </Row>
        </Container>
      </Jumbotron>
      <Container style={{ backgroundColor: "white" }}>
        <Row>
          {documentsError && (
            <>
              <strong>Error: {JSON.stringify(documentsError)}</strong>
              <br></br>
            </>
          )}
          {documentsLoading && (
            <>
              <strong>
                Subjects Loading: {JSON.stringify(documentsError)}
              </strong>
              <br></br>
              <LoadingScreen></LoadingScreen>
            </>
          )}
          {visibleDocs && (
            <Col xs={12}>
              <Table>
                <thead key="systemEventsTable">
                  <tr>
                    <th>Occurred</th>

                    <th>Type</th>
                    <th>Category</th>
                    <th>Message</th>
                  </tr>
                </thead>
                <tbody>{visibleDocs.map((u) => getTableRow(u))}</tbody>
              </Table>
            </Col>
          )}
        </Row>
        <PaginationButtons />
      </Container>
    </>
  );
};

export default SystemEventsList;
