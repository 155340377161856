import React, { useEffect, useState } from "react";
import { Row, Col, ListGroup, Container, Table } from "react-bootstrap";
import { SessionList } from "./Session/SessionList";
import { useRouteMatch } from "react-router-dom";
import { firestore } from "../../../firebase";
import DetailCard from "../../DetailCard";
import "./playlists.css";

interface PlaylistParams {
  programKey: string;
  playlist: any;
}

const PlaylistDetail = ({ playlist, programKey }: PlaylistParams) => {
  const [selectedSessions, setSelectedSessions] = useState("");
  let match = useRouteMatch("/programs/:programID/playlists/:playlistID?");
  const [selectedPlaylist, setSelectedPlaylist] = useState(playlist);

  useEffect(() => {
    if (selectedPlaylist) {
      setSelectedSessions(selectedPlaylist?.id);
      return () => { };
    }
    //@ts-ignore
    const playlistID = match.params.playlistID;
    if (!playlistID) {
      return () => { };
    }
    const subscriber = firestore
      .collection("programs")
      .doc(programKey)
      .collection("playlist")
      .doc(playlistID)
      .onSnapshot((snapshot: any) => {
        const data = snapshot.data();
        setSelectedPlaylist(data);
        setSelectedSessions(snapshot.id);
      });
    return subscriber;
  }, [match, playlist, programKey, selectedPlaylist]);

  const getListGroupItem = (name: string, value: any, limited: boolean = false) => {
    if (!value) return <></>;
    return (
      <Col xl={limited ? "5" : "auto"}>
        <h4 className="detail-card-header text-secondary">
          <strong>{name}:</strong>
        </h4>
        <div className="detail-card-subtext">{value}</div>
      </Col>
    );
  };

  if (!selectedPlaylist) return <></>;
  return (
    <Container fluid>
      <Row>
        <Col>
          <Row>
            <h4>Details</h4>
          </Row>
        </Col>
        <Table responsive bordered>
          <tr>
            <th>Name</th>
            <th>ID</th>
            <th>Rank</th>
            <th>User Viewable</th>
            <th>Description</th>
          </tr>
          <tr>
            <td>{selectedPlaylist?.name}</td>
            <td>{selectedPlaylist?.id}</td>
            <td>{selectedPlaylist?.rank}</td>
            <td>{selectedPlaylist.userViewable?.toString()}</td>
            <td>{selectedPlaylist?.description}</td>
          </tr>
        </Table>
      </Row>
      <Row style={{ marginTop: 20 }}>
        {selectedSessions ? (
          <SessionList
            playlistId={selectedPlaylist.id}
            programKey={programKey}
          />
        ) : null}
      </Row>
    </Container>
  );
};

export default PlaylistDetail;
