import React, {useState} from "react";
import {useCollection, useDocument} from "react-firebase-hooks/firestore";
import LoadingScreen from "../LoadingScreen";
import "./surveyAdherence.css";
import {firestore} from "../../firebase";
import AdminNavbar from "../AdminNavbar";
import {useHistory} from "react-router-dom";

import {
    Button, Col, Container, Form, FormControl, InputGroup, Jumbotron, Modal, Row, Table,
} from "react-bootstrap";
import {useParams} from "@reach/router";
import ReportTable from "./ReportTable";
import {useRouteMatch} from "react-router";

const Report = (props) => {
    let match = useRouteMatch();
    console.log('match', match);
    const key = match.params.key;
    const [report, reportLoading, reportError] = useDocument(firestore.doc("analytics/reports/reportPages/" + key), {
        snapshotListenOptions: {includeMetadataChanges: true},
    });

    return (<>
        {reportLoading && (<>
            <h1> Loading: {JSON.stringify(reportLoading)}</h1>
            <br></br>
            <LoadingScreen></LoadingScreen>
        </>)}
        {reportError && (<>
            <strong>Error: {JSON.stringify(reportError)}</strong>
            <br></br>
        </>)}

        {report && (<>
            {console.log('report data', report)}
            <AdminNavbar/>
            <Jumbotron>
                <Container fluid={true}>
                    <Row>
                        <Col><h1>{report.data().name}</h1></Col>
                    </Row>
                </Container>
            </Jumbotron>
            <Container fluid={true}>
                <Row>
                    <Col>
                        <p>{report.data().description}</p>
                    </Col>
                </Row>
                {report.data().tables.map((t) => {
                    return (<Row key={t + 'tableRow'}>
                        <ReportTable tableKey={t}></ReportTable>
                    </Row>)
                })}
            </Container>

        </>)}
    </>);
};

export default Report;
