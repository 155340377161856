import {auth, firestore} from "../firebase";
import {setDateProp, setProp} from "../util";
import * as React from "react";


class SystemEvent {
    constructor() {
    }

    static fromFirestore = (docRef) => {
        let se = new SystemEvent();
        se.id = docRef.id;
        const dat = docRef.data();
        for (const [key, value] of Object.entries(dat)) {
            se[key] = value;
        }

        //forcing defaults
        setProp("eventGroup", se, dat, null);
        setProp("type", se, dat, "Log");
        setProp("category", se, dat, "Information");
        setProp("message", se, dat, "");
        setDateProp("occurred", se, dat, new Date());

        return se;
    }

    static fireEvent = (type, category, message, eventGroup, props) => {

        let eventData = props ? {...props} : {};

        eventData.occurred = new Date();
        eventData.eventGroup = eventGroup;
        eventData.type = type ? type : "Log";
        eventData.category = category ? category : "Information";
        eventData.message = message;
        eventData.performedBy = auth.currentUser.email;
        firestore.collection("systemevents").doc().set(eventData).catch((e) => {
            console.error('its all broken', e);
        });

    }

    matchesFilter(filter) {
        if (!filter || filter.length === 0) return true;
        return (this.type.toLowerCase().includes(filter.toLowerCase()) || this.message.toLowerCase().includes(filter.toLowerCase()) || this.category.toLowerCase().includes(filter.toLowerCase()));
    }

    data() {
        let dat = {};
        for (const [key, value] of Object.entries(this)) {
            dat[key] = value;
        }
        return dat;
    }

    persist() {
        if (this.id) {
            firestore.collection('systemevents').doc(this.id).set(this.data());

        } else {
            firestore.collection('systemevents').doc().set(this.data());
        }
    }
}

export {SystemEvent};
