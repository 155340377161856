
import React, { useEffect, useRef, useState } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { getQuestionTypeInfo, Question, QuestionTypes } from "@swing-therapeutics/surveybay/dist/types";
import QuestionDisplay from "@swing-therapeutics/surveybay/dist/FormComponents/QuestionDisplay";
import { Formik, useField } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown, faArrowUp } from "@fortawesome/free-solid-svg-icons";
import * as yup from "yup";
import AddQuestionButton from "../AddQuestionButton";
import { firestore } from "../../../../firebase";
import _ from "lodash";
import { FormikRichTextField, FormikSelect, FormikTextField, FormikToggle } from "../../../FormikFields";

interface FormQuestionEditProps {
  question: Question;
  index: number;
  moveQuestion: (newIndex: number, oldIndex: number) => void;
  deleteQuestion: (index: number) => void;
  duplicateQuestion: (index: number) => void;
  addQuestion: (questionType: QuestionTypes, underIndex?: number) => void;
  finalQuestion?: boolean;
  formType: 'standard' | 'EPRO';
}

const displayIf = (condition: boolean) => {
  if (condition) {
    return undefined;
  }
  return { display: 'none' };
}

const FormQuestionEdit = React.forwardRef<any, FormQuestionEditProps>(({ question, index, addQuestion, moveQuestion, duplicateQuestion, deleteQuestion, finalQuestion, formType }, ref) => {
  const { name, creationInfo } = getQuestionTypeInfo(question.type);

  return (
    <Row className="mt-3" id={`question-${index + 1}`}>
      <Col>
        <Card>
          <Card.Header>
            <Row className="align-items-center">
              <Col xs={2}>
                <h5>{index + 1}. {name}</h5>
              </Col>
              <Col xs={10}>
                <Row className="justify-content-end">
                  <Col xs="auto">
                    <Button
                      onClick={() => moveQuestion(index - 1, index)}
                      disabled={index === 0}
                    >
                      <FontAwesomeIcon
                        icon={faArrowUp}
                      />
                    </Button>
                  </Col>
                  <Col xs="auto">
                    <Button
                      onClick={() => moveQuestion(index + 1, index)}
                      disabled={finalQuestion}
                    >
                      <FontAwesomeIcon
                        icon={faArrowDown}
                      />
                    </Button>
                  </Col>
                  <Col xs="auto">
                    <Button onClick={() => duplicateQuestion(index)}>
                      Duplicate
                    </Button>
                  </Col>
                  <Col xs="auto">
                    <AddQuestionButton addQuestion={addQuestion} underIndex={index} />
                  </Col>
                  <Col xs="auto">
                    <Button variant="danger" onClick={() => deleteQuestion(index)}>
                      X
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Card.Header>
          <Card.Body className="p-2">
            <Formik
              initialValues={{ ...question, order: index + 1 }}
              // Dont do anything on submit, values are pulled from ref if they are valid
              onSubmit={() => { }}
              validateOnChange={false}
              enableReinitialize={true}
              validationSchema={formSchema}
              innerRef={ref}
            >
              {props => (
                <Row>
                  <Col xs={6} className="px-4" style={{ borderRight: '1px solid grey' }}>
                    <Form>
                      <SetFormKey />
                      <Row className="mb-2">
                        <Col>
                          <h6>{creationInfo}</h6>
                        </Col>
                      </Row>
                      <BaseFields showAllowNoResponse={question.type !== QuestionTypes.DisplayText} />
                      <Row style={displayIf(question.type === QuestionTypes.DisplayText)}>
                        <Col>
                          <FormikRichTextField name="text" label="Display text" placeHolder="Display text" />
                        </Col>
                      </Row>
                      <Row style={displayIf([QuestionTypes.Text, QuestionTypes.TextMultiLine].includes(question.type))}>
                        <Col xs={12}>
                          <FormikTextField name="placeHolder" label="Placeholder" placeHolder="Placeholder" />
                        </Col>
                        <ValidationFields />
                      </Row>
                      <Row style={displayIf([QuestionTypes.CheckBox, QuestionTypes.Buttons, QuestionTypes.Radio].includes(question.type))}>
                        <OptionsFields numberValue={formType === 'EPRO'} />
                      </Row>
                      <Row style={displayIf(QuestionTypes.Slider === question.type)}>
                        <SliderOptionsField />
                      </Row>
                      <Row style={displayIf(question.type !== QuestionTypes.DisplayText)}>
                        <ConditionalDisplayFields questionType={question.type} />
                      </Row>
                    </Form>
                  </Col>
                  <Col xs={6} className="my-auto">
                    <Row className="px-5" style={{ maxWidth: 600 }}>
                      <QuestionDisplay
                        question={props.values}
                        questionNumber={props.values.order}
                        mode="creation"
                      />
                    </Row>
                  </Col>
                </Row>
              )}
            </Formik>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  )
})

// Fields that are in every question component
interface BaseFieldsProps {
  showAllowNoResponse: boolean;
}

const BaseFields: React.FC<BaseFieldsProps> = ({ showAllowNoResponse }) => {

  return (
    <>
      <FormikRichTextField name="instructions" label="Instructions" placeHolder="Instructions" />
      <Row>
        <Col xs={4}>
          <FormikTextField name="submitBtnText" label="Submit button text" placeHolder="Submit button text" />
        </Col>
        <Col xs={4}>
          <FormikToggle
            name="skipped"
            label="Question hidden by default"
            desc="Question will not be shown unless another questions conditional display shows it"
          />
        </Col>
        <Col xs={4} style={displayIf(showAllowNoResponse)}>
          <FormikToggle name="allowNoResponse" label="Allow no response" desc="Allow user to continue without submitting response" />
        </Col>
      </Row>
    </>
  )
}

// Getting the form key is an async process
// To reduce wait time form key will be set while question is made
const SetFormKey: React.FC = () => {
  const [fieldInputProps, fieldMetaProps, fieldHelpers] = useField('uniqueID');
  // Flags if the uniqueID is currently being set
  const isSetting = useRef(false);

  useEffect(() => {
    const setting = isSetting.current;
    if (setting) return;
    if (!fieldInputProps.value) {
      fieldHelpers.setError("No ID is set for question!");
    }
    else if (fieldInputProps.value.includes("SETID")) {
      isSetting.current = true;
      getUniqueQuestionID().then(questionID => {
        const lastIndex = fieldInputProps.value.lastIndexOf('_');
        const newKey = fieldInputProps.value.substring(0, lastIndex) + "_" + questionID;
        fieldHelpers.setValue(newKey);
      }).catch((error) => {
        console.error(error);
        fieldHelpers.setError("Error getting question ID");
      }).finally(() => {
        isSetting.current = false;
      })
    }
  }, [fieldInputProps.value, fieldHelpers, getUniqueQuestionID])

  return (
    <Row className="mb-2">
      <Col>
        {
          fieldMetaProps.error ?
            <h6 className="text-warning">{fieldMetaProps.error}</h6>
            :
            <h6>Question ID: {fieldInputProps.value.includes("SETID") ? "Loading..." : fieldInputProps.value}</h6>
        }
      </Col>
    </Row>
  )
}

const ValidationFields: React.FC = () => {
  const [answerTypeProps, _fieldMetaProps, _answerTypeHelpers] = useField('validation.answerType');
  const [_minProps, _minMetaProps, minHelpers] = useField('validation.min');
  const [_maxProps, _maxMetaProps, maxHelpers] = useField('validation.max');
  const [disabledMinMax, setDisabledMinMax] = useState(false);

  useEffect(() => {
    if (['year', 'year-not-future'].includes(answerTypeProps.value)) {
      minHelpers.setValue(4);
      maxHelpers.setValue(4);
      setDisabledMinMax(true);
    }
    else if (answerTypeProps.value) {
      setDisabledMinMax(false);
    }
  }, [answerTypeProps.value])

  return (
    <>
      <Col xs={4}>
        <FormikSelect
          name="validation.answerType"
          label="Answer type"
          options={[
            { label: 'Text', value: 'string' },
            { label: 'Number', value: 'number' },
            { label: 'Year', value: 'year' },
            { label: 'Year (not future)', value: 'year-not-future' },
          ]}
        />
      </Col>
      <Col xs={4}>
        <FormikTextField name="validation.min" label="Minimum characters" placeHolder="Minimum characters" disabled={disabledMinMax} />
      </Col>
      <Col xs={4}>
        <FormikTextField name="validation.max" label="Maximum characters" placeHolder="Maximum characters" disabled={disabledMinMax} />
      </Col>
    </>
  )
}

const SliderOptionsField: React.FC = () => {

  return (
    <>
      <Col xs={4}>
        <FormikTextField type="number" name="sliderOptions.min" label="Minimum value" placeHolder="Minimum value" />
      </Col>
      <Col xs={4}>
        <FormikTextField type="number" name="sliderOptions.max" label="Maximum value" placeHolder="Maximum value" />
      </Col>
      <Col xs={4}>
        <FormikTextField type="number" name="sliderOptions.step" label="Step size" placeHolder="Step size" />
      </Col>
      <Col xs={6}>
        <FormikTextField name="sliderOptions.minLabel" label="Min label" placeHolder="Min label" />
      </Col>
      <Col xs={6}>
        <FormikTextField name="sliderOptions.maxLabel" label="Max label" placeHolder="Max label" />
      </Col>
    </>
  )
}

interface OptionsFieldProps {
  // Force the value field to be a number
  numberValue?: boolean;
}

const OptionsFields: React.FC<OptionsFieldProps> = ({ numberValue = false }) => {
  const [fieldInputProps, _fieldMetaProps, fieldHelpers] = useField('options');

  return (
    <Col>
      <Row>
        <Col>
          <h4>Input options</h4>
        </Col>
      </Row>
      <Row>
        <Col>
          {
            fieldInputProps.value?.map((_value, index) => (
              <Row key={index}>
                <Col xs={5}>
                  <FormikTextField
                    name={`options[${index}].label`}
                    desc="What the user sees"
                    label="Label"
                    placeHolder="Label"
                  />
                </Col>
                <Col xs={5}>
                  <FormikTextField
                    name={`options[${index}].value`}
                    desc="What the response will be"
                    label="Value"
                    placeHolder="Value"
                    type={numberValue ? "number" : undefined}
                  />
                </Col>
                <Col xs={2}>
                  {
                    fieldInputProps.value.length > 1 &&
                    <Button variant="danger" onClick={() => {
                      // Remove option
                      const newOptions = [...fieldInputProps.value];
                      newOptions.splice(index, 1);
                      fieldHelpers.setValue(newOptions);
                    }}>
                      X
                    </Button>
                  }
                </Col>
              </Row>
            ))
          }
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <Button
            onClick={() => fieldHelpers.setValue([...fieldInputProps.value, { label: '', value: '' }])}
          >
            Add option
          </Button>
        </Col>
      </Row>
    </Col>
  )
}

const ConditionalDisplayFields: React.FC<{ questionType: QuestionTypes }> = ({ questionType }) => {
  const [fieldInputProps, _fieldMetaProps, _fieldHelpers] = useField('conditionalDisplay.hide');
  const hideShowText = fieldInputProps.value === undefined || fieldInputProps.value ? 'hide' : 'show';

  return (
    <>
      <Col xs={4}>
        <FormikSelect
          name="conditionalDisplay.hide"
          label="Hide/show questions"
          desc="Select to either hide or show questions if question equals a certain response"
          defaultOption="true"
          type="boolean"
          showSelectOption={false}
          options={[
            { label: 'Hide if condition met', value: 'true' },
            { label: 'Show if condition met', value: 'false' },
          ]}
        />
      </Col>
      <Col xs={4}>
        <FormikTextField
          name="conditionalDisplay.hideQuestionsIfEquals"
          label={`${hideShowText.substring(0, 1).toUpperCase() + hideShowText.substring(1)} questions if equal to`}
          desc={questionType === QuestionTypes.CheckBox ?
            `If the reponse equals this, ${hideShowText} other questions (leave empty for no response). Separate by comma for multiple values to match (ex. val1,val2)`
            :
            `If the response equals this, ${hideShowText} other questions (leave empty for no response).`
          }
          placeHolder="Question response"
        />
      </Col>
      <Col xs={4}>
        <FormikTextField
          name="conditionalDisplay.questionNumbersToHide"
          label={`Questions to ${hideShowText}`}
          desc={`Question numbers to ${hideShowText} if the response is equal, seperate by commas. Leave blank for no conditional display.`}
          placeHolder="1,2, etc..."
        />
      </Col>
    </>
  )
}

const formSchema = yup.object().shape({
  uniqueID: yup.string().required("Question ID is required but it is not set"),
  instructions: yup.string().required("Required"),
  submitBtnText: yup.string().required("Required"),
  options: yup.array().of(yup.object().shape({
    label: yup.string().required("Required"),
    value: yup.string().required("Required"),
  })).default(undefined),
  validation: yup.object().shape({
    answerType: yup.string().required("Required"),
    min: yup.number().typeError("Must be number").required("Required"),
    max: yup.number().typeError("Must be number").required("Required"),
  }).default(undefined),
  sliderOptions: yup.object().shape({
    min: yup.number().typeError("Must be number").required("Required"),
    max: yup.number().typeError("Must be number").required("Required"),
    step: yup.number().typeError("Must be number").required("Required"),
    minLabel: yup.string(),
    maxLabel: yup.string(),
  }).default(undefined),
  conditionalDisplay: yup.object().shape({
    hideQuestionsIfEquals: yup.string(),
    questionNumbersToHide: yup.array().of(yup.number().typeError("Must be numbers and commas")).transform((_v, o) => {
      if (!o || o.length === 0) return;
      const array = o.split(",");
      const parsed = array.map((o) => parseInt(o));
      return parsed;
    }).default(undefined),
    hide: yup.boolean().required("Required"),
  })
})

export default FormQuestionEdit;

export const getUniqueQuestionID = async () => {
  let newTotalQuestionsCreated: number;

  // Use a transaction to ensure that if another user is making a question at the exact same time
  // The newTotalQuestionCreated is still accurate and unique
  newTotalQuestionsCreated = await firestore.runTransaction(async (transaction) => {
    const docRef = await transaction.get(firestore.doc('tempest/surveyBay'));
    if (!docRef.exists) {
      throw new Error("Survey bay doc does not exist");
    }
    const surveyBayDoc = docRef.data();
    if (!surveyBayDoc) {
      throw new Error("No data on survey bay doc");
    }
    if (surveyBayDoc.totalQuestionsCreated === undefined) {
      throw new Error("totaQuestionsCreated on survey bay doc is not defined");
    }
    const updatedTotalQuestions = surveyBayDoc.totalQuestionsCreated + 1;
    // Save updated total questions
    transaction.update(firestore.doc('tempest/surveyBay'), { totalQuestionsCreated: updatedTotalQuestions });
    return updatedTotalQuestions;
  })

  // newTotalQuestionsCreated is always unique
  return _.padStart(String(newTotalQuestionsCreated), 6, "0");
}
