import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import DisplaySurveyAssignmentDay from "./Surveys/DisplaySurveyAssignmentDay";
import PaymentNotificationsDisplay from "./PaymentTracker/PaymentNotificationsDisplay";
import LandingPage, { LandingPageFormValues } from "../../../Model/LandingPage";
import LoadingScreen from "../../LoadingScreen";
import { Col, Container, Row } from "react-bootstrap";
import { firestore } from "../../../firebase";
import { Form as FormikForm, Formik } from "formik";
import { FormikSelect, FormikSubmitButton, FormikTextField, FormikToken } from "../../FormikFields";
import { FormSchema } from "./formSchemas/formSchema003";
import { Redirect } from "react-router";
import { SurveyBayForm } from "@swing-therapeutics/surveybay/dist/models/SurveyBayForm";
import { useCollectionClassData } from "../../../utils/Hooks";
import UniqueCallOption from "./UniqueCallOption";
import { useLandingPage } from '../../../hooks/useLandingPage';

interface Props {
  action: "new" | "edit";
  landingPageKey?: string;
}

const LandingPageCreateEdit: React.FC<Props> = ({ action, landingPageKey }) => {
  const [error, setError] = useState<string>(null);
  const [forms, _loading] = useCollectionClassData(SurveyBayForm, firestore.doc("tempest/surveyBay").collection("forms"));
  const [initialFormValues, setInitialFormValues] = useState<LandingPageFormValues>(null);
  const [message, setMessage] = useState("");
  const isMounted = useRef(true);
  const landingPage = useRef<LandingPage>(null)

  const { cohorts, studies } = useLandingPage();

  const fetchAndSetInitialFormValues = useCallback(async () => {
    const doc = await firestore.collection("web/surveysaurus/landingPages").doc(landingPageKey).get();
    if (doc?.exists) {
      const lp = LandingPage.fromFirestore(doc);
      const lpData = lp.getEditableData()
      setInitialFormValues(lpData);
      landingPage.current = lp;
    }
    else {
      setError(`Landing page key (${landingPageKey}) not found`);
    }
  }, [landingPageKey])


  useEffect(() => {
    isMounted.current = true;
    if (landingPageKey && action === "edit") {
      fetchAndSetInitialFormValues();
    }
    else {
      setInitialFormValues({ ...LandingPage.getEditableData() })
    }
    return () => { isMounted.current = false };
  }, [action, landingPageKey, fetchAndSetInitialFormValues])


  const setDisappearingMessage = useCallback((msg) => {
    setMessage(msg);
    setTimeout(() => {
      isMounted.current && setMessage(null);
    }, 5000);
  }, [isMounted])

  const formsAsOptions = useMemo(() => {
    if (forms.length === 0) {
      return [];
    }
    return forms.map((form) => ({ label: form.name, value: form.key }))
  }, [forms])

  if (action !== "new" && !landingPageKey) {
    console.error("Landing page key not defined for edit landing page");
    return <Redirect to="/surveysaurus" />
  }

  return (
    <Container>
      <Row className="mb-3">
        <h3>
          {action === "new" ? "Create new landing page" : "Edit landing page"}
        </h3>
      </Row>
      <Row>
        {initialFormValues ? (
          <Formik
            initialValues={{ ...initialFormValues }}
            enableReinitialize={true}
            onSubmit={async (values: LandingPageFormValues, actions) => {
              try {
                if (action === "new") {
                  // New landing page created
                  await LandingPage.persistNewLandingPage(values);
                  actions.resetForm();
                  setDisappearingMessage("New landing page created");
                } else {
                  // Landing page edited
                  if (
                    values.landingPageKey !== initialFormValues.landingPageKey
                  ) {
                    // landingPageKey changed, delete the old landing page
                    await LandingPage.deleteLandingPage(
                      initialFormValues.landingPageKey
                    );
                    const landingPage = await LandingPage.persistNewLandingPage(
                      values
                    );
                    setInitialFormValues({ ...landingPage.getEditableData() });
                    setDisappearingMessage(
                      "Landing page key changed, old landing page deleted, new one created"
                    );
                  } else {
                    Object.assign(landingPage.current, { ...values });
                    await landingPage.current.persist();
                    setInitialFormValues({
                      ...landingPage.current.getEditableData(),
                    });
                    setDisappearingMessage("Landing page updated");
                  }
                }
              } catch (error) {
                console.error(error);
                setError(`Unable to save form values: ${error}`);
              } finally {
                actions.setSubmitting(false);
              }
            }}
            validateOnChange={false}
            validationSchema={FormSchema}
          >
            <FormikForm role="form" style={{ width: "100%" }}>
              <Container>
                <Row>
                  <Col sm={12} md={6}>
                    <FormikTextField
                      name="landingPageKey"
                      label="Landing page key"
                      desc="Key use to point to landing page on Surveysaurus landing page"
                    />
                    <FormikTextField
                      name="trialID"
                      label="Trial ID"
                      desc="ID used by Tempo app to handle special cases for the trial"
                    />
                    <FormikSelect
                      name="postEligibilityProgram"
                      label="Post Eligibility Program"
                      desc="Active program for the user when they complete eligibility"
                      placeHolder="Select program..."
                      options={[
                        { label: "003Eligible 💘", value: "003Eligible" },
                        { label: "005Eligible 💘", value: "005Eligible" },
                      ]}
                    />
                    <FormikSelect
                      name="postBaselineProgram"
                      label="Post Baseline Program"
                      desc="Active program for the user when they complete baseline"
                      placeHolder="Select program..."
                      options={[
                        { label: "tempoACT2 💥", value: "tempoACT2" },
                        { label: "tempoACT3 🎉", value: "tempoACT3" },
                      ]}
                    />
                    <FormikSelect
                      name="postTherapyProgram"
                      label="Post Therapy Program"
                      desc="Active program for the user when they complete therapy"
                      placeHolder="Select program..."
                      options={[
                        { label: "goodbyeREACTFM 👋", value: "goodbyeREACTFM" },
                        {
                          label: "goodbyePROSPERFM 👋",
                          value: "goodbyePROSPERFM",
                        },
                      ]}
                    />
                    <FormikSelect
                      name="earlyTerminationProgram"
                      label="Early Termination Program"
                      desc="Active program for the user when they are removed early from the study"
                      placeHolder="Select program..."
                      options={[
                        {
                          label: "earlyTerminationREACTFM 💔",
                          value: "earlyTerminationREACTFM",
                        },
                        {
                          label: "earlyTerminationPROSPERFMEXT 💔",
                          value: "earlyTerminationPROSPERFMEXT",
                        },
                      ]}
                    />
                    <FormikTextField
                      name="tempoDownloadLink"
                      label="Tempo download link"
                      desc="Link to download the Tempo app"
                    />
                  </Col>
                  <Col sm={12} md={6}>
                    <FormikSelect
                      name="cohort"
                      label="Cohort"
                      desc="Cohort"
                      options={cohorts}
                    />
                    <FormikSelect
                      name="arm"
                      label="Arm"
                      desc="Arm/Group"
                      options={[
                        {
                          value: "ACT-daily-insights",
                          label: "ACT Daily Insights",
                        },
                        {
                          value: "ACT-weekly-insights",
                          label: "ACT Weekly Insights",
                        },
                      ]}
                    />
                    <FormikTextField name="site" label="Site" desc="Site" />
                    <FormikSelect
                      name="study"
                      label="Study"
                      desc="Study the user will be enrolled in"
                      options={studies}
                      placeHolder="Select study..."
                    />
                    <FormikSelect
                      name="role"
                      label="Role"
                      desc="Role"
                      defaultOption="patient"
                      options={[{ value: "patient", label: "Patient" }]}
                    />
                    <FormikTextField
                      name="maxUsersInConsent"
                      label="Max users in consent state"
                      desc="The maximum number of users that can be in consent state before new users are set to holding pre-screen state"
                      type="number"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormikTextField
                      name="disclaimerText"
                      label="Disclaimer Text"
                      desc="Disclaimer that pops up first time user logs into Surveysaurus"
                      multiLine={true}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Row className="my-3">
                      <Col>
                        <FormikToken
                          name="eligibilityScreening"
                          label="Eligibility Screening"
                          desc="Elegibility screening surveys assigned to user"
                          options={formsAsOptions}
                          selectedOptionsLabel="Selected surveys"
                          optionsLabel="surveys"
                        />
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col>
                        <FormikToken
                          name="baselineSurveys"
                          label="Baseline Surveys"
                          desc="Baseline surveys assigned to user"
                          options={formsAsOptions}
                          selectedOptionsLabel="Selected surveys"
                          optionsLabel="surveys"
                        />
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      <Col>
                        <DisplaySurveyAssignmentDay />
                      </Col>
                    </Row>
                    {initialFormValues?.surveyWeeks.map((surveyWeek) => (
                      <Row key={surveyWeek.week} className="mb-3">
                        <Col>
                          <FormikToken
                            name={`surveyWeeks[${surveyWeek.week - 1}].surveys`}
                            label={`Week ${surveyWeek.week} surveys`}
                            desc={`Surveys to be assigned to user on week ${surveyWeek.week} of therapy`}
                            options={formsAsOptions}
                            selectedOptionsLabel="Selected surveys"
                            optionsLabel="surveys"
                          />
                        </Col>
                        <FormikTextField
                          name={`surveyWeeks[${
                            surveyWeek.week - 1
                          }].daysToCompleteTherapySurvey`}
                          label="Days to complete therapy survey"
                          desc="Number of days a user has to complete a therapy survey"
                          type="number"
                        />
                      </Row>
                    ))}
                    <Row className="mb-3">
                      <Col>
                        <FormikToken
                          name="terminationSurveys"
                          label="Termination Surveys"
                          desc="Termination surveys assigned to user when they are terminated early"
                          options={formsAsOptions}
                          selectedOptionsLabel="Selected surveys"
                          optionsLabel="surveys"
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <UniqueCallOption
                  title="Consent call"
                  desc="User will be given option to schedule call after passing eligibility"
                  basePath="consentCall"
                  allowRequireToggle={false}
                />
                <UniqueCallOption
                  title="Termination call"
                  desc="User will be given option to schedule call after being terminated early"
                  basePath="terminationCall"
                  allowRequireToggle={false}
                />
                <Row className="my-3">
                  <PaymentNotificationsDisplay />
                </Row>
                <Row className="justify-content-center">
                  <FormikSubmitButton label="Submit" />
                </Row>
                {message && (
                  <Row className="justify-content-center">
                    <h6 className="mt-2 text-success">{message}</h6>
                  </Row>
                )}
              </Container>
            </FormikForm>
          </Formik>
        ) : error ? (
          <h3 className="text-warning">{error}</h3>
        ) : (
          <LoadingScreen />
        )}
      </Row>
    </Container>
  );
}

export default LandingPageCreateEdit;
