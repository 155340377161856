import React, {useState} from "react";
import {Link} from "react-router-dom";
import {useCollection, useDocument} from "react-firebase-hooks/firestore";
import LoadingScreen from "../../LoadingScreen";
import TabContainer from "react-bootstrap/TabContainer";
import "../surveyAdherence.css";
import {firestore} from "../../../firebase";
import AdminNavbar from "../../AdminNavbar";
import {useHistory} from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import NavItem from "react-bootstrap/NavItem";

import {Button, Col, Container, Form, FormControl, InputGroup, Jumbotron, Modal, Row, Table,} from "react-bootstrap";
// import AReportTable from "./AReportTable";
// import BReportTable from "./BReportTable";
import BReportTable004B from "./BReportTable004B";
import CmReportTable004B from "./CmReportTable004B";
import AReportTable004B from "./AReportTable004B";
import AsmallerReportTable004B from "./AsmallerReportTable004B";
import AnotherReportsListSidebar004B from "./AnotherReportsListSidebar004B";

// import AsmallerReportTable from "./AsmallerReportTable";
    // const query = firestore.collection("analytics/reports/reportPages");
    // const [reportPages, reportPagesLoading, reportPagesError] = useCollection(query, {
        // snapshotListenOptions: {includeMetadataChanges: true},
    // });
// const query = firestore.collection("analytics").doc("kpi_summary")
const B004Pros = (props) => {
 return (<>
        <AdminNavbar/>
        <Jumbotron>
        <Container fluid={true} style={{paddingBottom: "30px"}}>
                <Row>
                    <Col>
                        <h1>004 Participants Cohort 2</h1>
                    </Col>
                </Row>
            </Container>
        </Jumbotron>
        <AnotherReportsListSidebar004B style={{ paddingRight: "50px"}}/>
        {/* <Container fluid={true} style={{backgroundColor: "white"}}>

            <div className="sidenav">
            <p><Link className="TitleD" style={{paddingLeft: "20px"}} to="/004/sites">
                Sites</Link></p>
               <p><Link className="TitleD" style={{paddingLeft: "20px"}} to="/004/pros">
                PROs</Link></p>
            
            </div>
             </Container> */}
        <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "50px"}}>
            <div className="article">
            <p className="TitleC">PROs by Visit</p>
            <CmReportTable004B tableKey={"pros004B"}></CmReportTable004B>
                </div>
        </Container>
        {/* <Container fluid={true} style={{backgroundColor: "white"}}>
            <div className="article">
            <AReportTable tableKey={"engagement_kpis"}></AReportTable>
                </div>
        </Container>
        <Container fluid={true} style={{backgroundColor: "white"}}>
            <div className="article">
            <AReportTable tableKey={"lesson_kpis"}></AReportTable>
                </div>
        </Container>
        <Container fluid={true} style={{backgroundColor: "white"}}>
            <div className="article">
            <AsmallerReportTable tableKey={"pro_adherence_kpis"}></AsmallerReportTable>
                </div>
        </Container> */}
      
</>);

};

export default B004Pros;

