import React, { useCallback, useEffect, useState } from "react";
import LoadingScreen from "../../../LoadingScreen";
import Plot from "react-plotly.js";
import { DocumentData, firestore } from "../../../../firebase";
import { Badge, Row, Table } from "react-bootstrap";
import moment from "moment";
import GraphLines from "../../../Graphs/GraphLines";
import { UserSectionProps } from "./UserSectionInterface";

// TODO: Integrate session from swingcore session
// TODO: Fix line tooltips
// Difference between this component and SessionsTable.jsx?

interface TableRowProps {
  sessionDoc: DocumentData;
}

const TableRow: React.FC<TableRowProps> = ({ sessionDoc }) => {
  let durationText = "";
  const session = sessionDoc.data();
  let variant = "primary";
  if (session.status === "completed") {
    variant = "success";
    const started = moment(session.dateStarted.toDate());
    const completed = moment(session.dateCompleted.toDate());
    const duration = moment.duration(started.diff(completed));
    durationText = duration.humanize();
  } else if (session.status === "not-started") {
    variant = "warning";
  }
  return (
    <tr>
      <td>{sessionDoc.id}</td>
      <td>
        {session.dateStarted && session.dateStarted.toDate().toLocaleString()}
      </td>
      <td>
        {session.dateCompleted &&
          session.dateCompleted.toDate().toLocaleString()}
      </td>
      <td>{durationText}</td>
      <td>
        <Badge variant={variant}>{session.status}</Badge>
      </td>
    </tr>
  );
}

const UserSessionsTable: React.FC<UserSectionProps> = ({ user, programID }) => {
  const [sessions, setSessions] = useState<firebase.firestore.QuerySnapshot<DocumentData>>();
  const [lines, setLines] = useState<GraphLines[]>([GraphLines.onePerDayLine(84, 41)]);

  useEffect(() => {
    if (!user) return;
    const sub = firestore
      .collection("users")
      .doc(user.uid)
      .collection("programs")
      .doc(programID ? programID : user.activeProgram)
      .collection("sessions")
      .orderBy("dateStarted", "desc")
      .onSnapshot((snap) => {
        setSessions(snap);

        makeSessionLine(snap.docs);
      });

    return sub;
  }, [user, programID]);
  
  const makeSessionLine = useCallback(async (sessions: firebase.firestore.QueryDocumentSnapshot<DocumentData>[]) => {
    const activeProgRef = await firestore
      .collection("users")
      .doc(user.uid)
      .collection("programs")
      .doc(programID ? programID : user.activeProgram)
      .get();

    if (!activeProgRef) {
      return;
    }
    //the things that are needed to build a line in bizzaro plotly world
    const x = [];
    const y = [];
    const text = [];
    let completed = 0;

    const startTherapy = activeProgRef.data().therapyStartDate?.toDate();
    sessions.reverse().forEach((r) => {
      if (!r.data().dateCompleted) {
        return;
      }
      //get normalized day
      const date = r.data().dateCompleted.toDate();

      completed++;
      const progressDay = diffDays(startTherapy, date);
      const progressNumber = Number.parseInt(r.id) - progressDay + 1;
      x.push(progressDay);
      y.push(completed);
      text.push("Progress Number: " + progressNumber);
    });
    const line = {
      x: x,
      y: y,
      text: text,
      type: "scatter",
      mode: "lines+markers",
      line: {
        color: Math.floor(Math.random() * 16777215).toString(16),
        width: 3,
      },
      name: user.subjectID ? user.subjectID + " Sessions" : "Sessions",
    };
    // Looks like this function is called and only one line is ever created?
    // Dont want to add duplicate lines if the session updates
    //setLines(prevLines => [...prevLines, line]);
    setLines([GraphLines.onePerDayLine(84, 41), line])
  }, [user]);

  return sessions ?
    <>
      {lines &&
        <Row>
          <Plot
            scrollZoom={true}
            data={lines}
            className="col"
            useResizeHandler={true}
            layout={{
              margin: {
                l: 100,
                r: 100,
                t: 100,
              },
            }}
          />
        </Row>
      }
      <Row>
        <Table responsive>
          <thead>
            <tr>
              <th>#</th>
              <th>Started</th>
              <th>Completed</th>
              <th>Duration</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {sessions.docs.map((sessionDoc) =>
              <TableRow key={sessionDoc.id} sessionDoc={sessionDoc} />
            )}
          </tbody>
        </Table>
      </Row>
    </>
    :
    <LoadingScreen />
};

const diffDays = (dt2: Date, dt1: Date): number => {
  let diff = (dt2.getTime() - dt1.getTime()) / 1000;
  diff /= 60 * 60 * 24;
  return Math.abs(Math.round(diff));
};

export default UserSessionsTable;
