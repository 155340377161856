import React, { useEffect, useState } from "react";
import {
  Route,
  Switch,
  useHistory,
  useParams,
  useRouteMatch,
} from "react-router-dom";
import AdminNavbar from "../AdminNavbar";
import { Col, Container, Jumbotron, NavLink, Row } from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
import NavItem from "react-bootstrap/NavItem";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReportTable from "../Reports/ReportTable";
import {
  faCheckCircle,
  faClinicMedical,
  faClipboardCheck,
  faListAlt,
  faUserAstronaut,
} from "@fortawesome/free-solid-svg-icons";
import { ScreeningLogs } from "./ScreeningLogs";
import ScreeningFablesOfTables from "../Reports/ScreeningFablesOfTables";
import { ScreeningEnrollment } from "./ScreeningEnrollment";
import { ScreeningVerification } from "./ScreeningVerification";
import { ScreeningOverview } from "./ScreeningOverview";

// @ts-ignore
const Reg004 = (props: any) => {
  const { match, location } = props;
  let history = useHistory();

  // const { key } = useParams();
  const [active, setActive] = useState<string>("");
  const fullMatch = useRouteMatch("/:dashboard?/:section?");

  useEffect(() => {
    console.log("full match", fullMatch);
    if (!active) {
      const newActive = fullMatch.params["section"]
        ? fullMatch.params["section"]
        : "overview";
      setActive(newActive);
    }
  }, []);

  const navLinker = (newActive: string, text: string, icon: any) => {
    return (
      <NavItem>
        <NavLink
          onClick={() => {
            setActive(newActive);
            history.push(match.url + "/" + newActive);
          }}
        >
          <Row>
            <Col>
              <strong
                className={
                  active === newActive ? "text-primary" : "text-secondary"
                }
              >
                {text}
              </strong>
            </Col>
            <Col xs={"auto"}>
              {" "}
              <FontAwesomeIcon icon={icon}></FontAwesomeIcon>
            </Col>
          </Row>
        </NavLink>
      </NavItem>
    );
  };

  return (
    <>
      <AdminNavbar></AdminNavbar>
      <Container fluid={true}>
        <Jumbotron className={"text-center"}>
          <h1 className={"jumbotron-heading"}> 004 Screening Week</h1>
          <p className="lead text-muted">
            {" "}
            that special time in a patients life where we check if they can open
            an app
          </p>
        </Jumbotron>
        <Container fluid={true} style={{ backgroundColor: "white" }}>
          <Row>
            <Col xs={2} className={"sidebar "}>
              <div className={"sidebar-sticky"}>
                <Nav className={"flex-column "}>
                  {navLinker("overview", "Overview", faClinicMedical)}
                  {navLinker("enrollment", "Enrollment", faUserAstronaut)}
                  {navLinker("screening", "Screening Week", faCheckCircle)}
                  {navLinker("swappuse", "Screening Week App Use", faClipboardCheck)}
                  {navLinker("pros", "C2 Qualification - PROs", faClipboardCheck)}
                  {navLinker("logs", "Registration Logs", faListAlt)}
                </Nav>
              </div>
            </Col>
            <Col xs={"10"} className={"main-content"} style={{ padding: 10 }}>
              <>
                <Switch>
                  <Route exact={true} path={match.url}>
                    <ScreeningOverview></ScreeningOverview>
                  </Route>
                  <Route path={match.url + "/overview"}>
                    <ScreeningOverview></ScreeningOverview>
                  </Route>
                  <Route path={match.url + "/logs"}>
                    <ScreeningLogs></ScreeningLogs>
                  </Route>
                  <Route path={match.url + "/enrollment"}>
                    <ScreeningEnrollment></ScreeningEnrollment>
                  </Route>
                  <Route path={match.url + "/screening"}>
                    <ScreeningVerification></ScreeningVerification>
                  </Route>
                  <Route path={match.url + "/swappuse"}>
                  <h4>Screening Week App Use</h4>
<ReportTable tableKey={"screening004BappUse_ftable"}></ReportTable>
                  </Route>
                  <Route path={match.url + "/pros"}>
                    <h4>C2 Qualification - PROs</h4>
<ReportTable tableKey={"screening004B_C2qualPROs"}></ReportTable>

                  </Route>
                </Switch>
              </>
            </Col>
          </Row>
        </Container>
      </Container>
    </>
  );
};

export { Reg004 };
