import { Field, Form, Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { firestore } from "../../../firebase";
import { isEqual } from "lodash";
import ReactQuill from "react-quill";
import '../../textEditor.css'

export default function AboutPage({ programKey }) {
  const [inputs, setInputs] = useState({
    subtitle: "",
    address: "",
    copyright: "",
    termsOfUse: "",
    privacyPolicy: "",
  });

  const [content, setContent] = useState("");
  const originalData = useRef({});
  const originalContent = useRef("");

  const [message, setMessage] = useState("");

  const fetchData = () => {
    firestore
      .collection("programs")
      .doc(programKey)
      .get()
      .then((documentSnapshot) => {
        const data = documentSnapshot.data();
        if (data.aboutPage) {
          setInputs(data.aboutPage as any);
          originalData.current = data.aboutPage;
          if (data.aboutPage.content) {
            setContent(data.aboutPage.content);
            originalContent.current = data.aboutPage.content;
          }
        }
      });
  };

  const container = {
    borderStyle: "ridge",
    padding: "20px",
  };

  const handleSubmit = () => {
    const data = {
      ...inputs,
      content,
    };
    firestore
      .collection("programs")
      .doc(programKey)
      .update({ aboutPage: data })
      .then(() => {
        setMessage("Successfully updated");
      })
      .catch((error) => {
        setMessage(error.message);
      });
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setInputs((vals) => {
      return { ...vals, [name]: value };
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Formik
      initialValues={{
        subtitle: "",
        content: "",
        address: "",
        copyright: "",
        termsOfUse: "",
        privacyPolicy: "",
      }}
      onSubmit={handleSubmit}
    >
      {({}) => (
        <div className="container" style={container}>
          <div className="col-sm-12">
            <Form>
              <label>Subtitle</label>
              <br />
              <div className="form-group">
                <input
                  className="form-control"
                  type="text"
                  placeholder="Subtitle"
                  name="subtitle"
                  value={inputs.subtitle || ""}
                  onChange={handleChange}
                />
              </div>

              <div
                style={{
                  display: "flex",
                  width: "auto",
                  justifyContent: "space-around",
                }}
              >
                <div style={{ width: "100%" }}>
                  <label>Content</label>
                  <ReactQuill
                    theme="snow"
                    value={content || ""}
                    onChange={setContent}
                  />
                </div>
              </div>
              <label>Copyright</label>
              <div className="form-group">
                <Field
                  component="textarea"
                  className="form-control"
                  name="copyright"
                  placeholder="Copyright"
                  rows={6}
                  value={inputs.copyright || ""}
                  onChange={handleChange}
                />
              </div>
              <div style={{ width: "100%", marginBottom: 15 }}>
                <label>Address</label>
                <div>
                  <Field
                    style={{ height: 150 }}
                    component="textarea"
                    className="form-control"
                    name="address"
                    placeholder="Ownership and address"
                    rows={11}
                    value={inputs.address || ""}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <label>Privacy policy</label>
              <div className="form-group">
                <Field
                  className="form-control"
                  type="url"
                  placeholder="Privacy policy"
                  name="privacyPolicy"
                  value={inputs.privacyPolicy || ""}
                  onChange={handleChange}
                />
              </div>
              <label>Terms of use</label>
              <div className="form-group">
                <Field
                  className="form-control"
                  type="url"
                  placeholder="Terms of use"
                  name="termsOfUse"
                  value={inputs.termsOfUse || ""}
                  onChange={handleChange}
                />
              </div>

              <div
                className="form-group"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <button
                  className="btn btn-primary"
                  type="submit"
                  style={{ fontSize: "16px" }}
                >
                  Save
                </button>
                {message && <div>{message}</div>}
              </div>
            </Form>
          </div>
        </div>
      )}
    </Formik>
  );
}
