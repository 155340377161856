function setProp(propName, dest, source, defaultValue){
    if(!propName || !dest || !source){
        console.warn("unable to set prop without name or destination");
    }
    dest[propName] = source[propName]?source[propName]:defaultValue;
}

function setDateProp(propName, dest, source, defaultValue){
    if(!propName || !dest || !source){
        console.warn("unable to set prop without name or destination");
    }
    dest[propName] = source[propName]?source[propName].toDate():defaultValue;
}

export {setProp, setDateProp}