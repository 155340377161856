import React from "react";
import SignIn from "./SignIn";
import ProtectedRoute from "../providers/ProtectedRoute";
import UserList from "./Users/UserList";
import MedrioList from "./Medrio/MedrioList";
import SystemEventsList from "./SystemEvents/SystemEventsList";
import RolesList from "./Roles/RolesList";
import JournalTypesList from "./Journals/JournalTypeList";
import RolesDetailPage from "./Roles/RolesDetailPage";
import SubjectsList from "./Subjects/SubjectsList";
import SubjectsDetail from "./Subjects/SubjectsDetail";
import SubjectCreate from "./Subjects/SubjectCreate";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import RegistrationEdit from "./Registration/RegistrationEdit";
import RegisterNewUser from "./Registration/RegisterNewUser";
import ProgramsList from "./Content/ProgramsIndex";
import SurveySync from "./Surveys/SurveySync";
import DownloadTempo from "./DownloadTempo";
import RegistrationList from "./Registration/RegistrationList";
import NotificationsList from "./Notifications/NotificationsList";
import CohortPlotly from "./Users/CohortPlotly";
import NotifyPlayground from "./Notifications/NotifyPlayground";
import UserGraphPage from "./Graphs/UserGraphPage";
import UserGroupGraphPage from "./Graphs/UserGroupGraphPage";
import SurveyList from "./Surveys/SurveyList";
import NotificationTypeCreator from "./Notifications/NotificationTypeCreator";
import NotificationsTypeList from "./Notifications/NotificationTypeList";
import FablesOfTables from "./Reports/FablesOfTables";
import AppFablesOfTables from "./Reports/AppFablesOfTables";
import A004Promis_all from "./Reports/m004/A004Promis_all";
import A004PromisT_all from "./Reports/m004/A004PromisT_all";
import A004Promis_TW from "./Reports/m004/A004Promis_TW";
import A004PromisT_TW from "./Reports/m004/A004PromisT_TW";
import A004fivefacets_TW from './Reports/m004/A004fivefacets_TW';

import LessonsFablesOfTables from "./Reports/LessonsFablesOfTables";
import AreportsList from "./Reports/AreportsList";
import AreportsList004A from "./Reports/m004/AreportsList004A";
import AnInterviewsList from "./Reports/AnInterviewsList";
import PandExFablesOfTables from "./Reports/PandExFablesOfTables";
import NpsFablesOfTables from "./Reports/NpsFablesOfTables";
import FiqrFablesOfTables from "./Reports/FiqrFablesOfTables";
import Graphs002 from "./Reports/f002/Graphs002";
import FiqrVfablesOfTable from "./Reports/FiqrVfablesOfTable";
import A004Visits from "./Reports/m004/A004Visits";
import fuckthis004 from "./Reports/m004/fuckthis004";
import A004Sites from "./Reports/m004/A004Sites";
import B004Sites from "./Reports/m004B/B004Sites";
import B004Visits from "./Reports/m004B/B004Visits";
import AreportsList004B from "./Reports/m004B/AreportsList004B";
import A004Pro_compliance from "./Reports/m004/A004Pro_compliance";
import A004Pros from "./Reports/m004/A004Pros";
import B004Pros from "./Reports/m004B/B004Pros";
import Graphs004B from "./Reports/m004B/Graphs004B";
import Graphs004BST from "./Reports/m004B/Graphs004BST";
import B004FIQR from "./Reports/m004B/B004FIQR";
import B004FIQRbyTherapyWeek from "./Reports/m004B/B004FIQRbyTherapyWeek";
import B004byTherapyWeek from "./Reports/m004B/B004byTherapyWeek";
import A004OtherPROs from "./Reports/m004/A004OtherPROs";
import A004OtherPROs_all from "./Reports/m004/A004OtherPROs_all";
import A004FIQR from "./Reports/m004/A004FIQR";
import A004FIQRgraphs from "./Reports/m004/A004FIQRgraphs";
import A004PIPSgraphs from "./Reports/m004/A004PIPSgraphs";
import Graphs004A from "./Reports/m004/Graphs004A";
import Graphs004AST from "./Reports/m004/Graphs004AST";
import A004WIgraphs from "./Reports/m004/A004WIgraphs";
import A004FFNRgraphs from "./Reports/m004/A004FFNRgraphs";
import A004byTherapyWeek from "./Reports/m004/A004byTherapyWeek";
import A004FIQRbyTherapyWeek from "./Reports/m004/A004FIQRbyTherapyWeek";
import A004FIQRbyAppProgress from "./Reports/m004/A004FIQRbyAppProgress";
import A004byAppProgress from "./Reports/m004/A004byAppProgress";
import PROkey from "./Reports/PROkey";
import exitInterviews004 from "./Reports/s004all/exitInterviews004";
import LessonsFablesOfTables004A from "./Reports/m004/LessonsFablesOfTables004A";
import SessionsFablesOfTables004B from "./Reports/m004B/SessionsFablesOfTables004B";
import FancyGrid from "./Reports/FancyGrid";
import Report from "./Reports/Report";
import ReportsList from "./Reports/ReportsList";
import UserDetail from "./Users/UserDetail";
import MedrioDetail from "./Medrio/MedrioDetail";
import { UserDataReset } from "./Users/UserDataReset";
import UnregisteredUsersList from "./Users/UnregisteredUsers"
import { ProgramDetail } from "./Content/ProgramDetail";
import { Reg004 } from "./Dashboards/Reg004";
import Graphs from "./Reports/Graphs";
import BlacklistedVersionsList from "./Versions/BlacklistedVersionsList";
import EditBlacklistHTML from "./Versions/VersionEditors/EditBlacklistHTML";
import FormList from "./Forms/FormList";
import FormCreateEdit from "./Forms/FormCreateEdit";
import AddEproKey from "./Forms/FormCreateEdit/AddEproKey";
import Surveysaurus from "./Surveysaurus";
import Nudging from "./Nudging";

import ReportTest from "./Reports/Reports003A/m003Areferrers/ReportTest";
import Trial005 from "./Dashboards/Trial005";
import Cohorts from "./Cohorts/Cohorts";
import Studies from './Studies/Studies';
import AccessCodes from "./Users/AccessCodes/AccessCodes";
import ManageAccessCodes from "./Users/AccessCodes/ManageAccessCodes";
import AccessKeyPageLinks from "./Users/AccessCodes/AccessKeyPageLinks";
import FlagsmithLink from "./Flagsmith/FlagsmithLinkChange";

import CreatePrescription from "./Prescription/CreatePrescription";
import Prescriptions from "./Prescription/Prescriptions";
import Prescription from "./Prescription/Prescription";
import Sites from "./Sites/Sites";

function Application() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/signin" component={SignIn} />
        <Route exact path="/today" component={DownloadTempo} />
        <ProtectedRoute exact path="/" component={UserList} />
        <ProtectedRoute exact path="/users" component={UserList} />
        <ProtectedRoute exact path="/medrio" component={MedrioList} />
        <ProtectedRoute
          exact
          path="/notifications"
          component={NotificationsList}
        />
          <ProtectedRoute
          exact
          path="/accesscodes"
          component={AccessCodes}
        />
          <ProtectedRoute
          exact
          path="/manageAccessCodes"
          component={ManageAccessCodes}
        />
           <ProtectedRoute
          exact
          path="/accessKeyPageLinks"
          component={AccessKeyPageLinks}
        />
        <ProtectedRoute
          exact
          path="/notifications/play"
          component={NotifyPlayground}
        />
        <ProtectedRoute exact path="/surveysByEmail" component={SurveyList} />
        <ProtectedRoute
          exact
          path="/notificationTypes/new"
          component={NotificationTypeCreator}
        />
        <ProtectedRoute
          exact
          path="/notificationTypes"
          component={NotificationsTypeList}
        />
        <ProtectedRoute exact path="/dataReset" component={UserDataReset} />
        <ProtectedRoute exact path="/unregisteredUsers" component={UnregisteredUsersList} />
        <ProtectedRoute exact path="/proref" component={PROkey} />
        <ProtectedRoute
          exact
          path="/versions/blacklist"
          component={BlacklistedVersionsList}
        />

        <ProtectedRoute
          path="/versions/blacklist/edit"
          component={EditBlacklistHTML}
        />

        <ProtectedRoute path="/users/:uid" component={UserDetail} />

        <ProtectedRoute path="/medrio/:uid" component={MedrioDetail} />

        <ProtectedRoute exact path="/roles" component={RolesList} />
        <ProtectedRoute
          exact
          path="/roles/:roleName"
          component={RolesDetailPage}
        />
        <ProtectedRoute
          exact
          path="/journaltypes"
          component={JournalTypesList}
        />
        <ProtectedRoute exact path="/usergraph" component={UserGraphPage} />
        <ProtectedRoute
          exact
          path="/usergroupgraph"
          component={UserGroupGraphPage}
        />

        <ProtectedRoute exact path="/subjects" component={SubjectsList} />
        <ProtectedRoute exact path="/subjects/new" component={SubjectCreate} />
        <ProtectedRoute
          exact
          path="/subjects/:subjectID"
          component={SubjectsDetail}
        />
        <ProtectedRoute
          exact
          path="/FIQR_adherence"
          component={FablesOfTables}
        />
        <ProtectedRoute
          exact
          path="/FIQR_scores"
          component={FiqrFablesOfTables}
        />
        <ProtectedRoute exact path="/FIQR002" component={FiqrVfablesOfTable} />
        <ProtectedRoute exact path="/graphs" component={Graphs} />
        <ProtectedRoute exact path="/graphs002" component={Graphs002} />
        <ProtectedRoute exact path="/002/graphs002" component={Graphs002} />

        <ProtectedRoute
          exact
          path="/app_adherence"
          component={AppFablesOfTables}
        />
        <ProtectedRoute exact path="/app_progress" component={LessonsFablesOfTables} />
        <ProtectedRoute
          exact
          path="/nps_scores"
          component={NpsFablesOfTables}
        />
        <ProtectedRoute exact path="/kpis" component={AreportsList} />
        <ProtectedRoute exact path="/pande" component={PandExFablesOfTables} />
        <ProtectedRoute exact path="/interviews" component={AnInterviewsList} />
        <ProtectedRoute exact path="/004/interviews004" component={exitInterviews004} />
        <ProtectedRoute exact path="/004/sitesB" component={B004Sites} />
        <ProtectedRoute exact path="/004/visitsB" component={B004Visits} />
        <ProtectedRoute exact path="/004/prosB" component={B004Pros} />
        <ProtectedRoute exact path="/004/act004b" component={Graphs004B} />
        <ProtectedRoute exact path="/004/st004b" component={Graphs004BST} />
        <ProtectedRoute exact path="/004/app_progress004B" component={SessionsFablesOfTables004B} />
        <ProtectedRoute exact path="/004/kpisB" component={AreportsList004B} />
        <ProtectedRoute exact path="/004/fiqr004B" component={B004FIQR} />
        <ProtectedRoute exact path="/004/fiqrTW004B" component={B004FIQRbyTherapyWeek} />
        <ProtectedRoute exact path="/004/prosTWB" component={B004byTherapyWeek} />
        <ProtectedRoute exact path="/screeningWeek005" component={Trial005} />
        {/* B004byTherapyWeek */}
        {/* B004FIQR */}

        {/* SessionsFablesOfTables004B app_progress004*/}

        <ProtectedRoute exact path="/004/sites" component={A004Sites} />
        <ProtectedRoute exact path="/004/pros" component={A004Pros} />
        <ProtectedRoute exact path="/004/kpis" component={AreportsList004A} />
        <ProtectedRoute exact path="/004/visits" component={A004Visits} />
        <ProtectedRoute exact path="/004/other_prosA" component={A004OtherPROs} />
        {/* <ProtectedRoute exact path="/004/other_pros_all" component=   {A004OtherPROs_all}  /> */}
        <ProtectedRoute exact path="/004/fiqr004graphs" component={A004FIQRgraphs} />
        <ProtectedRoute exact path="/004/pips004graphs" component={A004PIPSgraphs} />
        <ProtectedRoute exact path="/004/wi004graphs" component={A004WIgraphs} />
        < ProtectedRoute exact path="/004/ffnr004graphs" component={A004FFNRgraphs} />
        <ProtectedRoute exact path="/004/act004a" component={Graphs004A} />
        <ProtectedRoute exact path="/004/st004a" component={Graphs004AST} />

        <ProtectedRoute exact path="/004/scorestherapyweek004" component={A004byTherapyWeek} />
        <ProtectedRoute exact path="/004/fiqrtherapyweek004" component={A004FIQRbyTherapyWeek} />
        {/* A004byAppProgress */}
        <ProtectedRoute exact path="/004/fiqrap004" component={A004FIQRbyAppProgress} />
        <ProtectedRoute exact path="/004/scoresap004" component={A004byAppProgress} />

        <ProtectedRoute exact path="/004/fiqr004A" component={A004FIQR} />
        <ProtectedRoute exact path="/004/app_progress004" component={LessonsFablesOfTables004A} />
        <ProtectedRoute exact path="/004/pro_compliance004" component={A004Pro_compliance} />
        <ProtectedRoute exact path="/004/consorts" component={fuckthis004} />
        <ProtectedRoute exact path="/004/promis004" component={A004Promis_all} />
        <ProtectedRoute exact path="/004/promis004T" component={A004PromisT_all} />
        <ProtectedRoute exact path="/004/promis004atw" component={A004Promis_TW} />
        <ProtectedRoute exact path="/004/promis004tatw" component={A004PromisT_TW} />
        <ProtectedRoute exact path="/004/fivefacets004atw" component={A004fivefacets_TW} />


        {/* Graphs004B */}

        <ProtectedRoute exact path="/004/app_progress004" component={LessonsFablesOfTables004A} />
        <ProtectedRoute exact path="/004/pro_compliance004" component={A004Pro_compliance} />

        <ProtectedRoute exact path="/003/reporttestA" component={ReportTest} />
        

        <ProtectedRoute exact path="/reports" component={AreportsList} />
        <ProtectedRoute exact path="/reportsgrid" component={FancyGrid} />
        <ProtectedRoute exact path="/genericReports" component={ReportsList} />
        <ProtectedRoute path="/screeningWeek" component={Reg004} />

        <ProtectedRoute path="/genericReports/:key" component={Report} />

        <ProtectedRoute exact path="/events" component={SystemEventsList} />
        <ProtectedRoute exact path="/register" component={RegistrationList} />
        <ProtectedRoute exact path="/cohort" component={CohortPlotly} />

        <ProtectedRoute
          exact
          path="/register/edit/:email"
          component={RegistrationEdit}
        />

        <ProtectedRoute
          exact
          path="/register/new"
          component={RegisterNewUser}
        />
        <ProtectedRoute
          exact
          path="/register/new/:email"
          component={RegisterNewUser}
        />

        <ProtectedRoute exact path="/programs" component={ProgramsList} />
        <ProtectedRoute exact path="/cohorts" component={Cohorts} />
        <ProtectedRoute exact path="/studies" component={Studies} />

        <ProtectedRoute path="/programs/:key" component={ProgramDetail} />

        <ProtectedRoute exact path="/surveyMonkey" component={SurveySync} />
        <ProtectedRoute exact path="/:version/forms" component={FormList} />
        <ProtectedRoute exact path="/:version/forms/:action/:formKey" component={FormCreateEdit} />
        <ProtectedRoute exact path="/:version/forms/:action" component={FormCreateEdit} />
        <ProtectedRoute exact path="/addEproKey" component={AddEproKey} />
        <ProtectedRoute path="/surveysaurus" component={Surveysaurus} />
        <ProtectedRoute path="/nudging" component={Nudging} />
        <ProtectedRoute exact path="/flagsmithchange" component={FlagsmithLink} />

        <ProtectedRoute exact path="/prescription/create" component={CreatePrescription}/>
        <ProtectedRoute exact path="/prescriptions" component={Prescriptions}/>
        <ProtectedRoute exact path="/prescriptions/:id" component={Prescription}/>

        <ProtectedRoute excat path="/sites" component={Sites}/>
      </Switch>
    </BrowserRouter>
  );
}

export default Application;
