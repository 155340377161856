import React, { useState } from "react";
import { Badge, Col, Table, Row, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { Route, Switch, useHistory, useRouteMatch } from "react-router-dom";
import StepEditor from "./StepEditors/StepEditor";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { LessonStepRow } from "./LessonStepRow";
import { cloneDeep } from "lodash";
import { firestore, auth } from "firebase";
import { NewStepModal } from "./NewStepModal";

const stepTypes = ["Text", "Prompt", "Audio", "Introduction", "Video"];

const LessonDetail = ({ activeLesson, setActiveLesson }) => {
  let history = useHistory();
  const match = useRouteMatch("/programs/:programID/lessons/:lessonID");
  const [isDragDisabled, setIsDragDisabled] = useState(true);
  const [stepModalState, setStepModalState] = useState({
    show: false,
    type: "",
  });

  const onDragEnd = (result, provided) => {
    // do nothing if the drop target is invalid or the row is dropped where it was originally dragged from
    if (
      !result.destination ||
      Number(result.draggableId) === result.destination.index
    ) {
      return;
    }

    const stepsCopy = cloneDeep(activeLesson.steps);
    const stepIndex = Number(result.draggableId);
    const targetIndex = result.destination.index;

    const [stepToMove] = stepsCopy.splice(stepIndex, 1);
    stepsCopy.splice(targetIndex, 0, stepToMove);

    firestore()
      .collection("programs")
      .doc(match.params["programID"])
      .collection("lessons")
      .doc(match.params["lessonID"])
      .update({
        steps: stepsCopy,
        updated: new Date(),
        updatedBy: auth().currentUser.email,
      })
      .catch((error) => {
        console.error(error);
        alert(
          "An error occurred when trying to reorder steps. Check the developer console for details."
        );
      });
  };

  const AddStepButtons = ({ position }) => (
    <>
      {isDragDisabled &&
        stepTypes.map((stepType) => (
          <Button
            key={`${stepType}-btn-${position}`}
            style={{ marginRight: "10px" }}
            variant="primary"
            onClick={() => setStepModalState({ show: true, type: stepType })}
          >
            Add {stepType}
          </Button>
        ))}
    </>
  );

  const detailView = (
    <>
      <NewStepModal
        {...stepModalState}
        steps={activeLesson?.steps || []}
        programID={match.params["programID"]}
        lessonID={match.params["lessonID"]}
        close={() => {
          setStepModalState({ show: false, type: "" });
        }}
      />
      <div
        className="back-button"
        onClick={() => {
          setActiveLesson(null);
          history.goBack();
        }}
      >
        <FontAwesomeIcon icon={faArrowLeft}></FontAwesomeIcon> Go Back
      </div>
      <Col xs={12} className={"lesson-content"}>
      <Row style={{ marginLeft: "5px" }}>
          <h5 className={"table-title"}>
            Steps: Chapter {activeLesson.chNum}, Lesson {activeLesson.num}:{" "}
            {activeLesson.title}
          </h5>
        </Row>
        <Row style={{ marginLeft: "5px" }}>
          <h5 className={"table-title"}>
            <FontAwesomeIcon color="#CCCC00" icon={faExclamationCircle} /> Steps highlighted in <span style={{color: '#CCCC00'}}>yellow</span> have an image file, but no specified image file type. These images will default to be a waved hero image. Please re-save the step with the desired image format.
          </h5>
        </Row>
        <Row style={{ marginLeft: "5px", marginBottom: "10px" }}>
          <Button
            style={{ marginRight: "10px" }}
            variant={isDragDisabled ? "info" : "warning"}
            onClick={() => setIsDragDisabled(!isDragDisabled)}
          >
            {isDragDisabled ? "Enable reordering" : "Disable reordering"}
          </Button>

          <AddStepButtons position="top" />
        </Row>
        {!isDragDisabled && (
          <Row>
            <Col>
              <p>Steps can be reordered by dragging and dropping table rows.</p>
            </Col>
          </Row>
        )}
        <Row style={{ marginLeft: "5px" }}>
          {activeLesson.steps === undefined && (
            <h5>No steps have been added yet</h5>
          )}
          <DragDropContext onDragEnd={onDragEnd}>
            <Table bordered hover responsive="xl">
              <thead>
                <tr>
                  <th>Number</th>
                  <th>Type</th>
                  <th>Title</th>
                  <th>Content</th>
                  <th>Media</th>
                  <th>Action</th>
                </tr>
              </thead>
              <Droppable droppableId="lessonStepDroppable">
                {(droppableProvided) => (
                  <>
                    <tbody
                      {...droppableProvided.droppableProps}
                      ref={droppableProvided.innerRef}
                    >
                      {activeLesson?.steps?.map((step, index) => (
                        <Draggable
                          key={`step-row-${index}`}
                          draggableId={index.toString()}
                          index={index}
                          isDragDisabled={isDragDisabled}
                        >
                          {(draggableProvided, draggableSnapshot) => (
                            <LessonStepRow
                              step={step}
                              activeLesson={activeLesson}
                              index={index}
                              isDragDisabled={isDragDisabled}
                              draggableProvided={draggableProvided}
                              draggableSnapshot={draggableSnapshot}
                            />
                          )}
                        </Draggable>
                      ))}
                      {droppableProvided.placeholder}
                    </tbody>
                  </>
                )}
              </Droppable>
            </Table>
          </DragDropContext>
        </Row>
        <Row style={{ marginLeft: "5px", marginTop: "10px" }}>
          <AddStepButtons position="bottom" />
        </Row>
      </Col>
    </>
  );
  return (
    <Switch>
      <Route exact={true} path={match.url}>
        {detailView}
      </Route>

      <Route exact={false} path={match.url + "/:stepNumber"}>
        <StepEditor lesson={activeLesson}></StepEditor>
      </Route>
    </Switch>
  );
};

export default LessonDetail;
