import React, { useState } from "react";
import { useCollection } from "react-firebase-hooks/firestore";
import LoadingScreen from "../LoadingScreen";
import { firestore } from "../../firebase";
import AdminNavbar from "../AdminNavbar";
import { useHistory } from "react-router-dom";
import {
  ColumnChooser,
  Grid,
  Table as GridTable,
  TableColumnVisibility,
  TableHeaderRow,
  Toolbar,
} from "@devexpress/dx-react-grid-bootstrap4";
import "@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css";
import { IntegratedSorting, SortingState } from "@devexpress/dx-react-grid";

import { Col, Container, Jumbotron, Row } from "react-bootstrap";

const FancyGrid = (props) => {
  const query = firestore.collection("surveyAdherence");
  const [defaultHiddenColumnNames] = useState([]);

  const getCols = () => {
    if (!rows || rows.docs.length < 1) {
      return [];
    }
    const row = rows.docs[0].data();
    let keys = new Set();
    rows.docs.forEach((doc) => {
      const dat = doc.data();
      Object.keys(dat).forEach((k) => keys.add(k));
    });
    console.log("got some keys", keys);
    let fleas = Array.from(keys.values()).sort();
    const cols = fleas.map((key) => {
      return { name: key, title: key };
    });
    return cols;
  };

  let history = useHistory();
  const [rows, rowsLoading, rowsError] = useCollection(query, {
    snapshotListenOptions: { includeMetadataChanges: true },
  });

  return (
    <>
      <AdminNavbar />
      <Jumbotron>
        <Container fluid={true}>
          <Row>
            <Col>
              <h1>FIQR Adherence</h1>
            </Col>
          </Row>
        </Container>
      </Jumbotron>
      <Container fluid={true}>
        <p className="TitleA" style={{ paddingLeft: "20px" }}>
          By Participant
        </p>
      </Container>
      <Container
        fluid={true}
        style={{ backgroundColor: "white", width: "90%" }}
      >
        <Row>
          {rowsError && (
            <>
              <strong>Error: {JSON.stringify(rowsError)}</strong>
              <br></br>
            </>
          )}
          {rowsLoading && (
            <>
              <strong>Subjects Loading: {JSON.stringify(rowsError)}</strong>
              <br></br>
              <LoadingScreen></LoadingScreen>
            </>
          )}
          {rows && (
            <Col xs={12}>
              <Row>
                <Grid rows={rows.docs.map((r) => r.data())} columns={getCols()}>
                  <SortingState />

                  <IntegratedSorting />
                  <GridTable />
                  <TableColumnVisibility
                    defaultHiddenColumnNames={defaultHiddenColumnNames}
                  />

                  <TableHeaderRow showSortingControls />

                  <Toolbar />
                  <ColumnChooser />
                </Grid>
              </Row>
            </Col>
          )}
        </Row>
      </Container>
    </>
  );
};

export default FancyGrid;
