import moment from "moment";
import React, { MouseEvent, useState } from "react";
import { Button, Col, Row, Table } from "react-bootstrap";
import { RouteComponentProps, useHistory } from "react-router";
import { firestore } from "../../../firebase";
import LandingPage from "../../../Model/LandingPage";
import { useCollectionClassData } from "../../../utils/Hooks";
import LoadingScreen from "../../LoadingScreen";

interface LandingPageRowProps {
  landingPage: LandingPage;
}

const LandingPageRow: React.FC<LandingPageRowProps> = ({ landingPage }) => {
  const history = useHistory();
  const [deleted, setDeleted] = useState(2);

  const handleDeleteClick = (event: MouseEvent) => {
    event.stopPropagation();
    if (deleted === 2) {
      setDeleted(1);
    }
    else {
      LandingPage.deleteLandingPage(landingPage.landingPageKey);
      setDeleted(0);
    }
  }

  return deleted === 0 ?
    null
    :
    <tr>
      <td>
        {landingPage.landingPageKey}
      </td>
      <td>
        {landingPage.study}
      </td>
      <td>
        {landingPage.trialID}
      </td>
      <td>
        {landingPage.postBaselineProgram}
      </td>
      <td>
        {moment(landingPage.created).format("MM/DD/YY")}
      </td>
      <td>
        <Button className="mr-2" onClick={() => history.push("/surveysaurus/landingpages/edit/" + landingPage.landingPageKey)}>
          Edit
        </Button>
        <Button onClick={handleDeleteClick} className={deleted === 1 ? 'btn-danger' : ''}>
          {
            deleted === 2 ?
              'Delete'
              :
              deleted === 1 &&
              'Confirm Delete'
          }
        </Button>
      </td>
    </tr>
}

const LandingPageList: React.FC<RouteComponentProps> = () => {
  const [landingPages, loading] = useCollectionClassData(LandingPage, firestore.collection("web/surveysaurus/landingPages"));

  return (
    <Col>
      <Row>
        <Col>
          <h2>Landing pages</h2>
        </Col>
      </Row>
      <Row>
        <Col>
          {
            loading ?
              <LoadingScreen />
              :
              <Table striped responsive className="table-center-rows">
                <thead>
                  <tr>
                    <th>Landing page key</th>
                    <th>Study</th>
                    <th>Trial ID</th>
                    <th>Post Baseline Program</th>
                    <th>Created</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {landingPages?.map((landingPage) => {
                    return (
                      <LandingPageRow key={landingPage.landingPageKey} landingPage={landingPage} />
                    )
                  })}
                </tbody>
              </Table>
          }
        </Col>
      </Row>
    </Col>
  )
}

export default LandingPageList;
