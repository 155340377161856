import { firestore } from "firebase";
import React, { useMemo, useState } from "react";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
  ResponderProvided,
} from "react-beautiful-dnd";
import { Button, Col, Container, Row, Table } from "react-bootstrap";
import {
  useCollection,
  useCollectionData,
} from "react-firebase-hooks/firestore";
import { Resource } from "../../Model/Resource";
import useRole from "../../UseRole";
import LoadingScreen from "../LoadingScreen";
import ResourcesFormModal from "./ResourcesFormModal";

interface ResourcesListProps {
  programKey: string;
}

const ResourcesList = ({ programKey }: ResourcesListProps) => {
  const [showAddModal, setShowAddModal] = useState(false);
  const [dragAndDropDisabled, setdragAndDropDisabled] = useState(true);
  const [selectedResource, setSelectedResource] = useState<Resource>(null);
  const isAdmin = useRole("admin");
  const query = firestore()
    .collection(`/programs/${programKey}/resourceLinks`)
    .orderBy("order", "asc");

  const [resourcesData, resourcesLoading, resourcesError] = useCollection(
    query
  );

  const resources = useMemo(() => {
    if (!resourcesData) {
      return [];
    }
    return resourcesData.docs.map((doc) => Resource.fromFirestore(doc));
  }, [resourcesData]);

  if (resourcesLoading) {
    return <LoadingScreen />;
  }

  const deleteResource = async (resource: Resource) => {
    if (
      window.confirm(
        `Are you sure you want to delete the "${resource.text}" resource?`
      )
    ) {
      const batch = firestore().batch();
      try {
        batch.delete(resource.query(programKey));

        const newResources = resources.filter(_resource => _resource.id !== resource.id);
        newResources.forEach((resource, index) => {
          resource.order = index + 1;
          resource.addToBatch(batch, programKey);
        });

        await batch.commit();
        window.alert("resource deleted successfully!");
      } catch (error) {
        window.alert(
          "There was a problem with deleting this resource. Check the developer console for details"
        );
        console.error(error);
      }
    }
  };

  const onDragEnd = async (result: DropResult, provided: ResponderProvided) => {
    if (
      !result.destination ||
      Number(result.draggableId) === result.destination.index
    ) {
      return;
    }
    const batch = firestore().batch();

    const resourcesCopy = [...resources];
    const resourceIndex = Number(result.draggableId);
    const targetIndex = result.destination.index;

    const [resourceToMove] = resourcesCopy.splice(resourceIndex, 1);
    resourcesCopy.splice(targetIndex, 0, resourceToMove);

    resourcesCopy.forEach((resource, index) => {
      resource.order = index + 1;
      resource.addToBatch(batch, programKey);
    });

    try {
      await batch.commit();
    } catch (error) {
      window.alert(
        `Could not reorder resources. Check the developer console for details.`
      );
      console.error(error);
    }
  };

  return (
    <Container fluid>
      <ResourcesFormModal
        programKey={programKey}
        resourcesLength={resources.length}
        selectedResource={selectedResource}
        show={showAddModal}
        close={() => {
          setShowAddModal(false);
          setSelectedResource(null);
        }}
      />
      {isAdmin && (
        <Row style={{ marginBottom: 5 }}>
          <Col>
            <Button
              variant="outline-primary"
              style={{ marginRight: "5px" }}
              onClick={() => setShowAddModal(true)}
            >
              Add Resource
            </Button>

            <Button
              variant={dragAndDropDisabled ? "outline-info" : "outline-warning"}
              style={{ marginRight: "5px" }}
              onClick={() => setdragAndDropDisabled(!dragAndDropDisabled)}
            >
              {dragAndDropDisabled ? "Enable reordering" : "Disable reordering"}
            </Button>
          </Col>
        </Row>
      )}

      {!dragAndDropDisabled && (
        <Row>
          <Col>
            <p>
              Resources can be reordered by dragging and dropping table rows.
            </p>
          </Col>
        </Row>
      )}

      <Row>
        <Col>
          <DragDropContext onDragEnd={onDragEnd}>
            <Table bordered hover responsive="xl">
              <thead>
                <tr>
                  <th>Order</th>
                  <th>Text</th>
                  <th>Url</th>
                  {dragAndDropDisabled && isAdmin && <th></th>}
                </tr>
              </thead>
              <Droppable droppableId="resourceDroppable">
                {(droppableProvided) => (
                  <>
                    <tbody
                      {...droppableProvided.droppableProps}
                      ref={droppableProvided.innerRef}
                    >
                      {resources.map((resource, index) => (
                        <Draggable
                          key={`resource-row-${index}`}
                          draggableId={index.toString()}
                          index={index}
                          isDragDisabled={dragAndDropDisabled}
                        >
                          {(draggableProvided) => (
                            <tr
                              key={`resource-${index}`}
                              ref={draggableProvided.innerRef}
                              {...draggableProvided.draggableProps}
                              {...draggableProvided.dragHandleProps}
                            >
                              <td>{resource.order}</td>
                              <td>{resource.text}</td>
                              <td>
                                <a href={resource.url}>{resource.url}</a>
                              </td>
                              {dragAndDropDisabled && isAdmin && (
                                <td>
                                  <Button
                                    variant="outline-info"
                                    style={{ margin: 5 }}
                                    onClick={() => {
                                      setSelectedResource(resource);
                                      setShowAddModal(true);
                                    }}
                                  >
                                    Edit
                                  </Button>
                                  <Button
                                    variant="outline-danger"
                                    style={{ margin: 5 }}
                                    onClick={() => deleteResource(resource)}
                                  >
                                    Delete
                                  </Button>
                                </td>
                              )}
                            </tr>
                          )}
                        </Draggable>
                      ))}
                      {droppableProvided.placeholder}
                    </tbody>
                  </>
                )}
              </Droppable>
            </Table>
          </DragDropContext>
        </Col>
      </Row>
    </Container>
  );
};

export default ResourcesList;
