import React from "react";
import { IconDefinition } from "@fortawesome/fontawesome-common-types";
import { Col, NavLink, Row, NavItem } from "react-bootstrap";
import {
  NavLink as RouterNavLink,
  useLocation,
} from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface NavLinkerProps {
  baseUrl: string; // Base url to append new route to
  route: string; // The new route
  label: string; // Label to appear in nav
  icon: IconDefinition; // Icon to appear in nav
  subLink?: boolean; // Flags if the link is a sub link, addes extra padding
  state?: object; // Pass state to the component
  children?:
  | React.ReactElement<NavLinkerProps>
  | React.ReactElement<NavLinkerProps>[];
}

// This component is use to create side bar links on pages
// Vuew UserDetail.tsx for an example
const NavLinker: React.FC<NavLinkerProps> = ({
  baseUrl,
  route,
  label,
  icon,
  subLink,
  children,
  state
}) => {
  const pathname = useLocation().pathname;
  // Flags if the current route is this link
  const isActive = pathname === baseUrl + route;
  // Flags if the current route is a parent of a sublink
  const partialMatch = pathname.includes(baseUrl + route);

  return (
    <NavItem bsPrefix={subLink ? "nav-sub-link" : ""}>
      <NavLink as={RouterNavLink} to={{ pathname: baseUrl + route, state }}>
        <Row>
          <Col>
            <strong className={isActive ? "text-primary" : "text-secondary"}>
              {label}
            </strong>
          </Col>
          <Col xs={"auto"}>
            {" "}
            <FontAwesomeIcon
              className={isActive ? "text-primary" : "text-secondary"}
              icon={icon}
            />
          </Col>
        </Row>
      </NavLink>
      {
        // If there is a partial (or full) match then display sub links if they exist
        partialMatch && children
      }
    </NavItem>
  );
};

export default NavLinker;