import React, { useState, useEffect } from "react";
import moment from "moment";
import { Col, Container, Row, Table, FormControl } from "react-bootstrap";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { firestore } from "../../../firebase";
import User from "../../../Model/Usert";
import WorkflowCharts from "./WorkflowCharts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleDown, faArrowCircleUp, faArrowDown, faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { isExcludedEmail } from "./ExcludeFromStats";
import { UserStatus } from "@swing-therapeutics/surveybay/dist/types";

const SurveysaurusStats: React.FC<RouteComponentProps> = () => {

  return (
    <Container>
      <Row>
        <h3>
          Stats
        </h3>
      </Row>
      <Row className="justify-content-center">
        <WorkflowCharts />
      </Row>
      <Row className="mt-3">
        <h3>
          Users at each workflow state
        </h3>
      </Row>
      <Row>
        <Col>
          <Row className="mt-3">
            <Table striped responsive className="table-center-rows">
              <thead>
                <tr>
                  <th>Workflow status</th>
                  <th># of users</th>
                  <th>Workflow info</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <WorkflowInfo workflowStatus={UserStatus.CREATED} />
                <WorkflowInfo workflowStatus={UserStatus.HOLDING_PRE_SCREEN} />
                <WorkflowInfo workflowStatus={UserStatus.SCREENING} />
                <WorkflowInfo workflowStatus={UserStatus.SCREENING_FAIL} />
                <WorkflowInfo workflowStatus={UserStatus.SCREENING_ABANDONED} />
                <WorkflowInfo workflowStatus={UserStatus.CONSENT} />
                <WorkflowInfo workflowStatus={UserStatus.CONSENT_FAIL} />
                <WorkflowInfo workflowStatus={UserStatus.CONSENT_ABANDONED} />
                <WorkflowInfo workflowStatus={UserStatus.BASELINE} />
                <WorkflowInfo workflowStatus={UserStatus.BASELINE_ABANDONED} />
                <WorkflowInfo workflowStatus={UserStatus.PRE_THERAPY} />
                <WorkflowInfo workflowStatus={UserStatus.NEVER_STARTED} />
                <WorkflowInfo workflowStatus={UserStatus.THERAPY} />
                <WorkflowInfo workflowStatus={UserStatus.EARLY_TERMINATION_FOLLOWUP} />
                <WorkflowInfo workflowStatus={UserStatus.EARLY_TERMINATION} />
                <WorkflowInfo workflowStatus={UserStatus.DONE} />
                <WorkflowInfo workflowStatus={UserStatus.EXT_ELIGIBLE} />
                <WorkflowInfo workflowStatus={UserStatus.EXT_NEVER_STARTED} />
                <WorkflowInfo workflowStatus={UserStatus.EXT_EARLY_TERMINATION_FOLLOWUP} />
                <WorkflowInfo workflowStatus={UserStatus.EXT_EARLY_TERMINATION} />
              </tbody>
            </Table>
          </Row>
        </Col>
      </Row>
    </Container>
  )
}

export default SurveysaurusStats;

interface WorkflowInfoProps {
  workflowStatus: UserStatus;
}

interface WorkflowStats {
  totalUsers: number;
}

const sortByCreated = (users: User[], sort: 'asc' | 'dsc'): User[] => {
  if (sort === 'asc') {
    return users.slice().sort((u1, u2) => u2.created.getTime() - u1.created.getTime());
  }
  else {
    return users.slice().sort((u1, u2) => u1.created.getTime() - u2.created.getTime());
  }
}

const WorkflowInfo: React.FC<WorkflowInfoProps> = ({ workflowStatus }) => {
  const [stats, setStats] = useState<WorkflowStats>();
  const [users, setUsers] = useState<User[]>();
  const [expanded, setExpanded] = useState(false);
  const [searchFilter, setSearchFilter] = useState('');
  const [createdSort, setCreatedSort] = useState<'asc' | 'dsc'>('asc');
  const workflowInfo = workflowStatusInfo[workflowStatus];

  useEffect(() => {
    const unsub = firestore.collection("users")
      .where("workflowStatus", "==", workflowStatus)
      .onSnapshot((snap) => {
        const users: User[] = [];
        for (const userDoc of snap.docs) {
          const user = User.fromFirestore(userDoc);
          if (!isExcludedEmail(user.email)) {
            users.push(user);
          }
        }

        setUsers(sortByCreated(users, 'asc'));
        setStats({
          totalUsers: users.length,
        })
      })

    return unsub;
  }, [workflowStatus])

  const handleCreatedClick = () => {
    if (createdSort === 'asc') {
      setUsers((users) => sortByCreated(users, 'dsc'));
      setCreatedSort('dsc');
    }
    else {
      setUsers((users) => sortByCreated(users, 'asc'));
      setCreatedSort('asc');
    }
  }

  return (
    <>
      <tr className="hoverablerow" onClick={() => setExpanded((prevState) => !prevState)}>
        <td>
          {
            expanded &&
            <FontAwesomeIcon icon={faArrowCircleDown} style={{ marginRight: 5 }} />
          }
          {workflowInfo.title}
        </td>
        <td>
          {stats ? stats.totalUsers : 'Loading...'}
        </td>
        <td colSpan={2}>
          {workflowInfo.info}
        </td>
      </tr>
      {
        expanded && (
          users ?
            <>
              <tr>
                <td colSpan={4}>
                  <FormControl
                    type="text"
                    value={searchFilter}
                    onChange={(e) => setSearchFilter(e.target.value)}
                    placeholder='Search...'
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <b>Name</b>
                </td>
                <td>
                  <b>Email</b>
                </td>
                <td>
                  <b>Active program</b>
                </td>
                <td className="hoverable" onClick={handleCreatedClick}>
                  <b>Created</b>
                  <FontAwesomeIcon icon={createdSort === 'asc' ? faArrowUp : faArrowDown} className="ml-2" />
                </td>
              </tr>
              {
                users.map((user) => {
                  return (
                    <UserRow key={user.uid} user={user} searchFilter={searchFilter} />
                  )
                })
              }
            </>
            :
            <tr>
              <td colSpan={4}>
                Loading...
              </td>
            </tr>
        )
      }
      {
        expanded &&
        <tr>
          <td colSpan={4}>
            <FontAwesomeIcon icon={faArrowCircleUp} style={{ marginRight: 5 }} />
            {workflowInfo.title}
          </td>
        </tr>
      }
    </>
  )
}

interface UserRowProps {
  user: User;
  searchFilter: string;
}

const UserRow: React.FC<UserRowProps> = ({ user, searchFilter }) => {
  const history = useHistory();

  const handleRowClick = () => {
    history.push(`/users/${user.uid}`);
  }

  return (
    <>
      {
        (searchFilter === '' ||
          user.email.toLowerCase().includes(searchFilter.toLowerCase()) ||
          user.displayName.toLowerCase().includes(searchFilter.toLowerCase())) &&
        <tr className="hoverablerow" onClick={handleRowClick}>
          <td>
            {user.displayName}
          </td>
          <td>
            {user.email}
          </td>
          <td>
            {user.activeProgram ? user.activeProgram : 'Not assigned to program'}
          </td>
          <td>
            {moment(user.created).format("MM/DD/yyyy")}
          </td>
        </tr>
      }
    </>
  )
}

export const workflowStatusInfo: { [key in UserStatus]: { title: string, info: string } } = {
  [UserStatus.CREATING]: {
    title: 'Creating',
    info: 'User\'s account is being created. This status is never in firestore, only on surveysaurus.',
  },
  [UserStatus.EARLY_TERMINATION_FOLLOWUP]: {
    title: 'Early termination followup',
    info: 'User has been terminated early and is following up.'
  },
  [UserStatus.EARLY_TERMINATION]: {
    title: 'Early termination',
    info: 'User was removed from the study before completion.',
  },
  [UserStatus.CREATED]: {
    title: 'Created',
    info: "User's account was created through Surveysaurus. Cloud function quickly moves users to holding pre-screen or screening.",
  },
  [UserStatus.HOLDING_PRE_SCREEN]: {
    title: 'Holding pre-screen',
    info: 'Users are in holding before manually being moving to screening.',
  },
  [UserStatus.SCREENING]: {
    title: 'Screening',
    info: 'Users are working through the eligibility screening survey.',
  },
  [UserStatus.SCREENING_ABANDONED]: {
    title: 'Screening abandoned',
    info: 'Users did not complete the eligibility screening survey before it expired.',
  },
  [UserStatus.SCREENING_FAIL]: {
    title: 'Screening fail',
    info: 'Users failed the eligibility screening survey.',
  },
  [UserStatus.CONSENT]: {
    title: 'Consent',
    info: 'Users are waiting for their consent call with the clinops team.',
  },
  [UserStatus.CONSENT_FAIL]: {
    title: 'Consent fail',
    info: 'Users did not pass their consent call.',
  },
  [UserStatus.CONSENT_ABANDONED]: {
    title: 'Consent abandoned',
    info: 'User abandoned consent call.',
  },
  [UserStatus.BASELINE]: {
    title: 'Baseline',
    info: 'Users are working through their baseline survey.',
  },
  [UserStatus.BASELINE_ABANDONED]: {
    title: 'Baseline abandoned',
    info: 'Users did not complete their baseline survey before it expired.',
  },
  [UserStatus.PRE_THERAPY]: {
    title: 'Pre-therapy',
    info: 'Users completed baseline survey but have not yet opened the Tempo app.',
  },
  [UserStatus.NEVER_STARTED]: {
    title: 'Never started',
    info: 'User was in pre-therapy status for more than 7 days, never opened the app.'
  },
  [UserStatus.THERAPY]: {
    title: 'Therapy',
    info: 'Users are working through therapy / therapy surveys.',
  },
  [UserStatus.DONE]: {
    title: 'Done',
    info: 'Users have completed all steps of the program.',
  },
  [UserStatus.EXT_ELIGIBLE]: {
    title: 'EXT Eligible',
    info: 'Users have completed all steps of the program and are eligible for extension.',
  },
  [UserStatus.EXT_DECLINED]: {
    title: 'EXT Declined',
    info: 'User was eligible for extension but declined to be in the extension.',
  },
  [UserStatus.EXT_CONSENT]: {
    title: 'EXT Consent',
    info: 'Users is waiting for their consent call with the clinops team to participate in the extension.',
  },
  [UserStatus.EXT_CONSENT_FAIL]: {
    title: 'EXT Consent fail',
    info: 'Users did not pass their consent call for the extension.',
  },
  [UserStatus.EXT_CONSENT_ABANDONED]: {
    title: 'EXT consent abandoned',
    info: 'User abandoned extension consent call.',
  },
  [UserStatus.EXT_PRE_THERAPY]: {
    title: 'EXT pre-therapy',
    info: 'Users completed extension consent but has not yet opened the Tempo app.',
  },
  [UserStatus.EXT_NEVER_STARTED]: {
    title: 'EXT Never started',
    info: 'User was in EXT pre-therapy status for more than 7 days, never opened the app.'
  },
  [UserStatus.EXT_THERAPY]: {
    title: 'EXT Therapy',
    info: 'Users are working through extension therapy / extension therapy surveys.',
  },
  [UserStatus.EXT_EARLY_TERMINATION_FOLLOWUP]: {
    title: 'EXT Early termination followup',
    info: 'User has been terminated early from th extension study and is following up.'
  },
  [UserStatus.EXT_EARLY_TERMINATION]: {
    title: 'EXT Early termination',
    info: 'User was removed from the extension of the study.',
  },
  [UserStatus.EXT_DONE]: {
    title: 'EXT Done',
    info: 'Users have completed all steps of the extension program',
  }
}
