import { useFormik } from "formik";
import React from "react";
import { Button, Modal, Form, Col } from "react-bootstrap";
import { Lesson } from "@swing-therapeutics/swingcore/dist/models/content/Lesson";
import * as yup from "yup";
import { formikHasError } from "../../../../utils/FormikHelpers";
import { firestore } from "firebase";

interface LessonModalProps {
  lesson: Lesson;
  lessons: Lesson[];
  programKey: string;
  close: () => void;
}

interface InitialValueType {
  chNum: number;
  title: string;
  description: string;
  num: number;
  type: SelectionType;
  unlockedByDefault: boolean;
  image_filename: string;
}

enum SelectionType {
  chapterType = "chapterType",
  numType = "numType",
  empty = "",
}

export const EditLessonModal = ({
  lesson,
  lessons,
  programKey,
  close,
}: LessonModalProps) => {
  const initialValues: InitialValueType = {
    type: SelectionType.empty,
    chNum: lesson.chNum ?? 0,
    title: lesson.title ?? "",
    description: lesson.description ?? "",
    num: lesson.num ?? 0,
    unlockedByDefault: lesson.unlockedByDefault ?? false,
    image_filename: lesson.image_filename ?? "",
  };

  const { values, errors, handleSubmit, handleChange, touched, setFieldValue } = useFormik({
    initialValues,
    validationSchema: yup.object().shape({
      title: yup
        .string()
        .required("Title is required")
        .min(1, "Title must be longer than 0"),
      chNum: yup.number().required("Chapter number is required"),
      num: yup.string().required("Lesson number is required"),
      image_filename: yup.string(),
    }),
    onSubmit: async (values) => {
      const oldNum = lesson.num;
      const chapter = lesson.chNum;

      lesson.title = values.title;
      lesson.description = values.description;
      lesson.unlockedByDefault = values.unlockedByDefault;
      lesson.image_filename = values.image_filename;

      if (values.type === "chapterType") {
        updateChapterNumber(lesson, chapter, oldNum);
        lesson.persist();
      }

      if (values.type === "numType") {
        const targetNum = Number(values.num);
        updateLessonNumber(targetNum, chapter);
        deleteLesson(lesson, chapter, oldNum);
        lesson.num = targetNum;
        lesson.persist();
      }

      lesson.persist()

      close();
    },
  });

  /**
   * @param targetNum The target number to update the lesson by
   * @param chapter The current chapter
   */
  const updateLessonNumber = (targetNum: number, chapter: number) => {
    const lessonsInChapter = lessons.filter(
      (lesson) => lesson.chNum === chapter
    );
    const oldNum = lesson.num;
    for (let i = 0; i < lessonsInChapter.length; i++) {
      if (targetNum <= lessonsInChapter[i].num && oldNum > lessonsInChapter[i].num) {
        lessonsInChapter[i].num += 1;
      }
      
    }

    lessonsInChapter.forEach((lesson) => lesson.persist());
  };

  /**
   * @param lesson The lesson to update
   * @param chapter The chapter to update the lesson by
   * @param number The number to update the lesson by
   */
  const updateChapterNumber = (
    lesson: Lesson,
    chapter: number,
    number: number
  ) => {
    const filteredByChapter = [];
    const filteredByOldChapter = getLessonsFromChapter(chapter);
    for (let i = 0; i < filteredByOldChapter.length; i++) {
      if (number < filteredByOldChapter[i].num) {
        filteredByOldChapter[i].num -= 1;
      }
    }
    filteredByOldChapter.forEach((lesson) => lesson.persist());

    lesson.chNum = Number(values.chNum);
    if (values.chNum !== lesson.chNum) {
      for (let l of lessons) {
        if (l.chNum === Number(values.chNum)) {
          filteredByChapter.push(l);
        }
      }
      let index = filteredByChapter.length;
      lesson.num = index;

      deleteLesson(lesson, chapter, number);
    }
  };

  const getLessonsFromChapter = (chapter: number) => {
    return lessons.filter((lesson) => lesson.chNum === chapter);
  };

  const deleteLesson = async (lesson: Lesson, chNum: number, num: number) => {
    await firestore()
      .collection("programs")
      .doc(programKey)
      .collection("lessons")
      .doc(lesson.id)
      .delete();
  };

  const hasError = formikHasError(errors);

  return (
    <Modal show onHide={close}>
      <Modal.Header>
        <Modal.Title>Edit Lesson: {lesson.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group as={Col} xs={12}>
          <Form.Label>Title</Form.Label>
          <Form.Control
            type="text"
            name="title"
            value={values.title}
            onChange={handleChange}
            isInvalid={errors.title && touched.title}
          />
          <Form.Control.Feedback type="invalid">{errors.title}</Form.Control.Feedback>
        </Form.Group>

        <Form.Group as={Col} xs={12}>
          <Form.Label>Description</Form.Label>
          <Form.Control
          as="textarea"
          rows={4}
          name="description"
          value={values.description}
          onChange={handleChange}
          placeholder={"Description..."}
        />
        </Form.Group>

        <Form.Group as={Col} xs={12}>
          <Form.Label>Image Filename</Form.Label>
          <Form.Control
          as="input"
          name="image_filename"
          value={values.image_filename}
          onChange={handleChange}
          placeholder={"Description..."}
        />
        </Form.Group>

        <Form.Group as={Col} xs={12}>
          <Form.Label>Unlocked By Default</Form.Label>
          <Form.Switch
            id="unlocked-by-default"
            name="unlockedByDefault"
            type="checkbox"
            onChange={() => setFieldValue('unlockedByDefault', !values.unlockedByDefault ?? true)}
            checked={values.unlockedByDefault}
          />
        </Form.Group>

        <Col>
          <Form.Row>
            <Form.Label>Chapter or Number Edit</Form.Label>
            <Form.Control
              name="type"
              value={values.type}
              onChange={handleChange}
              as="select"
            >
              <option value={undefined} selected>
                Pick A Type...
              </option>
              <option value={SelectionType.chapterType}>Chapter</option>
              <option value={SelectionType.numType}>Number</option>
            </Form.Control>
          </Form.Row>
        </Col>

        {values.type === "chapterType" && (
          <Col>
            <Form.Row>
              <Form.Group>
                <Form.Label>Chapter Number</Form.Label>
                <Form.Control
                  type="text"
                  name="chNum"
                  value={values.chNum}
                  onChange={handleChange}
                />
              </Form.Group>
            </Form.Row>
          </Col>
        )}

        {values.type === "numType" && (
            <Col>
              <Form.Row>
                <Form.Group>
                  <Form.Label>Lesson Number</Form.Label>
                  <Form.Control
                    type="text"
                    name="num"
                    value={values.num}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Form.Row>
            </Col>
        )}
      </Modal.Body>
      <Modal.Footer>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <Button
            variant="danger"
            style={{ marginRight: 30 }}
            onClick={() => close()}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              handleSubmit();
            }}
            variant={hasError ? "danger" : "primary"}
          >
            Save
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};
