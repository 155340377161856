import { useField } from "formik";
import React, { useEffect} from "react";
import { Button, Col, Row } from "react-bootstrap";
import { FormikTextField } from "../../FormikFields";

const AddFaqDisplay = (props) => {
  const {initialFormValues} = props
  const [fieldInfo, meta, helpers] = useField("faqs");
  const { value } = meta;
  const { setValue } = helpers;

  const addFaq = () => {
    setValue(
      value.concat({
        question: "",
        answer: "",
      })
    );
  };

  const removeFaq = (index: number) => {
    setValue(value.filter((_x, i) => i !== index));
  };

  useEffect(() => {
    if (initialFormValues) {
      setValue(initialFormValues.faqs);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialFormValues]);

  return (
    <Col>
      <Row><h3>Frequently Asked Questions</h3></Row>
      <Button style={{marginBottom: 10}} onClick={addFaq}>Add FAQ</Button>
      {value?.map((faq, index) => (
        <>
          <Row key={index} className="mb-3">
            <Col>
              <FormikTextField
                name={`faqs[${index}].question`}
                label={`FAQ #${index + 1}`}
                multiLine={true}
              />
            </Col>
            <Col>
              <FormikTextField
                name={`faqs[${index}].answer`}
                label={`Answer to FAQ # ${index + 1}`}
                multiLine={true}
              />
            </Col>
            <Col
              xl={1}
              style={{
                display: "flex",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <Button onClick={() => removeFaq(index)}>X</Button>
            </Col>
          </Row>
        </>
      ))}
    </Col>
  );
};

export default AddFaqDisplay;
