import {auth, firestore} from "../firebase";
import {User} from "./User";
import {SystemEvent} from "./SystemEvent";
import {setDateProp, setProp} from "../util";

class Subject {
    constructor() {
    }

    static fromFirestore(docRef) {
        if (!docRef.exists) return;
        let s = new Subject();
        const docData = docRef.data();
        s.subjectID = docRef.id;
        s.subjectID = docData.subjectID;
        s.firstName = docData.firstName ? docData.firstName : "";
        s.lastName = docData.lastName ? docData.lastName : "";
        s.email = docData.email;
        s.smsNumber = docData.smsNumber;
        s.smsOptOut = docData.smsOptOut;
        s.study = docData.study;
        s.activeProgram = docData.activeProgram ? docData.activeProgram : "tempo1";
        s.site = docData.site;

        s.cohort = docData.cohort;
        s.role = docData.role;
        s.surveyMonkeyID = docData.surveyMonkeyID;
        s.zendeskID = docData.zendeskID;
        s.dateCreated = docData.dateCreated ? docData.dateCreated.toDate() : null;
        s.lastUpdated = docData.lastUpdated ? docData.lastUpdated.toDate() : new Date();
        s.uid = docData.uid;
        s.addedBy = docData.addedBy;
        s.updatedBy = docData.updatedBy;
        s.registered = docData.registered ? docData.registered : false;
        return s;
    }

    static fromParams(email, subjectID, fullName, activeProgram, study, cohort, role, smsNumber, smsOptOut, surveyMonkeyID, zendeskID, uid) {
        if (!subjectID) {
        }
        let s = new Subject();
        if (!subjectID) {
            console.log("unable to create without subject ID");
            return;
        }
        s.subjectID = subjectID;

        s.activeProgram = activeProgram ? activeProgram : "tempo1";
        s.fullName = fullName;
        s.email = email;
        s.smsNumber = smsNumber;
        s.smsOptOut = smsOptOut;
        s.study = study;
        s.cohort = cohort;
        s.role = role;
        if (surveyMonkeyID) {
            s.surveyMonkeyID = surveyMonkeyID;
        }
        if (zendeskID) {
            s.zendeskID = surveyMonkeyID;
        }
        s.dateCreated = new Date();
        s.lastUpdated = new Date();
        s.uid = uid;
        s.addedBy = auth.currentUser.email;
        s.updatedBy = auth.currentUser.email;
        s.persist();
        return s;
    }

    static async subjectWithEmail(email) {
        const existingUser = await firestore
            .collection("subjects")
            .where("email", "==", email)
            .get();

        if (existingUser.empty) return null;
        var userRef = existingUser.docs[0];

        return await Subject.fromFirestore(userRef);
    }

    static async create(email, subjectID, fullName, study, cohort, role, smsNumber, smsOptOut, surveyMonkeyID, zendeskID, activeProgram, uid, eventGroup) {
        if (!email) {
            console.log("email required to create subject");
            return;
        }
        if (!subjectID) {
            console.log("blank subject id supplied");
            return;
        }
        if (!eventGroup) eventGroup = "new-subject-" + new Date().getTime().toString();
        let s = Subject.fromParams(email, subjectID, fullName, activeProgram, study, cohort, role, smsNumber, smsOptOut, surveyMonkeyID, zendeskID, uid);

        await s.persist();

        let props = {
            email,
            subjectID,
            fullName,
            activeProgram,
            study,
            cohort,
            role,
            smsNumber,
            smsOptOut,
            surveyMonkeyID,
            zendeskID,
            uid,
        };

        //(type, category, message, eventGroup, props)
        SystemEvent.fireEvent("Subject Created", "Subjects", " A new subject (id:" + subjectID + ") was created for " + email, eventGroup, props);

        await RegistrationRecord.createRegistrationFromSubject(s, eventGroup);
        await RegistrationRecord.register(email, eventGroup);
    }

    //firestore helpers
    data() {
        let dat = {};
        for (const [key, value] of Object.entries(this)) {
            if (this[key]) {
                this[key] = value;
            }
        }
        return dat;
    }

    async persist() {
        if (!this.subjectID) return;
        let data = this.data();
        data.lastUpdated = new Date();
        if (auth && auth.currentUser && auth.currentUser.email) {
            data.updatedBy = auth.currentUser.email;
        }

        let x = await firestore
            .collection("subjects")
            .doc(this.subjectID)
            .set(data);
        return x;
    }

    matchesFilter(filter) {
        if (!filter || filter.length === 0) return true;
        return (this.email.toLowerCase().includes(filter.toLowerCase()) || this.fullName.toLowerCase().includes(filter.toLowerCase()) || this.subjectID.toLowerCase().includes(filter.toLowerCase()));
    }
}

class RegistrationRecord {
    constructor() {
    }

    static fromFirestore = (docRef) => {
        let se = new RegistrationRecord();
        se.id = docRef.id;
        const dat = docRef.data();
        for (const [key, value] of Object.entries(dat)) {
            se[key] = value;
        }
        //forcing defaults
        setProp("subjectID", se, dat, null);
        setProp("uid", se, dat, null);
        setProp("zendeskID", se, dat, null);
        setProp("surveyMonkeyID", se, dat, null);
        setProp("role", se, dat, "patient");
        setProp("study", se, dat, "003");
        setProp("cohort", se, dat, "default");
        setProp("registered", se, dat, false);
        setDateProp("created", se, dat, new Date());

        return se;
    };

    static async recordForEmail(email) {
        const existingUser = await firestore
            .collection("registrations")
            .where("email", "==", email)
            .get();

        if (existingUser.empty) return null;
        const userRef = existingUser.docs[0];
        return await RegistrationRecord.fromFirestore(userRef);
    }

    static async createRegistrationFromSubject(subject, eventGroup) {
        let rec = new RegistrationRecord();
        rec.subjectID = subject.subjectID;
        rec.email = subject.email;
        rec.id = subject.email;
        if (!subject.email) {
            return;
        }
        setProp("zendeskID", rec, subject, null);
        setProp("surveyMonkeyID", rec, subject, null);
        setProp("role", rec, subject, "patient");
        setProp("study", rec, subject, "003");
        setProp("cohort", rec, subject, "default");
        setProp("activeProgram", rec, subject, "tempo1");

        rec.registered = false;
        await rec.persist();
        let props = {
            subject: subject.subjectID, email: subject.email,
        };

        //(type, category, message, eventGroup, props)
        SystemEvent.fireEvent("Registration Record Created", "Registration", " A registration record  was created for " + subject.email, eventGroup, props);
    }

    static async register(email, eventGroup) {
        if (!eventGroup) eventGroup = "register-" + new Date().getTime().toString();
        let regRecord = await RegistrationRecord.recordForEmail(email);
        if (!regRecord) {
            SystemEvent.fireEvent("Registration Aborted", "Registration", " Unable to update registration information for " + email + ", no registration record exists", eventGroup, {
                email: email,
            });
            return;
        }
        let regUser = await User.userWithEmail(email);
        if (!regUser) {
            //(type, category, message, eventGroup, props)
            SystemEvent.fireEvent("Registration Aborted", "Registration", " Unable to update registration information for " + email + ", no user with matching email", eventGroup, {
                email: email,
            });
            return;
        }
        const originalUserData = regUser.data();
        const originalRegistrationRecord = regRecord.data();
        regUser.userProperties = {
            study: regRecord.study, cohort: regRecord.cohort, role: regRecord.role,
        };
        regUser.registrationUpdated = new Date();
        regUser.subjectID = regRecord.subjectID;
        regUser.zendeskID = regRecord.zendeskID;
        regRecord.uid = regUser.uid;
        regRecord.registered = true;
        regUser.activeProgram = regRecord.activeProgram;
        await regUser.persist();
        await regRecord.persist();
        SystemEvent.fireEvent("User Registered", "Registration", email + "has been registered with subjectID:" + regUser.subjectID, eventGroup, {
            originalUserData: originalUserData, originalRegistrationRecord: originalRegistrationRecord,
        });

        const regSubject = await Subject.subjectWithEmail(email);

        if (!regSubject) {
            SystemEvent.fireEvent("Subject Update Failed", "Registration", "unable to update registration information for  subjectID:" + regUser.subjectID, eventGroup, {
                email: email, subjectID: regUser.subjectID,
            });
            return;
        }
        regSubject.uid = regUser.uid;

        await regSubject.persist();
        SystemEvent.fireEvent("Subject Update Success", "Registration", "Subject: " + regUser.subjectID + " updated with new registration information", eventGroup, {
            email: email, subjectID: regUser.subjectID, uid: regUser.uid,
        });
    }

    data() {
        let dat = {};
        for (const [key, value] of Object.entries(this)) {
            dat[key] = value;
        }
        return dat;
    }

    async persist() {
        return await firestore
            .collection("registrations")
            .doc(this.email)
            .set(this.data());
    }
}

export {Subject, RegistrationRecord};
