import React, { useEffect, useState } from "react";
import { firestore } from "../../firebase";
import User from "../../Model/Usert";
import { Button, Table } from "react-bootstrap";
import RegForm from "../Registration/RegForm";
import { sendToUsersDevices } from "../../Model/Notifications";
import { SystemEvent } from "../../Model/SystemEvent";

const ScreeningEnrollment = (props: any) => {
  const [unregUsers, setUnregUsers] = useState<User[]>([]);
  const [regTime, setRegTime] = useState<any>();

  useEffect(() => {
    const subscriber = firestore.collection("users").onSnapshot((snap) => {
      let unreg: User[] = [];

      snap.docs.forEach((u) => {
        let data = u.data();
        if (
          (!data.activeProgram || data.activeProgram === "null") &&
          data.email && !data.workflowStatus // Check workflowStatus to filter out users associated w/ ReactFM from Screening Week -> Enrollment list
        ) {
          unreg.push(User.fromFirestore(u));
        }
      });
      setUnregUsers(unreg);
    });
    return () => subscriber();
  }, []);

  const handleSuccessfulRegistration = async (results: any) => {
    console.log(results);
    const messages = [
      {
        title: "Time for Tempo",
        body: "It’s time to check in on Tempo! Tap here to get started.",
      },
      {
        title: "Tempo is here for you",
        body:
          "Logging your fibro symptoms can help you notice pain flare triggers. Tap here to get started.",
      },
      {
        title: "Let’s check in",
        body:
          "Every day presents an opportunity to check in and notice patterns in your symptoms. Tap here to log how you’re doing.",
      },
      {
        title: "Tempo is here to help",
        body:
          "Life can sometimes get in the way of our best intentions. Take a few minutes to pause and check in on how you’re doing.",
      },
      {
        title: "Time for Tempo",
        body:
          "It’s time to check in on how you’re doing. Tap here to get started.",
      },
    ];
    await sendToUsersDevices(results.user, new Date().toUTCString(), messages);
    const { registration, user } = results;

    const props = {
      registration: { ...registration },
      user: { ...user },
      subjectID: registration.subjectID,
    };
    SystemEvent.fireEvent(
      "Screening Enrollment",
      "004-reg",
      "Enrolled subject " +
      registration.subjectID +
      " as part of " +
      registration.cohort +
      " at the " +
      registration.site +
      " site",
      new Date().toISOString(),
      props
    );
  };

  const getTableRow = (user: User) => {
    return (
      <tr>
        <td>{user.email}</td>

        <td>{user.created.toLocaleString()}</td>
        <td>
          <Button
            onClick={() => {
              // @ts-ignore
              setRegTime({
                email: user.email,
                activeProgram: "screeningWeek",
                study: "004",
                role: "patient",
                cohort: "004-cohort-2",
              });
            }}
          >
            {" "}
            Register To Screening Week
          </Button>
        </td>
      </tr>
    );
  };

  return regTime ? (
    <RegForm
      presetValues={regTime}
      handleSuccess={handleSuccessfulRegistration}
    ></RegForm>
  ) : (
    <Table responsive striped>
      <thead>
        <tr>
          <th>Email</th>
          <th>Account Created</th>
          <th>Start Screening Week</th>
        </tr>
      </thead>
      <tbody>{unregUsers.map((u) => getTableRow(u))}</tbody>
    </Table>
  );
};

export { ScreeningEnrollment };
