import React, { useContext, useState, useEffect } from "react";

import { auth, firestore } from "../../firebase";
import AdminNavbar from "../AdminNavbar";
import { useHistory } from "react-router-dom";
import { Role } from "../../Model/Role";

import {
  Container,
  Row,
  Col,
  Button,
  ListGroup,
  ListGroupItem,
  Jumbotron,
  InputGroup,
  FormControl,
  Form,
} from "react-bootstrap";

const RolesList = () => {
  const [roles, setRoles] = useState([]);
  const [newRoleName, setNewRoleName] = useState("");
  const [filter, setFilter] = useState("");

  let history = useHistory();

  const getListItem = (roleData) => {
    if (!roleData.matchesFilter(filter)) {
      return <></>;
    }
    return (
      <ListGroupItem
        key={roleData.name}
        onClick={() => {
          history.push("/roles/" + roleData.name);
        }}
      >
        <Row>
          <Col xs={9}>
            <h5>{roleData.name}</h5>
          </Col>
          <Col>
            <small xs={3}>{roleData.membersCount}</small>
          </Col>
        </Row>
      </ListGroupItem>
    );
  };

  useEffect(() => {
    const subscriber = firestore.collection("roles").onSnapshot((snapshot) => {
      if (!snapshot.empty) {
        let rolesLists = [];
        snapshot.docs.forEach((doc) => {
          let r = Role.fromFirestore(doc);
          rolesLists.push(r);
        });

        setRoles(rolesLists);
      } else {
        console.log("no results", snapshot);
      }
    });
    return () => subscriber();
  }, [setRoles]);

  return (
    <>
      <AdminNavbar></AdminNavbar>
      <Jumbotron>
        <Container>
          <Row>
            <h1>Roles - {roles.length} </h1>
          </Row>
          <Row>
            <Col>
              <Form inline>
                <InputGroup className="">
                  <FormControl
                    id="search"
                    placeholder="Search"
                    onChange={(e) => {
                      setFilter(e.target.value.trim());
                    }}
                  />
                </InputGroup>
              </Form>
            </Col>
            <Col xs="auto">
              <Form inline>
                <InputGroup className="">
                  <FormControl
                    id="new"
                    placeholder="New Role"
                    onChange={(e) => {
                      setNewRoleName(e.target.value.trim());
                    }}
                  />
                  <InputGroup.Append>
                    <Button
                      onClick={() => {
                        Role.newRole(newRoleName);
                      }}
                    >
                      Create
                    </Button>
                  </InputGroup.Append>
                </InputGroup>
              </Form>
            </Col>
          </Row>
        </Container>
      </Jumbotron>
      <Container style={{ backgroundColor: "white" }}>
        <Row>
          <Col xs={12}>
            <ListGroup variant="flush" id="users">
              {roles.map((u) => getListItem(u))}
            </ListGroup>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default RolesList;
