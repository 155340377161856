import React, {useState} from "react";
import {Link} from "react-router-dom";
import {useCollection, useDocument} from "react-firebase-hooks/firestore";
import LoadingScreen from "../../LoadingScreen";
import TabContainer from "react-bootstrap/TabContainer";
import "../surveyAdherence.css";
import {firestore} from "../../../firebase";
import AdminNavbar from "../../AdminNavbar";
import {useHistory} from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import NavItem from "react-bootstrap/NavItem";

import {Button, Col, Container, Form, FormControl, InputGroup, Jumbotron, Modal, Row, Table,} from "react-bootstrap";
import AReportTable004A from "./AReportTable004A";
import AlargerReportTable004A from "./AlargerReportTable004A";
import AnotherReportsListSidebar from "./AnotherReportsListSidebar";

import AsmallerReportTable004A from "./AsmallerReportTable004A";
    // const query = firestore.collection("analytics/reports/reportPages");
    // const [reportPages, reportPagesLoading, reportPagesError] = useCollection(query, {
        // snapshotListenOptions: {includeMetadataChanges: true},
    // });
// const query = firestore.collection("analytics").doc("kpi_summary")
const A004Sites = (props) => {
 return (<>
        <AdminNavbar/>
        <Jumbotron>
            <Container fluid={true} style={{ paddingBottom: "30px"}}>
                <Row>
                    <Col>
                        <h1>004 Sites</h1>
                    </Col>
                </Row>
            </Container>
        </Jumbotron>
        <AnotherReportsListSidebar/>
        {/* <Container fluid={true} style={{backgroundColor: "white"}}>

<div className="sidenav">
<p><Link className="TitleD" style={{paddingLeft: "20px"}} to="/004/sites">
    Sites</Link></p>
   <p><Link className="TitleD" style={{paddingLeft: "20px"}} to="/004/pros">
    PROs</Link></p>

</div>
 </Container> */}

       
        {/* <div className="article.TitleC">
        KPIs
        </div> */}
        {/* <Container fluid={true} style={{backgroundColor: "white"}}>
        </Container>  */}
           <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "40px"}}>
            <div className="article" style={{paddingBottom: "30px", paddingLeft: "40px"}}>
                
            <div className="TitleC">Participant ARM counts</div>
            < div classname="TitleCb">As defined by app use, not medrio</div>
            <AsmallerReportTable004A tableKey={"site_arm_counts004"}></AsmallerReportTable004A>
                </div>
        </Container>
        <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "40px"}}>
            <div className="article" style={{paddingBottom: "30px", paddingLeft: "40px"}}>
            <div className="TitleC">Screen Fails </div>
            <AlargerReportTable004A tableKey={"screenfails004"}></AlargerReportTable004A>
                </div>
        </Container>
        <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "40px"}}>
            <div className="article" style={{paddingBottom: "30px", paddingLeft: "40px"}}>
            <div className="TitleC">Visits</div>
            <AReportTable004A tableKey={"visits_by_site"}></AReportTable004A>
                </div>
        </Container>
    
     
        {/* <Container fluid={true} style={{backgroundColor: "white"}}>
            <div className="article">
            <p className="TitleC">C2 Visits</p>
            <AReportTable004A tableKey={"C2visits"}></AReportTable004A>
                </div>
        </Container> */}

        {/* <Container fluid={true} style={{backgroundColor: "white"}}>
            <div className="article">
            <AReportTable tableKey={"engagement_kpis"}></AReportTable>
                </div>
        </Container>
        <Container fluid={true} style={{backgroundColor: "white"}}>
            <div className="article">
            <AReportTable tableKey={"lesson_kpis"}></AReportTable>
                </div>
        </Container>
        <Container fluid={true} style={{backgroundColor: "white"}}>
            <div className="article">
            <AsmallerReportTable tableKey={"pro_adherence_kpis"}></AsmallerReportTable>
                </div>
        </Container> */}



      
</>);

};

export default A004Sites;

