import { SurveyBayForm } from '@swing-therapeutics/surveybay/dist/models/SurveyBayForm';
import { SurveyWeek } from '@swing-therapeutics/surveybay/dist/types';
import { useField } from 'formik';
import React, { useMemo, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { firestore } from '../../../../firebase';
import { useCollectionClassData } from '../../../../utils/Hooks';
import { FormikTextField, FormikToken } from '../../../FormikFields';


const AddSurveyWeekDisplay: React.FC = () => {

  const [forms, formsLoading] = useCollectionClassData(SurveyBayForm, firestore.doc("tempest/surveyBay").collection("forms"));

  const formsAsOptions = useMemo(() => {
    if (forms.length === 0) {
      return [];
    }
    return forms.map((form) => ({ label: form.name, value: form.key }))
  }, [forms])

  const [fieldInfo] = useField("surveyWeeks");
  const [selectedWeek, setSelectedWeek] = useState(0)
  const [displaySurveyWeeks, setDisplaySurveyWeeks] = useState<boolean[]>(fieldInfo.value.map((surveyWeek: SurveyWeek) => surveyWeek.surveys.length !== 0))

  return (
    <Col>
      <h3>Add Survey Weeks</h3>
      <p>Add a week of surveys</p>
      <Row>
        <Col xs="auto">
          <Button
            onClick={() => {
              setDisplaySurveyWeeks((prevWeek) => {
                prevWeek[selectedWeek] = true;
                return [...prevWeek];
              })
            }}
          >
            Add Survey Week
          </Button>
        </Col>
        <Col xs="auto">
          <div className="form-group">
            <select
              className="form-control"
              value={selectedWeek}
              onChange={(event) => {
                let newValue = Number(event.target.value);
                setSelectedWeek(newValue)
              }}
            >
              {
                new Array(fieldInfo.value.length).fill(0).map((_x, index) => (
                  <option key={index} value={index}>
                    Week {index + 1}
                  </option>
                ))
              }
            </select>
          </div>
        </Col>
      </Row>
      {!formsLoading && displaySurveyWeeks.map((display, index) => (
        display === true &&
        <Row key={index} className="mb-3">
          <Col>
            <FormikToken
              name={`surveyWeeks[${index}].surveys`}
              label={`Week ${index + 1} surveys`}
              desc={`Surveys to be assigned to user on week ${index + 1} of therapy`}
              options={formsAsOptions}
              selectedOptionsLabel="Selected Surveys"
              optionsLabel="surveys"
            />
          </Col>
            <FormikTextField
                name={`surveyWeeks[${index}].daysToCompleteTherapySurvey`}
                label="Days to complete therapy survey"
                desc="Number of days a user has to complete a therapy survey"
                type="number"
            />
        </Row>
      ))}
      <Row className="mb-3">
        {!formsLoading && (
        <Col>
          <FormikToken
            name="terminationSurveys"
            label="Termination Surveys"
            desc="Termination surveys assigned to user when they are terminated early"
            options={formsAsOptions}
            selectedOptionsLabel="Selected surveys"
            optionsLabel="surveys"
          />
        </Col>
        )}
      </Row>
    </Col >
  )
}

export default AddSurveyWeekDisplay;
