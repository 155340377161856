import { SessionTemplate } from "@swing-therapeutics/swingcore/dist/models/Session";
import { getAwarenessTemplates, getJournalsTemplates, getLessonsTemplates } from "@swing-therapeutics/swingcore/dist/utils/queries";
import { firestore } from "firebase";
import { Formik, Form as FormikForm, useField } from "formik";
import React, { useCallback, useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import * as yup from "yup";
import { FormikSelect, FormikSubmitButton } from "../../../FormikFields";

interface AddUnlockedProps {
  show: boolean;
  close: () => void;
  session: SessionTemplate;
  sessionDoc: firestore.DocumentReference<firestore.DocumentData>;
  programID: string;
}

type ActivityType = 'lesson' | 'awareness' | 'journaling';

const activityOptions: { label: string, value: ActivityType }[] = [
  { label: 'Lesson', value: 'lesson' },
  { label: 'Awarness', value: 'awareness' },
  { label: 'Journal', value: 'journaling' },
];

export const AddUnlockedActivityModal: React.FC<AddUnlockedProps> = ({ show, close, session, sessionDoc, programID }) => {
  const currentUnlocks = session.unlockedActivities ?? [];

  return (
    <Modal show={show} onHide={close}>
      <Formik
        initialValues={{
          type: "",
          id: "",
        }}
        validationSchema={FormSchema}
        enableReinitialize={true}
        onSubmit={async (newActivity, actions) => {
          await sessionDoc.update({ unlockedActivities: [...currentUnlocks, newActivity] });
          actions.setSubmitting(false);
          close()
        }}
        validateOnChange={false}
      >
        <FormikForm>
          <Modal.Header closeButton>
            <Modal.Title>Add Unlocked Activity</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FormikSelect
              name="type"
              placeHolder="Pick a type..."
              options={activityOptions}
            />
            <SelectOptionSwitcher programID={programID} />
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={close} variant="secondary">
              Close
            </Button>
            <FormikSubmitButton label="Save" />
          </Modal.Footer>
        </FormikForm>
      </Formik>
    </Modal>
  )
}

const FormSchema = yup.object().shape({
  type: yup.string().required('Please select a type'),
  id: yup.string().required('Please select'),
})

interface SelectSwitchProps {
  programID: string;
}

// Switch the options available in the dropdown based on the selected type
const SelectOptionSwitcher: React.FC<SelectSwitchProps> = ({ programID }) => {
  const [type_fieldInput] = useField<ActivityType | ''>('type');
  const [_id_fieldInput, _id_fieldMeta, id_fieldHelpers] = useField('id');
  const [options, setOptions] = useState<{ label: string, value: string }[] | null>(null);

  // Fetch and set the correct options for the dropdown based on the type
  const getSetOptions = useCallback(async () => {
    setOptions(null);
    const _options: { label: string, value: string }[] = [];
    switch (type_fieldInput.value) {
      case "awareness":
        const awarnessSnap = await (getAwarenessTemplates(programID).get() as Promise<firestore.QuerySnapshot<firestore.DocumentData>>);
        awarnessSnap.docs.forEach((doc) => {
          const data = doc.data();
          if (data) {
            _options.push({ label: data.key, value: data.key });
          }
        })
        break;
      case 'journaling':
        const journalSnap = await (getJournalsTemplates(programID).get() as Promise<firestore.QuerySnapshot<firestore.DocumentData>>);
        journalSnap.docs.forEach((doc) => {
          const data = doc.data();
          if (data) {
            _options.push({ label: data.displayName, value: data.id });
          }
        })
        break;
      case 'lesson':
        const lessonSnap = await (getLessonsTemplates(programID).get() as Promise<firestore.QuerySnapshot<firestore.DocumentData>>);
        lessonSnap.docs.forEach((doc) => {
          const data = doc.data();
          if (data) {
            _options.push({ label: `Chapter: ${data.chNum} | Number: ${data.num}`, value: data.id });
          }
        })
        break;
      default:
        break;
    }
    setOptions(_options);
  }, [type_fieldInput.value, programID])

  useEffect(() => {
    // Be sure to clear id field when getting and setting new options
    // Dont want an option from say 'lesson' to be assigned to a 'awareness'
    id_fieldHelpers.setValue('');
    getSetOptions();
  }, [getSetOptions])

  return type_fieldInput.value ? (
    <FormikSelect
      name='id'
      placeHolder={!options ? 'Loading...' : 'Select...'}
      options={options === null ? [] : options}
    />
  ) : null
}
