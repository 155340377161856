import { Form as FormikForm, Formik, useField } from "formik";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Col, Container, Row, Button } from "react-bootstrap";
import { ConsentWorkflowData, Workflow } from "../../../../Model/Workflow";
import * as Yup from "yup";
import moment from "moment";
import { FormikSelect, FormikSubmitButton, FormikTextField } from "../../../FormikFields";
import { ConsentStatus, ScheduledCallStatus } from "@swing-therapeutics/surveybay/dist/types";

interface PreconsentViewProps {
  workflow: Workflow;
}

// Override the dateOfCall value so we can input a string date and later change
// back into date
interface ConsentFormValues extends Omit<ConsentWorkflowData, 'dateOfCall'> {
  dateOfCall: string;
}

const ConsentView: React.FC<PreconsentViewProps> = ({ workflow }) => {
  const workflowData = workflow.workflowData as ConsentWorkflowData;
  const isMounted = useRef(true);
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");

  useEffect(() => {
    isMounted.current = true;
    return () => { isMounted.current = false };
  }, [])

  const setDisappearingMessage = useCallback((msg, error = false) => {
    const setFunc = error ? setError : setMessage;
    setFunc(msg);
    setTimeout(() => {
      isMounted.current && setFunc("");
    }, 5000);
  }, [isMounted])

  return (
    <Col>
      <Row>
        <Formik
          initialValues={{
            ...workflowData,
            dateOfCall: moment.utc(workflowData.dateOfCall ?? new Date()).format("yyyy-MM-DD"),
          }}
          enableReinitialize={true}
          onSubmit={async (values: ConsentFormValues, actions) => {
            const updatedWorkflowData: any = { ...values };
            updatedWorkflowData.dateOfCall = new Date(updatedWorkflowData.dateOfCall);
            workflow.workflowData = { ...updatedWorkflowData } as ConsentWorkflowData;
            const status = await workflow.persist();
            if (status instanceof Error) {
              setDisappearingMessage("Error saving date, check console", true);
            }
            else {
              setDisappearingMessage("Saved");
            }
            actions.setSubmitting(false);
          }}
          validateOnChange={false}
          validationSchema={FormSchema}
        >
          <FormikForm role="form" style={{ width: '100%' }}>
            <Container>
              <Row>
                <ConsentStatusField originalCallStatus={workflowData.status} />
              </Row>
              <Row>
                <FormikTextField
                  name="status"
                  label="Call status"
                  disabled={true}
                />
              </Row>
              {
                workflowData.status === ScheduledCallStatus.CALL_SCHEDULED && workflowData.callEndTime && workflowData.callStartTime &&
                <>
                  <Row>
                    <p>
                      <small>
                        Scheduled for {moment(workflowData.callStartTime).format('h:mm')} -{' '}
                        {moment(workflowData.callEndTime).format('h:mm a, dddd, MMMM DD, YYYY')}
                      </small>
                    </p>
                  </Row>
                  <Row className="mb-3">
                    <a href={workflowData.cancelURL} target="_blank"><Button>Cancel call</Button></a>
                  </Row>
                </>
              }
              <Row>
                <FormikTextField
                  name="consentFormStatus"
                  label="Docusign consent form status"
                  disabled={true}
                />
              </Row>
              <Row>
                <FormikTextField
                  name="dateOfCall"
                  label="Pre-consent call date"
                  desc="Date the pre-consent call took place"
                  type="date"
                />
              </Row>
              <Row>
                <FormikTextField
                  name="employeeName"
                  label="Employee name"
                  desc="Name of the employee that approved the pre-consent"
                  placeHolder="Employee name"
                />
              </Row>
              <Row>
                <FormikTextField
                  name="notes"
                  label="Notes"
                  desc="Notes on the pre-consent call or other information to be saved"
                  placeHolder="Notes..."
                  multiLine={true}
                />
              </Row>
              <Row>
                <FormikSubmitButton label="Submit" />
              </Row>
              <Row>
                {
                  message &&
                  <h6 className="mt-2 text-success">{message}</h6>
                }
                {
                  error &&
                  <h6 className="mt-2 text-warning">{error}</h6>
                }
              </Row>
            </Container>
          </FormikForm >
        </Formik>
      </Row>
    </Col>
  )
}

export default ConsentView;

const FormSchema = Yup.object().shape({
  preConsentStatus: Yup.string()
    .required('Required'),
  employeeName: Yup.string()
    .required('Required'),
  dateOfCall: Yup.date()
    .transform((_value, originalValue) => {
      return new Date(originalValue);
    })
    .typeError('Enter date in format mm/dd/yyyy')
    .required('Required')
})

interface Props {
  originalCallStatus: ScheduledCallStatus;
}

const ConsentStatusField: React.FC<Props> = ({ originalCallStatus }) => {
  const [_statusInput, _statusMeta, statusHelpers] = useField('status');

  return (
    <FormikSelect
      name="preConsentStatus"
      label="Consent status"
      desc="Status of the consent process"
      onChange={(newValue) => {
        // The preConsentStatus drives the call status, so when this updates we need to change the call status
        if (newValue === ConsentStatus.ABANDONED) {
          statusHelpers.setValue(ScheduledCallStatus.ABANDONED);
        }
        else if ([ConsentStatus.APPROVED, ConsentStatus.FAILED_APPROVAL].includes(newValue)) {
          statusHelpers.setValue(ScheduledCallStatus.COMPLETED);
        }
        else if (newValue === ConsentStatus.NOT_APPROVED) {
          // If set to not approved, use original call status
          statusHelpers.setValue(originalCallStatus);
        }
      }}
      options={[
        { value: ConsentStatus.APPROVED, label: 'Consent approved' },
        { value: ConsentStatus.NOT_APPROVED, label: 'Consent not approved' },
        { value: ConsentStatus.FAILED_APPROVAL, label: 'Consent fail' },
        { value: ConsentStatus.ABANDONED, label: 'Consent abandoned' },
      ]}
    />
  )
}
