import React from "react";
import { auth } from "../firebase";
import { Link } from "react-router-dom";
import {
  Button,
  Col,
  Container,
  Nav,
  Navbar,
  NavDropdown,
  Row,
} from "react-bootstrap";
import { version } from "../../package.json";
import { firestore } from "../firebase";
import { useState, useEffect } from "react";

const AdminNavbar = (props) => {
  const [flagsmithL, setFlagsmithL] = useState({
    flagsmithLink: "",
  });

  const fetchFlagsmith = () => {
    firestore
      .collection("tempest")
      .doc("flagsmith")
      .get()
      .then(doc => setFlagsmithL(doc.data()))
  };

  useEffect(() => {
    fetchFlagsmith();
  }, []);

  return (
    <Navbar bg="light" variant="light">
      <Container fluid={true}>
        <Col>
          {
            process.env.REACT_APP_PRODUCTION_ENV !== 'true' &&
            <Row>
              <small className="text-white">Connected to '{process.env.REACT_APP_FIREBASE_PROJECT_ID}'</small>
            </Row>
          }
          <Row>
            <small className="text-white">v{version}</small>
          </Row>
          <Row>
            <Navbar.Brand href="/">Tempest</Navbar.Brand>
            <Nav className="mr-auto">
              <Link className="nav-link" to="/users">
                Users
              </Link>
              <NavDropdown title="">
                <Link className="nav-link" to="/register/new">
                  Register User
                </Link>
                <Link className="nav-link" to="/register">
                  Registration Records
                </Link>
                <Link className="nav-link" to="/subjects">
                  Subjects
                </Link>
                <Link className="nav-link d-none d-sm-block " to="/roles">
                  Roles
                </Link>
                <Link className="nav-link" to="/unregisteredUsers">
                  Unregistered Users
                </Link>
                <Link className="nav-link" to="/dataReset">
                  User Data Reset
                </Link>
              </NavDropdown>
              <NavDropdown title="Access Codes">
                <Link className="nav-link" to="/accessCodes">
                  Create Access Codes
                </Link>
                <Link className="nav-link" to="/manageAccessCodes">
                  Manage Access Codes
                </Link>
                <Link className="nav-link" to="/accessKeyPageLinks">
                  Access Key Page Links
                </Link>
              </NavDropdown>
              <Link className="nav-link d-none d-sm-block " to="/medrio">
                Medrio
              </Link>
              <Link className="nav-link d-none d-sm-block " to="/programs">
                Programs
              </Link>
              <Link className="nav-link d-none d-sm-block " to="/studies">
                Studies
              </Link>
              <Link className="nav-link d-none d-sm-block " to="/cohorts">
                Cohorts
              </Link>
              <Link className="nav-link d-none d-sm-block" to="/003/forms">
                Forms
              </Link>
              <NavDropdown title="">
                <Link className="nav-link" to="/003/forms/new">
                  Create form
                </Link>
                <Link className="nav-link" to="/addEproKey">
                  Add epro key
                </Link>
              </NavDropdown>
              <Link className="nav-link d-none d-sm-block" to="/surveysaurus">
                Surveysaurus
              </Link>
              <Link className="nav-link d-none d-sm-block" to="/nudging">
                Nudging
              </Link>
              <Link className="nav-link d-none d-sm-block" to="/screeningWeek005">
                Screening Week
              </Link>
              <NavDropdown title="">
                <Link className="nav-link" to="/screeningWeek005">
                  005
                </Link>
                <Link className="nav-link" to="/screeningWeek">
                  004
                </Link>
              </NavDropdown>
              <Link className="nav-link d-none d-sm-block " to="/events">
                Events
              </Link>

              <Link className="nav-link" to="/versions/blacklist">
                Blacklisted Versions
              </Link>

              <Link
                className="nav-link d-none d-sm-block "
                to={{ pathname: "https://analytics.tempo.systems/pokeList" }}
                target="_blank"
              >
                Poke List
              </Link>
              <Link
                className="nav-link d-none d-sm-block "
                to={{ pathname: "https://analytics.tempo.systems/" }}
                target="_blank"
              >
                Analytics
              </Link>
              <a className="nav-link d-none d-sm-block" 
                href={flagsmithL.flagsmithLink}
                target="_blank" rel="noreferrer"
              >
                Flagsmith
              </a>
              <NavDropdown title="">
                <Link className="nav-link" to="/flagsmithchange">
                Change flagsmith link
                </Link>
              </NavDropdown>

              <NavDropdown title="Prescription">
                <Link className="nav-link" to="/prescription/create">
                  Create Prescription
                </Link>
                <Link className="nav-link" to="/prescriptions">
                  Prescriptions
                </Link>
              </NavDropdown>

              <Link className="nav-link d-none d-sm-block " to="/sites">
                Sites
              </Link>
            </Nav>
            <Nav>
              <NavDropdown title="Under Development">
                <Link className="nav-link" to="/surveyMonkey">
                  Survey Monkey Sync
                </Link>
              </NavDropdown>

              <Button
                className="d-none d-sm-block "
                variant="outline-warning"
                onClick={() => {
                  auth.signOut();
                }}
              >
                Sign Out
              </Button>
            </Nav>
          </Row>
        </Col>
      </Container>
    </Navbar>
  );
};

export default AdminNavbar;
