import { Lesson } from "@swing-therapeutics/swingcore/dist/models/content/Lesson";
import { Chapter } from "@swing-therapeutics/swingcore/dist/models/content/Chapter";
import { firestore, Timestamp } from "../firebase";
import { Registration } from "./Registration";
import { SystemEvent } from "./SystemEvent";
import { DocumentData, DocumentRef, DocumentSnapshot } from "../firebase";

interface UserProperties {
  cohort: string;
  role: string
}

interface UserRef {
  uid: string;
  email: string;
  activeProgram: string;
  displayName: string;
  subjectID: string;
  userProperties: UserProperties;
  lastOpenedTime: Timestamp;
  appVersion: string;
  deviceName: string;
  deviceOS: string;
  deviceOSVersion: string;
  FCMToken: string;
  FCMTokenUpdated: Timestamp;
  zendeskID: string;
  site: string;
  cohort: string;
  role: string;
  arm: string;
  study: string;
  firstName: string;
  lastName: string;
  registered: string;
}

class User {
  uid: string;
  email: string;
  activeProgram: string;
  displayName: string;
  subjectID: string;
  userProperties: UserProperties;
  lastOpenedTime: Date;
  appVersion: string;
  deviceName: string;
  deviceOS: string;
  deviceOSVersion: string;
  FCMToken: string;
  FCMTokenUpdated: Date;
  zendeskID: string;
  site: string;
  cohort: string;
  role: string;
  arm: string;
  study: string;
  firstName: string;
  lastName: string;
  registered: string | boolean;

  constructor(props: User) {
    this.uid = props.uid;
    this.email = props.email;
    this.activeProgram = props.activeProgram;
    this.displayName = props.displayName ?? "";
    this.subjectID = props.subjectID ?? "default";
    this.userProperties = props.userProperties ?? { cohort: "default", role: "patient" };
    this.lastOpenedTime = props.lastOpenedTime ?? null;
    this.appVersion = props.appVersion ?? null;
    this.deviceName = props.deviceName ?? null;
    this.deviceOS = props.deviceOS ?? null;
    this.deviceOSVersion = props.deviceOSVersion ?? null;
    this.FCMToken = props.FCMToken ?? null;
    this.FCMTokenUpdated = props.FCMTokenUpdated ?? null;
    this.zendeskID = props.zendeskID ?? null;
    this.site = props.site ?? null;
    this.cohort = props.cohort ?? null;
    this.role = props.role ?? null;
    this.arm = props.arm ?? null;
    this.study = props.study ?? null;
    this.firstName = props.firstName ?? null;
    this.lastName = props.lastName ?? null;
    this.registered = props.registered ?? false;
  }

  static fromFirestore(docSnap: DocumentSnapshot<DocumentData>) {
    if (!docSnap.exists) return;
    const docData = docSnap.data() as UserRef;
    const uid = docSnap.id;

    return new User({
      uid,
      ...docData,
      lastOpenedTime: docData.lastOpenedTime?.toDate(),
      FCMTokenUpdated: docData.FCMTokenUpdated?.toDate(),
    } as User)
  }

  //firestore helpers
  data() {
    return {
      uid: this.uid,
      email: this.email,
      activeProgram: this.activeProgram,
      displayName: this.displayName,
      subjectID: this.subjectID,
      appVersion: this.appVersion,
      deviceName: this.deviceName,
      deviceOS: this.deviceOS,
      lastOpenedTime: this.lastOpenedTime,
      deviceOSVersion: this.deviceOSVersion,
      FCMToken: this.FCMToken,
      FCMTokenUpdated: this.FCMTokenUpdated,
      zendeskID: this.zendeskID,
      cohort: this.cohort,
      arm: this.arm,
      role: this.role,
      site: this.site,
      study: this.study,
      firstName: this.firstName,
      lastName: this.lastName,
      registered: this.registered,
    };
  }

  updateFromRegistration(reg: Registration) {
    let oldUserProps = {
      subjectID: this.subjectID,
      cohort: this.cohort,
      role: this.role,
      arm: this.arm,
      activeProgram: this.activeProgram ?? null,
      site: this.site,
      zendeskID: this.zendeskID,
      study: this.study,
      firstName: this.firstName,
      lastName: this.lastName,
      registered: this.registered,
    };

    this.subjectID = reg.subjectID;
    this.cohort = reg.cohort;
    this.role = reg.role;
    this.arm = reg.arm;
    this.activeProgram = reg.activeProgram;
    this.site = reg.site;
    this.zendeskID = reg.zendeskID;
    this.study = reg.study;
    this.firstName = reg.firstName;
    this.lastName = reg.lastName;
    this.registered = true;

    let newUserProps = {
      subjectID: this.subjectID,
      cohort: this.cohort,
      role: this.role,
      arm: this.arm,
      activeProgram: this.activeProgram ?? null,
      site: this.site,
      zendeskID: this.zendeskID,
      study: this.study,
      firstName: this.firstName,
      lastName: this.lastName,
      registered: this.registered,
    };
    reg.uid = this.uid;
    let props = {
      userEmail: this.email,
      uid: this.uid,
      previousUserProps: oldUserProps,
      newUserProps: newUserProps,
      registrationRecord: reg.data(),
    };
    SystemEvent.fireEvent("Registration Updated", "registration", "Updated User " + this.email + "'s" + " registration", null, props);
    reg.persist();
    this.persist();
  }

  persist() {
    firestore.collection("users").doc(this.uid).set(this.data());
  }

  static async userWithEmail(email: string) {
    const existingUser = await firestore
      .collection("users")
      .where("email", "==", email)
      .limit(1)
      .get();

    if (existingUser.empty) return null;
    let userRef = existingUser.docs[0];
    return User.fromFirestore(userRef);
  }

  matchesFilter(filter: string) {
    if (!filter || filter.length === 0) return true;
    return (this.displayName.toLowerCase().includes(filter.toLowerCase()) || 
      this.email.toLowerCase().includes(filter.toLowerCase()) || 
      this.subjectID.toLowerCase().includes(filter.toLowerCase()) || 
      (this.userProperties && this.userProperties.cohort.toLowerCase().includes(filter.toLowerCase()))
    );
  }

  //data loading
  async getTasks() {
    var snapshot = await firestore
      .collection("users")
      .doc(this.uid)
      .collection("programs")
      .doc(this.activeProgram)
      .collection("tasks")
      .get();

    if (snapshot.empty) return null;

    let tasks = [];
    snapshot.docs.forEach((doc) => {
      let t = new Task(doc as unknown as DocumentSnapshot<any>);
      tasks.push(t);
    });
    return tasks;
  }

  async getSessions() {
    var snapshot = await firestore
      .collection("users")
      .doc(this.uid)
      .collection("programs")
      .doc(this.activeProgram)
      .collection("sessions")
      .get();

    if (snapshot.empty) return null;

    let tasks = [];
    snapshot.docs.forEach((doc) => {
      let t = new Session(doc, new Date());
      tasks.push(t);
    });
    return tasks;
  }
}

class Task {
  docRef: DocumentRef<DocumentData>;
  taskRecordID: string;
  id: string;
  sessionId: string;
  status: string;
  displayName: string;
  type: string;
  order: string;
  dateStarted: string;
  dateCompleted: string;
  chapter: Chapter;
  lesson: Lesson;

  constructor(docSnap: DocumentSnapshot<any>) {
    if (!docSnap.exists) return;
    const docData = docSnap.data();
    this.docRef = docSnap.ref;
    this.taskRecordID = docData.taskRecordID;
    this.id = docData.id;
    this.sessionId = docData.sessionId;
    this.status = docData.status;
    this.displayName = docData.displayName;
    this.type = docData.type;
    this.order = docData.order;    
    this.dateStarted = docData.dateStarted ? docData.dateStarted.toDate() : null;
    this.dateCompleted = docData.dateCompleted ? docData.dateCompleted.toDate() : null;
    this.chapter = docData.chapter ?? null;
    this.lesson = docData.lesson ?? null;
  }

  //firestore helpers
  data() {
    return {
      taskRecordID: this.taskRecordID,
      id: this.id,
      sessionId: this.sessionId,
      status: this.status,
      displayName: this.displayName,
      type: this.type,
      order: this.order,
      dateStarted: this.dateStarted,
      dateCompleted: this.dateCompleted,
      chapter: this.chapter,
      lesson: this.lesson,
    };
  }

  persist() {
    this.docRef.set(this.data());
  }
}

class Session {
  docRef: DocumentRef<DocumentData>;
  id: string;
  status: string;
  therapyStartDate: Date;
  dateStarted: Date;
  dateCompleted: Date;

  constructor(docSnap: DocumentSnapshot<DocumentData>, therapyStartDate: Date) {
    if (!docSnap.exists) return;
    const docData = docSnap.data();
    this.docRef = docSnap.ref;
    this.id = docSnap.id;
    this.status = docData.status;
    this.therapyStartDate = therapyStartDate ?? new Date();
    this.dateStarted = docData.dateStarted.toDate() ?? null;
    this.dateCompleted = docData.dateCompleted.toDate() ?? null;
  }

  //firestore helpers
  data() {
    return {
      id: this.id,
      status: this.status,
      dateStarted: this.dateStarted,
      dateCompleted: this.dateCompleted,
    };
  }

  persist() {
    this.docRef.set(this.data());
  }
}

export { Task, User, Session };
