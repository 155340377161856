import { firestore } from "firebase";
import React, { useEffect, useState } from "react";
import { Dropdown, Form, Table, DropdownButton, Container, Row, Col } from "react-bootstrap";
import JSONTree from "react-json-tree";
import { UserAnalyticEvent } from "../../../../Model/AnalyticEvent";
import LoadingScreen from "../../../LoadingScreen";
import { PaginationBar } from "../../../PaginationBar";
import { SearchBar } from "../../../SearchBar";
import * as uuid from "uuid";

export type FirestoreQuery = firebase.firestore.Query<firebase.firestore.DocumentData>

const UserEventsTable = ({ uid }) => {
  const [loading, setLoading] = useState(true);
  const [loadingError, setLoadingError] = useState<Error>();

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(100);
  const [usages, setUsages] = useState([] as UserAnalyticEvent[]);
  const [previousTimeStamps, setPreviousTimestamps] = useState<firestore.Timestamp[]>([]);

  const baseQuery = firestore()
    .collection("analyticsEvents")
    .where("userId", "==", uid)
    .orderBy("timeStamp", "desc")
    .limit(limit);

  const [query, setQuery] = useState<FirestoreQuery>(baseQuery)

  const getQuery = () => {
    return previousTimeStamps.length > 0 ? query.startAfter(previousTimeStamps[previousTimeStamps.length - 1]) : query
  }

  const updatePage = (newPage: number) => {
    if (newPage > page) {
      previousTimeStamps.push(usages[usages.length - 1].timeStamp);
    }
    else if (newPage < page) {
      setPreviousTimestamps(previousTimeStamps.slice(0, newPage - 1));
    }
    setPage(newPage);
  }

  const updateQuery = (newQuery: FirestoreQuery) => {
    setPreviousTimestamps([]);
    setPage(1);
    setQuery(newQuery);
  }

  const updateLimit = (newLimit: number) => {
    setPreviousTimestamps([]);
    setPage(1);
    setLimit(newLimit);
  }

  useEffect(() => {
    setLoading(true)
    getQuery().get()
      .then(usages => {
        if (!usages.empty) {
          setLoadingError(undefined);
          setUsages(usages.docs.map(doc => doc.data() as UserAnalyticEvent))
        }
        else {
          setLoadingError(Error('No Events for user'));
        }
        setLoading(false);
      })
      .catch(e => setLoadingError(e))
  }, [page, limit, query])

  return (<Container fluid>
    <Row>
      <Col xs={3}>
        <Row className="align-items-center justify-content-start flex-nowrap" style={{ marginBottom: 15 }}>
          <Col xl="auto" className="px-0">
            <DropdownButton title={limit}>
              <Dropdown.Item onClick={() => updateLimit(25)}>25</Dropdown.Item>
              <Dropdown.Item onClick={() => updateLimit(50)}>50</Dropdown.Item>
              <Dropdown.Item onClick={() => updateLimit(100)}>100</Dropdown.Item>
            </DropdownButton>
          </Col>
          <Col xl="auto" className="px-0">
            events
          </Col>
        </Row>
      </Col>

      <Col xl={5} className="justify-content-start">
        <PaginationBar
          page={page}
          limit={limit}
          nextCallback={() => updatePage(page + 1)}
          prevCallback={() => updatePage(page - 1)}
          selectCallback={updatePage}
        />
      </Col>

      <Col xl={4}>
        <SearchBar
          baseQuery={baseQuery}
          newQueryCallback={updateQuery}
          searchProps={['eventName', 'eventType']}
        />
      </Col>

    </Row>
    {loading ? <LoadingScreen /> : <Row>
      <Col>
        <Table responsive>
          <thead>
            <tr>
              <th>Time</th>
              <th>Event Name</th>
              <th>App Open ID</th>
              <th>Event Type</th>
              <th>Screen Name</th>
              <th>Therapy Day</th>
              <th>Device ID</th>
              <th>Subject ID</th>
              <th>Event</th>
            </tr>
          </thead>
          <tbody>{!loadingError && usages.map((u) => getTableRow(u))}</tbody>
        </Table>

        {loadingError ? <strong>{loadingError.message}</strong> : <PaginationBar
          page={page}
          limit={limit}
          nextCallback={() => updatePage(page + 1)}
          prevCallback={() => updatePage(page - 1)}
          selectCallback={updatePage}
        />}
      </Col>
    </Row>}
  </Container>);
};

const getTableRow = (usage: UserAnalyticEvent) => {
  const jsonTheme = {
    scheme: "chalk", author: "chris kempson (http://chriskempson.com)", base00: "transparent", base01: "#333333", base02: "#555555", base03: "#777777", base04: "#999999", base05: "#bbbbbb", base06: "#dddddd", base07: "#ffffff", base08: "#ff4136", base09: "#ff851b", base0A: "#ffdc00", base0B: "#2ecc40", base0C: "#7fdbff", base0D: "#0074d9", base0E: "#b10dc9", base0F: "#85144b",
  };

  return (<tr key={uuid.v4()}>
    <td>{usage.timeStamp.toDate().toLocaleString()}</td>
    <td>{usage.eventName}</td>
    <td>{usage.appOpenID}</td>
    <td>{usage.eventType}</td>
    <td>{usage.screenName ? usage.screenName : usage.name ? usage.name : usage.stepNumber ? "Ch:" + usage.chapterNumber + " ln:" + usage.lessonNumber + " Step:" + usage.stepNumber : ""}</td>
    <td>{usage.therapyDay}</td>
    <td>{usage.deviceID}</td>
    <td>{usage.subjectID}</td>
    <td>
      <JSONTree
        data={usage}
        theme={jsonTheme}
        invertTheme={false}
        shouldExpandNode={() => false}
      />
    </td>
  </tr>);
};

export default UserEventsTable;
