import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import {
  Container,
  Jumbotron,
  ListGroup,
  ListGroupItem,
  Row,
} from "react-bootstrap";

import AdminNavbar from "../AdminNavbar";
import { useDocument } from "react-firebase-hooks/firestore";
import { firestore } from "../../firebase";
import { ReactComponent as Loading } from "../Images/loading.svg";
import { Registration } from "../../Model/Registration";
import RegForm from "./RegForm";

const RegistrationEdit = (props) => {
  let { email } = useParams();
  if (email) {
    email = decodeURIComponent(email);
  }
  const [registration, registrationLoading, registrationError] = useDocument(
    firestore.doc("/registrations/" + email),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  let history = useHistory();
  const [results, setResults] = useState();
  let resultsView = (
    <Row>
      <ListGroup id="results">
        {results && (
          <>
            <ListGroupItem variant={results.createdReg ? "success" : "error"}>
              <Row>
                <h2>Create Registration Record</h2>
              </Row>
              <Row>
                <strong> {results.createdReg ? "success" : "error"} </strong>
              </Row>
            </ListGroupItem>
            <ListGroupItem
              variant={results.createdSubject ? "success" : "error"}
            >
              <Row>
                <h2>Created Subject Record</h2>
              </Row>
              <Row>
                <strong>
                  {" "}
                  {results.createdSubject ? "success" : "error"}{" "}
                </strong>
              </Row>
            </ListGroupItem>
            <ListGroupItem variant={results.updatedUser ? "success" : ""}>
              <Row>
                <h2>Connected to Existing User</h2>
              </Row>
              <Row>
                <strong>
                  {" "}
                  {results.updatedUser ? "success" : "No user exists yet"}{" "}
                </strong>
              </Row>
            </ListGroupItem>
          </>
        )}
      </ListGroup>
    </Row>
  );

  return (
    <>
      {registrationError && (
        <>
          <strong>Error: {JSON.stringify(registrationError)}</strong>
          <br></br>
          <Loading></Loading>
        </>
      )}
      {registrationLoading && (
        <>
          <strong>
            Registration Loading: {JSON.stringify(registrationLoading)}
          </strong>
          <br></br>
          <Loading></Loading>
        </>
      )}
      {registration && (
        <>
          <AdminNavbar></AdminNavbar>
          <Jumbotron>
            <Container fluid={true}>
              <Row>
                <h1>Edit Registration for {email}</h1>
              </Row>
            </Container>
          </Jumbotron>
          <Container fluid={true} style={{ backgroundColor: "white" }}>
            <RegForm
              registration={Registration.fromFirestore(registration)}
            ></RegForm>
          </Container>
        </>
      )}
    </>
  );
};

export default RegistrationEdit;
