import { ILandingPage, ILandingPageExtension, PayCondition, PaymentNotifications, ScheduleCallOption, ScheduleCallOptions, SurveyWeek } from "@swing-therapeutics/surveybay/dist/types";
import { firebaseTimestampToDate } from "@swing-therapeutics/swingcore/dist/utils/firebase";
import { extConsentCall, extTerminationCall } from "../Components/Surveysaurus/CalendlyCalls";
import { firestore } from "../firebase";
import LandingPage from "./LandingPage";

const numberOfTherapyWeeks = 36;

export const defaultPayCondition = (paymentFor = ''): PayCondition => {
  return {
    eligibilityCompleted: false,
    baselineCompleted: false,
    therapyWeeksCompleted: new Array(numberOfTherapyWeeks).fill(false),
    paymentFor,
    amount: 0,
  }
}

const emptyPaymentNotification: PaymentNotifications = {
  eligibility: null,
  baseline: null,
  surveyWeeks: new Array(numberOfTherapyWeeks).fill(null),
}

export type LandingPageExtensionFormValues = Omit<ILandingPageExtension, 'created' | 'updated' | 'extensionID' | 'stylingID'>;

export const getEmptySurveyWeeks = (): SurveyWeek[] => {
  return new Array(numberOfTherapyWeeks).fill(0).map((_value, index) => ({
    week: index + 1,
    surveys: [],
    daysToCompleteTherapySurvey: 10,
  }))
}

const defaultTermCall: ScheduleCallOption = {
  eventName: extTerminationCall.name,
  calendlyCallURL: extTerminationCall.calendlyURL,
  description: '',
  title: '',
  required: true,
}

const defaultConsentCall: ScheduleCallOption = {
  eventName: extConsentCall.name,
  calendlyCallURL: extConsentCall.calendlyURL,
  description: '',
  title: '',
  required: true,
}

class LandingPageExtension implements ILandingPageExtension {
  private _docPath: string;
  created: Date | undefined;
  updated: Date;
  consentProgram: string;
  consentFailProgram: string;
  therapyProgram: string;
  doneProgram: string;
  earlyTerminationProgram: string;
  surveyWeeks: SurveyWeek[];
  terminationSurveys: string[];
  terminationCall: ScheduleCallOption;
  consentCall: ScheduleCallOption;
  trialID: string;
  paymentNotifications: PaymentNotifications;
  extensionID: string;
  weekdaySurveyAssignment: number;
  daysToCompleteTherapySurvey: number;
  landingPageKey: string;
  scheduleCallOptions: ScheduleCallOptions;
  stylingID: string;

  constructor(props: ILandingPageExtension) {
    Object.assign(this, {
      ...props,
      created: firebaseTimestampToDate(props.created),
      updated: firebaseTimestampToDate(props.updated),
      terminationCall: props.terminationCall ?? { ...defaultTermCall },
      consentCall: props.consentCall ?? { ...defaultConsentCall },
      terminationSurveys: props.terminationSurveys ?? [],
    })
  }

  static fromFirestore(docSnap: firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>) {
    const data = docSnap.data() as ILandingPageExtension;
    const lpEXT = new LandingPageExtension(data)
    lpEXT._docPath = docSnap.ref.path;
    return lpEXT;
  }

  data(): ILandingPageExtension {
    const data = { ...this }
    delete data._docPath;
    return data;
  }

  getDocPath() {
    // Userful for unique identifier when mapping
    return this._docPath;
  }

  getDaysToCompleteTherapySurveyIfExists() {
    this.surveyWeeks.forEach(
      (survey) =>
        (survey.daysToCompleteTherapySurvey =
          survey.daysToCompleteTherapySurvey === undefined
            ? this.daysToCompleteTherapySurvey
            : survey.daysToCompleteTherapySurvey)
    );
  }


  getEditableData(): LandingPageExtensionFormValues {
    this.getDaysToCompleteTherapySurveyIfExists()
    return {
      consentProgram: this.consentProgram,
      consentFailProgram: this.consentFailProgram,
      therapyProgram: this.therapyProgram,
      doneProgram: this.doneProgram,
      earlyTerminationProgram: this.earlyTerminationProgram,
      surveyWeeks: this.surveyWeeks,
      trialID: this.trialID,
      terminationSurveys: this.terminationSurveys,
      terminationCall: this.terminationCall,
      paymentNotifications: this.paymentNotifications,
      weekdaySurveyAssignment: this.weekdaySurveyAssignment,
      daysToCompleteTherapySurvey: this.daysToCompleteTherapySurvey,
      landingPageKey: this.landingPageKey,
      scheduleCallOptions: this.scheduleCallOptions,
      consentCall: this.consentCall,
    }
  }

  static getEditableData(): LandingPageExtensionFormValues {
    return {
      consentProgram: "",
      consentFailProgram: "",
      therapyProgram: "",
      doneProgram: "",
      earlyTerminationProgram: "",
      surveyWeeks: getEmptySurveyWeeks(),
      terminationSurveys: [],
      terminationCall: { ...defaultTermCall },
      consentCall: { ...defaultConsentCall },
      trialID: "",
      paymentNotifications: emptyPaymentNotification,
      weekdaySurveyAssignment: 6,
      daysToCompleteTherapySurvey: 4,
      landingPageKey: "",
      scheduleCallOptions: {},
    }
  }

  async persist() {
    const data = this.data();
    data.updated = new Date();
    return await firestore.doc(this._docPath).update(data);
  }

  static async persistNewLandingPageExt(values: LandingPageExtensionFormValues, landingPageKey: string): Promise<LandingPageExtension> {
    // Find the landing page
    const landingPage = await LandingPage.fromLandingPageKey(landingPageKey);

    const newLPEXTNoID: Omit<ILandingPageExtension, 'extensionID'> = {
      ...values,
      created: new Date(),
      updated: new Date(),
      stylingID: landingPage.stylingID
    }

    // Add in the newly created extension
    const newLPEXTRef = await firestore.collection(`web/surveysaurus/landingPages/${landingPageKey}/extensions`).add(newLPEXTNoID);
    const newLPEXT: ILandingPageExtension = {
      ...values,
      created: new Date(),
      updated: new Date(),
      extensionID: newLPEXTRef.id,
      stylingID: landingPage.stylingID
    }

    // Tie the new extension to the landing page
    landingPage.extensionID = newLPEXTRef.id;
    await landingPage.persist();
    newLPEXTRef.update({
      extensionID: newLPEXTRef.id
    })

    return new LandingPageExtension(newLPEXT);
  }

  static async deleteLandingPageExt(landingPageKey: string, landingPageExt: LandingPageExtension) {
    // Delete the extension doc from extensions collection inside of current landing page
    await firestore.collection(`web/surveysaurus/landingPages/${landingPageKey}/extensions`).doc(landingPageExt.extensionID).delete();
    // Need to update base Landing Page extensionID to false
    await firestore.collection("web/surveysaurus/landingPages").doc(landingPageKey).update({
      extensionID: false
    });
  }

  static async fromLandingPageKey(landingPageKey: string): Promise<LandingPageExtension> {
    const landingPageRef = await firestore.doc(`web/surveysaurus/landingPages/${landingPageKey}`).get();
    if (!landingPageRef.exists) {
      throw new Error(`Landing page with key ${landingPageKey} does not exist`);
    }
    const landingPageData = landingPageRef.data() as ILandingPage;
    if (!landingPageData.extensionID) {
      throw new Error(`Landing page does not have an extension`);
    }
    const landingPageExtensionRef = await firestore.doc(`web/surveysaurus/landingPages/${landingPageKey}/extensions/${landingPageData.extensionID}`).get();
    if (!landingPageExtensionRef.exists) {
      throw new Error(`Landing page with key ${landingPageKey} and extension ID $${landingPageData.extensionID} does not exist`);
    }
    return LandingPageExtension.fromFirestore(landingPageExtensionRef);
  }
}

export default LandingPageExtension;
