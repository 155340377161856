import { firestore } from "firebase";
import { useEffect, useState } from "react";

export const useLandingPage = () => {
  const [cohorts, setCohorts] = useState([]);
  const [studies, setStudies] = useState([]);

  useEffect(() => {
    const unsubscribe = firestore()
      .collection("cohorts")
      .onSnapshot({ includeMetadataChanges: true }, (collection) => {
        if (collection.docs.length > 0) {
          setCohorts(
            collection.docs.map((doc) => {
              const data = doc.data();
              data.label = data.name;
              data.value = data.id;
              return data;
            })
          );
        }
      });
    return unsubscribe;
  }, []);

  useEffect(() => {
    const unsubscribe = firestore()
      .collection("studies")
      .onSnapshot({ includeMetadataChanges: true }, (collection) => {
        if (collection.docs.length > 0) {
          setStudies(
            collection.docs.map((doc) => {
              const data = doc.data();
              data.label = data.name;
              data.value = data.id;
              return data;
            })
          );
        }
      });
    return unsubscribe;
  }, []);

  return {
    cohorts,
    studies,
  };
};
