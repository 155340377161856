import { firestore, auth } from "firebase";

export class Resource {
  createdBy: string;
  updatedBy: string;

  constructor(
    public id: string,
    public order: number,
    public text: string,
    public url: string,
    public created = new Date(),
    public updated = new Date()
  ) {
    this.createdBy = auth().currentUser?.email || "";
    this.updatedBy = auth().currentUser?.email || "";
  }

  public data() {
    return {
      id: this.id,
      order: this.order,
      text: this.text,
      url: this.url,
      created: this.created,
      updated: this.updated,
      createdBy: this.createdBy,
      updatedBy: this.updatedBy,
    };
  }

  static toFirestore(resource: Resource) {
    return resource.data();
  }

  static fromFirestore(
    snapshot: firebase.firestore.QueryDocumentSnapshot,
    options?: firebase.firestore.SnapshotOptions
  ): Resource {
    const data = snapshot.data(options);
    const resource = new Resource(
      data.id,
      data.order,
      data.text,
      data.url,
      data.created?.toDate(),
      data.updated?.toDate()
    );
    resource.createdBy = data.createdBy;
    resource.updatedBy = data.updatedBy;

    return resource;
  }

  query(programKey: string) {
    return firestore()
      .collection("programs")
      .doc(programKey)
      .collection("resourceLinks")
      .doc(this.id);
  }

  addToBatch(batch: firestore.WriteBatch, programKey: string) {
    batch.set(this.query(programKey), {
      ...this.data(),
      updated: new Date(),
      updatedBy: auth().currentUser?.email || ""
    });
  }

  persist(programKey: string) {
    this.updatedBy = auth().currentUser?.email || "";
    this.updated = new Date();

    return firestore()
      .collection("programs")
      .doc(programKey)
      .collection("resourceLinks")
      .doc(this.id)
      .withConverter(Resource)
      .set(this);
  }
}
