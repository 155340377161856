import { ScheduledCallStatus } from "@swing-therapeutics/surveybay/dist/types";
import moment from "moment";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Container, Row, Table } from "react-bootstrap";
import { DocumentData, DocumentSnapshot, firestore } from "../../../firebase";
import { ScheduleCall } from "../../../Model/ScheduleCall";
import User from "../../../Model/Usert";
import { optionalCalls } from "../CalendlyCalls";

const ScheduledCalls: React.FC = () => {
  return (
    <Container>
      <Row className="mb-3">
        <h3>
          Scheduled Calls
        </h3>
      </Row>
      {
        optionalCalls.map((optionalCall) => (
          <ScheduledCallList eventName={optionalCall.name} key={optionalCall.name} />
        ))
      }
    </Container>
  )
}

export default ScheduledCalls;

interface CallListProps {
  eventName: string;
}

const ScheduledCallList: React.FC<CallListProps> = ({ eventName }) => {
  const [callSnaps, setCallSnaps] = useState<DocumentSnapshot<DocumentData>[] | null>(null);

  const getScheduledCalls = useCallback(async () => {
    const collection = await firestore.collectionGroup('calls').where('eventName', '==', eventName).where('status', 'in', [ScheduledCallStatus.CALL_SCHEDULED, ScheduledCallStatus.NOT_SCHEDULED]).get();
    const safeCollection: DocumentSnapshot<DocumentData>[] = [];
    // Because collectionGroup can select any collection with id 'calls', run a quick check to make sure all come from the users collection
    for (const callSnap of collection.docs) {
      const splitPath = callSnap.ref.path.split('/');
      // Path should be /users/{any user}/calls/{call doc}
      if (splitPath[0] !== 'users' || splitPath[2] !== 'calls') {
        console.error(`collection group query for user scheduled calls has received a call collection doc at ${callSnap.ref.path} that is not from the users collection`)
        continue;
      }
      safeCollection.push(callSnap);
    }
    setCallSnaps(collection.docs);
  }, [eventName])

  useEffect(() => {
    getScheduledCalls();
  }, [getScheduledCalls])

  return (
    <>
      <Row className='mt-4'>
        <h5>Calendly event name: {eventName}</h5>
      </Row>
      <Row>
        <Table striped responsive>
          <thead>
            <tr>
              <th>
                Subject ID
              </th>
              <th>
                Call title
              </th>
              <th>
                Required call
              </th>
              <th>
                Status
              </th>
              <th>
                Created
              </th>
            </tr>
          </thead>
          <tbody>
            {
              callSnaps === null ?
                <tr>
                  <td colSpan={5}>
                    Loading...
                  </td>
                </tr>
                :
                callSnaps.length ?
                  callSnaps.map((callSnap) => {
                    return (
                      <ScheduledCallRow callSnap={callSnap} key={callSnap.id} />
                    )
                  })
                  :
                  <tr>
                    <td colSpan={5}>
                      No users have scheduled or currently have the option to schedule a '{eventName}' call
                    </td>
                  </tr>
            }
          </tbody>
        </Table>
      </Row>
    </>
  )
}

interface CallData {
  subjectID: string;
  uid: string;
  callStatus: ScheduledCallStatus;
  callTime: string | null;
  callTitle: string;
  created: string;
  required: boolean;
}

interface CallRowProps {
  callSnap: DocumentSnapshot<DocumentData>;
}

const ScheduledCallRow: React.FC<CallRowProps> = ({ callSnap }) => {
  const [callData, setCallData] = useState<CallData | null>(null);
  const unSub = useRef<() => void>();

  const parseCallSnap = useCallback(async () => {
    // Path is /users/{any user}/calls/{call doc}
    const splitPath = callSnap.ref.path.split('/');
    // Get the users doc to get the user id and subject id
    const userSnap = await firestore.doc(`users/${splitPath[1]}`).get();
    const userData = User.fromFirestore(userSnap);
    unSub.current = callSnap.ref.onSnapshot((snap) => {
      const scheduleCall = ScheduleCall.fromFirestore(snap);
      setCallData({
        subjectID: userData.subjectID,
        uid: userData.uid,
        callStatus: scheduleCall.status,
        callTitle: scheduleCall.title,
        callTime: ScheduledCallStatus.CALL_SCHEDULED === scheduleCall.status && scheduleCall.callStartTime && scheduleCall.callEndTime ?
          `${moment(scheduleCall.callStartTime).format('h:mm')} - ${moment(scheduleCall.callEndTime).format('h:mm a, MM/DD/YY')}`
          :
          null,
        created: moment(scheduleCall.created).format('MM/DD/YY'),
        required: scheduleCall.required,
      })
    })
  }, [callSnap])

  useEffect(() => {
    parseCallSnap();
    return () => {
      unSub.current?.();
    }
  }, [parseCallSnap])

  return (
    <>
      {
        callData === null ?
          <tr>
            <td colSpan={5}>
              Loading...
            </td>
          </tr>
          :
          <tr>
            <td>
              {callData.subjectID}
            </td>
            <td>
              {callData.callTitle}
            </td>
            <td>
              {callData.required.toString()}
            </td>
            <td>
              {
                {
                  [ScheduledCallStatus.CALL_SCHEDULED]: `Call scheduled ${callData.callTime ?? ''}`,
                  [ScheduledCallStatus.NOT_SCHEDULED]: 'Not scheduled',
                  [ScheduledCallStatus.DECLINED]: 'Declined',
                  [ScheduledCallStatus.ABANDONED]: 'Abandoned',
                  [ScheduledCallStatus.COMPLETED]: 'Completed',
                }[callData.callStatus] || callData.callStatus
              }
            </td>
            <td>
              {callData.created}
            </td>
          </tr>
      }
    </>
  )
}
