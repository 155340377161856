import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FavoritesList, FavoritesListJson } from '@swing-therapeutics/swingcore/dist/models/FavoritesList'
import { getFavoritesListsTemplates } from '@swing-therapeutics/swingcore/dist/utils/queries';
import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap'
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import * as yup from 'yup';
import { BasicBooleanField, BasicEasyEditField, BasicNumberField, BasicTextField } from '../../../utils/FormikHelpers';
import LoadingScreen from '../../LoadingScreen';

interface FavoritesEditorProps {
  programKey: string;
  close: () => void;
}

interface FavoritesListURL {
  programID?: string;
  favoriteListID?: string;
}

const headerStyle = "border-top border-dark pt-3 mt-3";

export const FavoritesEditor = ({ programKey }: FavoritesEditorProps) => {
  const urlData = useRouteMatch("/programs/:programID/favorites/edit/:favoriteListID")?.params as FavoritesListURL;
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [initialValues, setInitialValues] = useState<FavoritesListJson>({
    id: '',
    name: '',
    programKey: '',
    removeIconName: '',
    addIconName: '',
    nullStateTitle: '',
    nullStateImage: '',
    nullStateText: '',
    firstTimeModalTitle: '',
    firstTimeModalText: '',
    firstTimeModalImage: '',
    firstTimeImageSize: undefined,
  });

  const formik = useFormik({
    initialValues: { ...initialValues },
    validationSchema: yup.object().shape({
      id: yup.string().required('ID is required'),
      name: yup.string().required('name is required'),
      removeIconName: yup.string().required('Remove Icon is required'),
      addIconName: yup.string().required('Add Icon is required'),
    }),
    enableReinitialize: true,
    onSubmit: async results => {
      setLoading(true);
      if (!results.programKey) {
        results.programKey = programKey;
      }

      if (typeof results.firstTimeImageSize !== 'number'){
        results.firstTimeImageSize = undefined;
      }

      await (new FavoritesList(
        results.id,
        results.name,
        results.programKey,
        results.removeIconName,
        results.addIconName,
        results.nullStateTitle,
        results.nullStateText,
        results.nullStateImage,
        results.firstTimeModalTitle,
        results.firstTimeModalText,
        results.firstTimeModalImage,
        results.firstTimeImageSize,
      )).persist();
      history.goBack();
    }
  })

  useEffect(() => {
    if (urlData?.favoriteListID && urlData?.programID) {
      setLoading(true);
      getFavoritesListsTemplates(urlData.programID).doc(urlData.favoriteListID).get().then(snapshot => {
        if (snapshot.exists) {
          setInitialValues(snapshot.data() as FavoritesListJson);
          formik.resetForm();
        }
        setLoading(false);
      })
    } else {
      setLoading(false);
    }
  }, [])


  if (loading) {
    return (
      <Container>
        <LoadingScreen />
      </Container>
    )
  }

  return (
    <Container>
      <Row className="mb-4">
        <Col>
          <Link to={`/programs/${programKey}/values`} onClick={history.goBack}>
            <FontAwesomeIcon icon={faArrowLeft} /> Go Back
          </Link>
        </Col>
      </Row>

      <Row className={headerStyle}>
        <BasicTextField path="id" title="ID" formikHelpers={formik} />
        <BasicTextField path="name" title="Name" formikHelpers={formik} />
      </Row>

      <Row className={headerStyle}>
        <Col>
          <h4>Icon</h4>
          <Row>
            <BasicTextField path="addIconName" title="Add Icon Name" formikHelpers={formik} />
            <BasicTextField path="removeIconName" title="Remove Icon Name" formikHelpers={formik} />
          </Row>
        </Col>
      </Row>

      <Row className={headerStyle}>
        <Col>
          <h4>Null State</h4>
          <Row>
            <BasicTextField path="nullStateTitle" title="Null Title" formikHelpers={formik} />
            <BasicTextField path="nullStateImage" title="Null Image" formikHelpers={formik} />
            <BasicEasyEditField path="nullStateText" title="Null Text" formikHelpers={formik} lg={8} markupType="markdown" />
          </Row>
        </Col>
      </Row>

      <Row className={headerStyle}>
        <Col>
          <h4>First Time Modal</h4>
          <Row>
            <BasicTextField path="firstTimeModalTitle" title="Modal Title" formikHelpers={formik} lg={8}/>
            <BasicTextField path="firstTimeModalImage" title="Modal Image" formikHelpers={formik} lg={6}/>
            <BasicNumberField path="firstTimeImageSize" title="Image Size" formikHelpers={formik} lg={2} tooltip="Leave blank to automatically size"/>
            <BasicEasyEditField path="firstTimeModalText" title="Modal Text" formikHelpers={formik} lg={8} markupType="markdown" />
          </Row>
        </Col>
      </Row>

      <Row className="justify-content-end pr-3">
        <Col xs="auto">
          <Button
            variant={!formik.dirty ? 'secondary' : 'primary'}
            disabled={!formik.dirty}
            onClick={formik.submitForm}
          >
            Save
            </Button>
        </Col>
      </Row>
    </Container>
  )
}
