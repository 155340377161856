import { firestore } from "firebase";
import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import Select from "react-select";
import { Celebration } from "@swing-therapeutics/swingcore/dist/models/content/Celebration";

interface CelebrationEditorProps {
  session: any;
  sessionDoc: firestore.DocumentReference<firestore.DocumentData>;
  celebrations: Celebration[];
}

const CelebrationEditor = ({
  session,
  sessionDoc,
  celebrations,
}: CelebrationEditorProps) => {
  const [
    selectedCelebrationIndex,
    setSelectedCelebrationIndex,
  ] = useState<number>(null);
  const selectRef = useRef(null);
  const [touched, setTouched] = useState(false);

  useEffect(() => {
    if (!session) {
      setSelectedCelebrationIndex(null);
    } else {
      const celebrationIndex = celebrations.findIndex(
        (celebration) => celebration.id === session?.celebrationId
      );
      setSelectedCelebrationIndex(
        celebrationIndex === -1 ? null : celebrationIndex
      );
    }
  }, [session]);

  useEffect(() => {
    if (touched) {
      sessionDoc.update({
        celebrationId: celebrations[selectedCelebrationIndex].id,
        updated: new Date(),
      })
    }
  }, [selectedCelebrationIndex])

  const deleteCelebration = async () => {
    if (window.confirm("Are you sure you want to delete this celebration?")) {
      await sessionDoc.update({
        celebrationId: null,
        updated: new Date(),
      });
    }
  };

  return (
    <Row className="flex-nowrap align-items-center">
      <Col xl={6}>
        <Select
          ref={selectRef}
          placeholder="Select a celebration..."
          options={celebrations.map((celebration, index) => ({
            label: celebration.name,
            value: celebration.id,
            index,
          }))}
          onSelectResetsInput={false}
          value={{
            label: celebrations[selectedCelebrationIndex]?.name,
            value: celebrations[selectedCelebrationIndex]?.id,
            index: selectedCelebrationIndex,
          }}
          onChange={(option) => {
            setTouched(true);
            setSelectedCelebrationIndex(option["index"]);
          }}
        />
      </Col>
      <Col>
        <Button
          disabled={selectedCelebrationIndex === null}
          onClick={deleteCelebration}
          style={{ height: 30 }}
          variant="outline-danger"
          size="sm"
        >
          X
        </Button>
      </Col>
    </Row>
  );
};

export { CelebrationEditor };
