import React, { useEffect, useRef } from "react";
import { Row } from "react-bootstrap";

export default function usePayments(pageSize: number, pageNumber?: number) {
  const [docs, setDocs] = React.useState<any[]>([]);
  const [visibleDocs, setVisibleDocs] = React.useState<any[]>([]);
  const [documentsLoading, setDocumentsLoading] = React.useState(true);
  const page = useRef(1);
  const [hasNextPage, setHasNextPage] = React.useState(true);
  const [hasPreviousPage, setHasPreviousPage] = React.useState(false);
  const [maxPages, setMaxPages] = React.useState(0);
  const [documentsError, setDocumentsError] = React.useState("" as any);

  useEffect(() => {
    if (pageNumber && pageNumber !== 1) {
      page.current = Number(pageNumber);
    }
    getDocs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [docs]);

  useEffect(() => {
    setMaxPages(Math.ceil(docs.length / pageSize));
    setHasNextPage(page.current < maxPages);
    setHasPreviousPage(page.current > 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [docs, maxPages, pageSize, page.current]);

  const getDocs = async () => {
    if (!docs.length) return;
    try {
      if (pageNumber > Math.ceil(docs.length / pageSize)) {
        page.current = Math.ceil(docs.length / pageSize);
      }
      const start = (page.current - 1) * pageSize;
      const end = page.current * pageSize;
      setVisibleDocs(docs.slice(start, end));
      setDocumentsLoading(false);
    } catch (error) {
      setDocumentsError(error);
      setDocumentsLoading(false);
    }
  };

  const goToPreviousPage = () => {
    page.current = page.current - 1;
    const sliceEnd = page.current * pageSize;
    const sliceStart = sliceEnd - pageSize;
    setVisibleDocs(docs.slice(sliceStart, sliceEnd));
  };

  const goToNextPage = () => {
    const sliceStart = page.current * pageSize;
    let sliceEnd = sliceStart + pageSize;
    setVisibleDocs(docs.slice(sliceStart, sliceEnd));
    page.current = page.current + 1;
  };

  const PaginationButtons = () => {
    return (
      <Row
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: 20,
          marginBottom: 10,
        }}
      >
        <button
          style={{ opacity: !hasPreviousPage ? 0.3 : 1, marginRight: 15 }}
          disabled={!hasPreviousPage}
          onClick={goToPreviousPage}
        >
          Previous Page
        </button>
        <button
          style={{ opacity: !hasNextPage ? 0.3 : 1, marginLeft: 15 }}
          disabled={!hasNextPage}
          onClick={goToNextPage}
        >
          Next Page
        </button>
      </Row>
    );
  };

  return {
    docs,
    documentsLoading,
    page,
    hasNextPage,
    hasPreviousPage,
    visibleDocs,
    goToNextPage,
    goToPreviousPage,
    setDocs,
    PaginationButtons,
    documentsError,
  };
}
