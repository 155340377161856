import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SurveyInfo } from "@swing-therapeutics/surveybay/dist/types";
import React, { useState } from "react";
import { Col, Row, Table } from "react-bootstrap";
import User from "../../../../Model/Usert";
import { Workflow, getColorFromStatus, TerminationSurveysWorkflowData } from "../../../../Model/Workflow";
import SurveyResponseView from "./SurveyResponseView";

interface TerminationViewProps {
  workflow: Workflow;
  user: User;
}

enum views {
  SUMMARY = 'summary',
  SURVEY = 'survey',
}

const TerminationSurveysView: React.FC<TerminationViewProps> = ({ workflow, user }) => {
  const workflowData = workflow.workflowData as TerminationSurveysWorkflowData;
  const [view, setView] = useState<views>(views.SUMMARY);
  const [viewSurveyID, setViewSurveyID] = useState('');

  const handleRowClick = (survey: SurveyInfo) => {
    setViewSurveyID(survey.responseDocID);
    setView(views.SURVEY);
  }

  return (
    <Col>
      {
        view === views.SUMMARY &&
        <Row>
          <Table striped responsive>
            <thead>
              <tr>
                <th>surveyBayKey</th>
                <th>Status</th>
                <th>Assigned</th>
                <th>Expires</th>
              </tr>
            </thead>
            <tbody>
              {workflowData.terminationSurveys.map((survey) => {
                return (
                  <tr key={survey.surveyBayKey} className="hoverablerow" onClick={() => handleRowClick(survey)}>
                    <td>{survey.surveyBayKey}</td>
                    <td style={{ color: getColorFromStatus(survey.status) }}>{survey.status}</td>
                    <td>{survey.assigned.toDateString()}</td>
                    <td>{survey.expires.toDateString()}</td>
                  </tr>
                )
              })}
            </tbody>
          </Table>
        </Row>
      }
      {
        view === views.SURVEY &&
        <>
          <Row>
            <div
              className="journal-back-button"
              onClick={() => {
                setViewSurveyID('');
                setView(views.SUMMARY);
              }}
            >
              <FontAwesomeIcon icon={faArrowLeft} />
              {' '}Go Back
            </div>
          </Row>
          <SurveyResponseView responseDocID={viewSurveyID} userUID={user.uid} />
        </>
      }
    </Col>
  )
}

export default TerminationSurveysView;
