import { firestore } from "firebase";

export enum Platform {
  android = 'android',
  ios = 'ios',
}

interface BlacklistProps {
  fullVersionName: string;
  reason: string;
  platform: Platform;
  created: Date;
  createdBy: string;
}

class BlacklistedVersion implements BlacklistProps {
  fullVersionName: string = "";
  reason: string = "";
  platform: Platform | undefined = undefined;
  created: Date = new Date();
  createdBy: string = "";

  static fromParams(
    fullVersionName: string,
    reason: string,
    platform: Platform,
    created: Date,
    createdBy: string
  ) {
    const version = new BlacklistedVersion();
    version.fullVersionName = fullVersionName;
    version.reason = reason;
    version.platform = platform;
    version.created = created;
    version.createdBy = createdBy;

    return version;
  }

  static fromFirestore(docRef: any) {
    let data = docRef.data();
    return BlacklistedVersion.fromParams(
      data.fullVersionName,
      data.reason,
      data.platform,
      data.created = data.created ? data.created.toDate() : new Date(),
      data.createdBy,
    )
  }

  data() {
    return {
      fullVersionName: this.fullVersionName,
      reason: this.reason,
      platform: this.platform,
      created: this.created,
      createdBy: this.createdBy,
    }
  }

  query() {
    return firestore()
    .collection('tempest')
    .doc('versions')
    .collection('blacklist')
    .doc(this.fullVersionName)
  }

  async persist() {
    return await this.query()
      .set(this.data())
      .catch(e => console.log(`unable to persist blacklisted version ${this.fullVersionName}`, e))
  }

  addToBatch(batch: firestore.WriteBatch) {
    batch.set(this.query(), this.data());
  }
}

export { BlacklistedVersion };
