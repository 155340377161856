import React, { useState } from 'react';
import { Col, Container, Nav } from "react-bootstrap";
import { RouteComponentProps } from 'react-router';
import { WorkflowType } from '@swing-therapeutics/surveybay/dist/types';
import LandingPageCreateEdit from '../LandingPageCreateEdit';
import LandingPageCreateEditExt from '../extension/LandingPageCreateEditExt';
import LandingPageStylingEdit from '../LandingPageStylingEdit';

interface PageParams {
  action: "new" | "edit";
  landingPageKey?: string;
}

const ManageLandingPage: React.FC<RouteComponentProps<PageParams>> = ({ match }) => {

  const [activeKeyTab, setActiveKeyTab] = useState(WorkflowType.STANDARD);
  const stylingText = 'Styling';


  const handleTabSelect = (selectedKey) => {
    setActiveKeyTab(selectedKey)
  }

  return (
    <div className={"album bg-light"}>
      <Container fluid style={{ backgroundColor: "#f3f3f3" }} >
        <Col>
          <Nav
            variant="tabs"
            activeKey={activeKeyTab}
            onSelect={handleTabSelect}
            style={{ marginBottom: '20px' }}
          >
            <Nav.Item>
              <Nav.Link eventKey={WorkflowType.STANDARD}>{match.params.landingPageKey ?? 'New Landing Page'}</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey={WorkflowType.EXTENSION}>{match.params.landingPageKey} Extension Landing Page</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey={stylingText}>{match.params.landingPageKey} Styling</Nav.Link>
            </Nav.Item>
          </Nav>
        </Col>
      </Container>
      {
        {
          [WorkflowType.STANDARD]: <LandingPageCreateEdit action={match.params.action} landingPageKey={match.params.landingPageKey} />,
          [WorkflowType.EXTENSION]: <LandingPageCreateEditExt action={match.params.action} landingPageKey={match.params.landingPageKey} />,
          [stylingText]: <LandingPageStylingEdit action={match.params.action} landingPageKey={match.params.landingPageKey} />,
        }[activeKeyTab]
      }
    </div>
  )
}

export default ManageLandingPage;
