import React from "react";
import { DraggableProvided, DraggableStateSnapshot } from "react-beautiful-dnd";
import { Button, ButtonGroup } from "react-bootstrap";
import useRole from "../../../../UseRole";
import { Link, useRouteMatch } from "react-router-dom";
import { Celebration } from "@swing-therapeutics/swingcore/dist/models/content/Celebration";
import { Dictionary } from "lodash";
import { SessionTemplate } from "@swing-therapeutics/swingcore/dist/models/Session";

interface TableRowProps {
  session: SessionTemplate;
  celebrationsById: Dictionary<Celebration>;
  draggableProvider: DraggableProvided;
  draggableSnapshot: DraggableStateSnapshot;
  shouldShowCheckbox: boolean;
  deleteSession: () => void;
  selectedSessions: Set<any>;
  setSelectedSessions: (newSelectedSessions: Set<any>) => void;
}

const SessionListTableRow = ({
  session,
  celebrationsById,
  draggableProvider,
  draggableSnapshot,
  deleteSession,
  selectedSessions,
  setSelectedSessions,
  shouldShowCheckbox,
}: TableRowProps) => {
  const isAdmin = useRole("admin");
  const match = useRouteMatch();

  return (
    <>
      <tr
        {...draggableProvider.draggableProps}
        {...draggableProvider.dragHandleProps}
        ref={draggableProvider.innerRef}
        key={`row-${session.id}`}
      >
        {shouldShowCheckbox && (
          <td>
            <input
              type="checkbox"
              name="checks"
              onChange={() => {
                const newSelectedSessions = new Set(selectedSessions);
                if (selectedSessions.has(session)) {
                  newSelectedSessions.delete(session);
                } else {
                  newSelectedSessions.add(session);
                }
                setSelectedSessions(newSelectedSessions);
              }}
              checked={selectedSessions.has(session)}
            />
          </td>
        )}
        <td>{session.num}</td>
        <td>{session.id}</td>
        <td>{session.previousSessionID}</td>
        <td>{session.nextSessionID}</td>
        <td>
          <ul>
            {session.tasks?.map((task: any, index: number) => {
              const key = `row-${session.id}-task-${index + 1}`;

              if (task.type === "lesson") {
                return (
                  <li
                    key={key}
                  >{`lesson (Ch${task.chapter},L${task.lesson})`}</li>
                );
              } else {
                return <li key={key}>{task.displayName || task.type}</li>;
              }
            })}
          </ul>
        </td>
        <td>{celebrationsById[session.celebrationId]?.name}</td>
        {!draggableSnapshot.isDragging && isAdmin && (
          <td>
            <ButtonGroup>
              <Link to={`${match.url}/${session.id}/tasks`}>
                <Button variant="outline-primary" style={{ height: "100%" }}>
                  Edit Tasks
                </Button>
              </Link>
              <Button variant="outline-secondary" onClick={deleteSession}>
                Delete
              </Button>
            </ButtonGroup>
          </td>
        )}
      </tr>
    </>
  );
};

export { SessionListTableRow };
