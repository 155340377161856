import React, {useState} from "react";
import {Link} from "react-router-dom";
import {useCollection, useDocument} from "react-firebase-hooks/firestore";
import LoadingScreen from "../../LoadingScreen";
import TabContainer from "react-bootstrap/TabContainer";
import "../surveyAdherence.css";
import {firestore} from "../../../firebase";
import AdminNavbar from "../../AdminNavbar";
import {useHistory} from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import NavItem from "react-bootstrap/NavItem";

import {Button, Col, Container, Form, FormControl, InputGroup, Jumbotron, Modal, Row, Table,} from "react-bootstrap";
import AReportTable004B from "./AReportTable004B";
import AlargerReportTable004B from "./AlargerReportTable004B";
import AnotherReportsListSidebar004B from "./AnotherReportsListSidebar004B";

import AsmallerReportTable004B from "./AsmallerReportTable004B";
    // const query = firestore.collection("analytics/reports/reportPages");
    // const [reportPages, reportPagesLoading, reportPagesError] = useCollection(query, {
        // snapshotListenOptions: {includeMetadataChanges: true},
    // });
// const query = firestore.collection("analytics").doc("kpi_summary")
const B004Sites = (props) => {
 return (<>
        <AdminNavbar/>
        <Jumbotron>
            <Container fluid={true} style={{ paddingBottom: "30px"}}>
                <Row>
                    <Col>
                        <h1>004 Sites Cohort 2</h1>
                    </Col>
                </Row>
            </Container>
        </Jumbotron>
        <AnotherReportsListSidebar004B/>
        
           <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "40px"}}>
            <div className="article" style={{paddingBottom: "30px", paddingLeft: "40px"}}>
                
            <div className="TitleC">Participant ARM counts</div>
            < div classname="TitleCb">As defined by app use, not medrio</div>
            <AsmallerReportTable004B tableKey={"site_arm_counts004B"}></AsmallerReportTable004B>
                </div>
        </Container>
        <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "40px"}}>
            <div className="article" style={{paddingBottom: "30px", paddingLeft: "40px"}}>
            <div className="TitleC">Screen Fails</div>
            <AlargerReportTable004B tableKey={"screenfails004B"}></AlargerReportTable004B>
                </div>
        </Container>
        <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "40px"}}>
            <div className="article" style={{paddingBottom: "30px", paddingLeft: "40px"}}>
            <div className="TitleC">Visits</div>
            <AReportTable004B tableKey={"visits_by_siteB"}></AReportTable004B>
                </div>
        </Container>
    
     
       



      
</>);

};

export default B004Sites;

