import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import { firestore } from "../../../firebase";
import AdminNavbar from "../../AdminNavbar";

const AddEproKey = () => {
  const [inputs, setInputs] = useState({
    key: "",
    name: "",
    pro: "",
    pro_component: "",
    Type: "",
  });
  const [submit, setSubmit] = useState()

  const container = {
    borderStyle: "ridge",
    padding: "20px",
    marginRight: "50%",
    maxWidth: "50%",
  };

  const handleSubmit = () => {
    firestore.collection("/tempest/eproKeys/prePopKeys").doc(inputs.name).set({
      key: inputs.key,
      name: inputs.name,
      pro: inputs.pro,
      pro_component: inputs.pro_component,
      Type: inputs.Type,
    });
    setSubmit("Epro Key Submitted");
    setInputs("");
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setInputs((vals) => {
      return { ...vals, [name]: value };
    });
    setSubmit("");
  };

  return (
    <>
      <AdminNavbar></AdminNavbar>
      <div style={{ padding: 15 }}>
        <h2> Add Epro Key</h2>
        <Formik
          initialValues={{
            key: "",
            name: "",
            pro: "",
            pro_component: "",
            Type: "",
          }}
          onSubmit={handleSubmit}
        >
          {({}) => (
            <div className="container" style={container}>
              <div className="col-sm-12">
                <h4>{submit}</h4>
                <Form>
                  <label>Key</label>
                  <br />
                  <div className="form-group">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Key"
                      name="key"
                      value={inputs.key || ""}
                      onChange={handleChange}
                    />
                  </div>

                  <label>Name</label>
                  <div className="form-group">
                    <Field
                      type="text"
                      className="form-control"
                      name="name"
                      placeholder="Name"
                      value={inputs.name || ""}
                      onChange={handleChange}
                    />
                  </div>
                  <label>Pro</label>
                  <div className="form-group">
                    <Field
                      type="text"
                      className="form-control"
                      name="pro"
                      placeholder="Pro"
                      value={inputs.pro || ""}
                      onChange={handleChange}
                    />
                  </div>
                  <label>Pro_component</label>
                  <div className="form-group">
                    <Field
                      type="text"
                      className="form-control"
                      name="pro_component"
                      placeholder="Pro_component"
                      value={inputs.pro_component || ""}
                      onChange={handleChange}
                    />
                  </div>
                  <label>Type</label>
                  <div className="form-group">
                    <Field
                      type="text"
                      className="form-control"
                      placeholder="Type"
                      name="Type"
                      value={inputs.Type || ""}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="form-group">
                    <button
                      className="btn btn-primary"
                      type="submit"
                      style={{ fontSize: "16px" }}
                    >
                      Submit
                    </button>
                  </div>
                </Form>
              </div>
            </div>
          )}
        </Formik>
      </div>
    </>
  );
};

export default AddEproKey;
