import { storage } from "firebase";
import { useState, useEffect } from "react";

export function useStorageBucket(bucketUrl: string) {
  if (!bucketUrl.startsWith('gs://')) {
    throw new Error('Invalid storage bucket path (must use a gs:// url)');
  }

  const [files, setFiles] = useState<storage.Reference[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error>(null);

  useEffect(() => {
    storage()
    .refFromURL(bucketUrl)
    .listAll()
    .then(result => setFiles(result.items))
    .catch(error => setError(error))
    .finally(() => setLoading(false))
  }, []);

  return [files, loading, error] as [storage.Reference[], boolean, Error];
}

export function useSvgBucket() {
  return useStorageBucket('gs://tempo-pub/assets/v1/svgs');
}

export function useImageBucket() {
  return useStorageBucket(`gs://tempo-pub/assets/v1/images`);
}
