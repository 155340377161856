import React, { useEffect, useState } from "react";
import { firestore } from "../../firebase";

import Plot from "react-plotly.js";
import GraphLines from "./GraphLines";

const UserGraph = (props) => {
  const [lines, setLines] = useState([]);
  const [loading, setLoading] = useState(false);

  let { user, title, program } = props;
  title = title ? title : " ";

  /*
      Sets the program to default to the users active program if nothing is passed in.
       */

  const prog = program ? program : user.activeProgram;

  //Data Lines for fun

  const setup = async () => {
    if (loading) {
      return;
    }
    setLoading(true);
    let oneADayLine = GraphLines.onePerDayLine(84, 41);
    setLines([oneADayLine]);

    //add new lines here
    let taskLine = await GraphLines.getUsersTaskLine(user, prog);
    let sessionLine = await GraphLines.getUsersSessionLine(user, prog);
    //  let summaryLine = await GraphLines.getUsersSummaryLine(user, prog);
    //dont forget new lines go in the chart too
    setLines([oneADayLine, taskLine, sessionLine]);
  };

  useEffect(() => {
    setup();
  }, [setup]);

  let graph = (
    <Plot
      scrollZoom={true}
      data={lines}
      className="col"
      useResizeHandler={true}
      layout={{
        title: title,
        margin: {
          l: 100,
          r: 100,
          t: 100,
        },
      }}
    />
  );

  return graph;
};

export default UserGraph;
