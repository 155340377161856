import { firestore, Timestamp } from "../firebase";
import { clone } from "lodash";
import { SessionTemplate } from "@swing-therapeutics/swingcore/dist/models/Session";

import * as Yup from 'yup';

export interface PlaylistType {
  name: string;
  rank?: number;
  id: string;
  programKey: string;
  description: string;
  userViewable: boolean;
  sessions?: SessionTemplate[];
}

export interface PlaylistReferenceType extends PlaylistType {
  updated: Timestamp;
  created: Timestamp;
}

export interface ConfirmationActivity {
  description: string;
  icon: string;
}

export interface ConfirmationLesson {
  title: string;
}

export type Snapshot<T> = firebase.firestore.QueryDocumentSnapshot<T>

export type Document = Snapshot<
  firebase.firestore.DocumentData
>;

export type PlaylistDocument = Snapshot<
  PlaylistReferenceType
>;

export class Playlist implements PlaylistType {
  name: string;
  rank?: number;
  id: string;
  programKey: string;
  description: string;
  userViewable: boolean;
  confirmationLessons?: ConfirmationLesson[];
  confirmationActivities?: ConfirmationActivity[];
  updated: Date;
  created: Date;
  sessions?: SessionTemplate[];
  firstSessionID?: string;
  imageURL?: string;

  constructor(programKey: string, values: PlaylistReferenceType | PlaylistType | PlaylistDocument) {
    let data;
    //@ts-ignore '?.data' will safely determine if it's a document
    if (values?.data) {
      data = (values as PlaylistDocument).data();
    } else {
      data = values;
    }

    this.name = data?.name;
    this.rank = data?.rank;
    this.id = data?.id;
    this.description = data?.description;
    this.userViewable = data?.userViewable;
    this.programKey = programKey;
    this.confirmationLessons = data?.confirmationLessons;
    this.confirmationActivities = data?.confirmationActivities;
    this.created = this.getDateValue(data?.created);
    this.updated = this.getDateValue(data?.updated);
    this.firstSessionID = data?.firstSessionID;
    this.imageURL = data?.imageURL;
  }

  private getDateValue(value?: any,) {
    if (value) {
      if (value instanceof Date) {
        return value
      } else if (value?.toDate) {
        return value.toDate();
      }
    }

    return new Date();
  }

  getPersistableData() {
    this.updated = new Date();
    const data = clone(this);
    delete data.sessions;
    Object.keys(data).forEach(
      (key) => data[key] === undefined && delete data[key]
    );
    return Object.assign({}, data);
  }

  async persist() {
    if (!this.rank) {
      this.rank =
        (await firestore
          .collection("programs")
          .doc(this.programKey)
          .collection("playlist")
          .get()
          .then((snapshot) => snapshot.docs.length)) + 1;
    }

    await firestore
      .collection("programs")
      .doc(this.programKey)
      .collection("playlist")
      .doc(this.id)
      .set(this.getPersistableData());

    if (this.sessions?.length > 0) {
      for (let session of this.sessions) {
        await session.persist(this.id)
      }
    }
  }

  async loadSessions() {
    this.sessions = (await firestore
      .collection('programs')
      .doc(this.programKey)
      .collection('playlist')
      .doc(this.id)
      .collection('sessions')
      .get())
      .docs
      .map(doc => SessionTemplate.fromFirestore(doc));
  }
}

export const playlistSchema = Yup.object().shape({
  name: Yup.string().required('Name'),
  id: Yup.string().required('ID'),
  description: Yup.string().required('Description'),
})

export const deletePlaylist = async (programKey: string, id: string) => {
  const sessionDocs = await firestore
    .collection("programs")
    .doc(programKey)
    .collection("playlist")
    .doc(id)
    .collection("sessions")
    .get();

  for (let doc of sessionDocs.docs) {
    await doc.ref.delete();
  }

  await firestore
    .collection("programs")
    .doc(programKey)
    .collection("playlist")
    .doc(id)
    .delete();
};

export class PlaylistProgress {
  completedSessions: number = 0;
  currentSession: string = "";
  playlistCompleted: Date = null;
  playlistLocation: string = "";
  playlistStarted: Date = null;
  playlistExists: boolean = null;
  playlist: Playlist;

  static fromFirestore(docRef: any) {
    let data = docRef.data();
    let u = new PlaylistProgress();

    u.completedSessions = data.completedSessions;
    u.currentSession = data.currentSession;
    u.playlistLocation = data.playlistLocation;
    u.playlistCompleted = data.playlistCompleted ? data.playlistCompleted.toDate() : null;
    u.playlistStarted = data.playlistStarted ? data.playlistStarted.toDate() : null;

    return u;
  }

  async loadPlaylist(programID: string) {
    // Grab the playlist information
    // Strip that last portion of path, it is /sessions and we want actualy playlist
    const playlistPath = this.playlistLocation.substring(0, this.playlistLocation.lastIndexOf('/'));
    const playlistDoc = await firestore
      .doc(playlistPath)
      .get() as PlaylistDocument
    // Check that the playlist doc exists
    if (playlistDoc.exists) {
      this.playlistExists = true;
      this.playlist = new Playlist(programID, playlistDoc);
    }
    else {
      this.playlistExists = false;
      console.warn(`Playlist doesnt exist for program ${programID}`, playlistPath)
    }
  }
}