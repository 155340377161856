import React, {useState} from "react";
import {Link} from "react-router-dom";
import {useCollection, useDocument} from "react-firebase-hooks/firestore";
import LoadingScreen from "../LoadingScreen";
import TabContainer from "react-bootstrap/TabContainer";
import "./surveyAdherence.css";
import {firestore} from "../../firebase";
import AdminNavbar from "../AdminNavbar";
import {useHistory} from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import NavItem from "react-bootstrap/NavItem";

import {Button, Col, Container, Form, FormControl, InputGroup, Jumbotron, Modal, Row, Table,} from "react-bootstrap";
import AReportTable from "./AReportTable";
import AsmallerReportTable from "./AsmallerReportTable";
import AreportsListSidebar from "./AreportsListSidebar";
    // const query = firestore.collection("analytics/reports/reportPages");
    // const [reportPages, reportPagesLoading, reportPagesError] = useCollection(query, {
        // snapshotListenOptions: {includeMetadataChanges: true},
    // });
// const query = firestore.collection("analytics").doc("kpi_summary")
const AreportsList = (props) => {
 return (<>
        <AdminNavbar/>
        <Jumbotron>
            <Container fluid={true}>
                <Row>
                    <Col>
                        <h1>Reports 002</h1>
                    </Col>
                </Row>
            </Container>
        </Jumbotron>
        <AreportsListSidebar/>
      
        <Container fluid={true} style={{backgroundColor: "white"}}>
            <div className="article">
            <p className="TitleC">KPIs</p>
            <p></p>
            <p className="norms">NPS scores</p>
            <AReportTable tableKey={"nps_kpis"}></AReportTable>
                </div>
        </Container>
        <Container fluid={true} style={{backgroundColor: "white"}}>
            <div className="article">
            <p className="norms">80% of ACT participants engaging at least 5 days / week</p>
            <AReportTable tableKey={"engagement_kpis"}></AReportTable>
                </div>
        </Container>
        <Container fluid={true} style={{backgroundColor: "white"}}>
            <div className="article">
            <p className="norms">80% of ACT participants completing at least 4 lessons / week</p>
            <AReportTable tableKey={"lesson_kpis"}></AReportTable>
                </div>
        </Container>
        <Container fluid={true} style={{backgroundColor: "white"}}>
            <div className="article">
            <p className="norms">80% of ST participants completing at least 4 entries / week</p>
            <AsmallerReportTable tableKey={"ST_app_kpis"}></AsmallerReportTable>
                </div>
        </Container>
        <Container fluid={true} style={{backgroundColor: "white"}}>
            <div className="article">
            <p className="norms">80% of all participants completing weekly PROs.</p>
            <AsmallerReportTable tableKey={"pro_adherence_kpis"}></AsmallerReportTable>
                </div>
        </Container>
        <Container fluid={true} style={{backgroundColor: "white"}}>
            <div className="article">
            <p className="norms">Average Improvement & Proportion with 25% improvement in FIQR</p>
            <AReportTable tableKey={"fiqr_kpis002"}></AReportTable>
                </div>
        </Container>
        {/* wtf */}
      
</>);

};

export default AreportsList;

