import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Redirect, RouteComponentProps } from "react-router";
import { Col, Container, Row } from "react-bootstrap";
import { Form as FormikForm, Formik, useField } from "formik";
import * as Yup from "yup";
import { firestore } from "../../../firebase";
import ReferralGroup, { ReferralGroupFormValues } from "../../../Model/ReferralGroup";
import LoadingScreen from "../../LoadingScreen";
import { urlRegExp } from "../../../utils/Regex";
import { FormikSubmitButton, FormikTextField } from "../../FormikFields";

interface PageParams {
  action: "new" | "edit";
  referralGroupKey?: string;
}

const ImageDisplay: React.FC = () => {
  const [field, meta] = useField("logoURL");
  let validLogoURL: boolean | string = false;
  if (field.value && urlRegExp.test(field.value)) {
    validLogoURL = field.value;
  }

  return useMemo(() =>
    <>
      <Row className="justify-content-center">
        <h3>Logo Image</h3>
      </Row>
      <Row className="justify-content-center">
        {typeof validLogoURL === "string" ?
          <img src={validLogoURL} style={{ maxWidth: '100%', maxHeight: 300, borderRadius: 10 }} />
          :
          meta.touched ?
            <h4>Invalid logo url</h4>
            :
            <h4 className="mt-3">Enter Logo image URL to see preview</h4>
        }
      </Row>
    </>
    , [validLogoURL, meta.touched])
}

const ReferralGroupCreateEdit: React.FC<RouteComponentProps<PageParams>> = ({ match }) => {
  const {
    params: { action, referralGroupKey },
  } = match;
  const [initialFormValues, setInitialFormValues] = useState<ReferralGroupFormValues>(null);
  const [error, setError] = useState<string>(null);
  const [message, setMessage] = useState("");
  const isMounted = useRef(true);

  const fetchAndSetInitialFormValues = useCallback(async () => {
    const doc = await firestore.collection("web/surveysaurus/referralGroups").doc(referralGroupKey).get();
    if (doc?.exists) {
      const initialValues = ReferralGroup.fromFirestore(doc).getEditableData();
      setInitialFormValues(initialValues);
    }
    else {
      setError(`Referral group key (${referralGroupKey}) not found`);
    }
  }, [referralGroupKey])

  useEffect(() => {
    isMounted.current = true;
    if (referralGroupKey && action === "edit") {
      fetchAndSetInitialFormValues();
    }
    else {
      setInitialFormValues({ ...ReferralGroup.getEditableData() });
    }
    return () => { isMounted.current = false };
  }, [action, referralGroupKey])

  const setDisappearingMessage = useCallback((msg) => {
    setMessage(msg);
    setTimeout(() => {
      isMounted.current && setMessage(null);
    }, 5000);
  }, [isMounted])

  if (action !== "new" && !referralGroupKey) {
    console.error("Referral group key not defined for edit referral group page")
    return <Redirect to="/surveysaurus" />
  }

  return (
    <Container>
      <Row className="mb-3">
        <h3>
          {
            action === "new" ?
              "Create new referral group"
              :
              "Edit referral group"
          }
        </h3>
      </Row>
      <Row>
        {
          initialFormValues ?
            <Formik
              initialValues={{ ...initialFormValues }}
              enableReinitialize={true}
              onSubmit={async (values: ReferralGroupFormValues, actions) => {
                if (action === "new") {
                  // New referral group created
                  await ReferralGroup.persistNewReferralGroup(values);
                  actions.resetForm();
                  setDisappearingMessage("New referral group created");
                }
                else {
                  // Referral group edited
                  if (values.referralGroupKey !== initialFormValues.referralGroupKey) {
                    // referralGroupKey changed, delete the old referral group key
                    await ReferralGroup.deleteReferralGroup(initialFormValues.referralGroupKey);
                    const referralGroup = await ReferralGroup.persistNewReferralGroup(values);
                    setInitialFormValues({ ...referralGroup.getEditableData() });
                    setDisappearingMessage("Referral group key changed, old referral group deleted, new one created");
                  }
                  else {
                    // Referral group updated
                    const referralGroup = new ReferralGroup(values);
                    await referralGroup.persist();
                    setInitialFormValues({ ...referralGroup.getEditableData() });
                    setDisappearingMessage("Referral group updated");
                  }
                }
                actions.setSubmitting(false);
              }}
              validateOnChange={false}
              validationSchema={FormSchema}
            >
              <FormikForm role="form" style={{ width: '100%' }}>
                <Container>
                  <Row>
                    <Col sm={12} md={6}>
                      <FormikTextField
                        name="referralGroupKey"
                        label="Referral group key"
                        desc="Key used to point to referral group on Surveysaurus landing page"
                      />
                      <FormikTextField
                        name="name"
                        label="Referral group name"
                        desc="Name of the referral group to display on  Surveysaurus landing page"
                      />
                      <FormikTextField
                        name="referralGroupEmail"
                        label="Referral group email"
                        desc="Contact email for the referral group"
                      />
                      <FormikTextField
                        name="referralID"
                        label="Referral group ID"
                        desc="The ID for the referral group"
                        placeHolder="xx"
                      />
                      <FormikTextField
                        name="logoURL"
                        label="Logo image URL"
                        desc="URL of the image to display on  Surveysaurus landing page"
                      />
                      <FormikSubmitButton label="Submit" />
                      {
                        message &&
                        <h6 className="mt-2 text-success">{message}</h6>
                      }
                    </Col>
                    <Col sm={12} md={6}>
                      <ImageDisplay />
                    </Col>
                  </Row>
                </Container>
              </FormikForm>
            </Formik>
            :
            error ?
              <h3 className="text-warning">{error}</h3>
              :
              <LoadingScreen />
        }
      </Row>
    </Container>
  )
}

export default ReferralGroupCreateEdit;

const FormSchema = Yup.object().shape({
  referralGroupKey: Yup.string()
    .min(2, 'Too short!')
    .max(15, 'Too long!')
    .matches(/^[a-zA-Z0-9_-]*$/, 'Only charaters and numbers allowed')
    .required('Required'),
  name: Yup.string()
    .min(2, 'Too Short!')
    .required('Required'),
  logoURL: Yup.string()
    .matches(urlRegExp, 'Enter valid url, or leave blank'),
  referralGroupEmail: Yup.string()
    .email('Must be valid email')
    .required('Required'),
  referralID: Yup.string()
    .min(2, "Must be 2 characters")
    .max(2, "Must be 2 characters")
    .matches(/^[0-9]*$/, 'Only numbers allowed')
    .required("Required")
})
