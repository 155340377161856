import React, { useEffect, useState } from "react";
import { Formik, Form, Field } from "formik";
import AdminNavbar from "../AdminNavbar";
import { firestore } from "../../firebase";

const FlagsmithLinkChange = () => {
  const [input, setInput] = useState({
    flagsmithLink: "",
  });

  const fetchData = () => {
    firestore
      .collection("tempest")
      .doc("flagsmith")
      .get()
      .then(doc => setInput(doc.data()))
  };

  const container = {
    borderStyle: "ridge",
    padding: "20px",
    marginTop: "40px",
  };

  const handleSubmit = () => {
    firestore
      .collection("tempest")
      .doc("flagsmith")
      .update({
        flagsmithLink: input.flagsmithLink,
      });
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setInput((vals) => {
      return { ...vals, [name]: value };
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <><AdminNavbar></AdminNavbar><Formik
      initialValues={{
        flagsmithLink: "",
      }}
      onSubmit={handleSubmit}
    >
      {({ }) => (
        <div className="container" style={container}>
          <div className="col-sm-12">
            <Form>
              <label>Flagsmith Link</label>
              <div className="form-group">
                <Field
                  className="form-control"
                  type="url"
                  placeholder="Flagsmith Link"
                  name="flagsmithLink"
                  value={input.flagsmithLink}
                  onChange={handleChange} />
              </div>

              <div className="form-group">
                <button
                  className="btn btn-primary"
                  type="submit"
                  style={{ fontSize: "16px" }}
                >
                  Save
                </button>
              </div>
            </Form>
          </div>
        </div>
      )}
    </Formik></>
  );
};

export default FlagsmithLinkChange;
