import React, {useState} from "react";
import {Link} from "react-router-dom";
import {useCollection, useDocument} from "react-firebase-hooks/firestore";
import LoadingScreen from "../../LoadingScreen";
import TabContainer from "react-bootstrap/TabContainer";
import "../surveyAdherence.css";
import {firestore} from "../../../firebase";
import AdminNavbar from "../../AdminNavbar";
import {useHistory} from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import NavItem from "react-bootstrap/NavItem";

import {Button, Col, Container, Form, FormControl, InputGroup, Jumbotron, Modal, Row, Table,} from "react-bootstrap";
import AReportTable004B from "./AReportTable004B";
import AReportTable004BCT from "./AReportTable004BCT";

import AsmallerReportTable004B from "./AsmallerReportTable004B";
import AsmallerReportTable004BCT from "./AsmallerReportTable004BCT";
// import AnotherReportsListSidebar004B from "../AnotherReportsListSidebar004B";
import AnotherReportsListSidebar004B from "./AnotherReportsListSidebar004B";
import AsmallerReportTable004BST from "./AsmallerReportTable004BST";

// import AreportsListSidebar from "../AreportsListSidebar";
    // const query = firestore.collection("analytics/reports/reportPages");
    // const [reportPages, reportPagesLoading, reportPagesError] = useCollection(query, {
        // snapshotListenOptions: {includeMetadataChanges: true},
    // });
// const query = firestore.collection("analytics").doc("kpi_summary")
const AreportsList004B = (props) => {
 return (<>
        <AdminNavbar/>
        <Jumbotron>
            <Container fluid={true}>
                <Row>
                    <Col>
                        <h1>004 KPIs Cohort 2</h1>
                    </Col>
                </Row>
            </Container>
        </Jumbotron>
        <AnotherReportsListSidebar004B/>
      
        <Container fluid={true} style={{backgroundColor: "white"}}>
            <div className="article" style={{paddingLeft: "50px"}}>
            <p className="TitleC">KPIs</p>
            {/* <AReportTable tableKey={"nps_kpis"}></AReportTable> */}
                </div>
        </Container>
        <Container fluid={true} style={{backgroundColor: "white"}}>
            <div className="article" style={{paddingLeft: "50px"}}>
            <p className="norms">80% of ACT participants engaging at least 5 days / week</p>
            <AsmallerReportTable004BCT  tableKey={"engagement_kpis004B"}></AsmallerReportTable004BCT>
                </div>
        </Container>
        {/* <Container fluid={true} style={{backgroundColor: "white"}}>
            <div className="article">
            <p className="norms">80% of ACT participants completing at least 4 lessons / week</p>
            <AReportTable004BCT tableKey={"lesson_kpis004"}></AReportTable004BCT>
                </div>
        </Container> */}
         <Container fluid={true} style={{backgroundColor: "white"}}>
            <div className="article" style={{paddingLeft: "50px"}}>
            <p className="norms">80% of ACT participants completing at least 4 sessions / week</p>
            <AsmallerReportTable004BCT tableKey={"session_kpis004B"}></AsmallerReportTable004BCT>
                </div>
        </Container> 
        <Container fluid={true} style={{backgroundColor: "white"}}>
            <div className="article" style={{paddingLeft: "50px"}}>
            <p className="norms">80% of ST participants completing at least 4 entries / week</p>
            <AsmallerReportTable004BST tableKey={"ST_app_kpis004B"}></AsmallerReportTable004BST>
                </div>
        </Container>
        <Container fluid={true} style={{backgroundColor: "white"}}>
            <div className="article" style={{paddingLeft: "50px"}}>
            <p className="norms">80% of all participants completing weekly PROs</p>
            <AsmallerReportTable004B  tableKey={"pro_adherence_kpis004B"}></AsmallerReportTable004B>
                </div>
        </Container>
        {/* <Container fluid={true} style={{backgroundColor: "white"}}>
            <div className="article">
            <AsmallerReportTable tableKey={"pro_adherence_kpis"}></AsmallerReportTable>
                </div>
        </Container> */}
 
      
</>);

};

export default AreportsList004B;

