import { useEffect, useState } from "react";
import { auth, firestore } from "../firebase";
import useRole from "../UseRole";

export const useAccessCodes = () => {
  const [submitting, setSubmitting] = useState(false);
  const [hideButton, setHideButton] = useState(false);
  const [codes, setCodes] = useState([] as any);
  const isAdmin = useRole("admin");
  const [existingCodes, setExistingCodes] = useState([] as any);

  
  // fetch the collection of accessCodes
  useEffect(() => {
    const unsubscribe = firestore
      .collection("accessCodes")
      .orderBy("createdAt", "desc")
      .onSnapshot({ includeMetadataChanges: true }, (collection) => {
        if (collection.docs.length > 0) {
          setExistingCodes(collection.docs.map((doc) => doc.data()));
        }
      });
    return unsubscribe;
  }, []);

  function generateAccessCodeWithLetters() {
    const letters = "ABCDEFGHIJKMNPQRSTUVWXYZ";
    const numbers = "23456789";
    const lettersLength = letters.length;
    const numbersLength = numbers.length;
    let accessCode = "";
    for (let i = 0; i < 3; i++) {
      accessCode += letters.charAt(Math.floor(Math.random() * lettersLength));
      accessCode += numbers.charAt(Math.floor(Math.random() * numbersLength));
    }
    return accessCode;
  }

  async function checkIfAccessCodeExists(accessCode: string) {
    const accessCodeRef = await firestore.collection("accessCodes");
    const accessCodeDoc = await accessCodeRef.doc(accessCode);
    const accessCodeDocSnapshot = await accessCodeDoc.get();
    return accessCodeDocSnapshot.exists;
  }

  function padLeadingZeros(num, size) {
    var s = num + "";
    while (s.length < size) s = "0" + s;
    return s;
  }
  
 async function getPatientIdNum() {
  const accessCodeRef =  firestore.collection("accessCodes");
  const accessCodeDoc =  accessCodeRef.orderBy("patientIdNum", 'desc').limit(1);
  const accessCodeDocSnapshot = await accessCodeDoc.get();
  const dataRef = accessCodeDocSnapshot.docs ? accessCodeDocSnapshot.docs[0] : null;
  var newNum = 1;
  if(!dataRef){
    return newNum
  }else{
  var largestNum = dataRef.data().patientIdNum;
  newNum = largestNum + 1
  return newNum;
  }
 }

  async function onSubmit(values: any) {
    if (!isAdmin)
      return alert(
        "You are not authorized to perform this action you must be an admin"
      );
    setSubmitting(true);
    const numberOfAccessCodes = values.count;
    // loop through the number of access codes to be generated
    const successfulCodes = [];
    for (let i = 0; i < numberOfAccessCodes; i++) {
      const accessCode = generateAccessCodeWithLetters();
      const accessCodeExists = await checkIfAccessCodeExists(accessCode);
      if (accessCodeExists) {
        // if the access key already exists, generate a new access key and check it again to see if it exists
        i--;
      } else {
        // if the access key doesn't exist, save the access key to the database
        try {
          var sitePrefix = '';
          if(values.site === 'swing care'){
            sitePrefix = '-SC-';
          }
          const idNumVal = await getPatientIdNum()
          const accessCodeRef = await firestore.collection("accessCodes");
          const accessCodeDoc = await accessCodeRef.doc(accessCode+ sitePrefix + padLeadingZeros(idNumVal, 4));
          const newDoc = {
            id: accessCode + sitePrefix + padLeadingZeros(idNumVal, 4),
            accessCode: accessCode + sitePrefix + padLeadingZeros(idNumVal, 4),
            active: values.active,
            expires: Number(values.expiration),
            createdAt: new Date(),
            createdBy: auth.currentUser?.email,
            program: values.program,
            site: values.site,
            patientIdNum: idNumVal,
          };
          await accessCodeDoc.set(newDoc);
          successfulCodes.push(newDoc);
        } catch (error) {
          console.log(error);
        }
      }
    }
    setSubmitting(false);
    setCodes(successfulCodes);
  }

  return {
    generateAccessCodeWithLetters,
    checkIfAccessCodeExists,
    onSubmit,
    submitting,
    hideButton,
    setHideButton,
    codes,
    setCodes,
    isAdmin,
    existingCodes,
  };
};
