import React, { useEffect } from "react";
// import { useAccessCodes } from "../../../hooks/useAccessCodes";
import AdminNavbar from "../../AdminNavbar";
import usePagination from "../../../hooks/usePagination";
import "./AccessCodes.css";
import { useHistory, useLocation } from "react-router-dom";
import { FormControl, InputGroup } from "react-bootstrap";

export default function ManageAccessCodes() {
  let { search } = useLocation();
  const pageNumber = new URLSearchParams(search).get("page");
  const searchTerms = [
    "id",
    "createdBy",
    "expires",
    "program",
    "patientIdNum",
    "active",
  ];
  let history = useHistory();
  const {
    visibleDocs,
    page,
    PaginationButtons,
    ResetButton,
    SearchButton,
    filter,
    setFilter,
  } = usePagination(
    "accessCodes",
    25,
    "createdAt",
    "desc",
    searchTerms,
    Number(pageNumber)
  );

  useEffect(() => {
    //@ts-ignore
    if (page.current !== pageNumber) {
      history.replace({
        pathname: "/manageAccessCodes",
        search: "?page=" + page.current,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, page.current, pageNumber]);

  return (
    <div>
      <AdminNavbar />
      <div className="accessCodesContainer">
        <h1 className="accessCodeHeader">Manage Access Codes</h1>
        <div style={{ display: "flex", marginBottom: 5 }}>
          <InputGroup className="">
            <FormControl
              id="search"
              placeholder="Search"
              value={filter}
              onChange={(e) => {
                setFilter(e.target.value.trim());
              }}
            />
          </InputGroup>
          <SearchButton />
          <ResetButton />
        </div>
        <table className="table">
          <thead>
            <tr>
              <th>ID</th>
              <th>Created At</th>
              <th>Created By</th>
              <th>Expiration</th>
              <th>Program</th>
              <th>Active</th>
              <th>Site</th>
              <th>PatientIdNum</th>
            </tr>
          </thead>
          <tbody>
            {visibleDocs.map((accessCode) => (
              <tr key={accessCode?.id}>
                <td>{accessCode?.id}</td>
                <td>
                  {accessCode?.createdAt?.toDate().toLocaleString("en-GB", {
                    month: "long",
                    day: "numeric",
                    year: "numeric",
                  })}
                </td>
                <td>{accessCode.createdBy}</td>
                <td>{accessCode.expires}</td>
                <td>{accessCode.program}</td>
                <td>{accessCode.active === true ? "true" : "false"}</td>
                <td>{accessCode.site}</td>
                <td>{accessCode.patientIdNum}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <PaginationButtons />
      </div>
    </div>
  );
}
