import React, { useState } from "react";
import { useCollection } from "react-firebase-hooks/firestore";
import LoadingScreen from "../LoadingScreen";
import { firestore } from "../../firebase";
import { useHistory } from "react-router-dom";
import { Col, Container, Row, Table } from "react-bootstrap";
import JSONTree from "react-json-tree";

const NotificationListRow = (props) => {
  const { docRef } = props;

  let data = docRef.data();

  return (
    <tr key={data.subject}>
      <td>{data.name}</td>

      <td>{data.title}</td>
      <td>{data.body}</td>
      <td>{data.scheduledFor?.toDate().toLocaleString()}</td>
      <td>{data.status}</td>
      <td>
        <JSONTree data={data} shouldExpandNode={() => false} />
      </td>
    </tr>
  );
};

const NotificationsList = (props) => {
  const [filter, setFilter] = useState("");
  const { deviceID, uid } = props;

  let history = useHistory();
  let query = deviceID
    ? firestore
        .collection("notifications")
        .where("deviceID", "==", deviceID)
        .orderBy("scheduledFor", "desc")
    : uid
    ? firestore
        .collection("notifications")
        .where("uid", "==", uid)
        .orderBy("scheduledFor", "desc")
    : firestore
        .collection("notifications")
        .orderBy("scheduledFor", "desc")
        .limit(500);
  const [
    registrations,
    registrationsLoading,
    registrationError,
  ] = useCollection(query, {
    snapshotListenOptions: { includeMetadataChanges: true },
  });

  const getTableRow = (regRef) => {
    let reg = regRef.data();

    return (
      <tr key={reg.subject}>
        <td>{reg.subjectID}</td>

        <td>{reg.title}</td>
        <td>{reg.body}</td>
        <td>{reg.scheduledFor?.toDate().toLocaleString()}</td>
        <td>{reg.status}</td>
        <td>
          <JSONTree data={reg} shouldExpandNode={() => false} />
        </td>
      </tr>
    );
  };

  return (
    <>
      <Container fluid={true} style={{ backgroundColor: "white" }}>
        <Row>
          {registrationError && (
            <>
              <strong>Error: {JSON.stringify(registrationError)}</strong>
              <br></br>
            </>
          )}
          {registrationsLoading && (
            <>
              <strong>
                Subjects Loading: {JSON.stringify(registrationError)}
              </strong>
              <br></br>
              <LoadingScreen></LoadingScreen>
            </>
          )}
          {registrations && (
            <Col xs={12}>
              <Table>
                <thead key="systemEventsTable">
                  <tr>
                    <th>subjectID</th>

                    <th>Body</th>
                    <th>Scheduled</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {registrations.docs.map((u) => (
                    <NotificationListRow docRef={u}></NotificationListRow>
                  ))}
                </tbody>
              </Table>
            </Col>
          )}
        </Row>
      </Container>
    </>
  );
};

export default NotificationsList;
