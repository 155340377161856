import { JournalTemplate } from '@swing-therapeutics/swingcore/dist/models/journals/JournalTemplate';
import { PacingJournal } from '@swing-therapeutics/swingcore/dist/models/journals/PacingJournal';
import { useFormik } from 'formik';
import { cloneDeep } from 'lodash';
import React from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { PhaseFormFields } from './PhaseFormWrappers';

interface PhaseHeaderProps {
  phaseKey: string;
  journal: JournalTemplate;
}

export const AccordionPhaseCard = ({ phaseKey, journal }: PhaseHeaderProps) => {
  const phase = journal.phases[phaseKey];
  const isPacingJournal = PacingJournal.isPacingJournalID(journal.id);
  const isDartboard = journal.id === 'valuesDartboard'

  const initialValues = cloneDeep(phase);

  const {values, handleChange, errors, touched, setFieldValue, dirty, submitForm} = useFormik({
    initialValues,
    onSubmit: (async (results) => {
      const newJournal = new JournalTemplate(journal)
      Object.keys(results).map((key: string) => {
        newJournal.phases[phaseKey][key] = results[key];
      })
      try {
        await newJournal.persist();
        window.alert('Phase saved successfully!')
      } catch (error) {
        window.alert('An error occurred when saving this phase. Check the developer console for details.');
        console.error(error);
      }
    }),
  })

  return (
    <Card className="m-3">
      <Card.Title className="ml-3 mt-3 mb-0">Phase Details</Card.Title>
      <Card.Body>
        <PhaseFormFields
          phaseKey={phaseKey as any}
          values={values}
          errors={errors}
          touched={touched}
          handleChange={handleChange}
          setFieldValue={setFieldValue}
          isPacingJournal={isPacingJournal}
          isDartboard={isDartboard}
        />
        <Row>
          <Col>
            <Button 
              variant={dirty ? 'primary' : 'secondary'}
              disabled={!dirty}
              onClick={submitForm}
            >
              Save
            </Button>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  )
}

