// All available calendly calls, ultimately this would be stored in the database, as there calls are handled on the backend
interface AvailableCall {
  // The name of the calendly event
  name: string;
  // The link to the Calendly call, in Calendly click 'view live page' for an event and copy the link
  calendlyURL: string;
}

// Unique calls
export const terminationCall: AvailableCall = { name: 'REACT-FM Termination Followup Call', calendlyURL: 'https://calendly.com/d/cf6-7cw-7jt/react-fm-termination-followup-call' };
export const prosperExtTerminationCall: AvailableCall = { name: 'REACT-FM EXT Termination Followup Call', calendlyURL: 'https://calendly.com/d/2gx-yr3-7j2/prosper-fm-ext-termination-followup-call' };
export const extTerminationCall: AvailableCall = { name: 'PROSPER-FM EXT Termination Followup Call', calendlyURL: 'https://calendly.com/d/cjp-cxh-jcz/react-fm-ext-termination-followup-call' };
export const consentCall: AvailableCall = { name: 'Screening call- Informed Consent', calendlyURL: 'https://calendly.com/d/m24v-4n9j/screening-call-informed-consent' };
export const extConsentCall: AvailableCall = { name: 'REACT-FM EXT Consent Call', calendlyURL: 'https://calendly.com/d/chg-25c-7xv/react-fm-ext-consent-call' };
export const prosperExtConsentCall: AvailableCall = { name: 'PROSPER-FM EXT Consent Call', calendlyURL: 'https://calendly.com/d/dtx-fjh-vhp/prosper-fm-ext-informed-consent-call' };

export const mainCalls: AvailableCall[] = [
  { ...terminationCall },
  { ...prosperExtTerminationCall },
  { ...extTerminationCall },
  { ...consentCall },
  { ...extConsentCall },
  { ...prosperExtConsentCall }
];

// Optional calls to setup for a trial
export const optionalCalls: AvailableCall[] = [
  { name: 'REACT-FM EXT Product Feedback Call', calendlyURL: 'https://calendly.com/d/cdx-2ty-f7b/react-fm-ext-product-feedback-call' },
  { name: 'REACT-FM EXT End of Study Call', calendlyURL: 'https://calendly.com/d/cdy-xrv-vch/react-fm-ext-end-of-study-call' },
  { name: 'PROSPER-FM EXT Product Feedback Call', calendlyURL: 'https://calendly.com/d/z5d-7bt-dqg/prosper-fm-ext-product-feedback-call' },
  { name: 'PROSPER-FM EXT End of Study Call', calendlyURL: 'https://calendly.com/d/zz7-pcw-q2h/prosper-fm-ext-end-of-study-call' },
];
