import React, {useEffect, useState} from "react";
import {Button, Col, Row, Form, Container, Jumbotron,} from "react-bootstrap";
import AdminNavbar from "../AdminNavbar";
import NotificationType from "./NotificationType";
import {firestore} from "../../firebase";


const MessageView = (props) => {
    let {message} = props;

    const [body, setBody] = useState(message.body);


    return (<Row>
            <Col>
                <Row>
                    <Col xs={"auto"}><strong>Title: </strong></Col> <Col><Form.Control
                    type="text" placeholder="Enter title"

                    value={message.title}
                    onChange={(e) => {
                        message.title = e.target.value

                    }}/></Col>
                </Row>
                <Row>
                    <Col xs={"auto"}><strong>Body: </strong></Col> <Col><Form.Control
                    as="textarea" placeholder="Enter title"
                    value={message.body}
                    rows={2}
                    onChange={(e) => {
                        message.body = e.target.value
                        setBody(message.body);
                    }}/></Col>
                </Row>

            </Col>
            <Col xs={1}>
                <Button
                    size={"sm"}
                    variant="dark"
                    onClick={() => {
                        console.log('message is', message);
                    }}
                >
                    Edit
                </Button>
            </Col>

        </Row>

    )

}


const NotificationTypeCreator = (props) => {
    const [notifType, setNotifType] = useState();
    const [messages, setMessages] = useState([]);

    useEffect(() => {
        const n = new NotificationType();
        setMessages(n.messages);
        console.log("made a new notification type", n);
        n.persist().then(firestore.collection('tempest/notifications/types').doc(n.id).onSnapshot((snap) => {

            if (!snap.exists) {
                console.log('unable to find my notification type', snap);
            }
            let notification = NotificationType.fromFirestore(snap);
            setMessages(notification.messages);
            setNotifType(notification);
        }));


    }, [])

    const getMessageView = (message) => {


        return (<Row>
                <Col>
                    <Row>
                        <Col xs={"auto"}><strong>Title: </strong></Col> <Col><Form.Control
                        type="text" placeholder="Enter title"

                        value={message.title}
                        onChange={(e) => {
                            message.title = e.target.value;
                            notifType.persist()

                        }}/></Col>
                    </Row>
                    <Row>
                        <Col xs={"auto"}><strong>Body: </strong></Col> <Col><Form.Control
                        as="textarea" placeholder="Enter title"
                        value={message.body}
                        rows={2}
                        onChange={(e) => {
                            message.body = e.target.value;
                            notifType.persist();

                        }}/></Col>
                    </Row>

                </Col>
                <Col xs={1}>
                    <Button
                        size={"sm"}
                        variant="dark"
                        onClick={() => {
                            console.log('message is', message);
                        }}
                    >
                        Edit
                    </Button>
                </Col>

            </Row>

        )

    }


    return (<Container fluid={true} style={{backgroundColor: "white"}}>
            <Row>
                (<Col xs={12}>
                <AdminNavbar></AdminNavbar>
                {notifType?.id}
                <Jumbotron>
                    <Container fluid={true}>
                        <Row>
                            <h1>Notification Type</h1>
                        </Row>
                    </Container>
                </Jumbotron>
                <Container fluid={true} style={{backgroundColor: "white"}}>

                    <h2>
                        Messages
                        <Button
                            variant="primary"
                            onClick={() => {
                                let message = {};
                                message.title = "title";
                                message.body = "body";

                                if (notifType && notifType.messages) {
                                    notifType.messages.push({...message});
                                    notifType.persist();
                                    console.log('messages', notifType);
                                    setNotifType(notifType);
                                } else {
                                    console.log('coundnt add it', notifType);
                                }

                            }}
                        >
                            New Message
                        </Button>
                    </h2>
                    {messages.map((m) => getMessageView(m))}
                </Container>
            </Col>
            </Row>
        </Container>

    )
}


export default NotificationTypeCreator;