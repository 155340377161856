import { getJournalsTemplates } from "@swing-therapeutics/swingcore/dist/utils/queries";
import { JournalTemplate } from "@swing-therapeutics/swingcore/dist/models/journals/JournalTemplate";
import React, { useEffect, useState } from "react";
import {
  Accordion,
  Button,
  Card,
  Col,
  Container,
  Row,
} from "react-bootstrap";
import { useHistory, useRouteMatch } from "react-router";
import LoadingScreen from "../../LoadingScreen";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import "./journalScreen.css";
import { isNil } from "lodash";

import { AccordionPhaseCard } from "./AccordionPhaseCard";
import { useDocumentClassData } from "../../../utils/Hooks";
import { AccordionQuestionCard } from "./AccordionQuestionCard";
import { PhaseKeyType } from "./PhaseFormWrappers";
import { AddPhaseModal } from "./AddPhaseModal";
import { PacingJournalPhase } from "@swing-therapeutics/swingcore/dist/models/journals/PacingJournal";
import { JournalPhase } from "@swing-therapeutics/swingcore/dist/models/journals/JournalInterfaces";

interface JournalQuestionURL {
  programID?: string;
  journalID?: string;
}

export const JournalQuestionScreen = () => {
  const urlData = useRouteMatch("/programs/:programID/journals/:journalID")
    ?.params as JournalQuestionURL;
  const history = useHistory();
  const [error, setError] = useState<string>();
  const [showAddPhase, setShowAddPhase] = useState(false);
  const [journal, journalLoading, journalError] = useDocumentClassData(JournalTemplate, getJournalsTemplates(urlData.programID).doc(urlData.journalID));
  const orderedPhases = getOrderedPhases(journal?.phases);

  useEffect(() => {
    if (journalError) {
      setError("Error loading journal");
      console.error(journalError);
    } else {
      setError(null);
    }
  }, [journalError]);

  if (journalLoading) {
    return (
      <Container>
        <LoadingScreen />
      </Container>
    );
  }

  if (error) {
    return (
      <Container>
        <h1>{error}</h1>
      </Container>
    );
  }

  return (
    <Container>
      <Row>
        <Col>
          <div
            className="journal-back-button"
            onClick={() => {
              history.goBack();
            }}
          >
            <FontAwesomeIcon icon={faArrowLeft}></FontAwesomeIcon> Go Back
          </div>
        </Col>
      </Row>
      <Row className="mb-1">
        <Col>
          <h2>Editing Journal</h2>
        </Col>
      </Row>
      <Row>
        {getJournalDetail("Name", journal.displayName)}
        {getJournalDetail("ID", journal.id)}
        {getJournalDetail(
          "No Journal Left Behind",
          (journal as any).noJournalLeftBehind?.toString() ?? "false"
        )}
      </Row>

      <Row className="mb-1 mt-3">
        <Col>
          <h2>Phases</h2>
        </Col>
        <Col className="d-flex align-items-center justify-content-end">
          <Button onClick={() => setShowAddPhase(true)}>Edit Phases</Button>
        </Col>
      </Row>
      <Row>
        <Col>
          <Accordion defaultActiveKey={'0'}>
            {orderedPhases.map((phase, phaseIndex) => (
              <Card key={`${journal.id}=${phase}`}>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey={phaseIndex.toString()}
                    className="text-capitalize"
                  >
                    {phase}
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey={phaseIndex.toString()} style={{ backgroundColor: 'rgba(0,0,0,.01)' }}>
                  {getAccordionCards(phase as PhaseKeyType, journal)}
                </Accordion.Collapse>
              </Card>
            ))}
          </Accordion>
        </Col>
      </Row>
      <AddPhaseModal
        show={showAddPhase}
        close={() => setShowAddPhase(false)}
        journal={journal}
      />
    </Container>
  );
};

const getAccordionCards = (phase: PhaseKeyType, journal: JournalTemplate) => {
  switch (phase) {
    case JournalPhase.Creation:
    case JournalPhase.Conversion:
    case PacingJournalPhase.CheckIn:
    case PacingJournalPhase.Activity:
      return (
        <>
          <AccordionPhaseCard phaseKey={phase} journal={journal} />
          <AccordionQuestionCard phase={phase} journal={journal} />
        </>
      );
    case JournalPhase.Confirmation:
    case JournalPhase.Completion:
      return (
        <>
          <AccordionPhaseCard phaseKey={phase} journal={journal} />
        </>
      )
    default:
      return (
        <p>coming soon...</p>
      )
  }
}

const getOrderedPhases = (phases: any): string[] => {
  return phases ?
    Object.entries(phases)
      .sort((a: any, b: any) => {
        const [, phaseA] = a;
        const [, phaseB] = b;

        //Completion does not have order, put it at the end
        if (isNil(phaseA.order)) return 1;
        if (isNil(phaseB.order)) return -1;

        return phaseA.order - phaseB.order
      })
      .map(entry => entry[0])
    :
    []
}

const getJournalDetail = (heading: string, subheading: string) => (
  <Col xs="auto" className="border-left border-right border-light">
    <h4>{heading}</h4>
    <h5>{subheading}</h5>
  </Col>
);
