import { firestore } from 'firebase';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Button, Col, Container, Row, Table } from "react-bootstrap"
import VoiceActorModal from './VoiceActorModal';
import type { IVoiceActorMetaData } from '@swing-therapeutics/swingcore/dist/models/voice_actors/types';

interface Props {
  programKey: string;
}

interface State {
  editActor: firestore.QueryDocumentSnapshot | 'new';
  showModal: boolean;
}

const defaultState: State = { editActor: 'new', showModal: false };

const VoiceActorList: React.FC<Props> = ({ programKey }) => {
  const [modalState, setModalState] = useState<State>({ ...defaultState });
  const [voiceActors, setVoiceActors] = useState<firestore.QueryDocumentSnapshot[] | null>(null);
  const unSub = useRef(() => { });

  const fetchSetVoiceActors = useCallback(async () => {
    unSub.current();
    unSub.current = firestore().collection(`programs/${programKey}/voiceActors`).onSnapshot((snap) => {
      setVoiceActors(snap.docs);
    });
  }, [programKey])

  useEffect(() => {
    fetchSetVoiceActors();
    return unSub.current;
  }, [fetchSetVoiceActors])

  return (
    <Container style={{ gap: '30px', display: 'flex', flexDirection: 'column' }}>
      <h4>Voice actors</h4>
      <Row >
        <Col style={{ justifyContent: 'flex-end', display: 'flex' }}>
          <Button
            variant="outline-primary"
            onClick={() => setModalState({ showModal: true, editActor: 'new' })}
          >
            Add new voice actor
          </Button>
        </Col>
      </Row>
      <Row>
        <Col>
          <Table className="table-striped table-center-rows">
            <thead>
              <tr>
                <th>ID</th>
                <th>Name</th>
                <th>Default</th>
                <th>Description</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {voiceActors?.map((voiceActorSnap) => (
                <VoiceActorRow
                  onEditClick={() => setModalState({ editActor: voiceActorSnap, showModal: true })}
                  key={voiceActorSnap.id}
                  snap={voiceActorSnap}
                />
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>
      <VoiceActorModal show={modalState.showModal} editActor={modalState.editActor} onHide={() => setModalState({ ...defaultState })} programKey={programKey} />
    </Container>
  )
}

export default VoiceActorList;

interface RowProps {
  snap: firestore.QueryDocumentSnapshot;
  onEditClick: () => void;
}

const VoiceActorRow: React.FC<RowProps> = ({ snap, onEditClick }) => {
  const data = useMemo(() => {
    return snap.data() as IVoiceActorMetaData;
  }, [snap])

  return (
    <tr>
      <td>{data.id}</td>
      <td>{data.name}</td>
      <td>{data.defaultVoiceActor ? '✅' : ''}</td>
      <td>{data.desc}</td>
      <td>
        <Button onClick={onEditClick}>
          Edit
        </Button>
      </td>
    </tr>
  )
}

