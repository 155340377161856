import { JournalTemplate } from '@swing-therapeutics/swingcore/dist/models/journals/JournalTemplate';
import { useFormik } from 'formik';
import { isNil } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Modal, Row, Table } from 'react-bootstrap';

interface AddPhaseModalProps {
  show: boolean;
  close: () => void;
  journal: JournalTemplate;
}

const DefaultPhaseOrder = [
  'creation',
  'activity',
  'checkIn',
  'confirmation',
  'conversion',
]

export const AddPhaseModal = ({ show, close, journal }: AddPhaseModalProps) => {
  const [selectedPhases, setCurrentPhases] = useState(Object.keys(journal.phases));
  const supportedPhases = ['creation', 'confirmation', 'conversion', 'completion']

  const saveChanges = async () => {
    if (window.confirm('Are you sure you want to save changes? Removed phases will be lost')) {
      const newJournal = new JournalTemplate(journal);
      const phases = newJournal.phases;

      //Add missing phases
      selectedPhases.forEach(phase => {
        if (isNil(phases?.[phase])) {
          phases[phase] = {};
        }
      })

      //Remove phases
      Object.keys(phases).forEach(key => {
        if (!selectedPhases.includes(key)) {
          delete phases[key];
        }
      })

      //Reorder phases
      let order = 1;
      DefaultPhaseOrder.forEach(key => {
        if (key !== 'completion' && phases[key]) {
          phases[key].order = order;
          order++;
        }
      })

      newJournal.phases = phases;
      await newJournal.persist();
      close();
      window.alert('Phases Updated!')
    }
  }

  const editPhases = (phase: string, action: 'add' | 'remove') => {
    if (action === 'add' && !selectedPhases.includes(phase)) {
      setCurrentPhases([...selectedPhases, phase]);
    } else if (action === 'remove' && selectedPhases.includes(phase)) {
      setCurrentPhases(selectedPhases.filter(key => key !== phase))
    } 
  }

  return (
    <Modal show={show} onHide={close}>
      <Modal.Header closeButton>
        <Modal.Title>Edit {journal.displayName} Phases</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Row>
        <div style={{ display: 'flex', flex: 1, flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
          {supportedPhases.map(phase => {
            const isSelected = selectedPhases.includes(phase);
            const action = isSelected ? 'remove' : 'add'
            const buttonSymbol = isSelected ? '✓ ' : '𐄂 ';
            const variant = isSelected ? 'success' : 'secondary';
            return (
              <Button 
                key={phase}
                onClick={() => editPhases(phase, action)}
                className="m-1 text-capitalize" 
                style={{width: 300}} 
                variant={variant}>
                {buttonSymbol} {phase}
              </Button>
            )
          })}
        </div>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={close}>Close</Button>
        <Button variant="primary" onClick={saveChanges}>Save</Button>
      </Modal.Footer>
    </Modal>
  )
}
