import React from "react";
import { FormikErrors, useFormik } from "formik";
import {
  Button,
  Col,
  Form,
  Row,
  Dropdown,
} from "react-bootstrap";
import {
  ConfirmationActivity,
} from "../../../Model/Playlist";
import Icon, {
  AwarenessIcon,
  DartboardIcon,
  IconType,
  JournalIcon,
  LessonIcon,
} from "../../../icons/Icon";
import { faPlusCircle
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import * as Yup from 'yup';

interface ActivityProps {
  icon?: string;
  name?: string;
}

export default (submitActivity: Function) => {
  const formik = useFormik({
    initialValues: {
      icon: undefined,
      name: undefined,
    },
    validate: (values) => {
      const errors: FormikErrors<ActivityProps> = {};
      if (values.name === undefined || values.name.length === 0 ) {
        errors.name = "Name required"
      }
      if (values.icon === undefined) {
        errors.icon = "Icon required"
      }
      return errors;
    },
    initialErrors: {name: "Name required"},
    onSubmit: () => {
      const activity: ConfirmationActivity = {
        description: formik.values.name,
        icon: formik.values.icon,
      };
      submitActivity(activity);
      formik.setFieldValue('icon', undefined)
      formik.setFieldValue('name', '')
    },
  });

  return (
    <Col>
      <Form.Group as={Row} xs={12} controlId="description" style={{justifyContent: 'center'}}>
        <Dropdown style={{ width: 30, marginRight: 20 }}>
          <Dropdown.Toggle variant="activity-selector">
            {Icon(formik.values.icon)}
          </Dropdown.Toggle>
          <Dropdown.Menu >
            <Dropdown.Item
            style={{width: 30}}
              onSelect={() => formik.setFieldValue("icon", IconType.lesson)}
            >
              {LessonIcon}
            </Dropdown.Item>
            <Dropdown.Item
              onSelect={() => formik.setFieldValue("icon", IconType.journal)}
            >
              {JournalIcon}
            </Dropdown.Item>
            <Dropdown.Item
              onSelect={() => formik.setFieldValue("icon", IconType.awareness)}
            >
              {AwarenessIcon}
            </Dropdown.Item>
            <Dropdown.Item
              style={{ color: "red" }}
              onSelect={() => formik.setFieldValue("icon", IconType.dartboard)}
            >
              {DartboardIcon}
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        <Col>
          <Form.Control
            style={{ width: "100%" }}
            name="name"
            value={formik.values.name}
            onChange={formik.handleChange}
            placeholder="Confirmation Name"
          />
        </Col>
        <Button
          variant="activity-add"
          style={{marginLeft: 10}}
          onClick={() => formik.submitForm()}
        >
          <FontAwesomeIcon 
            icon={faPlusCircle} 
            color={formik.isValid ? "#3CE09F" : "#81AB9A"} 
            style={{height: 20, width: 20}}
          />
        </Button>
      </Form.Group>
    </Col>
  );
};
