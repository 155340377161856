import React from "react";
import "./surveyAdherence.css";
import AdminNavbar from "../AdminNavbar";

import { Col, Container, Jumbotron, Row } from "react-bootstrap";
import PROkeyTable from "./PROkeyTable";
import PROkeyTableXL from "./PROkeyTableXL";

// const query = firestore.collection("analytics/reports/reportPages");
// const [reportPages, reportPagesLoading, reportPagesError] = useCollection(query, {
// snapshotListenOptions: {includeMetadataChanges: true},
// });
// const query = firestore.collection("analytics").doc("kpi_summary")
const PROkey = (props) => {
  return (
    <>
      <AdminNavbar />
      <Jumbotron>
        <Container fluid={true}>
          <Row>
            <Col>
              <h1>PRO Reference</h1>
            </Col>
          </Row>
        </Container>
      </Jumbotron>

      <Container fluid={true} style={{ backgroundColor: "white" }}>
        <div className="article">
          <div className="TitleC">PROs</div>
          <PROkeyTable tableKey={"pro_score_key"}></PROkeyTable>
        </div>
      </Container>
      <Container fluid={true} style={{ backgroundColor: "white" }}>
        <div className="article">
          <div className="TitleC">PRO Questions</div>
          <PROkeyTableXL fluid={true}  tableKey={"pro_question_key"}></PROkeyTableXL>
        </div>
      </Container>


    </>
  );
};

export default PROkey;
// FFF9F3