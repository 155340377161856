import React, { useState } from "react";
import { ChapterEditor } from "./ChapterEditor";
import { ChapterList } from "./ChapterList";
import { Row } from "react-bootstrap";
import { useCollectionClassData } from "../../../utils/Hooks";
import { Chapter } from "@swing-therapeutics/swingcore/dist/models/content/Chapter";
import { getChaptersTemplates } from "@swing-therapeutics/swingcore/dist/utils/queries";

const ChapterViewer = (props: any) => {
  const { programKey } = props;
  const [activeChapter, setActiveChapter] = useState();
  const [show, setShow] = useState(false);
  const [chapters, loading] = useCollectionClassData(Chapter, getChaptersTemplates(programKey))

  const closeEditor = () => {
    setActiveChapter(undefined);
    setShow(false);
  };

  if (loading || chapters.length === 0) {
    return <div></div>;
  }

  return (
    <>
      {activeChapter && (
        <ChapterEditor
          programKey={programKey}
          activeChapter={activeChapter}
          show={show}
          close={closeEditor}
        />
      )}
      <Row className={"chapter-viewer"}>
        <ChapterList
          programKey={programKey}
          setShow={setShow}
          setActiveChapter={setActiveChapter}
          chapters={chapters}
        />
      </Row>
    </>
  );
};

export { ChapterViewer };
