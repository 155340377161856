import React, {Component, createContext} from "react";
import {auth, firestore} from "../firebase";

export const UserContext = createContext({user: null});

class UserProvider extends Component {
    authUnsubscribeFn;
    state = {
        user: null,
    };

    componentDidMount = async () => {
        this.authUnsubscribeFn = auth.onAuthStateChanged(async (userAuth) => {
            const user = userAuth;
            if (user && user.email) {
                let roleMember = await firestore.collection('roles').doc('tempest').collection('members').doc(user.email).get();
                if (roleMember.exists) {
                    this.setState({user});
                } else {
                    auth.signOut();

                }


            } else {
                auth.signOut();
            }
        });
    };

    componentWillUnmount() {
        if (this.authUnsubscribeFn) {
            this.authUnsubscribeFn();
        }
    }

    render() {
        const {user} = this.state;

        return (<UserContext.Provider value={user}>
                {this.props.children}
            </UserContext.Provider>);
    }
}

export default UserProvider;
