import { Button, Col, Form, Row } from "react-bootstrap";
import React, { useState } from "react";
import { useFormik } from "formik";
import Chance from "chance";
import { firestore } from "../../firebase";
import { Hypnotify } from "../../Model/Hypnotify";

const RegistrationForm = (props) => {
  const chance = new Chance();
  let { FCMToken, deviceID, uid } = props;

  const [regStatus, setRegStatus] = useState("");

  const jsonTheme = {
    scheme: "chalk",
    author: "chris kempson (http://chriskempson.com)",
    base00: "transparent",
    base01: "#333333",
    base02: "#555555",
    base03: "#777777",
    base04: "#999999",
    base05: "#bbbbbb",
    base06: "#dddddd",
    base07: "#ffffff",
    base08: "#ff4136",
    base09: "#ff851b",
    base0A: "#ffdc00",
    base0B: "#2ecc40",
    base0C: "#7fdbff",
    base0D: "#0074d9",
    base0E: "#b10dc9",
    base0F: "#85144b",
  };

  const formik = useFormik({
    initialValues: {
      title: "",
      scheduledFor: new Date(),
      status: "not Sent",
      FCMTokenID: "",
      body: "",
    },

    onSubmit: async (values, { setSubmitting }) => {
      let notification = {
        title: values.title,
        FCMToken: FCMToken,
        scheduledFor: values.scheduledFor,
        body: values.body,
        status: "scheduled",
      };
      if (uid) {
        notification.uid = uid;
        console.log("hypno");
        await Hypnotify.sendToUser(values.title, values.body, uid, true);
        return;
      }
      if (deviceID) {
        notification.deviceID = deviceID;
      }
      await firestore.collection("notifications").doc().set(notification);
    },
  });
  if (!FCMToken) {
    return <></>;
  }
  return (
    <Row>
      <Col xs={12}>
        {regStatus === "" && (
          <Form onSubmit={formik.handleSubmit}>
            <br></br>

            <Form.Row>
              <Form.Group as={Col} xs={12} controlId="title">
                <Form.Label>Title</Form.Label>
                <Form.Control
                  type="text"
                  name="title"
                  value={formik.values.title}
                  onChange={formik.handleChange}
                  placeholder="Enter title"
                />
              </Form.Group>

              <Form.Group as={Col} xs={12} md={12} controlId="body">
                <Form.Label>Body</Form.Label>
                <Form.Control
                  type="text"
                  name="body"
                  value={formik.values.body}
                  onChange={formik.handleChange}
                  placeholder="Body"
                />
              </Form.Group>
            </Form.Row>

            <Button
              disabled={formik.isSubmitting}
              variant="primary"
              type="submit"
              onClick={() => {
                formik.handleSubmit();
              }}
            >
              Submit
            </Button>
          </Form>
        )}
        {regStatus === "registering" && (
          <Col>
            <h1>Registering</h1>
          </Col>
        )}
      </Col>
    </Row>
  );
};

export default RegistrationForm;
