import {
  CombinedQuestionType,
  getMaxField,
  getMinField,
  matchType,
} from "./JournalQuestionFormWrappers";
import * as Yup from "yup";

const BaseQuestionValidator = {
  key: Yup.string()
    .required("key required")
    .max(50, "key cannot be longer than 50 characters"),
  type: Yup.string().required("type required"),
  order: Yup.number().required("order required"),
  screenText: {
    navigationTitle: Yup.string()
      .required("navigation title required")
      .max(50, "navigation title cannot be longer than 50 characters"),
    heading: Yup.string()
      .required("heading required")
      .max(50, "heading cannot be longer than 50 characters"),
    PrevButtonText: Yup.string()
      .required("previous button text required")
      .max(20, "previous button text cannot be longer than 20 characters"),
    NextButtonText: Yup.string()
      .required("next button text required")
      .max(20, "next button text cannot be longer than 20 characters"),
  },
  hasPinkEye: Yup.boolean().default(false),
  showNotificationPermissionAlert: Yup.boolean().default(false),
  pinkEye: Yup.object().when("hasPinkEye", {
    is: true,
    then: Yup.object().shape({
      contentTitle: Yup.string().required(
        "Title is required if pink 'i' is turned on"
      ),
      skipFirstTime: Yup.boolean().default(false),
      content: Yup.string()
        .required("Content is required if pink 'i' is turned on")
        //react-rte places 2 invisible characters when input is empty. Safe to assume content should be longer than 3 char anyway
        .min(3, "Content is required if pink 'i' is turned on"),
    }),
    otherwise: Yup.object().optional(),
  }),
};

const LengthValidators = (type: CombinedQuestionType) => {
  return {
    [getMinField(type)]: Yup.number()
      .required("min value is required")
      .min(0, "min value must be between 0-1000")
      .max(1000, "min value must be between 0-1000")
      .test("lteMax", "min must be <= max", function (value) {
        if (!Number.isInteger(this.resolve(getMaxField(type)))) {
          return true;
        }
        return value <= this.resolve(getMaxField(type));
      }),
    [getMaxField(type)]: Yup.number()
      .required("max value is required")
      .min(0, "max value must be between 0-1000")
      .max(1000, "max value must be between 0-1000")
      .test("gteMin", "max must be >= min", function (value) {
        if (!Number.isInteger(this.resolve(getMinField(type)))) {
          return true;
        }
        return value >= this.resolve(getMinField(type));
      }),
    ...((matchType(type, ["slider"]) || type === "pacing-summary") && {
      initialValue: Yup.number()
        .required()
        .min(0, "initial value must be between 0-100")
        .max(100, "initial value must be between 0-100"),
      discreteStepSize: Yup.number()
        .required()
        .min(0, "step size must be between 0-100")
        .max(100, "step size must be between 0-100"),
    }),
  };
};

const ContentValidator = (isPacingJournal = false) => {
  return Yup.object().shape({
    ...BaseQuestionValidator,
    screenText: Yup.object().shape({
      ...BaseQuestionValidator.screenText,
      promptText: Yup.string()
        .optional()
        .nullable()
        .max(1000, "prompt text cannot be longer than 1000 characters"),
      content: Yup.string()
        .required("content is required")
        .max(5000, "content cannot be longer than 5000 characters"),
      imageURL: Yup.string().optional().nullable(),
      ...(isPacingJournal && {
        callout: Yup.string().optional().nullable(),
      }),
    }),
  });
};

const SliderValidator = (
  type: CombinedQuestionType,
  isPacingJournal = false
) => {
  const summaryText = {
    summaryText: Yup.string()
      .optional()
      .nullable()
      .max(50, "summary cannot be longer than 50 characters"),
  };

  // pacing-slider has promptText1 and promptText2
  const promptText = {
    promptText:
      type === "pacing-slider"
        ? null
        : Yup.string()
          .required()
          .max(1000, "prompt text cannot be longer than 1000 characters"),
  };

  return Yup.object().shape({
    ...BaseQuestionValidator,
    graphName: Yup.string()
      .optional()
      .nullable()
      .max(50, "graph name cannot be longer than 50 characters"),
    ...(!isPacingJournal && summaryText),
    screenText: Yup.object().shape({
      ...BaseQuestionValidator.screenText,
      ...promptText,
      scale: Yup.string()
        .optional()
        .max(100, "scale cannot be longer than 100 characters"),
      ...(!isPacingJournal && {
        promptText: Yup.string()
          .required()
          .max(1000, "prompt text cannot be longer than 1000 characters"),
      }),
      ...(isPacingJournal && {
        promptText1: Yup.string()
          .required()
          .max(360, "promptText1 cannot be longer than 50 characters"),
        promptText2: Yup.string()
          .required()
          .max(100, "promptText2 cannot be longer than 50 characters"),
        unitSingular: Yup.string().required("units (singular) is required"),
        unitPlural: Yup.string().required("units (plural) is required"),
        summaryEndText: Yup.string()
          .optional()
          .nullable()
          .max(50, "summary end cannot be longer than 50 characters"),
        summaryUnits: Yup.string()
          .optional()
          .nullable()
          .max(25, "summary units cannot be longer than 25 characters"),
        ...summaryText,
      }),
      heading: Yup.string()
        .optional()
        .nullable()
        .max(50, "heading cannot be longer than 50 characters"),
    }),
    validation: Yup.object().shape({
      ...LengthValidators(type),
    }),
  });
};

const RestTimeModalValidator = () => {
  return Yup.object().shape({
    ...BaseQuestionValidator,
    screenText: Yup.object().shape({
      ...BaseQuestionValidator.screenText,
      scale: Yup.string()
        .optional()
        .max(100, "scale cannot be longer than 100 characters"),
      promptText1: Yup.string().required("promptText1 is required"),
      promptText2: Yup.string().required("promptText2 is required"),
      unitSingular: Yup.string().required("units (singular) is required"),
      unitPlural: Yup.string().required("units (plural) is required"),
      heading: Yup.string()
        .optional()
        .nullable()
        .max(50, "heading cannot be longer than 50 characters"),
      modalPromptText: Yup.string().required("modal prompt text is required"),
      checkboxText: Yup.string().required("checkbox text is required"),
    }),
    validation: Yup.object().shape({
      ...LengthValidators("pacing-slider"),
    }),
  });
};

const InstructionsValidator = (isPacingJournal = false) => {
  if (!isPacingJournal) {
    throw new Error(
      "Instructions question can only be used in a pacing/exercise journal"
    );
  }

  return Yup.object().shape({
    ...BaseQuestionValidator,
    screenText: Yup.object().shape({ ...BaseQuestionValidator.screenText }),
  });
};

const TextValidator = (type: CombinedQuestionType, isPacingJournal = false) => {
  const summaryText = {
    summaryText: Yup.string()
      .optional()
      .nullable()
      .max(50, "summary cannot be longer than 50 characters"),
  };

  return Yup.object().shape({
    ...BaseQuestionValidator,
    graphName: Yup.string()
      .optional()
      .nullable()
      .max(50, "graph name cannot be longer than 50 characters"),
    ...(isPacingJournal && {
      summaryEndText: Yup.string()
        .optional()
        .nullable()
        .max(50, "summary end cannot be longer than 50 characters"),
    }),
    ...(!isPacingJournal && {
      ...summaryText,
      displayValuesReference: Yup.boolean().optional(),
    }),
    screenText: Yup.object().shape({
      ...BaseQuestionValidator.screenText,
      promptText: Yup.string()
        .required("prompt text is required")
        .max(1000, "prompt text cannot be longer than 1000 characters"),
      placeholderText: Yup.string()
        .optional()
        .max(100, "placeholder text cannot be longer than 100 characters"),
      heading: Yup.string()
        .optional()
        .max(50, "heading cannot be longer than 50 characters"),
      ...(isPacingJournal && summaryText),
    }),
    validation: Yup.object().shape({
      ...LengthValidators(type),
    }),
  });
};

const OptionalTextValidator = (
  type: CombinedQuestionType,
  isPacingJournal = false
) => {
  const summaryText = {
    summaryText: Yup.string()
      .optional()
      .nullable()
      .max(50, "summary cannot be longer than 50 characters"),
  };

  return Yup.object().shape({
    ...BaseQuestionValidator,
    graphName: Yup.string()
      .optional()
      .nullable()
      .max(50, "graph name cannot be longer than 50 characters"),
    ...(!isPacingJournal && summaryText),
    screenText: Yup.object().shape({
      ...BaseQuestionValidator.screenText,
      positiveText: Yup.string()
        .required("positive text required")
        .max(25, "positive text cannot be longer than 25 characters"),
      negativeText: Yup.string()
        .required("negative text required")
        .max(25, "negative text cannot be longer than 25 characters"),
      promptText: Yup.string()
        .required()
        .max(1000, "prompt text cannot be longer than 1000 characters"),
      placeholderText: Yup.string()
        .optional()
        .max(100, "placeholder text cannot be longer than 100 characters"),
      ...(isPacingJournal && {
        summaryEndText: Yup.string()
          .optional()
          .nullable()
          .max(50, "summary end cannot be longer than 50 characters"),
        ...summaryText,
      }),
    }),
    validation: Yup.object().shape({
      ...LengthValidators(type),
    }),
  });
};

const BooleanValidator = () => {
  return Yup.object().shape({
    ...BaseQuestionValidator,
    screenText: Yup.object().shape({
      ...BaseQuestionValidator.screenText,
      promptText: Yup.string()
        .required()
        .max(1000, "prompt text cannot be longer than 1000 characters"),
      positiveText: Yup.string()
        .required("positive text required")
        .max(25, "positive text cannot be longer than 25 characters"),
      negativeText: Yup.string()
        .required("negative text required")
        .max(25, "negative text cannot be longer than 25 characters"),
    }),
  });
};

const SummaryValidator = (isPacingJournal = false) => {
  return Yup.object().shape({
    ...BaseQuestionValidator,
    screenText: Yup.object().shape({
      ...BaseQuestionValidator.screenText,
      promptText: Yup.string()
        .optional()
        .nullable()
        .max(1000, "prompt text cannot be longer than 1000 characters"),
      imageURL: Yup.string().optional().nullable(),
      heading: Yup.string()
        .optional()
        .nullable()
        .max(50, "heading cannot be longer than 50 characters"),
      ...(isPacingJournal && {
        promptText2: Yup.string()
          .optional()
          .max(100, "promptText2 cannot be longer than 50 characters"),
        scale: Yup.string().optional().nullable(),
      }),
    }),
    ...(isPacingJournal && {
      validation: Yup.object().shape({
        ...LengthValidators("pacing-summary"),
      }),
    }),
  });
};

const DartboardValidator = () => {
  return Yup.object().shape({
    ...BaseQuestionValidator,
    quadrant: Yup.number()
      .required("quadrant is required")
      .oneOf([1, 2, 3, 4], "quadrant must be 1, 2, 3, or 4"),
    screenText: Yup.object().shape({
      ...BaseQuestionValidator.screenText,
      promptText: Yup.string()
        .required()
        .max(1000, "prompt text cannot be longer than 1000 characters"),
      content: Yup.string()
        .required("content is required")
        .max(1000, "content cannot be longer than 1000 characters"),
    }),
  });
};

const DartboardV2Validator = () => {
  return Yup.object().shape({
    ...BaseQuestionValidator,
    valuesCategoryID: Yup.string().required("Values Category required"),
    screenText: Yup.object().shape({
      ...BaseQuestionValidator.screenText,
      heading: Yup.string()
        .optional()
        .nullable(),
      promptText: Yup.string()
        .required()
        .max(1000, "prompt text cannot be longer than 1000 characters"),
    }),
  });
};

const DartboardV2SummaryValidator = () => {
  return Yup.object().shape({
    ...BaseQuestionValidator,
    screenText: Yup.object().shape({
      ...BaseQuestionValidator.screenText,
      dartboardText: Yup.string()
        .required("dartboard text is required")
        .max(100, "prompt text cannot be longer than 100 characters"),
      content: Yup.string()
        .required("content is required")
        .max(5000, "content cannot be longer than 5000 characters"),
    }),
  });
};

const ValuesEntryValidator = () => {
  return Yup.object().shape({
    ...BaseQuestionValidator,
    valuesCategoryID: Yup.string().required("Values Category required"),
    screenText: Yup.object().shape({
      ...BaseQuestionValidator.screenText,
      heading: Yup.string().optional(),
      promptText: Yup.string().required("Prompt required"),
      defaultPlaceholder: Yup.string().required("Default Placeholder required"),
      placeholderTexts: Yup.array().of(Yup.string()),
    }),
  });
};

const MultipleInputValidator = (isPacingJournal = false) => {
  return Yup.object().shape({
    ...BaseQuestionValidator,
    screenText: Yup.object().shape({
      ...BaseQuestionValidator.screenText,
      heading: Yup.string().optional(),
      promptText: Yup.string().required("Prompt required"),
      defaultPlaceholder: Yup.string().required("Default Placeholder required"),
      placeholderTexts: Yup.array().of(Yup.string()),
      ...(isPacingJournal && {
        footer: Yup.string().optional(),
      })
    }),
    validation: Yup.object().shape({
      ...LengthValidators("multipleInput"),
      numInputs: Yup.number().min(1, "must have at least 1 input").required("number of inputs required"),
      minResponses: Yup.number().min(1, "must have at least 1 minimum response").required("number of minimum responses required"),
    }),
  });
};

const EnableRemindersValidator = () => {
  return Yup.object().shape({
    ...BaseQuestionValidator,
    screenText: Yup.object().shape({
      ...BaseQuestionValidator.screenText,
      content: Yup.string()
        .required("content is required")
        .max(5000, "content cannot be longer than 5000 characters"),
      imageURL: Yup.string().optional().nullable(),
    }),
  });
};

const SleepPlanValidator = () => {
  const reminderSectionValidator = {
    title: Yup.string().required("Title required"),
    promptText:
      Yup.string().required("Prompt text required"),
    orderErrorText: Yup.string().required("Order error text required"),
    timeBetweenErrorText: Yup.string().required("Time between error text required"),
    reminderBoxTitle: Yup.string().required("Reminder box title required"),
    reminderBoxCTA: Yup.string().required("Reminder box CTA text required"),
    reminderBoxCheckbox: Yup.string().required("Reminder box checkbox text required"),
    notificationTitle: Yup.string().required("Notification title required"),
    notificationBody: Yup.string().required("Notification body required"),
    notificationUrl: Yup.string().optional(),
  };
  return Yup.object().shape({
    ...BaseQuestionValidator,
    screenText: Yup.object().shape({
      ...BaseQuestionValidator.screenText,
      promptText: Yup.string().required("Prompt required"),
      imageURL: Yup.string().optional().nullable(),
      bedTime: Yup.object().shape(reminderSectionValidator),
      wakeUpTime: Yup.object().shape(reminderSectionValidator),
      windDownTime: Yup.object().shape(reminderSectionValidator),
    }),
  });
};

const PlanPickerValidator = () => {
  return Yup.object().shape({
    ...BaseQuestionValidator,
    screenText: Yup.object().shape({
      ...BaseQuestionValidator.screenText,
      promptText: Yup.string().required("Prompt required"),
      footer: Yup.string().optional(),
      timeBasedPacingDescription: Yup.string().required("Time based pacing description required"),
      taskBasedPacingDescription: Yup.string().required("Task based pacing description required"),
    }),
  });
};


export function getJournalQuestionValidator(type: CombinedQuestionType) {
  switch (type) {
    case "boolean":
      return BooleanValidator();
    case "text":
      return TextValidator(type);
    case "optionalText":
      return OptionalTextValidator(type);
    case "summary":
      return SummaryValidator();
    case "content":
      return ContentValidator();
    case "slider":
      return SliderValidator(type);
    case "dartboard":
      return DartboardValidator();
    case "dartboard_v2":
      return DartboardV2Validator();
    case "summary_dartboard_v2":
      return DartboardV2SummaryValidator();
    case "pacing-text":
      return TextValidator(type, true);
    case "pacing-instructions":
      return InstructionsValidator(true);
    case "pacing-content":
      return ContentValidator(true);
    case "pacing-slider":
      return SliderValidator(type, true);
    case "pacing-summary":
      return SummaryValidator(true);
    case "pacing-multipleInput":
      return MultipleInputValidator(true);
    case "pacing-planPicker":
      return PlanPickerValidator();
    case "pacing-restTimeModal":
      return RestTimeModalValidator();
    case "valuesEntry":
      return ValuesEntryValidator();
    case "multipleInput":
      return MultipleInputValidator();
    case "enableReminders":
      return EnableRemindersValidator();
    case "sleepPlan":
      return SleepPlanValidator();
    default:
      throw new Error(
        `invalid question type supplied to getJournalQuestionValidator: ${type}`
      );
  }
}
