import React from "react";
import {firestore} from "../../firebase";
import AdminNavbar from "../AdminNavbar";
import {useParams} from "react-router-dom";
import {Button, Col, Container, ListGroup, Row,} from "react-bootstrap";

import {Subject} from "../../Model/Subjects.js";
import {useDocument,} from "react-firebase-hooks/firestore";
import TasksTable from "../Users/UserDetail/Sections/UserTasksTable";
import FIQRTable from "./FIQRTable";
import FIQRGraph from "./FIQRGraph";
import {RegistrationRecord} from "../../Model/Subjects";

//TODO: Fix this component, its not rendering

const SubjectsDetail = (props) => {
    let {subjectID} = useParams();

    const [subject, subjectLoading, subjectError] = useDocument(firestore.doc("/subjects/" + subjectID), {
        snapshotListenOptions: {includeMetadataChanges: true},
    });
    const tryRegistration = async (sub) => {
        if (!sub || subjectLoading) {
            return;
        }
        let subject = await Subject.fromFirestore(sub);
        if (subject && subject.email) {
            await RegistrationRecord.register(subject.email, 'Reg-Attempt-' + new Date().getTime().toString());
        } else {
            console.log('registering with invalid subject', subject);
        }

    }
    const getUserSections = () => {
        let s = Subject.fromFirestore(subject);
        if (!s) return <></>;
        if (!s.uid) {
            console.log("found a subject with no uid", subject);
            return (<Row>
                    <h2>User has not been registered or matched to this subject. </h2>
                </Row>);
        }
        return (<Row>
                <TasksTable user={s} programID={s.activeProgram}></TasksTable>
            </Row>);
    };

    return (<>
            {subjectError && <strong>Error: {JSON.stringify(subjectError)}</strong>}
            {subjectLoading && <span>Document: Loading...</span>}
            {subject && (<>
                    <AdminNavbar></AdminNavbar>

                    <Container>
                        <Row>
                            <Col>
                                <Row>
                                    <br></br>
                                    <h1>{subject.data().fullName}</h1>
                                    {!subject.data().uid && <Button onClick={() => {
                                        tryRegistration(subject)
                                    }}>Attempt to register</Button>}
                                    <br></br>
                                </Row>
                                <br></br>
                                <Row>
                                    <Col xs="6" sm="3">
                                        <ListGroup>
                                            <ListGroup.Item>
                        <span>
                          <strong>Email: </strong> {subject.data().email}
                        </span>
                                            </ListGroup.Item>
                                            <ListGroup.Item>
                        <span>
                          <strong>Subject ID: </strong>{" "}
                            {subject.data().subjectID}
                        </span>
                                            </ListGroup.Item>
                                            <ListGroup.Item>
                        <span>
                          <strong>Short Subject ID: </strong>{" "}
                            {subject.data().subjectID}
                        </span>
                                            </ListGroup.Item>
                                            <ListGroup.Item>
                        <span>
                          <strong>SMS Number: </strong>{" "}
                            {subject.data().smsNumber}
                        </span>
                                            </ListGroup.Item>

                                            <ListGroup.Item
                                                variant={subject.data().zendeskID ? "success" : "danger"}
                                            >
                        <span>
                          <strong>Zendesk ID: </strong>{" "}
                            {subject.data().zendeskID}
                        </span>
                                            </ListGroup.Item>
                                        </ListGroup>
                                    </Col>
                                    <Col>
                                        <h3>FIQR</h3>
                                        <FIQRGraph subjectID={subjectID}></FIQRGraph>
                                    </Col>
                                    <Row>
                                        <Col>
                                            <FIQRTable subjectID={subjectID}></FIQRTable>
                                        </Col>
                                    </Row>
                                </Row>
                            </Col>
                        </Row>

                        <br></br>

                        <h3>Tasks</h3>
                        {getUserSections()}
                    </Container>
                </>
                //end subject load render
            )}
        </>);
};

export default SubjectsDetail;
