import React, {useState} from "react";
import {useCollection} from "react-firebase-hooks/firestore";
import LoadingScreen from "../LoadingScreen";

import {firestore} from "../../firebase";
import AdminNavbar from "../AdminNavbar";
import {useHistory} from "react-router-dom";

import {
    Button, Col, Container, Form, FormControl, InputGroup, Jumbotron, Row, Table,
} from "react-bootstrap";
import {Registration} from "../../Model/Registration";
import JSONTree from "react-json-tree";
import {useFormik} from "formik";
import {Hypnotify} from "../../Model/Hypnotify";

const NotifyPlayground = (props) => {
    const [regStatus, setRegStatus] = useState('');
    const [uids, setUids] = useState([]);

    const processUIDs = async (uidString) => {
        const uidStrings = uidString.split(",");
        let uis = await uidStrings.map(async (s) => {
            if (typeof (s) !== typeof ("s") || !s || s.length === 0) return;
            return firestore.collection('users').doc(s).get().then((u) => {
                if (!u.exists) {
                    return;
                }
                return {uid: s, user: u, email: u.data().email, subjectID: u.data().subjectID, notification: ""}
            });


        });
        Promise.all(uis).then((values) => {
            setUids(values);
            return;
        })


    }


    const formik = useFormik({
        initialValues: {
            title: "", scheduledFor: new Date(), status: "not Sent", FCMTokenID: "", body: ""

        },

        onSubmit: async (values, {setSubmitting}) => {
            setRegStatus("lame");
            setSubmitting(true);
            console.log('uids', uids);
            let x = uids.map(async (u) => {
                console.log('u useful', u);
                let n = await Hypnotify.sendToUser(values.title, values.body, u.uid, true);
                u.notification = n;
                return u;

            })
            console.log('promises', x);
            Promise.all(x).then((values) => {
                setUids(values);
                setSubmitting(false);
                setRegStatus("");
                return;
            })


        },
    });


    return (<>
        <Container fluid={true} style={{backgroundColor: "white"}}>
            <Row>
                {regStatus === '' && <Form onSubmit={formik.handleSubmit}>
                    <br></br>

                    <Form.Row>
                        <Form.Group as={Col} xs={12} controlId="title">
                            <Form.Label>UIDs</Form.Label>
                            <Form.Control
                                type="text"
                                name="UIDs"

                                onChange={async (event) => {
                                    await processUIDs(event.target.value.trim());
                                    console.log('processed')
                                }}
                                placeholder="Enter title"
                            />
                        </Form.Group>


                        <Form.Group as={Col} xs={12} controlId="title">
                            <Form.Label>Title</Form.Label>
                            <Form.Control
                                type="text"
                                name="title"
                                value={formik.values.title}
                                onChange={formik.handleChange}
                                placeholder="Enter title"
                            />
                        </Form.Group>


                        <Form.Group as={Col} xs={12} md={12} controlId="body">
                            <Form.Label>Body</Form.Label>
                            <Form.Control
                                type="text"
                                name="body"
                                value={formik.values.body}
                                onChange={formik.handleChange}
                                placeholder="Body"
                            />
                        </Form.Group>


                    </Form.Row>

                    <Button
                        disabled={formik.isSubmitting}
                        variant="primary"
                        type="submit"
                        onClick={() => {
                            formik.handleSubmit();
                        }}
                    >
                        Submit
                    </Button>
                </Form>}
            </Row>

            {uids.map(u => {
                return (<Row><p><strong>{u.subjectID}</strong> ({u.email}) <JSONTree data={u.notification}
                                                                                     shouldExpandNode={() => false}/>
                </p></Row>);
            })}

            {regStatus === 'registering' && <Col>
                <h1>Registering</h1>
            </Col>}</Container>
    </>);
};

export default NotifyPlayground;
