import { firestore } from 'firebase';
import { Formik, Form as FormikForm, useField } from 'formik';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Modal, Row } from 'react-bootstrap';
import { FormikSubmitButton, FormikTextField, FormikToggle } from '../../FormikFields';
import FormikSelectAudio from '../../FormikFields/Constructed/FormikSelectAudio';
import type { IVoiceActorMetaData } from '@swing-therapeutics/swingcore/dist/models/voice_actors/types';
import * as yup from 'yup';
import { useImageBucket } from '../../../hooks/storage';
import VoiceActor from '@swing-therapeutics/swingcore/dist/models/voice_actors/VoiceActor';
interface Props {
  show: boolean;
  onHide: () => void;
  programKey: string;
  editActor: firestore.QueryDocumentSnapshot | 'new';
}

interface MessageState {
  msg: string;
  error: boolean;
}

const defaultMessageState: MessageState = {
  msg: '',
  error: false,
}

const VoiceActorModal: React.FC<Props> = ({ show, onHide, programKey, editActor }) => {
  const [messageState, setMessageState] = useState({ ...defaultMessageState });

  const intialFormValues = useMemo(() => {
    if (editActor === 'new') {
      const newActor: IVoiceActorMetaData = {
        desc: '',
        name: '',
        id: '',
        image_filename: '',
        remote_audio_filename: '',
        defaultVoiceActor: false,
      }
      return newActor;
    }
    return editActor.data() as IVoiceActorMetaData;
  }, [editActor])

  const closeModal = useCallback(() => {
    setMessageState({ ...defaultMessageState });
    onHide();
  }, [onHide])

  return (
    <Modal show={show} onHide={closeModal}>
      <Formik
        initialValues={{ ...intialFormValues }}
        onSubmit={async (values, actions) => {
          try {
            if (values.defaultVoiceActor && !intialFormValues.defaultVoiceActor) {
              // Default value has been updated, check that only 1 voice actor is set to default
              const allVoiceActors = await VoiceActor.getAllVoiceActors(programKey);
              for (const voiceActor of allVoiceActors) {
                // Skip this one
                if (voiceActor.id === values.id) continue;
                if (voiceActor.defaultVoiceActor) {
                  // Make the voice actor no longer the default, the updated voice actor will be the new default
                  voiceActor.defaultVoiceActor = false;
                  await voiceActor.persist();
                }
              }
            }
            if (editActor === 'new') {
              // Check if the id already exists
              const doc = await firestore().doc(`programs/${programKey}/voiceActors/${values.id}`).get();
              if (doc.exists) {
                throw `Voice actor with ID '${values.id}' already exists`;
              }
              await firestore().doc(`programs/${programKey}/voiceActors/${values.id}`).set(values);
              closeModal();
            }
            else {
              await editActor.ref.update(values);
              setMessageState({ msg: "Saved", error: false });
            }
          } catch (error) {
            if (typeof error === 'string') {
              setMessageState({ msg: error, error: true });
            }
            else {
              setMessageState({ msg: 'Error submiting', error: true });
            }
            console.error(error);
          }
          actions.setSubmitting(false);
        }}
        validationSchema={FormSchema}
      >
        <FormikForm role="form">
          <Modal.Header closeButton>
            <Modal.Title>
              {editActor === 'new' ? 'New voice actor' : 'Edit voice actor'}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FormikTextField
              name="name"
              label="Name"
              desc="The name of the voice actor displayed to the user"
            />
            <FormikTextField
              name="id"
              label="ID"
              desc="ID of the voice actor"
              disabled={editActor !== 'new'}
            />
            <FormikToggle
              name="defaultVoiceActor"
              label="Default voice actor"
              allowLabelClick={true}
              desc="This voice actor will be the default voice played if a user has not selected a preferred voice actor."
              disabled={intialFormValues.defaultVoiceActor}
            />
            <FormikTextField
              name="desc"
              label="Description"
              multiLine={true}
            />
            <FormikSelectAudio
              name="remote_audio_filename"
              label="Sample audio"
              program={programKey}
              audioType='generic'
            />
            <FormikTextField
              name="image_filename"
              label="Image filename"
            />
            <Row className="justify-content-center">
              <ImageDisplay />
            </Row>
            <Row className="justify-content-center">
              <FormikSubmitButton label="Save" />
            </Row>
            <Row className="justify-content-center">
              <h6 className={`mt-2 ${messageState.error ? 'text-warning' : 'text-success'}`}>{messageState.msg}</h6>
            </Row>
          </Modal.Body>
        </FormikForm>
      </Formik>
    </Modal>
  )
}

export default VoiceActorModal;

const ImageDisplay: React.FC = () => {
  const [fieldInput, _fieldMeta, _fieldHelpers] = useField('image_filename');
  const [ref, loading, error] = useImageBucket();
  const [srcLoading, setSrcLoading] = useState(true);
  const [imgSrc, setImgSrc] = useState('');

  useEffect(() => {
    if (fieldInput.value && !loading && !error) {
      setSrcLoading(true);
      const imageRef = ref.find((imageRef) => imageRef.name === fieldInput.value)
      if (imageRef) {
        imageRef.getDownloadURL()
          .then((downloadURL) => {
            setImgSrc(downloadURL);
            setSrcLoading(false);
          });
      }
      else {
        setImgSrc('');
        setSrcLoading(false);
      }
    }
  }, [fieldInput.value, ref, loading, error])

  return imgSrc ?
    <img src={imgSrc} style={{ maxHeight: '200px', padding: '20px' }} />
    :
    !srcLoading && <h6 style={{ padding: '20px' }}>Image not found</h6>
}

const FormSchema = yup.object().shape({
  name: yup.string().required('Required'),
  desc: yup.string().required('Required'),
  id: yup.string().required('Required'),
  image_filename: yup.string().trim('The image filename may not have leading or trailing whitespaces.').strict(true).required('Required'),
  remote_audio_filename: yup.string().required('Required'),
})
