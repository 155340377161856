import {auth, firestore} from "../firebase";


class Hypnotify {

    static async sendToUser(title: string, body: string, uid: string, sendToAllDevices: boolean = false, scheduledFor: Date = new Date()) {

        let user = await firestore.collection('users')
            .doc(uid).get();
        if (!user.exists) {
            console.log('unable to locate user ', uid);
            return false;
        }

        let devices = await firestore.collection("users")
            .doc(uid).collection("devices").orderBy("lastOpenedTime", "desc").get();
        if (devices.empty) {
            console.log('user does not have any devices registered ', uid);
            return;
        }

        let allNotifs = devices.docs.map((docRef) => {
            let data = docRef.data();
            let notification = {
                title: title,
                body: body,
                FCMToken: data.FCMToken,
                deviceID: docRef.id,
                scheduledFor: scheduledFor,
                imageURL: "https://s3.amazonaws.com/pix.iemoji.com/images/emoji/apple/ios-12/256/dog.png",
                uid: uid,
                subjectID: user?.data()?.subjectID ? user?.data()?.subjectID : null,
                email: user?.data()?.email ? user?.data()?.email : null,
                status: "scheduled",

            }
            return notification;
        });
        let toBeSent = sendToAllDevices ? allNotifs : [allNotifs[0]];
        for (const notif of toBeSent) {
            // @ts-ignore
            let x = await firestore.collection("notifications").doc().set(notif);
            console.log(x);
        }
        return toBeSent;


    }


}


export {Hypnotify};