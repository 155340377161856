import { JournalPhase } from "@swing-therapeutics/swingcore/dist/models/journals/Journal";
import { PacingJournalPhase } from "@swing-therapeutics/swingcore/dist/models/journals/PacingJournal";
import React from "react";
import { Form, Col, Row } from "react-bootstrap";
import { BasicTextField, BasicEasyEditField, BasicBooleanField, BasicOptionField} from "../../../utils/FormikHelpers";

export type PhaseKeyType =
  'creation' |
  'conversion' |
  'confirmation' |
  'completion' |
  'activity' |
  'checkIn'


interface PhaseFormFieldsProps {
  phaseKey: PhaseKeyType;
  values: Record<string, any>;
  errors: Record<string, any>;
  touched: Record<string, any>;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void,
  handleChange: (event: React.ChangeEvent) => void;
  isPacingJournal: boolean;
  isDartboard: boolean;
}

interface BasicFieldProps {
  path: string,
  title?: string,
  md?: number | string,
  lg?: number | string,
}

export const PhaseFormFields = ({
  phaseKey,
  values,
  errors,
  touched,
  handleChange,
  setFieldValue,
  isPacingJournal,
  isDartboard,
}: PhaseFormFieldsProps) => {
  const formikHelpers = {values, errors, touched, handleChange, setFieldValue};

  const getTemplateFields = (
    fieldName: string,
    cardTypes: string[] = ['cardTitle', 'cardSubtitle', 'cardTemplate']
  ) => {
    const [cardTitle, cardSubtitle, cardTemplate] = cardTypes;


    return (
      <Row className="mt-3 border-top border-dark p-3">
        <Col>
          <h5 className="mb-3">{fieldName}</h5>
          <Row>
            <BasicTextField path={`${fieldName}.${cardTitle}`} title="Title" formikHelpers={formikHelpers}/>
            <BasicTextField path={`${fieldName}.${cardSubtitle}`} title="Subtitle" formikHelpers={formikHelpers}/>
            <BasicEasyEditField path={`${fieldName}.${cardTemplate}`} title="Template" lg={12} markupType="markdown" formikHelpers={formikHelpers}/>
          </Row>
        </Col>
      </Row>
    )
  }

  if (phaseKey === JournalPhase.Creation) {
    return (
      <BasicBooleanField path={'prefill'} title={'Prefill'} formikHelpers={formikHelpers}/>
    )
  }

  if (phaseKey === JournalPhase.Confirmation || phaseKey === PacingJournalPhase.Activity) {

    return (
      <>
        <Form.Group as={Col}>
          <Row>
            <BasicTextField path="positiveButtonText" title="Positive Button" formikHelpers={formikHelpers}/>
            <BasicTextField path="negativeButtonText" title="Negative Button" formikHelpers={formikHelpers}/>
            <BasicTextField path="cardTitle" title="Title" formikHelpers={formikHelpers}/>
            <BasicTextField path="cardSubtitle" title="Subtitle" formikHelpers={formikHelpers}/>
            <BasicEasyEditField path="cardTemplate" title="Template" lg={12} markupType="markdown"  formikHelpers={formikHelpers}/>
          </Row>
        </Form.Group>
      </>
    )
  }

  if (phaseKey === JournalPhase.Completion) {
    const completedComponent = (
      <BasicOptionField 
        path="completedComponent" 
        title="Completed Component" 
        formikHelpers={formikHelpers}
        undefinedOption="Select a completed component..."
        options={[
          {name: 'Default', value: 'default'},
          {name: 'Pacing', value: 'pacing'},
          {name: 'Values', value: 'values'},
          {name: 'Insights', value: 'insights'},
          {name: 'Dartboard', value: 'dartboard'},
          {name: 'Sleep', value: 'sleep'},
        ]}
      />
    )

    if (isDartboard) {
      const cardTypes = ['cardTitle', 'cardSubtitle', 'cardTemplateString'];
      return (
        <>
          <Form.Group as={Col}>
            {completedComponent}
            {getTemplateFields('default.0', cardTypes)}
            {getTemplateFields('default.1', cardTypes)}
            {getTemplateFields('default.2', cardTypes)}
            {getTemplateFields('default.3', cardTypes)}
          </Form.Group>
        </>
      )
    }

    return (
      <>
        <Form.Group as={Col}>
          {completedComponent}
          {getTemplateFields('positiveConfirmation')}
          {getTemplateFields('negativeConfirmation')}
          {getTemplateFields('default')}
        </Form.Group>
      </>
    )
  }

  return (
    <Row className="mb-2">
      <Col>
        <div>No fields for this phase</div>
      </Col>
    </Row>
  )
}

