import React, { useEffect, useState } from "react";
import LoadingScreen from "../LoadingScreen";
import { DocumentData,firestore } from "../../firebase";
import AdminNavbar from "../AdminNavbar";
import { RouteComponentProps, useHistory, useLocation } from "react-router-dom";

import {
  Col,
  Container,
  Form,
  FormControl,
  Jumbotron,
  Row,
  Table,
} from "react-bootstrap";
import useRole from "../../UseRole";
import moment from "moment";
import useUserList from "../../hooks/useUserList";

const diffHr = (dt2, dt1) => {
  var diff = (dt2.getTime() - dt1.getTime()) / 1000;
  diff /= 60 * 60;
  return Math.abs(Math.round(diff));
};

interface UserRowProps {
  userRef: DocumentData;
  filter: string;
  colorCode: boolean;
  isInfoViewer: boolean;
}

interface SelectOption {
  label: string;
  value: string;
}

const UserRow: React.FC<UserRowProps> = ({
  userRef,
  filter,
  colorCode,
  isInfoViewer,
}) => {
  const [lastUsage, setLastUsage] = useState<string>();
  const [cssClass, setCssClass] = useState("text-dark");
  const history = useHistory();

  const user = userRef;

  useEffect(() => {
    if (!userRef.id) return;
    const sub = firestore
      .collection("users")
      .doc(userRef.id)
      .collection("appUsage")
      .orderBy("timeOpened", "desc")
      .limit(1)
      .onSnapshot((snap) => {
        const lastUsageRef = snap.docs?.[0];

        let lastOpenedTime = lastUsageRef?.data()?.timeOpened
          ? lastUsageRef.data().timeOpened.toDate()
          : null;

        let openTime = lastOpenedTime
          ? lastOpenedTime.toLocaleString() + " (" + lastOpenedTime
            ? moment(lastOpenedTime).fromNow()
            // eslint-disable-next-line no-useless-concat
            : "" + " )"
          : "";

        let className = "text-success";
        if (lastOpenedTime) {
          let hrsAgo = diffHr(lastOpenedTime, new Date());
          className = hrsAgo > 24 && hrsAgo < 48 ? "text-warning" : className;
          className = hrsAgo > 48 ? "text-danger" : className;
        } else {
          className = "text-warning";
        }

        if (!colorCode) className = "text-dark";
        setCssClass(className);
        setLastUsage(openTime);
      });

    return sub;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, colorCode]);

  if (
    filter &&
    !user?.email?.toLowerCase().includes(filter) &&
    !user?.displayName?.toLowerCase().includes(filter) &&
    !user?.subjectID?.toLowerCase().includes(filter)
  ) {
    return <></>;
  }

  let regName = !user.firstName
    ? user.displayName
    : user.displayName + " (" + user.firstName + " " + user.lastName + ")";

  let study = user.study ? user.study + " at " + user.site : "";

  const handleRowClick = () => {
    history.push("/users/" + user.uid);
  };

  return (
    <tr className={"hoverablerow " + cssClass} onClick={handleRowClick}>
      {isInfoViewer && <td>{regName}</td>}
      {isInfoViewer && <td>{user.email}</td>}
      <td>{user.subjectID}</td>
      <td>{user.cohort}</td>
      <td>{study}</td>
      <td>{user.activeProgram}</td>
      <td>{user.arm}</td>
      <td>{user.appVersion}</td>
      <td>{lastUsage}</td>
      <td>{user.referralGroupKey}</td>
    </tr>
  );
};

const UserList: React.FC<RouteComponentProps> = () => {
  const isAdmin = useRole("admin");
  const [programs, setPrograms] = useState<SelectOption[]>([]);
  const [cohorts, setCohorts] = useState<SelectOption[]>([]);
  const [studies, setStudies] = useState<SelectOption[]>([]);
  const [sites, setSites] = useState([]);

  const filters = [
    "arm",
    "cohort",
    "study",
    "referralGroupKey",
    "group",
    "appVersion",
    "subjectID",
    "activeProgram",
    "email",
    "displayName",
    "site"
  ];

  const [colorCode, setColorCode] = useState(true);
  const [showBadMigrationVersions, setShowBadMigrationVersions] =
    useState(false);
  const isInfoViewer = useRole("info-viewer");

  let history = useHistory();
  let { search } = useLocation();
  const pageNumber = new URLSearchParams(search).get("page");
  let query = firestore.collection("users").orderBy("email", "asc");
  const {
    visibleDocs,
    PaginationButtons,
    page,
    searchFunction,
    filterBadVersions,
    ResetButton,
    filter,
    setFilter,
  } = useUserList(query, 20, filters);

  useEffect(() => {
    //@ts-ignore
    if (page.current !== pageNumber) {
      history.replace({
        pathname: "/users",
        search: "?page=" + page.current,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, page.current, pageNumber]);

  useEffect(() => {
    (async () => {
      const programsSnap = await firestore.collection(`programs`).get();
      const cohortsSnap = await firestore.collection(`cohorts`).get();
      const studiesSnap = await firestore.collection(`studies`).get();
      const sitesSnap = await firestore.collection(`medrio/sites/available`).get();

      const cohortsList: SelectOption[] = [];
      const programSites: SelectOption[] = [];
      const studiesList: SelectOption[] = [];
      const sitesList = [];

      for (const progSnap of programsSnap.docs) {
        const progData = progSnap.data();
        programSites.push({
          label: progData.name || progData.id,
          value: progSnap.id,
        });
      }
      for (const cohortSnap of cohortsSnap.docs) {
        const cohortData = cohortSnap.data();
        cohortsList.push({
          label: cohortData.name || cohortData.id,
          value: cohortSnap.id,
        });
      }

      for (const studySnap of studiesSnap.docs) {
        const studyData = studySnap.data();
        studiesList.push({
          label: studyData.name || studyData.id,
          value: studySnap.id,
        });
      }

      for (const siteSnap of sitesSnap.docs) {
        const siteData = siteSnap.data();
        sitesList.push({
          label: siteData.siteName ?? siteSnap.id,
          value: siteSnap.id,
        })
      }

      setStudies(studiesList);
      setCohorts(cohortsList);
      setPrograms(programSites);
      setSites(sitesList);
    })();
  }, []);

  return (
    <>
      <AdminNavbar />
      <Jumbotron>
        <Container fluid={true}>
          <Row>
            <Col style={{ paddingLeft: 10, fontFamily: "roboto" }}>
              <h1 style={{ margin: 0, fontSize: "2.5rem" }}>Users</h1>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form>
                <Form.Row>
                  <Col xs={8} md={2} lg={2}>
                    <Form.Label> Search </Form.Label>

                    <Form.Group className="" style={{ display: "flex" }}>
                      <FormControl
                        id="search"
                        placeholder="Search"
                        onKeyPress={(e) => {
                          return e.key === "Enter" ? e.preventDefault() : null;
                        }}
                        value={filter}
                        onSubmit={(e) => e.preventDefault()}
                        onChange={(e) => {
                          e.preventDefault();
                          setFilter(e.target.value);
                          searchFunction(e.target.value.trim());
                        }}
                      />
                      <ResetButton />
                    </Form.Group>
                  </Col>

                  <Col xs={4} md={3} lg={2}>
                    <Form.Label> Cohort </Form.Label>

                    <Form.Control
                      as="select"
                      onChange={(e) => {
                        searchFunction(e.target.value.trim(), 'cohort');
                      }}
                    >
                      <option></option>
                      {cohorts.map((cohort) => (
                        <option value={cohort.value} key={cohort.value}>
                          {cohort.label}
                        </option>
                      ))}
                    </Form.Control>
                  </Col>

                  <Col xs={4} md={3} lg={2}>
                    <Form.Label> Program </Form.Label>

                    <Form.Control
                      as="select"
                      onChange={(e) => {
                        searchFunction(e.target.value.trim(), 'activeProgram');
                      }}
                    >
                      <option></option>
                      {programs.map((program) => (
                        <option value={program.value} key={program.value}>
                          {program.label}
                        </option>
                      ))}
                    </Form.Control>
                  </Col>

                  <Col xs={4} md={3} lg={2}>
                    <Form.Label> Study </Form.Label>

                    <Form.Control
                      as="select"
                      onChange={(e) => {
                        searchFunction(e.target.value.trim(), 'study');
                      }}
                    >
                      <option></option>
                      {studies.map((studies) => (
                        <option value={studies.value} key={studies.value}>
                          {studies.label}
                        </option>
                      ))}
                    </Form.Control>
                  </Col>

                  <Col xs={4} md={3} lg={2}>
                    <Form.Label> Referrer </Form.Label>
                    <Form.Control
                      as="select"
                      onChange={(e) => {
                        searchFunction(e.target.value.trim(), 'referralGroupKey');
                      }}
                    >
                      <option></option>
                      <option value={""}></option>
                      <option value={"ipm"}>ipm</option>
                    </Form.Control>
                  </Col>

                  <Col xs={4} md={3} lg={2}>
                    <Form.Label> Group </Form.Label>

                    <Form.Control
                      as="select"
                      onChange={(e) => {
                        searchFunction(e.target.value.trim(), 'group');
                      }}
                    >
                      <option></option>
                      <option value={"ACT-weekly-insights"}>
                        A1 ACT Weekly Insights
                      </option>
                      <option value={"ACT-daily-insights"}>
                        A2 ACT Daily Insights
                      </option>
                    </Form.Control>
                  </Col>

                  <Col xs={4} md={3} lg={2}>
                    <Form.Label> Site </Form.Label>

                    <Form.Control
                      as="select"
                      onChange={(e) => {
                        searchFunction(e.target.value.trim(), 'site');
                      }}
                    >
                      <option></option>
                      {sites.map((site) => (
                        <option value={site.value} key={site.value}>{site.label}</option>
                      ))}
                    </Form.Control>
                  </Col>

                  <Col className="d-xs-none d-md-block">
                    <Form.Check
                      type={"checkbox"}
                      id={`color-code`}
                      checked={colorCode}
                      label={`Color Code`}
                      onChange={(e) => {
                        setColorCode(e.target.checked);
                      }}
                    />
                  </Col>

                  {isAdmin && (
                    <Col className="d-xs-none d-md-block">
                      <Form.Check
                        type="checkbox"
                        id="show-bad-migration-versions"
                        checked={showBadMigrationVersions}
                        label="Show bad migration versions only"
                        onChange={(e) => {
                          setShowBadMigrationVersions(e.target.checked);

                          filterBadVersions(e.target.checked);
                        }}
                      />
                    </Col>
                  )}
                </Form.Row>
              </Form>
            </Col>
          </Row>
        </Container>
      </Jumbotron>
      <Container fluid={true} style={{ backgroundColor: "white" }}>
        <Row>
          {!visibleDocs && (
            <>
              <strong>Subjects Loading</strong>
              <br></br>
              <LoadingScreen></LoadingScreen>
            </>
          )}
          {!!visibleDocs && (
            <Col xs={12}>
              <Table striped responsive>
                <thead key="systemEventsTable">
                  <tr>
                    {isInfoViewer && <th>Name</th>}
                    {isInfoViewer && <th>Email</th>}
                    <th>Subject ID</th>
                    <th>Cohort</th>
                    <th>Study</th>
                    <th>Active Program</th>
                    <th>Group</th>
                    <th>Version</th>
                    <th>Last Active</th>
                    <th>Referrer</th>
                  </tr>
                </thead>
                <tbody>
                  {visibleDocs.map((u) => {
                    return (
                      <UserRow
                        userRef={u}
                        filter={filter.toLowerCase()}
                        colorCode={colorCode}
                        isInfoViewer={isInfoViewer}
                        key={u.id}
                      />
                    );
                  })}
                </tbody>
              </Table>
              <PaginationButtons />
            </Col>
          )}
        </Row>
      </Container>
    </>
  );
};

export default UserList;
