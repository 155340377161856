import { Formik, Form as FormikForm, useField } from "formik";
import { Col, Row } from "react-bootstrap";
import React, { useEffect } from "react";
import { SystemEvent } from "../../../../Model/SystemEvent";
import { StepEditorProps } from "./StepEditor";
import { stepImageTypes } from "@swing-therapeutics/swingcore/dist/models/content/Lesson";
import * as yup from 'yup';
import { FormikRichTextField, FormikSelect, FormikSubmitButton, FormikTextField } from "../../../FormikFields";
import FormikSelectAudio from "../../../FormikFields/Constructed/FormikSelectAudio";

const AudioStepEditor: React.FC<StepEditorProps> = ({ step, lesson, goBack }) => {

  return (
    <Col>
      <Formik
        initialValues={step}
        validationSchema={FormSchema}
        onSubmit={async (values, actions) => {
          // This should be refractored eventually, should not be modifying props like this
          step.title = values.title ?? "";
          step.description = values.description ?? "";
          step.image_filename = values.image_filename ?? null;
          step.audio_filename = values.audio_filename ?? null;
          step.remote_audio_filename = values.remote_audio_filename ?? null;
          step.imageType = values.imageType ?? "";

          try {
            await lesson.persist();
            window.alert('Step saved successfully!');
            SystemEvent.fireEvent(
              "Content Update",
              "Audio step update",
              "Lesson " + lesson.id + " audio step edited",
              new Date().toISOString(),
              { lesson: JSON.stringify(lesson), step }
            );
            goBack();
          } catch (error) {
            window.alert('Error during saving. Refer to console for details')
          } finally {
            actions.setSubmitting(false);
          }
        }}
      >
        <FormikForm>
          <Row>
            <Col xs={12} md={6}>
              <FormikTextField
                name="title"
                desc="Title of the step"
                label="Title"
              />
            </Col>
            <Col xs={12} md={6}>
              <FormikTextField
                name="audio_filename"
                label="Local Audio filename"
                desc="Old version of audio filename that points to audio in app bundle, old versions of the app use this. If this is changed to new audio and the audio does not exist in the app bundle, the audio will not be played"
              />
              <FormikSelectAudio
                name="remote_audio_filename"
                label="Remote Audio filename"
                desc="New remote audio filename that points to audio stored outside of app, new versions of the app use this"
                program={lesson.programKey}
              />
            </Col>
          </Row>
          <FormikRichTextField
            name='description'
            label='Description'
            markupType='markdown'
          />
          <Row>
            <Col xs={12} md={6}>
              <FormikTextField
                name="image_filename"
                label="Image Filename"
              />
            </Col>
            <Col xs={12} md={6}>
              <ConditionalDisableImageType />
            </Col>
          </Row>
          <Row>
            <Col>
              <DisplaySelectedImage />
            </Col>
          </Row>
          <FormikSubmitButton label="Submit" />
        </FormikForm>
      </Formik>
    </Col>
  );
};

export default AudioStepEditor;

const FormSchema = yup.object().shape({
  title: yup.string().required('Title is required'),
  description: yup.string().required('Content is required'),
  audio_filename: yup.string().required('Audio filename is required'),
  remote_audio_filename: yup.string().required('Audio filename is required'),
  image_filename: yup.string().trim('The image filename may not have leading or trailing whitespaces.').strict(true).nullable(),
  imageType: yup.string().when('image_filename', {
    is: (fileName) => !!fileName,
    then: yup.string().required('Image type is required when using an image'),
    otherwise: yup.string().nullable(),
  }),
})

const ConditionalDisableImageType: React.FC = () => {
  const [fieldInput, _fieldMeta, fieldHelpers] = useField('image_filename');
  const [_it_fieldInput, _it_fieldMeta, it_fieldHelpers] = useField('imageType');

  useEffect(() => {
    if (fieldInput.value === "") {
      fieldHelpers.setValue(null);
      it_fieldHelpers.setValue(null);
    }
  }, [fieldInput.value])

  return (
    <FormikSelect
      label="Image Type"
      name="imageType"
      placeHolder={!fieldInput.value ? 'No image' : 'Select...'}
      disabled={!fieldInput.value}
      options={stepImageTypes.filter((option) => option.name !== 'None').map((option) => ({ label: option.name, value: option.value }))}
    />
  )
}

const DisplaySelectedImage: React.FC = () => {
  const [fieldInput, _fieldMeta, _fieldHelpers] = useField('image_filename');

  return fieldInput.value ? (
    <img
      style={{ maxHeight: "150px", marginBottom: '20px' }}
      alt={fieldInput.value}
      src={
        "https://storage.googleapis.com/tempo-pub/assets/v1/images/" +
        fieldInput.value
      }
    />
  ) : null
}
