import { FormikErrors, useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import {
  Celebration,
  CelebrationState,
  ImageWithText,
} from "@swing-therapeutics/swingcore/dist/models/content/Celebration";
import * as uuid from "uuid";
import firebase, { firestore } from "firebase";
import LoadingScreen from "../../LoadingScreen";
import { useParams } from "react-router-dom";
import Select from "react-select";
import { useDocumentClassDataOnce } from "../../../utils/Hooks";
import { getCelebrationsTemplates } from "@swing-therapeutics/swingcore/dist/utils/queries";
import { FieldHeader } from "../../../utils/FormikHelpers";
import { useImageBucket, useSvgBucket } from "../../../hooks/storage";

interface CelebrationEditorProps {
  selectedCelebration?: Celebration;
  hideEditor: () => void;
  programKey: string;
}

export type LoadableType = "loaded" | "loadable" | "notLoadable";
export type InfoType = "modal" | "nullState";

const getError = (errors: any) => {
  for (let error in errors) {
    return <div style={{ color: "red" }}>{`${errors[error]}`}</div>;
  }
  return <></>;
};

export const CelebrationEditor = ({
  selectedCelebration,
  hideEditor,
  programKey,
}: CelebrationEditorProps) => {
  const { id } = useParams() as { id: string };

  const query = id ? getCelebrationsTemplates(programKey).doc(id) : null;
  const [fetchedCelebration, loading, error] = useDocumentClassDataOnce(
    Celebration,
    query
  );
  const [submitPressed, setSubmitPressed] = useState(false);
  const [svgBucket, svgBucketLoading] = useSvgBucket();
  const [imageBucket, imageBucketLoading] = useImageBucket();

  const celebration = selectedCelebration ?? fetchedCelebration;

  const formik = useFormik({
    initialValues: {
      id: celebration?.id,
      name: celebration?.name,
      programKey: celebration?.programKey,
      loaded: celebration?.loaded,
      loadable: celebration?.loadable,
      notLoadable: celebration?.notLoadable,
    },
    validate: (values) => {
      const { name = "", loaded, loadable, notLoadable } = values;
      const errors: FormikErrors<Celebration> = {};

      if (name.length === 0) {
        errors.name = "Name is required";
        return errors;
      }

      const getImageTypeErrorMessage = (celebrationStateName: 'Loaded' | 'Loadable' | 'Not Loadable') => {
        return `${celebrationStateName} celebration already existed with an svg, cannot add an image. Make a new celebration and use that instead!`;
      };

      if (!verifyCelebrationState(loaded)) {
        errors.name = "All 'Loaded' Svg and Text boxes required";
        return errors;
      }

      if (!verifyCelebrationState(loadable)) {
        errors.name = "All 'Loadable' Svg and Text boxes required";
        return errors;
      }

      if (!verifyCelebrationState(notLoadable)) {
        errors.name = "All 'Not Loadable' Svg and Text boxes required";
        return errors;
      }

      return errors;
    },
    enableReinitialize: true,
    onSubmit: async (values) => {
      //Validating name on submit to prevent constant firestore calls
      const isUnique = await checkIfNameIsUnique(
        values.name,
        programKey,
        celebration?.id
      );
      if (!isUnique) {
        formik.setErrors({ name: "Name is not unique" });
        return;
      }

      const newCelebration = new Celebration({
        id: celebration?.id ?? uuid.v4(),
        name: values.name,
        programKey,
        loaded: values.loaded,
        loadable: values.loadable,
        notLoadable: values.notLoadable,
      });
      await newCelebration.persist();
      alert("Celebration Saved");
      hideEditor();
    },
  });

  const { values } = formik;

  if (error) {
    return <div>Error loading celebration</div>;
  }

  if (loading || svgBucketLoading || imageBucketLoading) {
    return <LoadingScreen />;
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div style={{ marginBottom: 20 }}>
        <Button onClick={() => hideEditor()} variant="secondary">
          Close
        </Button>
      </div>
      <h1>Create New Celebration 🎉</h1>
      <Form.Group style={{ maxWidth: 300, marginTop: 20 }}>
        <Form.Label>Name</Form.Label>
        <Form.Control
          name="name"
          value={values.name}
          onChange={formik.handleChange}
          placeholder={"Name"}
        />
      </Form.Group>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <CelebrationGroup
          celebrationState={values.loaded}
          loadableType="loaded"
          formik={formik}
          svgBucket={svgBucket}
          imageBucket={imageBucket}
        />
        <CelebrationGroup
          celebrationState={values.loadable}
          loadableType="loadable"
          formik={formik}
          svgBucket={svgBucket}
          imageBucket={imageBucket}
        />
        <CelebrationGroup
          celebrationState={values.notLoadable}
          loadableType="notLoadable"
          formik={formik}
          svgBucket={svgBucket}
          imageBucket={imageBucket}
        />
      </div>
      <Col>{submitPressed && <Row>{getError(formik.errors)}</Row>}</Col>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          marginTop: 20,
        }}
      >
        <Button
          style={{ marginRight: 20 }}
          onClick={() => hideEditor()}
          variant="secondary"
        >
          Close
        </Button>
        <Button
          onClick={() => {
            setSubmitPressed(true);
            formik.submitForm();
          }}
          variant="success"
        >
          Save changes
        </Button>
      </div>
    </div>
  );
};

const verifyCelebrationState = (state?: CelebrationState): boolean => {
  return (
    verifyImageWithText(state?.modal) && verifyImageWithText(state?.nullState)
  );
};

const verifyImageWithText = (imageWithText?: ImageWithText): boolean => {
  const textValid = imageWithText?.text && imageWithText?.text.length > 0;
  if (imageWithText?.svg) {
    return textValid && imageWithText?.svg && imageWithText?.svg.length > 0;
  } else {
    return (
      textValid &&
      imageWithText?.imageFilename &&
      imageWithText?.imageFilename?.length > 0
    );
  }
};

const checkIfNameIsUnique = async (
  name: string,
  programKey: string,
  id?: string
): Promise<boolean> => {
  return firestore()
    .collection("programs")
    .doc(programKey)
    .collection("celebrations")
    .get()
    .then((snapshots) => {
      for (const doc of snapshots.docs) {
        //Ignore docs with same id, allow a celebration to update itself
        if (id && doc?.data()?.id === id) {
          continue;
        }
        if (doc.data()?.name === name) {
          return false;
        }
      }
      return true;
    });
};

const CelebrationGroup = ({
  celebrationState,
  loadableType,
  formik,
  svgBucket,
  imageBucket
}: {
  celebrationState: CelebrationState;
  loadableType: LoadableType;
  formik: any;
  svgBucket: firebase.storage.Reference[];
  imageBucket: firebase.storage.Reference[];
}) => {
  const [modalImageType, setModalImageType] = useState<"svg" | "image">(celebrationState?.modal?.svg ? "svg" : "image");
  const [nullStateImageType, setNullStateImageType] = useState<"svg" | "image">(celebrationState?.nullState?.svg
    ? "svg"
    : "image");

  return (
    <Card border="dark" style={{ marginTop: 20 }}>
      <Card.Body>
        <Card.Title>{loadableType}</Card.Title>
        <Row>
          <Col className="border-right border-dark">
            <div>Modal</div>
            <CelebrationRow
              imageWithText={celebrationState?.modal}
              loadableType={loadableType}
              infoType="modal"
              imageType={modalImageType}
              onImageTypeChange={type => {
                setModalImageType(type);
              }}
              formik={formik}
              imageBucket={imageBucket}
              svgBucket={svgBucket}
            />
          </Col>
          <Col>
            <div>Null State</div>
            <CelebrationRow
              imageWithText={celebrationState?.nullState}
              loadableType={loadableType}
              infoType="nullState"
              imageType={nullStateImageType}
              onImageTypeChange={type => {
                setNullStateImageType(type);
              }}
              formik={formik}
              imageBucket={imageBucket}
              svgBucket={svgBucket}
            />
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

const CelebrationRow = ({
  imageWithText,
  loadableType,
  infoType,
  imageType,
  onImageTypeChange,
  formik,
  svgBucket,
  imageBucket,
}: {
  imageWithText: ImageWithText;
  loadableType: LoadableType;
  infoType: InfoType;
  imageType: "svg" | "image";
  onImageTypeChange: (imageType: "svg" | "image") => void;
  formik: any;
  svgBucket: firebase.storage.Reference[];
  imageBucket: firebase.storage.Reference[];
}) => {
  const [imagePreviewURL, setImagePreviewURL] = useState("");
  const fetchImagePreviewURL = (imageFilename: string) => {
    imageBucket.find(image => image.name === imageFilename)?.getDownloadURL().then((url => setImagePreviewURL(url))).catch(err => console.error(err));
  };

  useEffect(() => {
    const imageFilename = formik.values?.[loadableType]?.[infoType]?.imageFilename;

    if (imageType === "image" && imageFilename) {
      fetchImagePreviewURL(imageFilename);
    }
  }, []);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <FieldHeader
        title="Image Type"
        tooltip="tempoACT3 and beyond use images instead of SVGs. Please use images going forward."
      />
      <Select
        options={[
          { label: "Image", value: "image" },
          { label: "SVG", value: "svg" },
        ]}
        value={{
          label: imageType === "svg" ? "SVG" : "Image",
          value: imageType,
        }}
        onChange={(option) => {
          onImageTypeChange(option.value as "svg" | "image");

          if (option.value === 'svg') {
            formik.setFieldValue(
              `${loadableType}.${infoType}.imageFilename`,
              null
            );
          } else if (option.value === 'image') {
            formik.setFieldValue(
              `${loadableType}.${infoType}.svg`,
              null
            );
          }
        }}
      />
      {imageType === "svg" && (
        <>
          <Form.Label>Svg</Form.Label>
          <Select
            name={`${loadableType}.${infoType}.svg`}
            options={svgBucket.map((item) => ({
              label: item.name,
              value: item.name,
            }))}
            value={{ label: imageWithText?.svg, value: imageWithText?.svg }}
            onChange={(option) => {
              formik.setFieldValue(
                `${loadableType}.${infoType}.svg`,
                option["value"]
              );
            }}
            placeholder={"Svg name"}
          />
        </>
      )}
      {imageType === "image" && (
        <>
          <Form.Label>Image File Name</Form.Label>
          <Select
            name={`${loadableType}.${infoType}.imageFilename`}
            options={imageBucket.map((item) => ({
              label: item.name,
              value: item.name,
            }))}
            value={{ label: imageWithText?.imageFilename, value: imageWithText?.imageFilename }}
            onChange={(option) => {
              formik.setFieldValue(
                `${loadableType}.${infoType}.imageFilename`,
                option["value"]
              );
              setImagePreviewURL("");
              imageBucket.find(image => image.name === option["value"])?.getDownloadURL().then((url => setImagePreviewURL(url))).catch(err => console.error(err));
            }}
            placeholder={"Image File Name"}
          />
          <Form.Label>Image Style</Form.Label>
          <Select
            name={`${loadableType}.${infoType}.imageStyle`}
            options={[
              { label: "vignette", value: "vignette" },
              { label: "Default", value: "" },
            ]}
            value={{
              label: imageWithText?.imageStyle ? imageWithText?.imageStyle : "Default",
              value: imageWithText?.imageStyle ? imageWithText?.imageStyle : ""
            }}
            onChange={(option) => {
              formik.setFieldValue(
                `${loadableType}.${infoType}.imageStyle`,
                option["value"]
              );
            }}
            placeholder={"Image style"}
          />
          {imagePreviewURL && <>
            <Form.Label>Image Preview</Form.Label>
            <img src={imagePreviewURL} style={{ maxWidth: 400 }} />
          </>}
        </>
      )}
      <Form.Label>Text</Form.Label>
      <Form.Control
        as="textarea"
        rows={3}
        name={`${loadableType}.${infoType}.text`}
        value={imageWithText?.text}
        onChange={formik.handleChange}
        placeholder={"Celebration Text"}
      />
    </div>
  );
};
