import React, { useContext, useState, useEffect } from "react";
import { UserContext } from "../../providers/UserProvider";
import { auth, firestore } from "../../firebase";
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  ButtonGroup,
} from "react-bootstrap";
import JSONTree from "react-json-tree";
import AdminNavbar from "../AdminNavbar";
import { SystemEvent } from "../../Model/SystemEvent";
import { User } from "../../Model/User";

const backup = async (uid) => {
  const userDocRef = firestore.collection("users").doc(uid);
  const backupDocRef = firestore
    .collection("analytics")
    .doc("backups")
    .collection("users")
    .doc(uid);

  const usersDoc = await userDocRef.get();
  if (!usersDoc.exists) return;

  await backupDocRef.set(usersDoc.data());
  await backupCollection(
    userDocRef.collection("programs"),
    backupDocRef.collection("programs")
  );
  await backupCollection(
    userDocRef.collection("appUsage"),
    backupDocRef.collection("appUsage")
  );
  await backupCollection(
    userDocRef.collection("devices"),
    backupDocRef.collection("devices")
  );
  await backupCollection(
    userDocRef.collection("activitySummaries"),
    backupDocRef.collection("activitySummaries")
  );

  const activeProgram = usersDoc.data().activeProgram;
  const programDocRef = userDocRef.collection("programs").doc(activeProgram);
  const backupProgramDocRef = backupDocRef
    .collection("programs")
    .doc(activeProgram);

  await backupCollection(
    programDocRef.collection("journalEntries"),
    backupProgramDocRef.collection("journalEntries")
  );
  await backupCollection(
    programDocRef.collection("sessions"),
    backupProgramDocRef.collection("sessions")
  );
  await backupCollection(
    programDocRef.collection("tasks"),
    backupProgramDocRef.collection("tasks")
  );
};

const backupCollection = async (collectionRef, backupCollectionRef) => {
  const queryResults = await collectionRef.get();
  if (queryResults.docs.length < 1) {
    return;
  }

  const batch = firestore.batch();

  queryResults.docs.forEach((doc) => {
    batch.set(backupCollectionRef.doc(doc.id), doc.data());
  });

  await batch.commit();
};
const SurveySync = () => {
  const user = useContext(UserContext);

  // const token = 'HjJ5zaI8pD8lQU81kaLScaWJ9QpBOFrXEEey8JT1';
  // const username = 'allison@swingtherapeutics.com/token';
  // const startUrl = 'https://swingtx.zendesk.com/api/v2/tickets.json';
  // let headers = new Headers();
  // headers.set('Authorization', 'bearer ' + token);
  //
  // fetch(startUrl, {method: 'GET', headers: headers})
  //     .then(response => console.log('response ', response))
  const [responses, setResponses] = useState("");
  const [surveyID, setSurveyID] = useState("291284492");
  const [surveyName, setSurveyName] = useState("baseline");

  let surveyPages = "";

  const jsonTheme = {
    scheme: "chalk",
    author: "chris kempson (http://chriskempson.com)",
    base00: "transparent",
    base01: "#333333",
    base02: "#555555",
    base03: "#777777",
    base04: "#999999",
    base05: "#bbbbbb",
    base06: "#dddddd",
    base07: "#ffffff",
    base08: "#ff4136",
    base09: "#ff851b",
    base0A: "#ffdc00",
    base0B: "#2ecc40",
    base0C: "#7fdbff",
    base0D: "#0074d9",
    base0E: "#b10dc9",
    base0F: "#85144b",
  };

  const updateSurveyEmailDocs = async () => {
    let emails = await firestore
      .collection("analytics")
      .doc("SurveyMonkeyData")
      .collection("byEmail")
      .get();
    let e = emails.docs.map(async (e) => {
      let u = await User.userWithEmail(e.id);
      let userExists = u ? true : false;
      return await firestore
        .collection("analytics")
        .doc("SurveyMonkeyData")
        .collection("byEmail")
        .doc(e.id)
        .update({ userExists: userExists });
    });
    console.log("updating survey emails", e);
  };

  const updateSurveys = async () => {
    //  surveyID = 288631042;   //FIQR

    const token =
    "bearer IsDKHHgBqYrQWHP2UNbiItik1si.cgRWIF.8.zjgr6SWGWUExSq.9GthlPKhqMpcp-1MpcbGGLq-emLW9Bhm7QeSHq4PmV4r0DPBji7x4lG7UcjRAxzV-F97ZUVhIp48";
      // "bearer BjVjlV9MiVBgfe1XpS2xPfsINXQu4Jyb8RZdWp2KEwU8Z8f8YGPSs9At3EjoW.8rGpgjwq3V2uR3GneWesoLJX-X4xHwkdVlJm8qn5bEHHryAi.TmpBPBrkjaWMfEGVS";
      // BjVjlV9MiVBgfe1XpS2xPZgz7MNYbrot4vupV12AUtuk0D5ifqmbB.gfKaVgA9GUexbqSfJkSwQdcFgP9u528f8Qhmp3XRFkkKLV5agQfemAGY79R-.eZoh8NQb0kMVq";
      const username = "allison@swingtherapeutics.com/token";

    const startUrl =
      "https://api.surveymonkey.net/v3/surveys/" +
      surveyID +
      "/responses/bulk?per_page=100&sort_order=DESC";
    const descURL =
      "https://api.surveymonkey.net/v3/surveys/" + surveyID + "/details";

    // const startUrl = "https://api.surveymonkey.net/v3/surveys/288631042/responses/bulk?";
    // const descURL = "https://api.surveymonkey.net/v3/surveys/288631042/details";
    let cookie =
      "ep201=1TvFyb0gTAn4wet6C9MPa3wil7U=; ep202=XhF7X1aCcZg9WCWKtm5hWFiRefI=;" +
      " ep203=ErhtnLUu4BoNXv5rCAmIyqtMXRQ=; attr_multitouch=kkTVafK2ChUNzDjykPvZp7VfDgA=";
    let headers = new Headers();
    headers.set("Authorization", token);
    headers.set("Cookie", cookie);
    headers.set("Content-Type", "application/json");

    let surveyR = await fetch(descURL, { method: "GET", headers: headers });
    let surveyJ = await surveyR.json();
    surveyPages = surveyJ.pages ? surveyJ.pages : "";

    fetch(startUrl, { method: "GET", headers: headers }).then((response) => {
      response.json().then((data) => {
        processResponses(data);
      });
    });
  };

  const processResponses = async (raw) => {
    let data = raw.data;
    let resps = data.map((r) => processResponse(r));

    let eg = new Date();
    const batch = firestore.batch();

    resps.forEach((r) => {
      if (!r.email || !r.id) {
        return;
      }
      const ref = firestore
        .collection("analytics")
        .doc("SurveyMonkeyData")
        .collection("byEmail")
        .doc(r.email)
        .collection("surveys")
        .doc(r.id);
      const emailRef = firestore
        .collection("analytics")
        .doc("SurveyMonkeyData")
        .collection("byEmail")
        .doc(r.email.toLowerCase());
      let emailUpdate = {
        email: r.email,
        lastUpdated: new Date(),
        lastSurveySynced: surveyName,
      };
      emailUpdate[surveyName] = r.modified ? r.modified : new Date();
      emailUpdate[surveyName + "_status"] = r.status ? r.status : "";
      batch.set(emailRef, emailUpdate, { merge: true });
      batch.set(ref, r);
    });
    await batch.commit();
    SystemEvent.fireEvent(
      "Survey Sync",
      "surveyMonkey",
      "stored " + resps.length + " responses to survey " + surveyName,
      eg,
      {
        surveyName: surveyName,
        surveyID: surveyID,
      }
    );

    setResponses(resps);
    updateSurveyEmailDocs();
  };

  const processResponse = (resp) => {
    let pagesAnswers = [];

    let pageQuestions = resp.pages.map((p) => processPage(p, pagesAnswers));
    console.log("my responses ", resp);

    let processedResponse = {
      id: resp.id,
      email: resp.metadata?.contact?.email?.value,
      status: resp.response_status ? resp.response_status : null,
      surveyID: resp.survey_id ? resp.survey_id : null,
      analyzeURL: resp.analyze_url ? resp.analyze_url : null,
      created: resp.date_created ? resp.date_created : null,
      modified: resp.date_modified ? resp.date_modified : null,
      answers: pagesAnswers ? pagesAnswers : null,
      surveyName: surveyName,
    };

    if (!processedResponse.email) {
      if (processedResponse.answers?.length > 0) {
        let resp = processedResponse.answers.find((r) => {
          if (r.rowType === "email") return r;
        });

        processedResponse.email = resp.text;
      }
    }

    return processedResponse;
  };

  const processPage = (page, pageAnswers) => {
    let surveyPage = surveyPages.find((p) => p.id === page.id);
    if (!surveyPage) {
      return "";
    }

    let qs = page.questions.map((responseQuestion) => {
      let surveyQuestion = surveyPage.questions.find(
        (q) => q.id === responseQuestion.id
      );
      let answers = responseQuestion.answers.map((a) => {
        let row = surveyQuestion.answers?.rows?.find((r) => {
          return r.id === a.row_id;
        });
        let ans = {
          ...a,
        };
        if (surveyQuestion.position)
          ans.question_position = surveyQuestion.position;
        if (surveyQuestion.headings[0].heading)
          ans.question_heading = surveyQuestion.headings[0].heading;

        if (surveyPage.position) ans.page_position = surveyPage.position;
        if (row) {
          ans.position = row.position ? row.position : null;
          ans.prompt = row.text ? row.text : null;
          ans.rowType = row.type ? row.type : null;
        }
        if (a.choice_id) {
          let choice = surveyQuestion.answers?.choices?.find((r) => {
            return r.id === a.choice_id;
          });
          if (choice) {
            ans.choice_position = choice.position ? choice.position : null;
            ans.answer = choice.text ? choice.text : null;
            ans.weight = choice.weight ? choice.weight : null;
          }
        }

        pageAnswers.push(ans);
        return ans;
      });
      let q = {
        id: responseQuestion.id,
        position: surveyQuestion.position,
        family: surveyQuestion.family,
        subtype: surveyQuestion.subtype,
        answers: answers,
      };
      return q;
    });

    return {
      id: page.id,
      position: surveyPage.position,
      title: surveyPage.title,
      question_count: surveyPage.question_count,
      questions: qs,
    };
  };

  return (
    <>
      <AdminNavbar></AdminNavbar>

      <Container>
        <br />
        <h3> Put Data from Survey {surveyName} into </h3>
        /analytics/SurveyMonkeyData/byEmail/$email/<strong>{surveyName}</strong>
        /$surveyResponseID
        <Row>
          <Col md={8}>
            {" "}
            <ButtonGroup>
              <Button
                variant="warning"
                onClick={() => {
                  setSurveyName("baseline");
                  setSurveyID("291284492");
                }}
              >
                Baseline
              </Button>
              <Button
                variant="dark"
                onClick={() => {
                  setSurveyName("screening");
                  setSurveyID("290884559");
                }}
              >
                Screening Questionnaire
              </Button>
              <Button
                variant="info"
                onClick={() => {
                  setSurveyName("weekly_ACT");
                  setSurveyID("288631042");
                }}
              >
                Weekly ACT
              </Button>
              
              <Button
                variant="primary"
                onClick={() => {
                  setSurveyName("weekly_ACT+");
                  setSurveyID("299917493");
                }}
              >
                Weekly ACT+
              </Button>
{/* 299961761 */}
              <Button
                variant="info"
                onClick={() => {
                  setSurveyName("weekly_ST");
                  setSurveyID("291781338");
                }}
              >
                Weekly ST
              </Button>

              <Button
                variant="primary"
                onClick={() => {
                  setSurveyName("weekly_ST+");
                  setSurveyID("299961761");
                }}
              >
                Weekly ST +
              </Button>
            </ButtonGroup>
          </Col>
        </Row>
        <Row style={{paddingTop: "10px"}}>
          <Form>
            <Row>
              <Col>
                <Form.Control
                  value={surveyName}
                  placeholder="Survey Name"
                  onChange={(e) => {
                    setSurveyName(e.target.value.trim());
                  }}
                />
              </Col>
              <Col>
                <Form.Control
                  value={surveyID}
                  placeholder="Survey ID"
                  onChange={(e) => {
                    setSurveyID(e.target.value.trim());
                  }}
                />
              </Col>
              <Col>
                <Button
                variant="success"
                  onClick={() => {
                    updateSurveys();
                    // testAU();
                    //backup('BijcYOmrZAMi3NJxj0mFGnO2sPb2');
                  }}
                >
                  Update Surveys
                </Button>
              </Col>
            </Row>
          </Form>
        </Row>
        <Row>
          <Col>
            <JSONTree data={responses} theme={jsonTheme} invertTheme={false} />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default SurveySync;
