import React, { useEffect, useState } from "react";
import { useCollection, useCollectionData } from "react-firebase-hooks/firestore";
import LoadingScreen from "../LoadingScreen";
import { Program } from "./Program";
import { firestore, functions } from "firebase";
import AdminNavbar from "../AdminNavbar";
import { Link, useHistory } from "react-router-dom";

import {
  Button,
  Col,
  Container,
  Form,
  FormControl,
  InputGroup,
  Jumbotron,
  Modal,
  Row,
  Table,
} from "react-bootstrap";
import useRole from "../../UseRole";
import { ProgramEditor } from "./Programs/ProgramEditor";
import { isNil } from "lodash";
import { ProgramCloneModal } from "./Programs/ProgramCloneModal";

const ProgramsList = () => {
  const isAdmin = useRole("admin");
  const [filter, setFilter] = useState("");
  const [newProgramName, setNewProgramName] = useState();

  const [showEdit, setShowEdit] = useState(false);
  const [showCloneModal, setShowCloneModal] = useState(false);
  const [programToEdit, setProgramToEdit] = useState<Program>();

  let history = useHistory();

  const [programs, setPrograms] = useState<Program[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = firestore()
      .collection('programs')
      .onSnapshot(
        { includeMetadataChanges: true }, 
        collection => {
        if (collection.docs.length > 0) {
          setPrograms(collection.docs.map(doc => Program.fromFirestore(doc)))
        }
        setIsLoading(false);
      })
    return unsubscribe;
  },[])

  const getTableRow = (program: Program) => {
    if (filter?.length > 0 && !program.id.includes(filter)) {
      return <></>
    }

    return (
      <tr key={program.id}>
        <td>
          <Link to={"/programs/" + program.key}>{program.id} </Link>
        </td>
        <td>{program.name}</td>
        <td>{program.version}</td>
        <td>{program.contentTypeKey}</td>
        <td>{program.updated?.toLocaleString()}</td>
        {isAdmin &&
          <td>
          <Button
                variant="outline-primary"
                style={{ margin: 5}}
                onClick={() => {
                  setProgramToEdit(program);
                  setShowEdit(true);
                }}
              >
                Edit
          </Button>
          <Button
                variant="outline-info"
                style={{ margin: 5}}
                onClick={() => {
                  setProgramToEdit(program);
                  setShowCloneModal(true);
                }}
              >
                Clone
          </Button>
          <Button
                variant="outline-danger"
                style={{ margin: 5}}
                onClick={() => {
                  if (window.confirm(`Are you sure you want to delete ${program.id}?`)) {
                    deleteProgram(program.id)
                  }
                }}
              >
                Delete
          </Button>
          </td>
        }
      </tr>
    );
  };
  const makeProgram = async () => {
    setShowEdit(false);
    if (!newProgramName) {
      console.log("no program name set");
      return;
    }
    let prog = await firestore().collection("programs").doc(newProgramName).get();
    if (prog.exists) {
      console.log("program already existed", prog);
      return;
    } else {
      let np = Program.byKey(newProgramName);
      console.log("creating a new program", np);
      await np.persist();
      setNewProgramName(null);
      history.push("/programs/" + np.key);
    }
  };

  const deleteProgram = (programId: string) => {
    if (isNil(programId)) {
      window.alert('Error, invalid ID')
      setIsLoading(false);
      return;
    }

    const deleteFn = functions().httpsCallable("FS_recursiveDelete");
    const path = `programs/${programId}`
    setIsLoading(true);
    deleteFn({path})
      .then(() => {
        setIsLoading(false)
          window.alert(`Success: Program ${programId} deleted`);
      })
      .catch(function (err: any) {
        window.alert("Failed deletion " + err);
        setIsLoading(false);
      });
  };

  return (
    <>
      <AdminNavbar></AdminNavbar>
      <Jumbotron>
        <Container fluid={true}>
          <Row>
            <Col>
              <h1>Programs</h1>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form inline>
                <InputGroup className="">
                  <FormControl
                    id="search"
                    placeholder="Search"
                    onChange={(e) => {
                      setFilter(e.target.value.trim());
                    }}
                  />
                </InputGroup>
              </Form>
            </Col>
            {isAdmin && (
              <Col xs={"auto"}>
                <Button
                  onClick={() => {
                    setShowEdit(true);
                  }}
                >
                  New Program
                </Button>
              </Col>
            )}
          </Row>
        </Container>
      </Jumbotron>
      <Container fluid={true} style={{ backgroundColor: "white" }}>
        {isLoading ? (
          <>
            <strong>Programs Loading</strong>
            <br></br>
            <LoadingScreen></LoadingScreen>
          </>
        ) : (
          <Row>
            {programs.length === 0 && (
              <>
                <strong>No Programs Founnd</strong>
                <br></br>
              </>
            )}
            {programs && (
              <Col xs={12}>
                <Table>
                  <thead key="programs">
                    <tr>
                      <th>ID</th>
                      <th>Name</th>
                      <th>Version</th>
                      <th>Type</th>
                      <th>Updated</th>
                      {isAdmin && <th>Actions</th>}
                    </tr>
                  </thead>
                  <tbody>{programs.map((u: Program) => getTableRow(u))}</tbody>
                </Table>
              </Col>
            )}
          </Row>
        )}
      </Container>
      <ProgramEditor
        show={showEdit}
        close={() => {
          setShowEdit(false);
          setProgramToEdit(undefined);
        }}
        programToEdit={programToEdit}
        programs={programs}
      />
      <ProgramCloneModal programKey={programToEdit?.id} show={showCloneModal} close={() => {
        setProgramToEdit(null);
        setShowCloneModal(false);
      }} />
    </>
  );
};

export default ProgramsList;
