import { GatingBucket, IGatingRules } from '@swing-therapeutics/swingcore/dist/models/Session';
import { firestore } from 'firebase';
import React, { useEffect, useMemo, useState } from 'react';
import { Button, Col, Container, Row, Table } from "react-bootstrap";
import GatingRuleModal from './GatingRuleModal';

interface Props {
  programKey: string;
}

interface State {
  editRules: firestore.QueryDocumentSnapshot | 'new';
  showModal: boolean;
}

const defaultState: State = { editRules: 'new', showModal: false };

const GatingList: React.FC<Props> = ({ programKey }) => {
  const [modalState, setModalState] = useState<State>({ ...defaultState })
  const [gatingRules, setGatingRules] = useState<firestore.QueryDocumentSnapshot[] | null>(null);

  useEffect(() => {
    let unSub = () => { };
    (async () => {
      unSub = firestore().collection(`programs/${programKey}/gatingRules`).onSnapshot((snap) => {
        setGatingRules(snap.docs);
      })
    })()
    return unSub;
  }, [programKey])


  return (
    <Container style={{ gap: '30px', display: 'flex', flexDirection: 'column' }}>
      <h4>Session gating</h4>
      <Row >
        <Col style={{ justifyContent: 'flex-end', display: 'flex' }}>
          <Button
            variant="outline-primary"
            onClick={() => setModalState({ showModal: true, editRules: 'new' })}
          >
            Add new rule
          </Button>
        </Col>
      </Row>
      <Row>
        <Col>
          <p>A B C D refers to the # of sessions a user can complete in a day while in that bucket.</p>
          <p>'Default gating rules' applies to all cohorts not defined here.</p>
          <Table className="table-striped table-center-rows">
            <thead>
              <tr>
                <th>Cohort</th>
                <th>Target</th>
                <th>Margin</th>
                <th>A</th>
                <th>B</th>
                <th>C</th>
                <th>D</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {gatingRules?.map((gatingRulesSnap) => (
                <GatingRulesRow
                  key={gatingRulesSnap.id}
                  snap={gatingRulesSnap}
                  onEditClick={() => setModalState({ showModal: true, editRules: gatingRulesSnap })}
                />
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>
      <GatingRuleModal
        show={modalState.showModal}
        editRules={modalState.editRules}
        onHide={() => setModalState({ ...defaultState })}
        programKey={programKey}
      />
    </Container>
  )
}

export default GatingList;

interface RowProps {
  snap: firestore.QueryDocumentSnapshot;
  onEditClick: () => void;
}

const GatingRulesRow: React.FC<RowProps> = ({ snap, onEditClick }) => {
  const data = useMemo(() => {
    return snap.data() as IGatingRules;
  }, [snap])

  return (
    <tr>
      <td>{snap.id === 'defaultRules' ? 'Default gating rules' : snap.id}</td>
      <td>{data.target}</td>
      <td>{data.margin}</td>
      <td>{data.sessionsAllowed[GatingBucket.A]}</td>
      <td>{data.sessionsAllowed[GatingBucket.B]}</td>
      <td>{data.sessionsAllowed[GatingBucket.C]}</td>
      <td>{data.sessionsAllowed[GatingBucket.D]}</td>
      <td>
        <Button onClick={onEditClick}>
          Edit
        </Button>
      </td>
    </tr>
  )
}
