import type { JournalQuestion } from "@swing-therapeutics/swingcore/dist/models/journals/JournalInterfaces";
import type { PacingQuestion } from "@swing-therapeutics/swingcore/dist/models/journals/PacingJournalInterfaces";
import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import JSONTree from "react-json-tree";

interface UnknownQuestionProps {
  question: JournalQuestion | PacingQuestion;
}

export const UnknownQuestion = ({ question }: UnknownQuestionProps) => {
  return (
    <Row>
      <Form.Group as={Col} md={12} lg={6}>
        <Form.Label>Type</Form.Label>
        <Form.Control type="text" name="type" value={question.type} disabled />
      </Form.Group>
      <Form.Group as={Col} md={12} lg={6}>
        <Form.Label>Key</Form.Label>
        <Form.Control type="text" name="key" value={question.key} disabled />
      </Form.Group>
      <Col md={12}>
        <p>Editing is not yet supported for this question type.</p>
      </Col>
      <Col md={12}>
        <JSONTree data={question} />
      </Col>
    </Row>
  );
};
