import React, {useState} from "react";
import {useCollection} from "react-firebase-hooks/firestore";
import LoadingScreen from "../LoadingScreen";

import {firestore} from "../../firebase";
import AdminNavbar from "../AdminNavbar";
import {useHistory} from "react-router-dom";

import {
    Button, Col, Container, Form, FormControl, InputGroup, Jumbotron, Row, Table,
} from "react-bootstrap";
import {Registration} from "../../Model/Registration";
import JSONTree from "react-json-tree";

const NotificationTypeRow = (props) => {
    const {docRef} = props;

    let data = docRef.data();

    return (<tr key={data.id}>
        <td>{data.name}</td>

        <td>{data.description}</td>

    </tr>);

}

const NotificationsTypeList = (props) => {
    const [filter, setFilter] = useState("");
    const {deviceID, uid} = props;

    let history = useHistory();
    let query =


        firestore.collection("tempest/notifications/types");


    const [registrations, registrationsLoading, registrationError,] = useCollection(query, {
        snapshotListenOptions: {includeMetadataChanges: true},
    });


    return (<>
        <Container fluid={true} style={{backgroundColor: "white"}}>
            <Row>
                {registrationError && (<>
                    <strong>Error: {JSON.stringify(registrationError)}</strong>
                    <br></br>
                </>)}
                {registrationsLoading && (<>
                    <strong>
                        Subjects Loading: {JSON.stringify(registrationError)}
                    </strong>
                    <br></br>
                    <LoadingScreen></LoadingScreen>
                </>)}
                {registrations && (<Col xs={12}>
                    <Table>
                        <thead key="systemEventsTable">
                        <tr>

                            <th>Name</th>
                            <th>Description</th>
                        </tr>
                        </thead>
                        <tbody>{registrations.docs.map((u) => <NotificationTypeRow
                            docRef={u}></NotificationTypeRow>)}</tbody>
                    </Table>
                </Col>)}
            </Row>
        </Container>
    </>);
};

export default NotificationsTypeList;
