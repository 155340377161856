import User from "./Usert";
import {firestore} from "../firebase";

const sendToUsersDevices = async (user: User, eventGroup = "", messages: any[]) => {
    const batch = firestore.batch();
    const dev = await firestore.collection("users")
        .doc(user.uid)
        .collection("devices")
        .get().catch((e) => {
            console.warn(eventGroup + "unable to find devices for user", {error: e, user: user});
            return null;
        });
    if (!dev) {
        return null;
    }
    console.log("processing these devices for " + user.email ? user.email : "the user", dev.docs);
    const tokenIDs = dev.docs.map((e) => {
        return {FCMToken: e.data().FCMToken, deviceID: e.id};
    });
    const msg = messages[Math.floor(Math.random() * messages.length)];
    const body = msg.body;
    const title = msg.title;
    console.log(`${eventGroup} ${tokenIDs.length} devices owned by ${user.email} are about to get this message:  ***********************Title:  ${title}  *******************Body: ${body}  `, tokenIDs);
    tokenIDs.forEach((token) => {
        const mes = {
            title: title, body: body, FCMToken: token.FCMToken, deviceID: token.deviceID, uid: user.uid, email: user.email ? user.email : "", subjectID: user.subjectID ? user.subjectID : "", triggerMethod: "nudge", status: "scheduled", scheduledFor: new Date(),
        };
        const msgRef = firestore.collection("notifications").doc();
        batch.set(msgRef, mes, {merge: true});
    });
    await batch
        .commit()
        .then((res) => {
            console.log(eventGroup + "Successfully committed batch", res);
        })
        .catch((e) => {
            console.warn(eventGroup + "failed to commit batch", e);
            return null;
        });
    return true;
};
export {sendToUsersDevices}
