import { firestore } from "firebase";
import { isNil } from "lodash";
import { useEffect, useState } from "react";
import { useCollection, useCollectionOnce, useDocument, useDocumentOnce } from "react-firebase-hooks/firestore";

type CustomClass <T> = {
  fromFirestore: (snapshot: firestore.DocumentSnapshot) => T 
}

type CollectionReturn <T> = [ 
  T[],
  boolean,
  Error | undefined,
]

type DocumentReturn <T> = [
  T,
  boolean,
  Error | undefined,
]

export const useCollectionClassData = <T,> (customClass: CustomClass<T>, collectionQuery: firestore.Query, ): CollectionReturn<T> => {
  const [collection, loading, error] = useCollection(collectionQuery);
  const [_collection, setCollection] = useState<T[]>([] as T[]);
  const [_loading, setLoading] = useState(loading);

  useEffect(() => {
    if (!isNil(collection)) {
      setCollection(collection.docs.map((doc: any) => customClass.fromFirestore(doc)))
    }
  }, [collection])

  useEffect(() => {
    setLoading(loading)
  },[loading])

  useEffect(() => {
    if (error) {
      console.error(error);
    }
  }, [error])

  return [_collection, _loading, error];
}

export const useCollectionClassDataOnce = <T,> (customClass: CustomClass<T>, collectionQuery: firestore.Query, ): CollectionReturn<T> => {
  const [collection, loading, error] = useCollectionOnce(collectionQuery);
  const [_collection, setCollection] = useState<T[]>([] as T[]);
  const [_loading, setLoading] = useState(loading);

  useEffect(() => {
    if (!isNil(collection)) {
      setCollection(collection.docs.map((doc: any) => customClass.fromFirestore(doc)))
    }
  }, [collection])

  useEffect(() => {
    setLoading(loading)
  },[loading])

  return [_collection, _loading, error];
}


export const useDocumentClassData = <T,> (customClass: CustomClass<T>, docQuery: firestore.DocumentReference): DocumentReturn<T> => {
  const [document, loading, error] = useDocument(docQuery);
  const [_document, setDocument] = useState<T | undefined>();
  const [_loading, setLoading] = useState(loading);
  
  useEffect(() => {
    if (!isNil(document)) {
      setDocument(customClass.fromFirestore(document))
    }
  }, [document])

  useEffect(() => {
    setLoading(loading)
  },[loading])

  return [_document, _loading, error];
}

export const useDocumentClassDataOnce = <T,> (customClass: CustomClass<T>, doc: firestore.DocumentReference): DocumentReturn<T> => {
  const [data, loading, error] = useDocumentOnce(doc);
  const [_document, setDocument] = useState<T | undefined>();
  const [_loading, setLoading] = useState(loading);
  
  useEffect(() => {
    if (!isNil(data)) {
      setDocument(customClass.fromFirestore(data as firestore.DocumentSnapshot))
    }
  }, [data])

  useEffect(() => {
    setLoading(loading)
  },[loading])

  return [_document, _loading, error];
}
