import React, {useState} from "react";
import {useCollection} from "react-firebase-hooks/firestore";
import LoadingScreen from "../LoadingScreen";

import {firestore} from "../../firebase";
import AdminNavbar from "../AdminNavbar";
import {useHistory} from "react-router-dom";

import {
    Button, Col, Container, Form, FormControl, InputGroup, Jumbotron, Row, Table,
} from "react-bootstrap";

const ReportsList = () => {
    const [filter, setFilter] = useState("");

    let history = useHistory();
    const query = firestore.collection("analytics/reports/reportPages");
    const [reportPages, reportPagesLoading, reportPagesError] = useCollection(query, {
        snapshotListenOptions: {includeMetadataChanges: true},
    });

    const getTableRow = (reportPage) => {
        const data = reportPage.data();
        if (filter && !data.name.toLowerCase().includes(filter.toLowerCase())) {
            return <></>;
        }

        return (<tr onClick={(c) => {
            history.push('/genericReports/' + reportPage.id)
        }}>
            <td>{data.name}</td>

            <td>{data.description}</td>
        </tr>);
    };

    return (<>
        <AdminNavbar></AdminNavbar>
        <Jumbotron>
            <Container fluid={true}>
                <Row>
                    <Col>
                        <h1>System Events</h1>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form inline>
                            <InputGroup className="">
                                <FormControl
                                    id="search"
                                    placeholder="Search"
                                    onChange={(e) => {
                                        setFilter(e.target.value.trim());
                                    }}
                                />
                            </InputGroup>
                        </Form>
                    </Col>
                    <Col xs="auto">
                        <Form inline>
                            <InputGroup className="">
                                <Button
                                    onClick={() => {
                                        history.push("/subjects/new");
                                    }}
                                >
                                    Create
                                </Button>
                            </InputGroup>
                        </Form>
                    </Col>
                </Row>
            </Container>
        </Jumbotron>
        <Container style={{backgroundColor: "white"}}>
            <Row>
                {reportPagesError && (<>
                    <strong>Error: {JSON.stringify(reportPagesError)}</strong>
                    <br></br>
                </>)}
                {reportPagesLoading && (<>
                    <strong> Loading: {JSON.stringify(reportPagesLoading)}</strong>
                    <br></br>
                    <LoadingScreen></LoadingScreen>
                </>)}
                {reportPages && (<Col xs={12}>
                    <Table>
                        <thead key="reports List">
                        <tr>
                            <th>Name</th>

                            <th>Description</th>
                        </tr>
                        </thead>
                        <tbody>{reportPages.docs.map((u) => getTableRow(u))}</tbody>
                    </Table>
                </Col>)}
            </Row>
        </Container>
    </>);
};

export default ReportsList;
