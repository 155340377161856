import React, { useEffect, useState } from "react";
import { Button, Col, Row, Container, Form, FormControl } from "react-bootstrap";
import { useHistory, useLocation } from "react-router-dom";
import moment from "moment";
import AdminNavbar from "../AdminNavbar";
import usePagination from "../../hooks/usePagination";
import { firestore } from "../../firebase";

const Prescriptions = () => {
  const [sites, setSites] = useState([]);
  useEffect(() => {
    (async () => {
      const sitesSnap = await firestore.collection(`medrio/sites/available`).get();
      const sites = [];
      for (const siteSnap of sitesSnap.docs) {
        const siteData = siteSnap.data();
        sites.push({
          label: siteData.siteName ?? siteSnap.id,
          value: siteSnap.id,
        })
      }

      setSites(sites);
    })()
  }, [])

  const [isTest, setIsTest] = useState(false);
  
  let { search } = useLocation();
  const pageNumber = new URLSearchParams(search).get("page");
  const searchTerms = [
    "patient.firstName",
    "patient.lastName",
    "patient.email",
    "site"
  ];
  let history = useHistory();
  const {
    visibleDocs,
    page,
    filter,
    setFilter,
    searchFunction,
    PaginationButtons,
    searchBoolean,
  } = usePagination(
    "prescriptions",
    25,
    "site",
    "desc",
    searchTerms,
    Number(pageNumber)
  );

  useEffect(() => {
    //@ts-ignore
    if (page.current !== pageNumber) {
      history.replace({
        pathname: "/prescriptions",
        search: "?page=" + page.current,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, page.current, pageNumber]);

  return (
    <>
      <AdminNavbar/>
      <h1 style={{textAlign: "center"}}>Prescriptions</h1>
      <Container fluid={true}>
        <Col xs={12}>
          <Form inline style={{ paddingBottom: 10 }}>
            <Row>
              <Col>
                <Form.Label>Search</Form.Label>
                <FormControl
                  id="search"
                  placeholder="Search"
                  value={filter}
                  onChange={(e) => {
                    setFilter(e.target.value);
                    searchFunction(e.target.value.trim());
                  }}
                />
              </Col>

              <Col>
                <Form.Label>Site</Form.Label>
                <Form.Control
                  as="select"
                  onChange={(e) => {
                    searchFunction(e.target.value.trim(), 'site');
                  }}
                >
                  <option></option>
                  {sites.map((studies) => (
                    <option value={studies.value} key={studies.value}>
                      {studies.label}
                    </option>
                  ))}
                </Form.Control>
              </Col>

              <Col>
                <Form.Check
                  checked={isTest}
                  label={'is test'}
                  onChange={(e) => {
                    setIsTest(e.target.checked);
                    searchBoolean('isTest', e.target.checked);
                  }}
                />
              </Col>
            </Row>
          </Form>
        </Col>

        {visibleDocs && (
          <Col xs={12}>
            <table className="table">
              <thead>
                <tr>
                  <th>Patient Name</th>
                  <th>Prescriber Name</th>
                  <th>Prescription Status</th>
                  <th>Created By</th>
                  <th>Created At</th>
                  <th>Site</th>
                  <th>Is Test</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {visibleDocs.map((prescription) => (
                  <tr key={prescription?.id}>
                    <td>{prescription?.patient?.name}</td>
                    <td>{prescription?.prescriber?.name}</td>
                    <td>
                      {(prescription?.prescription?.activationDate && (prescription?.status === 'active' || prescription?.status === 'refill')) ? 'active' : 'inactive' }
                    </td>
                    <td>{prescription?.createdBy}</td>
                    <td>{prescription?.createdAt ? moment(prescription?.createdAt.toDate()).format('MM/DD/YY') : ''}</td>
                    <td>{prescription?.site}</td>
                    <td>{prescription?.isTest ? 'true' : 'false'}</td>
                    <td>
                      <Row>
                        {prescription?.id &&
                          <Button className="mr-2" onClick={() => history.push("/prescriptions/" + prescription?.id)}>
                            Edit
                          </Button>
                        }
                        {prescription?.patient?.userId &&
                          <Button className="mr-2" onClick={() => history.push("/users/" + prescription?.patient?.userId)}>
                            Go to User Profile
                          </Button>
                        }
                      </Row>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Col>
        )}
        <PaginationButtons />
      </Container>
    </>
  )
};

export default Prescriptions;