import React from "react";
import { useFormik } from "formik";
import { firestore } from "firebase";
import { Button, Col, Form, Modal } from "react-bootstrap";
import { useCollectionOnce } from "react-firebase-hooks/firestore";
import { JournalModalProps } from "./types";
import { JournalTemplate } from "@swing-therapeutics/swingcore/dist/models/journals/JournalTemplate";

export const JournalCloneModal = ({
  show,
  close,
  programKey,
  journals,
}: JournalModalProps & { journals?: JournalTemplate[] }) => {
  const initialValues: { selectedProgram: string } = {
    selectedProgram: undefined,
  };

  const [programs] = useCollectionOnce(firestore().collection("programs"));

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit: async (values) => {
      try {
        const batch = firestore().batch();
        journals.forEach((journal) => {
          journal.programKey = values.selectedProgram;
          const newJournalDoc = firestore()
            .collection("programs")
            .doc(values.selectedProgram)
            .collection("journalTypes")
            .doc(journal.id);
          batch.set(newJournalDoc, journal.data());
        });
        await batch.commit();
        window.alert("Journals cloned successfully!");
      } catch (error) {
        window.alert(
          "An error occurred while trying to clone the journals. Check the developer console for details.",
        );
        console.error(error);
      } finally {
        close();
      }
    },
  });

  const handleClose = (e?: any) => {
    formik.resetForm();
    close();
  };

  const getProgramOptions = () => {
    if (programs) {
      return programs.docs
        .filter((program) => program.id !== programKey)
        .map((program) => (
          <option key={program.id} value={program.id}>
            {program.id}
          </option>
        ));
    }
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Edit</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Col>
          <p>
            Cloning {journals.length.toString()} {journals.length === 1 ? "journal" : "journals"} to
            this program:
          </p>
          <Form.Group controlId="selectedProgram">
            <Form.Label>Select Program</Form.Label>
            <Form.Control
              as="select"
              value={formik.values.selectedProgram}
              onChange={formik.handleChange}
            >
              <option value={null} selected disabled>
                Choose one playlist...
              </option>
              {getProgramOptions()}
            </Form.Control>
          </Form.Group>
        </Col>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleClose} variant="outline-secondary">
          Close
        </Button>
        <Button
          disabled={!formik.values.selectedProgram}
          onClick={formik.submitForm}
          variant="outline-primary"
        >
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
