import { UserStatus } from "@swing-therapeutics/surveybay/dist/types";
import React, { useCallback, useEffect, useRef, useState } from "react";
import User from "../../../../../../Model/Usert";
import { Button, Col, Row } from "react-bootstrap";
import { Form as FormikForm, Formik, FormikProps } from "formik";
import { FormikTextField } from "../../../../../FormikFields";
import * as Yup from "yup";
import { functions } from "../../../../../../firebase";
import type firebase from "firebase";
import FormikFormSubmitting from "../../../../../FormikFields/FormikFormSubmitting";

interface Props {
  user: User;
}

enum TerminationStatus {
  NOT_TERMINATED,
  TERMINATION_FOLLOWUP,
  TERMINATED,
}

enum TerminationType {
  NOT_SET,
  EARLY_TERMINATION,
  EARLY_TERMINATION_FOLLOWUP,
}

interface TerminationForm {
  terminationReason: string;
  terminationType: TerminationType;
}

const initialTermStatus = (currentStatus: UserStatus) => {
  switch (currentStatus) {
    case UserStatus.EARLY_TERMINATION:
    case UserStatus.EXT_EARLY_TERMINATION:
      return TerminationStatus.TERMINATED;
    case UserStatus.EXT_EARLY_TERMINATION_FOLLOWUP:
    case UserStatus.EARLY_TERMINATION_FOLLOWUP:
      return TerminationStatus.TERMINATION_FOLLOWUP;
    default:
      return TerminationStatus.NOT_TERMINATED;
  }
}

const EarlyTermination: React.FC<Props> = ({ user }) => {
  const [termStatus, setTermStatus] = useState(initialTermStatus(user.workflowStatus));
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const isMounted = useRef(true);

  useEffect(() => {
    isMounted.current = true;
    return () => { isMounted.current = false };
  }, [])

  const setDisappearingMessage = useCallback((msg, error = false) => {
    const setFunc = error ? setError : setMessage;
    setFunc(msg);
    setTimeout(() => {
      isMounted.current && setFunc("");
    }, 5000);
  }, [isMounted])

  return (
    <Row className="mt-2">
      <Formik
        initialValues={{
          terminationReason: user.earlyTermInfo?.reason ?? "",
          // Will be set by the button that is pressed
          terminationType: TerminationType.NOT_SET,
        }}
        enableReinitialize={true}
        onSubmit={async (values, actions) => {
          console.log(values);
          const ssTerminateUser = functions.httpsCallable('ssTerminateUser');
          try {
            let result: firebase.functions.HttpsCallableResult;
            switch (values.terminationType) {
              case TerminationType.EARLY_TERMINATION:
                result = await ssTerminateUser({ userUID: user.uid, reason: values.terminationReason, followup: false });
                if (result.data.status !== 'SUCCESS') {
                  console.error(result.data);
                  throw new Error("Error registering user to early termination program, check console");
                }
                setTermStatus(TerminationStatus.TERMINATED);
                break;
              case TerminationType.EARLY_TERMINATION_FOLLOWUP:
                result = await ssTerminateUser({ userUID: user.uid, reason: values.terminationReason, followup: true });
                if (result.data.status !== 'SUCCESS') {
                  console.error(result.data);
                  throw new Error("Error registering user to early termination program, check console");
                }
                setTermStatus(TerminationStatus.TERMINATION_FOLLOWUP);
                break;
              default:
                throw new Error('Termination type not set');
            }
            setDisappearingMessage("Saved");
          } catch (error) {
            setError("Error terminating user");
            console.error(error);
          }
          actions.setSubmitting(false);
        }}
        validateOnChange={false}
        validationSchema={FormSchema}
      >
        {props =>
          <FormikForm role="form">
            <Col>
              <Row>
                <FormikTextField
                  name="terminationReason"
                  label="Reason for terminating"
                  placeHolder="Reason for terminating..."
                  multiLine={true}
                  disabled={termStatus === TerminationStatus.TERMINATED}
                />
              </Row>
              <TerminationFollowupButton termStatus={termStatus} formik={props} />
              <TerminationButton termStatus={termStatus} formik={props} />
              <Row>
                {
                  message &&
                  <h6 className="mt-2 text-success">{message}</h6>
                }
                {
                  error &&
                  <h6 className="mt-2 text-warning">{error}</h6>
                }
                <FormikFormSubmitting>
                  {isSubmitting => (
                    isSubmitting &&
                    <h6 className="mt-2 text-success">Saving...</h6>
                  )}
                </FormikFormSubmitting>
              </Row>
            </Col>
          </FormikForm>
        }
      </Formik>
    </Row>
  )
}

export default EarlyTermination;

const FormSchema = Yup.object().shape({
  terminationReason: Yup.string()
    .required('Required'),
})

interface ButtonProps {
  formik: FormikProps<TerminationForm>;
  termStatus: TerminationStatus;
}

const TerminationButton: React.FC<ButtonProps> = ({ formik, termStatus }) => {
  const [confirm, setConfirm] = useState(false);

  return (
    <>
      <Row>
        <Button
          className="btn-danger my-2"
          disabled={termStatus === TerminationStatus.TERMINATED || formik.isSubmitting}
          onClick={(event) => {
            event.stopPropagation();
            if (confirm) {
              formik.setFieldValue('terminationType', TerminationType.EARLY_TERMINATION);
              formik.submitForm();
            }
            else {
              formik.validateForm()
                .then((errors) => {
                  if (Object.keys(errors).length === 0) {
                    setConfirm(true);
                  }
                  else {
                    // Form has errors, subit it to show them
                    formik.submitForm();
                  }
                });
            }
          }}
        >
          {
            TerminationStatus.TERMINATED !== termStatus ?
              confirm ?
                'Confirm early termination'
                :
                'Terminate user'
              :
              'User terminated'
          }
        </Button>
      </Row>
      {
        TerminationStatus.TERMINATED !== termStatus &&
        <Row>
          <small style={{ maxWidth: 350 }}>Move user directly to termination status, no followup termination surveys or call options.</small>
        </Row>
      }
    </>
  )
}

const TerminationFollowupButton: React.FC<ButtonProps> = ({ formik, termStatus }) => {
  const [confirm, setConfirm] = useState(false);

  if (termStatus === TerminationStatus.TERMINATED) {
    return null;
  }

  return (
    <>
      <Row>
        <Button
          className="my-2"
          disabled={TerminationStatus.TERMINATION_FOLLOWUP === termStatus || formik.isSubmitting}
          onClick={(event) => {
            event.stopPropagation();
            if (confirm) {
              formik.setFieldValue('terminationType', TerminationType.EARLY_TERMINATION_FOLLOWUP);
              formik.submitForm();
            }
            else {
              formik.validateForm()
                .then((errors) => {
                  if (Object.keys(errors).length === 0) {
                    setConfirm(true);
                  }
                  else {
                    // Form has errors, subit it to show them
                    formik.submitForm();
                  }
                });
            }
          }}
        >
          {
            TerminationStatus.NOT_TERMINATED === termStatus ?
              confirm ?
                'Confirm early termination followup'
                :
                'Place user in early termination followup'
              :
              'User in termination followup'
          }
        </Button>
      </Row>
      <Row className="mb-3">
        <small style={{ maxWidth: 350 }}>Move user to early termination followup, provide them the option to take followup surveys and schedule a followup call.</small>
      </Row>
    </>
  )
}

