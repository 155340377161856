import { SessionTaskData } from "@swing-therapeutics/swingcore/dist/models/Session";
import React from "react";
import { DraggableProvided } from "react-beautiful-dnd";
import { Form, Button } from "react-bootstrap";

interface SessionTaskProps {
  index: number;
  task: SessionTaskData;
  provided: DraggableProvided;
  deleteTask: (index: number) => void;
  editTask: (index: number) => void;
}

export const SessionTask = ({ index, task, deleteTask, editTask, provided}: SessionTaskProps) => {
  return (
    <tr ref={provided.innerRef}
      style={{backgroundColor: 'white'}}
      {...provided.draggableProps}
      {...provided.dragHandleProps}>
      <td>{index + 1}</td>
      <td>{task.displayName}</td>
      <td>{task.type}</td>
      <td>{task.id}</td>
      <td>
        {task.type === 'awareness' &&
          <div>uuid: {task?.uuid}</div>
        }
        {task.type === 'lesson' &&
          <div>Chapter: {task?.chapter}  Lesson: {task?.lesson}</div>
        }
      </td>
      <td>
        {task.imageFilename ?? 'none'}
      </td>
      <td>
        <Button
          className="mr-2"
          variant="outline-primary"
          size="sm"
          onClick={() => editTask(index)}
        >
          Edit
        </Button>
        <Button
          variant="outline-danger"
          size="sm"
          onClick={() => deleteTask(index)}
        >
          Delete
        </Button>
      </td>
    </tr>
  )
}
