import React, {useState, useEffect} from "react";
import {Container, Row, Table } from "react-bootstrap";
import { useCollectionClassData } from "../../../utils/Hooks";
import LoadingScreen from "../../LoadingScreen";
import { firestore } from "../../../firebase";
import Medrio from "../../../Model/Medrio";
import moment from 'moment';

interface MedrioTriggersProps {
  subjectID: string;
}

interface TableRowProps {
  subjectID: string;
  medrioData: Medrio;
}

const TableRow: React.FC<TableRowProps> = ({
  subjectID,
  medrioData
}) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!medrioData.FormEventData) {
    } else {
      setLoading(false);
    }
  }, [medrioData]);

  return !loading ? (
    <tr
      className="hoverablerow"
      style={{ color: medrioData.FormEventData?.EventType === 'FormDataUpdated' ? 'red' : '' }}
    >
      <td>{medrioData.FormEventData?.EventType}</td>
      <td>{medrioData.FormEventData?.SubjectStatus}</td>
      <td>{medrioData.FormEventData?.FormName}</td>
      <td>{medrioData.FormEventData?.VisitName}</td>
      <td>{moment(medrioData.handledTimestamp.toDate()).format("DD MMM, yyyy hh:mm:ss a")}</td>
    </tr>
  ) : (
    <tr>
      <td>Loading...</td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
    </tr>
  );
};

const MedrioTriggers: React.FC<MedrioTriggersProps> = ({ subjectID }) => {
  const playlistProgressQuery = firestore
    .collection("medrio")
    .doc("events")
    .collection("playbackComplete")
    .where("FormEventData.SubjectIdentifier", "==", subjectID);

  const [progressCollection, progressCollectionLoading] =
    useCollectionClassData(Medrio, playlistProgressQuery);

  return (
    <Container fluid>
      {progressCollectionLoading ? (
        <LoadingScreen />
      ) : (
        <>
          <Row>
            <Table responsive striped className="table-center-rows">
              <thead>
                <tr>
                  <th>Event Type</th>
                  <th>Status</th>
                  <th>Form Name</th>
                  <th>Visit</th>
                  <th>Handled Timestamp</th>
                </tr>
              </thead>
              <tbody>
                {progressCollection
                  .slice()
                  .sort((a, b) => {
                    // Place newer playlists started at top of table
                    return (
                      b.timestamp.seconds - a.timestamp.seconds
                    );
                  })
                  .map((medrioData, index) => {
                    return (
                      <TableRow
                        key={index}
                        subjectID={medrioData.FormEventData.SubjectIdentifier}
                        medrioData={medrioData}
                      />
                    );
                  })}
              </tbody>
            </Table>
          </Row>
        </>
      )}
    </Container>
  );
};

export default MedrioTriggers;
