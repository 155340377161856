import React, { useState, useEffect } from "react";
import LoadingScreen from "../LoadingScreen";
import AdminNavbar from "../AdminNavbar";
import { useHistory, useLocation } from "react-router-dom";

import {
  Button,
  Col,
  Container,
  Form,
  FormControl,
  InputGroup,
  Jumbotron,
  Row,
  Table,
} from "react-bootstrap";
import usePagination from "../../hooks/usePagination";

const SubjectsList = () => {
  let { search } = useLocation();
  const pageNumber = new URLSearchParams(search).get("page");

  const searchTerms = [
    "subjectID",
    "role",
    "study",
    "firstName, lastName",
    "site",
    "cohort",
    "activeProgram",
  ];
  let history = useHistory();

  const {
    visibleDocs,
    page,
    PaginationButtons,
    documentsLoading,
    documentsError,
    filter,
    setFilter,
    ResetButton,
    SearchButton,
  } = usePagination(
    "subjects",
    20,
    "email",
    "asc",
    searchTerms,
    Number(pageNumber)
  );

  useEffect(() => {
    if (page.current !== pageNumber) {
      history.replace({
        pathname: "/subjects",
        search: "?page=" + page.current,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, page.current, pageNumber]);
  const getTableRow = (subject) => {
    return (
      <tr key={subject.subjectID} onClick={() => {}}>
        <td>{subject.subjectID}</td>
        <td>{subject.firstName}</td>
        <td>{subject.lastName}</td>
        <td>{subject.study}</td>
        <td>{subject.site}</td>
        <td>{subject.cohort}</td>
        <td>{subject.role}</td>
        <td>{subject.activeProgram}</td>
      </tr>
    );
  };

  useEffect(() => {
    if (page.current !== pageNumber) {
      history.replace({
        pathname: "/subjects",
        search: "?page=" + page.current,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, page.current, pageNumber]);

  return (
    <>
      <AdminNavbar></AdminNavbar>
      <Jumbotron>
        <Container>
          <Row>
            <Col>
              <h1>Subjects</h1>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form inline>
                <InputGroup className="">
                  <FormControl
                    id="search"
                    placeholder="Search"
                    onChange={(e) => {
                      setFilter(e.target.value.trim());
                    }}
                    value={filter}
                  />
                </InputGroup>
                <SearchButton />
                <ResetButton />
              </Form>
            </Col>
            <Col xs="auto">
              <Form inline>
                <InputGroup className="">
                  <Button
                    onClick={() => {
                      history.push("/register/new");
                    }}
                  >
                    Create
                  </Button>
                </InputGroup>
              </Form>
            </Col>
          </Row>
        </Container>
      </Jumbotron>
      <Container style={{ backgroundColor: "white" }}>
        <Row>
          {documentsError && (
            <>
              <strong>Error: {JSON.stringify(documentsError)}</strong>
              <br></br>
              <LoadingScreen></LoadingScreen>
            </>
          )}
          {documentsLoading && (
            <>
              <strong>
                Subjects Loading: {JSON.stringify(documentsError)}
              </strong>
              <br></br>
              <LoadingScreen></LoadingScreen>
            </>
          )}
          {visibleDocs && (
            <Col xs={12}>
              <Table>
                <thead key="subjectsListTable">
                  <tr>
                    <th>Subject ID</th>
                    <th>First Name</th>
                    <th>Last Name</th>
                    <th>Study</th>
                    <th>Site</th>
                    <th>Cohort</th>
                    <th>Role</th>
                    <th>Active Program</th>
                  </tr>
                </thead>
                <tbody>{visibleDocs.map((u) => getTableRow(u))}</tbody>
              </Table>
            </Col>
          )}
        </Row>
        <PaginationButtons />
      </Container>
    </>
  );
};

export default SubjectsList;
