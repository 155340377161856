import React from "react";
import "./App.css";
import Application from "./Components/Application";
import UserProvider from "./providers/UserProvider";
import "@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css";

function App() {
  return (
    <UserProvider>
      <Application />
    </UserProvider>
  );
}

export default App;
