import React from "react";
import "./surveyAdherence.css";
import AdminNavbar from "../AdminNavbar";
import AreportsListSidebar from "./AreportsListSidebar";
import PROkeyTable from "./PROkeyTable";
import PROkeyTableXL from "./PROkeyTableXL";

import { Col, Container, Jumbotron, Row } from "react-bootstrap";
import BReportTable from "./BReportTable";
// const query = firestore.collection("analytics/reports/reportPages");
// const [reportPages, reportPagesLoading, reportPagesError] = useCollection(query, {
// snapshotListenOptions: {includeMetadataChanges: true},
// });
// const query = firestore.collection("analytics").doc("kpi_summary")
const AnInterviewsList = (props) => {
  return (
    <>
      <AdminNavbar />
      <Jumbotron>
        <Container fluid={true}>
          <Row>
            <Col>
              <h1>Interviews 002</h1>
            </Col>
          </Row>
        </Container>
      </Jumbotron>
      {/* <AreportsListSidebar /> */}

      <Container fluid={true} style={{ backgroundColor: "white" }}>
        <div className="article">
          <p className="TitleC">interviews</p>
          <PROkeyTable tableKey={"interviews002"}></PROkeyTable>
        </div>
      </Container>
    </>
  );
};

export default AnInterviewsList;
