import { firestore } from "firebase";
import { keyBy } from "lodash";
import { useMemo } from "react";
import { useCollectionDataOnce } from "react-firebase-hooks/firestore";
import { Celebration } from "@swing-therapeutics/swingcore/dist/models/content/Celebration";

export function useCelebrations(programKey: string) {
  const celebrationsQuery = firestore().collection(`/programs/${programKey}/celebrations`);
  const [celebrations, celebrationsLoading, celebrationsError] = useCollectionDataOnce<Celebration>(celebrationsQuery);
  const celebrationsById = useMemo(() => keyBy(celebrations || [], 'id'), [celebrations]);

  return { celebrations, celebrationsLoading, celebrationsError, celebrationsById };
}
