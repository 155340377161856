import React from "react";
import { Button, Col, Container, Jumbotron, Row, Table } from "react-bootstrap";
import { RouteComponentProps } from "react-router";
import { Link } from "react-router-dom";
import { useCollectionClassData } from "../../utils/Hooks";
import { SurveyBayForm } from "@swing-therapeutics/surveybay/dist/models/SurveyBayForm";
import { getSurveyBayForms } from "@swing-therapeutics/surveybay/dist/utils";
import AdminNavbar from "../AdminNavbar";
import LoadingScreen from "../LoadingScreen";

interface FormRowProps {
  form: SurveyBayForm;
}

const deleteForm = async (form: SurveyBayForm) => {
  if (window.confirm(`Delete form ${form.name}?`)) {
    await getSurveyBayForms().doc(form.key).delete();
    window.alert('Form deleted')
  }
}

const FormRow: React.FC<FormRowProps> = ({ form }) => {
  return (
    <tr>
      <td>
        <Link to={{ pathname: `/003/forms/edit/${form.key}` }}>{form.key}</Link>
      </td>
      <td>{form.name}</td>
      <td>{form.updated.toLocaleString()}</td>
      <td>{form.created.toLocaleString()}</td>
      <td>
        <Button variant="danger" onClick={() => deleteForm(form)}>
          Delete
        </Button>
      </td>
    </tr>
  );
};

interface PageParams {
  version: string; // 003, 002, 004, etc
}

const FormList: React.FC<RouteComponentProps<PageParams>> = ({
  match,
}) => {  
  const [forms, loading] = useCollectionClassData(
    SurveyBayForm,
    getSurveyBayForms().orderBy("updated", "desc")
  );

  if (loading) {
    return <LoadingScreen />;
  }

  return (
    <>
      <AdminNavbar />
      <Jumbotron>
        <Container fluid style={{ backgroundColor: "white" }}>
          <Row>
            <Col xs={6}>
              <h1>Forms</h1>
            </Col>
            <Col
              xs={6}
              className="d-flex justify-content-end align-items-center"
            >
              <Link
                to={{
                  pathname: `/${match.params.version}/forms/new`,
                  state: { formKey: "new" },
                }}
              >
                <h5>Create new form</h5>
              </Link>
            </Col>
          </Row>
        </Container>
      </Jumbotron>
      <Container fluid style={{ backgroundColor: "white" }}>
        <Row>
          <Col xs={12}>
            <Table striped responsive>
              <thead>
                <tr>
                  <th>Key</th>
                  <th>Name</th>
                  <th>Updated</th>
                  <th>Created</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {forms &&
                  forms.map((form, index) => (
                    <FormRow key={index} form={form} />
                  ))}
              </tbody>
            </Table>
            {(!forms || forms.length === 0) && (
              <h2>
                No forms available. Please press the create new form button
              </h2>
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default FormList;
