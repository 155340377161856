import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SurveyInfo, WorkflowType } from "@swing-therapeutics/surveybay/dist/types";
import React, { useState } from "react";
import { Col, Row, Table } from "react-bootstrap";
import User from "../../../../Model/Usert";
import { Workflow, getColorFromStatus, TherapyWorkflowData } from "../../../../Model/Workflow";
import { calculateSurveySchedule } from "../../../../utils/SurveySchedule";
import SurveyResponseView from "./SurveyResponseView";

interface BaselineSurveysProps {
  workflow: Workflow;
  user: User;
  workflowType: WorkflowType;
}

enum views {
  SUMMARY = 'summary',
  SURVEY = 'survey',
}

const TherapySurveysView: React.FC<BaselineSurveysProps> = ({ workflow, user, workflowType }) => {
  const workflowData = workflow.workflowData as TherapyWorkflowData;
  const [view, setView] = useState<views>(views.SUMMARY);
  const [viewSurveyID, setViewSurveyID] = useState('');

  const handleRowClick = (survey: SurveyInfo) => {
    setViewSurveyID(survey.responseDocID);
    setView(views.SURVEY);
  }

  let totalWeeks: number = 36;
  let weekLength: number = 8;

  if (workflowType === WorkflowType.STANDARD) {
    totalWeeks = 12;
  } else if (workflowType === WorkflowType.EXTENSION) {
    totalWeeks = 36;
  }

  if (workflowType === WorkflowType.EXTENSION && user.study === '005') {
    weekLength = 8;
  } else {
    weekLength = 7;
  }

  const surveyConfig = {weekLength, totalWeeks};
  let surveySchedule = calculateSurveySchedule(surveyConfig, workflowData.therapyStartDay, workflowData.therapySurveys);

  return (
    <Col>
      {
        view === views.SUMMARY &&
        <>
          <Row>
            <h4>Current therapy week: {workflowData.therapyWeek}</h4>
          </Row>
          <Row>
            <h4>Therapy start date: {workflowData.therapyStartDay.toDateString()}</h4>
          </Row>

          <Row>
            <h4>Survey Schedule Prediction</h4>
          </Row>
          <Row className="mt-2">
            <Table striped responsive>
                <thead>
                  <tr>
                    <th>Survey week</th>
                    <th>Assigned</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {surveySchedule.map((survey) => {
                    return (
                      <tr key={survey.surveyWeek} className="hoverablerow">
                        <td>{survey.surveyWeek}</td>
                        <td>{survey.surveyDay}</td>
                        <td>{survey.surveyStatus}</td>
                      </tr>
                    )
                  })}
                </tbody>
            </Table>
          </Row>

          <Row>
            <h4>Survey Schedule</h4>
          </Row>
          <Row className="mt-2">
            <Table striped responsive>
              <thead>
                <tr>
                  <th>Survey week</th>
                  <th>surveyBayKey</th>
                  <th>Status</th>
                  <th>Assigned</th>
                  <th>Expires</th>
                </tr>
              </thead>
              <tbody>
                {workflowData.therapySurveys.map((therapySurvey) => {

                  return (
                    <React.Fragment key={therapySurvey.week}>
                      {therapySurvey.surveys.map((survey) => {
                        return (
                          <tr key={survey.responseDocID} className="hoverablerow" onClick={() => handleRowClick(survey)}>
                            <td>{therapySurvey.week}</td>
                            <td>{survey.surveyBayKey}</td>
                            <td style={{ color: getColorFromStatus(survey.status) }}>{survey.status}</td>
                            <td>{survey.assigned.toDateString()}</td>
                            <td>{survey.expires.toDateString()}</td>
                          </tr>
                        )
                      })}
                    </React.Fragment>
                  )
                })}
              </tbody>
            </Table>
          </Row>
        </>
      }
      {
        view === views.SURVEY &&
        <>
          <Row>
            <div
              className="journal-back-button"
              onClick={() => {
                setViewSurveyID('');
                setView(views.SUMMARY);
              }}
            >
              <FontAwesomeIcon icon={faArrowLeft} />
              {' '}Go Back
            </div>
          </Row>
          <SurveyResponseView responseDocID={viewSurveyID} userUID={user.uid} />
        </>
      }
    </Col>
  )
}

export default TherapySurveysView;
