import {useState, useEffect} from 'react';
import {auth, firestore} from "./firebase";
import {Role} from "./Model/Role";

function useRole(roleName: string) {
    const [hasRole, setHasRole] = useState(false);

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(async (user) => {
            if (user && user.email) {

                // @ts-ignore
                firestore
                    .collection("roles")
                    .doc(roleName)
                    .collection("members")
                    .doc(user.email).onSnapshot((snapshot => {
                    setHasRole(snapshot.exists);

                }))

            }
        });
        return () => unsubscribe();
    }, [setHasRole]);

    return hasRole;


}

export default useRole;