import { firestore } from "firebase";
import React from "react";
import { Button, Row, Table } from "react-bootstrap";
import { useCelebrations } from "../../../hooks/useCelebrations";
import { Celebration } from "@swing-therapeutics/swingcore/dist/models/content/Celebration";
import useRole from "../../../UseRole";
import LoadingScreen from "../../LoadingScreen";

interface CelebrationListProps {
  programKey: string;
  showEditor: (celebration?: Celebration) => void;
}

const CelebrationList = ({ programKey, showEditor }: CelebrationListProps) => {
  const isAdmin = useRole("admin");
  const {celebrations, celebrationsLoading} = useCelebrations(programKey);

  const deleteCelebration = async (celebration: Celebration) => {
    if (
      window.confirm(
        `Are you sure you want to delete the "${celebration.name}" celebrations? (Sessions referring to them must be updated by hand)`
      )
    ) {
      try {
        await firestore()
          .collection("programs")
          .doc(programKey)
          .collection("celebrations")
          .doc(celebration.id)
          .delete();
        window.alert("Celebrations deleted successfully!");
      } catch (error) {
        window.alert(
          "an error occurred while trying to delete the celebrations. Check the developer console for details."
        );
        console.error(error);
      }
    }
  };
  if (celebrationsLoading) {
    return <LoadingScreen />;
  }

  return (
    <>
      <Row style={{ marginLeft: "5px" }}>
        <h3>Celebrations</h3>
      </Row>
      <Row style={{ marginLeft: "20px" }}>
        <Button
          style={{ marginBottom: "10px" }}
          variant="outline-info"
          onClick={() => showEditor()}
        >
          Add Celebrations
        </Button>
      </Row>
     
      <Table bordered hover className={"table-striped"}>
        <thead>
          <tr>
            <th>Key</th>
            <th>Name</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {celebrations.map((celebration) => (
            <tr key={`celebration-${celebration.id}`}>
              <td>{celebration.id}</td>
              <td>{celebration.name}</td>
              <td>
                <Button
                  variant="outline-warning"
                  style={{ margin: 5 }}
                  onClick={() => showEditor(celebration)}
                >
                  Edit Celebrations
                </Button>

                {isAdmin && (
                  <Button
                    variant="outline-danger"
                    style={{ margin: 5 }}
                    onClick={() => deleteCelebration(celebration)}
                  >
                    Delete Celebrations
                  </Button>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
};

export { CelebrationList };
