import {ReactComponent as Loading} from "./Images/loading.svg";
import React from "react";
import {Container, Row, Col, Button, Card} from "react-bootstrap";
import AdminNavbar from "./AdminNavbar";

const LoadingScreen = (props) => {
    return (<>

        <Container>
            <br></br>

            <Row className="justify-content-md-center">
                <Loading></Loading>
            </Row>
            <Row className="justify-content-md-center">
                <br></br>
                <Col>
                    {" "}
                    <h1 className="text-center">Loading...</h1>
                </Col>
            </Row>
        </Container>
    </>);
};

export default LoadingScreen;
