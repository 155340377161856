import { firestore } from "firebase";
import React, { useEffect, useState } from "react";
import { Button, Col, Row, Table } from "react-bootstrap";
import lodash, { cloneDeep } from "lodash";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
  ResponderProvided,
} from "react-beautiful-dnd";
import { SessionListTableRow } from "./SessionListTableRow";
import { SessionEditor } from "./SessionEditor";
import "../playlists.css";
import useRole from "../../../../UseRole";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { useCelebrations } from "../../../../hooks/useCelebrations";
import { useCollectionClassData } from "../../../../utils/Hooks";
import { SessionTemplate } from "@swing-therapeutics/swingcore/dist/models/Session";

interface SessionListParams {
  programKey: string;
  playlistId: string;
}

const SessionList = ({
  playlistId,
  programKey,
}: SessionListParams) => {
  const isAdmin = useRole("admin");
  const match = useRouteMatch(
    "/programs/:programKey/playlists/:playlistID/:sessionID?"
  );
  const getSessionsCollection = () =>
    firestore()
      .collection("programs")
      .doc(programKey)
      .collection("playlist")
      .doc(playlistId)
      .collection("sessions");

  const getPlaylistDocument = () =>
    firestore()
      .collection("programs")
      .doc(programKey)
      .collection("playlist")
      .doc(playlistId);

  const query = getSessionsCollection().orderBy("num", "asc");
  const [sessions, sessionsLoading] = useCollectionClassData(SessionTemplate, query)

  const [loading, setLoading] = useState(false);
  const [dragAndDropDisabled, setdragAndDropDisabled] = useState(true);
  const [multiSelectEnabled, setMultiSelectEnabled] = useState(false);
  const [selectedSessions, setSelectedSessions] = useState(new Set() as Set<SessionTemplate>);

  const {celebrations, celebrationsLoading, celebrationsById} = useCelebrations(programKey);

  useEffect(() => {
    let errors: string[] = [];
    
    if (sessions.length < 1) return;

    sessions.forEach((session, index) => {
      if (index > 0 && session.previousSessionID && session.previousSessionID !== sessions[index - 1].id) {
        errors.push(`${session?.id} PREVIOUS ID ERROR: ${session?.previousSessionID} != ${sessions?.[index - 1]?.id}`)
      }

      else if (index < sessions.length && session.nextSessionID && session.nextSessionID !== sessions[index + 1].id) {
        errors.push(`${session?.id} NEXT ID ERROR: ${session?.nextSessionID} != ${sessions?.[index + 1]?.id}`)
      }
    });

    if (errors.length > 0) {
      throw new Error('Error in session id\'s\n\n' + JSON.stringify(errors))
    }
  },[sessions])

  const addNewSession = () => {
    const batch = firestore().batch();

    const oldLastSession = cloneDeep(sessions[sessions.length - 1]);

    const num = sessions.length + 1;

    // To prevent ID collisions, we need to get the "latest" ID and increment it by 1
    // for example, if the latest ID is part1_99, the new session needs to be part1_100.
    // Since sessions can be reordered, we can't depend on the number of sessions to generate a new id
    const latestSessionNumber =
      lodash(sessions)
        .map((session) => Number(session.id.split("_")[1]))
        .sort((a, b) => a - b)
        .last() || 0;

    const id = `${playlistId}_${latestSessionNumber + 1}`;
    const now = new Date();
    if (
      window.confirm(`create session ${num} in the ${playlistId} playlist?`)
    ) {
      const newLastSession = {
        id,
        num,
        created: now,
        updated: now,
        nextSessionID: null,
        previousSessionID: sessions[sessions.length - 1]?.id || null,
        programKey,
        tasks: [],
      };

      if (oldLastSession) {
        oldLastSession.nextSessionID = id;
        oldLastSession.updated = now;
        batch.update(
          getSessionsCollection().doc(oldLastSession.id),
          oldLastSession.data()
        );
      }

      batch.set(getSessionsCollection().doc(newLastSession.id), newLastSession);

      const firstSessionID = sessions?.length
        ? sessions[0].id
        : newLastSession.id;
      batch.set(getPlaylistDocument(), { firstSessionID }, { merge: true });

      return batch.commit();
    }
  };

  /**
   * @param sessionIndex The session to reorder or delete
   * @param targetIndex Move the session to this index. If null, deletes the session specificed at the sessionIndex.
   * @returns a copy of the sessions with the specificed session reordered or deleted
   */
  const moveSession = (sessionIndex: number, targetIndex: number | null) => {
    const sessionsCopy = cloneDeep(sessions);

    const session = sessionsCopy.splice(sessionIndex, 1)[0];

    if (targetIndex !== null) {
      sessionsCopy.splice(targetIndex, 0, session);
    }

    return sessionsCopy;
  };

  const batchSaveSessions = async (newSessions: SessionTemplate[] = []) => {
    const batch = firestore().batch();
    const oldSessions = sessions;

    if (newSessions.length) {
      newSessions.forEach((session) => {
        batch.set(getSessionsCollection().doc(session.id), session.data());
      });

      batch.set(
        getPlaylistDocument(),
        { firstSessionID: newSessions[0].id },
        { merge: true }
      );
    }

    if (newSessions.length < oldSessions.length) {
      const newSessionIds = new Set(newSessions.map((session) => session.id));
      const sessionsToDelete = oldSessions.filter(
        (session) => !newSessionIds.has(session.id)
      );

      sessionsToDelete.forEach((session) => {
        batch.delete(getSessionsCollection().doc(session.id));
      });
    }

    try {
      setLoading(true);
      await batch.commit();
    } catch (err) {
      alert(
        "Could not reorder sessions. See the developer console for details."
      );
      console.error(err);
    } finally {
      setLoading(false);
      setSelectedSessions(new Set());
      setMultiSelectEnabled(false);
    }
  };

  const reorderSessions = (newSessions: SessionTemplate[]) => {
    const reorderedSessions = cloneDeep(newSessions);
    const now = new Date();

    reorderedSessions.forEach((session, index) => {
      const prevSession = newSessions[index - 1];
      const nextSession = newSessions[index + 1];

      session.num = index + 1;
      session.previousSessionID = prevSession ? prevSession.id : null;
      session.nextSessionID = nextSession ? nextSession.id : null;
      session.updated = now;
    });

    return reorderedSessions;
  };

  const deleteSessions = async (sessionsToDelete: Set<SessionTemplate>) => {
    if (
      window.confirm(
        `Are you sure you want to delete ${sessionsToDelete.size === 1 ? `session ${Array.from(sessionsToDelete)[0].num}`: `${sessionsToDelete.size} sessions`}? (Sessions will automatically be re-ordered after this)`
      )
    ) {
      const newSessions = reorderSessions(sessions.filter(session => !sessionsToDelete.has(session)));

      if (newSessions.length === 0 && sessions.length) {
        // the only session(s) left were deleted
        try {
          setLoading(true);
          const batch = firestore().batch();
          sessionsToDelete.forEach(session => batch.delete(getSessionsCollection().doc(session.id)));
          await batch.commit();
        } catch (err) {
          alert(
            "Could not delete session. See the developer console for details."
          );
          console.error(err);
        } finally {
          setLoading(false);
          setSelectedSessions(new Set());
          setMultiSelectEnabled(false);
        }
      } else {
        batchSaveSessions(newSessions);
      }
    }
  };

  const onDragEnd = (result: DropResult, provided: ResponderProvided) => {
    // do nothing if the drop target is invalid or the row is dropped where it was originally dragged from
    if (
      !result.destination ||
      Number(result.draggableId) - 1 === result.destination.index
    ) {
      return;
    }

    const draggedSessionNum = Number(result.draggableId);
    const targetIndex = result.destination.index;
    const sessionIndex = sessions.findIndex(
      (session) => session.num === draggedSessionNum
    );
    const newSessions = reorderSessions(moveSession(sessionIndex, targetIndex));

    batchSaveSessions(newSessions);
  };

  const SessionListButtons = () =>
    !isAdmin ? null : (
      <Row>
        <Col className={"sessions-creation-actions"} style={{marginBottom: 5}}>
          {!multiSelectEnabled && (
            <>
              <Button
                variant="primary"
                style={{ marginRight: "5px" }}
                onClick={addNewSession}
              >
                Add New Session
              </Button>
              <Button
                variant={dragAndDropDisabled ? "info" : "warning"}
                style={{ marginRight: "5px" }}
                onClick={() => setdragAndDropDisabled(!dragAndDropDisabled)}
              >
                {dragAndDropDisabled
                  ? "Enable reordering"
                  : "Disable reordering"}
              </Button>
              <Button
                variant="danger"
                onClick={() => setMultiSelectEnabled(true)}
              >
                Select for deletion
              </Button>
            </>
          )}
          {multiSelectEnabled && (
            <>
              <Button
                variant="danger"
                disabled={selectedSessions.size === 0}
                style={{ marginRight: "5px" }}
                onClick={() => deleteSessions(selectedSessions)}
              >
                {" "}
                Delete {selectedSessions.size.toString()} sessions
              </Button>
              <Button
                variant="secondary"
                style={{ marginRight: "5px" }}
                onClick={() => {
                  if (selectedSessions.size === sessions.length) {
                    setSelectedSessions(new Set());
                  } else {
                    setSelectedSessions(new Set(sessions));
                  }
                }}
              >
                Select All
              </Button>
              <Button
                variant="info"
                onClick={() => {
                  setMultiSelectEnabled(false);
                  setSelectedSessions(new Set());
                }}
              >
                Cancel
              </Button>
            </>
          )}
        </Col>
      </Row>
    );

  if (sessionsLoading || loading) {
    return <h1>Loading...</h1>;
  }

  return (
    <Switch>
      <Route
        exact
        path="/programs/:programKey/playlists/:playlistID/:sessionID/tasks"
      >
        <SessionEditor celebrations={celebrations} celebrationsById={celebrationsById}/>
      </Route>

      <Route exact path="/programs/:programKey/playlists/:playlistID">
        <Col lg={10}>
          <h4>Sessions</h4>
          <SessionListButtons />
          <DragDropContext onDragEnd={onDragEnd}>
            {!dragAndDropDisabled && (
              <Row>
                <Col>
                  <p>
                    Sessions can be reordered by dragging and dropping table
                    rows.
                  </p>
                </Col>
              </Row>
            )}
            <Row>
              <Col xs={12}>
                <Table bordered className={"table sessions-table"}>
                  <thead className={""}>
                    <tr>
                      {multiSelectEnabled && <th></th>}
                      <th>Order</th>
                      <th>ID</th>
                      <th>Previous ID</th>
                      <th>Next ID</th>
                      <th>Tasks</th>
                      <th>Celebration</th>
                      {isAdmin && <th>Actions</th>}
                    </tr>
                  </thead>
                  <Droppable droppableId="sessionListDroppable">
                    {(droppableProvided) => (
                      <>
                        <tbody
                          {...droppableProvided.droppableProps}
                          ref={droppableProvided.innerRef}
                        >
                          {sessions.map((session, index) => (
                            <Draggable
                              isDragDisabled={dragAndDropDisabled}
                              draggableId={String(session.num)}
                              index={index}
                              key={`drag-${session.num}`}
                            >
                              {(draggableProvided, draggableSnapshot) => (
                                <SessionListTableRow
                                  session={session}
                                  draggableProvider={draggableProvided}
                                  draggableSnapshot={draggableSnapshot}
                                  deleteSession={() => deleteSessions(new Set([session]))}
                                  shouldShowCheckbox={multiSelectEnabled}
                                  selectedSessions={selectedSessions}
                                  setSelectedSessions={setSelectedSessions}
                                  celebrationsById={celebrationsById}
  
                                />
                              )}
                            </Draggable>
                          ))}
                          {droppableProvided.placeholder}
                        </tbody>
                      </>
                    )}
                  </Droppable>
                </Table>
              </Col>
            </Row>
          </DragDropContext>
        </Col>
      </Route>
    </Switch>
  );
};

export { SessionList };
