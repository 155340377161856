import {firestore} from "../../firebase";


// //QUERIES
// const sessionQuery = firestore
//     .collection("users")
//     .doc(user.uid)
//     .collection("programs")
//     .doc(prog)
//     .collection("sessions")
//     .orderBy("dateCompleted", "asc");
//
//
// const journalsQuery = firestore
//     .collection("users")
//     .doc(user.uid)
//     .collection("programs")
//     .doc(prog)
//     .collection("journalEntries")
//     .orderBy("dateCreated", "asc");
//
// const appUsages = firestore
//     .collection("users")
//     .doc(user.uid)
//     .collection("appUsage")
//     .orderBy("dateCreated", "asc");

export default class GraphLines {


    static onePerDayLine(numDays, numSessions) {
        let x = [];
        let y = [];
        let j = 1;
        if (!numSessions) {
            numSessions = numDays;
        }
        for (let i = 0; i < numDays; i++) {
            x.push(i);
            y.push(j);

            if (j < numSessions) {
                j++;
            }
        }
        return {
            x: x, y: y, type: "scatter", name: "Baseline - Daily", line: {
                color: "rgba(48,99,124,0.6)", width: 1,
            },
        };
    };


    static async getUsersTaskLine(user, program) {
        if (!user || !user.uid) {
            return;
        }

        //define the query
        const query = firestore
            .collection("users")
            .doc(user.uid)
            .collection("programs")
            .doc(program)
            .collection("tasks")
            .orderBy("dateCompleted", "asc");


        //do the actual query, and wait for the response
        let queryResp = await query.get();
        let programDocRef = await firestore.collection('users').doc(user.uid).collection('programs').doc(program).get();
        if (!programDocRef.exists) {
            return;
        }

        //the things that are needed to build a line in bizzaro plotly world
        let x = [];
        let y = [];
        let text = [];
        const startTherapy = programDocRef.data().therapyStartDate ? programDocRef.data().therapyStartDate.toDate() : new Date();
        let completed = 0;


        //loop over all of the items,
        queryResp.docs.map((r) => {
            if (!r.data().dateCompleted) {
                return;
            }

            //get normalized day
            const date = r.data().dateCompleted.toDate();


            let progressDay = diffDays(startTherapy, date);
            let progressNumber = Number.parseInt(r.id) - progressDay + 1;
            x.push(progressDay);
            y.push(completed);
            text.push("Progress Number: " + progressNumber);
            completed++;
        });
        let line = {
            x: x, y: y, text: text, type: "scatter", mode: "lines+markers", line: {
                color: Math.floor(Math.random() * 16777215).toString(16), width: 3,
            }, name: user.subjectID ? user.subjectID + " Tasks" : "Tasks",
        };

        return line;
    };

    static async getUsersSessionLine(user, program) {
        if (!user || !user.uid) {
            return;
        }

        //define the query
        const query = firestore
            .collection("users")
            .doc(user.uid)
            .collection("programs")
            .doc(program)
            .collection("sessions")
            .orderBy("dateCompleted", "asc");


        //do the actual query, and wait for the response
        let queryResp = await query.get();
        let programDocRef = await firestore.collection('users').doc(user.uid).collection('programs').doc(program).get();
        if (!programDocRef.exists) {
            return;
        }

        //the things that are needed to build a line in bizzaro plotly world
        let x = [];
        let y = [];
        let text = [];
        let completed = 0;
        const startTherapy = programDocRef.data().therapyStartDate ? programDocRef.data().therapyStartDate.toDate() : new Date();


        //loop over all of the items,
        queryResp.docs.map((r) => {
            if (!r.data().dateCompleted) {
                return;
            }

            //get normalized day
            const date = r.data().dateCompleted.toDate();

            completed++;
            let progressDay = diffDays(startTherapy, date);
            let progressNumber = Number.parseInt(r.id) - progressDay + 1;
            x.push(progressDay);
            y.push(completed);
            text.push("Progress Number: " + progressNumber);
        });
        let line = {
            x: x, y: y, text: text, type: "scatter", mode: "lines+markers", line: {
                color: Math.floor(Math.random() * 16777215).toString(16), width: 3,
            }, name: user.subjectID ? user.subjectID + " Sessions" : "Sessions",
        };

        return line;
    };

    static async getUsersSummaryLine(user, program) {
        if (!user || !user.uid) {
            return;
        }

        //define the query
        const query = firestore
            .collection("users")
            .doc(user.uid)
            .collection("activitySummaries")

            .orderBy("therapyDay", "asc");


        //do the actual query, and wait for the response
        let queryResp = await query.get();

        //the things that are needed to build a line in bizzaro plotly world
        let x = [];
        let y = [];
        let text = [];
        let count = 0;
        let startTherapy = new Date();


        //loop over all of the items,
        queryResp.docs.map((r) => {

            //get normalized day
            const date = r.data().created ? new Date(r.data().created) : null;
            let progressDay = r.data().therapyDay + 1;

            x.push(progressDay);
            y.push(r.data().minsInApp);
            let dat = r.data();
            let mes = "Date: " + dat.date + " ";
            mes = dat.minsInApp ? mes + ' Mins in app: ' + dat.minsInApp : mes + 'Mins in App: 0';
            mes = dat.numAppOpens ? mes + ' Times app opened: ' + dat.numAppOpens : mes;


            text.push(mes);
        });
        let line = {
            x: x, y: y, text: text, type: "bar", line: {
                color: Math.floor(Math.random() * 16777215).toString(16), width: 3,
            }, name: user.subjectID ? user.subjectID + " Mins in app" : "Mins in app",
        };

        return line;
    };

}


const diffDays = (dt2, dt1) => {
    let diff = (dt2.getTime() - dt1.getTime()) / 1000;
    diff /= 60 * 60 * 24;
    return Math.abs(Math.round(diff));
};
