import { useFormik } from "formik";
import { Alert, Button, Col, Form, Row, Spinner } from "react-bootstrap";
import React, { useEffect } from "react";
import { SystemEvent } from "../../../../Model/SystemEvent";
import { BasicEasyEditField, BasicOptionField, BasicTextField } from "@swing-therapeutics/surveybay/dist/Forms/FormikHelpers";
import { StepEditorProps } from "./StepEditor";
import { stepImageTypes } from "@swing-therapeutics/swingcore/dist/models/content/Lesson";
import * as yup from 'yup';
import { isNil } from "lodash";

const TextStepEditor = ({step, lesson, goBack}: StepEditorProps) => {
  const formik = useFormik({
    initialValues: step,
    validationSchema: yup.object().shape({
      title: yup.string().nullable(),
      content: yup.string().required('Content is required'),
      imageType: yup.string().nullable(),
      image_filename: yup.string().when('imageType', {
        is: (type) => !isNil(type),
        then: yup.string().required('Filename is required if an imagetype is selected').typeError('Filename is required if an imagetype is selected'),
        otherwise: yup.string().nullable().test('Is Nil', 'Cannot have a filename with an empty image type', value => isNil(value)),
      })
    }),
    onSubmit: async (values, { setSubmitting }) => {
      step.title = values.title ?? "";
      step.content = values.content ?? "";
      step.image_filename = values.image_filename ?? null;
      step.imageType = values.imageType ?? "";

      try {
        await lesson.persist();
        window.alert('Step saved successfully!');
        SystemEvent.fireEvent(
          "Content Update",
          "Text step update",
          "Lesson " + lesson.id + " text step edited",
          new Date().toISOString(),
          { lesson: JSON.stringify(lesson), step }
        );
        setSubmitting(false);
        goBack();
      } catch (error) {
        window.alert('Error during saving. Refer to console for details')
      }
    },
  });

  const invalidImageType = isNil(formik.values?.imageType) || formik.values?.imageType?.length === 0;
  const needsImageTypeChange = invalidImageType && !!formik.values?.image_filename && !formik.dirty;

  useEffect(() => {
    if (invalidImageType && formik.dirty) {
      formik.setFieldValue('image_filename', undefined);
    }
  }, [formik.values]);

  return (
    <Col>
      <Form onSubmit={formik.handleSubmit}>
        <br></br>
        <Form.Row>
          <BasicTextField disabled={needsImageTypeChange} title="Title" path="title" formikHelpers={formik}/>
          <BasicEasyEditField disabled={needsImageTypeChange} title="Content" path="content" markupType="markdown" formikHelpers={formik} lg={12}/>
          {needsImageTypeChange && 
            <Col sm={12}>
              <Alert variant="danger">Please change the image type before proceeding.</Alert>
            </Col>
          }
          <BasicOptionField title="Image Type" path="imageType" options={stepImageTypes} formikHelpers={formik}/> <Col xs={12} className="mb-2">
            <Row>
              <BasicTextField title="Image Filename" path="image_filename" formikHelpers={formik} md={12} lg={6} disabled={invalidImageType || needsImageTypeChange}/>
            </Row>
            {formik.values.image_filename && (
              <img
                style={{ maxHeight: "150px" }}
                alt={formik.values.image_filename}
                src={
                  "https://storage.googleapis.com/tempo-pub/assets/v1/images/" +
                  formik.values.image_filename
                }
              />
            )}
          </Col>
        </Form.Row>

        <Button
          disabled={!formik.isValid || formik.isSubmitting || needsImageTypeChange}
          variant="primary"
          type="submit"
          onClick={() => {
            formik.handleSubmit();
          }}
        >
          {formik.isSubmitting ? (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          ) : (
            <>Submit</>
          )}
        </Button>
      </Form>
    </Col>
  );
};

export default TextStepEditor;
