import React from "react";
import { useCollection } from "react-firebase-hooks/firestore";
import LoadingScreen from "../../../LoadingScreen";
import "../../../Reports/surveyAdherence.css";
import { firestore } from "../../../../firebase";
import { Table } from "react-bootstrap";
import UserSurveys from "../../UsersSurveys";

interface TableRowProps {
    docRef: firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>,
}

const TableRow: React.FC<TableRowProps> = ({ docRef }) => {
    if (!docRef) return <></>;

    const data = UserSurveys.fromFirestore(docRef);
    if (!data) return <></>;

    return (
        <tr>
            {/* <td>{data.enddate.toLocaleDateString()}</td> */}
            <td>{data.avgd_baseline_score}</td>
            <td>{data.percent_change_from_baseline}</td>
            <td>{data.percent_progress}</td>
            <td>{data.treatment_week}</td>
            <td>{data.score}</td>
            <td>{data.symptoms}</td>
            <td>{data.impact}</td>
            <td>{data.function}</td>
            <td>{data.nps}</td>
        </tr>
    );
}

interface UserSurveysTableProps {
    subjectID: string,
}

const UserSurveysTable: React.FC<UserSurveysTableProps> = ({ subjectID }) => {
    let query = firestore
        .collection("surveySummaries")
        .where("subjectID", "==", subjectID)
        .orderBy("enddate", "desc");
    const [surveys, surveysLoading, surveyError,] = useCollection(query, { snapshotListenOptions: { includeMetadataChanges: true }, });

    return (<>
        {surveyError && <strong>Error: {JSON.stringify(surveyError)}</strong>}
        {surveysLoading && <LoadingScreen />}
        {surveys &&
            <Table>
                <thead key="systemEventsTable">
                    <tr>
                        <th>avg Baseline</th>
                        <th>Change from Baseline</th>
                        <th>App Progress</th>
                        <th>Treatment Week</th>
                        <th>Score</th>
                        <th>Symptoms</th>
                        <th>Impact</th>
                        <th>Function</th>
                        <th>NPS</th>
                    </tr>
                </thead>
                <tbody>
                    {surveys.docs.map((u) =>
                        <TableRow key={u.id} docRef={u} />
                    )}
                </tbody>
            </Table>
        }
    </>);
};

export default UserSurveysTable;
