import React, { useState } from "react";
import { Card, Col, Container, Fade, Row } from "react-bootstrap";
import { Formik, Form as FormikForm } from "formik";
import moment from "moment";
import * as Yup from "yup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FormikSubmitButton, FormikTextField } from "../../../FormikFields";
import _ from "lodash";
import { RequestedPayment } from "@swing-therapeutics/swingcore/dist/models/payments/Payment";
import { auth } from "../../../../firebase";
interface CRPProps {
  requestedPayment: RequestedPayment;
}

const CompleteRequestedPayment: React.FC<CRPProps> = ({ requestedPayment }) => {
  const [expanded, setExpanded] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [message, setMessage] = useState('');

  return (
    <Col className="my-2" >
      <Card>
        <Card.Header className="hoverable" onClick={() => setExpanded(prev => !prev)}>
          <Row>
            <Col xs={3}>
              <Row className="px-2">
                <p className="mb-0">Subject ID: {requestedPayment.subjectID}</p>
              </Row>
            </Col>
            <Col xs={6}>
              <Row className="px-2">
                <p className="mb-0 mx-4">{requestedPayment.paymentFor}</p>
              </Row>
            </Col>
            <Col xs={3}>
              <Row className=" px-2 justify-content-end">
                <p className="mb-0">{moment(requestedPayment.requestedTimestamp).format("MM/DD/YY")}</p>
              </Row>
            </Col>
          </Row>
        </Card.Header>
        {
          expanded &&
          <Card.Body>
            <Fade in={completed}>
              <Container style={{ display: !completed ? 'none' : undefined }}>
                <Row className="justify-content-center">
                  <h3 className="text-success mr-3">Payment logged</h3>
                  <FontAwesomeIcon icon={faCheck} className="text-success" style={{ height: 40, width: 40 }} />
                </Row>
              </Container>
            </Fade>
            <Formik
              initialValues={{
                amount: requestedPayment.amount,
                paidBy: auth.currentUser.displayName,
                notes: '',
              }}
              onSubmit={async (values, actions) => {
                const { paidBy, notes, amount } = values;
                // Clone the payment because it is a prop
                const updatedRP = _.cloneDeep(requestedPayment);
                // Update any values that are allowed to change in the form
                updatedRP.amount = amount;
                try {
                  await updatedRP.completePayment(paidBy, notes, new Date());
                  setCompleted(true);
                  // Wait for completed message to show, then delete the payment from the 
                  // requested payment collection
                  setTimeout(() => updatedRP.deletePayment(), 3000);
                } catch (error) {
                  console.error(error);
                  setMessage("Error saving, check console");
                  actions.setSubmitting(false);
                }
              }}
              validateOnChange={false}
              validationSchema={FormSchema}
            >
              <FormikForm role="form" style={{ width: '100%', display: completed ? 'none' : undefined }}>
                <Container>
                  <Row>
                    <Col>
                      <p className="mb-0">Payment for: {requestedPayment.paymentFor}</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <small>Payment group: {requestedPayment.paymentGroup}</small>
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col>
                      <FormikTextField
                        name="amount"
                        label="Amount payed"
                        type="number"
                        prepend="$"
                      />
                    </Col>
                    <Col>
                      <FormikTextField
                        name="paidBy"
                        label="Paid by"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormikTextField
                        multiLine={true}
                        name="notes"
                        label="Notes"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormikSubmitButton label="Submit" />
                    </Col>
                  </Row>
                  {
                    message &&
                    <Row className="mt-2">
                      <Col>
                        <h6 className="text-warning">{message}</h6>
                      </Col>
                    </Row>
                  }
                </Container>
              </FormikForm>
            </Formik>
          </Card.Body>
        }
      </Card>
    </Col >
  )
}

export default CompleteRequestedPayment;

const FormSchema = Yup.object().shape({
  notes: Yup.string(),
  paidBy: Yup.string()
    .required('Required'),
  amount: Yup.number()
    .required('Required'),
})
