import React, { useCallback, useState } from "react";
import { Button, ButtonProps } from "react-bootstrap";

interface ConfirmButtonProps extends ButtonProps {
  confirmText: string;
}

/*
* Simple button that must be confirmed one time before calling 'onClick'
*/
const ConfirmButton: React.FC<ConfirmButtonProps> = ({ confirmText, onClick, children, ...buttonProps }) => {
  const [confirm, setConfirm] = useState(false);

  const handleButtonClick = useCallback((event) => {
    if (confirm) {
      // In confirm state so fire the onClick
      onClick?.(event);
    }
    setConfirm((prev) => !prev);
  }, [onClick, confirm])

  return (
    <Button {...buttonProps} onClick={handleButtonClick}>
      {confirm ? confirmText : children}
    </Button>
  )
}

export default ConfirmButton;
