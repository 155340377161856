import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Page, Program } from "../Program";
import { useFormik } from "formik";
import { Button, Col, Form, Spinner, Row } from "react-bootstrap";
import useRole from "../../../UseRole";
import ReactQuill from "react-quill";
import '../../textEditor.css'

const PagesEdit = (props: any) => {
  const isAdmin = useRole("admin");
  let history = useHistory();

  const page: Page = props.page;
  const program: Program = props.program;
  const [content, setContent] = useState(page?.HTML);
  const [htmlViewType, setHtmlViewType] = useState<"wysiwyg" | "raw">("raw");

  const formik = useFormik({
    initialValues: page ? page : {},

    onSubmit: async (values: any, { setSubmitting }) => {
      const workingPage = page ? page : new Page();
      workingPage.id = values.key ? values.key : workingPage.key;
      workingPage.key = values.key ? values.key : workingPage.key;
      workingPage.name = values.name ? values.name : workingPage.name;
      workingPage.HTML = content ? content : "";
      workingPage.URL = values.URL ? values.URL : workingPage.URL;
      workingPage.programKey = program?.key ? program.key : "";
      await workingPage.persist();

      setSubmitting(false);
      history.goBack();
    },
  });

  if (!isAdmin) {
    return <h3>Admin Only</h3>;
  }

  return (
    <Col xs={12}>
      <Form onSubmit={formik.handleSubmit}>
        <br></br>

        <Form.Row>
          <Form.Group as={Col} xs={6} sm={4} controlId="name">
            <Form.Label>Name</Form.Label>
            <Form.Control
              // @ts-ignore
              type="text"
              name="name"
              //@ts-ignore
              value={formik.values?.name ? formik.values.name : ""}
              onChange={formik.handleChange}
              placeholder="Enter Name"
            />
          </Form.Group>
          <Form.Group as={Col} xs={6} sm={4} controlId="key">
            <Form.Label>Key</Form.Label>
            <Form.Control
              // @ts-ignore
              readOnly={page}
              type="text"
              name="key"
              //@ts-ignore
              value={formik.values?.key ? formik.values.key : ""}
              onChange={formik.handleChange}
              placeholder="Enter Key"
            />
          </Form.Group>
          <Form.Group as={Col} xs={12} controlId="URL">
            <Form.Label>URL</Form.Label>
            <Form.Control
              // @ts-ignore
              type="text"
              name="URL"
              //@ts-ignore
              value={formik.values?.URL ? formik.values.URL : ""}
              onChange={formik.handleChange}
              placeholder="Enter URL (if no HTML)"
            />
          </Form.Group>
          <Form.Group as={Col} xs={12} controlId="HTML">
            <Form.Label>
              HTML (View as:{" "}
              <select
                value={htmlViewType}
                placeholder="View As..."
                onChange={(e) => {
                  setHtmlViewType(e.target.value as any);
                }}
              >
                <option value="wysiwyg">WYSIWYG</option>
                <option value="raw">Raw HTML</option>
              </select>
              )
            </Form.Label>
            {htmlViewType === "wysiwyg" && (
              <ReactQuill
                value={content}
                onChange={(newContent) => {
                  setContent(newContent);
                }}
              ></ReactQuill>
            )}
            {htmlViewType === "raw" && (
              <Row>
                <textarea
                  style={{ width: "75%" }}
                  rows={20}
                  value={content}
                  onChange={(e) => {
                    setContent(e.target.value);
                  }}
                />
              </Row>
            )}
          </Form.Group>
        </Form.Row>

        <Button
          disabled={formik.isSubmitting}
          variant="primary"
          type="submit"
          onClick={() => {
            formik.handleSubmit();
          }}
        >
          {formik.isSubmitting ? (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          ) : (
            <>Submit</>
          )}
        </Button>
      </Form>
    </Col>
  );
};

export { PagesEdit };
