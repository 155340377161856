import React, { useEffect, useState } from "react";
import { Button, Col, Table, Row } from "react-bootstrap";
import useRole from "../../UseRole";
import { BlacklistedVersion } from "../../Model/BlacklistedVersion";
import AdminNavbar from "../AdminNavbar";
import { firestore } from "firebase";
import AddVersionModal from "./VersionEditors/AddVersionModal";
import { useHistory, useRouteMatch } from "react-router-dom";

const BlacklistedVersionsList = () => {
  const isAdmin = useRole("admin");

  const [versions, setVersions] = useState<BlacklistedVersion[]>([]);
  const [isAdding, setAddingMode] = useState(false);
  const match = useRouteMatch();
  let history = useHistory();

  const addBlacklistVersion = () => {
    setAddingMode(true);
  };

  const deleteBlacklistVersion = async (version: BlacklistedVersion) => {
    if (
      window.confirm(
        `Are you sure you want to delete lesson '${version.fullVersionName}'?`
      )
    ) {
      await firestore()
        .collection("tempest")
        .doc("versions")
        .collection("blacklist")
        .doc(version.fullVersionName)
        .delete();
    }
  };

  useEffect(() => {
    const snap = firestore()
      .collection("tempest")
      .doc("versions")
      .collection("blacklist")
      .onSnapshot((snapshot) => {
        if (!snapshot.empty)
          setVersions(
            snapshot.docs.map((doc) => BlacklistedVersion.fromFirestore(doc))
          );
      });
    return snap;
  }, []);

  if (!versions) {
    return <></>;
  }

  return (
    <>
      <AdminNavbar />

      {match.isExact && (
        <>
          {isAdding && (
            <AddVersionModal
              versions={versions}
              close={() => setAddingMode(false)}
            />
          )}
          <>
            <Row className={"mt-4"}>
              <Col
                xs={10}
                className={"main-content"}
                style={{ paddingLeft: 40, paddingTop: 40 }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <h4>Blacklisted Versions</h4>
                </div>

                <Table bordered hover responsive={"xl"}>
                  <thead>
                    <tr>
                      <th>Version Name</th>
                      <th>Reason</th>
                      <th>Platform</th>
                      <th>Created</th>
                      <th>Created By</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {versions.map((version, index) => {
                      return (
                        <tr key={index} className="lesson-row">
                          <td>{version.fullVersionName}</td>
                          <td>{version.reason}</td>
                          <td>{version.platform}</td>
                          <td>{version.created?.toLocaleDateString()}</td>
                          <td>{version.createdBy}</td>
                          <td>
                            {isAdmin && (
                              <Button
                                variant="danger"
                                style={{ margin: 5 }}
                                onClick={() => deleteBlacklistVersion(version)}
                              >
                                Delete Version
                              </Button>
                            )}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </Col>
            </Row>
            <Row>
              <Col xs={3} style={{ margin: 20 }}>
                {isAdmin && (
                  <Button
                    variant="primary"
                    style={{ margin: 5 }}
                    onClick={() => addBlacklistVersion()}
                  >
                    Create a Version
                  </Button>
                )}
                {isAdmin && (
                  <Button
                    variant="warning"
                    style={{ margin: 5 }}
                    onClick={() => {
                      history.push(match.url + "/edit");
                    }}
                  >
                    Edit HTML for Blacklisted Screen
                  </Button>
                )}
              </Col>
            </Row>
          </>
        </>
      )}
    </>
  );
};

export default BlacklistedVersionsList;
