import { GatingBucket, IGatingRules } from '@swing-therapeutics/swingcore/dist/models/Session';
import { firestore } from 'firebase';
import React, { useCallback, useMemo, useState } from 'react';
import { Modal, Row } from 'react-bootstrap';
import { Formik, Form as FormikForm, useField } from 'formik';
import * as yup from 'yup';
import { FormikSubmitButton, FormikTextField } from '../../FormikFields';

interface Props {
  show: boolean;
  onHide: () => void;
  programKey: string;
  editRules: firestore.QueryDocumentSnapshot | 'new';
}

// Extend to edit id of gating rule document
interface EditGatingRule extends IGatingRules {
  id: string;
}

interface MessageState {
  msg: string;
  error: boolean;
}

const defaultMessageState: MessageState = {
  msg: '',
  error: false,
}

const GatingRuleModal: React.FC<Props> = ({ show, onHide, programKey, editRules }) => {
  const [messageState, setMessageState] = useState({ ...defaultMessageState });

  const intialFormValues = useMemo(() => {
    if (editRules === 'new') {
      const newRule: EditGatingRule = {
        id: '',
        margin: 7,
        target: 56,
        sessionsAllowed: {
          [GatingBucket.A]: 0,
          [GatingBucket.B]: 1,
          [GatingBucket.C]: 2,
          [GatingBucket.D]: 3,
        }
      }
      return newRule;
    }
    return { ...editRules.data() as IGatingRules, id: editRules.id };
  }, [editRules])


  const closeModal = useCallback(() => {
    setMessageState({ ...defaultMessageState });
    onHide();
  }, [onHide])

  return (
    <Modal show={show} onHide={closeModal}>
      <Formik
        initialValues={{ ...intialFormValues }}
        onSubmit={async (values, actions) => {
          try {
            // Pull the id from the data, its the id of the document
            // Doesnt need to be saved on the document
            const { id, ...ruleData } = values;
            if (editRules === 'new') {
              // New rule
              const newDocRef = firestore().doc(`programs/${programKey}/gatingRules/${id}`);
              const doc = await newDocRef.get();
              if (doc.exists) {
                throw `Rule with cohort '${id}' already exists`;
              }
              await newDocRef.set(ruleData);
              closeModal();
            } else {
              // Edit rule
              await editRules.ref.update(ruleData);
              setMessageState({ msg: 'Saved', error: false });
            }
          } catch (error) {
            // If a string is thrown, just a validation error
            if (typeof error === 'string') {
              setMessageState({ msg: error, error: true });
            } else {
              setMessageState({ msg: 'Error saving', error: true });
            }
          }
          actions.setSubmitting(false);
        }}
        validationSchema={FormSchema}
      >
        <FormikForm role="form">
          <Modal.Header closeButton>
            <Modal.Title>
              {editRules === 'new' ? 'New gating rule' : 'Edit gating rule'}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FormikTextField
              name="id"
              label="Cohort"
              desc="The cohort that this rule applies to"
              disabled={editRules !== 'new'}
            />
            <FormikTextField
              name="margin"
              label="Margin"
              type="number"
              desc="The margin for the gating bucket calculation"
            />
            <FormikTextField
              name="target"
              label="Target"
              type="number"
              desc="The target for the gating bucket calculation"
            />
            <EditSessionsAllowed />
            <Row className="justify-content-center">
              <FormikSubmitButton label="Save" />
            </Row>
            <Row className="justify-content-center">
              <h6 className={`mt-2 ${messageState.error ? 'text-warning' : 'text-success'}`}>{messageState.msg}</h6>
            </Row>
          </Modal.Body>
        </FormikForm>
      </Formik>
    </Modal>
  )
}

export default GatingRuleModal;

const EditSessionsAllowed: React.FC = () => {
  const [fieldInput] = useField('sessionsAllowed');

  return (
    <>
      {/* Alphabetical sort the keys */}
      {Object.keys(fieldInput.value).sort((a, b) => a.localeCompare(b)).map((bucket) => (
        <FormikTextField
          name={`sessionsAllowed.${bucket}`}
          label={`${bucket} # of sessions allowed`}
          type="number"
          desc={`The number of sessions allowed while the user is in ${bucket}`}
        />
      ))}
    </>
  )
}

const FormSchema = yup.object().shape({
  id: yup.string().required('Required'),
  margin: yup.number().required('Required'),
  // Target cannot be zero because then calculation will divide by 0 throwing error
  target: yup.number().test('Is zero?', 'Target cannot be 0', (value) => value !== 0).required('Required'),
  sessionsAllowed: yup.object().shape({
    [GatingBucket.A]: yup.number().required('Required'),
    [GatingBucket.B]: yup.number().required('Required'),
    [GatingBucket.C]: yup.number().required('Required'),
    [GatingBucket.D]: yup.number().required('Required'),
  })
})
