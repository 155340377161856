import React, { useEffect, useState } from "react";
import { Col, Row, Button, Table } from "react-bootstrap";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
  ResponderProvided,
} from "react-beautiful-dnd";
import { useFormik } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { AddTaskModal } from "./AddTaskModal";
import { CelebrationEditor } from "./CelebrationEditor";
import { useHistory, useRouteMatch } from "react-router-dom";
import LoadingScreen from "../../../LoadingScreen";
import { Dictionary, isNil } from "lodash";
import { Celebration } from "@swing-therapeutics/swingcore/dist/models/content/Celebration";
import { SessionTask } from "./SessionTask";
import { UnlockActivities } from "./UnlockActivities";
import { useDocumentClassData } from "../../../../utils/Hooks";
import { SessionTemplate } from "@swing-therapeutics/swingcore/dist/models/Session";
import { getSessionsTemplates } from "@swing-therapeutics/swingcore/dist/utils/queries";
import { EditTaskModal } from "./EditTaskModal";

interface SessionEditorProps {
  celebrations: any;
  celebrationsById: Dictionary<Celebration>;
}

interface urlParams {
  programKey: string;
  playlistID: string;
  sessionID: string;
}

const SessionEditor = ({
  celebrations,
}: SessionEditorProps) => {
  const match = useRouteMatch(
    "/programs/:programKey/playlists/:playlistID/:sessionID/tasks"
  );
  const history = useHistory();

  const { programKey, playlistID, sessionID } = match.params as urlParams;

  const query = getSessionsTemplates(programKey, playlistID, sessionID);

  const [session, sessionLoading] = useDocumentClassData(SessionTemplate, query)
  const [dragAndDropDisabled, setdragAndDropDisabled] = useState(true);
  const [showAddTask, setShowAddTask] = useState(false);
  const [selectedEditIndex, setSelectedEditIndex] = useState<number | undefined>();
  const closeAddTask = () => setShowAddTask(false);

  const goBack = () => {
    history.push(
      `/programs/${match.params["programKey"]}/playlists/${match.params["playlistID"]}`
    );
  };

  const onDragEnd = async (result: DropResult, provided: ResponderProvided) => {
    if (
      !result.destination ||
      Number(result.draggableId) === result.destination.index
    ) {
      return;
    }

    const draggedTaskIndex = Number(result.draggableId);
    const targetIndex = Number(result.destination.index);
    let draggedElement = session.tasks.splice(draggedTaskIndex, 1)[0];
    session.tasks.splice(targetIndex, 0, draggedElement);
    await session.persist(playlistID);
  };

  const deleteTask = async (index: number) => {
    if (window.confirm(`Are you sure you want to delete Task ${index + 1}?`)) {
      session.tasks.splice(index, 1);
      await session.persist(playlistID);
    }
  };

  const warningMessage = () => {
    if (
      window.confirm(
        "You have unsaved changes, leaving will delete these changes. Are you sure you want to exit?"
      )
    ) {
      goBack();
    }
  };

  if (sessionLoading) {
    return <LoadingScreen />;
  }

  return (
    <div style={{ width: '100%' }}>
      <Row style={{ paddingLeft: 15 }}>
        <Col style={{ marginTop: 15 }}>
          <Row>
            <Col xs="auto">
              <Button onClick={goBack}>
                <FontAwesomeIcon icon={faArrowLeft} style={{ marginRight: 6 }} />
                Close
              </Button>
            </Col>
          </Row>
          <Col>
            <Row className="mt-3">
              <h4>
                <b>{session.playlist}</b>, session {session.num}
              </h4>
            </Row>
            <Row>
              <div style={{ color: 'gray' }}><i>(warning: changes on this page are applied automatically)</i></div>
            </Row>
          </Col>

          <SessionSection title="Celebration">
            <CelebrationEditor
              session={session}
              celebrations={celebrations}
              sessionDoc={query}
            />
          </SessionSection>

          <SessionSection title="Unlocked Activities">
            <UnlockActivities session={session} sessionDoc={query} programID={programKey} />
          </SessionSection>

          <SessionSection title="Tasks">
            <Row style={{ marginBottom: 15 }}>
              <Col>
                <Button
                  variant="outline-primary"
                  onClick={() => setShowAddTask(true)}
                  style={{ marginRight: "5px" }}
                >
                  Add New Task
                </Button>
                <Button
                  variant={dragAndDropDisabled ? "outline-info" : "outline-warning"}
                  onClick={() => setdragAndDropDisabled(!dragAndDropDisabled)}
                >
                  {dragAndDropDisabled ? "Enable reordering" : "Disable reordering"}
                </Button>
              </Col>
            </Row>
            {/* Task List */}
            <DragDropContext onDragEnd={onDragEnd}>
              <Table hover responsive="xl">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Display Name</th>
                    <th>Type</th>
                    <th>ID</th>
                    <th>Info</th>
                    <th>Image filename</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <Droppable droppableId="taskDroppable">
                  {(droppableProvided) => (
                    <>
                      <tbody
                        {...droppableProvided.droppableProps}
                        ref={droppableProvided.innerRef}
                      >
                        {!!(session.tasks && session.tasks.length) &&
                          session.tasks.map((taskInfo: any, index: number) => {
                            return (
                              <Draggable
                                draggableId={index.toString()}
                                index={index}
                                isDragDisabled={dragAndDropDisabled}
                                key={`drag-${index}`}
                              >
                                {(provided) => (
                                  <SessionTask
                                    index={index}
                                    task={taskInfo}
                                    deleteTask={deleteTask}
                                    editTask={setSelectedEditIndex}
                                    provided={provided}
                                  />
                                )}
                              </Draggable>
                            );
                          })}
                      </tbody>
                    </>
                  )}
                </Droppable>
              </Table>
            </DragDropContext>
          </SessionSection>
          <AddTaskModal
            show={showAddTask}
            close={closeAddTask}
            session={session}
            playlistKey={match.params["playlistID"]}
          />
          {

          }
          <EditTaskModal
            show={!isNil(selectedEditIndex) && !isNil(session.tasks[selectedEditIndex])}
            close={() => setSelectedEditIndex(undefined)}
            session={session}
            task={session.tasks?.[selectedEditIndex]}
            playlistID={match.params["playlistID"]}
            taskIndex={selectedEditIndex}
          />
        </Col>
      </Row>
    </div>
  );
};

const SessionSection = ({ title, children }: { title: string, children?: any }) => (
  <Col className="border-bottom border-dark" style={{ paddingTop: 15, paddingBottom: 15 }} xl={12}>
    <h4 style={{ marginBottom: 5 }}>{title}</h4>
    {children ?? <></>}
  </Col>
)

export { SessionEditor };
