import React, { useEffect, useState } from "react";
import { firestore } from "../../../firebase";
import LoadingScreen from "../../LoadingScreen";
import { Button, Col, Row, Table } from "react-bootstrap";
import AwarenessModal from "./AwarenessModal";
import useRole from "../../../UseRole";
import { AwarenessCloneModal } from "./AwarenessCloneModal";
import { Activity } from '@swing-therapeutics/swingcore/dist/models/content/Activity';
import { useCollectionClassData } from "../../../utils/Hooks";

export default ({ programKey }: { programKey: string }) => {
  const isAdmin = useRole("admin");
  const query = firestore
    .collection("programs")
    .doc(programKey)
    .collection("awarenessActivities");


  const [activities, loading] = useCollectionClassData(Activity, query);

  const [showEdit, setShowEdit] = useState(false);
  const [activityToEdit, setActivityToEdit] = useState<Activity>();

  const [showCloneModal, setShowCloneModal] = useState(false);
  const [activitiesToClone, setActivitiesToClone] = useState<Activity[]>([]);

  const closeEditor = () => {
    setActivityToEdit(undefined);
    setShowEdit(false);
  };

  const deleteActivity = async (activity: Activity) => {
    if (
      window.confirm(
        `Are you sure you want to delete activity '${activity.title}'?`
      )
    ) {
      await firestore
        .collection("programs")
        .doc(programKey)
        .collection("awarenessActivities")
        .doc(activity.key)
        .delete();
    }
  }

  const getTableRow = (activity: Activity, index: number) => (
    <tr key={activity.uuid}>
      <td>{activity.title}</td>
      <td>{activity.key}</td>
      <td>{activity.description}</td>
      <td>{activity.unlockedByDefault ? '✅' : '🔒'}</td>
      {isAdmin && 
        <td>
          <Col xs={4}>
            <Button
              variant="outline-primary"
              style={{ margin: 5}}
              onClick={() => {
                setActivityToEdit(activity);
                setShowEdit(true);
              }}
            >
              Edit
            </Button>
            <Button
              variant="outline-info"
              style={{ margin: 5}}
              onClick={() => {
                setShowCloneModal(true);
                setActivitiesToClone([activities[index]]);
              }}
            >
              Clone
            </Button>
            <Button
              variant="outline-danger"
              style={{ margin: 5}}
              onClick={() => deleteActivity(activity)}
            >
              Delete
            </Button>
          </Col>
        </td>
      }
    </tr>
  );

  useEffect(() => {
    if (showCloneModal === false) {
      setActivitiesToClone([]);
    }
  }, [showCloneModal]);

  if (loading) {
    return <LoadingScreen />;
  }

  return (
    <>
      <AwarenessModal
        show={showEdit}
        close={closeEditor}
        programKey={programKey}
        activity={activityToEdit}
      />
      <AwarenessCloneModal 
        show={showCloneModal} 
        close={() => {
          setShowCloneModal(false);
          setActivitiesToClone([]);
        }}
        activities={activitiesToClone}
        programKey={programKey} 
      />
      <Col>
        <Col>
          <Row>
            <h4 className="table-title">Awareness Activities</h4>
          </Row>
        </Col>
        <Row className={"playlist-list"}>
          {isAdmin && 
            <Col>
              <Button
                variant="outline-primary"
                style={{ marginBottom: 10 }}
                onClick={() => setShowEdit(true)}
              >
                Add New Awareness Activity
              </Button>
              {!!activities.length && <Button
                variant="outline-info"
                style={{ marginLeft: 10, marginBottom: 10 }}
                onClick={() => {
                  setActivitiesToClone(activities);
                  setShowCloneModal(true);
                }}
              >
                Clone All
              </Button>}
            </Col>
          }
          <Col xs={12}>
            <Table bordered hover responsive="xl" className={"table-striped"}>
              <thead key="playlistsIndex">
                <tr>
                  <th>Title</th>
                  <th>key</th>
                  <th>Description</th>
                  <th>Unlocked By Default</th>
                  {isAdmin && <th></th>}
                </tr>
              </thead>
              <tbody>{activities?.map((activity, index) => getTableRow(activity, index))}</tbody>
            </Table>
          </Col>
        </Row>
      </Col>
    </>
  );
};
