import {firestore} from "../../firebase";
import {v4 as idMaker} from "uuid";

class NotificationType {
    id: string = idMaker();
    key: string = "";
    name: string = "";
    description: string = "";
    program: string = "";
    created: Date = new Date();
    messages: object[] = [];

    static fromFirestore(docRef: any) {
        if (!docRef.id) {
            return;
        }
        let notif = new NotificationType();
        let data = docRef.data();

        notif.id = docRef.id;
        notif.key = data.key;
        notif.name = data.name;
        notif.description = data.description;
        notif.created = data.created.toDate();
        notif.messages = data.messages;
        notif.program = data.program;
        console.log("Notif", notif);


        return notif;


    }

    data() {
        return {
            id: this.id,
            key: this.key,
            created: this.created,
            updated: new Date(),
            program: this.program,
            name: this.name,
            description: this.description,
            messages: this.messages
        };
    }

    async persist() {
        let res = await firestore.collection('tempest')
            .doc('notifications').collection('types').doc(this.id).set(this.data());
        return res;
    }


}


export default NotificationType;