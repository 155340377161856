import React from "react";
import { useCollection, } from "react-firebase-hooks/firestore";
import { ReactComponent as Loading } from "../../../Images/loading.svg";
import LoadingScreen from "../../../LoadingScreen";
import { DocumentData, firestore } from "../../../../firebase";
import { Table, } from "react-bootstrap";
import moment from "moment";
import { UserSectionProps } from "./UserSectionInterface";

interface TableRowProps {
    usageDoc: DocumentData;
    showAppDeviceInfo?: boolean;
}

const TableRow: React.FC<TableRowProps> = ({ usageDoc, showAppDeviceInfo }) => {
    let durationText = "";
    const usageRow = usageDoc.data();
    if (usageRow.timeClosed) {
        let started = moment(usageRow.timeOpened.toDate());
        let completed = moment(usageRow.timeClosed.toDate());
        let duration = moment.duration(started.diff(completed));
        durationText = duration.humanize();
    }

    return (
        <tr>
            <td>{usageRow.timeOpened.toDate().toLocaleString()}</td>
            <td>{usageRow.timeClosed ? usageRow.timeClosed.toDate().toLocaleString() : ""}</td>
            <td>
                {durationText}
            </td>
            {
                showAppDeviceInfo &&
                <>
                    <td>{usageRow.appVersion}</td>
                    <td>{usageRow.deviceOS}</td>
                    <td>{usageRow.deviceOSVersion}</td>
                </>
            }
        </tr>
    );
}

const UserUsageTable: React.FC<UserSectionProps> = ({ user, programID }) => {
    const uid = user.uid;
    const [usage, usageLoading, usageError] = useCollection(
        programID ?
            firestore
                .collection("users")
                .doc(uid)
                .collection("programs")
                .doc(programID)
                .collection("usage")
                .orderBy("timeOpened", "desc")
            :
            firestore
                .collection("users")
                .doc(uid)
                .collection("appUsage")
                .orderBy("timeOpened", "desc"),
        {
            snapshotListenOptions: { includeMetadataChanges: true },
        }
    );

    return (<>
        {usageError && (<>
            <strong>Error getting Usage: {JSON.stringify(usageError)}</strong>
            <br></br>
            <Loading></Loading>
        </>)}
        {usageLoading && <LoadingScreen></LoadingScreen>}
        {usage && (<Table responsive>
            <thead>
                <tr>
                    <th>App Opened</th>
                    <th>App Closed</th>
                    <th>App Open</th>
                    {
                        !programID &&  // A specific program's usage doc does not contain app/os version info
                        <>
                            <th>App Version</th>
                            <th>OS</th>
                            <th>OS Version</th>
                        </>
                    }
                </tr>
            </thead>
            <tbody>
                {usage.docs.map((usageDoc) =>
                    <TableRow key={usageDoc.id} usageDoc={usageDoc} showAppDeviceInfo={!programID} />
                )}
            </tbody>
        </Table>)}
    </>);
};

export default UserUsageTable;
