import React from "react";
import { Button, Col, Row, } from "react-bootstrap";
import {
  ConfirmationActivity,
} from "../../../Model/Playlist";
import Icon from "../../../icons/Icon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinusCircle } from "@fortawesome/free-solid-svg-icons";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

const rowStyle = { marginRight: 5, height: 30 }


const getOnDragEnd = (reorder) => {
  return (result) => {
    if (!result.destination) {
      return;
    }
    
    reorder(
      result.source.index,
      result.destination.index
    );
  }
}


export const getActivityRows = (
  edit: boolean,
  reorder: Function, 
  deleteActivity: Function,
  activities?: ConfirmationActivity[], 
  ) => {
    if (activities?.length > 0) {
      return (
        <DragDropContext onDragEnd={getOnDragEnd(reorder)}>
          <Droppable droppableId="droppable">
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {activities.map((activity, index) => (
                  <Draggable
                    isDragDisabled={!edit}
                    key={index}
                    draggableId={`activity-${index}`}
                    index={index}
                  >
                    {(provided) => (
                      <Row
                        className={edit ? "border-top border-bottom border-light" : ""}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        {edit && 
                          <Button
                            disabled={!edit}
                            variant="activity-add"
                            onClick={() => deleteActivity(index)}
                            style={{...rowStyle, right: 0, position: "absolute"}}
                          >
                            <FontAwesomeIcon
                              icon={faMinusCircle}
                              color="coral"
                              style={{
                                height: 20,
                                width: 20,
                                marginRight: 10,
                              }}
                            />
                          </Button>
                        }
                        <Col xs={1} style={rowStyle}>
                          {Icon(activity.icon)}
                        </Col>
                        <Col xs={6} style={rowStyle}>
                          {activity.description}
                        </Col>
                      </Row>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      );
    }
    return <></>;
  };