import React from "react";
import { faBook, faClinicMedical, faPlus } from "@fortawesome/free-solid-svg-icons";
import { Col, Container, Nav, Row } from "react-bootstrap";
import { Route, RouteComponentProps } from "react-router";
import AdminNavbar from "../AdminNavbar";
import NavLinker from "../NavLinker";
import NudgingOverview from "./NudgingOverview";
import NudgeList from "./NudgeList";
import NudgeCreateEdit from "./NudgeCreateEdit";

const ManageNudging: React.FC<RouteComponentProps> = ({ match }) => {

  return (
    <>
      <AdminNavbar />
      <div className={"album bg-light"}>
        <Container fluid={true} style={{ backgroundColor: "white" }}>
          <Row>
            <Col xs={2} className={"sidebar"}>
              <div className={"sidebar-sticky"}>
                <Nav className={"flex-column "}>
                  <NavLinker
                    baseUrl={match.url}
                    route=""
                    label="Overview"
                    icon={faClinicMedical}
                  />
                  <NavLinker
                    baseUrl={match.url}
                    route="/nudges"
                    label="Nudges"
                    icon={faBook}
                  >
                    <NavLinker
                      subLink={true}
                      baseUrl={match.url + "/nudges"}
                      route="/new"
                      label="Create"
                      icon={faPlus}
                    />
                  </NavLinker>
                </Nav>
              </div>
            </Col>
            <Col xs={10} className={"main-content dashboard-container"}>
              <Row className={"dashboard-content"}>
                <Col>
                  <Row className="dashboard-heading align-items-center">
                    <h2>
                      Nudge Administration 👉
                    </h2>
                  </Row>
                  <Route
                    path={match.url}
                    exact
                    component={NudgingOverview}
                  />
                  <Route
                    path={match.url + "/nudges"}
                    exact
                    component={NudgeList}
                  />
                  <Route
                    path={match.url + "/nudges/:action/:nudgeID?"}
                    component={NudgeCreateEdit}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

export default ManageNudging;
