import React from "react";
// import GraphTemplate from "../Graphs/GraphTemplate";
import GraphTemplateEPROsA from "../../Graphs/GraphTemplateEPROsA";
import AnotherReportsListSidebar from "./AnotherReportsListSidebar";

import AdminNavbar from "../../AdminNavbar";
import { Col, Container, Row,Jumbotron } from "react-bootstrap";

const A004PIPSgraphs = () => {
  return (
    <>
      <AdminNavbar />
      <Jumbotron>
            <Container fluid={true} style={{paddingBottom: "30px", paddingRight: "40px"}}>
                <Row>
                    <Col>
                        <h1>PIPS, by Therapy Week</h1>
                        <div className="TitleCb">12 questions, 1-7 pts each; max = 84 goal: decrease</div>
                    </Col>
                </Row>
            </Container>
        </Jumbotron>
      <AnotherReportsListSidebar/>
      <Container fluid={true} style={{paddingLeft: "40px"}}>
      {/* style={{align:'left'}} */}
        <div className="mt-4">
        <div className="article" style={{paddingLeft: "40px"}}>
          {/* <h4>PIPS</h4> */}
          <Row>
            <Col xs={10}>
              <GraphTemplateEPROsA report={"ACT_pips004A"} />
            </Col>
          </Row>
          <Row>
            <Col xs={10}>
              <GraphTemplateEPROsA report={"ST_pips004A"} />
            </Col>
          </Row>
          </div>
        </div>
      </Container>
    </>
  );
};

export default A004PIPSgraphs;
{/* <AdminNavbar/>
<Jumbotron>
    <Container fluid={true}>
        <Row>
            <Col>
                <h1>004 Participants</h1>
            </Col>
        </Row>
    </Container>
</Jumbotron>
<AnotherReportsListSidebar/> */}