import { firestore } from "../../../firebase";
import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row, Table } from "react-bootstrap";

import useRole from "../../../UseRole";
import LoadingScreen from "../../LoadingScreen";
import { JournalEditModal } from "./JournalEditModal";
import { JournalCloneModal } from "./JournalCloneModal";
import { Route, Switch, useRouteMatch } from "react-router";
import { JournalQuestionScreen } from "./JournalQuestionScreen";
import { Link } from "react-router-dom";
import { JournalTemplate } from "@swing-therapeutics/swingcore/dist/models/journals/JournalTemplate";
import { useCollectionClassData } from "../../../utils/Hooks";
import { checkForJournalsToMigrate, JournalMigrationComponent } from "../../../utils/migrations/journalTypeMigration";

const defaultJournal = new JournalTemplate({
  displayName: "",
  description: "",
  unlockedByDefault: false,
});

export const JournalList = ({ programKey }: { programKey: string }) => {
  const isAdmin = useRole("admin");
  const { url } = useRouteMatch("/programs/:programID/journals")

  const query = firestore.collection("programs").doc(programKey).collection("journalTypes");

  const [journals, loading] = useCollectionClassData(JournalTemplate, query)
  const [needToMigrate, setNeedToMigrate] = useState(false);

  const [showEditModal, setShowEditModal] = useState(false);
  const [editableJournal, setEditableJournal] = useState<JournalTemplate>(defaultJournal);

  const [showCloneModal, setShowCloneModal] = useState(false);
  const [cloneableJournal, setCloneableJournal] = useState<JournalTemplate[]>([]);

  const deleteJournal = async (journal: JournalTemplate) => {
    if (window.confirm(`Are you sure you want to delete journal "${journal.displayName}"?`)) {
      await query.doc(journal.id).delete();
    }
  };

  const addNewJournal = () => {
    setShowEditModal(true);
  };

  useEffect(() => {
    setNeedToMigrate(checkForJournalsToMigrate(journals))
  }, [journals])

  const getTableRow = (journal: JournalTemplate) => (
    <tr key={journal.id}>
      <td>
        <Link to={url + '/' + journal.id}>
          {journal.displayName}
        </Link>
      </td>
      <td>{journal.description}</td>
      <td>{journal.unlockedByDefault?.toString()}</td>
      {isAdmin && (
        <td className="text-center" style={{ width: "240px", maxWidth: "240px" }}>
          <Button
            variant="outline-primary"
            className="mr-1"
            onClick={() => {
              setEditableJournal(journal);
              setShowEditModal(true);
            }}
          >
            Edit
          </Button>
          <Button
            variant="outline-info"
            className="mr-1"
            onClick={() => {
              setShowCloneModal(true);
              setCloneableJournal([journal]);
            }}
          >
            Clone
          </Button>
          <Button variant="outline-danger" onClick={() => deleteJournal(journal)}>
            Delete
          </Button>
        </td>
      )}
    </tr>
  );

  if (loading) {
    return <LoadingScreen />;
  }

  if (needToMigrate) {
    return (
      <Container>
        <Row>
          <Col>
            <div>Before Journals can be edited, they need to be migrated.</div>
            <JournalMigrationComponent programKey={programKey} />
          </Col>
        </Row>
      </Container>
    )
  }

  return (
    <Switch>
      <Route exact path={url}>
        <Container fluid>
          <JournalEditModal
            show={showEditModal}
            close={() => {
              setShowEditModal(false);
              setEditableJournal(defaultJournal);
            }}
            programKey={programKey}
            journal={editableJournal}
          />

          <JournalCloneModal
            show={showCloneModal}
            close={() => {
              setShowCloneModal(false);
              setCloneableJournal([]);
            }}
            journals={cloneableJournal}
            programKey={programKey}
          />

          <Row className="flex-nowrap align-items-center justify-content-between mb-3">
            <Col className="flex-grow-1 flex-shrink-0">
              <h4 className="my-0">Journals</h4>
            </Col>
            {isAdmin && (
              <Col className="d-flex align-items-center justify-content-end flex-grow-0 flex-shrink-1">
                <Button
                  variant="outline-primary"
                  className="text-nowrap mr-2"
                  onClick={() => addNewJournal()}
                >
                  Add New Journal
                </Button>
                {!!journals.length && (
                  <Button
                    variant="outline-info"
                    className="text-nowrap"
                    onClick={() => {
                      setCloneableJournal(journals);
                      setShowCloneModal(true);
                    }}
                  >
                    Clone All
                  </Button>
                )}
              </Col>
            )}
          </Row>
          <Table bordered hover responsive>
            <thead>
              <tr>
                <th>Name</th>
                <th className='text-center'>Description</th>
                <th className='text-center'>Unlocked by Default</th>
                <th></th>
              </tr>
            </thead>
            <tbody>{journals.map(journal => getTableRow(journal))}</tbody>
          </Table>
        </Container>
      </Route>
      <Route exact={false} path={url + '/(.*)'}>
        <JournalQuestionScreen />
      </Route>
    </Switch>
  );
};
