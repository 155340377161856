import { faArrowDown, faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { UserStatus } from "@swing-therapeutics/surveybay/dist/types";
import { Form as FormikForm, Formik } from "formik";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { Button, Col, Container, FormControl, Row, Table } from "react-bootstrap";
import { useHistory } from "react-router";
import { auth, DocumentData, firebase, firestore, functions } from "../../../firebase";
import LandingPage from "../../../Model/LandingPage";
import User from "../../../Model/Usert";
import { surveysaurusLog } from "../../../utils/logging/surveysaurus";
import { LogTypes } from "../../../utils/logging/surveysaurus/enums";
import ConfirmButton from "../../Dashboards/ConfirmButton";
import { FormikSelect, FormikSubmitButton } from "../../FormikFields";
import LoadingScreen from "../../LoadingScreen";
import { workflowStatusInfo } from "../SurveysaurusStats/SurveySaurusStats";

const initQueryFilters = {
  workflowStatus: 'all',
}

type BrowserSort = 'created_asc' | 'created_dsc' | '';

const sortByCreated = (users: User[], sort: 'asc' | 'dsc'): User[] => {
  if (sort === 'asc') {
    return users.slice().sort((u1, u2) => u2.created.getTime() - u1.created.getTime());
  }
  else {
    return users.slice().sort((u1, u2) => u1.created.getTime() - u2.created.getTime());
  }
}

const SurveysaurusOverview: React.FC = () => {
  const [users, setUsers] = useState<User[]>();
  const [sortedUsers, setSortedUsers] = useState<User[]>();
  // Filters that are applied via a query
  const [queryFilters, setQueryFilters] = useState({ ...initQueryFilters });
  // Filters that are applied in browser
  const [browserSort, setBrowserSort] = useState<BrowserSort>('created_asc');
  // Array of landing page keys that have an extension associated with them
  const [lpEXTExists, setLPEXTExists] = useState<string[]>([]);
  // Search filter
  const [searchFilter, setSearchFilter] = useState('');

  // Initially sort the user array by set browser filters
  const initialBrowserSort = (users: User[]): User[] => {
    switch (browserSort) {
      case 'created_asc':
        return sortByCreated(users, 'asc');
      case 'created_dsc':
        return sortByCreated(users, 'dsc');
      default:
        return users
    }
  }

  useEffect(() => {
    setUsers(null);
    // Create the query to get all surveysaurus users
    let baseQuery = firestore.collection("users");
    let query: firebase.firestore.Query<DocumentData>;

    if (queryFilters.workflowStatus !== 'all') {
      query = baseQuery.where("workflowStatus", "==", queryFilters.workflowStatus);
    }
    else {
      query = baseQuery.where("workflowStatus", "!=", "");
    }

    const unsubscribe = query.onSnapshot((snap) => {
      const users = [];
      for (const user of snap.docs) {
        users.push(User.fromFirestore(user))
      }
      setUsers(users);
    }, (error) => {
      console.error("Error loading surveysaurus users", error);
    })

    return unsubscribe;
  }, [queryFilters])

  useEffect(() => {
    if (!users) {
      setSortedUsers(null);
    }
    else {
      setSortedUsers(initialBrowserSort(users));
    }
  }, [users, browserSort])

  useEffect(() => {
    (async () => {
    // Get all landing pages
      const lpDocSnaps = await firestore.collection('web/surveysaurus/landingPages').get();
      const _lpEXTExists: string[] = [];
      for (const docSnap of lpDocSnaps.docs) {
        const lpData = LandingPage.fromFirestore(docSnap);
        if (lpData.extensionID) {
          // Landing page has an extension, add to array of landing pages
          // That have an extension
          _lpEXTExists.push(lpData.landingPageKey);
        }
      }
      setLPEXTExists(_lpEXTExists);
    })();
  }, [])

  const handleCreatedClick = () => {
    if (browserSort !== 'created_asc' && browserSort !== 'created_dsc') {
      setSortedUsers((users) => sortByCreated(users, 'asc'));
      setBrowserSort('created_asc');
    }
    else {
      if (browserSort === 'created_asc') {
        setSortedUsers((users) => sortByCreated(users, 'dsc'));
        setBrowserSort('created_dsc');
      }
      else {
        setSortedUsers((users) => sortByCreated(users, 'asc'));
        setBrowserSort('created_asc');
      }
    }
  }

  return (
    <Container>
      <Row className="mb-3">
        <h3>Overview</h3>
      </Row>
      <Row className="mb-3">
        <Col>
          <MoveToScreeningButton />
        </Col>
      </Row>
      <Row className="mb-4">
        <Formik
          initialValues={{ ...queryFilters }}
          enableReinitialize={true}
          onSubmit={async (values, actions) => {
            setQueryFilters(values);
            actions.setSubmitting(false);
          }}
        >
          <FormikForm role="form" style={{ width: '100%' }}>
            <Container>
              <Row className="align-items-center">
                <Col xs={3}>
                  <FormikSelect
                    name="workflowStatus"
                    label="Workflow status"
                    options={Object.values(UserStatus).map((userStatus) => {
                      return { value: userStatus, label: workflowStatusInfo[userStatus].title };
                    })}
                  />
                </Col>
                <Col xs={2}>
                  <FormikSubmitButton
                    label="Apply filters"
                  />
                </Col>
              </Row>
            </Container>
          </FormikForm>
        </Formik>
      </Row>
      <Row className="mb-3">
        <FormControl
          type="text"
          value={searchFilter}
          onChange={(e) => setSearchFilter(e.target.value)}
          placeholder='Search...'
        />
      </Row>
      <Row>
        {
          sortedUsers ?
            <Table striped responsive>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Landing page</th>
                  <th>Workflow status</th>
                  <th className="hoverable" onClick={handleCreatedClick}>
                    Created
                    {
                      (browserSort === 'created_asc' || browserSort === 'created_dsc') &&
                      <FontAwesomeIcon icon={browserSort === 'created_asc' ? faArrowUp : faArrowDown} className="ml-2" />
                    }
                  </th>
                  <th>
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                {sortedUsers.map((user) => (
                  <UserRow user={user} key={user.uid} searchFilter={searchFilter} lpEXTExists={lpEXTExists} />
                ))}
              </tbody>
            </Table>
            :
            <LoadingScreen />
        }
      </Row>
    </Container>
  )
}

export default SurveysaurusOverview;

interface UserRowProps {
  user: User;
  searchFilter: string;
  lpEXTExists: string[];
}

const UserRow: React.FC<UserRowProps> = ({ user, searchFilter, lpEXTExists }) => {
  const history = useHistory();
  const [extExists, setExtExists] = useState(false);
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const handleRowClick = useCallback((event: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => {
    if (!(event.target instanceof HTMLButtonElement)) {
      // Button element actions take priority over row click
      // The click event is not a button, perform whatever needs to happen on a row click
      history.push(`/users/${user.uid}`);
    }
  }, [history, user.uid])

  const handleMoveToExtensionEligible = useCallback(async () => {
    try {
      setLoading(true);
      const batch = firestore.batch();
      batch.update(firestore.doc(`users/${user.uid}`), { workflowStatus: UserStatus.EXT_ELIGIBLE, landingPageKey: user.study, extEligibleManual: true });
      // Couple this update with a log
      await surveysaurusLog({
        landingPageKey: user.landingPageKey || user.study,
        fromStatus: user.workflowStatus,
        toStatus: UserStatus.EXT_ELIGIBLE,
        userUID: user.uid,
        subjectID: user.subjectID,
        movedBy: auth.currentUser.displayName,
        timestamp: new Date(),
        type: LogTypes.USERMOVE,
      }, batch);
      await batch.commit();
    } catch (error) {
      console.error(error);
      setMessage('Error moving user to extension eligible');
    } finally {
      setLoading(false);
    }
  }, [user.uid, user.study, user.landingPageKey, user.workflowStatus, user.subjectID])

  useEffect(() => {
    // If the user's landing page key exists in the array of landing page keys
    // That include an extension, then the extension option exists for the user
    setExtExists(lpEXTExists.includes(user.landingPageKey || user.study));
  }, [lpEXTExists, user.landingPageKey, user.study])

  return (
    <>
      {
        (searchFilter === '' ||
          user.email.toLowerCase().includes(searchFilter.toLowerCase()) ||
          user.displayName.toLowerCase().includes(searchFilter.toLowerCase())) &&
        <tr className="hoverablerow" onClick={handleRowClick}>
          <td>
            {user.displayName}
          </td>
          <td>
            {user.email}
          </td>
          <td>
            {user.landingPageKey}
          </td>
          <td>
            {user.workflowStatus}
          </td>
          <td>
            {moment(user.created).format("MM/DD/yyyy")}
          </td>
          <td>
            <Col>
              <Row>
                {user.workflowStatus === UserStatus.DONE && extExists &&
                  <ConfirmButton confirmText="Confirm move" onClick={handleMoveToExtensionEligible} disabled={loading}>
                    {loading ? 'Moving user...' : 'Move user to EXT'}
                  </ConfirmButton>
                }
              </Row>
              {
                message &&
                <Row>
                  {message}
                </Row>
              }
            </Col>
          </td>
        </tr>
      }
    </>
  )
}

const MoveToScreeningButton: React.FC = () => {
  const [usersInPreScreen, setUsersInPreScreen] = useState<number>();
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    const unsub = firestore.collection("users")
      .where("workflowStatus", "==", UserStatus.HOLDING_PRE_SCREEN)
      .onSnapshot((snap) => {
        let totalInPreScreen = 0;
        for (const user of snap.docs) {
          totalInPreScreen++;
        }
        setUsersInPreScreen(totalInPreScreen);
      })
    return () => { unsub() };
  }, [])

  const handleButtonClick = async () => {
    setSubmitting(true);
    const moveHoldingPreScreenUsers = functions.httpsCallable('moveHoldingPreScreenUsers');
    const result = await moveHoldingPreScreenUsers({});
    if (result.data.error) {
      console.error("Error moving users", result.data.error);
      setError(result.data.error.message);
    }
    else {
      setError("");
    }
    setSubmitting(false);
  }

  return (
    <Row className="align-items-center">
      <h5 className="mr-2">{usersInPreScreen} users in {UserStatus.HOLDING_PRE_SCREEN}</h5>
      <Button
        color="primary"
        onClick={handleButtonClick}
        disabled={!usersInPreScreen || submitting}
      >
        {
          submitting ?
            'Moving users...'
            :
            'Move all to screening'
        }
      </Button>
      {
        error &&
        <h5 className="text-warning ml-2">{error}</h5>
      }
    </Row>
  )
}
