import { IConsentMeta, IDisclaimer, IScheduleCallsMeta, ITerminationCallMeta, ITherapyMeta, SurveyInfo, SurveyStatus, WorkflowID, WorkflowIDMap } from "@swing-therapeutics/surveybay/dist/types";
import { migrateConsentMetaDoc } from "@swing-therapeutics/surveybay/dist/utils/migrations";
import { DocumentData, DocumentSnapshot, firestore, Timestamp } from "../firebase";

// A surveysaurus workflow document, this is a special document in that
// each workflow doc in the workflow collection has different data associated with it
export class Workflow {
  workflowID: string;
  workflowName: string;
  // Each workflow has different fields of data associated with it
  workflowData: WorkflowData;
  updated: Date | null;
  docPath: string;

  constructor(props, docPath) {
    const { workflowID, updated, ...workflowData } = props;
    this.workflowID = workflowID;
    this.docPath = docPath;
    this.workflowData = parseInDates(workflowID, workflowData);
    this.updated = updated ? updated.toDate() : null;
    this.workflowName = workflowNameFromID(workflowID);
  }

  async persist(): Promise<'Succes' | Error> {
    const data = {
      workflowID: this.workflowID,
      // Inside the class the workflow data is stored as it's own property
      // Deconstruct the data back to how the document is stored
      ...this.workflowData,
      updated: new Date(),
    }
    try {
      await firestore.doc(this.docPath).set(data, { merge: true });
    } catch (error) {
      console.error(`Error persisting workflow ${this.workflowID}`, error);
      return error as Error;
    }
    return 'Succes';
  }

  static fromFirestore(docSnap: DocumentSnapshot<DocumentData>): Workflow {
    const docData = docSnap.data();

    return new Workflow(docData, docSnap.ref.path);
  }
}

type WorkflowData = DisclaimerWorkflowData | EligibilityWorkflowData | ConsentWorkflowData | TherapyWorkflowData | BaselineWorkflowData | ScheduleCallWorkflowData | TerminationCallWorkflowData | TerminationSurveysWorkflowData;

export interface DisclaimerWorkflowData extends IDisclaimer { };
export interface ConsentWorkflowData extends IConsentMeta { };
export interface ScheduleCallWorkflowData extends IScheduleCallsMeta { };
export interface TerminationCallWorkflowData extends ITerminationCallMeta { };

export interface TerminationSurveysWorkflowData {
  terminationSurveys: SurveyInfo[];
}

export interface EligibilityWorkflowData {
  eligibilitySurveys: SurveyInfo[];
}

export interface BaselineWorkflowData {
  baselineSurveys: SurveyInfo[];
}

export interface TherapyWorkflowData extends ITherapyMeta { };

// Transcribe workflowIDs to names for displaying
const workflowNameFromID = (workflowID: string): string => {
  switch (workflowID) {
    case WorkflowID.DISCLAIMER:
      return 'Disclaimer';
    case WorkflowID.ELIGIBILITY:
      return 'Eligibility screening';
    case WorkflowID.CONSENT:
      return 'Consent';
    case WorkflowID.EXT_CONSENT:
      return 'EXT Consent';
    case WorkflowID.BASELINE:
      return 'Baseline surveys';
    case WorkflowID.EXT_SCHEDULED_CALLS:
      return 'EXT Scheduled calls';
    case WorkflowID.THERAPY:
      return 'Therapy surveys';
    case WorkflowID.EXT_THERAPY:
      return 'EXT Therapy surveys';
    case WorkflowID.EXT_EARLY_TERM_SURVEYS:
      return 'EXT Early Termination surveys';
    case WorkflowID.EARLY_TERM_SURVEYS:
      return 'Early Termination surveys';
    case WorkflowID.EXT_EARLY_TERM_CALL:
      return 'EXT Early Termination call';
    case WorkflowID.EARLY_TERM_CALL:
      return 'Early Termination call';
    default:
      console.error(`Could not transcribe workflowID ${workflowID} to a name`);
      return 'Unknown';
  }
}

// Convert all the firestore timestamps to js date objects
const parseInDates = (workflowID: string, workflowData: any): WorkflowData => {
  switch (workflowID) {
    case WorkflowID.ELIGIBILITY:
      (workflowData as EligibilityWorkflowData).eligibilitySurveys.forEach(survey => {
        survey.updated = (survey.updated as unknown as Timestamp).toDate();
        survey.assigned = (survey.assigned as unknown as Timestamp).toDate();
        survey.expires = (survey.expires as unknown as Timestamp).toDate();
      });
      return workflowData;
    case WorkflowID.BASELINE:
      (workflowData as BaselineWorkflowData).baselineSurveys.forEach(survey => {
        survey.updated = (survey.updated as unknown as Timestamp).toDate();
        survey.assigned = (survey.assigned as unknown as Timestamp).toDate();
        survey.expires = (survey.expires as unknown as Timestamp).toDate();
      });
      return workflowData;
    case WorkflowID.EARLY_TERM_SURVEYS:
    case WorkflowID.EXT_EARLY_TERM_SURVEYS:
      (workflowData as TerminationSurveysWorkflowData).terminationSurveys.forEach(survey => {
        survey.updated = (survey.updated as unknown as Timestamp).toDate();
        survey.assigned = (survey.assigned as unknown as Timestamp).toDate();
        survey.expires = (survey.expires as unknown as Timestamp).toDate();
      })
      return workflowData;
    case WorkflowID.EXT_THERAPY:
    case WorkflowID.THERAPY:
      (workflowData as TherapyWorkflowData).therapySurveys.forEach(therapySurvey => {
        therapySurvey.surveys.forEach(survey => {
          survey.updated = (survey.updated as unknown as Timestamp).toDate();
          survey.assigned = (survey.assigned as unknown as Timestamp).toDate();
          survey.expires = (survey.expires as unknown as Timestamp).toDate();
        });
      })
      workflowData.therapyStartDay = workflowData.therapyStartDay.toDate();
      return workflowData;
    case WorkflowID.EXT_CONSENT:
    case WorkflowID.CONSENT:
      workflowData.dateOfCall = workflowData.dateOfCall ? workflowData.dateOfCall.toDate() : null;
      workflowData.callEndTime = workflowData.callEndTime ? workflowData.callEndTime.toDate() : null;
      workflowData.callStartTime = workflowData.callStartTime ? workflowData.callStartTime.toDate() : null;
      return migrateConsentMetaDoc(workflowData, WorkflowIDMap[workflowID]);
    case WorkflowID.EARLY_TERM_CALL:
    case WorkflowID.EXT_EARLY_TERM_CALL:
      workflowData.dateOfCall = workflowData.dateOfCall ? workflowData.dateOfCall.toDate() : null;
      workflowData.callEndTime = workflowData.callEndTime ? workflowData.callEndTime.toDate() : null;
      workflowData.callStartTime = workflowData.callStartTime ? workflowData.callStartTime.toDate() : null;
      return workflowData;
    default:
      return workflowData;
  }
}

// Color code a survey status
export const getColorFromStatus = (status: SurveyStatus) => {
  switch (status) {
    case SurveyStatus.ASSIGNED:
      return 'var(--indigo)';
    case SurveyStatus.COMPLETED:
      return 'var(--success)';
    case SurveyStatus.IN_PROGRESS:
      return 'var(--cyan)';
    case SurveyStatus.EXPIRED:
    case SurveyStatus.EXPIRED_IN_PROGRESS:
      return 'var(--warning)';
    case SurveyStatus.FAILED:
      return 'var(--danger)';
    default:
      return '';
  }
}
