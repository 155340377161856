import * as Yup from "yup";

export const FormSchema = Yup.object().shape({
    metadata: Yup.object().shape({
        title: Yup.string().required('Required'),
        description: Yup.string().required('Required'),
    }),
    header: Yup.object().shape({
        title: Yup.object().shape({
            text: Yup.string().required('Required'),
            color: Yup.string().required('Required'),
        }),
        subtitle: Yup.object().shape({
            text: Yup.string().required('Required'),
            color: Yup.string().required('Required'),
        }),
    }),
    landingPageKey: Yup.string()
        .min(2, 'Too short!')
        .max(15, 'Too long!')
        .matches(/^[a-zA-Z0-9_-]*$/, 'Only charaters and numbers allowed')
        .required('Required'),
    sectionOne: Yup.object().shape({
        title: Yup.object().shape({
            text: Yup.string().required('Required'),
            color: Yup.string().required('Required'),
        }),
        subtitle: Yup.object().shape({
            text: Yup.string().required('Required'),
            color: Yup.string().required('Required'),
        }),
        button: Yup.object().shape({
            text: Yup.string().required('Required'),
            color: Yup.string().required('Required'),
            link: Yup.string().required('Required'),
        }),
    }),
    sectionTwo: Yup.object().shape({
        title: Yup.object().shape({
            text: Yup.string().required('Required'),
            color: Yup.string().required('Required'),
        }),
        subtitle: Yup.object().shape({
            text: Yup.string().required('Required'),
            color: Yup.string().required('Required'),
        }),
        subsectionOne: Yup.object().shape({
            title: Yup.object().shape({
                text: Yup.string().required('Required'),
                color: Yup.string().required('Required'),
            }),
            subtitle: Yup.object().shape({
                text: Yup.string().required('Required'),
                color: Yup.string().required('Required'),
            }),
        }),
        subsectionTwo: Yup.object().shape({
            title: Yup.object().shape({
                text: Yup.string().required('Required'),
                color: Yup.string().required('Required'),
            }),
            subtitle: Yup.object().shape({
                text: Yup.string().required('Required'),
                color: Yup.string().required('Required'),
            }),
        }),
        subsectionThree: Yup.object().shape({
            title: Yup.object().shape({
                text: Yup.string().required('Required'),
                color: Yup.string().required('Required'),
            }),
            subtitle: Yup.object().shape({
                text: Yup.string().required('Required'),
                color: Yup.string().required('Required'),
            }),
        }),
    }),
    footer: Yup.object().shape({
        textColor: Yup.string().required('Required'),
        bgColor: Yup.string().required('Required'),
    }),
})
