import * as Yup from "yup";
import { urlRegExp } from "../../../../utils/Regex";
import { YupPayCondition } from "./yupPayCondition";

export const FormSchema = Yup.object().shape({
  landingPageKey: Yup.string()
    .min(2, 'Too short!')
    .max(15, 'Too long!')
    .matches(/^[a-zA-Z0-9_-]*$/, 'Only charaters and numbers allowed')
    .required('Required'),
  postEligibilityProgram: Yup.string()
    .required('Required'),
  postBaselineProgram: Yup.string()
    .required('Required'),
  postTherapyProgram: Yup.string()
    .required('Required'),
  earlyTerminationProgram: Yup.string()
    .required('Required'),
  cohort: Yup.string()
    .min(2, 'Too Short!')
    .max(30, 'Too Long!')
    .required('Required'),
  arm: Yup.string()
    .min(2, 'Too Short!')
    .max(30, 'Too Long!')
    .required('Required'),
  site: Yup.string()
    .min(2, 'Too Short!')
    .max(30, 'Too Long!')
    .required('Required'),
  study: Yup.string()
    .min(2, 'Too Short!')
    .max(30, 'Too Long!')
    .required('Required'),
  role: Yup.string()
    .min(2, 'Too Short!')
    .max(30, 'Too Long!')
    .required('Required'),
  trialID: Yup.string()
    .min(2, 'Too Short!')
    .max(30, 'Too Long!')
    .required('Required'),
  eligibilityScreening: Yup.array()
    .min(1, 'Select at least one option')
    .required('Required'),
  baselineSurveys: Yup.array()
    .min(1, 'Select at least one option')
    .required('Required'),
  disclaimerText: Yup.string()
    .min(2, 'Too Short!')
    .required('Required'),
  surveyWeeks: Yup.array().of(
    Yup.object().shape({
      week: Yup.number(),
      surveys: Yup.array().of(Yup.string()),
      daysToCompleteTherapySurvey: Yup.string().required()
    })
  ),
  daysToCompleteTherapySurvey: Yup.number()
    .min(2, "Must be greater than 1")
    .required('Required'),
  tempoDownloadLink: Yup.string()
    .matches(urlRegExp, 'Enter valid url')
    .required('Required'),
  weekdaySurveyAssignment: Yup.number()
    .min(0, "Must be 0 or greater")
    .max(6, "Must be less than 7"),
  maxUsersInConsent: Yup.number()
    .min(1, "Must be 1 or greater")
    .required('Required'),
  paymentNotifications: Yup.object().shape({
    eligibility: YupPayCondition,
    baseline: YupPayCondition,
    surveyWeeks: Yup.array().of(YupPayCondition),
  })
})
