
import React, { useState, useEffect } from "react";
import { Form, Col, Row, Container, Button, Modal } from "react-bootstrap";
import PhoneInput, { isPossiblePhoneNumber, formatPhoneNumberIntl } from "react-phone-number-input/input";
import { useFormik } from "formik";
import * as yup from "yup";
import moment from "moment";
import AdminNavbar from "../AdminNavbar";
import { auth, firestore } from "../../firebase";
import { createPrescription, StanzaAndroidLink, StanzaIOSLink, sendText } from "../../utils/services";

const CreatePrescription = () => {
  const [sites, setSites] = useState([]);

  useEffect(() => {
    (async () => {
      const sitesSnap = await firestore.collection(`medrio/sites/available`).get();
      const sites = [];
      for (const siteSnap of sitesSnap.docs) {
        const siteData = siteSnap.data();
        sites.push({
          label: siteData.siteName ?? siteSnap.id,
          value: siteSnap.id,
        })
      }

      setSites(sites);
    })()
  }, [])

  const states = ['AK', 'AL', 'AR', 'AS', 'AZ', 'CA', 'CO', 'CT', 'DC', 'DE', 'FL', 'GA', 'GU', 'HI', 'IA', 'ID', 'IL', 'IN', 'KS', 'KY', 'LA', 'MA', 'MD', 'ME', 'MI', 'MN', 'MO', 'MS', 'MT', 'NC', 'ND', 'NE', 'NH', 'NJ', 'NM', 'NV', 'NY', 'OH', 'OK', 'OR', 'PA', 'PR', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VA', 'VI', 'VT', 'WA', 'WI', 'WV', 'WY'];

  const today = new Date();
  today.setHours(0, 0, 0, 0);

  let initialValues = {
    prescriberName: "",
    NPI: "",
    prescriberEmail: "",
    contactPreference: "phone",
    physicianState: "AK",
    patientFirstName: "",
    patientLastName: "",
    patientEmail: "",
    patientDOB: "",
    requestDate: "",
    filedDate: "",
    activationDate: "",
    length: 90,
    indication: "Fibromyalgia",
    discountCode: "",
    notes: "",
    isTest: false,
    site: "swing care",
    healthieId: ""
  };

  let validationSchema = yup.object().shape({
    prescriberName: yup.string().required("Name is required"),
    NPI: yup.string().required("NPI is required").matches(/^\d{10}$/, "NPI must have exactly 10 digits"),
    prescriberEmail: yup.string().required("Email is required").email("Invalid email format").test(
      "equal",
      "Prescriber and patient email should not match",
      function(value) {
        const ref = yup.ref("patientEmail");
        return value !== this.resolve(ref);
      }
    ),
    patientFirstName: yup.string().required("First name is required"),
    patientLastName: yup.string().required("Last name is required"),
    patientEmail: yup.string().required("Email is required").email("Invalid email format").test(
      "equal",
      "Prescriber and patient email should not match",
      function(value) {
        const ref = yup.ref("prescriberEmail");
        return value !== this.resolve(ref);
      }
    ),
    patientDOB: yup.date().required("Date of birth is required").transform((value) => new Date(value)).max(today, "Please input a valid date"),
    requestDate: yup.date().required("Request date is required").transform((value) => new Date(value)).max(today, "Please input a valid date"),
    filedDate: yup.date().required("Filed date is required").transform((value) => new Date(value)).max(today, "Please input a valid date"),
    activationDate: yup.date().transform((value) => new Date(value)).max(today, "Please input a valid date"),
    discountCode: yup.string().uppercase().matches(/^SWM(\w{3})$/, "Code must have exactly 6 alphanumeric characters"),
    notes: yup.string().max(512, "Notes must have a maximum limit of 512 characters"),
    isTest: yup.boolean(),
    healthieId: yup.string().when("site", {
      is: "swing care",
      then: yup.string().required("Healthie Id is required for Swing Care").matches(/^\d{7}$/, "Healthie Id must have exactly 7 digits")
    })
  });

  const [prescriberPhoneNumber, setPrescriberPhoneNumber] = useState("");
  const [patientPhoneNumber, setPatientPhoneNumber] = useState("");
  const [prescriberPhoneNumberTouched, setPrescriberPhoneNumberTouched] = useState(false);
  const [patientPhoneNumberTouched, setPatientPhoneNumberTouched] = useState(false);

  const phoneInputError = (value) => {
    return value ? (isPossiblePhoneNumber(value) ? undefined : "Invalid phone number format") : "Phone number is required"
  }

  const phoneSameError = (prescriberPhoneNumber && patientPhoneNumber && prescriberPhoneNumber === patientPhoneNumber) ? "Prescriber and patient phone number should not match": undefined;

  const [showModal, setShowModal] = useState(false);

  const {values, errors, touched, handleChange, handleSubmit, resetForm } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      if (phoneInputError(prescriberPhoneNumber) || phoneInputError(patientPhoneNumber)) {
        if (phoneInputError(prescriberPhoneNumber)) {
          setPrescriberPhoneNumberTouched(true);
        }

        if (phoneInputError(patientPhoneNumber)) {
          setPatientPhoneNumberTouched(true);
        }
        return;
      }

      if (phoneSameError) {
        return;
      }

      const message = `Hi ${values.patientFirstName.trim()}! Thank you for choosing Stanza for your care. Please use one of the following links, based on your device, to download and start using Stanza:\nAndroid: ${StanzaAndroidLink}\niOS: ${StanzaIOSLink}`;

      sendText(message, patientPhoneNumber);

      let prescriber = {
        name: values.prescriberName.trim(),
        NPI: values.NPI,
        phone: formatPhoneNumberIntl(prescriberPhoneNumber),
        email: values.prescriberEmail.trim(),
        contactPreference: values.contactPreference,
        state: values.physicianState,
      };

      let patient = {
        firstName: values.patientFirstName.trim().toLowerCase(),
        lastName: values.patientLastName.trim().toLowerCase(),
        name: values.patientFirstName.trim() + " " + values.patientLastName.trim(),
        phone: formatPhoneNumberIntl(patientPhoneNumber),
        email: values.patientEmail.trim(),
        DOB: moment(values.patientDOB).format("MM/DD/YYYY"),
      };

      let prescription = {
        requestDate: new Date(moment(values.requestDate).format("MM/DD/YYYY")),
        filedDate: new Date(moment(values.filedDate).format("MM/DD/YYYY")),
        activationDate: values.activationDate ? new Date(moment(values.activationDate).format("MM/DD/YYYY")) : null,
        length: +values.length,
        indication: values.indication,
        discountCode: values.discountCode.substring(0,3).toUpperCase() + values.discountCode.substring(3),
        notes: values.notes.trim(),
      };

      let prescriptionForm: any = {
        prescriber: prescriber,
        patient: patient,
        prescription: prescription,
        isTest: values.isTest,
        site: values.site,
        createdBy: auth.currentUser?.email || "",
      };

      let healthieIdError = false;

      if (values.healthieId) {
        const existingPrescriptions = await firestore.collection("prescriptions").where("healthieId", "==", values.healthieId).get();

        if (!existingPrescriptions.empty) {
          existingPrescriptions.forEach((doc) => {
            const prescriptionData = doc.data();
            if (!(prescriptionData?.status === 'inactive' || prescriptionData?.status === 'expired')) {
              setShowhealthieErrorModal(true);
              healthieIdError = true;
            }
          })
        }

        prescriptionForm.healthieId = values.healthieId;
      }

      if (healthieIdError) {
        return;
      }

      await createPrescription(prescriptionForm);
      
      setShowModal(true);
      setPrescriberPhoneNumber("");
      setPatientPhoneNumber("");
      setPrescriberPhoneNumberTouched(false); 
      setPatientPhoneNumberTouched(false);
      resetForm();
      
    },
  });

  const [showhealthieErrorModal, setShowhealthieErrorModal] = useState(false);

  return (
    <>
      <AdminNavbar/>

      <Modal centered show={showModal} onHide={() => setShowModal(false)} animation={false}>
        <Modal.Header style={{ display: "flex", justifyContent: "center" }}>
          <Modal.Title>Form is saved successfully</Modal.Title>
        </Modal.Header>
        <Modal.Footer style={{ display: "flex", justifyContent: "center" }}>
          <Button variant="primary" onClick={() => setShowModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal centered show={showhealthieErrorModal} onHide={() => setShowhealthieErrorModal(false)} animation={false}>
        <Modal.Header style={{ display: "flex", justifyContent: "center" }}>
          <Modal.Title>Sorry, the healthie ID you entered is already associated with a prescription, please use a different one.</Modal.Title>
        </Modal.Header>
        <Modal.Footer style={{ display: "flex", justifyContent: "center" }}>
          <Button variant="primary" onClick={() => setShowhealthieErrorModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <h1 style={{textAlign: "center"}}>Create Prescription</h1>
        
      <Form onSubmit={handleSubmit}>
        <Container>
          <Row>
            <Form.Group as={Col} xs={12} md={6} controlId="isTest">
              <Form.Check
                label={"Test Prescription"}
                name={"isTest"}
                checked={values.isTest}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group as={Col} xs={12} md={6} controlId="site">
              <Form.Label>Site</Form.Label>
              <Form.Control 
                as="select"
                name="site" 
                value={values.site}
                onChange={handleChange}
              >
                {sites.map((site) => (
                  <option value={site.value} key={site.value}>{site.label}</option>
                ))}
              </Form.Control>
            </Form.Group>
          </Row>

          <Row>
            <Form.Group as={Col} xs={12} md={6} controlId="prescriberName">
              <Form.Label>Prescriber Name</Form.Label>
              <Form.Control
                type="text"
                name="prescriberName"
                placeholder="Name"
                value={values.prescriberName}
                onChange={handleChange}
                isInvalid={!!errors.prescriberName && !!touched.prescriberName}
              />
              <Form.Control.Feedback type="invalid">
                {errors.prescriberName}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} xs={12} md={6} controlId="NPI">
              <Form.Label>NPI</Form.Label>
              <Form.Control
                type="text"
                name="NPI"
                placeholder="NPI"
                value={values.NPI}
                onChange={handleChange}
                isInvalid={!!errors.NPI && !!touched.NPI}
              />
              <Form.Control.Feedback type="invalid">
                {errors.NPI}
              </Form.Control.Feedback>
            </Form.Group>
          </Row>

          <Row>
            <Form.Group as={Col} xs={12} md={6} controlId="prescriberPhoneNumber">
              <Form.Label>Prescriber Phone Number</Form.Label>
              <br></br>
              <PhoneInput
                country="US"
                name="prescriberPhoneNumber"
                placeholder="(XXX) XXX-XXXX"
                value={prescriberPhoneNumber}
                onChange={setPrescriberPhoneNumber}
                onBlur={() => setPrescriberPhoneNumberTouched(true)}
              />
              <br></br>
              <div className='invalid-feedback d-block'>{prescriberPhoneNumberTouched && phoneInputError(prescriberPhoneNumber) || phoneSameError}</div>
            </Form.Group>

            <Form.Group as={Col} xs={12} md={6} controlId="prescriberEmail">
              <Form.Label>Prescriber Email</Form.Label>
              <Form.Control
                type="email"
                name="prescriberEmail"
                placeholder="example@domain.com"
                value={values.prescriberEmail}
                onChange={handleChange}
                isInvalid={!!errors.prescriberEmail && !!touched.prescriberEmail}
              />
              <Form.Control.Feedback type="invalid">
                {errors.prescriberEmail}
              </Form.Control.Feedback>
            </Form.Group>
          </Row>

          <Row>
            <Form.Group as={Col} xs={12} md={6} controlId="contactPreference">
              <Form.Label>Contact Preference</Form.Label>
              <Form.Control 
                as="select"
                name="contactPreference"
                value={values.contactPreference}
                onChange={handleChange}
              >
                <option value={"phone"}>Phone</option>
                <option value={"email"}>Email</option>
              </Form.Control>
            </Form.Group>

            <Form.Group as={Col} xs={12} md={6} controlId="physicianState">
              <Form.Label>Physician's State of Practice</Form.Label>
              <Form.Control 
                as="select"
                name="physicianState" 
                value={values.physicianState}
                onChange={handleChange}
              >
                {states.map(state => <option value={state} key={state}>{state}</option> )}
              </Form.Control>
            </Form.Group>
          </Row>

          <Row>
            <Form.Group as={Col} xs={12} md={6} controlId="patientFirstName">
              <Form.Label>Patient First Name</Form.Label>
              <Form.Control
                type="text"
                name="patientFirstName"
                placeholder="First Name"
                value={values.patientFirstName}
                onChange={handleChange}
                isInvalid={!!errors.patientFirstName && !!touched.patientFirstName}
              />
              <Form.Control.Feedback type="invalid">
                {errors.patientFirstName}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} xs={12} md={6} controlId="patientLastName">
              <Form.Label>Patient Last Name</Form.Label>
              <Form.Control
                type="text"
                name="patientLastName"
                placeholder="Last Name"
                value={values.patientLastName}
                onChange={handleChange}
                isInvalid={!!errors.patientLastName && !!touched.patientLastName}
              />
              <Form.Control.Feedback type="invalid">
                {errors.patientLastName}
              </Form.Control.Feedback>
            </Form.Group>
          </Row>

          <Row>
            <Form.Group as={Col} xs={12} md={6} controlId="patientPhoneNumber">
              <Form.Label>Patient Phone Number</Form.Label>
              <br></br>
              <PhoneInput
                country="US"
                name="patientPhoneNumber"
                placeholder="(XXX) XXX-XXXX"
                value={patientPhoneNumber}
                onChange={setPatientPhoneNumber}
                onBlur={() => setPatientPhoneNumberTouched(true)}
              />
              <br></br>
              <div className='invalid-feedback d-block'>{patientPhoneNumberTouched && phoneInputError(patientPhoneNumber) || phoneSameError}</div>
            </Form.Group>

            <Form.Group as={Col} xs={12} md={6} controlId="patientEmail">
              <Form.Label>Patient Email</Form.Label>
              <Form.Control
                type="email"
                name="patientEmail"
                placeholder="example@domain.com"
                value={values.patientEmail}
                onChange={handleChange}
                isInvalid={!!errors.patientEmail && !!touched.patientEmail}
              />
              <Form.Control.Feedback type="invalid">
                {errors.patientEmail}
              </Form.Control.Feedback>
            </Form.Group>
          </Row>

          <Row>
            <Form.Group as={Col} xs={12} md={6} controlId="patientDOB">
              <Form.Label>Patient Date of Birth</Form.Label>
              <Form.Control
                type="date"
                name="patientDOB"
                placeholder="MM/DD/YYYY"
                value={values.patientDOB}
                onChange={handleChange}
                isInvalid={!!errors.patientDOB && !!touched.patientDOB}
              />
              <Form.Control.Feedback type="invalid">
                {errors.patientDOB}
              </Form.Control.Feedback>
            </Form.Group>
          
            {values.site === "swing care" &&
              <Form.Group as={Col} xs={12} md={6} controlId="NPI">
                <Form.Label>Healthie Id</Form.Label>
                <Form.Control
                  type="text"
                  name="healthieId"
                  value={values.healthieId}
                  onChange={handleChange}
                  isInvalid={!!errors.healthieId && !!touched.healthieId}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.healthieId}
                </Form.Control.Feedback>
              </Form.Group>
            }
          </Row>

          <Row>
            <Form.Group as={Col} xs={12} md={6} controlId="requestDate">
              <Form.Label>Prescription Request Date</Form.Label>
              <Form.Control
                type="date"
                name="requestDate"
                placeholder="MM/DD/YYYY"
                value={values.requestDate}
                onChange={handleChange}
                isInvalid={!!errors.requestDate && !!touched.requestDate}
              />
              <Form.Control.Feedback type="invalid">
                {errors.requestDate}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} xs={12} md={6} controlId="filedDate">
              <Form.Label>Prescription Filed Date</Form.Label>
              <Form.Control
                type="date"
                name="filedDate"
                placeholder="MM/DD/YYYY"
                value={values.filedDate}
                onChange={handleChange}
                isInvalid={!!errors.filedDate && !!touched.filedDate}
              />
              <Form.Control.Feedback type="invalid">
                {errors.filedDate}
              </Form.Control.Feedback>
            </Form.Group>
          </Row>

          <Row>
            <Form.Group as={Col} xs={12} md={6} controlId="activationDate">
              <Form.Label>Prescription Activation Date</Form.Label>
              <Form.Control
                type="date"
                name="activationDate"
                placeholder="MM/DD/YYYY"
                value={values.activationDate}
                onChange={handleChange}
                isInvalid={!!errors.activationDate && !!touched.activationDate}
              />
              <Form.Control.Feedback type="invalid">
                {errors.activationDate}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} xs={12} md={6} controlId="length">
              <Form.Label>Prescription Length</Form.Label>
              <Form.Control 
                as="select"
                name="length"
                value={values.length}
                onChange={handleChange}
              >
                <option value={90}>90 days</option>
                <option value={180}>180 days</option>
                <option value={365}>365 days</option>
              </Form.Control>
            </Form.Group>
          </Row>

          <Row>
            <Form.Group as={Col} xs={12} md={6} controlId="indication">
              <Form.Label>Indication</Form.Label>
              <Form.Control 
                as="select"
                name="indication" 
                value={values.indication}
                onChange={handleChange}
              >
                <option value={"Fibromyalgia"}>Fibromyalgia</option>
              </Form.Control>
            </Form.Group>

            <Form.Group as={Col} xs={12} md={6} controlId="discountCode">
              <Form.Label>Discount Code</Form.Label>
              <Form.Control
                type="text"
                name="discountCode"
                placeholder="SWMXXX"
                value={values.discountCode}
                onChange={handleChange}
                isInvalid={!!errors.discountCode && !!touched.discountCode}
              />
              <Form.Control.Feedback type="invalid">
                {errors.discountCode}
              </Form.Control.Feedback>
            </Form.Group>
          </Row>

          <Row>
            <Form.Group as={Col} xs={12} controlId="notes">
              <Form.Label>Notes</Form.Label>
              <Form.Control
                as="textarea"
                name="notes"
                placeholder="Notes or special instructions"
                value={values.notes}
                onChange={handleChange}
                isInvalid={!!errors.notes && !!touched.notes}
              />
              <Form.Control.Feedback type="invalid">
                {errors.notes}
              </Form.Control.Feedback>
            </Form.Group>
          </Row>

          <Row>
            <Col style={{textAlign: "center"}}>
              <Button
                variant="primary"
                type="submit"
                onClick={() => {setPrescriberPhoneNumberTouched(true); setPatientPhoneNumberTouched(true);}}
              >
                Submit
              </Button>
            </Col>
          </Row>
        </Container>
      </Form>
    </>
  )
}

export default CreatePrescription;
