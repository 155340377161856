import { Col } from "react-bootstrap";
import React from "react";

const PropertyDisplay = (props: any) => {
  const { name, value } = props;
  if (!value) return <></>;
  return (
    <Col xs={6} sm={4} lg={3}>
      <h5 className={"text-muted"}>
        <strong> {value} </strong>
      </h5>
      <h6>{name}</h6>
    </Col>
  );
};

export { PropertyDisplay };
