import React from "react";
import { Link } from "react-router-dom";
import "./surveyAdherence.css";

import { Container } from "react-bootstrap";
// import AReportTable from "./AReportTable";
// import AsmallerReportTable from "./AsmallerReportTable";
// const query = firestore.collection("analytics/reports/reportPages");
// const [reportPages, reportPagesLoading, reportPagesError] = useCollection(query, {
// snapshotListenOptions: {includeMetadataChanges: true},
// });
// const query = firestore.collection("analytics").doc("kpi_summary")
const AreportsListSidebar = (props) => {
  return (
    <>
      <Container fluid={true} style={{ backgroundColor: "white" }}>
        <div className="sidenav">
        <p>
		<Link className="TitleDB" style={{ paddingLeft: "10px" }} to="/kpis" > KPIs </Link>
		</p>
		<p>
		<Link className="TitleDB" style={{ paddingLeft: "10px" }} to="/app_adherence" > Engagement </Link>
		</p>
		<p>
		<Link className="TitleDB" style={{ paddingLeft: "10px" }} to="/app_progress" > App Progress </Link>
		</p>
		<p>
		<Link className="TitleDB" style={{ paddingLeft: "10px" }} to="/FIQR002">FIQR Scores</Link>
		</p>
		<p>
		<Link className="TitleDB" style={{ paddingLeft: "10px" }} to="/FIQR_adherence" > FIQR Adherence </Link>
		</p>
		<p>
		<Link className="TitleDB" style={{ paddingLeft: "10px" }} to="/nps_scores" > NPS Scores </Link>
		</p>
		<p>
		<Link className="TitleDB" style={{ paddingLeft: "10px" }} to="/pande" > Pacing & Exercise </Link>
		</p>
		<p>
		<Link className="TitleDB" style={{ paddingLeft: "10px" }} to="/interviews" > Interviews </Link>
		</p>
        </div>
      </Container>
    </>
  );
};

export default AreportsListSidebar;
