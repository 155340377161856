import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Modal } from "react-bootstrap";
import { firestore } from "firebase";
import { useCollectionOnce } from "react-firebase-hooks/firestore";
import { Activity } from '@swing-therapeutics/swingcore/dist/models/content/Activity';

interface PlaylistCloneProps {
  show: boolean;
  close: Function;
  programKey: string;
  activities: Activity[];
}

export const AwarenessCloneModal = ({
  show,
  close,
  programKey,
  activities,
}: PlaylistCloneProps) => {
  const initialValues: {selectedProgram: string} = {
    selectedProgram: undefined,
  };

  const [programs] = useCollectionOnce(firestore().collection("programs"));
  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit: async (values) => {
      try {
        const batch = firestore().batch();
        activities.forEach(activity => {
          activity.programKey = values.selectedProgram;
          const newActivity = firestore().collection('programs').doc(values.selectedProgram).collection('awarenessActivities').doc(activity.key);
          batch.set(newActivity, activity.data());
        });
        await batch.commit();
        window.alert('activities cloned successfully!');
      } catch (error) {
        window.alert('an error occurred while trying to clone the activities. Check the developer console for details.');
        console.error(error);
      } finally {
        close();
      }
    },
  });

  useEffect(() => {
    formik.resetForm();
  }, [show]);

  const getProgramOptions = () => {
    if (programs) {
      return programs.docs
        .filter((program) => program.id !== programKey)
        .map((program) => (
          <option key={program.id} value={program.id}>
            {program.id}
          </option>
        ));
    }
  };

  return (
    <>
      <Modal show={show} onHide={close}>
        <Modal.Header closeButton>
          <Modal.Title>Clone Activities</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Col>
            <p>Cloning {activities.length.toString()} {activities.length === 1 ? 'activity' : 'activities'} to this program:</p>
            <Form.Group>
              <Form.Label>Select Program</Form.Label>
            
              <Form.Control
                name="selectedProgram"
                value={formik.values.selectedProgram}
                onChange={formik.handleChange}
                as="select"
              >
                <option value={null} selected disabled>
                  Choose one playlist...
                </option>
                {getProgramOptions()}
              </Form.Control>
            </Form.Group>
          </Col>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => close()} variant="outline-secondary">
            Close
          </Button>
          <Button
            disabled={!formik.values.selectedProgram}
            onClick={() => {
              formik.submitForm();
            }}
            variant="outline-success"
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
