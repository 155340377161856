import { firestore } from "firebase";
import { isNil } from "lodash";
import React, { useEffect } from "react";
import { useFormik } from "formik";
import {
  Button,
  Container,
  Modal,
  FormControl,
  Col,
  Table,
} from "react-bootstrap";
import useRole from "../../UseRole";
import AdminNavbar from "../AdminNavbar";
import * as yup from "yup";

type Study = {
  id: string;
  name: string;
};

export default function Studies() {
  const [studies, setStudies] = React.useState([] as any);
  const [modalOpen, setModalOpen] = React.useState(false);
  const isAdmin = useRole("admin");
  const preExistingIds = studies.map((study) => study.id);

  const validationSchemaFunction = (preExistingIds: []) => {
    return yup.object().shape({
      name: yup
        .string()
        .required("Name is required")
        .notOneOf(preExistingIds, "Study already exists"),
    });
  };

  const { values, errors, handleChange, submitForm } = useFormik({
    initialValues: { name: "" },
    enableReinitialize: true,
    validationSchema: validationSchemaFunction(preExistingIds),
    onSubmit: async (values) => {
      const newStudy: Study = { name: values.name, id: values.name };
      setModalOpen(false);
      await firestore().collection("studies").doc(newStudy.id).set(newStudy);
      window.alert(`${newStudy.name} saved`);
    },
  });
  console.log(errors);

  useEffect(() => {
    const unsubscribe = firestore()
      .collection("studies")
      .onSnapshot({ includeMetadataChanges: true }, (collection) => {
        if (collection.docs.length > 0) {
          setStudies(collection.docs.map((doc) => doc.data()));
        }
      });
    return unsubscribe;
  }, []);

  const deleteStudy = async (studyId: string) => {
    if (isNil(studyId)) {
      window.alert("Error, invalid ID");
      return;
    }

    await firestore().collection("studies").doc(studyId).delete();
    window.alert(`${studyId} deleted`);
  };

  return (
    <div>
      <AdminNavbar />
      <Modal show={modalOpen}>
        <Modal.Header style={{ display: "flex", justifyContent: "center" }}>
          <Modal.Title>Create Study</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ display: "flex", justifyContent: "center" }}>
          <FormControl
            id="name"
            name="name"
            value={values.name}
            type="text"
            onChange={handleChange}
            placeholder="Study Name"
          />
        </Modal.Body>
        <Modal.Footer
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {errors && <p>{errors.name}</p>}
          <div
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              width: "50%",
            }}
          >
            <Button variant="secondary" onClick={() => setModalOpen(false)}>
              Close
            </Button>
            <Button
              variant="primary"
              onClick={() => {
                submitForm();
              }}
            >
              Save Changes
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
      <Container
        fluid={true}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {isAdmin && (
          <Button
            onClick={() => setModalOpen(true)}
            style={{ marginTop: 10, marginBottom: 10 }}
          >
            New Study
          </Button>
        )}
        <Col xs={12}>
          <Table>
            <thead key="studies">
              <tr>
                <th>ID</th>
                <th>Name</th>
                {isAdmin && <th>Actions</th>}
              </tr>
            </thead>
            <tbody>
              {studies.map((study) => (
                <tr key={study.id}>
                  <td>{study.id}</td>
                  <td>{study.name}</td>
                  {isAdmin && (
                    <td>
                      <Button
                        variant="danger"
                        onClick={() => {
                          if (
                            window.confirm(
                              `Are you sure you want to delete ${study.id}?`
                            )
                          ) {
                            deleteStudy(study.id);
                          }
                        }}
                      >
                        Delete
                      </Button>
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Container>
    </div>
  );
}
