import React, { useEffect, useState } from "react"
import { JournalTemplate } from "@swing-therapeutics/swingcore/dist/models/journals/JournalTemplate"
import { firestore } from "firebase"
import { Button, ProgressBar, } from "react-bootstrap"
import useRole from "../../UseRole"
import { useCollectionClassDataOnce } from "../Hooks"
import { PacingJournal } from "@swing-therapeutics/swingcore/dist/models/journals/PacingJournal"

export const JournalMigrationComponent = ({programKey}: {programKey: string}) => {
  const isAdmin = (useRole("admin"));

  const [journals, loadingJournals] = useCollectionClassDataOnce(JournalTemplate, firestore().collection('programs').doc(programKey).collection('journalTypes'));
  const [loading, setLoading] = useState(false);
  const [migratedIDs, setMigratedIDs] = useState([] as string[]); 
  const [nextIndex, setNextIndex] = useState<number | undefined>();

  const total = journals.map(journal => journal.id);
  const progress = parseInt((migratedIDs.length / total.length * 100).toFixed(2));


  useEffect(() => {
    if (nextIndex !== undefined) {
      setLoading(true);
      let journal = journals[nextIndex];
      journal.programKey = programKey // Some journals have missing/wrong program key, must be a bug somewhere else
      journal = migrateJournal(journal);
      journal.persist().then(() => loadNextJournal(journal.id));
    }
  }, [nextIndex])

  const loadNextJournal = (lastID: string) => {
    setMigratedIDs([...migratedIDs, lastID])

    if (nextIndex + 1 > journals.length - 1) {
      setNextIndex(undefined);
      setLoading(false);
      setTimeout(() => window.alert(getCompletedMessage(migratedIDs)), 100)
    } else {
      setNextIndex(nextIndex + 1);
    }
  }

  const startMigration = () => {
    if (journals && window.confirm('Are you sure you want to migrate journals?')) {
      setMigratedIDs([]);
      setNextIndex(0);
    } else {
      window.alert('No journals found to migrate')
    }
  }


  if (!isAdmin || loadingJournals) {
    return <></>
  }

  if (progress >= 100) {
    return <div>✅  Migration Complete!</div>
  }

  if (loading) {
    return (
      <>
        <div>Migrating journals...</div>
        <ProgressBar  now={progress} label={`${progress}%`}/>
      </>
    )
  }

  return (    
    <>
      <div>Press the button below if you would like to migrate journals from using <b>type</b> to <b>questionType</b></div>
      <Button onClick={startMigration} className="mt-2">
        Migrate Journals
      </Button>
    </>
  )
}

const getCompletedMessage = (completed: string[]) => {
  return 'Journals updated:' + completed.map(id => `\n${id}`)
}

export const checkForJournalsToMigrate = (journals: JournalTemplate[]) => {
  for (const journal of journals) {
    const phaseKeys = Object.keys(journal.phases);
    for (const key of phaseKeys) {
      const phase = journal.phases[key];
      if (phase.questions && phase.questions.length > 0) {
        for (const question of phase.questions) {
          if (question.type && !question.questionType) return true;
        }
      }
    }
  }

  return false;
}

export const migrateJournal = (journal: JournalTemplate) => {
  try {
    const isPacing = PacingJournal.isPacingJournalID(journal.id);
    const phaseKeys = Object.keys(journal.phases);
    phaseKeys.forEach(key => {
      const phase = journal.phases[key];
      if (phase.questions && phase.questions.length > 0) {
        phase.questions.map(question => {
          if (question.type) {
            question.questionType = isPacing ? 'pacing-' + question.type : question.type
          } else {
            throw new Error(`Where the heck is the type for: ${question}`)
          }
        })
      }
      journal.phases[key] = phase;
    })
  } catch (error) {
    console.warn(error)
  }
  return journal;
}