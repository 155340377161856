import React, { useEffect, useMemo, useRef, useState } from "react";
import { ErrorMessage, useField } from "formik"
import ErrorMessageRender from "./ErrorMessageRender";
import RichTextEditor from "react-rte";

interface FormikRichTextFieldProps {
  name: string;
  label?: string;
  desc?: string;
  disabled?: boolean;
  placeHolder?: string;
  markupType?: MarkupType;
}

type MarkupType = 'html' | 'markdown';

// Must be a child of a Formik Form component
const FormikRichTextField: React.FC<FormikRichTextFieldProps> = ({ name, label, desc, disabled, placeHolder, markupType = 'html' }) => {
  const [fieldInput, _fieldMeta, fieldHelpers] = useField(name);
  const [markDownContent, setMarkDownContent] = useState(fieldInput.value ?
    RichTextEditor.createValueFromString(fieldInput.value, markupType)
    :
    RichTextEditor.createEmptyValue()
  )

  useEffect(() => {
    const stringMarkDown = markDownContent.toString(markupType);
    if (markupType === 'html' && stringMarkDown === "<p><br></p>") {
      // Empty field
      fieldHelpers.setValue("");
    }
    else {
      fieldHelpers.setValue(stringMarkDown);
    }
    fieldHelpers.setTouched(true);
  }, [markDownContent])

  return useMemo(() =>
    <>
      <div className="form-group">
        {
          label &&
          <label className="mb-0">{label}</label>
        }
        {
          desc &&
          <small className="form-text text-muted">{desc}</small>
        }
        {
          (label || desc) && // Spacer for the label or desc
          <div className="mb-2" />
        }
        <RichTextEditor
          toolbarConfig={toolbarConfig}
          readOnly={disabled}
          as="textarea"
          value={markDownContent}
          onChange={(value) => setMarkDownContent(value)}
          placeholder={placeHolder ?? ""}
        />
        <ErrorMessage name={name} render={ErrorMessageRender} />
      </div>
    </>
    , [markDownContent, name, label, desc, disabled, placeHolder])
}

export default FormikRichTextField;

const toolbarConfig = {
  // Optionally specify the groups to display (displayed in the order listed).
  display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'LINK_BUTTONS', 'BLOCK_TYPE_DROPDOWN'],
  INLINE_STYLE_BUTTONS: [
    { label: 'Bold', style: 'BOLD' },
    { label: 'Italic', style: 'ITALIC' },
    { label: 'Underline', style: 'UNDERLINE' },
    { label: 'Strikethrough', style: 'STRIKETHROUGH' },
  ],
  BLOCK_TYPE_DROPDOWN: [
    { label: 'Normal', style: 'unstyled' },
    { label: 'Heading Large', style: 'header-one' },
    { label: 'Heading Medium', style: 'header-two' },
    { label: 'Heading Small', style: 'header-three' }
  ],
};
