import React from "react";
import "../surveyAdherence.css";
import AdminNavbar from "../../AdminNavbar";

import { Col, Container, Jumbotron, Row } from "react-bootstrap";
import PROkeyTable from "../PROkeyTable";
import PROkeyTableXL from "../PROkeyTableXL";

import AlargerReportTable004A from "../m004/AlargerReportTable004A";
// const query = firestore.collection("analytics/reports/reportPages");
// const [reportPages, reportPagesLoading, reportPagesError] = useCollection(query, {
// snapshotListenOptions: {includeMetadataChanges: true},
// });
// const query = firestore.collection("analytics").doc("kpi_summary")
const exitInterviews004 = (props) => {
  return (
    <>
      <AdminNavbar />
      <Jumbotron>
        <Container fluid={true}>
          <Row>
            <Col>
              <h1>Exit Interviews 004</h1>
            </Col>
          </Row>
        </Container>
      </Jumbotron>

      
      <Container fluid={true} style={{ backgroundColor: "white" }}>
        <div className="article">
           <div className="TitleC">interviews</div> 
           <PROkeyTable fluid={true}  tableKey={"interviews004exit"}></PROkeyTable>
           {/* style={{lineHeight:"1.1"}} */}
          {/* <AlargerReportTable004A fluid={true} style={{lineHeight:"1.1"}} tableKey={"interviews004exit"}></AlargerReportTable004A> */}
        </div>
      </Container>


    </>
  );
};

export default exitInterviews004;
