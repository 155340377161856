import awareness from "./awareness.svg";
import dartboard from "./dartboard.svg";
import journal from "./journal.svg";
import lesson from "./lesson.svg";
import React from "react";

export const AwarenessIcon = <img src={awareness} />;
export const DartboardIcon = <img src={dartboard} />;
export const JournalIcon = <img src={journal} />;
export const LessonIcon = <img src={lesson} />;

export enum IconType {
  awareness = "awareness",
  dartboard = "dartboard",
  journal = "journal",
  lesson = "lesson",
}

export default (type: IconType | string) => {
  switch (type) {
    case IconType.awareness:
      return AwarenessIcon;
    case IconType.dartboard:
      return DartboardIcon;
    case IconType.lesson:
      return LessonIcon;
    case IconType.journal:
      return JournalIcon;
    default:
      return <></>
  }
};
