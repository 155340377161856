import React, { useEffect, useState } from "react";
import LoadingScreen from "../LoadingScreen";
import AdminNavbar from "../AdminNavbar";
import { useHistory, useLocation } from "react-router-dom";

import {
  Button,
  Col,
  Container,
  Form,
  FormControl,
  InputGroup,
  Jumbotron,
  Row,
  Table,
} from "react-bootstrap";
import usePagination from "../../hooks/usePagination";

const RegistrationList = (props) => {
  let { search } = useLocation();
  const pageNumber = new URLSearchParams(search).get("page");
  const searchTerms = ["email", "subjectID", "role", "activeProgram", "study"];
  let history = useHistory();
  const {
    visibleDocs,
    page,
    PaginationButtons,
    documentsError,
    documentsLoading,
    ResetButton,
    SearchButton,
    filter, 
    setFilter,
  } = usePagination(
    "registrations",
    20,
    "email",
    "asc",
    searchTerms,
    Number(pageNumber)
  );

  useEffect(() => {
    if (page.current !== pageNumber) {
      history.replace({
        pathname: "/register",
        search: "?page=" + page.current,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, page.current, pageNumber]);

  const getTableRow = (reg) => {
    return (
      <tr
        key={reg.email}
        onClick={() => {
          if (reg.email === "No results found") {
            return;
          }
          history.push("/register/edit/" + encodeURIComponent(reg.email));
        }}
      >
        <td>{reg.email}</td>
        <td>{reg.subjectID}</td>
        <td>{reg.role}</td>
        <td>{reg.activeProgram}</td>
      </tr>
    );
  };

  return (
    <>
      <AdminNavbar></AdminNavbar>
      <Jumbotron>
        <Container fluid={true}>
          <Row>
            <Col>
              <h1>Registration Records</h1>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form inline style={{ display: "flex", alignItems: "center" }}>
                <InputGroup className="">
                  <FormControl
                    id="search"
                    placeholder="Search"
                    value={filter}
                    onChange={(e) => {
                      setFilter(e.target.value.trim());
                    }}
                  />
                </InputGroup>
                <SearchButton />
                <ResetButton />
              </Form>
            </Col>
            <Col xs="auto">
              <Form inline>
                <InputGroup className="">
                  <Button
                    onClick={() => {
                      history.push("/register/new");
                    }}
                  >
                    Create
                  </Button>
                </InputGroup>
              </Form>
            </Col>
          </Row>
        </Container>
      </Jumbotron>
      <Container fluid={true} style={{ backgroundColor: "white" }}>
        <Row>
          {documentsError && (
            <>
              <strong>Error: {JSON.stringify(documentsError)}</strong>
              <br></br>
            </>
          )}
          {documentsLoading && (
            <>
              <br></br>
              <LoadingScreen></LoadingScreen>
            </>
          )}
          {visibleDocs && (
            <Col xs={12}>
              <Table>
                <thead key="systemEventsTable">
                  <tr>
                    <th>email</th>
                    <th>subjectID</th>
                    <th>role</th>
                    <th>activeProgram</th>
                  </tr>
                </thead>
                <tbody>{visibleDocs.map((doc) => getTableRow(doc))}</tbody>
              </Table>
            </Col>
          )}
        </Row>
        <PaginationButtons />
      </Container>
    </>
  );
};

export default RegistrationList;
