import React, { useEffect, useState } from "react";
import LoadingScreen from "../LoadingScreen";
import { Program } from "./Program";
import { Button, Col, Row } from "react-bootstrap";
import { firestore } from "firebase";
import { ProgramEditor } from "./Programs/ProgramEditor";

const ProgramOverview = (props: any) => {
  const program: Program = props.program;
  const [programs, setPrograms] = useState<Program[]>([]);
  const [showEditor, setShowEditor] = useState(false);

  useEffect(() => {
    firestore()
      .collection("programs")
      .get()
      .then((collection) => {
        if (collection.docs.length > 0) {
          setPrograms(collection.docs.map((doc) => Program.fromFirestore(doc)));
        }
      });
  }, []);

  if (!program) return <LoadingScreen></LoadingScreen>;

  const getDescriptionRow = (name: string, value: string) => {
    if (!value) return <></>;

    return (
      <Row>
        <dt className="col-sm-3 text-right">{name}</dt>
        <dd className="col-sm-9">{value}</dd>
      </Row>
    );
  };

  return (
    <>
      <Row>
        <Col md={8}>
          {getDescriptionRow("Name", program.name)}
          {getDescriptionRow("id", program.id)}
          {getDescriptionRow("Description", program.name)}
          {getDescriptionRow("Content Type", program.contentTypeKey)}
          {getDescriptionRow("Initial Display State", program.initialDisplayState)}
          {getDescriptionRow("Starting Page Key", program.startingPageKey)}
          {getDescriptionRow(
            "Reminder Title",
            program.reminderMessage?.title ?? "-No reminder title-"
          )}
          {getDescriptionRow(
            "Reminder Text",
            program.reminderMessage?.text ?? "-No reminder text-"
          )}
        </Col>
        <Col md={4}>
          <Button variant="outline-primary" onClick={() => setShowEditor(true)}>
            Edit
          </Button>
        </Col>
      </Row>
      <ProgramEditor
        show={showEditor}
        close={() => setShowEditor(false)}
        programToEdit={program}
        programs={programs}
      />
    </>
  );
};

export { ProgramOverview };
