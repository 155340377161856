import React, { useEffect, useState } from "react";
import { ValuesCategory } from "@swing-therapeutics/swingcore/dist/models/values/ValuesCategory";
import { Button, Col, Row, Table } from "react-bootstrap";
import { useCollectionClassData } from "../../../utils/Hooks";
import { getValuesCategoryCollection } from "@swing-therapeutics/swingcore/dist/utils/queries";
import LoadingScreen from "../../LoadingScreen";
import { Route, Switch, useHistory } from "react-router";
import { Link, useRouteMatch } from "react-router-dom";
import { ValuesCategoryForm } from "./ValuesCategoryForm";
import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
  Droppable,
  DropResult,
  ResponderProvided,
} from "react-beautiful-dnd";
import { firestore } from "firebase";
import { ValuesCategoryCloneModal } from "./ValuesCategoryCloneModal";

export const ValuesCategoryList = ({ programKey }: { programKey: string }) => {
  const { url } = useRouteMatch("/programs/:programID/values");
  const history = useHistory();

  const [valuesCategories, loading] = useCollectionClassData(
    ValuesCategory,
    getValuesCategoryCollection(programKey).orderBy("order", "asc")
  );
  const [
    selectedValuesCategory,
    setSelectedValuesCategory,
  ] = useState<ValuesCategory>();
  const [showCloneModal, setShowCloneModal] = useState(false);
  const [dragDropDisabled, setDragDropDisabled] = useState(true);

  const onDragEnd = async (result: DropResult, provided: ResponderProvided) => {
    // do nothing if the drop target is invalid or the row is dropped where it was originally dragged from
    if (
      !result.destination ||
      result.source.index === result.destination.index
    ) {
      return;
    }
    const valuesCategoryIndex = result.source.index;
    const targetIndex = result.destination.index;
    const batch = firestore().batch();
    const newValuesCategories = [...valuesCategories];
    const [valuesCategoryToMove] = newValuesCategories.splice(
      valuesCategoryIndex,
      1
    );
    newValuesCategories.splice(targetIndex, 0, valuesCategoryToMove);

    newValuesCategories.forEach((valuesCategory, index) => {
      valuesCategory.order = index + 1;
      batch.set(valuesCategory.query(), valuesCategory.data());
    });

    try {
      await batch.commit();
    } catch (error) {
      window.alert(
        `Could not reorder values categories. Check the developer console for details.`
      );
      console.error(error);
    }
  };

  const deleteValuesCategory = async (valuesCategory: ValuesCategory) => {
    if (
      !window.confirm(
        "Are you sure you want to delete this values category? This cannot be undone."
      )
    ) {
      return;
    }

    const batch = firestore().batch();
    const newValuesCategories = [...valuesCategories];
    const index = newValuesCategories.findIndex(
      (_valuesCategory) => valuesCategory.id === _valuesCategory.id
    );
    newValuesCategories.splice(index, 1);
    batch.delete(valuesCategory.query());

    newValuesCategories.forEach((valuesCategory, index) => {
      valuesCategory.order = index + 1;
      batch.set(valuesCategory.query(), valuesCategory.data());
    });

    try {
      await batch.commit();
      window.alert("Values Category deleted successfully!");
    } catch (error) {
      window.alert(
        `Could not delete the values category. Check the developer console for details.`
      );
      console.error(error);
    }
  };

  if (loading) {
    return <LoadingScreen />;
  }

  return (
    <>
      <Switch>
        <Route exact path={url}>
          {selectedValuesCategory && (
            <ValuesCategoryCloneModal
              show={showCloneModal}
              close={() => setShowCloneModal(false)}
              valuesCategory={selectedValuesCategory}
            />
          )}
          <Row style={{ marginBottom: "10px" }} className="justify-content-end">
            <Col xs="auto">
              <Link style={{ marginRight: "5px" }} to={url + "/add"}>
                <Button variant="outline-secondary">Add Values Category</Button>
              </Link>
              <Button
                variant={dragDropDisabled ? "outline-info" : "outline-warning"}
                onClick={() => setDragDropDisabled(!dragDropDisabled)}
              >
                {dragDropDisabled ? "Enable reordering" : "Disable reordering"}
              </Button>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <DragDropContext onDragEnd={onDragEnd}>
                {!dragDropDisabled && (
                  <Row>
                    <Col>
                      <p>
                        Values categories can be reordered by dragging and
                        dropping table rows.
                      </p>
                    </Col>
                  </Row>
                )}
                {!valuesCategories.length && (
                  <p>No values categories to show</p>
                )}
                {!!valuesCategories.length && (
                  <Table bordered hover responsive>
                    <thead>
                      <tr>
                        <th>Order</th>
                        <th>ID</th>
                        <th>Name</th>
                        <th>Description</th>
                        {dragDropDisabled && <th></th>}
                      </tr>
                    </thead>
                    <Droppable droppableId="valuesCategoryDroppable">
                      {(droppableProvided) => (
                        <>
                          <tbody
                            {...droppableProvided.droppableProps}
                            ref={droppableProvided.innerRef}
                          >
                            {valuesCategories.map((valuesCategory, index) => (
                              <Draggable
                                isDragDisabled={dragDropDisabled}
                                draggableId={valuesCategory.id}
                                index={index}
                                key={`drag-${valuesCategory.id}`}
                              >
                                {(draggableProvided, draggableSnapshot) => (
                                  <ValuesCategoryListRow
                                    draggableProvider={draggableProvided}
                                    draggableSnapshot={draggableSnapshot}
                                    dragDropDisabled={dragDropDisabled}
                                    valuesCategory={valuesCategory}
                                    setSelectedValuesCategory={
                                      setSelectedValuesCategory
                                    }
                                    onDeleteClick={(valuesCategory) =>
                                      deleteValuesCategory(valuesCategory)
                                    }
                                    onCloneClick={(valuesCategory) => {
                                      setSelectedValuesCategory(valuesCategory);
                                      setShowCloneModal(true);
                                    }}
                                    onEditClick={(valuesCategory) => {
                                      setSelectedValuesCategory(valuesCategory);
                                      history.push(
                                        `/programs/${programKey}/values/edit`
                                      );
                                    }}
                                  />
                                )}
                              </Draggable>
                            ))}
                            {droppableProvided.placeholder}
                          </tbody>
                        </>
                      )}
                    </Droppable>
                  </Table>
                )}
              </DragDropContext>
            </Col>
          </Row>
        </Route>
        <Route exact path={url + "/add"}>
          <ValuesCategoryForm
            order={valuesCategories.length + 1}
            programKey={programKey}
          />
        </Route>
        {selectedValuesCategory && (
          <Route exact path={url + "/edit"}>
            <ValuesCategoryForm
              programKey={programKey}
              valuesCategory={selectedValuesCategory}
              clearSelectedValuesCategory={() =>
                setSelectedValuesCategory(null)
              }
            />
          </Route>
        )}
      </Switch>
    </>
  );
};

interface ValuesCategoryListRowProps {
  valuesCategory: ValuesCategory;
  setSelectedValuesCategory: React.Dispatch<
    React.SetStateAction<ValuesCategory>
  >;
  draggableProvider: DraggableProvided;
  draggableSnapshot: DraggableStateSnapshot;
  dragDropDisabled: boolean;
  onDeleteClick: (valuesCategory: ValuesCategory) => Promise<void>;
  onCloneClick: (valuesCategory: ValuesCategory) => void;
  onEditClick: (valuesCategory: ValuesCategory) => void;
}

const ValuesCategoryListRow = ({
  valuesCategory,
  setSelectedValuesCategory,
  draggableProvider,
  draggableSnapshot,
  dragDropDisabled,
  onDeleteClick,
  onCloneClick,
  onEditClick,
}: ValuesCategoryListRowProps) => {
  return (
    <tr
      {...draggableProvider.draggableProps}
      {...draggableProvider.dragHandleProps}
      ref={draggableProvider.innerRef}
      key={`row-${valuesCategory.id}`}
    >
      <td>{valuesCategory.order}</td>
      <td>{valuesCategory.id}</td>
      <td>{valuesCategory.name}</td>
      <td>{valuesCategory.description}</td>
      {dragDropDisabled && (
        <td>
          <Button
            style={{ marginRight: "5px" }}
            onClick={() => onEditClick(valuesCategory)}
            variant="outline-info"
          >
            Edit
          </Button>
          <Button
            style={{ marginRight: "5px" }}
            onClick={() => onCloneClick(valuesCategory)}
            variant="outline-secondary"
          >
            Clone
          </Button>
          <Button
            variant="outline-danger"
            onClick={() => onDeleteClick(valuesCategory)}
          >
            Delete
          </Button>
        </td>
      )}
    </tr>
  );
};
