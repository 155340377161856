import { faChartLine, faMobileAlt, faPersonBooth, faTicketAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useMemo, useState } from "react";
import { Button, Card, Col, ListGroup, Row } from "react-bootstrap";
import { useCollectionDataOnce } from "react-firebase-hooks/firestore";
import { auth, firestore, functions } from "../../../../firebase";
import User from "../../../../Model/Usert";
import useRole from "../../../../UseRole";
import { Program } from "../../../Content/Program";
import LoadingScreen from "../../../LoadingScreen";

interface UserOverviewProps {
  user: User;
  programs?: Program[];
  activeProgram?: Program;
}

const colPaddingBottom = 25;
const cardStyle = {
  height: '100%',
}

const diffDays = (date1: Date, date2: Date): number => {
  const diffTime = Math.abs(date1.getTime() - date2.getTime());
  return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
}

const UserOverview: React.FC<UserOverviewProps> = ({ user, programs, activeProgram }) => {
  const isAdmin = useRole("admin");
  const [sendDocusignEmailLoading, setSendDocusignEmailLoading] = useState(false);
  const usersActiveProgram = useMemo(() => {
    // Get the active program depending on if a 
    // programs array is supplied or activeProgram is supplied
    if (activeProgram) {
      return activeProgram;
    }
    return programs.find((program) => {
      return program.id === user.activeProgram;
    })
  }, [user, programs, activeProgram]);

  const [programTemplates, programTemplatesLoading] = useCollectionDataOnce(
    usersActiveProgram?.id ? firestore.collection("programs").where("id", "==", usersActiveProgram.id).limit(1) : null
  );
  const programName = (programTemplates?.[0] as unknown as any)?.name || usersActiveProgram?.name;

  if (programTemplatesLoading) {
    return (<LoadingScreen />);
  }

  return (
    <Col xs={12}>
      <Row>
        <Col sm={12} md={6} style={{ paddingBottom: colPaddingBottom }}>
          <Card border="warning" style={cardStyle}>
            <Card.Header className="bg-warning text-white">
              <h3>
                <FontAwesomeIcon
                  icon={faPersonBooth}
                />
                {" "}About
              </h3>
            </Card.Header>
            <ListGroup variant="flush">
              <ListGroup.Item>
                <Row>
                  <Col xs={4}>
                    <h6>Subject ID</h6>
                    <span>{user.subjectID}</span>
                  </Col>
                  <Col xs={8}>
                    <h6>Firebase ID</h6>
                    <span>{user.uid}</span>
                  </Col>
                </Row>
              </ListGroup.Item>
              <ListGroup.Item>
                <Row>
                  <Col xs={4}>
                    <h6>Zendesk ID</h6>
                    <span>{user.zendeskID}</span>
                  </Col>
                  <Col xs={8}>
                    <h6>App Last Opened</h6>
                    <span>
                      {user.lastOpenedTime?.toLocaleString()}
                    </span>
                  </Col>
                </Row>
              </ListGroup.Item>
            </ListGroup>
          </Card>
        </Col>
        <Col sm={12} md={6} style={{ paddingBottom: colPaddingBottom }}>
          <Card border="primary" style={cardStyle}>
            <Card.Header className="bg-primary text-white">
              <h3>
                <FontAwesomeIcon
                  icon={faTicketAlt}
                />
                {" "}Registration
              </h3>
            </Card.Header>
            <ListGroup variant="flush">
              <ListGroup.Item>
                <Row>
                  <Col>
                    <h6>Cohort</h6>
                    <span>{user.cohort}</span>
                  </Col>
                  <Col>
                    <h6>Role</h6>
                    <span>{user.role}</span>
                  </Col>
                </Row>
              </ListGroup.Item>
              <ListGroup.Item>
                <Row>
                  <Col>
                    <h6>Study</h6>
                    <span>{user.study}</span>
                  </Col>
                  <Col>
                    <h6>Site</h6>
                    <span>{user.site}</span>
                  </Col>
                </Row>
              </ListGroup.Item>
            </ListGroup>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col sm={12} md={6} style={{ paddingBottom: colPaddingBottom }}>
          <Card border="info" style={cardStyle}>
            <Card.Header className="bg-info text-white">
              <h3>
                <FontAwesomeIcon
                  icon={faMobileAlt}
                />
                {" "}Device
              </h3>
            </Card.Header>
            <ListGroup variant="flush">
              <ListGroup.Item>
                <Row>
                  <Col xs={4}>
                    <h6>OS Version</h6>
                    <span>
                      {user.deviceOSVersion}
                    </span>
                  </Col>
                  <Col xs={4}>
                    <h6>Device OS</h6>
                    <span>{user.deviceOS}</span>
                  </Col>
                  <Col xs={4}>
                    <h6>Phone</h6>
                    <span>{user.phone}</span>
                  </Col>
                </Row>
              </ListGroup.Item>
              <ListGroup.Item>
                <Row>
                  <Col xs={4}>
                    <h6>App Version</h6>
                    <span>{user.appVersion}</span>
                  </Col>
                  <Col xs={8}>
                    <h6>Device Name</h6>
                    <span>{user.deviceName}</span>
                  </Col>
                </Row>
              </ListGroup.Item>
            </ListGroup>
          </Card>
        </Col>
        {usersActiveProgram &&
          <Col sm={12} md={6} style={{ paddingBottom: colPaddingBottom }}>
            <Card border="success" style={cardStyle}>
              <Card.Header className="bg-success text-white">
                <h3>
                  <FontAwesomeIcon
                    icon={faChartLine}
                  />
                  {" "}Progress
                </h3>
              </Card.Header>
              <ListGroup variant="flush">
                <ListGroup.Item>
                  <Row>
                    <Col>
                      <h6>Active Program</h6>
                      <span>{programName}</span>
                    </Col>
                    <Col>
                      <h6>Therapy Start Date</h6>
                      <span>
                        {usersActiveProgram.therapyStartDate.toLocaleDateString()}
                      </span>
                    </Col>
                  </Row>
                </ListGroup.Item>
                <ListGroup.Item>
                  <Row>
                    <Col>
                      <h6>Enrollment State</h6>
                      <span>
                        {usersActiveProgram.enrollmentState}
                      </span>
                    </Col>
                    <Col>
                      <h6>Therapy Day</h6>
                      <span>
                        {" "}
                        {diffDays(
                          new Date(),
                          usersActiveProgram.therapyStartDate
                        )}
                      </span>
                    </Col>
                  </Row>
                </ListGroup.Item>
              </ListGroup>
            </Card>
          </Col>
        }
      </Row>
      {isAdmin && user?.cohort === "test" && auth.currentUser.uid === user?.uid &&
        <Col sm={12} md={6} style={{ paddingBottom: colPaddingBottom }}>
          <h1>Docusign Test</h1>
          <p>This will send a DocuSign document to the user. 
            After they have completed it, it will update the consentFormStatus to "completed" and send an email to the study conductor (currently developer@swingtherapeutics.com) for the remaining signatures.
            Check your user document after about a minute (the webhook doesn't fire immediately).
          </p>
          <Button onClick={() => {
            setSendDocusignEmailLoading(true);
            functions.httpsCallable('sendConsentFormTest')({}).then(() => {
              window.alert('Email sent! User document should be updated soon with envelopeId and consentFormStatus');
            }).catch((error) => {
              window.alert('An error occurred, could not send the email.');
              console.error(error);
            }).finally(() => {
              setSendDocusignEmailLoading(false);
            });
          }} disabled={sendDocusignEmailLoading}>Send Sample Docusign Email To Myself</Button>
        </Col>
      }
    </Col>
    
  );
};

export default UserOverview;
