import React, { Fragment, useEffect, useReducer, useState } from "react";
import { Button, Col, Table, Form } from "react-bootstrap";
import { Lesson } from "@swing-therapeutics/swingcore/dist/models/content/Lesson";
import { useFormik } from "formik";
import { LessonAndChapterCopyModal } from "./LessonAndChapterCopyModal";
import { usePrograms } from "../ContentGetters";
import { Program } from "../Program";
import { firestore } from "firebase";
import useRole from "../../../UseRole";
import { EditLessonModal } from "./LessonEditors/EditLessonModal";
import { AddLessonModal } from "./LessonEditors/AddLessonModal";
import { Link, useRouteMatch } from "react-router-dom";

interface LessonListProps {
  lessons: Lesson[];
  programKey: string;
  setActiveLesson: React.Dispatch<React.SetStateAction<Lesson>>;
}

const initialValues: {
  checks: Array<Lesson | boolean>;
  selectedProgramID: string | undefined;
} = {
  checks: [],
  selectedProgramID: undefined,
};



const LessonList = ({
  lessons,
  programKey,
  setActiveLesson,
}: LessonListProps) => {
  const isAdmin = useRole("admin");
  const [selectMode, setSelectMode] = useReducer((current) => !current, false);
  const [showCopy, setShowCopy] = useState(false);
  const [lessonToEdit, setLessonToEdit] = useState<Lesson>();

  const [isAdding, setAddingMode] = useState(false);
  const match = useRouteMatch();

  const addLesson = () => {
    setAddingMode(true);
  };

  const editLesson = (lesson: Lesson) => {
    setLessonToEdit(lesson);
  };

  const programs = usePrograms();

  const formik = useFormik({
    initialValues,
    onSubmit: ({ checks, selectedProgramID }) => {
      const batch = firestore().batch();
      for (let lesson of checks as Lesson[]) {
        if (!lesson?.id) continue;
        try {
          lesson.programKey = selectedProgramID;
          lesson.addToBatch(batch);
        } catch (error) {
          console.log("unable to persist lesson", lesson, error);
        }
      }
      batch.commit();
      setFieldValue("checks", []);
      setSelectMode();
    },
  });

  const { values, resetForm, setFieldValue, handleSubmit } = formik;

  const checkBox = (index: number, value: Lesson) => {
    if (values.checks[index]) {
      setFieldValue(`checks.${index}`, false);
    } else {
      setFieldValue(`checks.${index}`, value);
    }
  };

  const selectAll = () => {
    //Selects or deselects all check boxes
    if (values.checks.length === lessons.length) {
      setFieldValue("checks", []);
    } else {
      setFieldValue("checks", lessons);
    }
  };

  const hasSelectedLessons = () => {
    for (let lesson of values.checks) {
      if (lesson !== false) return true;
    }
    return false;
  };

  const deleteLesson = async (lesson: Lesson) => {
    const chapterOfLesson = lesson.chNum;
    const num = lesson.num;
    if (
      window.confirm(
        `Are you sure you want to delete lesson '${lesson.title}'?`
      )
    ) {
      updateLessonNumbers(num, chapterOfLesson);
      await firestore()
      .collection("programs")
        .doc(programKey)
        .collection("lessons")
        .doc(lesson.id)
        .delete();
    }
  }

   /**
   * @param targetNum The target number to update the lesson by
   * @param chapter The current chapter
   */
  const updateLessonNumbers = (lessonNumber: number, chapter: number) => {
    const lessonsInChapter = lessons.filter(
      (lesson) => lesson.chNum === chapter
    );
    const lessonsToIncrement = [];
    for (let i = 0; i < lessonsInChapter.length; i++) {
      if (lessonNumber <= lessonsInChapter[i].num) {
        lessonsInChapter[i].num -= 1;
        lessonsToIncrement.push(lessonsInChapter[i]);
      }
    }
    lessonsToIncrement.forEach((lesson) => lesson.persist());
  };

  return (
    <Col className={"lesson-content"}>
      <>
        {isAdding && (
          <AddLessonModal
            lessons={lessons}
            programKey={programKey}
            close={() => setAddingMode(false)}
          />
        )}
        {lessonToEdit && (
          <EditLessonModal
            lessons={lessons}
            programKey={programKey}
            lesson={lessonToEdit}
            close={() => setLessonToEdit(undefined)}
          />
        )}
        
        {showCopy && (
          <LessonAndChapterCopyModal
            contents={values.checks}
            programs={programs}
            selectProgram={(selectedProgram: Program) => {
              setFieldValue("selectedProgramID", selectedProgram.id);
              handleSubmit();
            }}
            close={() => setShowCopy(false)}
          />
        )}
      
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <h4 className={"table-title"}>Lessons</h4>
          <div>
            <Button
              variant="primary"
              style={{ margin: 5 }}
              onClick={() => {
                addLesson();
              }}
            >
              Add Lesson
            </Button>
            {selectMode && (
              <Button
                variant={hasSelectedLessons() ? "success" : "outline-dark"}
                style={{ marginRight: 10 }}
                disabled={!hasSelectedLessons()}
                onClick={() => setShowCopy(true)}
              >
                Copy
              </Button>
            )}
            <Button
              variant={selectMode ? "primary" : "outline-primary"}
              style={{ margin: 5 }}
              onClick={() => {
                if (selectMode) {
                  resetForm();
                }
                setSelectMode();
              }}
            >
              {selectMode ? "Cancel" : "Select for Copy"}
            </Button>
          </div>
        </div>
        <Form>
          <Table bordered hover responsive={selectMode ? false : "xl"}>
            <thead>
              <tr>
                {selectMode && (
                  <th>
                    <Button onClick={() => selectAll()} variant="success">
                      Select All
                    </Button>
                  </th>
                )}
                <th>Chapter</th>
                <th>Number</th>
                <th>Title</th>
                <th>Description</th>
                <th>Steps</th>
                <th>Unlocked By Default</th>
                <th>Last Edited</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {lessons.map((lesson, index) => {
                return (
                  <Fragment key={index}>
                    <tr
                      className="lesson-row"
                      onClick={() => {
                        if (selectMode) checkBox(index, lesson);
                      }}
                    >
                      {selectMode && (
                        <td>
                          <input
                            type="checkbox"
                            name="checks"
                            onChange={(event) => {
                              checkBox(index, lesson);
                            }}
                            checked={!!values.checks[index] ?? false}
                          />
                        </td>
                      )}
                      <td>{lesson.chNum}</td>
                      <td>{lesson.num}</td>
                      <td>
                        <Link to={match.url + "/" + lesson.id}>
                          {lesson.title}
                        </Link>
                      </td>
                      <td>{lesson.description}</td>
                      <td>{lesson.steps?.length}</td>
                      <td>{lesson.unlockedByDefault ? "✅" : "🔒"}</td>
                      <td>{lesson.updated.toLocaleDateString()}</td>
                      <td>
                        {isAdmin && (
                          <Button
                            variant="outline-primary"
                            style={{ margin: 5 }}
                            onClick={() => editLesson(lesson)}
                          >
                            Edit Lesson
                          </Button>
                        )}
                        {isAdmin && (
                          <Button
                            variant="outline-danger"
                            style={{ margin: 5 }}
                            onClick={() => deleteLesson(lesson)}
                          >
                            Delete Lesson
                          </Button>
                        )}
                      </td>
                    </tr>
                  </Fragment>
                );
              })}
            </tbody>
          </Table>
        </Form>
      </>
    </Col>
  );
};

export default LessonList;
