import QuestionDisplay from "@swing-therapeutics/surveybay/dist/FormComponents/QuestionDisplay";
import { SurveyBayResponse } from "@swing-therapeutics/surveybay/dist/models/SurveyBayResponse";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { firestore, Timestamp } from "../../../../firebase";
import { getColorFromStatus } from "../../../../Model/Workflow";
import LoadingScreen from "../../../LoadingScreen";

interface SurveyResponseProps {
  responseDocID: string;
  userUID: string;
}

const SurveyResponseView: React.FC<SurveyResponseProps> = ({ responseDocID, userUID }) => {
  const [surveyResponse, setSurveyResponse] = useState<SurveyBayResponse>();

  useEffect(() => {
    setSurveyResponse(undefined);
    const unsubscribe = firestore
      .doc(`users/${userUID}/surveyBayResponses/${responseDocID}`)
      .onSnapshot((snap) => {
        if (!snap.exists) {
          console.error(`Could not find response doc with ID ${responseDocID} for user ${userUID}`);
          return;
        }
        setSurveyResponse(SurveyBayResponse.fromFirestore(snap));
      })
    return unsubscribe;
  }, [responseDocID, userUID])

  return (
    <Container>
      {
        !surveyResponse ?
          <LoadingScreen />
          :
          <>
            <Row>
              <h5>SurveyBay key: {surveyResponse.surveyBayKey}</h5>
            </Row>
            <Row>
              <h5>
                Survey status:{' '}
                <span style={{ color: getColorFromStatus(surveyResponse.status) }}>
                  {surveyResponse.status}
                </span>
              </h5>
            </Row>
            <Row>
              <h5>
                Survey assigned: {moment(surveyResponse.assigned).format("MM/DD/YYYY")}
              </h5>
            </Row>
            <Row className="mb-4">
              <h5>
                Survey expires: {moment(surveyResponse.expires).format("MM/DD/YYYY")}
              </h5>
            </Row>
            <Row>
              <p>
                Note: The question numbers here may not be the same as the numbers a user sees (the user may skip some questions).
              </p>
            </Row>
            {surveyResponse.questions.map((question) => {
              const questionFailed = surveyResponse.failReason && surveyResponse.failReason.failQuestionIDs.includes(question.uniqueID);

              return (
                <Row className="mb-4" key={question.uniqueID}>
                  <Col xs={12} md={5}>
                    <Row className="mb-2">
                      <QuestionDisplay mode="display" question={question} questionNumber={question.order} />
                    </Row>
                    {
                      questionFailed &&
                      <>
                        <h6 className="text-danger">Question failed</h6>
                        <h6>{surveyResponse.failReason.reason}</h6>
                      </>
                    }
                    <Row>
                      <small className="ml-3">
                        Question ID: {question.uniqueID}
                      </small>
                    </Row>
                    {
                      question.answered &&
                      <Row>
                        <small className="ml-3">
                          Response updated {moment((question.updated as any as Timestamp).toDate()).fromNow()}
                        </small>
                      </Row>
                    }
                  </Col>
                </Row>
              )
            })}
          </>
      }
    </Container >
  )
}

export default SurveyResponseView;
