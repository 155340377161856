import { firestore, Timestamp } from "../firebase";
import { DocumentData, DocumentRef, DocumentSnapshot } from "../firebase";

interface MedrioProperties {
  DataEntryDate: string;
  DataEntryUserName: string;
  EventType: string;
  FormCompletionStatus: string;
  FormExternalID: string;
  FormLabel: string;
  FormName: string;
  GroupExternalID: string;
  MedrioSubjectID: string;
  RequestID: string;
  SiteExternalID: string;
  SiteName: string;
  StudyExternalID: string;
  StudyTitle: string;
  SubjectIdentifier: string;
  SubjectStatus: string;
  VisitExternalID: string;
  VisitLabel: string;
  VisitName: string;
}

interface MedrioRef {
  FormEventData: MedrioProperties;
  handledTimestamp: Timestamp;
  retries: number;
  timestamp: Timestamp;
}

class Medrio {
  FormEventData: MedrioProperties;
  handledTimestamp: Timestamp;
  retries: number;
  timestamp: Timestamp;

  constructor(props: Medrio) {
    this.FormEventData = props.FormEventData;
    this.handledTimestamp = props.handledTimestamp
    this.retries = props.retries;
    this.timestamp = props.timestamp;
  }

  static fromFirestore(docSnap: DocumentSnapshot<DocumentData>) {
    if (!docSnap.exists) return;
    const docData = docSnap.data() as MedrioRef;

    return new Medrio({
      ...docData,
      handledTimestamp: docData.handledTimestamp,
      timestamp: docData.timestamp,
    } as Medrio)
  }

  //firestore helpers
  data() {
    return {
      FormEventData: this.FormEventData,
      handledTimestamp: this.handledTimestamp,
      retries: this.retries,
      timestamp: this.timestamp
    };
  }

  static async userWithEmail(email: string) {
    const existingUser = await firestore
      .collection("users")
      .where("email", "==", email)
      .limit(1)
      .get();

    if (existingUser.empty) return null;
    let userRef = existingUser.docs[0];
    return Medrio.fromFirestore(userRef);
  }

  matchesFilter(filter: string) {
    if (!filter || filter.length === 0) return true;
    return true;
  }

  //data loading
  async getUser() {
    var snapshot = await firestore
      .collection("users")
      .where('subjectID', '==', this.FormEventData.SubjectIdentifier)
      .get();

    if (snapshot.empty) return null;

    let tasks = [];
    snapshot.docs.forEach((doc) => {
      console.log(doc);
      /* let t = new Task(doc as unknown as DocumentSnapshot<any>);
      tasks.push(t); */
    });
    return tasks;
  }

  async getTriggers() {
    var snapshot = await firestore
      .collection("medrio")
      .doc("events")
      .collection("completed")
      .get();

    if (snapshot.empty) return null;

    let tasks = [];
    snapshot.docs.forEach((doc) => {
      /* let t = new Session(doc, new Date());
      tasks.push(t); */
      console.log(doc);
    });
    return tasks;
  }
}

export default Medrio;
