import React, { useEffect, useState } from "react";
import { firestore } from "../../../firebase";
import "./lessonViewer.css";
import LessonDetail from "./LessonDetail";
import LessonList from "./LessonList";
import { Lesson } from "@swing-therapeutics/swingcore/dist/models/content/Lesson";
import { Row } from "react-bootstrap";

import { Route, Switch, useRouteMatch } from "react-router-dom";
import { useCollectionClassData } from "../../../utils/Hooks";
import { getLessonsTemplates } from "@swing-therapeutics/swingcore/dist/utils/queries";
import LoadingScreen from "../../LoadingScreen";

interface LessonViewerProps {
  programKey: string;
} 

const LessonViewer = ({ programKey }: LessonViewerProps) => {
  const [lessons, loading] = useCollectionClassData(Lesson, getLessonsTemplates(programKey)) 
  const [activeLesson, setActiveLesson] = useState<Lesson>();
  const match = useRouteMatch("/programs/:programID/lessons"); // this is needed for the switch statement and to see if we have an exact match.
  const fullMatch = useRouteMatch("/programs/:programID/lessons/:lessonID?"); //this is the easiest way to pull the lessonID from the URL

  //load the active lesson from url parameters, if there isnt one already
  useEffect(() => {
    const lessonID = fullMatch.params["lessonID"];
    if (lessonID && !activeLesson) {
      const snap = firestore
        .collection("programs")
        .doc(fullMatch.params["programID"])
        .collection("lessons")
        .doc(lessonID)
        .onSnapshot((snapshot) => {
          if (snapshot.exists) {
            setActiveLesson(Lesson.fromFirestore(snapshot));
          }
        });
      return snap;
    } else if (!lessonID && activeLesson) {
      setActiveLesson(null); //set active to null if by chance we end up here, cleanup that may not be totally necessary
    }
  }, [fullMatch.params["lessonID"]]);

  if (loading) {
    return <LoadingScreen/>
  }

  if (lessons.length === 0) {
    return <p>No lessons available.</p>;
  }

  return (
    <>
      <Row className={"lesson-viewer"}>
        <Switch>
          <Route exact={true} path={match.url}>
            <LessonList programKey={programKey} setActiveLesson={setActiveLesson} lessons={lessons} />
          </Route>

          <Route exact={false} path={match.url + "/" + activeLesson?.id}>
            {" "}
            <LessonDetail
              setActiveLesson={setActiveLesson}
              activeLesson={activeLesson}
            />
          </Route>
        </Switch>
      </Row>
    </>
  );
};

export { LessonViewer };
