import React from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { BasicBooleanField, BasicTextAreaField, BasicTextField, FieldHeader, formikHasError } from "../../../utils/FormikHelpers";
import { JournalTemplate } from '@swing-therapeutics/swingcore/dist/models/journals/JournalTemplate'
import { JournalModalProps } from "./types";
import * as uuid from "uuid";
import { isNil } from "lodash";

export const JournalEditModal = ({
  show,
  close,
  programKey,
  journal,
}: JournalModalProps & { journal?: JournalTemplate }) => {
  const [hasErrors, setHasErrors] = React.useState(false);
  const formik = useFormik({
    initialValues: { ...journal, unlockedByDefault: !!journal.unlockedByDefault },
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      displayName: Yup.string().required("Name Required"),
    }),
    onSubmit: async (values) => {
      const updatedJournal = new JournalTemplate({ ...values, programKey });
      if (isNil(updatedJournal.phases)) {
        updatedJournal.phases = {};
      }
      updatedJournal.persist();
      close();
    },
  });
  const { errors } = formik;

  React.useEffect(() => {
    if (formikHasError(errors)) {
      setHasErrors(true);
    } else if (hasErrors) {
      setHasErrors(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);

  return (
    <Modal show={show} onHide={close}>
      <Modal.Header closeButton>
        <Modal.Title>Edit</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col xl={12}>
            <FieldHeader title="ID"/>
          </Col>
          <BasicTextField path="id" md={9} lg={9} disabled={!!journal.id}  formikHelpers={formik} placeholder={`Human readable ID (e.g. "dailyInsights")`} />
          <BasicTextField path="displayName" title="Display Name" lg={12} formikHelpers={formik} />
          <BasicTextAreaField path="description" title="Description" rows={4} lg={12} formikHelpers={formik}/>
          <BasicTextField path="imageURI" title="Image URI" lg={12} formikHelpers={formik} />
          <BasicBooleanField path="unlockedByDefault" title="Unlocked By Default" formikHelpers={formik} />
          <BasicBooleanField path="noJournalLeftBehind" title="No Journal Left Behind" formikHelpers={formik} />
          <BasicBooleanField path="notAssignableToTask" title="Not assignable to task" tooltip="Disables the ability to assign this journal to a session task. Useful for deprecated journals or journals not intended to be assigned to a session (e.g. task based pacing)" formikHelpers={formik} />
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={close} variant="outline-secondary">
          Close
        </Button>
        <Button onClick={formik.submitForm} variant="outline-primary">
          Save changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
