import React, { ChangeEvent, useCallback, useEffect, useState } from "react";
import ErrorMessageRender from "./ErrorMessageRender";
import { ErrorMessage, useField } from "formik";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";

interface FormikTokenProps {
  name: string;
  options?: Array<SelectOption>; // Array of options for the select
  fetchOptions?: () => Promise<Array<SelectOption>>; // Function to call to return options for the select
  label?: string;
  desc?: string;
  selectedOptionsLabel?: string;
  optionsLabel?: string;
}

interface SelectOption {
  value: string;
  label: string;
}

// Must be a child of a Formik Form component
const FormikToken: React.FC<FormikTokenProps> = ({ name, options, fetchOptions, label, desc, selectedOptionsLabel = "Selected options", optionsLabel = "options" }) => {
  const [field, _meta, helpers] = useField(name);
  const [selectOptions, setSelectOptions] = useState<Array<SelectOption>>(options ?? []);
  const [filteredOptions, setFilteredOptions] = useState<Array<SelectOption>>(options ?? [])
  const [search, setSearch] = useState("");
  const [optionsOpen, setOptionsOpen] = useState(false);

  const fetchAndSetSelectOptions = useCallback(async () => {
    const options = await fetchOptions();
    setSelectOptions(options);
    setFilteredOptions(options);
  }, [fetchOptions])

  useEffect(() => {
    if (!options && fetchOptions) {
      fetchAndSetSelectOptions();
    }
    else if (!options && !fetchOptions) {
      console.warn("No options array and no fetchOptions function passed to field select \"" + name + "\"");
    }
    else {
      setSelectOptions(options ?? []);
      setFilteredOptions(options ?? []);
    }
  }, [options, fetchOptions])


  const handleSearchChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.value) {
      const filteredOptions = selectOptions.filter((option) =>
        option.label.toLowerCase().includes(event.target.value.toLowerCase()
        ));
      setFilteredOptions(filteredOptions);
    }
    else {
      setFilteredOptions(selectOptions);
    }
    setSearch(event.target.value);
  }, [selectOptions])

  const handleButtonClick = useCallback((option: SelectOption, add: boolean) => {
    if (add) {
      helpers.setTouched(true);
      const nextValue = [...(field.value ?? []), option.value];
      helpers.setValue(nextValue);
    }
    else {
      const nextValue = field.value.filter(value => value !== option.value);
      helpers.setValue(nextValue);
    }
  }, [field.value])

  return (
    <div className="form-group">
      {
        label &&
        <label className="mb-0">{label}</label>
      }
      {
        desc &&
        <small className="form-text text-muted">{desc}</small>
      }
      {
        (label || desc) && // Spacer for the label or desc
        <div className="mb-2" />
      }
      <label className="mx-2 mb-0">{selectedOptionsLabel}</label>
      <div className="mb-3">
        {
          !field.value?.length &&
          <small className="form-text text-muted mx-2">No {optionsLabel} selected</small>
        }
        {field.value?.map((value) => {
          // Find the option based on the value
          let option = selectOptions.find((option) => option.value === value);
          if (!option) {
            // Option doesn't exist, show error
            option = {
              value: value,
              label: 'ERROR: Option not found',
            };
            console.error(`Option with value ${value} is not in available options, ${JSON.stringify(selectOptions)}`);
          }
          return (
            <Button
              className="m-2"
              key={value}
              variant="outline-primary"
              onClick={() => handleButtonClick(option, false)}>
              {option.label}
              <FontAwesomeIcon
                className="ml-2"
                icon={faMinus}
              />
            </Button>
          )
        })}
      </div>
      <div>
        <label className="mx-2 mb-0 hoverable text-info" onClick={() => setOptionsOpen(prevState => !prevState)}>
          {optionsOpen ? 'Hide ' : 'Show '}{optionsLabel}
        </label>
        <div style={optionsOpen ? undefined : { display: 'none' }}>
          <input className="form-control m-2" autoComplete="off" placeholder={`Search ${optionsLabel}`} value={search} onChange={handleSearchChange} />
          <div>
            {filteredOptions.map((option => {
              // Only show the add button if it is not in the field value
              return !field.value?.includes(option.value) ?
                <Button
                  className="m-2"
                  key={option.value}
                  variant="outline-primary"
                  onClick={() => handleButtonClick(option, true)}>
                  {option.label}
                  <FontAwesomeIcon
                    className="ml-2"
                    icon={faPlus}
                  />
                </Button>
                :
                null
            }))}
          </div>
        </div>
      </div>
      <ErrorMessage name={name} render={ErrorMessageRender} />
    </div>
  )
}

export default FormikToken;
