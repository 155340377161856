import React, { useCallback, useEffect, useState } from "react";
import LoadingScreen from "../../../LoadingScreen";
import { firestore } from "../../../../firebase";
import { Table } from "react-bootstrap";
import { UserSectionProps } from "./UserSectionInterface";

interface PromptResponse {
  uuid: string;
  chapterNumber: string;
  lessonNumber: string;
  stepNumber: number;
  questionText: string;
  response: string;
}

interface TableRowProps {
  promptResponse: PromptResponse;
}

const TableRow: React.FC<TableRowProps> = ({ promptResponse }) => {

  return (
    <tr>
      <td>{promptResponse.chapterNumber}</td>
      <td>{promptResponse.lessonNumber}</td>
      <td>{promptResponse.stepNumber}</td>
      <td>{promptResponse.questionText}</td>
      <td>{promptResponse.response}</td>
    </tr>
  );
}

const UserPromptsTable: React.FC<UserSectionProps> = ({ user, programID }) => {
  const [responsesByLesson, setResponsesByLesson] = useState<PromptResponse[]>();

  const loadPrompts = useCallback(async () => {
    const programRef = await firestore
      .collection("users")
      .doc(user.uid)
      .collection("programs")
      .doc(programID)
      .get();

    const programDoc = programRef.data();
    const snap = await firestore
      .collection("programs")
      .doc(programID)
      .collection("lessons")
      .orderBy("chNum", "asc")
      .orderBy("num", "asc")
      .get();

    const answers = programDoc.promptAnswers;
    let resps = [];
    snap.docs?.forEach((doc) => {
      const lesson = doc.data();
      lesson.steps.forEach((step, index) => {
        if (step.type === "Prompt") {
          const resp = {
            uuid: step.uuid,
            chapterNumber: lesson.chNum,
            lessonNumber: lesson.num,
            stepNumber: index + 1,
            questionText: step.prompt,
            response: answers[step.uuid] ? answers[step.uuid] : "",
          };
          resps.push(resp);
        }
      });
    });
    setResponsesByLesson(resps);
  }, [user, programID]);

  useEffect(() => {
    loadPrompts();
  }, [user, programID]);

  return (
    <>
      {
        !responsesByLesson ?
          <LoadingScreen />
          :
          <Table responsive>
            <thead>
              <tr>
                <th>Chapter</th>
                <th>Lesson</th>
                <th>Step</th>
                <th>Question</th>
                <th>Response</th>
              </tr>
            </thead>
            <tbody>
              {responsesByLesson.map((lesson, index) =>
                <TableRow key={lesson.uuid + index} promptResponse={lesson} />
              )}
            </tbody>
          </Table>
      }
    </>
  );
};

export default UserPromptsTable;